<template>
	<div>
		<fixed-top :resized="checkins.info.render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
					{{$LanguageHelper.translate('_TOTAL_CHECKINS_')}}: {{checkins.info.total}}
				</f7-list-item>
			</f7-list>

			<list-form :params="form" @changed="changed"></list-form>
		</fixed-top>

		<f7-list media-list inset v-if="render">
			<list-item v-for="(checkin, key) in checkins.list" type="accordion"
				:key="key"
				:title="checkin.name"
				:after="checkin.created_on"
				:subtitle="checkin.event_name"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_DATE_')}}: {{checkin.created_on}}</div>
						<div class="item">{{$LanguageHelper.translate('_EVENT_NAME_')}}: {{checkin.event_name}}</div>
						<div class="item">{{$LanguageHelper.translate('_NAME_')}}: {{checkin.name}}</div>
						<div v-if="checkin.name_appuser.length > 0" class="item">{{$LanguageHelper.translate('_CONNECTED_TO_')}}: {{checkin.name_appuser}}</div>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>
	</div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';
import ListItem from '../../components/smart/list-item.vue';

export default {
  components: {
		'list-form': ListForm,
    'fixed-top': FixedTop,
    'list-item': ListItem,
  },

	props: {
		idc: {required: true},
		sortSelected: {type: String, required: true, default: ''},
		searched: {type: String, required: true, default: ''},
		pageEndReached: {type: Boolean, required: true, default: false},
	},

  data() {
    return {
			render: false,
			infiniteDone: false,

			form: {
				fields: {
					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: true,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},

			checkins: {
				info: {
					render: false,
					total: 0
				},

				list: []
			}
    };
  },

	computed: {
    orderBy() {
			return this.sortSelected;
		},

		dateStart() {
			return this.form.fields.date.fields.date_start.value;
		},

		dateEnd() {
			return this.form.fields.date.fields.date_end.value;
		},
  },

	methods: {
		changed(field) {
			// Initial call is also send from here (change event is fired whenever date values are set)!
			if(field.name == 'dateRange') this.load(true);
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.checkins.list = [];
			}

			// Reset the render to false so the height of fixed top will be recalculated
			this.checkins.info.render = false;

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('eventWithScanAccess', 'getCheckins', this.constructRequestData(), {callback: this[firstOrReset ? '_checkinsInit' : '_checkins']});
		},

		constructRequestData() {
			return {
				idc: this.idc,
				order: this.orderBy,
				date_range: {
					 start: this.dateStart,
					 end: this.dateEnd
				}
			};
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getCheckins');
		},

		storeData(data, init) {
			if(init) {
				this.checkins.info.total = data.total_checkins
				this.checkins.info.render = true;
			}

			if(data.resultset && data.resultset.length > 0) {
				this.checkins.list.push(...data.resultset);

				//Let our parent know to reset the page end status!
				this.$emit('page-end-loaded');
			} else this.infiniteDone = true;
		},

		_checkinsInit(data) {
			this.storeData(data, true);
			this.render = true;
		},

		_checkins(data) {
			this.storeData(data);
		}
	},

	watch: {
		searched(val) {
			// Empty current list
			this.checkins.list = [];

			// Search for customers
			if(val.length > 0) {
				$AjaxHelper.doRequest('eventWithScanAccess', 'searchCheckins', Object.assign({search: val}, this.constructRequestData()), {callback: this._checkinsInit});
			}
			// No input, reset the list!
			else {
				this.load(true);
			}
		},

		sortSelected(val) {
			this.load(true);
		},

		pageEndReached(val) {
			if(val) this.load();
		}
	}
};
</script>
