<template>
  <page title="Page not found">
		<f7-block strong>
      <p>Sorry</p>
      <p>Requested content not found.</p>
    </f7-block>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },
}
</script>
