import Dashboard from '../../components/dashboard.vue';

export default {
	components: {
    'dashboard': Dashboard,
  },

  props: {
		idc: {required: true},
    initData: {required: false}, // Contains data from first call, if this is filled we don't have to do a server request!
	}
};
