<template>
  <page
		:title="$LanguageHelper.translate('_TRANSACTIONS_')"
		:infinite="true"
		:search="true"
		:sort-options="sortOptions"
		:fab="fab"
		@infinite-scroll-page-end="pageEndReached"
		@searched="searched"
		@sort-selected="sortSelected"
		@fab-clicked="fabClicked"
		@initiated="go"
		class="manageTransactions"
		>

		<fixed-top :resized="transactions.info.render" v-show="transactions.info.render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
					<div>{{$LanguageHelper.translate('_BANK_BALANCE_')}}: <a class="theme link" @click="moneyWithdrawOpened=true">&euro; {{transactions.info.bank_balance}}</a></div>
					<div>{{$LanguageHelper.translate('_RECEIVABLE_BALANCE_')}}: <a class="theme link" @click="moneyWithdrawOpened=true">&euro; {{transactions.info.receivable_balance}}</a></div>
					<div>{{$LanguageHelper.translate('_AVAILABLE_SOON_')}}: <a class="theme link" @click="moneyWithdrawOpened=true">&euro; {{transactions.info.held_direct_debit_funds}}</a></div>
				</f7-list-item>
			</f7-list>

			<list-form :params="form" @changed="changed" />
		</fixed-top>

		<f7-list media-list inset v-if="render">
			<list-item v-for="(transaction, key) in transactions.list" type="accordion"
				:key="`key_${key}`" 
				:title="transaction.name"
				:subtitle="transaction.date_booked"
				:after="`&euro; ${transaction.amount}`"
				:iconClass="transaction.held_direct_debit && transaction.held_direct_debit == 1 ? 'ionInformationCircle orange' : ''"
				>
				
				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div v-if="transaction.held_direct_debit && transaction.held_direct_debit == 1" class="item fontOrange bold">{{$LanguageHelper.translate('_AVAILABLE_SOON_')}}</div>
						<div class="item" v-if="transaction.iban && transaction.iban.length > 0">{{$LanguageHelper.translate('_IBAN_')}}: {{transaction.iban}}</div>
						<div class="item">{{$LanguageHelper.translate('_DESCRIPTION_')}}: {{transaction.description}}</div>
						<div class="item">{{$LanguageHelper.translate('_AMOUNT_')}}: &euro; {{transaction.amount}}</div>
						<div class="item">{{$LanguageHelper.translate('_REFERENCE_')}}: {{transaction.id}}</div>
						<div class="item" v-if="transaction.sale_id && transaction.sale_id.length > 0">{{$LanguageHelper.translate('_SALE_NUMBER_')}}: {{transaction.sale_id}}</div>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>

		<manage-money-withdraw v-if="moneyWithdrawOpened" :idc="idc" @close="moneyWithdrawOpened=false" />
		<manage-year-end-balances v-if="yearEndBalancesOpened" :idc="idc" @close="yearEndBalancesOpened=false" />
	</page>
</template>

<script>
import ListItem from '../components/smart/list-item.vue';
import Page from '../components/smart/page.vue';
import FixedTop from '../components/smart/fixed-top.vue';
import ListForm from '../components/smart/list-form.vue';
import ManageMoneyWithdraw from '../popups/manage-money-withdraw.vue';
import ManageYearEndBalances from '../popups/manage-year-end-balances.vue';

export default {
  components: {
    'page': Page,
    'fixed-top': FixedTop,
		'list-form': ListForm,
		'list-item': ListItem,
		'manage-money-withdraw': ManageMoneyWithdraw,
		'manage-year-end-balances': ManageYearEndBalances,
  },

	props: {
		idc: {required: true},
	},

  data() {
    return {
			render: false,
			moneyWithdrawOpened: false,
			yearEndBalancesOpened: false,
			infiniteDone: false,

			form: {
				fields: {
					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: false,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},

			// Sort options
			sortOptions: {
				title: '_SORT_AND_FILTER_',
				options: {
					date_transaction_asc: {value: 'date_transaction_asc', label: '_DATE_ASC_', selected: false},
					date_transaction_desc: {value: 'date_transaction_desc', label: '_DATE_DESC_', selected: true},
				}
			},

			// Floating action buttons
			fab: {
				position: 'right-bottom',
				buttons: {
					withdraw: {name:'withdraw', label: $LanguageHelper.translate('_WITHDRAWAL_'), icon: 'icon ionLogoEuro', render: $UserHelper.hasAccess('payments', 'clearing', this.idc), clicked: false},
					export: {name:'export', label: $LanguageHelper.translate('_EXPORT_SELECTED_PERIOD_'), icon: 'icon ionDownload', render: $UserHelper.hasAccess('payments', 'transactions', this.idc) && !$Config.app.onDevice, clicked: false},
					yearBalances: {name:'year-end-balances', label: $LanguageHelper.translate('_END_YEAR_BALANCES_'), icon: 'icon ionList', render: $UserHelper.hasAccess('payments', 'transactions', this.idc) && !$Config.app.onDevice, clicked: false},
				}
			},

			// Transactions data
			transactions: {
				info: {
					render: false,
					bank_balance: 0,
					receivable_balance: 0,
					held_direct_debit_funds: 0,
				},

				list: [],
			}
    };
  },

	computed: {
    orderBy() {
			return (Object.values(this.sortOptions.options).find(option => option.selected)).value;
		},

		dateStart() {
			return this.form.fields.date.fields.date_start.value;
		},

		dateEnd() {
			return this.form.fields.date.fields.date_end.value;
		}
  },

	methods: {
		changed(field) {
			// Initial call is also send from here (change event is fired whenever date values are set)!
			if(field.name == 'dateRange') this.load(true);
		},

		sortSelected() {
			this.load(true);
		},

		pageEndReached() {
			this.load();
		},

		fabClicked(fab) {
			switch(fab.name) {
			  case 'withdraw':
			    this.moneyWithdrawOpened = true;
			    break;

				case 'year-end-balances':
			    this.yearEndBalancesOpened = true;
			    break;

				case 'export':
					return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=exportTransactions&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&date_start=${this.dateStart}&date_end=${this.dateEnd}&language=${$LanguageHelper.current}`);
			}
		},

		downloadInvoice(id) {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoice&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&id=${id}&language=${$LanguageHelper.current}`);
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.transactions.list = [];
			}

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getTransactions', this.constructRequestData(), {callback: this[firstOrReset ? '_transactionsInit' : '_transactions']});
		},

		searched(val) {
			// Empty current list
			this.transactions.list = [];

			// Search for customers
			if(val.length > 0) {
				$AjaxHelper.doRequest('payment', 'searchTransactions', Object.assign({search: val}, this.constructRequestData()), {callback: this._transactionsInit});
			}
			// No input, reset the list!
			else {
				this.load(true);
			}
		},

		constructRequestData() {
			return {
				idc: this.idc,
				order: this.orderBy,
				date_range: {
					 start: this.dateStart,
					 end: this.dateEnd
				}
			};
		},

		storeData(data, init) {
			if(init) {
				this.transactions.info = data.resultset;
				this.transactions.info.render = true;
			}

			if(data.resultset.transactions && data.resultset.transactions.length > 0) {
				this.transactions.list.push(...data.resultset.transactions);
			} else this.infiniteDone = true;
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getTransactions');
		},

		_transactions(data) {
			this.storeData(data);
		},

		_transactionsInit(data) {
			this.storeData(data, true);
		},

		go() {
			this.form.fields.date.render = true;
			this.render = true;
		}
	}
};
</script>
