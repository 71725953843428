<template>
	<popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form v-show="visible" :params="form" @submitted="submitted" @changed="changed"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		filters: {type: Object, required: false, default: null},
		selectFilters: {type: Object, required: false, default: null},
  },

	data() {
		return {
      visible: false,
			opened: false,
			title: 'Filter',
      form: {
        fields: {
          gender: {name:'gender', type: 'select', title: '_GROUP_', render: false,
            options: {
							b: {name: 'b', value: 'b', label: '_MEN_AND_WOMAN_', selected: true},
              m: {name: 'm', value: 'm', label: '_MEN_ONLY_', selected: false},
              v: {name: 'v', value: 'v', label: '_WOMAN_ONLY_', selected: false},
            }
          },
          filter_ranking_day: {name: 'day', type: 'checkbox', title: '_DAY_', checked: true, value: 1, render: true},
          filter_ranking_overall: {name: 'overall', type: 'checkbox', title: '_OVERALL_', checked: true, value: 1, render: true},
				  otherDivider: {type: 'divider', title: '_OTHER_', render: false},
          filter_rx: {name: 'rx', type: 'checkbox', title: 'RX', checked: false, value: 1, render: false},
          filter_fundamental: {name: 'fundamental', type: 'checkbox', title: 'FDM', checked: false, value: 1, render: false},
          filter_scaled: {name: 'scaled', type: 'checkbox', title: 'Scaled', checked: false, value: 1, render: false},
          categoryDivider: {type: 'divider', title: '_WOD_RANK_CATEGORIES_', info: $LanguageHelper.translate('SHOW_ONLY_SELECTED_CATEGORY_'), render: false},
          id_wod_ranking_category: {name: 'id_wod_ranking_category', type: 'checkbox_group', items: [], render: false},
        },
				submit: {text: '_SAVE_', render: true},
				values: {},
      }
		}
	},

	methods: {
		changed(field, option) {
			switch(field.name) {
				case 'gender':
					if(option.value) this.selectFilters.gender.value = option.value;
				break;
				case 'day':
					this.selectFilters.filter_ranking_day.value = (field.checked ? 1 : 0);
				break;
				case 'overall':
					this.selectFilters.filter_ranking_overall.value = (field.checked ? 1 : 0);
				break;
				case 'rx':
					this.selectFilters.filter_rx.value = (field.checked ? 1 : 0);
				break;
				case 'fundamental':
					this.selectFilters.filter_fundamental.value = (field.checked ? 1 : 0);
				break;
				case 'scaled':
					this.selectFilters.filter_scaled.value = (field.checked ? 1 : 0);
				break;
				case 'id_wod_ranking_category':
					this.selectFilters.id_wod_ranking_category.value = (field.checked ? field.value : '');
					//others then selected false
					this.form.fields.id_wod_ranking_category.items.forEach(function (item, index) {
						if(field.value == item.value) item.checked = (field.checked ? true : false);;
					});
				break;
			}
		},

		submitted() {
			let data = [];
			data = JSON.parse(JSON.stringify(this.form.values))
			this.$emit('filterList', data);
			this.opened = false;
		},

    setFilters() {
			if(this.selectFilters.gender) {
        var that = this;
        $.each(that.form.fields.gender.options, function (index, obj) {
          obj.selected = (that.selectFilters.gender.value == obj.value ? true : false);
        });

        this.form.fields = that.form.fields;
        this.form.fields.gender.render = true;
      }

      if(this.filters.wod.has_overall_ranking) {
        if(!this.selectFilters.filter_ranking_day.value || this.selectFilters.filter_ranking_day.value == 0) this.form.fields.filter_ranking_day.checked = false;

        if(!this.selectFilters.filter_ranking_overall.value || this.selectFilters.filter_ranking_overall.value == 0) this.form.fields.filter_ranking_overall.checked = false;
      }

			if(!isNaN(this.selectFilters.filter_rx.value) || !isNaN(this.selectFilters.filter_fundamental.value) || !isNaN(this.selectFilters.filter_scaled.value)) this.form.fields.otherDivider.render = true;

			if(!isNaN(this.selectFilters.filter_rx.value)) {
				this.form.fields.filter_rx.render = true;
				if(this.selectFilters.filter_rx.value == 1) this.form.fields.filter_rx.checked = true;
			}

			if(!isNaN(this.selectFilters.filter_fundamental.value)) {
				this.form.fields.filter_fundamental.render = true;
				if(this.selectFilters.filter_fundamental.value == 1) this.form.fields.filter_fundamental.checked = true;
			}

			if(!isNaN(this.selectFilters.filter_scaled.value)) {
				this.form.fields.filter_scaled.render = true;
				if(this.selectFilters.filter_scaled.value == 1) this.form.fields.filter_scaled.checked = true;
			}

      if(this.filters.ranking_categories && this.filters.ranking_categories.length > 0) {
        this.form.fields.id_wod_ranking_category.items = [];
        var that = this;

        that.filters.ranking_categories.forEach(function (item, index) {
          that.form.fields.id_wod_ranking_category.items.push({
            name: 'id_wod_ranking_category',
            value: item.id_wod_ranking_category,
            title: item.name,
            checked: item.selected && item.selected == 1,
            render: true
          });
        });
        this.form.fields.id_wod_ranking_category.render = true;
        this.form.fields.categoryDivider.render = true;
      }

			this.visible = true;
    },
	},

  created() {
		this.setFilters();
  },
};
</script>
