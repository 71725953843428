import DateTimeListInput from '../../components/smart/date-time-list-input.vue';
import ColorPickerListInput from '../../components/smart/color-picker-list-input.vue';
import ListAutocompleteFormField from '../../components/smart/list-autocomplete-form-field.vue';

export default {
	components: {
		'date-time-list-input': DateTimeListInput,
		'color-picker-list-input': ColorPickerListInput,
		'list-autocomplete-form-field': ListAutocompleteFormField,
	},

	props: {
		className: {type: String, required: false},
		params: {type: Object, required: true},
  },

	computed: {
		submitted() {
			return this.params.submitted;
		},

		formValues() {
			return this.params.values;
		},

		formFields() {
			return this.params.fields;
		}
	},

	methods: {
		colorPickerChanged(field, val) {
			field.value = val;

			this.$emit('changed', field);
		},

		searched(field) {
			this.$emit('changed', field);
		},

		autocompleteSelected(field, item) {
			this.$emit('changed', field, item);
		},

		datePicked(field, date, combinedFields) {
			// Set current field value, date or datetime!
			field.value = field.type == 'date' ? moment(date).format('YYYY-MM-DD') : moment(date).format('YYYY-MM-DD HH:mm');

			if(!combinedFields) {
				if(!field['cnt']) field['cnt'] = 0;
				field['cnt'] +=1;

				return this.$emit('changed', field);
			}

			// Only continue when both fields are initiated!
			if(!combinedFields['cnt']) combinedFields['cnt'] = 0;
			combinedFields['cnt'] +=1;

			if(combinedFields['cnt'] < 2) return;

			// Emit the change event only if the first date in range is before the second one!
			let fields = Object.values(combinedFields.fields);
			if(moment(fields[0].value).isBefore(fields[2].value)) {
				this.$emit('changed', combinedFields);
			}
		},

		toggle(field, action, event) {
			if(action == 'checked') field.checked = !field.checked;

			if(action == 'toggled') {
				field.toggled = !field.toggled;

				// If toggled, we want to prevent the checkbox change event from emitting!
				event.preventDefault();
			}

			this.$emit('changed', field, action);
		},

		submittedClicked() {
			this.setSubmitValues();
			this.removeUnlistedValueFields();
			this.$emit('submitted');
		},

		selected(field, options) {
			const selectedValue = $(this.$refs[`select_${field}`]).val();

			var optionSelected = null;

			// Make it an array if it's not!
			if(!Array.isArray(options)) options = Object.values(options);

			options.forEach((option) => {
				option.selected = false;
				if(option.value == selectedValue) {

					optionSelected = option;
					option.selected = true;
				}
			});

			this.$emit('changed', this.params.fields[field] ? this.params.fields[field] : field, optionSelected);
		},

		// Set raw values for after the submit!
		setSubmitValues() {
			// Empty first
			this.params.values = {};

			// Now go ahead and set your values!
			for(var field in this.params.fields) {
				var fieldValuesName = field;
				if(Array.isArray(this.params.fields)) {
					if(this.params.fields[field].name) {
						fieldValuesName = this.params.fields[field].name
					}
				}

				// Set value from select option
				if(this.params.fields[field].type == 'select' || this.params.fields[field].type == 'select-regular') {
					for (var keyOption in this.params.fields[field].options) {
						if(this.params.fields[field].options[keyOption].selected) {
							this.params.values[fieldValuesName] = this.params.fields[field].options[keyOption].value;
						}
					}
				}
				// Set plain input value
				else if(this.params.fields[field].type == 'input'
				|| this.params.fields[field].type == 'color'
				|| this.params.fields[field].type == 'textarea'
				|| this.params.fields[field].type == 'password'
				|| this.params.fields[field].type == 'date'
				|| this.params.fields[field].type == 'datetime') {
					this.params.values[fieldValuesName] = this.params.fields[field].value;
				}
				// Add the checked one with his value only if it's checked!
				else if(this.params.fields[field].type == 'checkbox') {
					if(this.params.fields[field].checked && this.params.fields[field].value) {
						this.params.values[fieldValuesName] = this.params.fields[field].value;
					}
				}

				// Add the checked ones with their values
				else if(this.params.fields[field].type == 'checkbox_group') {
					this.params.values[fieldValuesName] = [];
					this.params.fields[field].items.forEach((item, key) => {
						if(item.checked && item.value) {
							this.params.values[fieldValuesName].push(item.value);
						}
					});
				}

				// Set combined field values
				else if(this.params.fields[field].type == 'combined') {
					for (var fieldL2 in this.params.fields[field].fields) {
						if(this.params.fields[field].fields[fieldL2].type == 'select') {
							for (var keyOption in this.params.fields[field].fields[fieldL2].options) {
								if(this.params.fields[field].fields[fieldL2].options[keyOption].selected) {
									this.params.values[fieldL2] = this.params.fields[field].fields[fieldL2].options[keyOption].value;
								}
							}
						}

						else if(this.params.fields[field].fields[fieldL2].type == 'date'
						|| this.params.fields[field].fields[fieldL2].type == 'datetime'
						|| this.params.fields[field].fields[fieldL2].type == 'input') {
							this.params.values[fieldL2] = this.params.fields[field].fields[fieldL2].value;
						}
					}
				}
			}
		},

		// Set individual values per field object from the plain values, used for filling values from DB!
		setIndividualsFromValues() {
			for(var field in this.params.fields) {
				var fieldValuesName = field;
				if(Array.isArray(this.params.fields)) {
					if(this.params.fields[field].name) {
						fieldValuesName = this.params.fields[field].name;
					}
				}

				if(this.params.fields[field].type == 'combined') {
					for(var fieldCombined in this.params.fields[field].fields) {
						if(this.params.fields[field].fields[fieldCombined].type == 'select') {
							for(var keyOption in this.params.fields[field].fields[fieldCombined].options) {
								// Only set value if field exists in values array, otherwise leave me alone!
								if(this.params.values[fieldCombined]) {
									if(this.params.fields[field].fields[fieldCombined].options[keyOption].value == this.params.values[fieldCombined]) {
										this.params.fields[field].fields[fieldCombined].options[keyOption].selected = true;
									} else {
										this.params.fields[field].fields[fieldCombined].options[keyOption].selected = false;
									}
								}
							}
						}
					}
				}

				// Set the selected option to true!
				else if(this.params.fields[field].type == 'select' || this.params.fields[field].type == 'select-regular') {
					for(var keyOption in this.params.fields[field].options) {
						// Only set value if field exists in values array, otherwise leave me alone!
						if(this.params.values[fieldValuesName]) {
							if(this.params.fields[field].options[keyOption].value == this.params.values[fieldValuesName]) {
								this.params.fields[field].options[keyOption].selected = true;
								
								// Fix for F7 smart select bug, the item-after is not set automatically :(nst
								const ref = `select_${field}`;
								if(this.params.fields[field].type == 'select' && this.$refs[ref]) {
									this.fixF7SmartSelectedOptionTitle(field, keyOption);
								}
							} else {
								this.params.fields[field].options[keyOption].selected = false;
							}
						}
					}
				}

				// Set plain input value
				else if(this.params.fields[field].type == 'input'
				|| this.params.fields[field].type == 'textarea'
				|| this.params.fields[field].type == 'date'
				|| this.params.fields[field].type == 'datetime') {
					// Force date value to be empty if wrong format is given!
					if(this.params.fields[field].type == 'datetime' && (this.params.values[fieldValuesName] == '0000-00-00 00:00' || this.params.values[fieldValuesName] == '0000-00-00 00:00:00')) this.params.values[fieldValuesName] = '';
					if(this.params.fields[field].type == 'date' && this.params.values[fieldValuesName] == '0000-00-00') this.params.values[fieldValuesName] = '';

					// Only set value if field exists in values array, otherwise leave me alone!
					if(this.params.values[fieldValuesName]) this.params.fields[field].value = this.params.values[fieldValuesName];
				}
			}
		},

		// Remove non listed and rendered false fields from values array so we keep it clean!
		removeUnlistedValueFields() {
			let wanted = [];
			for(var field in this.params.fields) {
				var fieldValuesName = field;
				if(Array.isArray(this.params.fields)) {
					if(this.params.fields[field].name) {
						fieldValuesName = this.params.fields[field].name;
					}
				}

				// Extract actual combined fields
				if(this.params.fields[field].type == 'combined' && this.params.fields[field].render && !this.params.fields[field].excludeFromValues) {
					// Push combined fields in the wanted array
					wanted.push(...Object.keys(this.params.fields[field].fields));
					continue;
				}

				if(this.params.fields[field].excludeFromValues || !this.params.fields[field].render) continue;

				if(this.params.fields[field].type == 'divider'
				|| this.params.fields[field].type == 'description'
				|| this.params.fields[field].type == 'button') continue;

				// Push the field here
				wanted.push(fieldValuesName);
			}

			// Delete unwanted values here!
			for(var field in this.params.values) {
				// Delete if not in wanted array!
				if(wanted.indexOf(field) == -1) {
					delete this.params.values[field];
				}
			}
		},

		fixF7SmartSelectedOptionTitle(field, keyOption) {
			const ref = `select_${field}`;
			if(this.params.fields[field].render && this.$refs[ref].length > 0) {
				$($(this.$refs[ref])[0]).prev('.item-after').html($LanguageHelper.translate(this.params.fields[field].options[keyOption].label));
			}
		}
	},

	watch: {
		submitted(val) {
			if(!val) return;
			this.submittedClicked();
		},

		formValues: {
			deep: true,
			immediate: true,
			 handler(obj) {
				 // Remove unwanted from the values list first
				this.removeUnlistedValueFields();

				 // Only call this method if actual values are set!
				 if(obj && Object.keys(obj).length > 0) {
					 this.setIndividualsFromValues();
				 }
			 },
		},

		formFields: {
			deep: true,
			handler() {
				
				// Fix for F7 smart select bug, the item-after is not set automatically :(
				for(var field in this.params.fields) {
					if(this.params.fields[field].type != 'select') continue;
						var ref = `select_${field}`;
						if(this.$refs[ref]) {
						 for(var keyOption in this.params.fields[field].options) {
							 if(this.params.fields[field].options[keyOption].selected) {
								this.fixF7SmartSelectedOptionTitle(field, keyOption);
							 }
						 }
					 }
				 }
			 }
		}
	},
};
