<template>
  <page :title="$LanguageHelper.translate('_INSTRUCTION_VIDEOS_')" @initiated="getInstructionVideos">
    <f7-list v-if="render" inset>
      <f7-list-item v-for="(item, key, index) in list" :key="index" accordion-item :title="$FunctionsHelper.specialChars(item.category)">
				<f7-accordion-content>
					<f7-list>
						<f7-list-item v-for="(video, key, index) in item.videos" :key="index" accordion-item :title="$FunctionsHelper.specialChars(video.title)" @click="video.render=!video.render">
							<f7-accordion-content>
								<f7-block class="marginBottom10">
									<div class="item nl2br">{{video.description}}</div>
										<f7-button v-if="video.cta_btn_text.length > 0 && video.cta_btn_pos_top == 1" :href="video.cta_btn_url" raised fill small class="external theme marginTop15" :target="$Config.app.onDevice ? '_system' : '_blank'">{{$FunctionsHelper.specialChars(video.cta_btn_text)}}</f7-button>

										<div v-if="video.render" class="marginTop25 marginBottom25 relative wrapperVideo">
											<div style="padding:56.34% 0 0 0;position:relative;">
												<iframe :src="video.player_url" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen"></iframe>
											</div>
										</div>

										<f7-button v-if="video.cta_btn_text.length > 0 && video.cta_btn_pos_top != 1" :href="video.cta_btn_url" raised fill small class="external theme marginTop15" :target="$Config.app.onDevice ? '_system' : '_blank'">{{$FunctionsHelper.specialChars(video.cta_btn_text)}}</f7-button>
									</f7-block>
							</f7-accordion-content>
						</f7-list-item>
					</f7-list>
				</f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },

  props: {idc: {required: false}},

  data() {
    return {
			render: false,
      list: [],
    };
  },

  methods: {
    getInstructionVideos() {
      $AjaxHelper.doRequest('gym', 'getInstructionVideos', {idc: this.idc}, {callback: (data) => {
				this.list = data.list;
				this.render = true;
			}});
    },
  },
};
</script>

<style scoped>
.wrapperVideo {
	max-width: 960px;
}
</style>
