<template>
  <page :title="$LanguageHelper.translate('_FIND_COMPANY_TITLE_')" @initiated="loadData">
    <list-form :params="form" @submitted="submitted" v-if="visible" @changed="changed" />

    <f7-list media-list inset>
      <list-item v-for="company in findCompanies" :key="company.id_gym" type="media" :img="`../static/img/${$Config.app.currentApp}/logo-list.png`"
        :link="toUrlCompany(company)"
        :title="company.name"
        :subtitle="`${company.zipcode} ${company.city}`"
        />
    </f7-list>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';
import ListItem from '../components/smart/list-item.vue';

export default {
	components: {
    'page': Page,
    'list-form': ListForm,
    'list-item': ListItem
  },

  data() {
    return {
      visible: false,
      name: true,
      zipcode: false,
      companyTypes: [],
      findCompanies: [],

      options: [
        {text: $LanguageHelper.translate('_NAME_'), value: 'name'},
        {text: $LanguageHelper.translate('_ZIPCODE_CITY_'), value: 'zipcode'}
      ],

      form: {
				fields: {
          search_on: {
						type: 'select', name: 'search_on', title: '_SEARCH_ON_', render: true, options: {
							name : {name: 'name', value: 0, label: '_NAME_', selected: true},
							zipcode : {name: 'zipcode', value: 1, label: '_ZIPCODE_', selected: false},
						}
					},
          name: {type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          zipcode: {type: 'input', label: '_ZIPCODE_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          city: {type: 'input', label: '_CITY_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          country: {type: 'select', title: '_COUNTRY_', render: false,
            options: {
              nederland: {name: 'nederland', value: 'nederland', label: '_THE_NETHERLANDS_', selected: true},
              belgie: {name: 'belgie', value: 'belgie', label: '_BELGIUM_', selected: false},
            },
          },
				},

				values: {},
				submit: {text: '_SEARCH_', render: true},
			}
    };
  },

  methods: {
    // Regular methods here!
    loadData () {
      $AjaxHelper.doRequest('gym', 'getSearchForm', {}, {callback: this._initSearchForm});
    },

    toUrlCompany(company) {
      return `/company-found/idc/${company.id_gym}/`;
    },

    changed(field, option) {
      //  Check which option was selected and render form fields needed!
      if(field.name && field.name == 'search_on') {
				// Empty results
				this.findCompanies = [];

        // Disable all fields first!
        for(var field in this.form.fields) {
          this.form.fields[field].render = false;
        }

        // Enable the one field we need
        this.form.fields.search_on.render = true;

        // Set the defaults we always need
        if(option.name == 'name') {
          this.form.fields.name.render = true;
        }

        if(option.name == 'zipcode') {
          this.form.fields.zipcode.render = true;
          this.form.fields.city.render = true;
          this.form.fields.country.render = true;
        }
      }
    },

    submitted() {
      this.form.submitted = false;

      let formData = [];

      if (this.form.values.search_on == 0 && this.form.values.name == '') {
        $ViewHelper.alert($LanguageHelper.translate('_ENTER_NAME_'));
        return false;
      } else  {
        formData = this.form.values;
        if(this.form.values.search_on == 0) {
          formData.searchType = 'name';
        } else {
          formData.searchType = 'zipcode';
        }
      }

      $AjaxHelper.doRequest('gym', 'search', formData, {callback: this._initFindCompanies});
    },

    // Callback methods here, we prefix those with the _ character!
    _initSearchForm(data) {
      if(data && data.resultset) {
        this.companyTypes = data.resultset;
        this.visible = true;
      }
    },

    _initFindCompanies: function (data) {
      this.form.values = [];
      this.findCompanies = [];
      if(data && data.resultset) {
        this.findCompanies = data.resultset;
      }
    },
  },

  filters: {
    toUrlCompany(company) {
      return `/company-found/idc/${company.id_gym}/`;
    },
  },
};
</script>
