<template>
	<f7-list accordion-list inset>
		<list-item v-for="(product, key) in products.list" type="accordion"
			:key="`key_${key}`" 
			:title="product.name"
			:footer="getType(product)"
			>
			
			<f7-accordion-content>
				<f7-block class="marginBottom10">
					<!-- Order in the shop -->
					<div class="item" v-if="product.bookingOption !=2">{{$LanguageHelper.translate('_ORDER_')}}: {{(product.order_me && product.order_me >= 0 ? product.order_me : $LanguageHelper.translate('_NOT_SET_'))}}</div>

					<!-- Installment amounts -->
					<template v-if="product.installments == 1 && product.id_invoice_product_type != 3">
						<div class="item" v-if="product.amount_in_vat_first_installment > 0">{{$LanguageHelper.translate('_AMOUNT_FIRST_PERIOD_')}}: &euro; {{product.amount_in_vat_first_installment}}</div>
						<div class="item">{{$LanguageHelper.translate('_AMOUNT_OTHER_PERIODS_')}}: &euro; {{product.amount_in_vat}}</div>
						<div class="item">{{$LanguageHelper.translate('_VAT_PERCENTAGE_')}}: {{product.vat_percentage}}%</div>

						<div class="item">{{$LanguageHelper.translate('_ATHORIZATION_MULTIPLE_PERIODS_')}}: {{$LanguageHelper.translate('_YES_')}}</div>
						<div class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{product.number_installments == 0 ? $LanguageHelper.translate('_CONTINUOUSLY_') : product.number_installments}}</div>
						<div class="item">{{$LanguageHelper.translate('_ATHORIZATION_SEPA_')}}: {{$LanguageHelper.translate('_EVERY_')}} {{product.length > 1 ? product.length : ''}} {{product.period}}</div>
					</template>

					<!-- Settings for credits -->
					<template v-if="product.credits == 1">
						<!-- Number credits and valid until here -->
						<div class="item">{{$LanguageHelper.translate('_NUMBER_CREDITS_')}}: {{product.number_credits}}</div>

						<div class="item" v-if="product.number_months_valid > 0">{{$LanguageHelper.translate('_NUMBER_MONTHS_VALID_')}}: {{product.number_months_valid}}</div>
						<div class="item" v-else-if="product.number_weeks_valid > 0">{{$LanguageHelper.translate('_NUMBER_WEEKS_VALID_')}}: {{product.number_weeks_valid}}</div>
						<div class="item" v-else>{{$LanguageHelper.translate('_PERIOD_VALID_')}}: {{$LanguageHelper.translate('_VALID_UNLIMITED_')}}</div>

						<!-- Payment method here -->
						<div class="item" v-if="product.pm_ideal == 1">iDEAL: {{$LanguageHelper.translate('_YES_')}}</div>
						<div class="item" v-if="product.pm_bancontact == 1">Bancontact: {{$LanguageHelper.translate('_YES_')}}</div>
						<div class="item" v-if="product.pm_machtiging == 1">{{$LanguageHelper.translate('_ATHORIZATION_')}}: {{$LanguageHelper.translate('_YES_')}}</div>
						<div class="item" v-if="product.paypal == 1">PayPal: {{$LanguageHelper.translate('_YES_')}}</div>
					</template>

					<!-- Amount and vat percentage if not free trial form and not installments -->
					<template v-if="product.installments != 1 && product.id_invoice_product_type != 3">
						<div class="item">{{$LanguageHelper.translate('_AMOUNT_IN_VAT_')}}: &euro; {{product.amount_in_vat}}</div>
						<div class="item">{{$LanguageHelper.translate('_VAT_PERCENTAGE_')}}: {{product.vat_percentage}}%</div>
					</template>

					<!-- Other settings if not a free trial -->
					<template v-if="product.id_invoice_product_type != 3">
						<div class="item">{{$LanguageHelper.translate('_NOT_VISIBLE_IN_SHOP_')}}: {{product.hide_from_shop && product.hide_from_shop == 1 ? $LanguageHelper.translate('_ENABLED_') : $LanguageHelper.translate('_DISABLED_')}}</div>
						<div class="item">{{$LanguageHelper.translate('_DISABLED_IN_SHOP_')}}: {{product.disabled_from_shop && product.disabled_from_shop == 1 ? $LanguageHelper.translate('_ENABLED_') : $LanguageHelper.translate('_DISABLED_')}}</div>
						<div class="item">{{$LanguageHelper.translate('_DISABLE_SHARING_')}}: {{product.disabled_sharing && product.disabled_sharing == 1 ? $LanguageHelper.translate('_ENABLED_') : $LanguageHelper.translate('_DISABLED_')}}</div>
					</template>

					<div class="item">{{$LanguageHelper.translate('_THE_CURRENT_SUPPLY_IS_')}}: {{product.stock_current_supply}}</div>

					<div v-if="product.orderURL && product.orderURL.length > 0" class="item marginTop10">
						<div><b>{{$LanguageHelper.translate('_ORDER_LINK_')}}</b><a class="link theme" @click="copyToClipboard(product.orderURL)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a></div>
						<div>{{$LanguageHelper.translate('_SHARE_AND_SELL_PRODUCT_TEXT_')}}</div>
						<div><a :href="product.orderURL" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{product.orderURL}}</a></div>
					</div>

					<div v-if="product.bookingURL && product.bookingURL.length > 0" class="item marginTop10">
						<div><b>{{$LanguageHelper.translate('_BOOKING_LINK_')}}</b><a class="link theme" @click="copyToClipboard(product.bookingURL)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a></div>
						<div>{{product.id_invoice_product_type == 3 ? $LanguageHelper.translate('_SHARE_TRIAL_PRODUCT_TEXT_') : $LanguageHelper.translate('_BOOK_PRODUCT_TEXT_')}}</div>
						<div><a :href="product.bookingURL" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{product.bookingURL}}</a></div>
					</div>
				</f7-block>

				<f7-block class="marginBottom10">
					<f7-row>
						<f7-col v-if="$UserHelper.hasAccess('invoice_product', 'save', idc)">
							<f7-button @click="product.opened = true" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
						</f7-col>

						<f7-col v-if="$UserHelper.hasAccess('invoice_product', 'remove', idc)">
							<f7-button v-if="product.sold && product.sold == 1" @click="archive(product.id_invoice_product, key)" raised fill small>{{$LanguageHelper.translate('_ARCHIVE_PRODUCT_')}}</f7-button>
							<f7-button v-else @click="remove(product.id_invoice_product, key)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
						</f7-col>
					</f7-row>
				</f7-block>
			</f7-accordion-content>

			<!-- Manage product edit popup here, contents is only rendered when opened is true -->
			<manage-product v-if="product.opened"
				:idc="idc"
				:index="key"
				:id_product="product.id_invoice_product"
				@edited="replaceListItem"
				@close="product.opened=false"
			/>
		</list-item>
	</f7-list>

	<!-- Add product popup here-->
	<manage-product v-if="fab.buttons.add.clicked"
		:idc="idc"
		@added="addListItem"
		@close="fab.buttons.add.clicked=false"
	/>

	<!-- Add product archive popup here-->
	<manage-product-archive v-if="fab.buttons.archive.clicked"
		:idc="idc"
		@added="addListItemFromArchive"
		@close="fab.buttons.archive.clicked=false"
	/>
</template>

<script>
import ListItem from '../../components/smart/list-item.vue';
import CopyToClipboard from '../../js/mixins/copyToClipboard';
import ManageProduct from '../../popups/manage-product.vue';
import ManageProductArchive from '../../popups/manage-product-archive.vue';

export default {
	mixins: [CopyToClipboard],

	components: {
		'list-item': ListItem,
		'manage-product': ManageProduct,
		'manage-product-archive': ManageProductArchive,
	},

	props: {
		idc: {required: true},
		sortSelected: {type: String, required: true, default: ''},
		searched: {type: String, required: true, default: ''},
		fab: {type: Object, required: true},
  },

	data() {
    return {
			render: false,
			products: {
				list: []
			}
		};
  },

	computed: {
		orderBy() {
			return this.sortSelected;
		}
	},

	methods: {
		load() {
			$AjaxHelper.doRequest('invoiceProduct', 'productItems', {idc: this.idc, order: this.orderBy}, {callback: this._products})
		},

		search(val) {
			if(val.length > 0) {
				$AjaxHelper.doRequest('invoiceProduct', 'searchProducts', {idc: this.idc, search: val, order: this.orderBy}, {callback: this._products})
			} else {
				this.load();
			}
		},

		renderList(items) {
			this.products.list = items;
		},

		replaceListItem(product) {
			// Remove props that no longer exists in the new object and then merge them together!
			if(!product['orderURL']) delete this.products.list[product.index].orderURL;
			if(!product['bookingURL']) delete this.products.list[product.index].bookingURL;
			this.products.list[product.index] = Object.assign(this.products.list[product.index], product);
		},

		removeListItem(key) {
			let list = this.products.list;

			list.splice(key, 1);
			this.products.list = [];

			// Wait a bit so accordion is closed!
			setTimeout(() => {this.products.list = list}, 100);
		},

		addListItem(product) {
			// Added needed props needed for opening the popup
			product['opened'] = false;

			// Add item to beginning of the list
			this.products.list.unshift(product);
		},

		addListItemFromArchive(product) {
			// Added needed props needed for opening the popup
			product['opened'] = false;
			product['sold'] = 1;

			// Add item to beginning of the list
			this.products.list.unshift(product);
		},

		remove(id, key) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('invoiceProduct', 'removeProduct', {idc: this.idc, id_invoice_product: id, key: key}, {callback: this._removed})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		archive(id, key) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('invoiceProduct', 'updateArchiveStatus', {idc: this.idc, id_invoice_product: id, status: 1, key: key}, {callback: this._removed})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		getType(product) {
			if(product.id_invoice_product_type == 1) return $LanguageHelper.translate('_SUBSCRIPTION_');
			if(product.id_invoice_product_type == 2) return $LanguageHelper.translate('_CARD_WITH_CREDITS_');
			if(product.id_invoice_product_type == 3) return $LanguageHelper.translate('_FREE_TRIAL_');
			if(product.id_invoice_product_type == 4) return $LanguageHelper.translate('_OTHER_PRODUCT_');
		},

		_products(data) {
			// Empty the list first
			this.products.list = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj, key) => {obj.opened = false});

				this.renderList(data.resultset);
			}
		},

		_removed(data) {
			if(data.status == 'OK') this.removeListItem(data.params_send.key);
		}
	},

	watch: {
		searched(val) {
			// Search for customers
			if(val.length > 0) {
				$AjaxHelper.doRequest('invoiceProduct', 'searchProducts', {search: val, order: this.orderBy, idc: this.idc}, {callback: this._products});
			}
			// No input, reset the list!
			else {
				this.load();
			}
		},

		sortSelected() {
			this.load();
		},
	},

	created() {
		// Get the products here!
		this.load();
	}
};
</script>
