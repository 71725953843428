<template>
  <div class="wrapperMain">
		<!-- Fixed toolbar at the top -->
		<f7-toolbar position="top" class="toolbarTop agenda backgroundColorWhite no-shadow">
			<!-- Clickable date  and navigation -->
			<div class="wrapperPeriod">
				<table class="center">
					<tr>
						<td class="arrow"><a @click="$emit('paginated', 'backward')"><i class="icon ionArrowDropLeft" /></a></td>
						<td class="titleTD"><span class="title" @click="$emit('period-clicked')">{{period}}</span></td>
						<td class="arrow"><a @click="$emit('paginated', 'forward')"><i class="icon ionArrowDropRight" /></a></td>
					</tr>
				</table>
			</div>
		</f7-toolbar>

		<div class="wrapperContent">
			<!-- All 7 days -->
			<div v-for="(day, key) in appointments" :key="key" class="wrapperAppointments">
				<div class="dayTitle" :class="isToday(key) ? 'today' : ''">{{$FunctionsHelper.ucFirst(daysFull[key])}} {{dateWeekDay(key)}}</div>

				<!-- Appointments go in here -->
				<div v-for="(item, key) in day" :key="key" class="appointment"
					:class="`${item.status} ${params.settings.appointmentStatusFullColor ? 'statusFullcolor' : ''} ${appointmentOver(item) ? 'over' : ''}`"
					:style=" {backgroundColor: !params.settings.appointmentStatusFullColor ? item.type_background_color : '', color: !params.settings.appointmentStatusFullColor ? item.type_text_color : ''}"
					@click="openAppointment(item)"
					>

					<div class="relative">
						<div v-if="item.showNumberSubscription" class="numberSubscriptions" :style=" {backgroundColor: !params.settings.appointmentStatusFullColor ? item.type_background_color : ''}">
							{{item.total_subscriptions}}/{{item.max_subscriptions}}
						</div>

						<div class="name">{{$FunctionsHelper.specialChars(item.name)}}</div>
						<div class="timeStartEnd">{{timeStart(item)}} - {{timeEnd(item)}}</div>
						<div v-if="item.employee_name.length > 0" class="employee">{{item.employee_name}}</div>
					</div>
				</div>
				<div class="spacer"></div>
			</div>
		</div>

    <!-- Manage appointment popup -->
    <manage-agenda-appointment v-if="manageAppointmentOpened"
      :idc="idc"
      :id_agenda="params.id"
      :params="appointment"
      @changed="$emit('changed')"
      @close="manageAppointmentOpened=false"
    />

    <!-- Customer appointment popup -->
    <customer-agenda-appointment v-if="customerAppointmentOpened"
      :idc="idc"
      :id_agenda="params.id"
      :params="appointment"
      @changed="$emit('changed')"
      @close="customerAppointmentOpened=false"
    />

		<!-- Login new account popup -->
    <login-new-account v-if="loginNewAccountOpened" :idc="idc" @loggedin="loggedIn" @close="loginNewAccountOpened=false" />
	</div>
</template>

<script>
import AgendaViewMixin from '../js/mixins/agendaView';

export default {
	mixins: [AgendaViewMixin],

	computed: {
		period() {
			let date = this.params.date;
			let period = `${moment(date).date()} - ${moment(date).add(6, 'd').date()} ${this.months[moment(date).add(6, 'd').month()]}. ${moment(date).year()}`;

			if(moment(date).year() < moment(date).add(6, 'd').year()) {
				period = `${moment(date).date()} ${this.months[moment(date).month()]} . ${moment(date).year()} - ${moment(date).add(6, 'd').date()} ${this.months[moment(date).add(6, 'd').month()]}. ${moment(date).add(6, 'd').year()}`;
			}

			return period;
		}
	},

	methods: {
		isToday(dayNumber) {
			return moment(this.params.date).add(dayNumber, 'd').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
		},

		dateWeekDay(dayNumber) {
			let date = moment(this.params.date).add(dayNumber, 'd');
			return `${date.date()} ${this.monthsFull[date.month()]}`;
		},

		drawScreen() {
			this.appointments = this.params.data;
		},
	}
};
</script>

<style scoped>
.wrapperContent {
	margin-top: 44px;
	height: 100%;
	background: #fff;
}

.agenda .appointment {
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}

.agenda .appointment .relative {
	padding: 8px;
	padding-left: 15px;
}

.agenda .appointment .relative .numberSubscriptions {
	font-size: 14px;
	padding-right: 3px;
	padding-bottom: 3px;
}

.agenda .appointment .name {
	font-weight: bold;
	padding-bottom: 5px;
}

.agenda .spacer {height: 25px;}

.agenda .dayTitle {
	padding-left: 15px;
	padding-top: 5px;
	padding-bottom: 10px;
}
</style>
