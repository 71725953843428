<template>
  <div class="svg-container">
    <div id="c3-chart"></div>
  </div>
</template>

<script>
import c3 from 'c3';

export default {
	components: {
    'c3': c3,
  },

  props: {
    render: {required: true},
    params: {required: true},
	},

  data() {
    return {
      chart: [],
    };
  },

	computed: {
		type() {
			// Category or line chart
			return this.params.data.graph.options.dashboards.xAxis.type;
		}
	},

	watch: {
		render(val) {
			if(val) this.renderChart();
		}
	},

  methods: {
		renderChart() {
			if(this.type == 'category')  {
				this.renderCategoriesChart();
			} else  {
				this.renderLineChart();
			}
		},

    renderCategoriesChart() {
			var that = this;

			// Set chart options
      let options = this.params.data.graph.options.dashboards;

      // Set chart data columns
			let columns = [];
			this.params.data.graph.data.forEach((item) => {
				// Decode items name special chars to human readable
				item[0] = $FunctionsHelper.specialChars(item[0]);
				columns.push(item);
			});

      c3.generate({
        // Bind chart to element
        bindto: '#c3-chart',

        padding: {
          right: 20,
        },

				size: {
	        height: that.calculateHeight()
	    	},

				// Fix to overwrite the default c3 max height behaviour!
				onrendered: function() {
					that.setSize();
				},

				onresized: function() {
					that.setSize();
				},

        data: {
          // Set chart type (bar, pie etc.)
        	type: options.chart,

					// Columns data
          columns: columns,

          //Set groups for data stacking
					groups: [options.groups],

					//display text if no data
          empty: {
            label: {
              text: options.text,
            },
          },
        },

        // Disable grid line on hover
        grid:{
          focus:{
            show: false,
          },
        },

        // Set colors
        color: {
          pattern: options.pattern,
        },

        // Do not show every point in line
        point: {
          show: false,
        },

        // Tooltip shows individual data
        tooltip: {
          grouped: false,
          format: {
            title(x, index) { return '';},
            name(name, ratio, id, index) { return name + ':'; }
          },
        },

        axis: {
          y: {
            tick: {
              //Set format ($, € etc.)
              format(value) {
                // If integer, no format
                if(options.yAxis.integer == 1 && !Number.isInteger(value)) {
                  return '';
                }

                // Set format
                return options.yAxis.prefix + ' ' + value;
              },
              padding: {
                top: 10,
              },
            },
          },

          x: {
            type: 'category',

            // Categories (data with names)
            categories: options.xAxis.customValues,

            // Rotate category names a bit
            tick: {
              rotate: 25,
              multiline: false,
            }
          },
        },
      });
    },

    // Render line chart
    renderLineChart() {
			var that =  this;

			// Set chart options
      let options = this.params.data.graph.options.dashboards;

      // Set data for columns
			let columns = [];
			let resultset = [];

			// Custom values for xAxis, could be dates for example!
			if(this.params.data.graph.options.dashboards.xAxis.customValues != '') {
				columns = [this.params.data.graph.options.dashboards.xAxis.customValues];
				resultset = [this.params.data.graph.data];
			}
			// No custom values, just a plain array with [0] => xAxis values and [1] => yAxis values
			else {
				resultset = this.params.data.graph.data;
			}

			// Combine customValues with colums
			resultset.forEach((item, key) => {
				columns.push(item);
			});

			// Fix no data C3 bug, just give a 0 value!
			if(columns[1].length == 0) columns[1] = [0];

			/*
				Endresult columns array:
				[0] => xAxis values (left to right dates for example)
				[1] => yAxis values (top to bottom euro amounts for example)
			*/

      // Generate chart
  		c3.generate({
        // Bind chart tot element
		    bindto: '#c3-chart',

		    padding: {
		    	top: 20,
		    	right: 20,
		    },

				size: {
	        height: that.calculateHeight()
	    	},

				// Fix to overwrite the default c3 max height behaviour!
				onrendered: function() {
					that.setSize();
				},

				onresized: function() {
					that.setSize();
				},

		    data: {
		    	x: 'x',

          // Set columns
	      	columns: columns,

					// Set groups for data stacking
	      	groups: [options.groups],

	      	type: options.chart, // Chart Type (line, bar, pie)

          // No data text here
	      	empty: {
				    label: {
				      text: options.text,
				    },
  				},
  		  },

        // Disable grid line on hover
		    grid: {
		        focus: {
		        show: false,
		      },
		    },

        //set colors
		    color: {
	        pattern: options.pattern,
		    },

        // If bar set width
		    bar: {
		    	width: {
		    		ratio: 0.12,
		    	},
		    },

        // Do not show every point in line
		    point: {
	        show: false,
		    },

        // Tooltip shows individual data
        tooltip: {
          grouped: false,
          format: {
            name(name, ratio, id, index) { return name + ':'; }
          },
        },

  		  axis: {
      		y: {
      			tick: {
              // Include prefix to value
        			format(value) {
        				return `${options.yAxis.prefix} ${value}`;
        			},
            },

            padding: {
            	top: 10,
            },
	        },

	        x: {
            // Set x-axis type (timeseries, categories etc)
            type: options.xAxis.type,

            tick: {
              //set format (if timeseries it's a date format)
              format(value) {
              	if(options.xAxis.format && options.xAxis.format != '') {
              		return moment(value).format(options.xAxis.format);
              	}

              	if(options.xAxis.prefix && options.xAxis.prefix != '') {
              		return `${options.xAxis.prefix} ${value}`;
              	}
              },

              // Displays all custom values even when there is no datapoint for a value
              fit: options.xAxis.fit,

							rotate: 75,
            }
          },
		    },
  		});
    },

		setSize() {
			$('#c3-chart').css({'max-height': 'none'});
			$('#c3-chart').css({'height': `${this.calculateHeight()}px`});
		},

		calculateHeight() {
			//get document height
		  let documentHeight = $(document).height();

      //get summary height
  		let summaryHeight = $('#dashboard #summaryList').height();

			//page content = document height minus fixed-top
			let pageContentHeight = documentHeight - $('#dashboard #fixed').height();

			//this is the height we want to get for new chart height
  		let wantedHeight = (pageContentHeight - summaryHeight) - 220;
			if(wantedHeight < 320) {
  			wantedHeight = 320;
  		}

			return wantedHeight;
		}
  },

  mounted() {
		this.renderChart();
	},
}
</script>

<style>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
}

.c3 path, .c3 line {
    fill: none;
    stroke: #000;
}

.c3-chart-arc path {
    stroke: #fff;
}

.c3-chart-arc text {
    fill: #fff;
    font-size: 13px;
}

.c3-legend-item-hidden {
  opacity:0.25;
}

.c3-title {
  font: 12px sans-serif;
}
.c3-tooltip {
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  background-color: rgba(255, 255, 255);
}
.c3-tooltip th {
  font-size:12px;
  text-align: left;
}

.c3-tooltip td {
  font-size:12px;
}

.c3-chart-arcs .c3-chart-arcs-gauge-unit {
  font-size:12px;
}
</style>
