<template>
	<page
		:title="title"
		:back="false"
		:panel="false"
		:language="true"
		:toolbar-branding="true"
		:backgroundImage="backgroundImage"
		:visible="visible"
		pageContentClass="companyBackground"
		>

		<f7-block>
			<div class="loginNewAccount">
				<div class="left">
					<div class="content">
						<form>
							<f7-card>
								<f7-card-header>{{$LanguageHelper.translate('_LOGIN_')}}</f7-card-header>
								<f7-list inset>
									<f7-list-input @input="formLogin.username = $event.target.value"
										type="text"
										autocomplete="off"
										clear-button
										:label="$LanguageHelper.translate('_YOUR_USERNAME_')"
										:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
									/>

									<f7-list-input @input="formLogin.pass = $event.target.value"
										type="password"
										autocomplete="off"
										clear-button
										:label="$LanguageHelper.translate('_PASSWORD_')"
										:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
										v-on:keypress.enter="loginSubmitted"
									/>
								</f7-list>

								<f7-block strong>
									<f7-button fill raised @click="loginSubmitted">{{$LanguageHelper.translate('_LOGIN_')}}</f7-button>
								</f7-block>

								<f7-block>
									<!-- Forget password link and popup component  -->
									<div class="wrapperForgotPass">
										<f7-link class="theme forgotPass" @click="openForgotPassword=true">{{$LanguageHelper.translate('_FORGOT_PASSWORD_')}}?</f7-link>
										<forgot-password v-if="openForgotPassword" :title="$LanguageHelper.translate('_FORGOT_PASSWORD_')" @close="openForgotPassword=false" />
									</div>
								</f7-block>
							</f7-card>
						</form>
					</div>
				</div>

				<div class="right">
					<div class="content">
						<form>
							<f7-card>
								<f7-card-header>{{$LanguageHelper.translate('_NO_ACCOUNT_YET_')}}?</f7-card-header>
								<f7-card-content>
									{{newAccountText}}
								</f7-card-content>

								<f7-block strong>
									<!-- Forget password link and popup component  -->
									<f7-button fill raised @click="openNewAccount=true">{{$LanguageHelper.translate('_CREATE_NEW_ACCOUNT_')}}</f7-button>
									<account v-if="openNewAccount" :title="$LanguageHelper.translate('_CREATE_NEW_ACCOUNT_')" @close="openNewAccount=false" />
								</f7-block>
							</f7-card>
						</form>
					</div>
				</div>
			</div>
		</f7-block>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ForgotPassword from '../popups/forgot-password.vue';
import Account from '../popups/account.vue';

export default {
	components: {
    'page': Page,
		'forgot-password': ForgotPassword,
		'account': Account,
  },

  data() {
    return {
			visible: false,
			title: this.$Config.apps[this.$Config.app.currentApp].displayName,
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href), /* Query params from URL, there might be a company id in there */
			backgroundImage: '',
			newAccountText: this.$LanguageHelper.translate('_CREATE_NEW_ACCOUNT_TEXT_'),
			formLogin: {
				username: '',
				pass: '',
			},
			openForgotPassword: false,
			openNewAccount: false,
    };
  },

  methods: {
		loginSubmitted() {
			let data = JSON.parse(JSON.stringify(this.formLogin));
			$AjaxHelper.doRequest('user', 'login', $.extend(data, {gcl: 1 /* Get company levels too */}), {callback: this._login});
		},

		_login (data) {
			// Login and send the user to the home page!
			if(data.status == 'OK') {
				// Log the user in!
				$UserHelper.login(data);

				// Set the company component and feature levels for all companies user has levels!
				if(data.company_levels) {
					$.each(data.company_levels, (key, obj) => {
						$UserHelper.accesslevels.setCompanyLevels(key, obj.accesslevels.data);
					});
				}
				
				if($Config.app.onDevice) {
					return $ViewHelper.router.navigate(`/`, {animate: false});
				} else {
					return $ViewHelper.reloadWindow();
				}
			}
		},

		_companyStyles(data) {
			// Set background images if there are any
			if(data.companyImages && data.companyImages.bg) this.backgroundImage = data.companyImages.bg;

			// Set company name in navbar title if it's there
			if(data.gyminfo) this.title = data.gyminfo.name;

			// Set the new account text with the company name
			this.newAccountText = this.$LanguageHelper.translate('_CREATE_NEW_ACCOUNT_GYM_') + ' ' + data.gyminfo.name;

			this.visible = true;
		}
  },

	created() {
		// Check if there is a company id present, then we do a random call to the server to get the company styles!
		if(this.queryParams.id) {
			$AjaxHelper.doRequest('user', 'gymsUser', {idc: this.queryParams.id, companyImages: 1}, {callback: this._companyStyles});
		} else {
			this.visible = true;
		}
	}
};
</script>

<style scoped>
.loginNewAccount {
	width: 730px;
	overflow:hidden;
	margin:auto;
	padding:0;
}

.loginNewAccount .card {
	margin: 0;
	height: 280px;
}

.loginNewAccount .card-content {height: 100px;}
.loginNewAccount .left, .loginNewAccount .right {width: 350px;}
.loginNewAccount .wrapperForgotPass {text-align: center;}
.loginNewAccount .wrapperForgotPass .forgotPass {padding-top:3px; padding-bottom:10px;}

@media screen and (max-width: 820px) {
	.loginNewAccount {
		width: 100%;
	}

	.loginNewAccount .card, .loginNewAccount .card-content {height:auto;}
	.loginNewAccount .right {margin-top:35px;}

	.loginNewAccount .left, .loginNewAccount .right {
		float: none;
		width: 100%;
	}
}
</style>
