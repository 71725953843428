<template>
	<popup :title="$LanguageHelper.translate('_PLAN_DEACTIVATION_')" :opened="opened" @mounted="opened=true" @close="$emit('close')" class="deactivateDirectDebit">
		<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		id: {required: true},
		idc: {required: true},
		date_deactivation_start_options: {required: true},
		date_deactivation_end_options: {required: true},
  },

	data() {
    return {
			opened: false,
			hasDeactivationPlanned: false,

			form: {
				fields: {
					date_deactivation_start_info: {type: 'divider', title: '_SELECTED_PERIOD_WIL_NOT_BE_PROCESSED_', render: true},
					date_deactivation_start: {type: 'select', name: 'date_deactivation_start', title: '_DEACTIVATE_BY_', render: true, options: []},
					date_deactivation_start_end_info: {type: 'divider', title: '_SELECTED_PERIOD_WIL_BE_PROCESSED_', render: true},
					date_deactivation_end: {type: 'select', name: 'date_deactivation_end', title: '_REACTIVATE_BY_', render: true, options: []},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		};
  },

	methods: {
		changed(field, option) {
			if(field.name != 'date_deactivation_start') return;

			// Reset the date deactivion end
			this.form.submit.text = '_SAVE_';
			if(option.value == '') {
				this.form.fields.date_deactivation_end.render = false;
				this.form.fields.date_deactivation_end.options.forEach((option, index) => {
					option.selected = false;
				});

				// Make the default one selected again!
				this.form.fields.date_deactivation_end.options[0].selected = true;

				// Render with delay (otherwise the title is not set in the smart select)
				setTimeout(() => {
					this.form.fields.date_deactivation_end.render = true;

					// Change the submit button text here!
					this.form.submit.text = '_REMOVE_DEACTIVATION_';
				}, 2);
			}
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			// Remove the deactivation is it has one planned and now the empty option is selected, otherwise plan the deactivation!
			if(this.hasDeactivationPlanned && data.date_deactivation_start == '') {
				$AjaxHelper.doRequestExtraLogin('payment', 'removeDeactivationSubscription', Object.assign({idc: this.idc, id: this.id}, data), {callback: this._saved});
			} else {
				$AjaxHelper.doRequestExtraLogin('payment', 'deactivateSubscription', Object.assign({idc: this.idc, id: this.id}, data), {callback: this._saved});
			}
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Let our parent know there has been changes so it reloads the list!
				this.$emit('changed', this.id);

				//Show Dialog to unsubscribe customers appointments!
				if(data.noticeUserHasSubscriptionsAfterDate && data.noticeUserHasSubscriptionsAfterDate.length > 0) {
					//Set the end date to today if the user didn't enter it (happens when the deactivate option is clicked in prev request)
					let dataToSend = {id_invoice_product: data.params_needed.id_invoice_product, id_appuser: data.params_needed.id_appuser, id_gym: data.params_needed.id_gym, date_end: data.params_send.date_deactivation_start};

					let buttons = [
						{text: $LanguageHelper.translate('_YES_'), onClick: () => {$AjaxHelper.doRequest('Payment', 'unsubscribeUserAppointmentsAfterDate', dataToSend, {callback: (data) => {}});}},
						{text: $LanguageHelper.translate('_NO_'), onClick: () => {return false;}},
					];

					$ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_NOTICE_'), data.noticeUserHasSubscriptionsAfterDate);
				}

				// Close popup here, were done!
				this.opened = false;
			}
		},
	},

	created() {
		// Set deactivation options
		this.form.fields.date_deactivation_start.options.push({label: $LanguageHelper.translate('_NOT_DEACTIVATE_'), value: '', selected: false});
		this.date_deactivation_start_options.forEach((option, index) => {
			// Once we have found a seleced option we know it has been set before by the user
			if(option.selected == 1) this.hasDeactivationPlanned = true;

			// Set the options here
			this.form.fields.date_deactivation_start.options.push({
				label: $LanguageHelper.translate('_INSTALLMENT_') + ' ' + option.period + ' (' + option.date + ')',
				value: option.date_deactivation_start,
				selected: option.selected == 1
			});
		});

		// Set deactivation end options for pause!
		this.form.fields.date_deactivation_end.options.push({label: $LanguageHelper.translate('_NOT_REACTIVATE_'), value: '', selected: false});
		this.date_deactivation_end_options.forEach((option, index) => {
			// Set the options here
			this.form.fields.date_deactivation_end.options.push({
				label: option.date,
				value: option.date_deactivation_end,
				selected: option.selected == 1
			});
		});

		// Select default selected values if none were set!
		if(!this.hasDeactivationPlanned) {
			this.form.fields.date_deactivation_start.options[0].selected = true;
			this.form.fields.date_deactivation_end.options[0].selected = true;
		}
	}
};
</script>

<style>
/* Reduce top padding because our first item is a divider with a grey background */
.deactivateDirectDebit .page-content {padding-top: 24px!important;}
</style>
