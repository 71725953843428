<template>
  <page :title="title" :idc="idc" class="customerPage" @initiated="getModules">
    <div v-if="render">
      <div class="topImage">
        <img v-if="companyImage" :src="companyImage">
        <f7-toolbar tabbar labels position="top" class="backgroundWhite">
          <f7-link v-if="companyInfo.email && companyInfo.email.length > 0" :href="`mailto:${companyInfo.email}`" class="external" :target="$Config.app.onDevice ? '_system' : '_blank'"><i class="icon ionMail" /></f7-link>
          <f7-link v-if="companyInfo.website && companyInfo.website.length > 0" :href="`https://${companyInfo.website}`" class="external" :target="$Config.app.onDevice ? '_system' : '_blank'"><i class="icon ionWeb" /></f7-link>
          <f7-link @click.prevent="openCompanyInfo=true"><i class="icon ionInformationCircle" /></f7-link>
        </f7-toolbar>
      </div>

      <div :class="`cardCenter${companyImage ? ' withCompanyLogo' : ''}`">
        <f7-list inset v-if="invoices > 0">
          <f7-list-item class="textPayAttention arrowWhite" :link="`${invoicesLink}/tabInvoicesActive/${tabInvoicesActive}`">
            <div v-if="invoices = 1"><f7-icon class="icon white ionWarning marginRight10"></f7-icon>{{$LanguageHelper.translate('_THERE_IS_')}} {{invoices}} {{$LanguageHelper.translate('_UNPAID_INVOICE_')}}</div>
            <div v-if="invoices > 1"><f7-icon class="icon white ionWarning marginRight10"></f7-icon>{{$LanguageHelper.translate('_THERE_ARE_')}} {{invoices}} {{$LanguageHelper.translate('_UNPAID_INVOICES_')}}</div>
          </f7-list-item>
        </f7-list>

        <div v-if="hasQREnabled" class="wrapperQRCheckin marginBottom25">
          <f7-button small raised fill class="button" @click="fetchCustomerQROptions"><f7-icon class="icon white icon iconScannerQR" />{{$LanguageHelper.translate('_CHECKIN_WITH_QR_')}}</f7-button>
        </div>

        <f7-card v-if="widgets.reservations.enabled == 1">
          <f7-card-header class="withIcon"><i class="icon iconAgenda" />{{$LanguageHelper.translate('_UPCOMING_APPOINTMENTS_')}}</f7-card-header>
          <f7-card-content style="padding-top:10px;">
            <div v-if="widgets.reservations.data.length > 0">
              <div v-for="(reservation, key) in widgets.reservations.data" :key="key" class="line">
                <table width="100%">
                  <tr>
                    <td>
                      <div class="bold">{{reservation.name}}</div>
                      <div>{{reservation.date_start}}</div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div v-else>{{$LanguageHelper.translate('_NO_NEW_APPOINTMENTS_')}}</div>

            <div class="wrapper marginTop15 marginBottom15">
              <f7-button small raised fill class="button" :href="toUrAgenda(idc)">{{$LanguageHelper.translate('_TO_AGENDA_')}} ></f7-button>
            </div>
          </f7-card-content>
        </f7-card>
      
        <f7-card v-if="widgets.webshop.enabled == 1 && widgets.webshop.data.length >= 3">
          <f7-card-header class="withIcon"><i class="icon iconCart" />{{$LanguageHelper.translate('_FROM_THE_ASSORTMENT_')}}</f7-card-header>
          <f7-block class="noMarginTop">
            <f7-row>
              <f7-col v-for="(item, key) in widgets.webshop.data" :key="`F-${key}`">
                <f7-card class="widthShopCard">
                  <f7-card-content :padding="false" @click="itemClicked(item.id_invoice_product)" class="product">
                    <div class="image" :style="{ 'background-image': 'url(' + item.imageURL + ')' }"></div>
                    <div class="information">
                      <div class="textOverflow" v-html="item.name"></div>
                      <div class="theme"><b>€ {{item.amount}}</b></div>
                    </div>
                  </f7-card-content>
                </f7-card>
              </f7-col>
            </f7-row>

            <div class="wrapper marginTop15 paddingBottom30">
              <f7-button small raised fill class="button" :href="toUrWebhop(idc)">{{$LanguageHelper.translate('_TO_ALL_PRODUCTS_')}} ></f7-button>
            </div>
          </f7-block>
        </f7-card>

        <f7-card v-if="widgets.webshop.enabled == 1 && widgets.webshop.data.length < 3">
          <f7-card-header class="withIcon"><i class="icon iconCart" />{{$LanguageHelper.translate('_PRODUCT_OFFER_')}}</f7-card-header>
          <f7-block class="noMarginTop paddingTop15">
            <div class="wrapper marginTop15 paddingBottom30">
              <f7-button small raised fill class="button" :href="toUrWebhop(idc)">{{$LanguageHelper.translate('_TO_ALL_PRODUCTS_')}} ></f7-button>
            </div>
          </f7-block>
        </f7-card>
        
        <f7-card v-if="la == 0">
          <f7-card-header class="withIcon"><i class="icon iconPayments" />{{$LanguageHelper.translate('_YOUR_LATEST_PURCHASES_')}}</f7-card-header>
          <div class="wrapperCardContent" style="padding-top:10px;">
            <div v-if="widgets.purchases.data && widgets.purchases.data.length > 0">
              <div v-for="(purchase, key) in widgets.purchases.data" :key="key" class="line">
                <table width="100%">
                  <tr>
                    <td>
                      <div class="bold">{{purchase.name}}</div>
                      <div>{{purchase.created_on}}<span v-if="purchase.credits == 1">&nbsp;({{$LanguageHelper.translate('_YOUR_STILL_HAVE_')}} {{purchase.number_credits}} {{$LanguageHelper.translate('_CREDITS_LEFT_')}})</span></div>
                    </td>
                    <td align="right" valign="top">&euro; {{purchase.amount}}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div v-else>
              <div>{{$LanguageHelper.translate('_NO_PURCHASES_YET_')}}</div>
              <div class="wrapper marginTop15 marginBottom15 paddingBottom30">
              <f7-button small raised fill class="button" :href="toUrWebhop(idc)">{{$LanguageHelper.translate('_TO_SHOP_')}} ></f7-button>
              </div>
            </div>
          </div>
          
          <f7-card-footer v-if="widgets.purchases.data && widgets.purchases.data.length > 0">
            <a :href="toUrlPurchases(idc)" class="link">{{$LanguageHelper.translate('_VIEW_YOUR_PURCHASES_')}} ></a>
          </f7-card-footer>
        </f7-card>

         <f7-list v-if="modulesCustomer && modulesCustomer.length > 0" inset class="marginTop20">
          <f7-list-item v-for="(modules, key, index) in modulesCustomer" :key="index" :title="modules.name" :link="modules.link">
            <template #media><f7-icon :icon="`icon ${modules.icon}`"></f7-icon></template>
          </f7-list-item>
        </f7-list>
      </div>
    </div>
    
    <company-information v-if="openCompanyInfo" :idc="idc" @close="openCompanyInfo=false" />

    <product-information v-if="productInfoOpened"
		  :idc="idc"
		  :id="productID"
      :closeOnCartChange=true
      :showShoppingCart=false
		  @shoppingCartChanged="toWebshop(idc)"
			@close="productInfoOpened=false"
		/>

    <customer-qr-code-fetcher v-if="customerQRCodeOpened" :events="eventsWithQRAccess" @close="customerQRCodeOpened=false" />
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import CompanyInformation from '../popups/company-information.vue';
import ProductInformation from '../popups/product-information.vue';
import CustomerQRCode from '../popups/customer-qr-code-fetcher.vue';

export default {
  components: {
    'page': Page,
    'company-information': CompanyInformation,
    'product-information': ProductInformation,
    'customer-qr-code-fetcher': CustomerQRCode,
  },

  props: {
		idc: { required: true },
  },

  data() {
    return {
      render: false,
      productInfoOpened: false,
      customerQRCodeOpened: false,
      eventsWithQRAccess: null,
      widgets: {},
      modulesCustomer: [],
      companyInfo: {},
      openCompanyInfo: false,
      title: this.$Config.apps[this.$Config.app.currentApp].displayName,
      companyImage: '',
      invoices: 0,
      la: 0,
      invoicesLink: '',
      tabInvoicesActive: true,
      hasQREnabled: false
    };
  },

  watch: {
		render(val) {
			if(val) {
				setTimeout(() => this.resize(), 10)
				$(window).resize(() => {
					this.resize();
				});
			}
		}
	},

  methods: {
    resize() {
			let el = $('.image');
			el.height(el.width());
		},
    
    storeItemImageURL(data) {
			return !data.imageURL || data.imageURL.length == 0 ? '../../../static/img/no-image.png' : data.imageURL;
  	},

    itemClicked(id) {
      this.productID = id;
      this.productInfoOpened = true;
    },

    toWebshop(idc) {
      // We need this timeout, otherwise the popup has no time to close and the backbutton will break!
      setTimeout(() => $ViewHelper.router.navigate(this.toUrWebhop(idc), {animate: false}), 1000);
    },

    toUrWebhop(idc) {
      return `/customer-invoice_product/idc/${idc}`;
    },

    toUrAgenda(idc) {
      return `/agenda-routing/idc/${idc}/manage/0/`;
    },

    toUrlPurchases(idc) {
      return `/customer-my-purchases/idc/${idc}`;
    },

    _initModules(data) {
      if(data.status == 'OK') {
				// These we don't wanna show anymore!
				var ignoreModules = [
					'nutrition',
					'customer-invoices',
					'information',
					'contact',
					'trainingschedule',
					'trainingdistance',
					'free-advice',
				];

        if(data.widgets) this.widgets = data.widgets;

        if(this.widgets.webshop && this.widgets.webshop.data.length > 0) {
          this.widgets.webshop.data.forEach((product) => {
            product.imageURL = this.storeItemImageURL(product);
          });
        }
        
        if (data.resultset && data.resultset.length > 0) {
					this.invoices = data.number_outstanding_invoices;
          this.la = data.la;
          
          data.resultset.forEach((obj) => {
            // We need to show a QR button to the user!
            if(obj.name_system == 'qr_access') this.hasQREnabled = true;

						if (!obj.backend_only || (obj.backend_only == 0) || (obj.name_system == 'invoice_product')) {
              if (obj.name_system == 'invoice_product') {
                obj.name = $LanguageHelper.translate('_SHOP_');
                obj.name_system == 'shop';
              }

              if(obj.name_system == 'my-products') {
                obj.icon = 'iconPayments';
                obj.name = $LanguageHelper.translate('_MY_PURCHASES_');
                obj.name_system = 'my-purchases';
                obj.url = 'pages/app-gym/customer-my-purchases';
              }

							// Exlude modules we don't want to show
              if(ignoreModules.includes(obj.name_system)) return;

							// Store link
              obj.link = '/customer-' + obj.name_system + '/idc/' + this.idc;

              if(obj.name_system == 'my-purchases') this.invoicesLink = obj.link;
							if(obj.name_system == 'agenda') obj.link = `/agenda-routing/idc/${this.idc}/manage/0`;

              this.modulesCustomer.push(obj);
            }
          });
        }

        // Add company image and title
        if(data.companyImages && data.companyImages.logo && data.companyImages.logo != '') {
          this.companyImage = data.companyImages.logo;
          $('.page.customerPage').addClass('hasTopImage'); // If we try do this with reactive variable, the previous page will show blank, no idea why :(
        }

        if(data.gyminfo) this.title = data.gyminfo.name;
        if(data.company_info) this.companyInfo = data.company_info;

				// Render the page!
				this.render = true;
      }
    },

    getModules() {
      $AjaxHelper.doRequest('gym', 'getModulesEnabledGym', {idc: this.idc, companyImages: 1, numberOutstandingInvoices: 1}, {callback: this._initModules});
    },

    fetchCustomerQROptions() {
      // This method fetches the QR code options for customer, if there is more than one we open a popup so user can decide for options
      $AjaxHelper.doRequest('eventWithScanAccess', 'getCustomerQROptions', {idc: this.idc}, {callback: (data) => {
        if(data.resultset.length > 0) this.eventsWithQRAccess = data.resultset;
        
        // Open popup if there are multiple check-in options, otherwise just send the user directly to the scan page!
        if(data.resultset.length > 0) {
          if(data.multipleCheckinOptions == 1) this.customerQRCodeOpened = true;
          else {
            // Only one QR code available for check-in, send user to QR scan page
            const params = $FunctionsHelper.getAllQueryParams(this.eventsWithQRAccess[0].products[0].qr);
			      setTimeout(() => $ViewHelper.router.navigate(`/customer-qr-checkin/idc/${params.idc}/ide/${params.ide}/ids/${params.ids}/t/${params.t}`, {animate: false}), 500);
          }
        }
      }});
    }
  },
};
</script>

<style>
.textPayAttention.arrowWhite .item-link .item-inner:before {color:#ffffff;}
.page.hasTopImage .topImage {
  position: static!important;
}

.page.hasTopImage .page-content {
  padding-top: calc(44px + var(--f7-safe-area-top))!important;
}

</style>

<style scoped>
table {
  border-spacing:0;
}

.wrapperQRCheckin {
  padding-left: 15px;
  padding-right: 15px;
}

.wrapperQRCheckin .iconScannerQR {
  padding-right: 15px;
  font-size: 15px;
}

.widthShopCard {
  max-width:341px;
}

.information {
  margin-left: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
}

.product {
  cursor: pointer;
}

.image {
  border: 1px solid #e7e7e7;
  height: 342px;
  object-fit: contain;
  background-size: cover;
	background-position: center;
}

.info {
  text-align: left;
  clear: both;
  margin: 5px;
  max-width: 340px;
}

.list.inset {
  margin-left: 15px!important;
  margin-right: 15px!important;
}

.cardCenter.withCompanyLogo {
  margin-top:35px;
}

a.link {
	color: rgb(6 124 216);
}

.title {
	font-size: 24px;
}

.bold {
	font-weight: bold;
}

.button {
	text-transform: none;
	font-size: 14px!important;
}

.red {
	color:#ff0016;
}

.wrapper {
	margin-top: 15px;
}

.wrapper:first-child {
	margin-top: 0;
}

.line {
	margin-top: 5px;
}

.info {
  text-align: left;
  clear: both;
  margin: 5px;
  max-width: 340px;
}

.card-header {font-weight: var(--f7-list-item-title-font-weight); color: var(--f7-list-item-title-text-color);}
.card-header.withIcon {
  justify-content: flex-start;
}

.card-footer:before {background-color: #fff;}

.theme {color: var(--theme-color);}

.themeButton {color: var(--btn-bg-color);}

.block:first-child {
  margin-top: 20px;
  margin-bottom: 0px;
}

.block-title:first-child {margin-top: 10px;}

.toolbar-top {
  top: 0!important;
  border-top: 1px solid #e7e7e7;
}

.toolbar-top:before {background:none;}

.companyInfo {
  background-color:#ffffff;
  height:50px;
  margin:auto;
}

i.icon.ionWeb {color: var(--navbar-bg-color); font-size: 30px;}
i.icon.ionInformationCircle {color: var(--navbar-bg-color); font-size: 30px;}
i.icon.ionMail {color: var(--navbar-bg-color); font-size: 30px;}
i.icon.ionCall {color: var(--navbar-bg-color); font-size: 30px;}

.ios .tabbar-labels a.link {
  padding-top:0px;
}
</style>
