<template>
	<popup :title="$LanguageHelper.translate('_PRODUCT_CATEGORY_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id_category: {required: false},
  },

	data() {
    return {
			opened: false,

			form: {
				fields: {
					name: {type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		};
  },

	methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			if(this.id_category) {
				data.id_invoice_product_category =  this.id_category;
			}

			$AjaxHelper.doRequest('invoiceProduct', 'saveProductCategory', Object.assign({idc: this.idc}, data), {callback: this._saved});
		},

		_productCategory(data) {
			this.form.values = data.resultset;
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Let our parent know there has been changes so it reloads the list!
				this.$emit('changed');

				// Close popup here, were done!
				this.opened = false;
			}
		},
	},

	created() {
		if(this.id_category) $AjaxHelper.doRequest('invoiceProduct', 'productCategory', {idc: this.idc, id_invoice_product_category: this.id_category}, {callback: this._productCategory});
	}
};
</script>
