<template>
	<popup :title="$LanguageHelper.translate('_FILTER_APPOINTMENTS_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form v-if="render" :params="form" @submitted="submitted" @changed="changed" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		filters: {type: Object},
  },

	data() {
		return {
      render: false,
			opened: false,

			form: {
				fields: {
					employees_divider: {type: 'divider', title: '_EMPLOYEES_INSTRUCTORS_', wrapperClass: 'employeesDivider', render: false},
					employees: {type: 'checkbox_group', items: [], render: false},
				},

				submit: {text: '_APPLY_', render: true},
				values: {},
			}
		}
	},

	methods: {
		changed(field) {
			// Fill the employees array with selected values
			if(field.name == 'employee') {
				this.filters.employees.selected = [];
				this.form.fields.employees.items.forEach((item) => {
					if(item.checked) this.filters.employees.selected.push(item.value);
				});
			}
		},

		submitted() {
			this.$emit('submitted');
			this.opened = false;
		},

    setFilters() {
			this.setEmployees();
			this.render = true;
    },

		setEmployees() {
			this.filters.employees.list.forEach((item) => {
				this.form.fields.employees.items.push({
					name: 'employee',
					title: item.name,
					value: item.id,
					checked: this.filters.employees.selected.indexOf(item.id) > -1,
					render: true,
				});
			});

			this.form.fields.employees_divider.render = this.filters.employees.list.length > 0;
			this.form.fields.employees.render = this.filters.employees.list.length > 0;
		},
	},

  created() {
		this.setFilters();
  },
};
</script>

<style>
.item-divider.employeesDivider {background: #fff!important;}
</style>
