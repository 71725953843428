<template>
	<div>
		<fixed-top>
			<list-form :params="form" @changed="changed"></list-form>
		</fixed-top>

		<f7-list inset>
			<f7-list-item accordion-item v-for="(sale, key) in sales" :key="key" :title="$FunctionsHelper.specialChars(sale.name)" :footer="sale.created_on">
				<f7-accordion-content>
		      <f7-block>
						<div v-if="isConnectedByCompany(sale)" class="item"><i><u>{{$LanguageHelper.translate('_PRODUCT_CONNECTED_BY_COMPANY_')}}</u></i></div>
						<div v-if="sale.description" class="item">{{$FunctionsHelper.specialChars(sale.description)}}</div>
						<div class="item" v-if="modules.agenda == 1 && sale.active !=1 || sale.installments">{{$LanguageHelper.translate('_AGENDA_STATUS_')}}: {{sale.active == 1 ? $LanguageHelper.translate('_ACTIVE_') : $LanguageHelper.translate('_INACTIVE_')}}</div>
						<div class="item" v-if="modules.payments == 1 && sale.installments == 1">{{$LanguageHelper.translate('_DIRECT_DEBIT_STATUS_')}}: {{sale.process_direct_debit == 1 ? $LanguageHelper.translate('_ACTIVE_') : $LanguageHelper.translate('_INACTIVE_')}}</div>
						<div class="item">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}: {{sale.number_items}}</div>
						<div class="item">{{$LanguageHelper.translate('_AMOUNT_')}}: &euro; {{sale.amount_in_vat}}</div>

						<template v-if="sale.installments == 1">
							<div v-if="sale.number_installments > 0" class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}} {{sale.number_installments}}</div>
							<div v-else class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{$LanguageHelper.translate('_CONTINUOUSLY_')}} </div>
						</template>

						<template v-if="sale.credits == 1">
							<div class="item">{{$LanguageHelper.translate('_NUMBER_CREDITS_')}}: {{sale.number_credits_org}}</div>
							<div class="item">{{$LanguageHelper.translate('_CREDITS_LEFT_')}}: {{sale.number_credits}}</div>
							<div class="item">{{$LanguageHelper.translate('_VALID_UNTIL_')}}: {{sale.expires_on_date == 0 ? $LanguageHelper.translate('_VALID_UNLIMITED_') : sale.expires_on_date}}</div>
						</template>
					</f7-block>

					<!-- Action buttons (refund, open direct debit, product form) -->
					<f7-block class="marginBottom10 marginTop10">
						<f7-row>
							<template v-if="modules.payments == 1 && !isConnectedByCompany(sale)">
								<!-- Direct Debit -->
								<template v-if="sale.installments == 1">
									<f7-col v-if="$UserHelper.hasAccess('payments', 'subscriptions', idc)">
										<f7-button @click="sale.directDebitExistingOpened=true" raised fill small>{{$LanguageHelper.translate('_MANAGE_DIRECT_DEBIT_')}}</f7-button>

										<!-- Manage Direct Debit popup here -->
										<manage-direct-debit v-if="sale.directDebitExistingOpened"
											:idc="idc"
											:openedViaCustomer="true"
											:params="{id: sale.id_invoice_product_sale, id_invoice_product_sale_group: sale.id_invoice_product_sale_group}"
											@changed="load"
											@close="sale.directDebitExistingOpened=false"
										/>
									</f7-col>
								</template>

								<!-- Refund -->
								<template v-else-if="sale.id_invoice > 0">
									<f7-col v-if="$UserHelper.hasAccess('payments', 'refund', idc)">
										<f7-button @click="sale.refundOpened=true" raised fill small>{{$LanguageHelper.translate('_REPAY_')}}</f7-button>

										<!-- Manage refund component -->
										<manage-sale-refund v-if="sale.refundOpened" :idc="idc" :id_invoice="sale.id_invoice" @close="sale.refundOpened=false" />
									</f7-col>
								</template>
							</template>

							<!-- Product form if agenda is enabled and user has access-->
							<f7-col v-if="modules.agenda == 1 && $UserHelper.hasAccess('invoice_product', 'customerForm', idc) && (sale.installments == 1 || sale.credits == 1)">
								<f7-button @click="sale.productFormOpened=true" raised fill small>{{$LanguageHelper.translate('_PRODUCT_CUSTOMER_FORM_')}}</f7-button>

								<!-- Make agenda changes to product-->
								<manage-customer-product-form v-if="sale.productFormOpened"
									:title="$LanguageHelper.translate('_CHANGE_CONNECTED_PRODUCT_')"
									:idc="idc"
									:id_customer="id_customer"
									:id_product="sale.id_invoice_product"
									:id_sale="sale.id_invoice_product_sale"
									@changed="load"
									@close="sale.productFormOpened=false"
								/>
							</f7-col>
						</f7-row>
					</f7-block>
	    	</f7-accordion-content>
			</f7-list-item>
		</f7-list>

		<!-- We can't use the fab click here directly because we need to fetch data first before we can open the popup -->
		<manage-direct-debit-data v-if="direcDebitNewOpened"
			:idc="idc"
			:id_customer="id_customer"
			:openedViaCustomer="true"
			:directDebit="directDebitNewData"
			@changed="load"
			@close="direcDebitNewOpened=false"
		/>

		<!-- Connect a new free product if FAB is clicked -->
		<manage-customer-product-form v-if="fab.buttons.connectFreeProduct.clicked"
			:title="$LanguageHelper.translate('_CONNECT_FREE_PRODUCT_')"
			:idc="idc"
			:id_customer="id_customer"
			@changed="load"
			@close="fab.buttons.connectFreeProduct.clicked=false"
		/>
	</div>
</template>

<script>
import ManageDirectDebitData from '../manage-direct-debit-data.vue'; // Adding a new direct debit
import ManageDirectDebit from '../manage-direct-debit/index.vue'; // Open existing direct debit info
import ManageSaleRefund from '../manage-sale-refund.vue';
import ManageCustomerProductForm from '../manage-customer-product-form.vue';
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';

export default {
	components: {
    'manage-direct-debit-data': ManageDirectDebitData,
    'manage-direct-debit': ManageDirectDebit,
		'manage-sale-refund': ManageSaleRefund,
		'manage-customer-product-form': ManageCustomerProductForm,
		'list-form': ListForm,
		'fixed-top': FixedTop,
	},

	props: {
		idc: {required: true},
		id_customer: {required: true},
		fab: {required: true},
	},

	data() {
		return {
			render: false,
			sales: {},
			modules: {},
			direcDebitNewOpened: false,
			directDebitNewData: {},
			form: {
				fields: {
					type: {type: 'select', name: 'type', title: '_TYPE_', render: true, options: []},
				}
			}
		};
	},

	computed: {
		fabCreateDirectDebitClicked() {
			return this.fab.buttons.createDirectDebit.clicked;
		},

		productType() {
			return (Object.values(this.form.fields.type.options).find(option => option.selected)).value;
		}
	},

	methods: {
		changed(field, val) {
			// Reload the page, with selected type!
			if(field.name == 'type') this.load();
		},

		load() {
			$AjaxHelper.doRequest('invoiceProduct', 'productSales', {idc: this.idc, id_appuser: this.id_customer, type: this.productType}, {callback: this._sales});
		},

		isConnectedByCompany(sale) {
			return sale.saved_by_gym == 1;
		},

		_sales(data) {
			// Save the modules
			this.modules = data.modules;

			// Set opened variabled for different screens!
			data.resultset.forEach((obj, key) => {
				obj.directDebitExistingOpened = false;
				obj.refundOpened = false;
				obj.productFormOpened = false;
			});

			// Set the sales again!
			setTimeout(() => this.sales = data.resultset, 5);

			// Only render FABs user has access
			this.fab.buttons.connectFreeProduct.render = data.modules.agenda == 1 && $UserHelper.hasAccess('invoice_product', 'customerForm', this.idc) ? true : false;
			this.fab.buttons.createDirectDebit.render = data.modules.payments == 1 && $UserHelper.hasAccess('payments', 'subscriptions', this.idc) ? true : false;
		}
	},

	watch: {
		fabCreateDirectDebitClicked(val) {
			if(!val) return;

			$AjaxHelper.doRequest('payment', 'subscriptionNew', {idc: this.idc}, {callback: (data) => {
				// Store the data the manage direct debit component needs!
				this.directDebitNewData['countries'] = data.resultset.countries;
				this.directDebitNewData['languages'] = data.resultset.languages;
				this.directDebitNewData['products'] = data.resultset.products;
				this.directDebitNewData['periods'] = data.resultset.periods;
				if(data.agendaEnabled) this.directDebitNewData['agendaEnabled'] = data.agendaEnabled;

				// Open the popup now!
				this.direcDebitNewOpened = true;

				// Set the fab clicked back to false!
				this.fab.buttons.createDirectDebit.clicked = false;
			}});
		}
	},

	created() {
		this.form.fields.type.options.push({
			label: '_ONGOING_PRODUCTS_',
			value: 'ongoing',
			selected: true
		});

		this.form.fields.type.options.push({
			label: '_OTHER_PRODUCTS_',
			value: 'other',
			selected: false
		});

		this.load();
	}
};
</script>
