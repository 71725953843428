<template>
  <page :title="$LanguageHelper.translate('_MANAGEMENT_')" @initiated="getModulesCompany">
    <f7-list v-if="showSummary" accordion-list inset>
      <f7-list-item>
        <f7-swiper v-if="swiperItems" pagination 
          :slidesPerView="2"
          :spaceBetween="10"
          :slideToClickedSlide="true"
          :invert="true"
          :initialSlide="5"
        >
          
          <f7-swiper-slide v-for="(swiper, key, index) in swiperItems" :key="index" class="fontBlack">
            <table class="content marginTop5">
              <tr>
                <td colspan="2">{{swiper.date}}</td>
              </tr>
              <tr>
                <td><b>{{swiper.total_amount}}</b></td>
                <td>{{swiper.percentage}}
                  <f7-icon v-if="swiper.plus == 1" icon="icon ionArrowup greenIcon"></f7-icon>
                  <f7-icon v-else icon="icon ionArrowDown redIcon"></f7-icon>
                </td>
              </tr>
            </table>
          </f7-swiper-slide>
        </f7-swiper>
      </f7-list-item>

      <f7-list-item v-for="(summary, key, index) in summaryItems" :key="index" accordion-item :title="summary.title" class="smallTitle summaryItem">
        <f7-accordion-content>
          <div v-for="(item, key, index) in summary.items" :key="index" class="marginBottom10 smallTitle">
            <div v-html="item.name"></div>

            <!-- Loop -->
            <div v-for="(extraInfo, key, index) in item.extra_info" :key="index" class="colorGrey">
              <template v-if="key == 'sale_info'">
                <div v-for="(saleInfo, key2, item) in item.extra_info.sale_info" :key="item" class="colorGrey marginBottom10">
                  <div v-for="(info, key3, item2) in saleInfo" :key="item2" v-html="info"></div>
                </div>
              </template>
              <template v-else>
                {{extraInfo}}
              </template>
            </div>
          </div>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item>
        <f7-button v-if="dashboardButton" :href="dashboardButton" fill small raised class="widthFull">{{$LanguageHelper.translate('_GO_TO_DASHBOARD_')}}</f7-button>
      </f7-list-item>
    </f7-list>

		<f7-list inset>
      <f7-list-item v-for="(moduleCompany, key, index) in modulesCompany" :key="index" :title="$FunctionsHelper.specialChars(moduleCompany.name)" :link="moduleCompany.link">
        <template #media><f7-icon :icon="`${moduleCompany.icon}`"></f7-icon></template>
      </f7-list-item>
    </f7-list>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },

	props: {
		idc: {required: true},
	},

  data() {
    return {
      modulesCompany: [],
      swiperItems: [],
      summaryItems: [],
      showSummary: false,
      dashboardButton: '',
    };
  },

  methods: {
		getModulesCompany() {
			$AjaxHelper.doRequest('gym', 'getModulesEnabledGym', {idc: this.idc, app_admin_module: 1, 'agenda_deprecated_load': 1, 'summary': 1}, {callback: this._modulesCompany});
    },

		//Build the list with enabled modules!
		_modulesCompany(data) {
      var ignoreModules = ['nutrition', 'transfer']; // These we don't wanna show anymore!
			var agendaPosition = 0;

			// Instruction video's first
			if(!data.hideInstructionVideos || (data.hideInstructionVideos && data.hideInstructionVideos != 1)) {
				this.modulesCompany.push({name: $LanguageHelper.translate('_INSTRUCTION_VIDEOS_'), link: '/instruction-videos/idc/' + this.idc, icon: 'iconTV'});
			}

			//User allowed to the customers module?
			if($UserHelper.hasAccess('users', 'show')) {
				agendaPosition = 1;
				this.modulesCompany.push({name: $LanguageHelper.translate('_CUSTOMERS_'), link: '/manage-customers/idc/' + this.idc, icon: 'ionPeople'});
			}

			//User always can see the agenda, just as everyone!
			if(data.resultset && data.resultset.length > 0) {
				var that = this;
				data.resultset.forEach(function (obj, index) {
					obj.link = '/manage-' + obj.name_system + '/idc/' + that.idc;

          //exlude dashboard modules if summary is there and get link for button
          if(data.summary && data.summary.resultset) {
            if(data.summary.resultset.swiper && data.summary.resultset.summary) {
              if(obj.name_system == 'dashboard') {
                that.dashboardButton = obj.link;
                return;
              }
            }
          }

					if(obj.name_system == 'pushmessage') 	obj.icon = 'iconSend'; //  Hardcode overwrite with the send icon
          if($UserHelper.hasAccess(obj.name_system) && obj.name_system != 'agenda' && !ignoreModules.includes(obj.name_system)) that.modulesCompany.push(obj);
          
          //Set agenda on first or second position
					if(obj.name_system == 'agenda') {
            obj.link = `/agenda-routing/idc/${that.idc}/manage/1`;
						that.modulesCompany.splice(agendaPosition, 0, obj);
						that.modulesCompany.join();
					}
				});
			}

			// Show link to FAQ
			this.modulesCompany.push({name: `${$LanguageHelper.translate('_FAQ_')}`, link: `/faq/idc/${this.idc}/no-styles/1`, icon: 'ionHelpCircle'});
			
      // Promote Pay n Plan
      if(data.show_promotion && data.show_promotion == 1) this.modulesCompany.push({name: `${$LanguageHelper.translate('_EARN_MONEY_WITH_')} ${$Config.apps[$Config.app.currentApp].displayName}`, link: '/promote-us/idc/' + this.idc, icon: 'iconMegaPhone'});

      if(data.summary && data.summary.resultset) {
				if (data.summary.resultset.swiper && data.summary.resultset.summary) {
          this.swiperItems = data.summary.resultset.swiper.reverse();
          this.summaryItems = data.summary.resultset.summary;
          // Set showSummary to true if both are true!
        	this.showSummary = true;
        }
			}
		}
  },
};
</script>

<style>
.swiper-slide {border-left: 4px solid var(--theme-color);}
.list .summaryItem .item-inner, .list .summaryItem .item-content {
  min-height: 15px;
  max-height: 30px;
}
</style>
