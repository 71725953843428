<template>
	<popup
		:title="$LanguageHelper.translate('_ARCHIVE_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
		>
		<f7-list inset v-show="show">
			<f7-list-item accordion-item v-for="(event, key) in events.list"
				:key="key"
				:title="$FunctionsHelper.specialChars(event.name)"
				:footer="event.created_on"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_CREATED_ON_')}}: {{event.created_on}}</div>
						<div class="item" v-if="event.date_start">{{$LanguageHelper.translate('_DATE_START_EVENT_')}}: {{event.date_start}}</div>
						<div class="item" v-if="event.date_end">{{$LanguageHelper.translate('_DATE_END_')}}: {{event.date_end}}</div>
					</f7-block>

					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('qr_access', 'remove', idc)">
								<f7-button @click="restore(event.id_qr_event)" raised fill small>{{$LanguageHelper.translate('_RESTORE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
			show: true,
			events: {
				list: []
			}
		};
  },

	methods: {
		load() {
			$AjaxHelper.doRequest('eventWithScanAccess', 'archivedEventItems', {idc: this.idc}, {callback: this._events});
		},

		renderList(items) {
			this.events.list = items;
			this.show = true;
		},

		restore(id) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('eventWithScanAccess', 'archiveEvent', {idc: this.idc, id: id, archive: 0}, {callback: this._restored})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		_events(data) {
			// Empty the list first
			this.events.list = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj, key) => {obj.opened = false});

				this.renderList(data.resultset);
			}
		},

		_event(data) {
			// Emit added event back to parent so this items get's added back to the list!
			this.$emit('added', data.resultset);

			// Reload the archive list
			this.load();
		},

		_restored(data) {
			$AjaxHelper.doRequest('eventWithScanAccess', 'getEvent', {idc: this.idc, id: data.params_send.id}, {callback: this._event});
		}
	},

	created() {
		// Get the products here!
		this.load();
	}
};
</script>
