<template>
	<page
		:title="title"
		:back="internalPage"
		:panel="internalPage"
		:language="!internalPage"
		:visible="visible"
		>

		<div class="centerScreen">
			<div id="qrcode"></div>
		</div>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';

// => https://github.com/ushelp/EasyQRCodeJS
const QRCode = require('easyqrcodejs');

export default {
	components: {
    'page': Page,
		'list-form': ListForm,
  },

	props: {
		idc: {required: false},
		ide: {required: false},
		ids: {required: false},
		t: {required: false},
  },

  data() {
    return {
			params: {
				idc: null,
				ide: null,
				ids: null,
				t: null,
			},

			internalPage: true,
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href), /* Query params from URL, there might be a company id in there */
			visible: false,
			title: $LanguageHelper.translate('_CHECKIN_WITH_QR_'),
		};
  },

	methods: {
		go() {
			setTimeout(() => {
				// Prevent double canvas elements
				$('#qrcode').empty();
				
				new QRCode(document.getElementById('qrcode'), {
					text: this.buildQRString()
				});
			}, 100);
		},

		setParams() {
			// External page, use the query params from url to set the params
			if(this.queryParams && this.queryParams.idc) {
				this.params = {
					idc: this.queryParams.idc,
					ide: this.queryParams.ide,
					ids: this.queryParams.ids,
					t: this.queryParams.t,
				};

				// set internal page to true for page behaviour
				this.internalPage = false;
			} 
			// Internal page, use the props to set the params
			else {
				this.params = {
					idc: this.idc,
					ide: this.ide,
					ids: this.ids,
					t: this.t,
				}
			}
		},

		buildQRString() {
			const json = JSON.stringify({
				idc: this.params.idc,
				ide: this.params.ide,
				ids: this.params.ids,
				t: this.params.t
			});

			return json;
		}
	},

	created() {
		// Set the params we need for the QR string to be created!
		this.setParams();

		// External page => Hack => Needs timeout for it to work, otherwise it will show logo in the navbar
		if(!this.internalPage) {
			setTimeout(() => {
				this.visible = true;
				this.go();
			}, 100);
		}	
		// Internal page, regular stuff
		else {
			this.visible = true;
			this.go();
		}
	}
};
</script>

<style scoped>
.centerScreen {
	background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 44px); /* Window height - 44 px for the nav bar */
}

.wrapperQR {
	padding: 45px;
}
</style>
