<template>
	<popup
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<list-form v-show="visible" :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		title: {required: true},
  },

	data() {
    return {
			opened: false,
			visible: false,

			form: {
        fields: {
					warning: {name: 'warning', type: 'description', text: '_NO_REFUNDING_MESSAGE_', class: 'textPayAttention', render: true},
					cash_balance: {name: 'cash_balance', type: 'description', text: '', class: 'bold', render: true},
					amount_to_withdrawal: {name: 'amount', type: 'input', label: '_AMOUNT_TO_WITHDRAWAL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					reason_withdrawal: {name: 'reason', type: 'input', label: '_REASON_WITHDRAWAL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
        },

				submit: {text: '_SAVE_', render: true},
				values: {},
      }
    };
  },

	methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			$AjaxHelper.doRequestExtraLogin('payment', 'saveWithdrawal', Object.assign({idc: this.idc, action: 'withdrawal'}, data), {callback: this._withdrawalSubmitted});
		},

		_withdrawalSubmitted(data) {
			if(data.status == "OK") {
				this.$emit('withdrawalSubmitted');
				this.opened = false;
			}
		},

		_manageWithdrawal(data) {
			if(data.resultset && data.resultset.cash_balance) {
				this.form.fields.cash_balance.text = `${$LanguageHelper.translate('_IN_REGISTER_')}: ${data.resultset.cash_balance}`;
				this.visible = true;
			}
		}
	},

	created() {
		$AjaxHelper.doRequest('payment', 'getWithdrawal', {idc: this.idc, action: 'manage-withdrawal'}, {callback: this._manageWithdrawal});
	}
};
</script>
