<template>
  <page
		:title="$LanguageHelper.translate('_REGISTER_PAYMENT_')"
		:toolbar="toolbar"
    @sort-selected="setSortSelected"
		@searched="setSearched"
		@infinite-scroll-page-end="pageEndReached=true"
		@tab-clicked="pageEndReached=false"
		@initiated="go"
	>
		<template #register v-if="toolbar.tabs.register && toolbar.tabs.register.active">
			<tab-register :idc="idc" />
		</template>

		<template #ledger v-if="toolbar.tabs.ledger && toolbar.tabs.ledger.active">
			<tab-ledger
        :idc="idc"
        :sort-selected="sortSelected"
				:searched="searched"
        :fab="fab.ledger"
        :page-end-reached="pageEndReached"
        @page-end-loaded="pageEndReached=false"
      />
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabRegister from './tab-register.vue';
import TabLedger from './tab-ledger.vue';

export default {
  components: {
    'page': Page,
    'tab-register': TabRegister,
    'tab-ledger': TabLedger,
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
      sortOptions: null,
      sortSelected: 'date_sale_desc',
      searched: '',
      pageEndReached: false,

			toolbar: {
				tabs: {
					register: {text: $LanguageHelper.translate('_REGISTER_PAYMENT_'), icon: 'icon ionCalculator', render: $UserHelper.hasAccess('payments', 'pin', this.idc), active: false},
					ledger: {text: $LanguageHelper.translate('_LEDGER_'), icon: 'icon ionLedger', infinite: true, search: true, sortOptions: null, render: $UserHelper.hasAccess('payments', 'ledger', this.idc), active: false},
				}
			},

      fab: {
				ledger: {
					position: 'right-bottom',
					buttons: {
						export: {name:'export', label: $LanguageHelper.translate('_EXPORT_SELECTED_PERIOD_'), icon: 'icon ionDownload', render: $UserHelper.hasAccess('payments', 'financial', this.idc) && !$Config.app.onDevice, clicked: false},
            withdrawal: {name:'withdrawal', label: $LanguageHelper.translate('_WITHDRAWAL_'), icon: 'icon ionArrowRoundUp', render: $UserHelper.hasAccess('payments', 'withdrawal', this.idc), clicked: false},
            deposit: {name:'deposit', label: $LanguageHelper.translate('_MANAGE_DEPOSIT_'), icon: 'icon ionArrowRoundDown', render: $UserHelper.hasAccess('payments', 'withdrawal', this.idc), clicked: false},
					}
				},
			}
    };
  },

  methods: {
		setSearched(val) {
			this.searched = val;
		},

		setSortSelected(val) {
			this.sortSelected = val;
		},

		go() {
			// Set floating action buttons per tab
			this.toolbar.tabs.ledger.fab = this.fab.ledger;

	    // Set sort options for the revenue tab
			this.toolbar.tabs.ledger.sortOptions = {
				title: '_SORT_AND_FILTER_',
				options: {
					date_transaction_desc: {value: 'date_transaction_desc', label: '_DATE_DESC_', selected: true},
					date_transaction_asc: {value: 'date_transaction_asc', label: '_DATE_ASC_', selected: false},
				}
			};

			if(this.toolbar.tabs.register.render) this.toolbar.tabs.register.active = true;
			else if(this.toolbar.tabs.ledger.render) this.toolbar.tabs.ledger.active = true;
		}
	}
};
</script>
