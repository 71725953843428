<!-- This stays fixed at the top! The padding of your page-content must be set per top content, depends on the height-->
<template>
	<div class="fixedTop">
		<div class="wrapper">
			<div class="content">
				<slot></slot>
				<div class="spacer"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		resized: {type: Boolean, required: false},
	},

	methods: {
		setSizes() {
			// Remove the earlier inserted style tag first!
			const fixName = 'fixedTopStyle';
			$(`#${fixName}`).remove();

			// Calculate and set padding top the page-content element should be depending on the height of the fixed top!
			const elFixedTopContent = $('.fixedTop .wrapper');

			if(!elFixedTopContent[0]) return;

			const elPageContent = elFixedTopContent.closest('.page-content').addClass(fixName);

			// Set the inital padding
			if(!elPageContent.data('padding')) {
				parseInt(elPageContent.css('padding-top').replace('px', ''));
				elPageContent.data('padding', parseInt(elPageContent.css('padding-top').replace('px', '')));
			}

			const elPageContentPadding = elPageContent.data('padding');
			const elFixedTopHeight = parseInt(elFixedTopContent.height());
			const paddingTop = elFixedTopHeight + elPageContentPadding;

			// Insert the style element with new padding into the page content!
			elPageContent.prepend(`<style id="${fixName}">.fixedTopStyle{padding-top:${paddingTop}px;}</style>`);
		}
	},

	watch: {
		// Our parent will let us know when we need to recalculate and call setSizes!
		resized() {
			setTimeout(() => this.setSizes(), 100);
		}
	},

	mounted() {
		setTimeout(() => this.setSizes(), 100);

		$(window).resize(() => this.setSizes());
	}
}
</script>
