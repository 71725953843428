<template>
  <popup :title="$LanguageHelper.translate('_ACTIVATE_MODULE_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<f7-card inset>
			<f7-card-header>{{module.name}}</f7-card-header>

			<f7-card-content>
				<div>{{module.info}}</div>
				<div class="marginTop10">{{$LanguageHelper.translate('_ACTIVATE_MODULE_PREFIX_')}} &euro; {{module.price}} {{$LanguageHelper.translate('_ACTIVATE_MODULE_POSTFIX_')}}</div>
			</f7-card-content>

      <f7-block strong>
      	<f7-button raised small fill @click="activeModule">{{$LanguageHelper.translate('_ACTIVATE_')}}</f7-button>
      </f7-block>
    </f7-card>
  </popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
  components: {
    'popup': Popup,
  },

	props: {
    module: {required: true},
    idc: {required: true},
	},

  data() {
    return {
      opened: false,
    }
  },

	methods: {
		activeModule() {
			var buttons = [
				// Activate my module
				{text: $LanguageHelper.translate('_CONFIRM_'),

				onClick: () => {
					$AjaxHelper.doRequest('Gym', 'activateModule', {idc: this.idc, name: this.module.name_system}, {callback: (data) => {
						this.opened = false;
					}});
				}},

				// All fine, don't do anything
				{text: $LanguageHelper.translate('_CANCEL_'), onClick: () => {}},
			];

			$ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),$LanguageHelper.translate('_YOUR_MONTHLY_AMOUNT_WILL_BE_CHANGED_'));
		}
	}
};
</script>
