<template>
	<form v-show="render">
		<f7-list inset>
			<f7-list-input v-for="(picker, key) in colorPickers"
				type="text"
				:key="key"
				:label="picker.label"
		    :placeholder="picker.placeholder"
				:id="picker.inputEl"
				:value="picker.value"
				:inputReadOnly="false"
			 >
				<template #media><f7-icon class="icon colorPickerIcon" :id="picker.targetEl"></f7-icon></template>
			</f7-list-input>
		</f7-list>

		<f7-block>
			<f7-button @click.prevent="submitted" raised fill>{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
		</f7-block>
	</form>
</template>

<script>
import StylesHelper from '../../js/helpers/stylesHelper';

export default {
	props: {
		idc: {required: true}
	},

  data() {
    return {
			render: false,

			colorPickers: {
				theme_color: {label: $LanguageHelper.translate('_THEME_COLOR_'), inputEl: 'themeInput', targetEl: 'themeTarget', value: '', startValue: '', picker: null},
				navbar_bg_color: {label: $LanguageHelper.translate('_BG_COLOR_NAVBAR_'), inputEl: 'navbarBGInput', targetEl: 'navbarBGTarget', value: '', startValue: '', picker: null},
				navbar_color: {label: $LanguageHelper.translate('_COLOR_NAVBAR_'), inputEl: 'colorNavbarInput', targetEl: 'colorNavbarTarget', value: '', startValue: '', picker: null},
				btn_bg_color: {label: $LanguageHelper.translate('_BG_COLOR_BUTTONS_'), inputEl: 'buttonsBGInput', targetEl: 'buttonsBGTarget', value: '', startValue: '', picker: null},
				btn_color: {label: $LanguageHelper.translate('_COLOR_BUTTONS_'), inputEl: 'colorButtonsInput', targetEl: 'colorButtonsTarget', value: '', startValue: '', picker: null}
			}
    };
  },

	mounted() {
		// Get company styles here!
		$AjaxHelper.doRequest('Gym', 'getCompanyStyles', {idc: this.idc}, {callback: this._companyStyles});
	},

  methods: {
		submitted() {
			var data = {};
			$.each( this.colorPickers, (key, obj) => {
				data[key] = obj.value;
			});

			$AjaxHelper.doRequest('Gym', 'saveCompanyStyles', $.extend({idc: this.idc}, data));
		},

		_companyStyles(data) {
			// Everything is loaded, make the page visible now
			this.render = true;

			var that = this;
			$.each( that.colorPickers, (key, obj) => {
				if(that.colorPickers[key]) {
					that.colorPickers[key].picker = $f7.colorPicker.create({
					  inputEl: '#' + obj.inputEl,
					  targetEl: '#' + obj.targetEl,
					  targetElSetBackgroundColor: true,
						sliderLabel: true,
						sliderValue: true,
						sliderValueEditable: true,
						hexLabel: true,
		  			hexValueEditable: true,
						closeByBackdropClick: false,
						closeByOutsideClick: false,
						navbarTitleText: $LanguageHelper.translate('_PICK_YOUR_COLOR_'),
						navbarCloseText: $LanguageHelper.translate('_DONE_'),
						modules: ['sb-spectrum', 'hue-slider', 'rgb-sliders', 'alpha-slider', 'hex'],
					  openIn: 'popup',
						on: {
							change() {
								// Update form field value and write the CSS variable to show example!
								obj.value = 'rgba(' + this.getValue().rgba.join(',') + ')';
								StylesHelper.writeCSSVar(key, obj.value);
							},

							close() {
								var buttons = [
									// Reset start value!
									{text: $LanguageHelper.translate('_CANCEL_'),
									onClick: function() {
										StylesHelper.writeCSSVar(key, that.colorPickers[key].startValue);
										that.colorPickers[key].picker.setValue({rgba: $FunctionsHelper.colors.getRGBArrayFromString(that.colorPickers[key].startValue)});
									}},

									// Not happy with chosen color, re-open the picker
									{text: $LanguageHelper.translate('_NO_'), onClick: function() {that.colorPickers[key].picker.open()}},

									// All fine, don't do anything
									{text: $LanguageHelper.translate('_YES_'), onClick: function() {}},
								];

								$ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),$LanguageHelper.translate('_QUESTION_COLOR_OK_'));
							}
						}
					});
				}

				// Set current value from DB
				if(data.resultset[key]) {
					that.colorPickers[key].startValue = data.resultset[key];
					that.colorPickers[key].picker.setValue({rgba: $FunctionsHelper.colors.getRGBArrayFromString(data.resultset[key])});
				}
				// Set default values from application
				else {
					that.colorPickers[key].startValue = StylesHelper.getCSSvarValue(key, true);
					that.colorPickers[key].picker.setValue({rgba: $FunctionsHelper.colors.getRGBArrayFromString(StylesHelper.getCSSvarValue(key, true))});
				}
			});
		}
	}
};
</script>
