<template>
  <page
		:title="$LanguageHelper.translate('_SALES_')"
		:infinite="true"
		:search="true"
		:sort-options="sortOptions"
		@infinite-scroll-page-end="pageEndReached"
		@searched="searched"
		@sort-selected="sortSelected"
		@initiated="go"
		class="manageSales"
		>

		<fixed-top :resized="sales.info.render" v-show="sales.info.render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
					<div>&euro; {{sales.info.amount ? sales.info.amount : 0}} {{$LanguageHelper.translate('_SOLD_OVER_SELECTED_PERIOD_')}}</div>
				</f7-list-item>
			</f7-list>

			<list-form :params="form" @changed="changed"></list-form>
		</fixed-top>

		<f7-list media-list inset v-if="render">
			<f7-list-item accordion-item v-for="(sale, key) in sales.list"
				:key="key"
				:title="sale.saleInfo.username.length > 0 ? $FunctionsHelper.specialChars(sale.saleInfo.username) : $LanguageHelper.translate('_INDIVIDUAL_SALES_')"
				:after="`€ ${sale.saleInfo.amount}`"
				:subtitle="sale.saleInfo.created_on"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item" v-if="sale.saleInfo.user_connected && sale.saleInfo.user_connected.length > 0"><b>{{$LanguageHelper.translate('_CONNECTED_TO_')}}:</b><br />{{$FunctionsHelper.specialChars(sale.saleInfo.user_connected)}}</div>

						<div class="item">
							{{$LanguageHelper.translate('_LINK_TO_ORDER_DETAILS_PAGE_')}}&nbsp;&nbsp;<a class="link theme" @click="copyToClipboard(sale.saleInfo.order_details_url)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a><br>
							<a :href="sale.saleInfo.order_details_url" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{sale.saleInfo.order_details_url}}</a>
						</div>

						<!-- Order detail token -->
		 				<div class="item marginTop10"><b>{{$LanguageHelper.translate('_TOKEN_ORDER_DETAILS_PAGE_')}}</b>&nbsp;&nbsp;<a class="link theme" @click="copyToClipboard(sale.saleInfo.order_details_token)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a></div>
		 				<div class="item">{{sale.saleInfo.order_details_token}}</div>

						<template v-if="sale.products && sale.products.length > 0">
							<div v-for="(product, key) in sale.products" :key="key" class="productWrapper">
								<div class="item"><b>{{product.name}}</b></div>
								<div class="item">{{$FunctionsHelper.specialChars(product.description)}}</div>
								<div class="item">{{$LanguageHelper.translate('_SALE_NUMBER_')}}: {{sale.saleInfo.sale_id}}</div>
								<div class="item">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}: {{product.number_items ? product.number_items : 1}}</div>
								<div class="item">{{$LanguageHelper.translate('_VAT_PERCENTAGE_')}}: {{product.vat_percentage}}%</div>
								<div class="item">{{$LanguageHelper.translate('_AMOUNT_')}}: &euro; {{product.amount}}</div>
							</div>
						</template>
					</f7-block>

					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('payments', 'financial', idc)">
								<f7-button @click="downloadInvoice(sale)" raised fill small>{{$LanguageHelper.translate('_DOWNLOAD_INVOICE_')}}</f7-button>
							</f7-col>

							<f7-col v-if="$UserHelper.hasAccess('payments', 'refund', idc) && sale.saleInfo.id_payment_status == 2">
								<f7-button @click="sale.opened=true" raised fill small>{{$LanguageHelper.translate('_REPAY_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>

				<!-- Manage refund component -->
				<manage-sale-refund v-if="sale.opened" :idc="idc" :id_invoice="sale.saleInfo.id_invoice" @close="sale.opened=false" />
			</f7-list-item>
		</f7-list>
	</page>
</template>

<script>
import CopyToClipboard from '../js/mixins/copyToClipboard';
import Page from '../components/smart/page.vue';
import FixedTop from '../components/smart/fixed-top.vue';
import ListForm from '../components/smart/list-form.vue';
import ManageSaleRefund from '../popups/manage-sale-refund.vue';

export default {
	mixins: [CopyToClipboard],

  components: {
    'page': Page,
		'fixed-top': FixedTop,
		'list-form': ListForm,
		'manage-sale-refund': ManageSaleRefund
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			render: false,
			infiniteDone: false,

			form: {
				fields: {
					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: false,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},

			// Sort options
			sortOptions: {
				title: '_SORT_AND_FILTER_',
				options: {
					date_sale_asc: {value: 'date_sale_asc', label: '_DATE_ASC_', selected: false},
					date_sale_desc: {value: 'date_sale_desc', label: '_DATE_DESC_', selected: true},
				}
			},

			// Sales data
			sales: {
				info: {
					render: false,
					amount: 0
				},

				list: [],
			}
    };
  },

	computed: {
    orderBy() {
			return (Object.values(this.sortOptions.options).find(option => option.selected)).value;
		},

		dateStart() {
			return this.form.fields.date.fields.date_start.value;
		},

		dateEnd() {
			return this.form.fields.date.fields.date_end.value;
		}
  },

	methods: {
		downloadInvoice(sale) {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoice&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&id=${sale.saleInfo.id_invoice}&language=${$LanguageHelper.current}`);
		},

		changed(field) {
			// Initial call is also send from here (change event is fired whenever date values are set)!
			if(field.name == 'dateRange') this.load(true);
		},

		sortSelected() {
			this.load(true);
		},

		pageEndReached() {
			this.load();
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.sales.list = [];
			}

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getSales', this.constructRequestData(), {callback: this[firstOrReset ? '_salesInit' : '_sales']});
		},

		searched(val) {
			// Empty current list
			this.sales.list = [];

			// Search for sales
			if(val.length > 0) {
				$AjaxHelper.doRequest('payment', 'searchSales', Object.assign({search: val}, this.constructRequestData()), {callback: this._salesInit});
			}
			// No input, reset the list!
			else {
				this.load(true);
			}
		},

		constructRequestData() {
			return {
				idc: this.idc,
				order: this.orderBy,
				date_range: {
					 start: this.dateStart,
					 end: this.dateEnd
				}
			};
		},

		storeData(data, init) {
			if(init) {
				this.sales.info.amount = data.resultset.amount;
				this.sales.info.render = true;
			}

			if(data.resultset.sales && data.resultset.sales.length > 0) {
				data.resultset.sales.forEach((obj, key) => {obj.opened = false});
				this.sales.list.push(...data.resultset.sales);
			} else this.infiniteDone = true;
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getSales');
		},

		_salesInit(data) {
			this.storeData(data, true);
		},

		_sales(data) {
			this.storeData(data);
		},

		go() {
			this.form.fields.date.render = true;
			this.render = true;
		}
	}
};
</script>

<style>
.productWrapper {margin-top: 10px;}
</style>
