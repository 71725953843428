<template>
	<popup :title="$LanguageHelper.translate('_COLLECTION_AGENCY_INFO_TITLE_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<div v-if="render" class="pageContent">
			<f7-card>
				<f7-card-content v-if="info.collection_agency_enabled == 1">
					<i class="icon ionCheckmarkCircle colorGreenOK" />{{this.info.title}}
					<div class="marginTop5 nl2br">{{this.info.text}}</div>
					<div class="marginTop10">
						<f7-link fill raised @click="toggleCollectionAgencyStatus" class="theme link"><b>{{$LanguageHelper.translate('_DISABLE_MODULE_')}}...</b></f7-link>
					</div>
				</f7-card-content>

				<f7-card-content v-if="info.collection_agency_enabled == 0">
					<div class="bold">{{this.info.title}}</div>
					<div class="marginTop10 nl2br">{{this.info.text}}</div>
					<div class="marginTop5">
						<div class="marginTop25"><f7-button small raised fill class="button" @click="toggleCollectionAgencyStatus">{{$LanguageHelper.translate('_ENABLE_MODULE_')}}</f7-button></div>
					</div>
				</f7-card-content>
			</f7-card>
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm,
	},

	props: {
		idc: {required: true},
  },

	data() {
		return {
      render: false,
			opened: false,

			info: {
				collection_agency_enabled: 0,
				title: '',
				text: '',
			},
		}
	},

	methods: {
		toggleCollectionAgencyStatus() {
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('gym', 'toggleCollectionAgencyStatus', {idc: this.idc, enable: this.info.collection_agency_enabled == 0 ? 1 : 0}, {callback: (data) => {
					if(data.status == 'OK') {
						this.opened = false;
						this.$emit('changed');
					}
				}});
			}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},
	},

  created() {
		$AjaxHelper.doRequest('gym', 'getGymCollectionAgencyInfo', {idc: this.idc}, {callback: (data) => {
			this.info = data.resultset;
			this.render = true;
		}});
		
  },
};
</script>

<style scoped>
.icon {
	margin-right: 9px;
}
</style>
