<template>
	<li @click="$emit('item-clicked')" class="clickable">
		<div class="item-content">

			<div v-if="media" class="item-media">
				<slot name="media"></slot>
			</div>

			<div class="item-inner">
				<div class="item-title-row">
					<div class="item-title">{{itemTitle}}</div>
					<div class="item-after">{{itemAfter}}</div>
				</div>

				<div class="item-title-row">
					<div class="item-subtitle">{{itemSubtitle}}</div>
					<div class="item-after">
						<slot name="actions"></slot>
					</div>
				</div>
			</div>
		</div>

		<slot></slot>
	</li>
</template>

<script>
export default {
	props: {
    media: {type: Boolean, required: false, default: true},
    itemTitle: {type: String, required: false},
    itemAfter: {type: String, required: false},
    itemSubtitle: {type: String, required: false},
  }
}
</script>
