<template>
	<popup :title="$LanguageHelper.translate('_PRODUCT_INFO_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<div v-if="render" class="pageContent">
			<f7-card v-if="info" inset>
				<f7-card-header>
					<div class="maxWidth75">{{$FunctionsHelper.specialChars(info.name)}}</div>
					<div class="theme"><b>€ {{info.amount_in_vat}}</b></div>
				</f7-card-header>

				<f7-card-content>
					<div class="productImage" :style="{ backgroundImage: 'url(' + info.imageURL + ')' }"></div>
					<f7-block class="marginTop20 marginBottom10">
	          <f7-row v-if="info.number_credits > 0">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_NUMBER_CREDITS_')}}</b></f7-col>
							<f7-col class="col-50">{{info.number_credits}}</f7-col>
						</f7-row>

						<f7-row v-if="info.number_weeks_valid > 0">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_NUMBER_WEEKS_VALID_')}}</b></f7-col>
							<f7-col class="col-50">{{info.number_weeks_valid}}</f7-col>
						</f7-row>

						<f7-row v-if="info.number_months_valid > 0">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_NUMBER_MONTHS_VALID_')}}</b></f7-col>
							<f7-col class="col-50">{{info.number_months_valid}}</f7-col>
						</f7-row>

						<f7-row v-if="info.number_months_valid != 0 && info.number_weeks_valid != 0">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_PERIOD_VALID_')}}</b></f7-col>
							<f7-col class="col-50">{{$LanguageHelper.translate('_UNLIMITED_')}}</f7-col>
						</f7-row>

						<f7-row v-if="info.installments == 1">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}</b></f7-col>
							<f7-col v-if="info.number_installments > 0" class="col-50">{{info.number_installments}}</f7-col>
							<f7-col v-else class="col-50">{{$LanguageHelper.translate('_CONTINUOUSLY_')}}</f7-col>
						</f7-row>

						<f7-row v-if="info.installments == 1">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_ATHORIZATION_SEPA_')}}</b></f7-col>
							<f7-col class="col-50">{{$LanguageHelper.translate('_EVERY_') + ' ' + (info.length > 1 ? info.length + ' ' : '') + info.period}}</f7-col>
						</f7-row>

						<f7-row v-if="info.installments == 1">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_AMOUNT_FIRST_')}}</b></f7-col>
							<f7-col class="col-50">€ {{parseInt(info.amount_in_vat_first_installment) > 0 ? info.amount_in_vat_first_installment : info.amount_in_vat}}</f7-col>
						</f7-row>

						<f7-row v-if="info.installments == 1">
	            <f7-col class="col-50"><b>{{$LanguageHelper.translate('_AMOUNT_OTHERS_')}}</b></f7-col>
							<f7-col class="col-50">€ {{info.amount_in_vat}}</f7-col>
						</f7-row>
						<br/>

						<f7-row>
	            <f7-col v-html="info.description"></f7-col>
						</f7-row>
					</f7-block>

					<f7-block class="marginTop20 marginBottom10">
						<f7-row>
							<f7-col v-if="info.installments != 1 || (info.installments == 1 && (!shoppingCart.has_installment_product || shoppingCart.has_installment_product == 0))">
								<f7-button @click="addToShoppingCartClicked" raised fill small>{{$LanguageHelper.translate('_IN_SHOPPING_CART_')}}</f7-button>
							</f7-col>

							<f7-col>
								<f7-button raised fill small @click="makePaymentClicked">{{$LanguageHelper.translate('_PAY_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-card-content>
			</f7-card>

			<div v-if="renderShoppingCart && showShoppingCart" class="marginTop30">
				<shopping-cart
					:idc="idc"
					:update="update"
					:hideNoItemsMessage="true"
					@changed="shoppingCartChanged"
					@close="opened=false"
				/>
			</div>
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ShoppingCart from '../components/shopping-cart.vue';

export default {
	components: {
    'popup': Popup,
		'shopping-cart': ShoppingCart,
	},

	props: {
		showShoppingCart: {required: false, default: true},
		closeOnCartChange: {required: false, default: false},
		idc: {required: true},
		id: {required: true}, // The id of the product
	},

	data() {
    return {
			opened: false,
			render: false,
			renderShoppingCart: false,
			shoppingCart: {}, // We need to now if the is an installments products in cart, add to cart button should be hidden!
			info: {}, // Product information
			update: 0, // Cart will be watching for changed
		};
  },

	methods: {
		shoppingCartChanged(data) {
			this.shoppingCart = data;
			this.$emit('shoppingCartChanged', data);
			
			this.opened = !this.closeOnCartChange;
		},

		makePaymentClicked() {
			$ViewHelper.toBrowser(this.info.purchase_url, !$Config.app.onDevice ? window.open() : null);
		},

		addToShoppingCartClicked() {
			let data = {idc: this.idc, id_appuser: $UserHelper.getID(), id_invoice_product: this.id, action: 'add-to-cart'};

			$AjaxHelper.doRequest('invoiceProduct', 'cartAction', data, {callback: (data) => {
				if(data.status == 'OK') {
					// Emit change to customer shop parent page so navbar can be updated
					this.shoppingCartChanged(data.shoppingCart);

					// Show the shopping cart, at least one item has to be in there since we got the ok back!
					this.renderShoppingCart = true;

					// Let shopping cart component know there has been changes
					this.update += 1;
				}
			}});
		}
	},

	created() {
		let data = {idc: this.idc, id_appuser: $UserHelper.getID(), shoppingCart: 1, id_invoice_product: this.id};

    $AjaxHelper.doRequest('invoiceProduct', 'product', data, {callback: (data) => {
			if(data.status == 'OK') {
				// If no product image set default
				if(data.resultset.imageURL == '') data.resultset.imageURL = '../../../static/img/no-image.png';

				// Store product information
				this.info = data.resultset;

				// Show shopping cart if products are in it
				this.renderShoppingCart = data.shoppingCart.products.length > 0;

				if(data.shoppingCart) this.shoppingCart = data.shoppingCart;

				// Render page
				this.render = true;
      }
		}});
	}
};
</script>

<style scoped>
.productImage {
	width:250px;
	height:250px;
	border: 1px solid #e7e7e7;
	background-size: cover;
	background-position: center;
}

.theme {color: var(--theme-color);}

i.icon.ionTrash {bottom: 8px;}
i.icon.ionRefresh {bottom: 8px;}

.maxWidth75 {max-width: 75%;}
</style>
