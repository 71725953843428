<template>
	<popup :title="$LanguageHelper.translate('_CHECKIN_WITH_QR_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form v-if="render" :params="form" @submitted="submitted" @changed="changed" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		cameras: {required: true},
  },

	data() {
		return {
      render: false,
			opened: false,

			form: {
				fields: {
					camera: {type: 'select', name: 'cameras', title: '_SELECT_CAMERA_', render: false, options: []},
				},

				submit: {text: '_ENABLE_CAMERA_', render: false},
				values: {},
			},
		}
	},

	methods: {
		changed() {},

		initCameras() {
			this.form.fields.camera.render = true;
			this.form.submit.render = true;

			// Default options
      this.form.fields.camera.options.push({
        name: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        value: 'none',
        label: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        selected: false,
      });

			// Options to choose from
      this.cameras.forEach((camera) => {
        this.form.fields.camera.options.push({
          name: camera.label,
          value: camera.id, 
          label: camera.label,
          selected: false,
        });
      });			
		},

		submitted() {
			const cameraSelected = JSON.parse(JSON.stringify(this.form.values)).camera;
			if(!cameraSelected) {
				return $ViewHelper.alert($LanguageHelper.translate('_SELECT_A_CAMERA_FIRST_'), $LanguageHelper.translate('_NOTICE_'));
			}
			this.$emit('selected', cameraSelected);

			this.opened = false;
		},
	},

  created() {
		this.initCameras();
		this.render = true;
  },
};
</script>
