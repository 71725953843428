<template>
  <page title="Agenda's" :renderNavbar="!queryParams.external" :pageClass="queryParams.external ? 'noNavbar' : ''" @initiated="go">
		<f7-list v-if="agendas" inset>
      <f7-list-item v-for="(agenda, index) in agendas" :key="index" :title="$FunctionsHelper.specialChars(agenda.name)" :link="`/agenda/idc/${idc}/id/${agenda.id_agenda}/manage/${manage}`">
				<template #media><f7-icon icon="iconAgenda"></f7-icon></template>
      </f7-list-item>
    </f7-list>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },

  props: {
		idc: {required: true},
		manage: {required: true},
	},

  data() {
    return {
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href),
      agendas: [],
    }
  },

	methods: {
		go() {
			// Data already set in the global space by router?
			if(window.agendaList) {
				this.agendas = Object.assign({}, window.agendaList);
				delete window.agendaList;
			}
			// Data not set, fetch it ourselves
			else {
				$AjaxHelper.doRequest('agenda', 'getAgendas', {idc: this.idc}, {callback: (data) => {
					this.agendas = data.resultset;
				}});
			}
		}
	}
}
</script>
