<template>
	<popup :title="$LanguageHelper.translate('_MANAGE_DIRECT_DEBIT_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id_customer: {required: false},
		openedViaCustomer: {required: false, default: false},
		directDebit: {required: true}, // All info comes in as prop here!
  },

	data() {
		return {
			opened: false,

			form: {
				fields: {
					// Start date
					date_process_direct_debit: {name: 'date_process_direct_debit', type: 'date', label: '_FIRST_PROCESS_DATE_DIRECT_DEBIT_', placeholder: '_SELECT_DATE_', render: true, value: ''},

					// Connected user autocomplete field
					user_connected: {type: 'autocomplete', name: 'user_connected', label: '_CONNECT_TO_CUSTOMER_', placeholder: '_SEARCH_CUSTOMER_', render: !this.openedViaCustomer, value: '', items: []},

					// Creditor (customer) date
					data_creditor: {type: 'divider', title: '_DATA_CREDITOR_', render: true, action: {icon: 'ionTrash', title: '_DELETE_', name: 'removeCreditor', render: !this.openedViaCustomer}},
					user_connected_description: {type: 'description', text: '', render: false, class: 'bold'},
					company: {name: 'company', type: 'input', label: '_COMPANY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					vat_number: {name: 'vat_number', type: 'input', label: '_VAT_NUMBER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					firstname: {name: 'firstname', type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					lastname: {name: 'lastname', type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					email: {name: 'email', type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					mobile: {name: 'mobile', type: 'input', label: '_MOBILE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					street: {name: 'street', type: 'input', label: '_STREET_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					housenumber: {name: 'street', type: 'input', label: '_HOUSENUMBER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					zipcode: {name: 'zipcode', type: 'input', label: '_ZIPCODE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					city: {name: 'city', type: 'input', label: '_CITY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					id_country: {type: 'select', name: 'id_country', title: '_COUNTRY_', render: true, options: []},
					id_language: {type: 'select', name: 'id_language', title: '_INVOICE_LANGUAGE_', render: true, options: []},
					
					// How do you want to receive the money?
					subscription_type_info: {type: 'divider', title: '_WAY_OF_PAYMENT_', render: true, value: ''},
					process_by_invite_worker_only: {type: 'select-regular', name: 'process_by_invite_worker_only', title: '_WAY_OF_PAYMENT_', render: true, options: []},
					
					iban: {name: 'iban', type: 'input', label: '_IBAN_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					product_info: {type: 'divider', title: '_PRODUCT_INFO_', render: true, value: ''},

					// Product data
					id_invoice_product: {type: 'select-regular', name: 'id_invoice_product', title: '_PRODUCT_', render: true, options: []},
					name: {name: 'name', type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					number_installments: {type: 'select', name: 'number_installments', title: '_NUMBER_INSTALLMENTS_', render: true, options: []},
					collect_every: {type: 'combined', title: '_COLLECT_EVERY_', render: true,
						fields: {
							length: {type: 'select', class: 'small', name: 'length', options: []},
							period: {type: 'select', class: 'medium', name: 'period', options: []},
						}
					},
					amount_in_vat_first_installment: {name: 'amount_in_vat_first_installment', type: 'input', label: '_AMOUNT_FIRST_PERIOD_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					amount_in_vat: {name: 'amount_in_vat', type: 'input', label: '_AMOUNT_PERIODS_IN_VAT_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					vat_percentage: {name: 'vat_percentage', type: 'input', label: '_VAT_PERCENTAGE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					vat_shifted_eu: {type: 'select', name: 'vat_shifted_eu', title: '_VAT_SHIFTED_ABROAD_', render: true, options: []},

					// Agenda settings
					agenda_settings_info: {type: 'divider', title: '_AGENDA_SETTINGS_', render: false, value: ''},
					active: {type: 'select', name: 'active', title: '_ACTIVATE_', render: false, options: []},

					// Statement of consent
					consent: {type: 'divider', title: '_DECLARATION_OF_CONSENT_', render: true, value: ''},
					direct_debit_permission: {type: 'checkbox', title: '_DIRECT_DEBIT_PERMISSION_', name: 'direct_debit_permission', checked: false, value: 1, render: true},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			},

			id_user: null
		}
	},

	methods: {
		changed(field, item) {
			// Hide or show the IBAN field if way of payment is switched
			if(field.name == 'process_by_invite_worker_only') {
				this.form.fields.iban.render = item.value != 1;
				this.form.fields.consent.render = item.value != 1;
				this.form.fields.direct_debit_permission.render = item.value != 1;
			}

			// Fetch the selected user and do auto complete stuff!
			if(field.name == 'user_connected') {
				if(item && item.autocompleteSelected) {
					// Empty the field here, we don't want to do this on search, otherwise it will create a loop!
					field.value = '';

					// Fetch the selected user
					this.fetchUser(item.id);
				} else {
					// Remove current selected user id, empty prev list and find users that match search query
					this.form.fields.user_connected.items = [];
					if(field.value.length > 0) {
						$AjaxHelper.doRequest('payment', 'getUsersByNameForSubscription', {name: field.value, idc: this.idc}, {callback: this._foundUsers});
					}
				}
			}

			// Remove creditor data
			if(field.name == 'removeCreditor') {
				this.emptyUserFields();
			}

			//  Get the invoice product here
			if(field.name == 'id_invoice_product' && item.value > 0) {
				$AjaxHelper.doRequest('payment', 'getProductForSubscription', {id: item.value, idc: this.idc}, {callback: this._fetchedProduct});
			}
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			data['idc'] = this.idc;

			// The back-end expects length_period instead of just length :(
			data['length_period'] = data.length;

			if(this.id_user > 0) data['id_user'] = this.id_user;

			// Existing direct debit, set the id before we save!
			if(this.directDebit.info) data['id'] = this.directDebit.info.id;

			$AjaxHelper.doRequestExtraLogin('payment', 'saveSubscription', data, {callback: this._saved});
		},

		emptyUserFields() {
			this.id_user = null;

			this.form.fields.user_connected_description.text = '';
			this.form.fields.user_connected_description.render = false;

			this.form.fields.company.value = '';
			this.form.fields.vat_number.value = '';
			this.form.fields.firstname.value = '';
			this.form.fields.lastname.value = '';
			this.form.fields.email.value = '';
			this.form.fields.mobile.value = '';
			this.form.fields.street.value = '';
			this.form.fields.housenumber.value = '';
			this.form.fields.zipcode.value = '';
			this.form.fields.city.value = '';
			this.form.fields.mobile.value = '';
			this.form.fields.id_country.value = '';
			this.form.fields.id_language.value = '';
			this.form.fields.iban.value = '';
		},

		resetFormValues(data) {
			for(var field in data) {
				if(this.form.fields[field]) {
					if(this.form.fields[field].type == 'input') {
						this.form.fields[field].value = data[field];
					} else if(this.form.fields[field].type == 'select') {
						for(var key in this.form.fields[field].options) {
							this.form.fields[field].options[key].selected = this.form.fields[field].options[key].value == data[field];
						}
					}
				}
				// These are combined select fields and not directly present!
				else if(field == 'length' || field == 'period') {
					this.form.fields.collect_every.fields[field].options.forEach((option) => {
						option.selected = option.value == data[field];
					});
				}
			}
		},

		setAutocompleteSelectedUser(name, email, id_user) {
			// Set the description field and render it
			this.form.fields.user_connected.items = [];
			this.form.fields.user_connected_description.text = `${$LanguageHelper.translate('_SELECTED_CUSTOMER_')}<br />${$FunctionsHelper.specialChars(name)} (${email})`;
			this.id_user = id_user;
			this.form.fields.user_connected_description.render = true;
		},

		agendaEnabled() {
			// Does this company have the agenda module enabled? If not were done here?
			if(!this.directDebit.agendaEnabled || this.directDebit.agendaEnabled != 1) return false;

			// New direct debit or agenda activation settings not processed yet!
			if(!this.directDebit.info || this.directDebit.info.agenda_active != 1) {
				return true;
			}

			return false;
		},

		setInstallmentPeriodSettings(periods, length = 1) {
			// Period options (weekly of monthly)
			periods.forEach((option) => {
				this.form.fields.collect_every.fields.period.options.push({
					label: option.label,
					value: option.id,
					selected: option.selected == 1
				});
			});

			// Length (the number of times per period)
			for(var i = 1; i <= 24; i ++) {
				this.form.fields.collect_every.fields.length.options.push({value: i, label: i, selected: length == i});
			}
		},

		_foundUsers(data) {
			// Empty fields here!
			this.form.fields.user_connected.items = [];
			this.emptyUserFields();

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((user, key) => {
					this.form.fields.user_connected.items.push({
						autocompleteSelected: true,
						title: user.name,
						footer: user.email,
						name: user.name,
						email: user.email,
						id: user.id_user,
					});
				});
			}
		},

		fetchUser(id) {
			$AjaxHelper.doRequest('payment', 'getUserForSubscription', {id: id, idc: this.idc}, {callback: this._fetchedUser});
		},

		_fetchedUser(data) {
			if(data.status == 'OK') {
				// Reset the form values
				this.resetFormValues(data.resultset);

				// Set the description field and render it
				var fullname = `${$FunctionsHelper.specialChars(data.resultset.firstname)} ${$FunctionsHelper.specialChars(data.resultset.lastname)}`;
				this.setAutocompleteSelectedUser(fullname, data.resultset.email, data.resultset.id_user);
			}
		},

		_fetchedProduct(data) {
			if(data.status == 'OK') {
				// Change the name so form knows what to do with it (inconsequent naming)!
				data.resultset['length'] = data.resultset.length_period;

				// Reset the form values
				this.resetFormValues(data.resultset);
			}
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Let our parent know there has been changes so it reloads the list!
				this.$emit('changed', data.id_invoice_product_sale);

				// Close popup here, were done!
				this.opened = false;
			}
		},
	},

	created() {
		// Stuff we need to set if we are editing an existing direct debit
		if(this.directDebit && this.directDebit.info) {
			// Naming correction from back-end!
			this.directDebit.info['period'] = this.directDebit.info['period_number'];
			this.directDebit.info['active'] = this.directDebit.info['agenda_active'];
			
			if(this.directDebit.info.user_connected) {
				this.setAutocompleteSelectedUser(this.directDebit.info.user_connected, this.directDebit.info.user_email_connected, this.directDebit.info.id_user);
			}

			/*
			Copy needed fields into form values!
			If we just copy it straight away this.directDebit.info wil break because form watcher will remove unwanted fields
			*/
			for(var key in this.directDebit.info) this.form.values[key] = this.directDebit.info[key];
		}

		// Set countries
		this.directDebit.countries.forEach((country, key) => {
			this.form.fields.id_country.options.push({value: country.value, label: country.label, selected: country.selected == 1});
		})

		// Set languages
		this.directDebit.languages.forEach((language, key) => {
			this.form.fields.id_language.options.push({value: language.value, label: language.label, selected: language.selected == 1});
		});

		// Set the number installments
		this.form.fields.number_installments.options.push({value: '', label: $LanguageHelper.translate('_CHOSE_AN_OPTION_')});
		this.form.fields.number_installments.options.push({value: 0, label: $LanguageHelper.translate('_ONGOING_'), selected: (this.directDebit.info && this.directDebit.info.number_installments == 0 ? true: false)});
		for (var i = 1; i <= 60; i ++) {
      this.form.fields.number_installments.options.push({value: i, label: i, selected: (this.directDebit.info && this.directDebit.info.number_installments == i ? true: false)});
    }

		// Set the installment periods
		this.setInstallmentPeriodSettings(this.directDebit.periods, this.directDebit.info ? this.directDebit.info.length : null);

		// How will payment be received?
		this.form.fields.subscription_type_info['info'] = '_WAY_OF_PAYMENT_INFO_';
		this.form.fields.process_by_invite_worker_only.options.push({value: 0, label: $LanguageHelper.translate('_PAY_VIA_SEPA_'), selected: (this.directDebit.info && this.directDebit.info.process_by_invite_worker_only != 1 ? true: false)});
		this.form.fields.process_by_invite_worker_only.options.push({value: 1, label: $LanguageHelper.translate('_PAY_VIA_INVITE_'), selected: (this.directDebit.info && this.directDebit.info.process_by_invite_worker_only == 1 ? true: false)});
		
		// Hide IBAN + permission field if it's an invite subscription
		if(this.directDebit.info && this.directDebit.info.process_by_invite_worker_only == 1) this.form.fields.iban.render = false
		if(this.directDebit.info && this.directDebit.info.process_by_invite_worker_only == 1) this.form.fields.consent.render = false
		if(this.directDebit.info && this.directDebit.info.process_by_invite_worker_only == 1) this.form.fields.direct_debit_permission.render = false

		// Hide the first installment field if it's a new direct debit or no installments has been processed yet!
		if(!this.directDebit.info || (this.directDebit.info && this.directDebit.info.number_periods_processed == 0)) {
      this.form.fields.amount_in_vat_first_installment.render = true;
			this.form.fields.amount_in_vat.label = '_AMOUNT_OTHER_PERIODS_';
    }
		// Set the products user can select from
		this.form.fields.id_invoice_product.options.push({value: '', label: $LanguageHelper.translate('_CHOOSE_PRODUCT_'), selected: false});
		this.directDebit.products.forEach((product, key) => {
			this.form.fields.id_invoice_product.options.push({value: product.id_invoice_product, label: $FunctionsHelper.specialChars(product.name), selected: false});
		});

		// Set the vat shifted options, yes or no!
		this.form.fields.vat_shifted_eu.options.push({value: 0, label: $LanguageHelper.translate('_NO_'), selected: this.directDebit.info && this.directDebit.info.vat_shifted_eu ? true : false});
		this.form.fields.vat_shifted_eu.options.push({value: 1, label: $LanguageHelper.translate('_YES_'), selected: this.directDebit.info && this.directDebit.info.vat_shifted_eu == 1 ? true : false});

		// Agenda activation settings
		if(this.agendaEnabled()) {
			this.form.fields.active.options.push({value: 0, label: $LanguageHelper.translate('_ACTIVATE_AFTER_PAYMENT_'), selected: (!this.directDebit.info || (this.directDebit.info && this.directDebit.info.agenda_active == 0 && (!this.directDebit.info.date_deactivation_end || this.directDebit.info.date_deactivation_end == '0000-00-00')) ? 1 : 0)});
			this.form.fields.active.options.push({value: 1, label: $LanguageHelper.translate('_ACTIVATE_NOW_'), selected: (this.directDebit.info && this.directDebit.info.agenda_active == 1 ? 1 : 0)});
			this.form.fields.active.options.push({value: 2, label: $LanguageHelper.translate('_ACTIVATE_ON_DIRECT_DEBIT_DATE_'), selected: (this.directDebit.info && this.directDebit.info.agenda_active == 0 && this.directDebit.info.date_deactivation_end && this.directDebit.info.date_deactivation_end != '0000-00-00' ? 1 : 0)});

			// Show the agenda product change info (if you change the product, user can subcribe immediately with the new product)
			this.form.fields.product_info['info'] = '_PRODUCT_CHANGE_MESSAGE_';

			// Render agenda fields!
			this.form.fields.agenda_settings_info.render = true;
			this.form.fields.active.render = true;
		}

		// If the customer id prop is here then fetch the user for auto complete!
		if(this.id_customer > 0) this.fetchUser(this.id_customer);
	}
};
</script>