<template>
  <div v-if="visible">
    <f7-list v-if="reportings.length > 0" inset>
        <f7-list-item accordion-item v-for="(reporting, key) in reportings" :key="key" :title="reporting.date_reporting">

          <f7-accordion-content>
            <f7-block>
              <template v-if="reporting.goals">
                <div class="item"><b>{{$LanguageHelper.translate('_GOALS_')}}</b></div>
                <div class="item marginBottom10" v-html="reporting.goals"></div>
              </template>
              <template v-if="reporting.report">
                <div class="item"><b>{{$LanguageHelper.translate('_REPORTING_')}}</b></div>
                <div class="item marginBottom10" v-html="reporting.report"></div>
              </template>
              <template v-if="reporting.actions">
                <div class="item"><b>{{$LanguageHelper.translate('_ACTIONS_')}}</b></div>
                <div class="item marginBottom10" v-html="reporting.actions"></div>
              </template>
              <template v-if="reporting.other_info">
                <div class="item"><b>{{$LanguageHelper.translate('_OTHER_INFO_')}}</b></div>
                <div class="item marginBottom10" v-html="reporting.other_info"></div>
              </template>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
    </f7-list>

    <f7-card v-else class="pageContent">
			<f7-card-content>
				<div>{{$LanguageHelper.translate('_NO_REPORT_MESSAGE_')}}</div>
			</f7-card-content>
		</f7-card>
  </div>
</template>

<script>
export default {
  props: {
		idc: { required: true },
  },

  data() {
    return {
      reportings: {},
      visible: false,
    };
  },

  methods: {
    _initReportings(data) {
      if(data.status == 'OK') {
        if(data.resultset.length > 0) {
          this.reportings = data.resultset;
        }
        this.visible = true;
      }
    },
  },

  created() {
    $AjaxHelper.doRequest('gym', 'getReportingsUser', {idc: this.idc, id_appuser: $UserHelper.getID()}, {callback: this._initReportings});
	},
};
</script>
