<template>
  <popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
    <list-form v-if="render" :params="form" @submitted="submitted" @changed="changed" />
  </popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
  components: {
    'popup': Popup,
    'list-form': ListForm,
  },

  props: {
		title: {required: true},
		idc: {required: true},
		type: {required: true},
    id: {required: false} // Connection trigger id,
	},

  data() {
    return {
      opened: false,
      render: false,

      form: {
        fields: {
					name: {name: 'name', type: 'input', label: '_NAME_YOUR_TRIGGER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					trigger: {type: 'select', name: 'trigger', title: '_TRIGGER_', render: true, options: []},
					productsFilterDivider: {type: 'divider', title: '_SELECT_TRIGGER_PRODUCTS_', info: $LanguageHelper.translate('_INFO_ABOUT_TRIGGER_PRODUCTS_'), render: false},
					trialsFilterDivider: {type: 'divider', title: '_SELECT_TRIGGER_TRIALS_', info: $LanguageHelper.translate('_INFO_ABOUT_TRIGGER_TRIALS_'), render: false},
					products: {type: 'checkbox_group', items: [], render: false},
					actionDivider: {type: 'divider', title: '_WHICH_ACTIONS_NEEDS_TO_HAPPEN_', render: this.id > 0},
					action: {type: 'select', name: 'action', title: '_ACTION_', render: false, options: []},
					list: {type: 'select', name: 'list', title: '_WHICH_LIST_', render: false, options: []},
					tag: {type: 'select', name: 'tag', title: '_WHICH_TAG_', render: false, options: []},
        },

        submit: {text: '_SAVE_', render: true},
				values: {},
      }
    }
  },

  methods: {
    submitted() {
      let data = JSON.parse(JSON.stringify(this.form.values));

			if(this.id) data['id'] = this.id; // Set id for existing trigger!

			$AjaxHelper.doRequest('apiConnect', 'saveTrigger', Object.assign({idc: this.idc, type: this.type}, data), {callback: (data) => {
				if(data.status == 'OK') {
					// Let our parent know there has been changes so it reloads the list!
					this.$emit('changed');

					// Close popup here, were done!
					this.opened = false;
				}
			}});
    },

		changed(field, item) {
			if(field.type == 'checkbox') return;

			if(field.name == 'trigger') {
				this.form.fields.action.render = false;
				this.form.fields.actionDivider.render = false;
				this.form.fields.list.render = false;
				this.form.fields.tag.render = false;

				if(item.value == 'trial' || item.value == 'sale') {
					this.setProducts(item.value, true);

					if(item.value == 'trial') {
						this.form.fields.productsFilterDivider.render = false;
						this.form.fields.trialsFilterDivider.render = true;
					} else {
						this.form.fields.trialsFilterDivider.render = false;
						this.form.fields.productsFilterDivider.render = true;
					}
				} else {
					// Empty product list and hide product fields
					this.form.fields.products.items = [];
					this.form.fields.productsFilterDivider.render = false;
					this.form.fields.trialsFilterDivider.render = false;
					this.form.fields.products.render = false;
				}

				// Only show the action field if a valid trigger was selected
				if(item.value != '') {
					this.setTriggerActions(true);
					setTimeout(() => {
						this.form.fields.actionDivider.render = true;
						this.form.fields.action.render = true;
					}, 100);
				}
			}

			if(field.name == 'action') {
				this.setLists(true);
				this.setTags(true);

				this.form.fields.list.render = item.value == 'list';
				this.form.fields.tag.render = item.value == 'tag';
			}
		},

		setTriggerTypes() {
			this.form.fields.trigger.options = [];
			this.form.fields.trigger.options.push({value: '', label: '_SELECT_EVENT_', selected: false});
			$.each(this.triggerTypes, (index, triggerType) => {
				this.form.fields.trigger.options.push({value: triggerType.type, label: triggerType.description, selected: triggerType.selected == 1});
			});
		},

		setTriggerActions(reset) {
			this.form.fields.action.options = [];
			this.form.fields.action.options.push({value: '', label: '_SELECT_ACTION_', selected: false});
			$.each(this.triggerActions, (index, triggerAction) => {
				this.form.fields.action.options.push({value: triggerAction.type, label: triggerAction.description, selected: triggerAction.selected == 1 && !reset});
			});
		},

		setLists(reset) {
			this.form.fields.list.options = []
			this.form.fields.list.options.push({value: '', label: '_SELECT_LIST_', selected: false});
			$.each(this.lists, (index, list) => {
				this.form.fields.list.options.push({value: list.id, label: list.name, selected: list.selected == 1 && !reset});
			});
		},

		setTags(reset) {
			this.form.fields.tag.options = [];
			this.form.fields.tag.options.push({value: '', label: '_SELECT_TAG_', selected: false});
			$.each(this.tags, (index, tag) => {
				this.form.fields.tag.options.push({value: tag.id, label: tag.name, selected: tag.selected == 1 && !reset});
			});
		},

		setProducts(type, reset) {
			this.form.fields.products.items = [];
			this.triggerTypes[type].products.forEach((item) => {
				this.form.fields.products.items.push({
					type: 'checkbox',
					title: item.name,
					value: item.id,
					checked: item.selected == 1 && !reset,
					render: true
				});
			});

			this.form.fields.products.render = true;
		},

		_renderForm(data) {
			this.render = false;

			this.triggerTypes = data.triggerTypes;
			this.triggerActions = data.triggerActions;
			this.lists = data.lists;
			this.tags = data.tags;

			this.setTriggerTypes();
			this.setTriggerActions();
			this.setLists();
			this.setTags();

			// Set form values for existing trigger and render the fields that needs to be visible
			if(this.id > 0) {
				this.form.fields.name.value = data.name; // Set name value

				// Render fields
				this.form.fields.trigger.render = true;

				// Show products if trigger type is sale or trial
				if(data.trigger == 'sale' || data.trigger == 'trial') this.setProducts(data.trigger);
				// Render sale fields
				this.form.fields.productsFilterDivider.render = data.trigger == 'sale';
				this.form.fields.trialsFilterDivider.render = data.trigger == 'trial';

				// Render the action field
				this.form.fields.action.render = true;

				// Render list or tag action fields
				if(data.action == 'list') this.form.fields.list.render = true;
				if(data.action == 'tag') this.form.fields.tag.render = true;
			}

			// Render page
			this.render = true
		},

		fetchTriggerTypes() {
			$AjaxHelper.doRequest('apiConnect', 'getTriggerTypes', {idc: this.idc, type: this.type}, {callback: this._renderForm});
		},

		fetchTrigger() {
			$AjaxHelper.doRequest('apiConnect', 'getTrigger', {idc: this.idc, id: this.id, type: this.type}, {callback: this._renderForm});
		}
  },

  created() {
		if(this.id > 0) this.fetchTrigger(); // Fetch existing trigger
		else this.fetchTriggerTypes(); // Fetch data needed to create a new trigger
  }
};
</script>
