<template>
	<page
		:title="title"
		:back="false"
		:panel="false"
		:language="true"
		:visible="visible"
		>

		<div class="cardCenter">
			<f7-card v-if="$UserHelper.hasAccess('qr_access', 'scan', idc)">
				<f7-card-header>
					<template v-if="camerasDevicesAvailable.length == 0">
						<div>{{$LanguageHelper.translate('_WAITING_FOR_CAMERA_')}}..</div>
					</template>

					<template v-else>
						<a v-if="cameraDeviceSelected" href="#" class="link" @click="disableCamera">{{$LanguageHelper.translate('_CLICK_DISABLE_CAMERA_')}}</a>
						<a v-else href="#" class="link" @click="enableCamera">{{$LanguageHelper.translate('_CLICK_ENABLE_CAMERA_')}}</a>
					</template>
				</f7-card-header>

				<f7-card-content class="">
					<div id="reader" class="" v-show="!scannerState.scanning && scannerState.response == ''"></div>

					<!-- Waiting for scan result -->
					<div class="scanning" v-if="scannerState.scanning && scannerState.response == ''">
						<div class="waitForScanResult">{{$LanguageHelper.translate('_WERE_CHECKING_YOUR_QR_NOW_')}}</div>
					</div>
					
					<!-- Valid check-in -->
					<div v-if="scannerState.response != '' && scannerState.checkinValid" class="scannerResponse valid">
						<div class="response"><i class="icon ionCheckmark" /><span class="text">{{scannerState.response}}</span></div>
						<audio v-if="scannerState.playSound" src="/static/sounds/checkin-valid.mp3" autoplay />
					</div>

					<!-- Invalid check-in -->
					<div v-if="scannerState.response != '' && !scannerState.checkinValid" class="scannerResponse invalid">
						<div class="response"><i class="icon ionWarning" /><span class="text">{{scannerState.response}}</span></div>
						<audio v-if="scannerState.playSound" src="/static/sounds/checkin-invalid.mp3" autoplay />
					</div>
				</f7-card-content>
			</f7-card>

			<f7-card v-else>
				<f7-card-content>
					<h2>{{$LanguageHelper.translate('_PAGE_NOT_FOUND_')}}</h2>
				</f7-card-content>
			</f7-card>
		</div>

		<manage-qr-camera-devices v-if="manageCameraDevicesOpened && camerasDevicesAvailable.length > 0" :cameras="camerasDevicesAvailable" @close="manageCamerasOpened=false" @selected="cameraSelected" />
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';
import ManageQRCameraDevices from '../popups/manage-qr-camera-devices.vue';
import {Html5Qrcode} from "html5-qrcode" // => https://github.com/mebjas/html5-qrcode

export default {
	components: {
    'page': Page,
		'list-form': ListForm,
		'manage-qr-camera-devices': ManageQRCameraDevices,
  },

  data() {
    return {
			title: $LanguageHelper.translate('_PAGE_NOT_FOUND_'),
			idc: $FunctionsHelper.getAllQueryParams(window.location.href).idc,
			visible: false,
			manageCameraDevicesOpened: false,
			camerasDevicesAvailable: [],
			cameraDeviceSelected: null,
			scannerState: {
				scanning: false,
				checkinValid: false,
				playSound: false,
				response: '',
			}
		};
  },

	methods: {
		// Camera selected from popup if there are multiple ones
		cameraSelected(id) {
			this.cameraDeviceSelected = id;
			this.startScanner();
		},

		enableCamera() {
			// Check here if we have more than one camera if so than open the popup!
			if(this.camerasDevicesAvailable.length > 1) {
				this.manageCameraDevicesOpened = true;
			} 
			// Open the camera directly, since there is only one available
			else {
				this.cameraDeviceSelected = this.camerasDevicesAvailable[0].id;
				this.startScanner();
			}
		},

		setScannerResponse(scannerObj, response, valid, playSound) {
			this.scannerState.response = response;
			this.scannerState.checkinValid = valid;
			this.scannerState.playSound = playSound;

			setTimeout(() => { 
				scannerObj.resume();
				this.scannerState.scanning = false;
				this.scannerState.checkinValid = false;
				this.scannerState.response = '';
			}, 2000);
		},

		startScanner() {
			// Start code reader here
			const html5QrCode = new Html5Qrcode('reader');
			html5QrCode.start(
				this.cameraDeviceSelected, 
				{
					fps: 10, // Optional, frame per seconds for qr code scanning
					qrbox: (viewfinderWidth, viewfinderHeight) => {
						let minEdgePercentage = 0.7; // 70%
						let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
						let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
						return {
							width: qrboxSize,
							height: qrboxSize
						};
					}
				},
				(decodedText) => {
					//console.log(decodedText)

					// The code is read, now do server request and put scanner on pauze!
					this.scannerState.scanning = true;
					html5QrCode.pause();
					
					// The QR code could contain a Json or plain Query string, query string is the old way for backwards compatibility!
					const params = this.isJson(decodedText) ? JSON.parse(decodedText) : $FunctionsHelper.getAllQueryParams(decodedText);
					
					if(!params.idc || ! params.ide || !params.ids || !params.t)  {
						this.scannerState.response = $LanguageHelper.translate('_QR_CHECK_FAILED_');
						return this.setScannerResponse(html5QrCode, $LanguageHelper.translate('_QR_CHECK_FAILED_'));
					}

					$AjaxHelper.doRequest('eventWithScanAccess', 'scanQRCode', {idcc: this.idc, idc: params.idc, ide: params.ide, ids: params.ids, t: params.t}, {callback: (data) => {
						return this.setScannerResponse(html5QrCode, data.response, data.valid == 1, data.playSound == 1);
					}});
				},
				(errorMessage) => {})
			.catch((err) => {});
		},

		disableCamera() {
			// Switch camera off again by reloading the page!
			window.location.reload();
		},

		go() {
			// This method will trigger user permissions
			Html5Qrcode.getCameras().then(cameras => {
				if(cameras && cameras.length > 0) {
					this.camerasDevicesAvailable = cameras;
				}
			}).catch(err => {});

			this.visible = true;
		},

		isJson(val) {
			try {
				JSON.parse(val);
				return true;
			} catch(e) {
				return false;
			}
		}
	},

	created() {
		// If user is not logged in, internally redirect to login screen!
		if(!$UserHelper.isLoggedIn()) return $ViewHelper.router.navigate('/login-new-account/', {reloadCurrent: true, animate: false });
		
		// IF user has access set the correct page title
		if($UserHelper.hasAccess('qr_access', 'scan', this.idc)) this.title = $LanguageHelper.translate('_SCAN_QR_CODE_');
		
		// Run the go function where we handle logic...
		setTimeout(() => {this.go()}, 100);
	}
};
</script>

<style scoped>
.scannerResponse {
	padding: 18px;
	padding-bottom: 19px;
	border-radius: 5px;
}

.scannerResponse .response {
	color: #fff;
	font-size: 23px;
	font-weight: bold;
}

.waitForScanResult {
	font-size: 23px;
	font-weight: bold;
}

.scannerResponse .response .text {margin-left: 12px;}
.scannerResponse .response .icon {vertical-align: top;}
.scannerResponse.valid {background: rgb(68, 238, 68);}
.scannerResponse.invalid {background: rgb(233, 48, 2);}
</style>
