<template>
	<page
		:title="$LanguageHelper.translate('_CUSTOMERS_')"
		:infinite="true"
		:search="true"
		:sort-options="sortOptions"
		:fab="fab"
		@infinite-scroll-page-end="pageEndReached"
		@searched="searched"
		@sort-selected="sortSelected"
		@initiated="go"
		class="manageCustomers"
	>
		<fixed-top :resized="render" v-show="render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo" v-if="render">
				<f7-list-item>
					{{customers.total}} {{$LanguageHelper.translate('_CUSTOMERS_').toLowerCase()}}, <a class="theme link" @click="customers.birthdaysOpened=true">{{customers.birthdays.length}} {{$LanguageHelper.translate('_BIRTHDAYS_TITLE_').toLowerCase()}}</a>
					<manage-birthdays v-if="customers.birthdays.length > 0 && customers.birthdaysOpened" :customers="customers.birthdays" @customer-clicked="openCustomerBirthday" @close="customers.birthdaysOpened=false" />
					</f7-list-item>
			</f7-list>
		</fixed-top>

		<f7-list media-list inset class="customers listWithActions">
			<list-item-with-actions v-for="(customer, key) in customers.list"
				:key="key"
				:item-title="$FunctionsHelper.specialChars(customer.name)"
				:item-after="customer.customer_since"
				:item-subtitle="customer.email"
				@item-clicked="openCustomer(customer)"
			>

				<template #media>
					<img v-if="customer.imageURL" :src="customer.imageURL" class="customerImage" />
					<f7-icon v-else class="ionPerson"></f7-icon>
				</template>

				<template #actions v-if="$UserHelper.hasAccess('users', 'show', idc)">
					<!-- Out of sync -->
					<i v-if="customer.out_of_sync == 1 && customer.ignore_out_of_sync == 0" class="action icon ionSync red" />
					<!-- Pending -->
					<i v-if="customer.id_appuser_status == 2" class="action icon ionHourglass" />
					<!-- Invited -->
					<i v-if="customer.id_appuser_status == 4 || customer.id_appuser_status == 7" class="action icon ionPaperPlane" />
					<!-- Blocked -->
					<i v-if="customer.id_appuser_status == 5" class="action icon ionLock" />
					<!-- Connected -->
					<i v-if="customer.id_appuser_status == 3" class="action icon ionCheckmark" />
				</template>

				<!-- Manage customer edit popup here, contents is only rendered when opened is true -->
				<manage-customer v-if="customer.opened"
					:idc="idc"
					:index="key"
					:title="$FunctionsHelper.specialChars(customer.name)"
					:id_customer="customer.id_user"
					@archived="removeListItem(key)"
					@edited="replaceListItem"
					@close="customer.opened=false"
				/>
			</list-item-with-actions>
		</f7-list>

		<!-- Manage customer add-->
		<manage-customer-add v-if="fab.buttons.add.clicked" :idc="idc" @close="fab.buttons.add.clicked=false" @added="addListItem" />

		<!-- Manage customer export -->
		<manage-customers-export v-if="fab.buttons.export.clicked" :idc="idc" @close="fab.buttons.export.clicked=false" />

		<!-- Manage customer archive -->
		<manage-customers-archive v-if="fab.buttons.archive.clicked" :idc="idc" @close="fab.buttons.archive.clicked=false" />

		<!-- Invite customer via link popup -->
		<invite-customers-via-link v-if="fab.buttons.invite.clicked" :idc="idc" @close="fab.buttons.invite.clicked=false" />

		<!-- Manage customer for birthday popup here, contents is only rendered when opened is true -->
		<manage-customer v-if="birthdayCustomer.opened"
			:idc="idc"
			:title="$FunctionsHelper.specialChars(birthdayCustomer.title)"
			:id_customer="birthdayCustomer.id_customer"
			@close="birthdayCustomer.opened=false"
		/>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import Popup from '../components/smart/popup.vue';
import FixedTop from '../components/smart/fixed-top.vue';
import ListItemWithActions from '../components/smart/list-item-with-actions.vue';
import ManageBirthdays from '../popups/manage-birthdays.vue';
import ManageCustomer from '../popups/manage-customer/index.vue';
import ManageCustomerAdd from '../popups/manage-customer-add.vue';
import ManageCustomersExport from '../popups/manage-customers-export.vue';
import ManageCustomersArchive from '../popups/manage-customers-archive.vue';
import InviteCustomersViaLink from '../popups/invite-customers-via-link.vue';

export default {
  components: {
    'page': Page,
    'popup': Popup,
		'fixed-top': FixedTop,
		'list-item-with-actions': ListItemWithActions,
		'manage-birthdays': ManageBirthdays,
		'manage-customer': ManageCustomer,
		'manage-customer-add': ManageCustomerAdd,
		'manage-customers-export': ManageCustomersExport,
		'manage-customers-archive': ManageCustomersArchive,
		'invite-customers-via-link': InviteCustomersViaLink,
  },

	props: {
    idc: {required: true},
  },

  data() {
    return {
			render: false,
			infiniteDone: false,

			// Floating action buttons
			fab: {
				position: 'right-bottom',
				buttons: {
					add: {name:'add', label: $LanguageHelper.translate('_ADD_CUSTOMER_'), icon: 'icon ionPersonAdd', render: $UserHelper.hasAccess('users', 'edit', this.idc), clicked: false},
					invite: {name:'invite', label: $LanguageHelper.translate('_INVITE_CUSTOMERS_VIA_LINK_'), icon: 'icon ionPaperPlane', render: $UserHelper.hasAccess('users', 'show', this.idc), clicked: false},
					export: {name:'export', label: $LanguageHelper.translate('_EXPORT_'), icon: 'icon ionDownload', render: $UserHelper.hasAccess('users', 'export', this.idc) && !$Config.app.onDevice, clicked: false},
					archive: {name:'archive', label: $LanguageHelper.translate('_OPEN_ARCHIVE_'), icon: 'icon ionTrash', render: $UserHelper.hasAccess('users', 'edit', this.idc), clicked: false},
				}
			},

			// Sort options
			sortOptions: {
				title: '_SORT_AND_FILTER_',
				options: {
					name_asc: {value: 'name_asc', label: '_SORT_NAME_A_Z_', selected: true},
					name_desc: {value: 'name_desc', label: '_SORT_NAME_Z_A_', selected: false},
					customer_since_desc: {value: 'customer_since_desc', label: '_SORT_CUSTOMER_SINCE_NEWEST_', selected: false},
					customer_since_asc: {value: 'customer_since_asc', label: '_SORT_CUSTOMER_SINCE_OLDEST_', selected: false},
					blocked: {value: 'blocked', label: '_BLOCKED_', selected: false},
					invited: {value: 'invited', label: '_INVITED_', selected: false},
					no_account: {value: 'no_account', label: '_NO_ACCOUNT_', selected: false}
				}
			},

			// Customer data
			customers: {
				total: 0,
				list: [],
				birthdays:[],
				birthdaysOpened: false
			},

			birthdayCustomer: {
				id_customer: null,
				title: '',
				opened: false,
			},
    };
  },

	computed: {
    orderBy() {
			return (Object.values(this.sortOptions.options).find(option => option.selected)).value;
		}
  },

  methods: {
		openCustomer(customer) {
			if($UserHelper.hasAccess('users', 'edit', this.idc)) {
				customer.opened = true;
			}
		},

		openCustomerBirthday(birthday) {
			if($UserHelper.hasAccess('users', 'edit', this.idc)) {
				this.birthdayCustomer.id_customer = birthday.id_user;
				this.birthdayCustomer.title = birthday.name;
				this.birthdayCustomer.opened = true;
			}
		},

		load(firstOrReset = false) {
			if(firstOrReset) this.destroyInfinite();
			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('user', 'loadUsersForAdmin', {idc: this.idc, order: this.orderBy}, {callback: this[firstOrReset ? '_customersInit' : '_customers']});
		},

		storeData(data) {
			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj, key) => {obj.opened = false;});
				this.customers.list.push(...data.resultset);
			} else this.infiniteDone = true;
			if(data.numberCustomers) this.customers.total = data.numberCustomers;
			if(data.birthdays) this.customers.birthdays = data.birthdays;
		},

		// Remove this customer from the list after being archived
		removeListItem(key) {
			// Wait a bit so animated popup close is done first
			setTimeout(() => this.customers.list.splice(key, 1), 200);
		},

		addListItem(customer) {
			// Added needed props needed for opening the customer popup
			customer['opened'] = false;
			customer['id_user'] = customer.id_appuser;

			// Add item to beginning of the list
			this.customers.list.unshift(customer);
		},

		// Replace this customer from in the list after being edited
		replaceListItem(customer) {
			// Wait a bit so animated popup close is done first
			this.customers.list[customer.index] = Object.assign(this.customers.list[customer.index], customer);
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('loadUsersForAdmin');
		},

		sortSelected() {
			this.customers.list = [];

			// Load the new results
			this.load(true);
		},

		searched(val) {
			// Empty current list
			this.customers.list = [];

			// Search for customers
			if(val.length > 0) {
				$AjaxHelper.doRequest('user', 'searchUserForAdmin', {search: val, order: this.orderBy, idc: this.idc}, {callback: this._customersInit});
			}
			// No input, reset the list!
			else {
				this.load(true);
			}
		},

		pageEndReached() {
			this.load();
		},

		// Customers callback
		_customers(data) {
			this.storeData(data);
		},

		// Customers initial callback
		_customersInit(data) {
			this.render = true;
			this.storeData(data);
		},

		go() {
			this.load(true);
		}
	}
};
</script>

<style scoped>
img.customerImage, .ionPerson {
	width: 44px;
	height: 44px;
	border-radius: 50px;
	border: 1px solid #D7D7D7;
}

.listWithActions.customers .ionPerson {
	text-align: center;
	font-size: 30px;
}
</style>
