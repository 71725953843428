<template v-if="visible">
	<div class="pageContent">
    <f7-list inset>
      <f7-list-item accordion-item v-for="(report, key) in reports"
        :key="key"
        :title="report.date_reporting"
        :footer="report.subject"   
        >

        <f7-accordion-content>
					<f7-block>
	          <template v-if="report.goals">
	            <div class="item"><b>{{$LanguageHelper.translate('_GOALS_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(report.goals)}}</div>
	          </template>
	          <template v-if="report.report">
	            <div class="item"><b>{{$LanguageHelper.translate('_REPORTING_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(report.report)}}</div>
	          </template>
	          <template v-if="report.actions">
	            <div class="item"><b>{{$LanguageHelper.translate('_ACTIONS_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(report.actions)}}</div>
	          </template>
	          <template v-if="report.other_info">
	            <div class="item"><b>{{$LanguageHelper.translate('_OTHER_INFO_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(report.other_info)}}</div>
	          </template>
	          <template v-if="report.hidden_comment">
	            <div class="item"><b>{{$LanguageHelper.translate('_NOT_VISIBLE_FOR_CUSTOMER_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(report.hidden_comment)}}</div>
	          </template>
					</f7-block>

          <f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('reporting', 'save', idc)">
								<f7-button @click="editReport(report.id_reporting)" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
							</f7-col>

							<f7-col v-if="$UserHelper.hasAccess('reporting', 'remove', idc)">
								<f7-button @click="deleteReport(report.id_reporting)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <!-- Load if new FAB is clicked -->
		<manage-customer-new-report v-if="fab.buttons.new.clicked"
			:idc="idc"
			:id_customer="id_customer"
      :edit="edit"
      @newReport="newReport"
			@close="fab.buttons.new.clicked=false, edit=null"
		/>
  </div>
</template>

<script>
import ManageCustomerNewReport from '../manage-customer-new-report.vue';

export default {
  components: {
		'manage-customer-new-report': ManageCustomerNewReport,
	},

	props: {
		idc: {required: true},
		id_customer: {required: true},
    fab: {required: true},
	},

  data() {
    return {
      visible: false,
      edit: false,
      reports: [],
    }
  },

  methods: {
    newReport() {
      this.reports = [];
			this.edit = null;
      this.getReports();
    },

    editReport(val) {
      this.edit = val;
      this.fab.buttons.new.clicked = true;
    },

    deleteReport(val) {
      this.reports = [];
      $AjaxHelper.doRequest('gym', 'adminRemoveReportingUser', {idc: this.idc, id_appuser: this.id_customer, id_reporting: val}, {callback: this._reports});
    },

    _reports(data) {
      if(data.status == 'OK') {
        if(data.resultset.length > 0) {
          this.reports = data.resultset;
					this.visible = true;
        }
      }
    },

    getReports() {
      $AjaxHelper.doRequest('gym', 'getReportingsUserForAdmin', {idc: this.idc, id_appuser: this.id_customer}, {callback: this._reports});
    }
  },

  created() {
    this.getReports();
  }
};
</script>
