<template>
	<popup
		:title="$LanguageHelper.translate('_ADD_CUSTOMER_')"
		:opened="opened"
		@mounted="opened=true"
		@close="close"
		>

		<account-form
			:idc="idc"
			:admin="true"
			@close="opened=false"
			@added="added"
		/>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import AccountForm from '../components/account-form.vue';

export default {
	components: {
    'popup': Popup,
    'account-form': AccountForm,
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
    };
  },

	methods: {
		added(customer) {
			this.$emit('added', customer);
		},

		close() {
			this.$emit('close');
		}
	}
};
</script>
