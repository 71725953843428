<template>
  <page
		:title="$LanguageHelper.translate('_QR_EVENTS_')"
		:toolbar="toolbar"
		@sort-selected="setSortSelected"
		@searched="setSearched"
		@infinite-scroll-page-end="pageEndReached=true"
		@initiated="go"
	>
		<template #events v-if="toolbar.tabs.events && toolbar.tabs.events.active">
			<tab-events :idc="idc" :fab="fab.events" :sort-selected="sortSelected" :searched="searched" />
		</template>

		<template #checkins v-if="toolbar.tabs.checkins && toolbar.tabs.checkins.active">
			<tab-checkins :idc="idc" :sort-selected="sortSelected" :searched="searched" :page-end-reached="pageEndReached" @page-end-loaded="pageEndReached=false" />
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabEvents from './tab-events.vue';
import TabCheckins from './tab-checkins.vue';

export default {
  components: {
    'page': Page,
		'tab-events': TabEvents,
		'tab-checkins': TabCheckins,
  },

	props: {
		idc: {required: true},
	},

  data() {
    return {
			sortOptions: null,
			sortSelected: 'date_created_on_desc',
			searched: '',
			fabClicked: null,
			pageEndReached: false,

			fab: {
				events: {
					position: 'right-bottom',
					buttons: {
						add: {name:'add', label: $LanguageHelper.translate('_CREATE_NEW_'), icon: 'icon ionAdd', render: $UserHelper.hasAccess('qr_access', 'save', this.idc), clicked: false},
						archive: {name:'archive', label: $LanguageHelper.translate('_OPEN_ARCHIVE_'), icon: 'icon ionTrash', render: $UserHelper.hasAccess('qr_access', 'remove', this.idc), clicked: false},
					}
				},
			},

			toolbar: {
				tabs: {
					events: {text: $LanguageHelper.translate('_QR_EVENTS_'), icon: 'icon iconScannerQR', render:  $UserHelper.hasAccess('qr_access', 'scan', this.idc), active: false, search: true, sortOptions: null, active: false},
					checkins: {text: $LanguageHelper.translate('_CHECKINS_'), icon: 'icon ionList', render:  $UserHelper.hasAccess('qr_access', 'scan', this.idc), search: true, sortOptions: null, active: false},
				}
			}
    };
  },

	methods: {
		setSearched(val) {
			this.searched = val;
		},

		setSortSelected(val) {
			this.sortSelected = val;
		},

		go() {
			// Set floating action buttons per tab
			this.toolbar.tabs.events.fab = this.fab.events;

			this.toolbar.tabs.events.render = $UserHelper.hasAccess('qr_access', 'scan', this.idc);
			this.toolbar.tabs.checkins.render = $UserHelper.hasAccess('qr_access', 'scan', this.idc);

			// Set sort options for the events tab
			this.toolbar.tabs.events.sortOptions = {
				title: '_SORT_AND_FILTER_',
				options: {
					date_created_on_desc: {value: 'date_created_on_desc', label: '_CREATED_ON_DESC_', selected: true},
					date_created_on_asc: {value: 'date_created_on_asc', label: '_CREATED_ON_ASC_', selected: false},
					name_asc: {value: 'name_asc', label: '_SORT_NAME_A_Z_', selected: false},
					name_desc: {value: 'name_desc', label: '_SORT_NAME_Z_A_', selected: false},
				}
			};

			// Set sort options for the checkins tab
			this.toolbar.tabs.checkins.sortOptions = {
				title: '_SORT_AND_FILTER_',
				options: {
					date_created_on_desc: {value: 'date_checkin_on_desc', label: '_SORT_CHECKIN_DATE_DESC_', selected: true},
					date_created_on_asc: {value: 'date_checkin_on_asc', label: '_SORT_CHECKIN_DATE_ASC_', selected: false},
					name_asc: {value: 'name_event_asc', label: '_SORT_EVENT_NAME_A_Z_', selected: false},
					name_desc: {value: 'name_event_desc', label: '_SORT_EVENT_NAME_Z_A_', selected: false},
				}
			};

			// Set active tab
			if(this.toolbar.tabs.events.render) this.toolbar.tabs.events.active = true;
			else if(this.toolbar.tabs.checkins.render) this.toolbar.tabs.checkins.active = true;

			this.render = true;
		}
	}
};
</script>
