<template>
  <page :title="$LanguageHelper.translate('_MY_PASSWORD_')">
  	<list-form :params="form" @submitted="submitted"></list-form>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
  components: {
    'page': Page,
		'list-form': ListForm
  },

  props: {idc: {required: false}},

  data() {
    return {
			form: {
				fields: {
					pass_current: {type: 'password', label: '_CURRENT_PASSWORD_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					pass: {type: 'password', label: '_NEW_PASSWORD_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					pass2: {type: 'password', label: '_REPEAT_PASSWORD_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {}
			}
    };
  },

  methods: {
    submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			$AjaxHelper.doRequest('user', 'editAccountLoginData', Object.assign({idc: this.idc}, data));
		},
  },
};
</script>
