<template>
  <f7-card v-if="render" inset class="pageContent">
    <f7-card-content>
      <div class="fontRed marginBottom25 nl2br">{{$LanguageHelper.translate('_SEND_MESSAGE_INFO_TEXT_')}}</div>
      <f7-list>
      	<f7-list-item
					:title="$LanguageHelper.translate('_SEND_MESSAGE_TO_')"
					:smart-select-params="{openIn: 'popover', closeOnSelect: true}"
					smart-select
				>
					<select @change="selected($event.target.value)" name="send_to">
						<option name="all_customers" value="1">{{$LanguageHelper.translate('_ALL_MY_CUSTOMERS_')}}</option>
            <option name="select_customers" value="2" :selected="selectedList && selectedList.length > 0 ? 1 : 0">{{$LanguageHelper.translate('_SELECTION_CUSTOMERS_')}}</option>
					</select>
				</f7-list-item>

      <!-- Autocomplete customer-->
      <template v-if="showAutocomplete">
        <li class="item-content item-input">
          <list-autocomplete-form-field
            :label="$LanguageHelper.translate('_SEARCH_CUSTOMER_')"
            :placeholder="$LanguageHelper.translate('_NAME_')"
            :val="$FunctionsHelper.specialChars(selectedCustomer)"
            @changed="selectedCustomer=$event"
            @searched="customerSearched(selectedCustomer)"
          />
        </li>

        <!-- Autocomplete result-->
        <template v-for="(customer) in customers" :key="customer.id_user">
          <f7-list-item v-if="customers.length > 0" class="autocompleteResult"
            :title="$FunctionsHelper.specialChars(customer.name)"
            :text="customer.email"
            link="#"
            @click="customerSelected(customer)"
          />
        </template>

        <!-- chosen result  -->
        <template v-if="chosenCustomers.length > 0">
        	<f7-list-item :title="$LanguageHelper.translate('_SELECTED_')" />

          <f7-list-item v-for="(chosenCustomer, key) in chosenCustomers"
            :key="key"
            :title="`${chosenCustomer.name} (${chosenCustomer.email})`"
          >
            <f7-link class="link theme action" @click="removeCustomer(chosenCustomer.id_user)" :title="$LanguageHelper.translate('_DELETE_')"><f7-icon class="icon ionTrash"></f7-icon></f7-link>
          </f7-list-item>
        </template>
      </template>

      <f7-list-input
				:label="$LanguageHelper.translate('_SUBJECT_')"
				:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
				:value="subject"
				clear-button
				@input="subject=$event.target.value"
      />

	    <f7-list-input
        type="texteditor"
				:textEditorParams="{
					linkUrlText: $LanguageHelper.translate('_INSERT_LINK_URL_'),
					buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'link'],
        }"
				:label="$LanguageHelper.translate('_MESSAGE_')"
				:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
				@texteditor:change="(v) => message = v"
      />

			<f7-list-item :title="$LanguageHelper.translate('_SEND_MESSAGE_AS_')" :smart-select-params="{openIn: 'popover', closeOnSelect: true}" smart-select>
			  <select @change="sendOption=$event.target.value">
					<option value="1" :selected="sendOption==1">{{$LanguageHelper.translate('_PUSH_MESSAGE_AND_EMAIL_')}}</option>
					<option value="2" :selected="sendOption==2">{{$LanguageHelper.translate('_PUSH_MESSAGE_')}}</option>
					<option value="3" :selected="sendOption==3">{{$LanguageHelper.translate('_EMAIL_')}}</option>
				</select>
			</f7-list-item>
		</f7-list>

		<div class="marginBottom10"></div>
  </f7-card-content>

	<f7-block strong>
		<div class="fontRed marginBottom15">{{$LanguageHelper.translate('_JUST_CONNECTED_CUSTOMERS_CAN_RECEIVE_MESSAGES_')}}</div>
		<f7-button @click.prevent="submitted" fill raised>{{$LanguageHelper.translate('_SEND_')}}</f7-button>
	</f7-block>
</f7-card>
</template>

<script>
import ListForm from '../components/smart/list-form.vue';
import ListAutocompleteFormField from '../components/smart/list-autocomplete-form-field.vue';

export default {
  components: {
    'list-form': ListForm,
    'list-autocomplete-form-field': ListAutocompleteFormField,
  },

	props: {
		idc: {required: true},
		selectedList: {required: false}, /* List of pre selected customers */
	},

  data() {
    return {
      render: false,
      showAutocomplete: false,
      subject: '',
      message: '',
      sendOption: 1,
      selectedCustomer: '',
      customers: [],
      chosenCustomers: [],
    }
  },

  methods: {
    // If chosen to send to selection customers show autocomplete
    selected(val) {
			this.chosenCustomers = [];
      this.showAutocomplete = val == 2;
    },

    //remove from chosen customers
    removeCustomer(val) {
      if(val) {
        let chosen = [];
        this.chosenCustomers.forEach((field, index) => {
          if(val != field.id_user) chosen.push(field);
  			});
        this.chosenCustomers = chosen;
      }
    },

    //get customers
    customerSearched(val) {
      if(val) $AjaxHelper.doRequest('Gym', 'getUsersByNameWithAccount', {idc: this.idc, name: val}, {callback: this._customerSearched});
      //empty list customers if autocomplete is empty
      else this.customers = [];
    },

    //add selected customer to chosen customers
    customerSelected(item) {
      this.selectedCustomer = '';
      this.customers = [];
      this.chosenCustomers.push(item);
    },

    //send message
    submitted() {
      let users = [];
      this.chosenCustomers.forEach((field, index) => {
        users.push(field.id_user);
      });

			const data = {idc: this.idc, message: this.message, subject: this.subject, send_option: this.sendOption};
			if(users.length > 0) data.id_users = users;

			this.render = false;
			$AjaxHelper.doRequest('pushMessage', 'sendMessage', data, {callback: (data) => {
				// Reset the form again!
				this.showAutocomplete = false;
				this.subject = '',
	      this.message =  '',
				this.sendOption = 1;
	      this.selectedCustomer = '',
	      this.customers = [],
	      this.chosenCustomers = [],

				this.render = true;

				this.$emit('submitted');
			}});
    },

    //callback searched customers
    _customerSearched(data) {
      this.customers = [];
      if(data.resultset) this.customers = data.resultset;
    },
  },

	mounted() {
		if(this.selectedList && this.selectedList.length > 0) {
			this.selectedList.forEach((customer, index) => {
				this.chosenCustomers.push({id_user: customer.id_appuser, name: customer.name, email: customer.email});
			});

			this.showAutocomplete = true;
		}

		this.render = true;
	}
};
</script>
