<template>
	<popup :title="$LanguageHelper.translate('_CHECKIN_WITH_QR_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form v-if="render" :params="form" @submitted="submitted" @changed="changed" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		events: {required: true},
  },

	data() {
		return {
      render: false,
			opened: false,

			form: {
				fields: {
					events: {type: 'select', name: 'events', title: '_SELECT_QR_EVENT_', render: false, options: []},
          select_product_divider: {type: 'divider', title: '_SELECT_QR_INCHECK_PRODUCTS_TITLE_', render: false},
					qr: {type: 'select', name: 'qr', title: '_SELECT_QR_INCHECK_PRODUCT_OPTION_', render: false, options: []},
				},

				submit: {text: '_SHOW_QR_CODE_', render: false},
				values: {},
			}
		}
	},

	methods: {
		changed(field, option) {
			this.form.submit.render = false;

			if(field.name == 'events') {
				this.setProductsFromEvent(option.value);
			}

			if(field.name == 'qr') {
				this.form.submit.render = option.value != 'none';
			}
		},

		// Set event options here
    setEvents() {
			// Default options
      this.form.fields.events.options.push({
        name: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        value: 'none',
        label: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        selected: false,
      });

      // Options to choose from
      this.events.forEach((event, key) => {
        this.form.fields.events.options.push({
          name: event.event,
          value: key, 
          label: event.event,
          selected: false,
        });
      });

			this.form.fields.events.render = true;
    },

		// Set the products based on selected checkin option
		setProductsFromEvent(selectedEventId) {
			this.form.fields.qr.options = [];
			this.form.fields.select_product_divider.render = false;
			this.form.fields.qr.render = false;
			
			if(selectedEventId == 'none') return;

			this.form.fields.qr.options.push({
        name: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        value: 'none',
        label: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        selected: false,
      });

			// Options to choose from
      this.events[selectedEventId].products.forEach((product) => {
        this.form.fields.qr.options.push({
          name: product.name,
          value: product.qr, 
          label: product.name,
          selected: false,
        });
      });

			this.form.fields.select_product_divider.render = true;
			this.form.fields.qr.render = true;;
		},

		submitted() {
			this.opened = false;
			
			// We need this timeout, otherwise the popup has no time to close and the backbutton will break!
			const qr = JSON.parse(JSON.stringify(this.form.values)).qr;
			const params = $FunctionsHelper.getAllQueryParams(qr);
			const url = `/customer-qr-checkin/idc/${params.idc}/ide/${params.ide}/ids/${params.ids}/t/${params.t}`;
			
			setTimeout(() => $ViewHelper.router.navigate(url, {animate: false}), 500);
		},
	},

  created() {
		this.setEvents();

		this.render = true;
  },
};
</script>
