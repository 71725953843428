<template>
	<div>
		<f7-toolbar position="top" class="toolbarTop agenda backgroundColorWhite no-shadow">
      <!-- Clickable date  and navigation -->
      <div class="wrapperPeriod">
        <table class="center">
          <tr>
            <td class="arrow"><a @click="goBack"><i class="icon ionArrowDropLeft" /></a></td>
            <td class="titleTD"><span class="title">{{period}}</span></td>
            <td class="arrow"><a @click="goForward"><i class="icon ionArrowDropRight" /></a></td>
          </tr>
        </table>
      </div>
    </f7-toolbar>

   <f7-list inset class="marginTop65">
     <f7-list-item v-for="(appointment, index) in appointments"
       accordion-item
       :key="index"
       :title="$FunctionsHelper.specialChars(appointment.name)"
       :footer="`${appointment.date_start} ${appointment.no_show && appointment.no_show == 1 ? ' (No Show)' : ''}`"
     >
       <f7-accordion-content>
         <f7-block class="marginBottom10">
           <div v-if="appointment.partner" class="item">{{$LanguageHelper.translate('_REGISTERED_PARTNER_')}}: {{$FunctionsHelper.specialChars(appointment.username)}}</div>
           <div class="item">{{$LanguageHelper.translate('_DATETIME_START_')}}: {{appointment.time_start}} {{$LanguageHelper.translate('_HOUR_')}}</div>
           <div class="item">{{$LanguageHelper.translate('_DATETIME_END_')}}: {{appointment.time_end}} {{$LanguageHelper.translate('_HOUR_')}}</div>
           <div v-if="appointment.location" class="item">{{$LanguageHelper.translate('_LOCATION_')}}: {{appointment.location}}</div>
           <div class="item">{{$LanguageHelper.translate('_REGISTERED_WITH_')}}: {{appointment.product_name && appointment.product_name != '' ? $FunctionsHelper.specialChars(appointment.product_name) : ''}}</div>
           <div v-if="appointment.number_credits > 0" class="item">{{$LanguageHelper.translate('_CHARGED_')}}: {{appointment.number_credits}} {{appointment.number_credits > 1 ? $LanguageHelper.translate('_CREDITS_') : $LanguageHelper.translate('_CREDIT_')}}</div>
           <div v-else-if="appointment.installments != 1" class="item">{{$LanguageHelper.translate('_REGISTERED_FREE_')}}</div>
           <div v-if="appointment.no_show && appointment.no_show == 1" class="item">{{$LanguageHelper.translate('_NO_SHOW_REGISTRATION_CUSTOMER_MESSAGE_')}}</div>

           <f7-block class="marginTop10">
             <f7-button small raised fill @click="openAppointment(appointment)">{{$LanguageHelper.translate('_VIEW_APPOINTMENT_')}}</f7-button>
           </f7-block>
         </f7-block>
      </f7-accordion-content>

     </f7-list-item>
   </f7-list>

   <!-- customer appointment popup -->
   <customer-agenda-appointment v-if="appointmentOpened"
     :idc="idc"
     :id_agenda="appointment.id_agenda"
     :params="appointment"
     @changed="changed"
     @close="appointmentOpened=false"
   />
 </div>
</template>

<script>
import CustomerAgendaAppointment from '../popups/customer-agenda-appointment.vue';

export default {
  components: {
    'customer-agenda-appointment': CustomerAgendaAppointment,
  },

  props: {
		idc: {required: true},
		pageEndReached: {type: Boolean, required: true, default: false},
	},

  data() {
    return {
			infiniteDone: false,
      appointmentOpened: false,
      appointments: [],
      appointment: null,
      period: '',
      date: moment().format('YYYY-MM-DD'),
    };
  },

	watch: {
		pageEndReached(val) {
			if(val) this.getAppointments();
		}
	},

  methods: {
    changed() {
      this.getAppointments();
			this.$emit('changed');
    },

    goForward() {
      this.date = moment(this.date).add(1, 'months').format('YYYY-MM-DD');
      this.getAppointments(true);
    },

    goBack() {
      this.date = moment(this.date).subtract(1, 'months').format('YYYY-MM-DD');
      this.getAppointments(true);
    },

    openAppointment(item) {
			this.appointment = {
				id_agenda: item.id_agenda,
				id_appointment: item.id_appointment,
				date_start: item.date_start_org, // Actual date (could be different if it's a series)
				date_end: item.date_end_org, // Actual date (could be different if it's a series)
			};

      this.appointmentOpened = true;
    },

    getAppointments(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.appointments = [];
			}

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('agenda', 'ownAppointmentsUser', {idc: this.idc, date: this.date}, {callback: this[firstOrReset ? '_userAppointmentsInit' : '_userAppointments']});
    },

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('ownAppointmentsUser');
		},

    _userAppointments(data) {
			if(data.resultset && data.resultset.length > 0) {
				this.appointments.list.push(...data.resultset);

				//Let our parent know to reset the page end status!
        this.$emit('page-end-loaded');
			} else this.infiniteDone = true;
    },

		_userAppointmentsInit(data) {
			this.period = `${$LanguageHelper.getMonthLabel(moment(this.date).month())}  ${moment(this.date).year()}`;

			if(data.resultset)  {
				this.appointments = data.resultset;

				//Let our parent know to reset the page end status!
				this.$emit('page-end-loaded');
			}
    }
  },

  created() {
    this.getAppointments(true);
  }
};
</script>

<style scoped>
.wrapperPeriod {
  margin: auto;
}
</style>
