import Config from '../config';
import LanguageTags from '../language/languageTags';

export default  {
	current: Config.app.languageDefault,
	tags: LanguageTags,

	availableLanguages:[
		{code: 'en_GB', locale: 'en', name: 'English', name_short: 'ENG', dateFormat: 'YYYY-MM-DD', dateFormatAlt: 'Y-m-d'},
		{code: 'nl_NL', locale: 'nl', name: 'Nederlands', name_short: 'NL', dateFormat: 'DD-MM-YYYY', dateFormatAlt: 'd-m-Y'},
	],

	dayLabels: [
		'_MONDAY_',
		'_TUESDAY_',
		'_WEDNESDAY_',
		'_THURSDAY_',
		'_FRIDAY_',
		'_SATURDAY_',
		'_SUNDAY_',
	],

	monthLabels: [
		'_JAN_',
		'_FEB_',
		'_MAR_',
		'_APR_',
		'_MAY_',
		'_JUNE_',
		'_JULY_',
		'_AUG_',
		'_SEPT_',
		'_OCT_',
		'_NOV_',
		'_DEC_'
	],

	getPhoneCountryCode(countryID) {
		if(countryID == 1) return '31';
		if(countryID == 2) return '32';
		if(countryID == 3) return '49';
		if(countryID == 4) return '33';
		if(countryID == 5) return '34';
	},

	getDayLabel(weekDay) {
		let labelFound = '';
		this.dayLabels.forEach((label, index) => {
			if(weekDay == index) {
				labelFound = label;
			}
		});

		return this.translate(labelFound);
	},

	getMonthLabel(month) {
		let labelFound = '';
		this.monthLabels.forEach((label, index) => {
			if(month == index) {
				labelFound = label;
			}
		});

		return this.translate(labelFound);
	},

	getCurrentLanguageObj() {
		var current = null;
		var that = this;
		$.each(this.availableLanguages, (index, obj) => {
			if(obj.code == that.current) {
				current = obj;
				return;
			}
		});

		return current;
	},

	getDateFormatAlt() {
		return this.getCurrentLanguageObj()['dateFormatAlt'];
	},

	formatDatetime(datetime) {
		return moment(datetime).format(this.getCurrentLanguageObj().dateFormat + ' HH:mm');
	},

	formatDate(date) {
		return moment(date).format(this.getCurrentLanguageObj().dateFormat);
	},

	formatPickerDate(date) {
		return moment(date).format(this.getCurrentLanguageObj().datePickerFormat);
	},

	formatPickerLocal(date) {
		return moment(date).format(this.getCurrentLanguageObj().datePickerLocal);
	},

	init() {
		this.setLanguage(this.current);
	},

	setLanguage(language)	{
		var languageStored = localStorage.getItem('language');
		if (languageStored) {
			this.current = languageStored;
		} else {
			this.saveLanguage(language);
		}
	},

	saveLanguage(language) {
		if(!language || language.length == 0) {
			return false;
		}

		// Change language to camel case
		var pieces = language.split('_');
		pieces.forEach((piece, index) => {
			if(index > 0) pieces[index] = piece.toUpperCase();
		});

		language = pieces.join('_');

		var found = false;
		$.each(this.availableLanguages, (index, obj) => {
			if(obj.code == language) {
				found = true;
			}
		});

		if(!found) {
			return false;
		}

		this.current = language;

		localStorage.setItem('language', language);
		this.setLanguage(language);

  	return true;
	},

	translate(tag) {
		//Initiate the language tags
		LanguageTags.init();

		if(this.tags[this.current] && LanguageTags[this.current][tag]) {
			return LanguageTags[this.current][tag];
		}

		return tag;
	}
};
