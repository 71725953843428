<template>
  <page :title="$LanguageHelper.translate('_COMPANY_ACCOUNT_')" :toolbar="toolbar" @initiated="go">
		<template #settings v-if="toolbar.tabs.settings && toolbar.tabs.settings.active">
			<tab-settings :idc="idc"></tab-settings>
		</template>

    <template #companyInfo v-if="toolbar.tabs.companyInfo && toolbar.tabs.companyInfo.active">
			<tab-company-information :idc="idc"></tab-company-information>
		</template>

		<template #companyAccount v-if="toolbar.tabs.companyAccount && toolbar.tabs.companyAccount.active">
			<tab-company-account :idc="idc"></tab-company-account>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabSettings from './tab-settings.vue';
import TabCompanyInfo from './tab-company-information.vue';
import TabCompanyAccount from './tab-company-account.vue';

export default {
  components: {
    'page': Page,
    'tab-settings': TabSettings,
    'tab-company-information': TabCompanyInfo,
    'tab-company-account': TabCompanyAccount,
  },

	props: {
		idc: {required: true},
	},

  data() {
		return {
			toolbar: {
				tabs:{
          settings: {text: $LanguageHelper.translate('_SETTINGS_'), icon: 'icon ionSettings', active: false, render: $UserHelper.hasAccess('companySettings', 'change', this.idc)},
          companyInfo: {text: $LanguageHelper.translate('_COMPANY_INFO_'), icon: 'icon ionInformationCircle', active: false, render: $UserHelper.hasAccess('companySettings', 'change', this.idc)},
          companyAccount: {text: $LanguageHelper.translate('_COMPANY_ACCOUNT_'), name: 'companyAccount', icon: 'icon ionBriefcase', active: false, render: $UserHelper.hasAccess('payments', 'account', this.idc)},
			  }
      }
    };
  },

	methods: {
		go() {
			if(this.toolbar.tabs.settings.render) this.toolbar.tabs.settings.active = true;
			else if(this.toolbar.tabs.companyInfo.render) this.toolbar.tabs.companyInfo.active = true;
			else if(this.toolbar.tabs.companyAccount.render) this.toolbar.tabs.companyAccount.active = true;
		}
	}
};
</script>
