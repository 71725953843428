<template>
	<popup v-if="visible" :title="$LanguageHelper.translate('_SEARCH_WOD_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
    <!-- Search form -->
    <search v-if="searchEnabled" :enabled="searchEnabled" @searched="searched" :placeholder="$LanguageHelper.translate('_SEARCH_')" />

    <f7-list inset>
      <f7-list-item link="#" @click="clicked(wod.id_wod_whiteboard)" v-for="(wod, key) in wods"
        :key="key"
        :title="wod.name"
        >
      </f7-list-item>
    </f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import Search from '../components/smart/search.vue';

export default {
	components: {
    'popup': Popup,
		'search': Search
	},

	props: {
		idc: {required: true},
  },

	data() {
		return {
			searchEnabled: true,
			opened: false,
      wods: {},
		}
	},

	methods: {
    searched(val) {
      // Empty current list
      this.wods = [];

      // Search for wods
      if(val.length > 0) {
       $AjaxHelper.doRequest('WOD', 'searchWODWhiteBoard', {idc: this.idc, name: val}, {callback: this._initSearchResult});
      }
    },

		clicked(val) {
			this.opened = false;
			this.$emit('searchResult', val);
		},

		_initSearchResult(data) {
			if(data.status == 'OK') {
				if(data.resultset) this.wods = data.resultset;
			}
		},
	},

  created() {
    this.visible = true;
  }
};
</script>
