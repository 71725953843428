<template>
	<div class="cardCenter" v-if="render">
		<f7-card>
			<f7-list v-if="companies && companies.length > 1">
				<li>
					<select name="companies" @change="companySelected">
						<option v-for="(company, key) in companies" :key="key" :value="company.idc" :selected="company.idc == idc">
							{{$FunctionsHelper.specialChars(company.name)}} <span v-if="company.admin == 1"> &#x2699;</span>
						</option>
					</select>
				</li>
			</f7-list>
		</f7-card>

		<f7-card>
			<f7-card-content v-if="summary.bank.has_bank == 1">
				<div class="wrapper paddingTop10">
					<div class="title bold">&euro; {{summary.bank.balance.total}}</div>
					<div>{{$LanguageHelper.translate('_BALANCE_TOTAL_')}}</div>
				</div>

				<div class="wrapper">
					<div class="title bold">&euro; {{summary.bank.balance.available}}</div>
					<div>{{$LanguageHelper.translate('_BALANCE_AVAILABLE_')}}</div>

					<div class="marginTop10">
						<div v-if="summary.bank.clearing_date">{{$LanguageHelper.translate('_CLEARING_SCHEDULED_FOR_')}} {{summary.bank.clearing_date}}</div>
						<div v-else class="red">{{$LanguageHelper.translate('_SCHEDULED_YOUR_CLEARING_')}}</div>
					</div>
					
					<div v-if="summary.bank.comments.length > 0" class="marginTop15">
						<div class="red bold">{{summary.bank.comments}}</div>
					</div>
				</div>

				<div class="wrapper">
					<f7-button small raised fill class="button" :href="toUrlManageCompany(idc)">{{$LanguageHelper.translate('_TO_ADMIN_ENV_')}} ></f7-button>
					<div v-if="userIsConnected(idc)" class="marginTop15"><a class="link" :href="toUrlCompany(idc)">{{$LanguageHelper.translate('_TO_CUSTOMER_ENV_')}} ></a></div>
				</div>
			</f7-card-content>

			<f7-card-content v-else>
				<div class="wrapper">
					<div class="title bold">&euro; {{summary.bank.balance.total}}</div>
					<div>{{$LanguageHelper.translate('_BALANCE_TOTAL_')}}</div>
					<div class="red bold">{{$LanguageHelper.translate('_THE_PAYMENY_MODULE_IS_DISABLED_')}}</div>
					<div><a :href="`mailto:${$Config.apps[$Config.app.currentApp].supportEmail}?subject=${$LanguageHelper.translate('_I_WANT_TO_UPGRADE_PAY')}`" class="link external">{{$LanguageHelper.translate('_I_WANT_TO_UPGRADE_PAY')}} ></a></div>
				</div>
				
				<div class="wrapper marginTop20">
					<f7-button small raised fill class="button" :href="toUrlManageCompany(idc)">{{$LanguageHelper.translate('_TO_ADMIN_ENV_')}} ></f7-button>
					<div v-if="userIsConnected(idc)" class="marginTop15"><a class="link" :href="toUrlCompany(idc)">{{$LanguageHelper.translate('_TO_CUSTOMER_ENV_')}} ></a></div>
				</div>
			</f7-card-content>
		</f7-card>

		<f7-card>
			<f7-card-header>{{$LanguageHelper.translate('_SUBSCRIPTIONS_DIRECT_DEBITS_')}}</f7-card-header>
			<div class="wrapperCardContent">
				<div class="wrapper">
					<div class="title bold">{{summary.subscriptions.total_number}}</div>
					<div>{{$LanguageHelper.translate('_DIRECT_DEBITS_TOTAL_NUMBER_ITEMS_')}}</div>
				</div>
				
				<div class="wrapper">
					<div class="title bold">&euro; {{summary.subscriptions.total_amount}}</div>
					<div>{{$LanguageHelper.translate('_DIRECT_DEBITS_TOTAL_')}}</div>
				</div>
			</div>
			<f7-card-footer><a :href="toUrlManageSubscriptions(idc)" class="link">{{$LanguageHelper.translate('_TO_SUBSCRIPTION_MANAGEMENT_')}} ></a></f7-card-footer>
		</f7-card>

		<f7-card>
			<f7-card-header>{{$LanguageHelper.translate('_OUTSTANDING_BILLS_')}}</f7-card-header>
			<div class="wrapperCardContent">
				<div class="wrapper">
					<div class="title bold">{{summary.outstanding.total_number}}</div>
					<div>{{$LanguageHelper.translate('_UNPAID_')}}</div>
				</div>
				
				<div class="wrapper">
					<div class="title bold">&euro; {{summary.outstanding.total_amount}}</div>
					<div>{{$LanguageHelper.translate('_AMOUNT_OUTSTANDING_')}}</div>
				</div>

				<div v-if="summary.bank.collection_agency.show == 1 && summary.bank.collection_agency.enabled == 0" class="wrapper">
					<div class="red bold">{{$LanguageHelper.translate('_TO_COLLECTION_AGENCY_TITLE_')}}</div>
					<div class="marginTop10 marginBottom25"><f7-button small raised fill class="button" @click="manageCollectionAgencyInfoOpened=true">{{$LanguageHelper.translate('_MORE_INFO_')}}</f7-button></div>
				</div>

				<div v-if="summary.bank.collection_agency.show == 1 && summary.bank.collection_agency.enabled == 1" class="wrapper">
					<div class="marginTop5 marginBottom20">
						<i class="icon ionCheckmarkCircle colorGreenOK"></i><a class="link" @click="manageCollectionAgencyInfoOpened=true">{{$LanguageHelper.translate('_COLLECTION_AGENCY_ENABLED_LINK_')}}</a>
					</div>
				</div>
			</div>
			<f7-card-footer><a :href="toUrlManageAccountancy(idc)" class="link">{{$LanguageHelper.translate('_TO_ACCOUNTING_')}} ></a></f7-card-footer>
		</f7-card>

		<f7-card>
			<f7-card-header>{{$LanguageHelper.translate('_NEWEST_SALES_')}}</f7-card-header>
			<div class="wrapperCardContent">
				<div v-for="(sale, key) in summary.sales" :key="key" class="line">
					<table width="100%">
						<tr>
							<td>
								<div class="bold">{{sale.name}}</div>
								<div>{{sale.created_on}}</div>
							</td>
							<td align="right" valign="top">&euro; {{sale.amount}}</td>
						</tr>
					</table>
				</div>
			</div>
			<f7-card-footer><a :href="toUrlManageSales(idc)" class="link">{{$LanguageHelper.translate('_VIEW_ALL_SALES_')}} ></a></f7-card-footer>
		</f7-card>

		<manage-collection-agency-info v-if="manageCollectionAgencyInfoOpened" :idc="idc" @close="manageCollectionAgencyInfoOpened=false" @changed="collectionAgencyStatusChanged" />
	</div>
</template>

<script>
import ManageCollectionAgencyInfo from '../popups/manage-collection-agency-info.vue';

export default {
	 components: {
    'manage-collection-agency-info': ManageCollectionAgencyInfo,
  },

	props: {
		idc: {required: true},
		pageData: {},
  },

  data() {
    return {
			manageCollectionAgencyInfoOpened: false,
			render: false,
      companies: [],
			summary: null,
    };
  },

  methods: {
		companySelected() {
			let selectedCompany = null;
			this.companies.forEach((company) => {
				if(company.idc == event.target.value) selectedCompany = company;
			});

			if(selectedCompany.admin && selectedCompany.admin == 1) return $ViewHelper.router.navigate(`/home/idc/${event.target.value}`, {animate: false});

			return $ViewHelper.router.navigate(`/company/idc/${event.target.value}`, {animate: false});
		},

		userIsConnected(idc) {
			let connected = false;
			this.companies.forEach((company) => {
				if(company.idc == idc && company.connected == 1) connected = true;
			});

			return connected;
		},

		toUrlManageSales(idc) {
			return `/manage-sales/idc/${idc}/`;
		},

		toUrlManageTransactions(idc) {
			return `/manage-transactions/idc/${idc}/`;
		},

    toUrlCompany(idc) {
      return `/company/idc/${idc}/`;
    },

		toUrlManageCompany(idc) {
      return `/manage-company/idc/${idc}/`;
    },

		toUrlManageSubscriptions(idc) {
			return `/manage-direct-debits/idc/${idc}/`;
		},

		toUrlManageAccountancy(idc) {
			return `/manage-accountancy/idc/${idc}/`;
		},
		
		collectionAgencyStatusChanged() {
			this.$emit('changed');
		}
  },

	created() {
		this.companies = this.pageData.companies;
		this.summary = this.pageData;
		this.render = true;
	}
};
</script>

<style scoped>
.icon {
	margin-right: 9px;
}

select[name="companies"] {
	background-image: url(../static/img/dropdown_arrow.png);
	background-position: 100% 5px;
	background-repeat: no-repeat;
	background-color: #fff;
	width: 100%;
	height: 44px;
	margin-bottom: 0px;
	margin-top: 7px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 2px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-shadow: none;
	border: 0.5px solid #E7E7E7;
	border-bottom: none;
	border-right: none;
	color: var(--f7-list-item-title-text-color);
	cursor: pointer;
}

a.link {
	color: rgb(6 124 216);
}

.title {
	font-size: 24px;
}

.title.small {
	font-size: 20px;
}

.bold {
	font-weight: bold;
}

.button {
	text-transform: none;
	font-size: 14px!important;
}

.red {
	color:#ff0016;
}

.wrapper {
	margin-top: 15px;
}

.wrapper:first-child {
	margin-top: 0;
}

.line {
	margin-top: 0px;
}

.info {
  text-align: left;
  clear: both;
  margin: 5px;
  max-width: 340px;
}

.card-footer {margin-top: 20px;}
.card-header {margin-bottom: 5px;}
</style>

