<template>
	<div>
		<fixed-top :resized="transactions.info.render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
					<table v-if="transactions.info.render">
						<tr>
							<td width="80">&euro; {{transactions.info.amounts.transaction_costs_over_period}}</td>
							<td>{{$LanguageHelper.translate('_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{transactions.info.amounts.invoiced_transaction_costs_over_period}}</td>
							<td>{{$LanguageHelper.translate('_INVOICED_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{transactions.info.amounts.not_transaction_costs_over_period}}</td>
							<td>{{$LanguageHelper.translate('_NOT_INVOICED_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{transactions.info.amounts.transaction_cost_reservations}}</td>
							<td>{{$LanguageHelper.translate('_TRANSACTION_COSTS_RESERVATION_')}}</td>
						</tr>
					</table>
				</f7-list-item>
			</f7-list>

			<list-form :params="form" @changed="changed"></list-form>
		</fixed-top>

		<f7-list media-list inset v-if="render">
			<f7-list-item accordion-item v-for="(transaction, key) in transactions.list"
				:key="key"
				:title="transactionStatus(transaction)"
				:after="`€ ${transaction.amount_for_pnp}`"
				:subtitle="transaction.created_on"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_COST_INVOICE_')}}: {{invoicedStatus(transaction)}}</div>
						<div class="item" v-if="transaction.name && transaction.name.length > 0">{{$LanguageHelper.translate('_NAME_')}}: {{transaction.name}}</div>
						<div class="item" v-if="transaction.sale_id && transaction.sale_id.length > 0">{{$LanguageHelper.translate('_SALE_NUMBER_')}}: {{transaction.sale_id}}</div>
						<div class="item" v-if="transaction.unique_id && transaction.unique_id.length > 0">{{$LanguageHelper.translate('_REFERENCE_')}}: {{transaction.unique_id}}</div>
						<div class="item" v-if="transaction.invoice_number && transaction.invoice_number.length > 0">{{$LanguageHelper.translate('_INVOICE_')}}: {{transaction.invoice_number}}</div>
						<div class="item" v-if="transaction.number_installment && transaction.number_installment > 0">{{$LanguageHelper.translate('_INSTALLMENT_')}}: {{transaction.number_installment}}</div>
						<div class="item">{{$LanguageHelper.translate('_AMOUNT_')}}: &euro; {{transaction.amount}}</div>

						<template v-if="transaction.sales && transaction.sales.length > 0">
							<div class="item marginTop10 bold">{{$LanguageHelper.translate('_SALES_')}}:</div>
							<div v-for="(sale, key) in transaction.sales" :key="key" class="item">{{sale}}</div>
						</template>
					</f7-block>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
	</div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';

export default {
  components: {
		'list-form': ListForm,
    'fixed-top': FixedTop,
  },

	props: {
		idc: {required: true},
		pageEndReached: {type: Boolean, required: true, default: false},
		fab: {type: Object, required: true},
	},

  data() {
    return {
			render: false,
			infiniteDone: false,

			form: {
				fields: {
					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: true,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},

			transactions: {
				info: {
					render: false,
					amounts: {
						vat: 0,
						invoiced_transaction_costs_over_period: 0,
						not_transaction_costs_over_period: 0,
						transaction_cost_reservations: 0,
						transaction_costs_over_period: 0,
					}
				},

				list: []
			}
    };
  },

	computed: {
		dateStart() {
			return this.form.fields.date.fields.date_start.value;
		},

		dateEnd() {
			return this.form.fields.date.fields.date_end.value;
		},

		fabExportClicked() {
			return this.fab.buttons.export.clicked;
		},

		fabDownloadClicked() {
			return this.fab.buttons.download.clicked;
		},
  },

	methods: {
		changed(field) {
			// Initial call is also send from here (change event is fired whenever date values are set)!
			if(field.name == 'dateRange') this.load(true);
		},

		transactionStatus(transaction) {
			return transaction.payment_method + ' | ' + transaction.state + (transaction.id_invoice_pnp > 0 ? ' | ' + $LanguageHelper.translate('_INVOICED_').charAt(0).toUpperCase() + $LanguageHelper.translate('_INVOICED_').slice(1) : '');
		},

		invoicedStatus(transaction) {
			return (transaction.invoice_number_transaction_costs && transaction.invoice_number_transaction_costs != '' ? transaction.invoice_number_transaction_costs : $LanguageHelper.translate('_NOT_INVOICED_YET_'));
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.transactions.list = [];

				// Reset the render to false so the height of fixed top will be recalculated
				this.transactions.info.render = false;
			}

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getTransactionCostSpecifications', this.constructRequestData(), {callback: this._transactions});
		},

		export() {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=exportTransactionCosts&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&date_start=${this.dateStart}&date_end=${this.dateEnd}&language=${$LanguageHelper.current}`);
		},

		download() {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadTransactionCostInvoices&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&date_start=${this.dateStart}&date_end=${this.dateEnd}&language=${$LanguageHelper.current}`);
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getTransactionCostSpecifications');
		},

		constructRequestData() {
			return {
				idc: this.idc,
				order: this.orderBy,
				date_range: {
					 start: this.dateStart,
					 end: this.dateEnd
				}
			};
		},

		storeData(data) {
			this.transactions.info.amounts.vat = data.resultset.btw;
			this.transactions.info.amounts.invoiced_transaction_costs_over_period = data.resultset.invoiced_transaction_costs_over_period;
			this.transactions.info.amounts.not_transaction_costs_over_period = data.resultset.not_transaction_costs_over_period;
			this.transactions.info.amounts.transaction_cost_reservations = data.resultset.transaction_cost_reservations;
			this.transactions.info.amounts.transaction_costs_over_period = data.resultset.transaction_costs_over_period;

			if(data.resultset.transactions && data.resultset.transactions.length > 0) {
				this.transactions.list.push(...data.resultset.transactions);

				//Let our parent know to reset the page end status!
				this.$emit('page-end-loaded');
			} else this.infiniteDone = true;

			this.transactions.info.render = true;
		},

		_transactions(data) {
			this.storeData(data);
		},
	},

	watch: {
		pageEndReached(val) {
			if(val) this.load();
		},

		fabExportClicked(val) {
			if(val) {
				this.export();
				this.fab.buttons.export.clicked = false;
			}
		},

		fabDownloadClicked(val) {
			if(val) {
				this.download();
				this.fab.buttons.download.clicked = false;
			}
		},
	},

	mounted() {
		// For iOS, we want the page transition to be finished first!
		setTimeout(() => this.render = true, 500);
	}
};
</script>
