<template>
	<page
		:title="title"
		:back="false"
		:panel="false"
		:language="true"
		:toolbarBranding="true"
		:backgroundImage="backgroundImage"
		:visible="visible"
		pageContentClass="companyBackground"
	>

		<form v-if="page == 'form'" class="marginTop35">
			<f7-card class="cardCenter">
				<f7-card-header>{{header}}</f7-card-header>
				<f7-card-content class="nl2br">{{description}}</f7-card-content>

				<f7-list inset>
					<f7-list-input @input="form.pass = $event.target.value"
					:label="$LanguageHelper.translate('_PASSWORD_')"
					:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
					type="password"
					autocomplete="off"
					clear-button
					/>

					<f7-list-input @input="form.pass2 = $event.target.value"
						:label="$LanguageHelper.translate('_PASSWORD2_')"
						:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
						type="password"
						autocomplete="off"
						clear-button
					/>

					<li>
						<checkbox-agree-to-terms v-if="userTerms"
							:link="userTerms.userTermsURL"
							:text-label="$LanguageHelper.translate('_AGREE_WITH_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_TERMS2_')"
							:text-link="$FunctionsHelper.specialChars($Config.apps[this.$Config.app.currentApp].displayName)"
							:checked="form.userTerms"
							@changed="(val) => form.userTerms = +val /* Convert bool to int with the + char */"
						/>
					</li>
				</f7-list>

				<f7-block strong>
					<f7-button fill @click.prevent="submitted">{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
				</f7-block>
			</f7-card>
		</form>

		<form v-else-if="page == 'message'" class="marginTop35">
			<message-to-page-button :msg="msg" @click="$ViewHelper.toWindow('/')" :btnText="$LanguageHelper.translate('_GO_TO_HOME_PAGE_')" />
		</form>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import CheckboxAgreeToTerms from '../components/checkbox-agree-to-terms.vue';
import MessageToPageButton from '../components/message-to-page-button.vue';
import LanguageSelect from '../components/language-select-right-corner.vue';

/*
	This page is visited from the outside to generate a (new) password or confirm user his account!

	Possible incoming requests are:
	1: Lost password, create a new one.
	2: Create a password after user created an account himself without a company connection.
	3: Create a password after user created an account himself with a company connection.
	4: Confirm account created by the company for you. Step 1 create your password here. Step 2 forward user to accept invitation page.
	5: Account was created in WAF, user clicked the link in the mail jus to confirm (verify) his account. We only send out a request to confirm this!
*/
export default {
	components: {
    'page': Page,
    'checkbox-agree-to-terms': CheckboxAgreeToTerms,
		'message-to-page-button': MessageToPageButton,
		'language-select-right-corner': LanguageSelect
  },

  data() {
    return {
			page: 'message', // The default page, in case there is a wrong or expired link message from the WS to show!
			visible: false,
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href),
			title: this.$Config.apps[this.$Config.app.currentApp].displayName,
			header: $LanguageHelper.translate('_CREATE_LOGIN_'), // Default header title, will be overwritten if the fromBooking param is set
			description: $LanguageHelper.translate('_PASS_DESCRIPTION_'), // Default description, will be overwritten if the fromBooking param is set
			msg: '',
			userTerms: null,
			backgroundImage: '',
			form: {
				username: '',
				pass: '',
				pass2: '',
				userTerms: false
			},
		};
  },

  methods: {
		submitted() {
			var formData = JSON.parse(JSON.stringify(this.form));

			formData = Object.assign(this.queryParams, formData);
			$AjaxHelper.doRequest('user', 'saveCreateLogin', formData, {callback: this._saveCreateLogin});
		},

		_saveCreateLogin(data) {
			// Set the response notice from the WS if it's there
			if(data.notice) this.msg = data.notice;

			if(data.status == 'OK') {
				// Log user in first, this always happens if the create login was successful.
				$UserHelper.login({
					id_user: data.id_user,
					token: data.token,
					accesslevels: data.accesslevels
				});

				// Redirect user to custom URL if we get this from the back-end (used when a open reservation is booked)
				if(data.redirectURL && data.redirectURL != '') {
					return $ViewHelper.toBrowser(data.redirectURL);
				}

				// Forward user to company invite page if this is requested in the URL
				if(this.queryParams['company-invite'] && this.queryParams['company-invite'] == 1) {
					return $ViewHelper.reloadWindow({page: 'company-invite'});
				}

				// Regular password reset, show the to home page button!
				this.page = 'message';
			}
		},

		_checkCreateLogin(data) {
			// If an account is created already user needs to be forwarded to the invit page?
			if(data.noticeCode && data.noticeCode == 'invalid_link') {
				// Forward user to company invite page if this is requested in the URL (account propably already exists)
				if(this.queryParams['company-invite'] && this.queryParams['company-invite'] == 1) {
					return $ViewHelper.reloadWindow({page: 'company-invite'});
				}
			}

			// Set the response notice from the WS if it's there
			if(data.notice) this.msg = data.notice;

			// Set background images if there are any
			if(data.companyImages && data.companyImages.bg) this.backgroundImage = data.companyImages.bg;

			// Set company name in navbar title if it's there
			if(data.gyminfo) this.title = data.gyminfo.name;

			// Set the PNP user terms, the are here if the gym did a invite and it's a new user!
			if(data.userTerms && Object.keys(data.userTerms).length > 0) this.userTerms = data.userTerms;

			// Make page visible now that everything is loaded!
			this.visible = true;

			// Show the create login form or a message
			if (data.status == 'OK') {
				/*
					The account has been verified (created via WAF)... Show the message page with button to go to home page
					App will automaticly forward user to login-new account if he is not logged in!
				*/
				if(data.result == 'toLogin') this.page = 'message';

				// Show the form page to set a (new) password
				else if(data.result == 'setPassword') this.page = 'form';
			}
		},
	},

	created() {
		//Log user out first
		$UserHelper.logout();

		// Overwrite header and descriptions if the user comes via (to confirm his) booking
		if(this.queryParams.vb && this.queryParams.vb == 1) this.header = $LanguageHelper.translate('_CREATE_LOGIN_VIA_BOOKING_');
		if(this.queryParams.vb && this.queryParams.vb == 1) this.description = $LanguageHelper.translate('_PASS_DESCRIPTION_VIA_BOOKING_');

		var data = {
			u: this.queryParams.u,
      t: this.queryParams.t,
      l: this.queryParams.l,
			v: (this.queryParams.v ? this.queryParams.v : 0), // Account created in WAF? User already agreed to terms, account needs to be verified only!
      idc: (this.queryParams.id ? this.queryParams.id : null),
			companyImages: 1
		};

		$AjaxHelper.doRequest('user', 'checkCreateLogin', data, {callback: this._checkCreateLogin});
	}
};
</script>
