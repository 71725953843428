<template>
	<div>
		<fixed-top :resized="revenue.info.render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
					<div v-for="(revenue, percentage) in revenue.info.amount_vat_percentages" :key="percentage">&euro; {{revenue.amount}} {{$LanguageHelper.translate('_INCLUDING_SHORT_')}} {{percentage}}% {{$LanguageHelper.translate('_VAT_')}} (&euro; {{revenue.amount_vat}})</div>
					<div>&euro; {{revenue.info.amount_total.amount}} {{$LanguageHelper.translate('_REVENUE_OVER_SELECTED_PERIOD_')}} (&euro; {{revenue.info.amount_total.amount_vat}})</div>
				</f7-list-item>
			</f7-list>

			<list-form :params="form" @changed="changed"></list-form>
		</fixed-top>

		<f7-list media-list inset v-if="render">
			<list-item v-for="(revenue, key) in revenue.list" type="accordion"
				:key="key"
				:title="`${revenue.username.length > 0 ? revenue.username : $LanguageHelper.translate('_INDIVIDUAL_SALES_')}`"
				:after="`€ ${revenue.amount}`"
				:subtitle="revenue.created_on_date"
				:text="revenue.name"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_SALE_NUMBER_')}}: {{revenue.sale_id}}</div>
						<div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: <a @click="downloadInvoice(revenue.id_invoice)" class="link theme">{{revenue.invoice_number}}</a></div>
						<div class="item">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}: {{revenue.number_items}}</div>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>
	</div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';
import ListItem from '../../components/smart/list-item.vue';

export default {
  components: {
		'list-form': ListForm,
    'fixed-top': FixedTop,
    'list-item': ListItem,
  },

	props: {
		idc: {required: true},
		sortSelected: {type: String, required: true, default: ''},
		searched: {type: String, required: true, default: ''},
		pageEndReached: {type: Boolean, required: true, default: false},
		fab: {type: Object, required: true},
	},

  data() {
    return {
			render: false,
			infiniteDone: false,

			form: {
				fields: {
					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: true,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},

			revenue: {
				info: {
					render: false,
					amount_total: {
						amount: 0.00,
						amount_vat: 0.00,
					},
					amount_vat_percentages: {}
				},

				list: []
			}
    };
  },

	computed: {
    orderBy() {
			return this.sortSelected;
		},

		dateStart() {
			return this.form.fields.date.fields.date_start.value;
		},

		dateEnd() {
			return this.form.fields.date.fields.date_end.value;
		},

		fabExportClicked() {
			return this.fab.buttons.export.clicked;
		},

		fabDownloadClicked() {
			return this.fab.buttons.download.clicked;
		}
  },

	methods: {
		changed(field) {
			// Initial call is also send from here (change event is fired whenever date values are set)!
			if(field.name == 'dateRange') this.load(true);
		},

		downloadInvoice(id) {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoice&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&id=${id}&language=${$LanguageHelper.current}`);
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.revenue.list = [];
			}

			// Reset the render to false so the height of fixed top will be recalculated
			this.revenue.info.render = false;

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getFinancials', this.constructRequestData(), {callback: this[firstOrReset ? '_financialsInit' : '_financials']});
		},

		export() {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=exportFinancials&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&date_start=${this.dateStart}&date_end=${this.dateEnd}&language=${$LanguageHelper.current}`);
		},

		download() {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoices&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&date_start=${this.dateStart}&date_end=${this.dateEnd}&language=${$LanguageHelper.current}`);
		},

		constructRequestData() {
			return {
				idc: this.idc,
				order: this.orderBy,
				date_range: {
					 start: this.dateStart,
					 end: this.dateEnd
				}
			};
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getFinancials');
		},

		storeData(data, init) {
			if(init) {
				this.revenue.info.amount_vat_percentages = data.resultset.amount_vat_percentages_info;
				this.revenue.info.amount_total = {
					amount: data.resultset.amount,
					amount_vat: data.resultset.amount_vat,
				};

				this.revenue.info.render = true;
			}

			if(data.resultset.sales && data.resultset.sales.length > 0) {
				this.revenue.list.push(...data.resultset.sales);

				//Let our parent know to reset the page end status!
				this.$emit('page-end-loaded');
			} else this.infiniteDone = true;
		},

		_financialsInit(data) {
			this.storeData(data, true);
			this.render = true;
		},

		_financials(data) {
			this.storeData(data);
		}
	},

	watch: {
		searched(val) {
			// Empty current list
			this.revenue.list = [];

			// Search for customers
			if(val.length > 0) {
				$AjaxHelper.doRequest('payment', 'searchFinancials', Object.assign({search: val}, this.constructRequestData()), {callback: this._financialsInit});
			}
			// No input, reset the list!
			else {
				this.load(true);
			}
		},

		sortSelected(val) {
			this.load(true);
		},

		fabExportClicked(val) {
			if(val) {
				this.export();
				this.fab.buttons.export.clicked = false;
			}
		},

		fabDownloadClicked(val) {
			if(val) {
				this.download();
				this.fab.buttons.download.clicked = false;
			}
		},

		pageEndReached(val) {
			if(val) this.load();
		}
	}
};
</script>
