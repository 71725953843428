<template>
  <!-- App -->
  <f7-app v-bind="f7params">
    <!-- Show install prompt if app is not installed yet, delete app via chrome://apps/ -->
    <InstallPrompt />

    <!-- Right Panel -->
    <f7-panel right cover>
      <f7-view v-if="isLoggedIn" url="/panel-right/" />
    </f7-panel>

    <!-- Main View -->
    <f7-view v-if="ready"
      id="main-view"
      url="/"
      main
      class="safe-areas"
      :preloadPreviousPage="false"
      :iosSwipeBack="false"
    />
  </f7-app>
</template>

<script>
import { ref, onMounted } from "vue";
import { f7, f7ready } from "framework7-vue";
import Routes from "../js/routes.js";
import CordovaApp from "../js/cordova-app.js";
import StylesHelper from "../js/helpers/stylesHelper.js";
import InstallPrompt from './smart/install-prompt.vue';
import { EventBus } from "../js/eventBus.js";

export default {
  components: {
    InstallPrompt
  },

  setup() {
    // Set this to true when the page is ready to fire
    const isLoggedIn = ref(false);
    const ready = ref(false);
    
    // Page name in route
    const page = ref("");

    // Framework7 Parameters
    const f7params = {
      id: `io.bothoffmedia.${$Config.app.currentApp}`, // App bundle ID
      name: $Config.apps[$Config.app.currentApp].displayName, // App name
      theme: $Config.app.theme == "detect" ? $ViewHelper.detectTheme() : $Config.app.theme, // Theme based on Config setting
      iosTranslucentBars: false,
      iosTranslucentModals: false,
      routes: Routes,

      navbar: {
        iosCenterTitle: false
      },

      popup: {
        closeByBackdropClick: false
      },

      dialog: {
        buttonOk: $LanguageHelper.translate("_OK_"),
        buttonCancel: $LanguageHelper.translate("_CANCEL_")
      },
    };

    // This function starts everything up
    const go = () => {
      //Add the Framework7 object to the global scope, we need it in some helpers!
      window.$f7 = f7;

      // store the query params we later need!
      const queryParams = $FunctionsHelper.getAllQueryParams(
        window.location.href
      );

      // Init the language helper
      $LanguageHelper.init();

      //Check if a language params is in the URL, if so then overwrite the default and set it automaticly!
      $LanguageHelper.saveLanguage(
        queryParams.language && queryParams.language != ""
          ? queryParams.language
          : null
      );

			//@TODO remove this when we go live this is for testing on a computer
			// $(document).keyup( (e) => {
			// 	if(e.code == 'ArrowLeft') return $ViewHelper.router.backButtonClicked();
			// });

      // onDevice stuff
      if ($Config.app.onDevice) {
        //Native Android back button handler!
        document.addEventListener("backbutton", () => {
          return $ViewHelper.router.backButtonClicked();
        });
      }

      // When prev is clicked in the browser the popstate event if fired from the window obj stack!
      if (!$Config.app.onDevice) {
        window.addEventListener('popstate', () => {
          // Keeps internal track of how many items are left on the stack
          $ViewHelper.router.nativeHistory.itemRemoved();

          // Set reloadFromPageback default to true unless preventBack was set to true, because then page:afterin event will not be triggered
          $ViewHelper.router.nativeHistory.reloadFromPageback = !$ViewHelper.router.nativeHistory.preventBack; // Value will be set to false again in page:afterin
          
          // Call the back button function is preventBack was not set to false
          if(!$ViewHelper.router.nativeHistory.preventBack) {
            // Go back in history here
            $ViewHelper.router.backButtonClicked();
          } 
          
          // Always bring back preventBack to it's default state after were done!
          $ViewHelper.router.nativeHistory.preventBack = false;
        });
      }

      // Write styles (custom colors) when ever the beforein event is called!
      $(document).on("page:beforein", e => {
        // Exclude our side panel here, this is also a considered a page :(
        if (e.target.classList.contains("panelPage")) return;

        // Remove the home class so the Pay n Plan logo doesn't go through the navbar title during page transition!
        if (page.value == "home") {
          $(".home").removeClass("home");
        }

        // Reset the page name to default!
        page.value = "";

        // Write styles for actual page!
        const params = $ViewHelper.router.getParams();
        const router = $ViewHelper.router.getCurrentRoute();

        // Reset the styles if the route says so... Check the routes for a noStyles attribute!
        const resetStyles = (params && params.noStyles) || (router.route && router.route.noStyles);

        StylesHelper.applyStyles(
          params && params.idc
            ? params.idc
            : queryParams.id
            ? queryParams.id
            : null,
          resetStyles
        );   
      });

      // Emit afterin event, we run our first page ajax call after this so we keep a smooth animated transition
      $(document).on("page:afterin", e => {
        isLoggedIn.value = $UserHelper.isLoggedIn();

        // Exclude our side panel here, this is also a considered a page :(
        if (e.target.classList.contains("panelPage")) return;

        // Add current state for native browser back history if it's not home page and also not a back button reload!
        if (!$Config.app.onDevice) {
          // Add history item when a popup is opened at the homepage
          if(!$ViewHelper.router.nativeHistory.reloadFromPageback) {
            if($ViewHelper.router.getCurrentRoute().name != 'home' && $ViewHelper.router.getCurrentRoute().name != 'LoginNewAccount') {
              $ViewHelper.router.nativeHistory.addItem();
            } else if($('.popup').length > 0) {
              $ViewHelper.router.nativeHistory.addItem();
            }
          }
          // Always bring the screen closed by user back to it's default mode again!
          $ViewHelper.router.nativeHistory.reloadFromPageback = false;
        }

	      // Set the page name if available
        page.value = $ViewHelper.router.getCurrentRoute().name
          ? $ViewHelper.router.getCurrentRoute().name
          : "";

        // Add the classes we need to the appRoot element
        const appRootEl = $("#appRoot");
        appRootEl.removeClass().addClass(`${$Config.app.currentApp}`);
        if (page.value != "") {
          appRootEl.addClass(`${page.value}`);
        }

        // Fire page change and initiated events used by popup and panel right!
        EventBus.$emit("page-changed");
        EventBus.$emit("initiated", e);
      });

      // F7 bugfix, remove popup behind class, otherwise popup won't close
      $(document).on("popup:opened", () => {
        $(".popup-behind").removeClass("popup-behind");
      });
    };

    onMounted(() => {
      // Browser stuff we need to handle!
      if (!$Config.app.onDevice) {
        // Install prompt for browser
        window.addEventListener('beforeinstallprompt', (e) => {
          // This prevents from installing automaticaly
          e.preventDefault();
        });
      }

      // Add classname for current app to the body element!
      $("body").addClass($Config.app.currentApp);
    });

    f7ready(() => {
        // Wait for the device ready event to fire and then go!
      if($Config.app.onDevice) {
        document.addEventListener("deviceready", function() {
          // Init cordova plugins (keyboard etc.)
          CordovaApp.init(f7);

          // Go run the app!
          ready.value = true;
          go();
        }, false);
      } else {
        // We need to reset the browsers native history first in order for the back button to work!
        $ViewHelper.router.nativeHistory.resetItems();

        const interval = setInterval( () => {
          if($ViewHelper.router.nativeHistory.getNumberOfItems() == 0) {
            clearInterval(interval);
            
            // Go run the app!
            ready.value = true;
            go();
          }  
        }, 1000);
      }
    });

    return {
      f7params,
      isLoggedIn,
      ready
    };
  }
};
</script>

<style>
:root {
  --background-color: #f7f7f7;
  --background-color-content: #fff;
  --f7-page-bg-color: var(--background-color-content);
  --f7-searchbar-input-font-size: var(--f7-list-item-title-font-size);
  --f7-list-item-title-text-color: rgb(68, 68, 68);
  --f7-navbar-title-margin-left: 15px;
}

.md body {
  --f7-dialog-button-font-size: 13px;
}

body.paynplan {
  --theme-color-org: rgba(43, 129, 220, 1);
  --navbar-bg-color-org: rgba(43, 129, 220, 1);
  --navbar-color-org: rgba(255, 255, 255, 1);
  --btn-bg-color-org: rgba(43, 129, 220, 1);
  --btn-color-org: rgba(255, 255, 255, 1);

  --theme-color: var(--theme-color-org);
  --navbar-bg-color: var(--navbar-bg-color-org);
  --navbar-color: var(--navbar-color-org);
  --btn-bg-color: var(--btn-bg-color-org);
  --btn-color: var(--btn-color-org);
}

body.wodapp {
  --theme-color-org: rgba(153, 1, 0, 1);
  --navbar-bg-color-org: rgba(153, 1, 0, 1);
  --navbar-color-org: rgba(255, 255, 255, 1);
  --btn-bg-color-org: rgba(153, 1, 0, 1);
  --btn-color-org: rgba(255, 255, 255, 1);

  --theme-color: var(--theme-color-org);
  --navbar-bg-color: var(--navbar-bg-color-org);
  --navbar-color: var(--navbar-color-org);
  --btn-bg-color: var(--btn-bg-color-org);
  --btn-color: var(--btn-color-org);
}

body.ptapp {
  --theme-color-org: rgba(81, 174, 51, 1);
  --navbar-bg-color-org: rgba(81, 174, 51, 1);
  --navbar-color-org: rgba(255, 255, 255, 1);
  --btn-bg-color-org: rgba(81, 174, 51, 1);
  --btn-color-org: rgba(255, 255, 255, 1);

  --theme-color: var(--theme-color-org);
  --navbar-bg-color: var(--navbar-bg-color-org);
  --navbar-color: var(--navbar-color-org);
  --btn-bg-color: var(--btn-bg-color-org);
  --btn-color: var(--btn-color-org);
}

body.recurme {
  --f7-theme-color: rgba(4, 215, 160, 1);
  --theme-color-org: rgba(4, 215, 160, 1);
  --navbar-bg-color-org: rgba(4, 215, 160, 1);
  --navbar-color-org: rgba(255, 255, 255, 1);
  --btn-bg-color-org: rgba(4, 215, 160, 1);
  --btn-color-org:  rgba(255, 255, 255, 1);

  --theme-color: var(--theme-color-org);
  --navbar-bg-color: var(--navbar-bg-color-org);
  --navbar-color: var(--navbar-color-org);
  --btn-bg-color: var(--btn-bg-color-org);
  --btn-bg-color: var(--btn-bg-color-org);
  --btn-color: var(--btn-color-org);
}

.navbar,
.navbar-inner {
  background: var(--navbar-bg-color);
  color: var(--navbar-color);
  overflow: visible!important;
}

.button,
.button.active-state {
  color: var(--btn-color);
}
.button.button-fill {
  background: var(--btn-bg-color);
}
.ios .button.button-fill {
  background: var(--btn-bg-color);
  border: 1px solid var(--btn-bg-color);
}
.fab > a,
.fab > a i,
.fab > a.active-state,
.fab-buttons a,
a.fab-label-button.active-state {
  color: var(--btn-color);
}
.fab > a,
.fab > a.active-state,
.fab-buttons a,
a.fab-label-button.active-state {
  background: var(--btn-bg-color);
}

.swiper-pagination-bullet-active {
  background: var(--theme-color);
}
.input-clear-button {
  color: var(--theme-color);
}
.link.theme,
.link.theme .icon {
  color: var(--btn-bg-color) !important;
}

.icon.ion-icon.ionMenu,
.icon.icon-back,
.navbar .right a {
  color: var(--navbar-color);
}

/** Tab colors **/
.tab-link-active i.icon,
.tab-link-active .tabbar-label {
  color: var(--theme-color);
}
.md .tabbar .tab-link-highlight,
.md .tabbar-labels .tab-link-highlight {
  background: var(--theme-color);
}
/** Tab colors **/

/** Input field colors on focus **/
label.item-checkbox input[type="checkbox"]:checked ~ .icon-checkbox,
label.item-checkbox input[type="checkbox"]:checked ~ * .icon-checkbox,
.checkbox input[type="checkbox"]:checked ~ i {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
.md .item-input:not(.item-input-outline) .item-input-wrap:after,
.md .input:not(.input-outline):after {
  background-color: var(--theme-color);
}
/** Input field colors on focus **/

/** Select switch **/
.ios .icon-radio:after {
  color: var(--theme-color);
}
.md label.item-radio input[type="radio"]:checked ~ .icon-radio,
.md label.item-radio input[type="radio"]:checked ~ * .icon-radio,
.md .radio input[type="radio"]:checked ~ .icon-radio {
  border-color: var(--theme-color);
}
.md label.item-radio input[type="radio"]:checked ~ .icon-radio:after,
.md label.item-radio input[type="radio"]:checked ~ * .icon-radio:after,
.md .radio input[type="radio"]:checked ~ .icon-radio:after {
  background-color: var(--theme-color);
}
/** Select switch **/

.md .preloader[class*="color-"] {
  --f7-preloader-color: var(--theme-color);
}
.ios .preloader[class*="color-"] {
  --f7-preloader-color: var(--theme-color);
}

.recurme.home #main-view .navbar .navbar-inner {
  background: #00DC9C url(/static/img/recurme/logo-navbar.png) no-repeat 20px
    calc(12px + var(--f7-safe-area-top));
}
.recurme .page-content.companyBackground {
  background: url(/static/img/recurme/bg.jpg) no-repeat center center;
}

.paynplan.home #main-view .navbar .navbar-inner {
  background: url(/static/img/paynplan/logo-navbar.png) no-repeat 20px
    calc(0px + var(--f7-safe-area-top));
}
.paynplan .page-content.companyBackground {
  background: url(/static/img/paynplan/bg.jpg) no-repeat center center;
}

.wodapp.home #main-view .navbar .navbar-inner {
  background: url(/static/img/wodapp/logo-navbar.png) no-repeat 20px
    calc(0px + var(--f7-safe-area-top));
}
.wodapp .page-content.companyBackground {
  background: url(/static/img/wodapp/bg.jpg) no-repeat center center;
}

.ptapp .page-content.companyBackground {
  background: url(/static/img/ptapp/bg.jpg) no-repeat center center;
}
</style>
