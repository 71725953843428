<template>
	<popup
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="close"
		>

		<account-form
			:id_customer="id_customer"
			:idc="idc"
			:admin="true"
			:editAccount="true"
			@close="opened=false"
			@edited="edited"
		/>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import AccountForm from '../components/account-form.vue';

export default {
	components: {
    'popup': Popup,
    'account-form': AccountForm,
	},

	props: {
		title: {type: String, required: true},
		idc: {required: true},
		id_customer: {required: true},
  },

	data() {
    return {
			opened: false,
    };
  },

	methods: {
		close() {
			this.$emit('close');
		},

		edited() {
			this.opened = false;

			// @HACK We need a timeout, otherwise the popup close event won't be called and our native window stack layer won't be removed!
			setTimeout((() => {
				this.$emit('edited');
			}), 300);
		}
	}
};
</script>
