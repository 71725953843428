<template>
  <page :title="$LanguageHelper.translate('_MY_PURCHASES_')" :toolbar="toolbar" @initiated="go">
		<template #products v-if="toolbar.tabs.products && toolbar.tabs.products.active">
			<tab-products :idc="idc"></tab-products>
		</template>

		<template #invoices v-if="toolbar.tabs.invoices && toolbar.tabs.invoices.active">
			<tab-invoices :idc="idc" :tabInvoicesActive="tabInvoicesActive"></tab-invoices>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabProducts from './tab-products.vue';
import TabInvoices from './tab-invoices.vue';

export default {
  components: {
    'page': Page,
    'tab-products': TabProducts,
    'tab-invoices': TabInvoices,
  },

	props: {
		idc: {required: true},
    tabInvoicesActive: {required: false},
	},

  data() {
		return {
      bankValidated: null,
			toolbar: {
				tabs:{
          products: {text: $LanguageHelper.translate('_PRODUCTS_'), icon: 'icon ionList', active: false, render: false},
          invoices: {text: $LanguageHelper.translate('_INVOICES_'), name: 'invoices', icon: 'icon iconPayments', active: false, render: false},
			  }
      }
    };
  },

  methods: {
    _getCompanyComplete(data) {
       if(data.status == "OK") {
        if(data.gyminfo) this.bankValidated = data.gyminfo.bank_validated;
       }
    },

		go() {
			$AjaxHelper.doRequest('gym', 'payAndOrPlanEnabled', {id: this.idc, idc: this.idc}, {callback: (data) => {
				this.toolbar.tabs.products.render = true;
				this.toolbar.tabs.invoices.render = data.modules.payments == 1;

				// Check if we need to activate the invoices tab (in case of unpaid ones, user clicked on warning)
		    if(this.tabInvoicesActive && this.toolbar.tabs.invoices.render) this.toolbar.tabs.invoices.active = true;
		    else this.toolbar.tabs.products.active = true;
			}});
		}
  }
};
</script>
