<template>
  <popup :title="$LanguageHelper.translate('_FAMILY_MEMBERS_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
	  <f7-card inset class="marginBottom20">
	    <f7-card-content>
	      <div class="item-title"><b>{{$LanguageHelper.translate('_ADD_')}}</b></div>
	      <div class="marginBottom10">
	        <f7-list>
	          <f7-list-input
	            :label="$LanguageHelper.translate('_FIRST_NAME_')"
	            type="text"
	            :value="$FunctionsHelper.specialChars(firstname)"
	            @input="firstname=$event.target.value"
	            :placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
	            clear-button
	          />
	          <f7-list-input
	            :label="$LanguageHelper.translate('_LAST_NAME_')"
	            type="text"
	            :value="$FunctionsHelper.specialChars(lastname)"
	            @input="lastname=$event.target.value"
	            :placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
	            clear-button
	          />
	        </f7-list>

	        <f7-row>
	          <f7-col>
	            <f7-button small raised fill @click="savePartnerUser()">{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
	          </f7-col>
	        </f7-row>
	      </div>

	      <template v-if="partnerUsers && partnerUsers.length > 0">
	        <div class="item-title"><b>{{$LanguageHelper.translate('_EDIT_')}}</b></div>
	        <div v-for="(partner, index) in partnerUsers" :key="index">
	          <f7-list>
	            <f7-list-input
	              :label="$LanguageHelper.translate('_FIRST_NAME_')"
	              type="text"
	              :value="$FunctionsHelper.specialChars(partner.firstname)"
	              @input="partner.firstname = $event.target.value"
	              :placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
	              clear-button
	            />
	            <f7-list-input
	              :label="$LanguageHelper.translate('_LAST_NAME_')"
	              type="text"
	              :value="$FunctionsHelper.specialChars(partner.lastname)"
	              @input="partner.lastname = $event.target.value"
	              :placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
	              clear-button
	            />
	          </f7-list>

	          <f7-row>
	            <f7-col>
	              <f7-button small raised fill @click="savePartnerUser(partner)">{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
	            </f7-col>
	            <f7-col>
	              <f7-button small raised fill @click="removePartnerUser(partner)">{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
	            </f7-col>
	          </f7-row>
	        </div>
	      </template>
	    </f7-card-content>
	  </f7-card>
  </popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
  components: {
    'popup': Popup,
  },

  props: {
		idc: {required: true},
	},

  data() {
    return {
      opened: false,
      partnerUsers: [],
      firstname: '',
      lastname: '',
    };
  },

  methods: {
    savePartnerUser(partner) {
      let data = {
				idc: this.idc,
				action: 'save-user-partner',
				firstname: partner ? partner.firstname : this.firstname, // Existing or new one
				lastname: partner ? partner.lastname : this.lastname, // Existing or new one
			};

			if(partner) data.id_partner = partner.id_appuser_partner_user;

      $AjaxHelper.doRequest('user', 'submitPartnerUser', data, {callback: this._partnerSubmitted});
    },

    removePartnerUser(partner) {
      $ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('user', 'submitPartnerUser', {idc: this.idc, action: 'remove-user-partner', id_partner: partner.id_appuser_partner_user}, {callback: this._partnerSubmitted});
			}});
    },

    fetchPartners() {
      $AjaxHelper.doRequest('user', 'loadPartnerUsers', {idc: this.idc}, {callback: this._partnerUsers});
    },

    _partnerSubmitted(data) {
			if(data.status == 'OK') {
				this.opened = false;
				this.$emit('changed');
			}
    },

    _partnerUsers(data) {
      this.partnerUsers = [];
      this.firstname = '';
      this.lastname = '';
      if(data.resultset) this.partnerUsers = data.resultset;
    },
  },

  created() {
    this.fetchPartners();
  }
};
</script>
