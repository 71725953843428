<template v-if="visible">
	<div class="pageContent">
    <f7-list inset>
      <f7-list-item accordion-item v-for="(intake, key) in intakes"
        :key="key"
        :title="intake.date_intake"
        >

        <f7-accordion-content>
					<f7-block>
	          <template v-if="intake.background">
	            <div class="item"><b>{{$LanguageHelper.translate('_BACKGROUND_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.background)}}</div>
	          </template>
	          <template v-if="intake.goals">
	            <div class="item"><b>{{$LanguageHelper.translate('_HELP_GOALS_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.goals)}}</div>
	          </template>
	          <template v-if="intake.injuries">
	            <div class="item"><b>{{$LanguageHelper.translate('_INJURIES_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.injuries)}}</div>
	          </template>
	          <template v-if="intake.doctor_treatments">
	            <div class="item"><b>{{$LanguageHelper.translate('_DOCTOR_TREATMENTS_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.doctor_treatments)}}</div>
	          </template>
	          <template v-if="intake.diseases">
	            <div class="item"><b>{{$LanguageHelper.translate('_DISEASES_CONDITIONS_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.diseases)}}</div>
	          </template>
	          <template v-if="intake.medications">
	            <div class="item"><b>{{$LanguageHelper.translate('_MEDICATIONS_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.medications)}}</div>
	          </template>
	          <template v-if="intake.program">
	            <div class="item"><b>{{$LanguageHelper.translate('_AGREEMENTS_PROGRAM_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.program)}}</div>
	          </template>
	          <template v-if="intake.other_info">
	            <div class="item"><b>{{$LanguageHelper.translate('_OTHER_INFO_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.other_info)}}</div>
	          </template>
	          <template v-if="intake.hidden_comment">
	            <div class="item"><b>{{$LanguageHelper.translate('_NOT_VISIBLE_FOR_CUSTOMER_')}}</b></div>
	            <div class="item marginBottom10">{{$FunctionsHelper.specialChars(intake.hidden_comment)}}</div>
	          </template>
					</f7-block>

          <f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('intake', 'save', idc)">
								<f7-button @click="editIntake(intake.id_intake)" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
							</f7-col>

							<f7-col v-if="$UserHelper.hasAccess('intake', 'remove', idc)">
								<f7-button @click="deleteIntake(intake.id_intake)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>

    <!-- Load if new FAB is clicked -->
		<manage-customer-new-intake v-if="fab.buttons.new.clicked"
			:idc="idc"
			:id_customer="id_customer"
      :edit="edit"
      @newIntake="newIntake"
			@close="fab.buttons.new.clicked=false, edit=null"
		/>
  </div>
</template>

<script>
import ManageCustomerNewIntake from '../manage-customer-new-intake.vue';

export default {
  components: {
		'manage-customer-new-intake': ManageCustomerNewIntake,
	},

	props: {
		idc: {required: true},
		id_customer: {required: true},
    fab: {required: true},
	},

  data() {
    return {
      visible: false,
      edit: null,
      intakes: [],
    }
  },

  methods: {
    newIntake() {
      this.intakes = [];
			this.edit = null;
      this.getIntakes();
    },

    editIntake(val) {
      this.edit = val;
      this.fab.buttons.new.clicked = true;
    },

    deleteIntake(val) {
      this.intakes = [];
      $AjaxHelper.doRequest('gym', 'adminRemoveIntakeUser', {idc: this.idc, id_appuser: this.id_customer, id_intake: val}, {callback: this._intakes});
    },

    _intakes(data) {
      if(data.status == 'OK') {
        if(data.resultset.length > 0) {
          this.intakes = data.resultset;
					this.visible = true;
        }
      }
    },

    getIntakes() {
      $AjaxHelper.doRequest('gym', 'getIntakesUserForAdmin', {idc: this.idc, id_appuser: this.id_customer}, {callback: this._intakes});
    }
  },

  created() {
    this.getIntakes();
  }
};
</script>
