<template>
  <page
		:renderNavbar="!queryParams.external"
    :dynamic-navbar-component="navbarList"
    :sort-options="navbarSelectViewOptions"
    @sort-selected="navbarViewSelected"
    @dynamic-navbar-component-changed="navbarComponentChanged"
		@initiated="go"
    :fab="fab.agenda"
		:toolbar-branding="queryParams.external ? true: false"
		:pageClass="queryParams.external ? 'noNavbar' : ''"
  >
		<!-- Agenda contents -->
		<div class="agenda" :class="current.view" v-if="render">
			<!-- Hidden input field to open the calendar when we click the date at the top bar -->
			<date-time-list-input v-if="renderDateListInput" type="date" :value="current.date" :wrapperHTML="false" @set="calendarDateSelected" />

			<!-- Day view -->
			<agenda-view-day v-if="current.view == 'day'" :manage="manage" :params="current" :idc="this.idc" @period-clicked="periodClicked" @paginated="paginated" @changed="fetchAppointments" />

      <!-- Week view -->
      <agenda-view-week v-if="current.view == 'week'" :manage="manage" :params="current" :idc="this.idc" @period-clicked="periodClicked" @paginated="paginated" @changed="fetchAppointments" />

			<!-- List view -->
			<agenda-view-list v-if="current.view == 'list'" :manage="manage" :params="current" :idc="this.idc" @period-clicked="periodClicked" @paginated="paginated" @changed="fetchAppointments" />
		</div>

    <!-- Manage new appointment popup -->
    <manage-agenda-appointment v-if="fab.agenda.buttons.add.clicked"
      :idc="idc"
      :id_agenda="current.id"
			@changed="fetchAppointments"
      @close="fab.agenda.buttons.add.clicked=false"
    />

    <!-- Manage appointment types -->
    <manage-agenda-appointment-types v-if="fab.agenda.buttons.appointment_types.clicked"
      :idc="idc"
      @changed="fetchAppointments"
      @close="fab.agenda.buttons.appointment_types.clicked=false"
    />

    <!-- Manage agenda settings popup -->
    <manage-agenda-settings v-if="fab.agenda.buttons.settings.clicked"
      :idc="idc"
      :id_agenda="current.id"
      @changed="fetchAppointments"
      @close="fab.agenda.buttons.settings.clicked=false"
    />

    <!-- Customer my appointments popup -->
    <customer-agenda-my-appointments v-if="fab.agenda.buttons.my_appointments.clicked"
      :idc="idc"
      @changed="fetchAppointments"
      @close="fab.agenda.buttons.my_appointments.clicked=false"
    />

		<!-- Agenda filters popup-->
    <filter-agenda v-if="fab.agenda.buttons.filter.clicked"
      :filters="filters"
      @submitted="fetchAppointments"
      @close="fab.agenda.buttons.filter.clicked=false"
    />
	</page>
</template>

<script>
import { EventBus } from '../js/eventBus.js';
import AppointmentTableHelper from '../js/helpers/appointmentTableHelper.js';
import Page from '../components/smart/page.vue';
import DateTimeListInput from '../components/smart/date-time-list-input.vue'
import ManageAppointment from '../popups/manage-agenda-appointment.vue';
import ManageAppoinmentTypes from '../popups/manage-agenda-appointment-types.vue';
import ManageSettings from '../popups/manage-agenda-settings.vue';
import CustomerMyAppointments from '../popups/customer-agenda-my-appointments.vue';
import AgendaViewDay from '../components/agenda-view-day.vue';
import AgendaViewWeek from '../components/agenda-view-week.vue';
import AgendaViewList from '../components/agenda-view-list.vue';
import FilterAgenda from '../popups/filter-agenda.vue';

export default {
  components: {
		'page': Page,
		'date-time-list-input': DateTimeListInput,
    'manage-agenda-appointment': ManageAppointment,
    'manage-agenda-appointment-types': ManageAppoinmentTypes,
    'manage-agenda-settings': ManageSettings,
    'customer-agenda-my-appointments': CustomerMyAppointments,
    'agenda-view-day': AgendaViewDay,
    'agenda-view-week': AgendaViewWeek,
    'agenda-view-list': AgendaViewList,
    'filter-agenda': FilterAgenda,
  },

  props: {
		idc: {required: true},
    id: {required: true}, // Agenda ID
		manage: {required: true}, // Via manage route? 1 or 0
	},

  data() {
    return {
			render: false,
			renderDateListInput: true,
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href),

			current: {
				id: this.id, // Agenda ID
				date: '',
				view: 'week',
				views: ['day', 'week', 'list'],
				data: {}, // Resultset from back-end
				settings: { // Settings from back-end
					backgroundColor: '',
					appointmentStatusFullColor: false,
				},

				earliest: 0 // Earliest appointment, used for auto scrolling into view
			},

			// All agenda employees, set after init
			employees: null,

			navbarList: {
        name: 'agenda-navbar-switch',
        params: {
          agendas: [],
        },
      },

      // Options to change view (week, day, list)
			navbarSelectViewOptions: {
				title: '_AGENDA_VIEW_',
        icon: 'icon iconAgenda',
				options: [
					{name: 'week', value: 'week', label: '_WEEK_', selected: false},
					{name: 'day', value: 'day', label: '_DAY_', selected: false},
					{name: 'list', value: 'list', label: '_LIST_', selected: false},
				]
			},

			// Search filters
			filters: {
				// Agenda specific employees set when agenda changes
				employees: {
					list: [],
					selected: [],
				}
			},

      fab: {
        agenda: {
          position: 'right-bottom',
          buttons: {
						add: {name:'add', icon: 'icon ionAdd', label: $LanguageHelper.translate('_NEW_APPOINTMENT_'), render: ($UserHelper.hasAccess(`agenda_${this.id}`, 'saveAppointment', this.idc) && this.manage == 1), clicked: false},
						appointment_types: {name:'appointment_types', icon: 'icon ionOptions', label: $LanguageHelper.translate('_APPOINTMENT_TYPES_'), render: ($UserHelper.hasAccess('agenda', 'settings', this.idc) && this.manage == 1), clicked: false},
            settings: {name:'settings', icon: 'icon ionSettings', label: $LanguageHelper.translate('_SETTINGS_'), render: ($UserHelper.hasAccess('agenda', 'settings', this.idc) && this.manage == 1), clicked: false},
						filter: {name:'filter', icon: 'icon ionFunnel', label: $LanguageHelper.translate('_FILTER_APPOINTMENTS_'), render: true, clicked: false},
						my_appointments: {name:'my_appointments', icon: 'icon ionList', label: $LanguageHelper.translate('_MY_APPOINTMENTS_'), render: false, clicked: false},
          }
        }
      },
    }
  },

  methods: {
		setFilters() {
			this.filters.employees.list = this.currentEmployees();
		},

		hasFilters() {
			return this.currentEmployees().length > 0;
		},

		currentEmployees() {
			// Return the employees for current selected agenda
			if(this.employees && this.employees[this.current.id]) {
				return Object.values(this.employees[this.current.id]);
			}

			return [];
		},

		periodClicked() {
			// Make the hidden input think it was clicked, trick to open it :)
			$('.calendarHidden .form-control').click();
		},

		calendarDateSelected(date, numberEmits) {
			// Don't use it the first time, when component is created it will automaticly fire an event
			if(numberEmits > 1) {
				this.setStartDate(moment(date).format('YYYY-MM-DD'));
				this.fetchAppointments(true);
			}
		},

		paginated(direction) {
			let date = null;
			if(this.current.view == 'week' || this.current.view == 'list') {
				if(direction == 'forward') {
					date = moment(this.current.date).add(1, 'w').format('YYYY-MM-DD');
				} else {
					date = moment(this.current.date).subtract(1, 'w').format('YYYY-MM-DD');
				}
			}

			if(this.current.view == 'day') {
				if(direction == 'forward') {
					date = moment(this.current.date).add(1, 'd').format('YYYY-MM-DD');
				} else {
					date = moment(this.current.date).subtract(1, 'd').format('YYYY-MM-DD');
				}
			}

			this.setStartDate(date);
			this.fetchAppointments(true);
		},

		// Switch to selected agenda
    navbarComponentChanged(item) {
			this.navbarList.params.agendas.forEach((agenda) => {
        agenda.checked = false;

				// Selected agenda found
        if(agenda.id == item.id) {
          agenda.checked = true;

					// Set the agenda id and fetch the appointments for selected agenda
					this.current.id = item.id;

					// Reset the earliest scroll position
					this.current.scrollTo = null;

					// Reset the selected employees in filter
					this.filters.employees.selected = [];

					// Go fetch the appointments
					this.fetchAppointments(true);
        }
      });
		},

		// Change the view and fetch the appointments again
    navbarViewSelected(view) {
			// Reset the earliest scroll position
			this.current.earliest = 0;

			// Change the curren view to selected one
			this.setView(view);

			// Set the start date and fetch appointmentName
			this.setStartDate(this.current.date, true);

			// Set render to false so we won't see half a page before load is finished!
			this.render = false;

			// Go fetch ...
			this.fetchAppointments(true);
		},

		setView(view) {
			// Change this to list (listview is old but still used by back-end)
			if(view.toLowerCase() == 'listview') view = 'list';

			// Only set the mode if its in the allowed array!
			if(this.current.views.indexOf(view) >= 0) this.current.view = view;

			// Set the selected option to true
			this.navbarSelectViewOptions.options.forEach((option, key) => option.selected = option.value == this.current.view);
		},

		// Called whenever we change the view, click the calendar of paginate!
		setStartDate (val, viewChange = false) {
			let date = moment(val);

			// If the full date param is in the URL use that one!
			if(!val && this.queryParams.fd) {
				date = moment(this.queryParams.fd);
			}

			// Set the current date value dependend on the current view
			if(this.current.view == 'week' || this.current.view == 'list') {
				this.current.date = date.startOf('iso' + 'week').format('YYYY-MM-DD');
			} else if(this.current.view == 'day') {
				this.current.date = date.format('YYYY-MM-DD');

				// If today falls into the current week then set the current date value to today!
				if(moment().isSame(moment(this.current.date), 'week') && viewChange) {
					this.current.date = moment().format('YYYY-MM-DD');
				}

			} else if(this.current.view == 'appointments') {
				this.current.date = date.startOf('iso' + 'month').format('YYYY-MM-DD');
			}
		},

    // Set agendas for navbar
    storeNavbar(agendas) {
			// First empty agendas
			this.navbarList.params.agendas = [];

			// Now store them again
			agendas.forEach((agenda) => {
				this.navbarList.params.agendas.push({
					title: agenda.name,
					id: agenda.id_agenda,
					checked: agenda.id_agenda == this.current.id,
				});
			});
    },

		scrollIntoView() {
			let interval = setInterval(() => {
				if($('.agenda .wrapperAppointments').length <- 0) return;
				clearInterval(interval);

				let scrollValue = 0;
				if(this.current.view  == 'week' || this.current.view  == 'day') {
					let height = $('.agenda .wrapperMain').height();
					let dateScrollTo = new Date(this.current.earliest);

					let minutesTotal = 24 * 60; //1440
					let minutesAppointment = parseInt(dateScrollTo.getHours() * 60) + parseInt(dateScrollTo.getMinutes());
					let percentage = 100 / ((minutesTotal) / minutesAppointment);

					// Set the scroll value here.
					scrollValue = (height / 100 * percentage);
				}

				// If current view is list and today is in current week then scroll to today
				if(this.current.view == 'list' && moment().isSame(moment(this.current.date), 'week')) {
					let currentDayNumber = moment().isoWeekday();
					$.each($('.agenda.list .wrapperAppointments'), (index, dayEl) => {
						if((index + 1) < currentDayNumber) scrollValue += $(dayEl).height();
					});
				}

				// Scroll to earliest calculated appointment!
				$ViewHelper.scrollTo(scrollValue);

				// Emit the auto scroll done event so the FAB will show again!
				setTimeout(() => {EventBus.$emit('auto-scroll-done')}, 50)
			}, 1);
		},

		// Called on first render, get the settings for requested agenda!
		init() {
			$AjaxHelper.doRequest('agenda', 'initSettings', {idc: this.idc, id_agenda: this.current.id}, {callback: (data) => {
        // Set the correct view (mode)
				if(this.queryParams.mode) {
					this.setView(this.queryParams.mode);
				} else if(data.settings.mode) {
					this.setView(data.settings.mode);
				}

				// Set the employees for all agenda's
				this.employees = data.employees;

				// Set the start date
				this.setStartDate(moment().format('YYYY-MM-DD'), true);

				// Fetch the appointments
				this.fetchAppointments(true);
			}});
		},

		fetchAppointments(scrollIntoView = false) {
			let data = {
				idc: this.idc,
				type: 'gym', // WS expects this paramater but it is acutally deprecated :(
				id_agenda: this.current.id,
				dateInfo: {date: this.current.date},
				filters: {
					employees: this.filters.employees.selected
				}
			};

			// Destroy the hidden date field so new date will be set when we render it again
			this.renderDateListInput = false;

			// The method depends on the view (week, day, list)
			let view = this.current.view;
			if(view == 'list') view = 'listView'; // Back-end uses listView

			$AjaxHelper.doRequest('agenda', view, data, {callback: (data) => {
				// Store agenda settings here, this is always the same.
				if(data.settings) {
					// Status full color or not?
					this.current.settings.appointmentStatusFullColor = data.settings.agenda_appointments_fullcolor == 1 ? true : false;
				}

				// Set the agenda background color if it's here!
				if(data.agendaSettings && data.agendaSettings.color_background) this.current.settings.backgroundColor = data.agendaSettings.color_background;

				// Set the background to empty if this default color comes from back-end (change this later on back-end)
				if(this.current.settings.backgroundColor == 'rgb(247, 247, 247)') this.current.settings.backgroundColor = '';

				// Store the navbar title settings
				if(data.agendas) this.storeNavbar(data.agendas.resultset);

				// Set the data here, the view will automaticly re-render
				this.current.data = this.structureAppointments(data.resultset);

				// Render output
				this.render = true;

				// Render hidden date input field for calendar date select
				this.renderDateListInput = true;

				// Set filters (again)
				this.setFilters();

				// Show the filters tab if there is anything to filter
				this.fab.agenda.buttons.filter.render = this.hasFilters();

				// Scroll first appointment in viewport
				if(data.earliest && data.earliest > 0) this.current.earliest = data.earliest;
				if(scrollIntoView) this.scrollIntoView();
			}});
		},

		go() {
			// Show my appointments FAB when logged in, no management and not external (embedded)
			this.fab.agenda.buttons.my_appointments.render = $UserHelper.isLoggedIn() && this.manage == 0 && !this.queryParams.external

			// Get the initial settings so we can start rendering
			this.init();
		},

		showNumberSubscriptions(appointment) {
			if((appointment.public && appointment.public == 1) || $UserHelper.hasAccess(`agenda_${this.id}`, 'viewSubscriptions', this.idc)) return true;

			return false;
		},

		structureAppointments(data) {
			let newData = [];

			// Structure for the week view
			if(this.current.view == 'week') {
				for(var i = 1; i <= 7; i ++) {
					if(data[i]) {
						var table = new AppointmentTableHelper(data[i]);
						$.each(data[i], (index, appointment) => {
							// Show the number of subscriptions? Only if management acces or is set to public
							appointment.showNumberSubscription = this.showNumberSubscriptions(appointment);

							// Get the proper alignments
							appointment.align = table.getAlignment(appointment.id_appointment);
							if(!newData[i -1]) newData[i - 1] = [];

							newData[i - 1].push(appointment);
						});
					} else {
						newData[i - 1] = {};
					}
				}
			}

			// Structure for the list view
			if(this.current.view == 'list') {
				for(var i = 1; i <= 7; i ++) {
					if(data[i]) {
						$.each(data[i], (index, appointment) => {
							// Show the number of subscriptions? Only if management acces or is set to public
							appointment.showNumberSubscription = this.showNumberSubscriptions(appointment);

							if(!newData[i -1]) newData[i - 1] = [];
							newData[i - 1].push(appointment);
						});
					} else {
						newData[i - 1] = [];
					}
				}
			}

			// Structure for the day view
			if(this.current.view == 'day') {
				var table = new AppointmentTableHelper(data);
				$.each(data, (index, appointment) => {
					// Show the number of subscriptions? Only if management acces or is set to public
					appointment.showNumberSubscription = this.showNumberSubscriptions(appointment);

					// Get the proper alignments
					appointment.align = table.getAlignment(appointment.id_appointment);

					// Store appointment in array
					newData.push(appointment);
				});
			}

			return newData;
		}
  }
}
</script>

<style>
.ios .navbar .agendaList {margin-left: 10px;}

.agenda .toolbarTop .toolbar-inner {
	padding-left: 0;
	padding-right: 0;
}

.agenda .wrapperPeriod .center {
	margin: auto;
}

.agenda .wrapperPeriod .titleTD {
	width: 250px;
	text-align: center;
}

.agenda .wrapperPeriod td.arrow {
	width: 40px;
	text-align: center;
}

.agenda .wrapperPeriod .icon {
	color: rgba(0, 0, 0 , 0.54);
}

.agenda .toolbarTop {
	border-bottom: 1px solid #c8c7cc6e;
}

.agenda .toolbarTop .period {
	text-align: center;
	padding-top: 10px;
}

.wrapperToolbar .toolbarDays .date {
	text-align: center;
	font-size: 14px;
}

.agenda.week .wrapperHours, .agenda.day .wrapperHours {
	background: #fff;
	grid-column: 1/2;
}

.agenda.week .wrapperHours .hour, .agenda.day .wrapperHours .hour {
	height: 60px;
	text-align: center;
	border-bottom: 1px solid #c8c7cc6e;
}

.agenda.week .wrapperHours .title, .agenda.day .wrapperHours .title {
	height: 100%;
	display: flex;
  align-items: center;
  justify-content: center;
}

.agenda.week, .agenda.day {background: #c8c7cc6e!important;}

/* Hide the calendar input field (we need it to open the calendar but we don't want to show it)  */
.agenda .vdatetime-input {display: none;}

.today {color: var(--theme-color);}

/** Period bar **/
.wrapperPeriod {
	padding-top: 0px;
}

.wrapperPeriod .title {
	text-align: center;
	font-size: 16px;
	cursor: pointer;
}
/** Period bar **/

/* Overwrite default F7 display flex */
.agenda .toolbarTop.agenda .toolbar-inner {display: block;}

.wrapperAppointments {
	position: relative;
}

/* Default color could be overwritten by company settings */
.agenda .backgroundColorEditable {background: #fff;}

.agenda .appointment {
	border: 1px solid #c8c7cc6e;
	cursor: pointer;
	background: #fff;
	border-radius: 3px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	overflow: hidden;
}

/** Give opacity to appointments that are already over **/
.agenda .appointment.over {opacity: .7;}

.agenda .appointment .relative {height: 100%;}

.agenda .appointment .name {overflow: hidden;}

.agenda .appointment .relative .numberSubscriptions {
	background: #fff;
	font-weight: normal;
	font-size: 11px;
	position: absolute;
	bottom: 0;
	right: 2px;
	padding-left: 2px;
}

.agenda .appointment.open.statusFullcolor {background: #51e268; color: #fff;}
.agenda .appointment.open.statusFullcolor .numberSubscriptions {background: #51e268; color: #fff;}

.agenda .appointment.closed.statusFullcolor {background: rgb(234, 30, 67); color: #fff;}
.agenda .appointment.closed.statusFullcolor .numberSubscriptions {background: rgb(234, 30, 67); color: #fff;}

.agenda .appointment.subscribed.statusFullcolor {background: rgb(251, 215, 91); color: #fff;}
.agenda .appointment.subscribed.statusFullcolor .numberSubscriptions {background: rgb(251, 215, 91); color: #fff;}

.agenda .appointment.waiting.statusFullcolor {background: #ff9900; color: #fff;}
.agenda .appointment.waiting.statusFullcolor .numberSubscriptions {background: #ff9900; color: #fff;}

.agenda .appointment.open {border-bottom: 4px solid #51e268;}
.agenda .appointment.closed {border-bottom: 4px solid rgb(234, 30, 67);}
.agenda .appointment.subscribed {border-bottom: 4px solid rgb(251, 215, 91);}
.agenda .appointment.waiting {border-bottom: 4px solid #ff9900;}

.agenda.list .appointment.open {border-bottom: 6px solid #51e268;}
.agenda.list .appointment.closed {border-bottom: 6px solid rgb(234, 30, 67);}
.agenda.list .appointment.subscribed {border-bottom: 6px solid rgb(251, 215, 91);}
.agenda.list .appointment.waiting {border-bottom: 6px solid #ff9900;}

/** Clear the bottom border for full color status div **/
.agenda.week .appointment.statusFullcolor, .agenda.day .appointment.statusFullcolor {border-bottom: 0!important;}

/* Appointment settings */
.agenda.week .appointment, .agenda.day .appointment {
	position: absolute;
  padding: 0;
	padding-left: 3px;
  font-size: 12px;
	font-weight: bold;
}

.agenda.week .appointment .timeStartEnd, .agenda.day .appointment .timeStartEnd, .agenda.week .appointment .employee, .agenda.day .appointment .employee {
	font-weight: normal;
	font-size: 12px;
	overflow: hidden;
}

/** Small screen **/
@media screen and (max-width: 960px) {
	.bigScreen {display: none!important;}
}
</style>
