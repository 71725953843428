export default {
	en_GB: {
    '_HOME_': 'Home',
    '_MANAGEMENT_': 'Management',
    '_DEFAULT_APPOINTMENT_DURATION_MINUTES_': 'Default appointment duration in minutes',
    '_MY_ACCOUNT_': 'My Account',
    '_REMOVE_ACCOUNT_': 'Remove account',
    '_REMOVE_ACCOUNT_CONFIRM_': 'All your data will be permanently deleted, this cannot be undone!',
    '_REMOVE_MY_ACCOUNT_': 'Remove my account',
    '_REMOVE_ACCOUNT_INFO_': 'You can delete your account via the button below.\n\nPlease note, all your data will be permanently deleted, this cannot be undone!',
    '_MY_COMPANIES_': 'My Companies',
    '_LANGUAGE_': 'Language',
    '_LOGIN_': 'Log In',
    '_LOGOUT_': 'Log Out',
    '_SETTINGS_': 'Settings',
		'_CHANGE_SETTINGS_': 'Change settings',
		'_CHANGE_STATUS_': 'Change status',
    '_CLOSE_': 'Close',
    '_SAVE_': 'Save',
    '_SELECT_LANGUAGE_': 'Select language',
		'_SELECT_DATE_': 'Select date',
    '_SELECT_DATE_TIME_': 'Select date time',
    '_SETTINGS_SAVED_': 'Your settings have been saved',
    '_NOTICE_': 'Notification',
    '_YOUR_USERNAME_': 'Username / Email',
    '_YOUR_PASSWORD_': 'Password',
    '_EMAIL_': 'E-mail',
    '_YOUR_EMAIL_': 'Your e-mail',
    '_NO_ACCOUNT_YET_': 'No account yet',
    '_FORGOT_PASSWORD_': 'Forgot your password',
    '_CREATE_NEW_ACCOUNT_': 'Create account',
    '_CREATE_NEW_ACCOUNT_TEXT_': 'Create your account and find the company you would like to join.',
    '_CREATE_NEW_ACCOUNT_TEXT_VA_': 'Create your account here.',
    '_SEND_DATA_': 'Send data',
    '_FORGOT_PASS_TEXT_': 'After your request you will receive a new password in your mail, don\'t forget to click on the link in the mail to activate your new password!',
    '_USERNAME_': 'Username',
    '_ADD_USER_': 'Add user',
    '_MOBILE_': 'Mobile',
    '_PASSWORD_': 'Password',
    '_PASSWORD2_': 'Repeat password',
    '_BIRTH_DATE_': 'Birthdate',
    '_FIRST_NAME_': 'Firstname',
    '_MIDDLE_NAME_': 'Middlename',
    '_LAST_NAME_': 'Lastname',
    '_GENDER_': 'Gender',
    '_MALE_': 'Male',
    '_FEMALE_': 'Female',
    '_REGISTER_': 'Register',
    '_SEARCH_': 'Search',
    '_SEARCH_YOUR_COMPANY_': 'Search your company',
    '_PRODUCT_MODULE_DESCRIPTION_': 'Available and Purchased',
    '_FIND_COMPANY_TITLE_': 'Find your company',
    '_ZIPCODE_': 'Zipcode',
    '_NAME_': 'Name',
    '_SEARCH_ON_': 'Search on',
    '_THE_NETHERLANDS_': 'The Netherlands',
    '_BELGIUM_': 'Belgium',
    '_COUNTRY_': 'Country',
    '_ZIPCODE_OR_NAME_': 'Zipcode or name',
    '_FILL_IN_HERE_': 'Fill in here',
    '_FIND_COMPANY_TEXT_': 'Find your company and join to register to appointments or buy products',
    '_ADD_COMPANY_': 'Add company',
    '_COMPANY_CONTACT_ME_': 'Contact me',
    '_COMPANY_CONTACT_ME_CONFIRM_': 'Your data will be send to the company so they can contact you',
    '_CANCEL_': 'Cancel',
    '_OK_': 'Ok',
    '_USERS_': 'Users',
    '_SUNDAY_': 'sunday',
    '_MONDAY_': 'monday',
    '_TUESDAY_': 'tuesday',
    '_WEDNESDAY_': 'wednesday',
    '_THURSDAY_': 'thursday',
    '_FRIDAY_': 'friday',
    '_SATURDAY_': 'saturday',
    '_SUN_': 'sun',
    '_MON_': 'mon',
    '_TUE_': 'tue',
    '_WED_': 'wed',
    '_THU_': 'thu',
    '_FRI_': 'fri',
    '_SAT_': 'sat',
    '_JAN_': 'jan',
    '_FEB_': 'feb',
    '_MAR_': 'mar',
    '_APR_': 'apr',
    '_MAY_': 'may',
    '_JUNE_': 'june',
    '_JULY_': 'july',
    '_AUG_': 'aug',
    '_SEPT_': 'sept',
    '_OCT_': 'oct',
    '_NOV_': 'nov',
    '_DEC_': 'dec',
    '_JAN_FULL_': 'january',
    '_FEB_FULL_': 'february',
    '_MAR_FULL_': 'march',
    '_APR_FULL_': 'april',
    '_MAY_FULL_': 'may',
    '_JUNE_FULL_': 'june',
    '_JULY_FULL_': 'juli',
    '_AUG_FULL_': 'august',
    '_SEPT_FULL_': 'september',
    '_OCT_FULL_': 'october',
    '_NOV_FULL_': 'november',
    '_DEC_FULL_': 'december',
    '_WEEK_': 'Week',
    '_DAY_': 'Day',
    '_AGENDA_': 'Agenda',
    '_MANAGEMENT_': 'Management',
    '_APPOINTMENT_': 'Appointment',
    '_EDIT_': 'Edit',
    '_CREATE_APPOINTMENT_TYPE_': 'Create new appointment type',
    '_FREE_': 'Free',
    '_WITH_CREDITS_': 'With credits',
    '_WITH_INSTALLMENTS_': 'With installments',
    '_BOOK_CREDITS_': 'Book credits',
    '_CHOOSE_NUMBER_CREDITS_': 'Enter number credits',
    '_SUBSCRIBE_POSSIBLE_WITH_': 'Register with',
    '_CUSTOMER_SEES_SUBSCRIPTIONS_': 'Customers can see registrations',
    '_YES_': 'Yes',
    '_NO_': 'No',
    '_PRIVACY_': 'Privacy',
    '_CHOOSE_APPOINTMENT_TYPE_': 'Choose appointment type',
    '_MANAGE_APPOINTMENT_TYPES_': 'Manage appointment types',
    '_ENABLE_WAITINGLIST_': 'Enable waitinglist',
    '_SUBSCRIPTION_SETTINGS_': 'Registration settings',
    '_SUBSCRIBE_POSSIBLE_FROM_': 'Register from',
    '_IN_ADVANCE_': 'hour in advance',
    '_IN_ADVANCE_PLAIN_': 'in advance',
    '_DAY_LOWER_': 'day',
    '_DAYS_LOWER_': 'days',
    '_HOUR_': 'hour',
    '_WEEKS_': 'weeks',
    '_WEEK_LOWER_': 'week',
    '_NO_LIMITATION_': 'No limitation',
    '_SUBSCRIBE_MAX_HOURS_': 'Registration allowed',
    '_UNSUBSCRIBE_MAX_HOURS_': 'Unregister allowed',
    '_UNSUBSCRIBE_PAID_FROM_HOURS_': 'Paid unregister from',
    '_APPOINTMENT_BEGINS_': 'Appoinment begins',
    '_DELETE_': 'Delete',
    '_ENTER_NAME_': 'Enter a name',
    '_EDIT_APPOINTMENT_TYPE_': 'Edit appointment type',
    '_ARE_YOU_SURE_': 'Are you sure?',
    '_ONLY_THIS_': 'Only this',
    '_THIS_AND_FUTURE_': 'This and future ones',
    '_REMOVE_APPOINTMENT_': 'Remove appointment',
    '_REMOVE_APPOINTMENT_LEGEND_': 'Remove this appointment',
    '_SUBSCRIPTIONS_TO_APPOINTMENT_': 'Registrations to appointment',
    '_SUBSCRIPTIONS_TO_WAITINGLIST_': 'Registrations to watinglist',
    '_THERE_ARE_': 'There are',
    '_SUBSCRIPTIONS_': 'registrations',
    '_SUBSCRIBE_CUSTOMER_': 'Register customer',
    '_SUBSCRIBE_': 'Register',
    '_SUBSCRIBE_FREE_': 'Register Free',
    '_APPOINTMENT_MANAGEMENT_': 'Appointment management',
    '_SELECT_': 'Select',
    '_APPOINTMENT_TYPE_': 'Appoinment type',
    '_MAKE_APPOINTMENT_TYPES_FIRST_': 'Before you can make appointments you have to create at least one appointment type first, close this window and click the management tab in toolbar to do this!',
    '_ENTER_NUMBER_': 'Enter a number',
    '_ENTER_NUMBER_POSITIVE_': 'Enter a positive number',
    '_MAX_SUBSCRIPTIONS_': 'Max registrations',
    '_DATE_FROM_': 'Date from',
    '_DATE_UNTIL_': 'Datum until',
    '_LOCATION_': 'Location',
    '_ENTER_LOCATION_': 'Enter location',
    '_ENTER_DESCRIPTION_': 'Enter description',
    '_CHANGE_': 'Change',
    '_ONLY_THIS_APPINTMENT_': 'Only this appointment',
    '_THIS_APPOINTMENT_AND_FUTURE_': 'Only this appointment',
    '_REPEAT_': 'Repeat',
    '_SINGLE_APPOINTMENT_CLICK_CHANGE_': 'Single appointment (click to change)',
    '_DAILY_': 'Daily',
    '_WORKING_DAY_': 'Working day (mon-fri)',
    '_WEEKLY_': 'Weekly',
    '_MONTHLY_': 'Monthly',
    '_EVERY_': 'every',
    '_ST_': 'st',
    '_AT_DAY_': 'on day',
    '_YEARLY_': 'Yearly',
    '_AT_': 'at',
    '_DESCRIPTION_': 'Description',
    '_FROM_': 'From',
    '_UNTIL_': 'Until',
    '_UNTIL_LOWER_': 'until',
    '_SPOTS_AVAILABLE_INFO_': 'Spots available',
    '_SPOTS_AVAILABLE_': 'available',
    '_SPOTS_LEFT_': 'left',
    '_SUBSCRIBE_TO_APPOINTMENT_': 'Register',
    '_YOU_NEED_A_PRODUCT_': 'Buy a product',
    '_UNSUBSCRIBE_FROM_APPOINTMENT_': 'Unregister',
    '_UNSUBSCRIBE_NO_LONGER_POSSIBLE_': 'Unregister no longer possible',
    '_SUBSCRIBE_NO_LONGER_POSSIBLE_': 'Registration is no longer possible',
    '_WHEN_SUBSCRIBING_': 'There wil be',
    '_CREDITS_BOOKED_SUBSCIPTION_HOLDERS_': 'credits booked from your account',
    '_CREDITS_BOOKED_': 'credits booked from your account, except if you have a subscription',
    '_HOURS_BEFORE_START_': 'hours before start',
    '_SUBSCRIBE_WAITINGLIST_': 'Register for waitinglist',
    '_UNSUBSCRIBE_WAITINGLIST_': 'Unregister for waitinglist',
    '_VIEW_SUBSCRIPTIONS_': 'Show registrations',
    '_SUBSCRIBE_ALLOWED_FROM_': 'Register allowed from',
    '_YOUR_CUSTOMERS_': 'Your customers',
    '_CUSTOMER_MANAGEMENT_': 'Customer Management',
    '_AGENDA_MANAGEMENT_': 'Agenda Management',
    '_CUSTOMERS_': 'Customers',
    '_CUSTOMER_SINCE_': 'Customer Since',
    '_BLOCK_': 'Block',
    '_ACCEPT_': 'Accept',
    '_INVITE_CUSTOMERS_VIA_LINK_': 'Invite via link',
		'_INVITE_CUSTOMERS_VIA_LINK_EXPLAINED_': 'Copy link below and send it to your customers to have them join your company.',
    '_EXPORT_': 'Export',
    '_STATS_': 'Stats',
		'_SORT_AND_FILTER_': 'Sort and filter',
    '_SORT_NAME_A_Z_': 'Name: a-z',
    '_SORT_NAME_Z_A_': 'Name: z-a',
    '_SORT_CUSTOMER_SINCE_NEWEST_': 'Customer since: newest',
    '_SORT_CUSTOMER_SINCE_OLDEST_': 'Customer since: oldest',
    '_BIRTHDAYS_': 'birthdays',
    '_BIRTHDAYS_TITLE_': 'Birthdays',
    '_STATS_CUSTOMER_SUMMARY_TEXT_': 'You have [1] customers from [2] ([3]%) with an active product',
    '_STATS_NO_PRODUCTS_TEXT_': '[1] customer(s) didn\'t purchase a product',
    '_STATS_NO_MORE_VALID_PRODUCTS_TEXT_': '[1] customer(s) have expired product(s)',
    '_STATS_ALMOST_EXPIRED_PRODUCTS_TEXT_': '[1] customer(s) haven\'t got a valid product anymore',
    '_SHOW_PRODUCTS_': 'Show products',
    '_PURCHASED_ON_': 'Purchased on',
    '_EXPIRES_ON_': 'Expires on',
    '_PRODUCT_INFO_': 'Product Information',
    '_AGENDA_INFO_': 'Agenda Information',
    '_STATS_NEVER_SUBSCRIBED_BEFORE_TEXT_': '[1] customer(s) ([2]%) have never registered to appointments before',
    '_STATS_LAST_SUBSCRIBED_ON_': 'Last registration on',
    '_STATS_CUSTOMER_SUMMARY_TEXT_': 'You have [1] customers from [2] ([3]%) with an active product',
    '_STATS_SUBSCRIBED_LONG_TIME_AGO_TEXT_': '[1] customer(s) ([2]%) registered to appointments a long time ago',
    '_TO_EXPORT_DATA_': 'Data to export',
    '_DATE_BIRTH_': 'Birthdate',
    '_ADDRESS_': 'Address',
    '_PRODUCT_FILTERS_': 'Product Filters',
    '_COMPANY_INFO_': 'Company Information',
    '_COMPANY_INFO_DESCRIPTION_': 'Tell us about your company',
    '_PHONE_': 'Phone',
    '_UNKNOWN_': 'Unknown',
    '_LENGTH_': 'Length (cm)',
    '_BORN_AT_': 'Born At',
    '_APPOINTMENTS_': 'Appoinments',
    '_SHOW_APPOINTMENTS_': 'Show Appoinments',
    '_REGISTERED_FREE_': 'Registered for free',
    '_REGISTERED_WITH_SUBSCRIPTION_': 'Registered with subscription',
    '_POINT_OF_TIME_': 'Point of time',
    '_REGISTERED_WITH_': 'Registered with',
    '_CREDITS_': 'credits',
    '_CREDIT_': 'credit',
    '_SHOW_': 'Show',
    '_BACKGROUND_': 'Background',
    '_HELP_GOALS_': 'Help needed/goals',
    '_MEDICAL_BACKGROUND_': 'Medical background',
    '_INJURIES_': 'Injuries',
    '_DOCTOR_TREATMENTS_': 'Doctor treatments',
    '_DISEASES_CONDITIONS_': 'Diseases/conditions',
    '_MEDICATIONS_': 'Medications',
    '_AGREEMENTS_PROGRAM_': 'Program to follow',
    '_PROGRAM_': 'Program',
    '_OTHER_INFO_': 'Other info',
    '_NOT_VISIBLE_FOR_CUSTOMER_': 'Not visible for customer',
    '_GOALS_': 'Goals',
    '_ACTIONS_': 'Actions',
    '_NEW_INTAKE_': 'New intake',
    '_NEW_REPORTING_': 'New reporting',
    '_INTAKE_': 'Intake',
    '_REPORTING_': 'Reporting',
    '_DATE_': 'Date',
    '_ENTER_HERE_': 'Enter here',
    '_SUBJECT_': 'Subject',
    '_INTAKE_REPORTING_': 'Intake + reporting',
    '_UNTIL_': 'until',
    '_TRAININGSCHEDULES_': 'Training Schedules',
    '_TRAINING_DAY_': 'Training Day',
    '_EXERCISES_': 'Exercises',
    '_EXERCISE_': 'Exercise',
    '_PERFORMED_': 'Performed',
    '_WEIGHT_': 'Weight',
    '_NUMBER_SETS_': 'Number sets',
    '_TEMPO_': 'Tempo',
    '_NUMBER_REPEATS_': 'Number Repetitions',
    '_DURATION_': 'Duration',
    '_PAUZE_': 'Pauze',
    '_EXPLANATION_': 'Explanation',
    '_START_TIMER_': 'Start timer',
    '_RESET_TIMER_': 'Reset timer',
    '_EXPLAIN_EXERCISE_TITLE_': 'Interpretation Exercise',
    '_PERFORM_EXERCISES_ON_TIME_TXT_': 'Perform exercises on time!',
    '_GENERAL_': 'General',
    '_BREAKFAST_': 'Breakfast',
    '_LUNCH_': 'Lunch',
    '_SNACK_': 'Snack',
    '_SNACK_EVENING_': 'Snack',
    '_DINER_': 'Diner',
    '_CREATE_NEW_': 'Create new',
		'_CREATE_PRODUCT_': 'Create product',
		'_CREATE_CATEGORY_': 'Create category',
    '_NUTRITION_SCHEDULES_': 'Nutrition Schedules',
    '_NUTRITION_DIARIES_': 'Nutrition Diaries',
    '_YOUTUBE_URL_': 'Youtube URL',
    '_NUTRITION_SCHEDULE_': 'Nutrition Schedule',
    '_YOUTUBE_': 'Youtube',
    '_LOAD_NUTRITION_SCHEDULE_': 'Load Nutrition Schedule',
    '_DIARY_': 'Diary',
    '_ADVICE_': 'Advice',
    '_SCHEDULES_': 'Schedules',
    '_SHOW_DATA_': 'Show Data',
    '_SHOW_SETTINGS_': 'Show Settings',
    '_VIDEO_': 'Video',
    '_KCAL_': 'kcal',
    '_PROTEIN_': 'protein',
    '_CARBS_': 'carbs',
    '_FAT_': 'fat',
    '_TOTAL_DAY_': 'Total of the day',
    '_COMMENTS_': 'Comments',
    '_VIEW_COMMENTS_': 'View Comments',
    '_NO_COMMENTS_': 'No Comments',
    '_TOTAL_': 'Total',
    '_NUTRITION_DIARY_EXTRA_INFO_': 'What is eaten/drunk?',
    '_COMMENTS_GENERAL_': 'Comments general',
    '_CREATE_NEW_DIARY_': 'Create new diary',
    '_NUTRITION_DIARY_': 'Nutrition Diary',
    '_PRODUCTS_': 'Products',
    '_ENTER_COMMENTS_HERE_': 'Enter comments here',
    '_NUTRITION_EXPLAIN_UNITS_': 'Number (1= 100 gram or 1 piece)',
    '_SEARCH_NUTRITION_PRODUCTS_': 'Nutrition',
    '_MANAGE_PROGRESS_': 'Manage progress',
    '_VIEW_PROGRESS_': 'View progress',
    '_THIS_PRODUCT_CONTAINS_': 'This product contains per',
    '_VIEW_MEASUREMENT_CARD_': 'Measurements',
    '_VIEW_SKINFOLD_MEASUREMENT_': 'Skinfold',
    '_VIEW_GRAPH_': 'View graph',
    '_MANAGE_PROGRESS_EXTRA_INFO_': 'Manage progress',
    '_VIEW_MEASUREMENT_CARD_EXTRA_INFO_': 'Circumference in centimeters',
    '_VIEW_SKINFOLD_MEASUREMENT_EXTRA_INFO_': 'Measured millimeters',
    '_VIEW_GRAPH_EXTRA_INFO_': 'Progress in graph',
    '_PROGRESS_': 'Progress',
    '_INSERT_PROGRESS_': 'Insert progress',
    '_FAT_PERCENTAGE_': 'Fat Percentage',
    '_VISCERAL_FAT_LEVEL_': 'Visceral fat level',
    '_FAT_FREE_MASS_': 'Fat Free Mass',
    '_MUSCLE_MASS_': 'Muscle Mass',
    '_BONE_MASS_': 'Bone Mass',
    '_BODY_FLUID_': 'Body Fluid',
    '_BLOOD_GLUCOSE_VALUE_': 'Blood Glucose Value',
    '_MEASUREMENT_CARD_LEGEND_': 'Measurement Card (cm)',
    '_TOP_WIDTH_': 'Chest',
    '_STOMACH_': 'Stomach',
    '_WAIST_': 'Waist',
    '_BELLY_': 'Belly',
    '_HIPS_': 'Hips',
    '_LEFT_THIGH_': 'Left Thigh',
    '_RIGHT_THIGH_': 'Right Thigh',
    '_LEFT_KNEE_': 'Left Knee',
    '_RIGHT_KNEE_': 'Right Knee',
    '_LEFT_CALF_': 'Left Calf',
    '_RIGHT_CALF_': 'Right Calf',
    '_LEFT_ARM_': 'Left Arm',
    '_RIGHT_ARM_': 'Right Arm',
    '_SKINFOLD_MEASUREMENT_LEGEND_': 'Skinfold Measurement (mm)',
    '_CHIN_': 'Chin',
    '_CHEEK_': 'Cheek',
    '_CHEST_': 'Chest',
    '_BICEPS_': 'Biceps',
    '_TRICEPS_': 'Triceps',
    '_SCACAPULA_': 'Scapula',
    '_SIDE_RIBS_': 'Side Ribs',
    '_HIP_BONE_': 'Hip Bone',
    '_NAVEL_': 'Navel',
    '_KNEE_': 'Knee',
    '_CALF_': 'Calf',
    '_QUADRICEPS_': 'Quadriceps',
    '_HAMSTRING_': 'Hamstring',
    '_WEEK_': 'Week',
    '_TOTAL_': 'Total',
    '_FIRST_': 'First',
    '_PREVIOUS_': 'Previous',
    '_NEXT_': 'Next',
    '_LAST_': 'Last',
    '_DIFF_PREVIOUS_': 'Diff previous',
    '_DIFF_FIRST_': 'Diff first',
    '_SIZES_': 'Sizes',
    '_MILLIMETERS_': 'Millimeters',
    '_SKINFOLD_MILLIMETERS_TITLE_': 'Skinfold Measurement (mm)',
    '_CALVES_': 'Calves',
    '_BMI_': 'BMI',
    '_MEASUREMENT_CARD_CM_TITLE_': 'Measurement Card cm',
    '_SHOW_LIST_': 'Show list',
    '_PROGRESS_GRAPH_': 'Progress Graph',
    '_WOD_MANAGEMENT_': 'WOD Management',
    '_NOT_PUBLISHED_': 'Not published',
    '_LAST_PUBLISHED_': 'Published',
    '_SHARE_WOD_ADMIN_LABEL_': 'Share WOD outside the app',
    '_SHARE_WOD_ADMIN_TEXT_': 'You can share this link to share the WOD outside the app, there has to be a minimum of 1 publication!',
    '_PUBLISH_': 'Publish',
    '_DATE_WOD_DESC_': 'Published: newest',
    '_DATE_WOD_ASC_': 'Published: oldest',
    '_VIEW_ONLY_PUBLISHED_WODS_': 'You cannot edit this WOD because there are publications on the whiteboard already!',
    '_WOD_SCORE_AS_': 'Score as',
    '_WOD_RX_LABEL_': 'RX',
    '_WOD_RANK_CATEGORIES_': 'Ranking categories',
    '_OTHER_OPTIONS_': 'Other options',
    '_WOD_BENCHMARK_LABEL_': 'Benchmark',
    '_WOD_OVERALL_RANKING_LABEL_': 'Overall ranking',
    '_WOD_FUNDAMENTAL_LABEL_': 'Fundamental',
    '_DISABLE_SHARING_': 'Disable sharing (social media)',
    '_SAVE_AND_PUBLISH_': 'Save and publish',
    '_PUBLISH_WOD_': 'Publish WOD',
    '_BY_DATE_': 'By date',
    '_WOD_': 'WOD',
    '_ONLY_PUBLISH_WOD_': 'You can only publish, not edit the WOD! Choose your publication date, an optional YouTube URL and click the publish button',
    '_WHITEBOARD_PUBLICATIONS_HISTORY_': 'Whiteboard publications',
    '_MANAGE_WHITEBOARD_PUBLICATION_': 'Manage whiteboard publication',
    '_RANKING_': 'Ranking',
    '_MY_WOD_': 'MY WOD',
    '_SHARE_': 'Share',
    '_YOUR_COMMENT_': 'Your comment',
    '_RESULTS_': 'results',
    '_VIEW_HISTORY_': 'Show History',
    '_VIEW_WOD_': 'Show WOD',
    '_VIEW_BENCHMARK_': 'Show Benchmark',
    '_YOUR_PERSONAL_RECORD_': 'Your Personal Record',
    '_LOG_YOUR_RESULT_': 'Log your result',
    '_DELETE_YOUR_RESULT_': 'Delete result',
    '_LOG_ATHLETE_RESULT_': 'Athlete result',
    '_RANKING_CATEGORY_': 'Category',
    '_TIME_': 'Time',
    '_RX_OR_SCALED_': 'RX or Scaled',
    '_DELETE_BUTTON_DAY_': 'Delete day',
    '_DELETE_BUTTON_ALL_': 'Delete all',
    '_REMOVE_ATHLETE_RESULT_TXT_': 'Below you can remove athletes result!<br />- All=all including overall ranking) <br />- Day=only this WOD',
    '_DAY_RANKING_': 'Day Ranking',
    '_SCORE_': 'Score',
    '_OVERALL_RANKING_': 'Overall Ranking',
    '_MEN_ONLY_': 'Men only',
    '_WOMAN_ONLY_': 'Woman only',
    '_MEN_AND_WOMAN_': 'Men and Woman',
    '_OVERALL_': 'Overall',
    '_SHOW_RANKING_CATEGORIES_': 'Show Ranking Categories',
    '_SEARCH_WOD_': 'Search WOD',
    '_PRODUCT_CATEGORIES_': 'Product categories',
    '_MANAGE_PRODUCT_CATEGORIES_': 'Manage Product Categories',
    '_PRODUCT_CUSTOMER_FORM_': 'Product Form',
    '_CONNECT_PRODUCT_TO_CUSTOMERS_': 'Connect products to customers',
    '_PRODUCT_SEPA_FORM_': 'Create SEPA Batch',
    '_CREATE_SEPA_FILE_FROM_PRODUCTS_': 'Create Batch from products',
    '_MANAGE_PRODUCTS_': 'Manage Products',
    '_PRODUCT_MANAGEMENT_': 'Product Management',
    '_PRODUCT_CATEGORY_': 'Product Category',
    '_CARD_WITH_CREDITS_': 'Credits',
    '_OTHER_PRODUCT_': 'Other',
    '_LEFT_EMPTY_': 'Left empty',
    '_PRODUCT_TYPE_': 'Product Type',
    '_PERIOD_VALID_': 'Period Valid',
    '_CONTINUOUSLY_': 'Continuously',
    '_MONTHS_': 'Months',
    '_MONTHS_LOWER_': 'months',
    '_MONTH_LOWER_': 'month',
    '_VALID_UNLIMITED_': 'Valid unlimited',
    '_NUMBER_MONTHS_VALID_': 'Number months valid',
    '_ATHORIZATION_': 'Authorization',
    '_ATHORIZATION_MULTIPLE_PERIODS_': 'Authorization Periods',
    '_NUMBER_INSTALLMENTS_': 'Number installments',
    '_ORDER_': 'Order',
    '_NOT_SET_': 'Not set',
    '_VAT_PERCENTAGE_': 'Vat Percentage',
    '_MONTHLY_AMOUNT_': 'Monthly amount',
    '_VAT_PERCENTAGE_': 'VAT Percentage',
    '_AMOUNT_FIRST_PERIOD_': 'Amount first period in VAT',
    '_AMOUNT_OTHER_PERIODS_': 'Amount other periods in VAT',
    '_AMOUNT_PERIODS_IN_VAT_': 'Amount periods in VAT',
    '_AMOUNT_': 'Amount',
    '_NUMBER_CREDITS_': 'Number credits',
    '_NOT_VISIBLE_IN_SHOP_': 'Not visible in shop',
    '_ENABLED_': 'Enabled',
    '_DISABLED_': 'Disabled',
    '_DISABLE_IN_SHOP_': 'Get product from sale',
    '_DISABLED_IN_SHOP_': 'Product withdrawn from sale',
    '_SHARE_AND_SELL_PRODUCT_': 'Share and sell your product',
    '_SHARE_AND_SELL_PRODUCT_TEXT_': 'Through the product link below you can sell your product outside the app as well. If the user is no customer yet, he will be added to your list of customers automaticly during the purchase!',
    '_SHARE_TRIAL_PRODUCT_TEXT_': 'Through this link below existing customers and new customers can plan a free trial event.',
    '_PRODUCT_': 'Product',
    '_ORDER_EXPLAIN_NUMBER_': 'Enter a number (lowest shows first)',
    '_NO_CREDITS_OTHER_PRODUCT_': 'No credits, other product',
    '_NUMBER_MONTHS_VALID_VALID_': 'Number months valid',
    '_PAYMENT_METHOD_': 'Payment Method',
    '_AMOUNT_IN_VAT_': 'Amount in vat',
    '_PRIVACY_SETTINGS_': 'Privacy settings',
    '_PAYMENT_ONCE_LINK_': 'Single payment (click to change)',
    '_PAYMENT_SUBSCRIPTION_LINK_': 'Subscription (click to change)',
    '_NOT_APPLICABLE_': 'n/a',
    '_NUMBER_SUBSCRIPTIONS_PER_WEEK_': 'Max appointments per week',
    '_NUMBER_SUBSCRIPTIONS_PER_MONTH_': 'Max appointments per month',
    '_ONGOING_': 'Ongoing',
    '_ATHORIZATION_SEPA_': 'SEPA Authorization',
    '_MANAGE_IMAGE_': 'Manage image',
    '_UPLOAD_IMAGE_': 'Upload image',
    '_IMG_UPLOAD_INFO_': 'After saving the product you can upload an image by editing the product.',
    '_NO_IMG_YET_': 'No image is uploaded yet!',
    '_FILE_NAME_': 'File Name',
    '_FILE_SIZE_': 'File Size',
    '_FILE_TYPE_': 'File Type',
    '_CHOOSE_FILE_': 'Choose your file',
    '_BROWSE_IMAGES_': 'Browse images',
    '_DELETE_IMAGE_': 'Remove image',
    '_UPLOAD_NEW_IMAGE_': 'Upload new image',
    '_CURRENT_IMAGE_': 'Current image',
    '_UPLOAD_': 'Upload',
    '_DELETE_FILE_': 'Delete File',
    '_UPLOADED_': 'uploaded',
    '_NO_FILE_SELECTED_': 'No file selected',
    '_CONNECT_PRODUCT_TO_CUSTOMER_EXPLAIN_': 'Pay attention! Settings you make for the link to the calendar and do not affect payments.',
    '_FIND_CUSTOMER_': 'Find customer',
    '_ACTIVE_': 'Active',
    '_INACTIVE_': 'Inactive',
    '_CONNECTED_PRODUCT_': 'Connected by you',
    '_ACTIVATE_': 'Activate',
    '_DEACTIVATE_': 'Deactivate',
    '_CONNECTED_PRODUCTS_': 'Connected products',
    '_CONNECT_NEW_PRODUCT_': 'Connect new product',
    '_CONNECT_FREE_PRODUCT_': 'Connect product for free',
    '_SELECT_PRODUCT_': 'Select a product',
    '_CONNECT_PRODUCT_': 'Connect product',
    '_CURRENT_PRODUCT_': 'Current product',
    '_AVAILABLE_': 'Available',
    '_VALID_UNTIL_': 'Valid until',
    '_VALID_TM_': 'Valid until',
    '_CHANGE_CONNECTED_PRODUCT_': 'Change connected product',
    '_SHOP_': 'Shop',
    '_PURCHASED_': 'Purchased',
    '_PMTH_': 'p/mth',
    '_ITEMS_': 'items',
    '_AMOUNT_FIRST_': 'Amount first period',
    '_AMOUNT_OTHERS_': 'Amount other periods',
    '_ORDER_PRODUCT_': 'Order Product',
    '_IN_SHOPPING_CART_': 'In Shopping Cart',
    '_NUMBER_ITEMS_': 'Number',
    '_PRODUCTS_IN_SHOPPING_CART_': 'Products in Shopping Cart',
    '_PAY_SHOPPINGCART_': 'Checkout Cart',
    '_UPDATE_CART_': 'Update',
    '_PAY_': 'Pay',
    '_PAY_ALL_': 'Pay all',
    '_CREDITS_LEFT_': 'credits left',
    '_STATUS_': 'Status',
    '_WHAT_IS_TOUR_GOAL_': 'What is your goal?',
    '_SEND_': 'Send',
    '_FORM_TRAINING_DISTANCE_': 'Lifestyle advice',
    '_DESCRIPTION_TRAINING_DISTANCE_': 'Through this form you can request for a training of nutrition advice.',
    '_FORM_FREE_ADVICE_': 'Request for a free advice',
    '_DESCRIPTION_FREE_ADVICE_': 'Through this form you can request for a free lifestyle advice from a lifestyle coach',
    '_MESSAGE_': 'Message',
    '_CONTACT_': 'Contact',
    '_MY_MANAGEMENT_': 'My Management',
    '_MY_MANAGEMENT_DESCRIPTION_': 'For the company',
    '_SEND_PUSHMESSAGE_': 'Send Message',
    '_ALL_MY_CUSTOMERS_': 'All my customers',
    '_SEND_MESSAGE_TO_': 'Send to',
    '_SEND_MESSAGE_AS_': 'Send as',
    '_SELECTION_CUSTOMERS_': 'Selection customers',
    '_FORGOT_PASS_': 'Forgot password',
    '_CREATE_ACCOUNT_': 'Create account',
    '_DESCRIPTION_COMPANY_': 'Describe your company',
    '_STREET_': 'Street',
    '_HOUSENUMBER_': 'Housenumber',
    '_HOUSENUMBER_EXTRA_': 'Housenumber extra',
    '_RESIDENCE_': 'Residence',
    '_MY_PASSWORD_': 'Change password',
    '_CURRENT_PASSWORD_': 'Current password',
    '_NEW_PASSWORD_': 'New password',
    '_REPEAT_PASSWORD_': 'Repeat password',
    '_COMPANY_SETTINGS_': 'Company settings',
		'_PERSONAL_SETTINGS_': 'Personal settings',
    '_FAQ_': 'FAQ',
    '_EMAIL_AGENDA_EVENTS_': 'Email agenda events',
    '_URL_COMPANY_TERMS_': 'URL company terms',
    '_ACCEPT_USERS_FIRST_': 'Accept customer first',
    '_ACCEPT_WAITING_CUSTOMER_': 'Customer is waiting to be accepted.',
    '_SEND_NEW_USER_EMAIL_': 'Send new customer email',
    '_UPLOAD_PROFILE_IMAGE_': 'Upload profile image',
    '_UPLOAD_PROFILE_IMAGE_DESCRIPTION_': 'You can upload one square photo (jpg, no larger than 200 KB)',
    '_WHOOPS_': 'Whoops',
    '_SOMETHING_WENT_WRONG_TEXT_': 'Something went wrong, our apologies, try again later',
    '_GO_TO_HOME_PAGE_': 'To home screen',
    '_COMPANY_STYLES_': 'Custom styles',
    '_COMPANY_ACCESS_SETTINGS_': 'User access',
    '_COMPANY_PICTURES_': 'Company images',
    '_HEADER_LOGO_': 'Header logo',
    '_LOGO_RATIO_': 'Approximately this ratio',
    '_HEADER_LOGO_SUB_TITLE_': 'Is displayed on your personal login/new account page in the top bar',
    '_COMPANY_LOGO_': 'Logo at the top of company page',
    '_COMPANY_LOGO_SUB_TITLE_': 'Is displayed on your company start page at the top and invoices',
    '_BG_LOGO_': 'Background image',
    '_BG_LOGO_SUB_TITLE_': 'Is displayed as background image on your personal login/new account page',
    '_LOGO_RATIO_CHOOSE_YOURSELF_': 'Choose your own ratio and check if it displays nice (this image wil be centered)',
    '_IMAGES_': 'Images',
    '_STYLES_': 'Styles',
    '_COMPONENTS_': 'Components',
    '_COLOR_HEADER_BORDER_': 'Color, line at the top',
    '_COLOR_OUTLINE_BUTTONS_': 'Color outline buttons in app',
    '_COLOR_OUTLINE_TEXT_BUTTONS_': 'Text color in buttons app',
    '_COLOR_SP_BG_BUTTONS_BG_': 'Background color buttons new account/login page',
    '_COLOR_SP_BUTTONS_TEXT_': 'Text color buttons new account/login page',
    '_COLOR_SP_HEADERS_': 'Text header colors buttons new account/login page',
    '_FAQ_Q_INTEGRATE_APP_': 'Can I intregate the app on my website?',
    '_FAQ_A_INTEGRATE_APP_': 'Yes you can, check out the options for this.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_': 'Copy any of the code examples here or contact your webbuilder or us for help.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_1_': 'The agenda on your site:',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_2_': 'The agenda on your site starting in the current week in list view:',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_2_EXTRA_': 'Use mode=week for week, mode=day for day and mode=listView for list view',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_3_': 'Alter width and heigth:',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_3_EXTRA_': 'Use width=500 for the width, height=600 for the height (alter this to fit your needs) and fullscreen=true for fullscreen.<br> If width parameter is left out, it will take the width of wrapping element',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_4_': 'I have mulpiple agenda\s and want to integrate one directly',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_5_': 'I want to implement the whole app.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_5_EXTRA_': 'Use code example below.',
    '_FAQ_MEMBER_FORGOT_PASS_': 'My customer lost his accountdata, how can I help?',
    '_FAQ_A_MEMBER_FORGOT_PASS_': 'Your customer can request for a new password via the forgot login function, the new password needs to be confirmed first via the link en the e-mail.',
    '_FAQ_MEMBER_CHILDREN_AGENDA_': 'Can my cutomer buy a product for her children?',
    '_FAQ_A_MEMBER_CHILDREN_AGENDA_': 'No, the parent needs to create a seperate account for each child',
    '_FAQ_MEMBER_SUBSCRIBE_AGENDA_': 'My customer cannot register for the appointment bu he has paid, what can I do?',
    '_FAQ_A_MEMBER_SUBSCRIBE_AGENDA_': 'Check if you have checked all needed products for the appointment. Check if customer has a valid product that has not expired',
    '_FAQ_NOT_WORKING_': 'My question is not here, can you help me?',
    '_FAQ_A_NOT_WORKING_': 'Yes, contact us via:',
    '_SLOGAN_APP_': 'planning, payments and customer management for your company',
    '_SLOGAN_APP_TOOLBAR_': 'Customer Mangagement, Payments and planning for your company!',
    '_LOGIN_NEW_ACCOUNT_': 'Login/new account',
    '_TO_SHOP_': 'Go to webshop',
    '_REMOVE_COMPANY_': 'Remove company',
    '_REMOVE_AS_START_PAGE_': 'Remove as start page',
    '_ADD_AS_START_PAGE_': 'Add as start page',
    '_ACCOUNT_SUCCESS_LOGIN_VIA_APP_': 'Your account has been created, you can login into the app now',
    '_REGISTER_VIA_WEBSITE_': 'Register via the website',
    '_NON_COMMERCIAL_USE_': 'This is the minimum data we need for the app to function and wil not be given to third parties. Your email address is needed for login and if you forget your pass.',
    '_OTHER_': 'Other',
    '_AGENDA_APPOINTMENTS_VIEW_': 'Agenda status view',
    '_APPOINTMENTS_FULL_COLOR_': 'In fullcolor',
    '_APPOINTMENTS_LINE_COLOR_': 'Line bottom',
    '_UPDATING_APP_': 'Updating',
    '_FAMILY_MEMBERS_': 'Family members',
    '_UNSUBSCRIBE_OUTSIDE_TIME_': 'No credits wil be returned',
    '_SUBSCRIBE_NOT_POSSIBLE_': 'Subscribing not possible',
    '_NONE_': 'None',
    '_NUMBER_FAMILY_MEMBERS_ALLOWED_': 'Number family members allowed',
    '_ADD_FAMILY_MEMBERS_': 'Add family members',
    '_NO_SHOW_REGISTRATION_': 'No show registration (no credits were returned)',
    '_NO_SHOW_REGISTRATION_CUSTOMER_MESSAGE_': 'Canceled after the final registration period (no credits were returned)',
    '_NO_SHOWS_APPOINTMENT_': 'No shows on appointment',
    '_NO_SHOWS_': 'no shows',
    '_QUESTION_REMOVE_NO_SHOW_': 'The credit wil be returned',
    '_QUESTION_COLOR_OK_': 'Are you sure this is the right color?',
    '_CHOOSE_AN_OPTION_': 'Choose an option',
    '_NOTICE_ADMIN_UNSUBSCRIBE_APPOINTMENT_': 'Do you want to return the credits?',
    '_UNSUBSCRIBE': 'Unsubscribe',
    '_QUESTION_UNSUBSCRIBE_NO_CREDITS_RETURNED_': 'No credits wil be returned if you unsubscribe outside the time',
    '_UNSUBSCRIBE_NO_LONGER_POSSIBLE_': 'Unregister no longer possible',
    '_CAN_LOG_TO_': 'Can log to',
    '_TO_DATE_': 'To date',
    '_SINGLE_WOD_': 'Single wod',
    '_COMPETITION_WOD_': 'Competition',
    '_COMPETITION_': 'Competition',
    '_MANAGE_COMPETITIONS_': 'Manage competitions',
    '_MANAGE_RANKINGS_': 'Manage rankings',
    '_CLICK_MANAGE_COMPETITIONS_': 'Click here to manage competitions',
    '_CLICK_MANAGE_RANKINGS_': 'Click here to manage rankings',
    '_LOGGING_POSSIBLE_WITH_': 'Logging with',
    '_WOD_TYPE_': 'WOD type',
    '_MANAGE_COMPETITION_': 'Manage competition',
    '_EDIT_NOT_USED_RANKING_ONLY_': 'You can only edit the unused rankings',
    '_ADD_RANKING_CATEGORY_': 'New ranking category',
    '_DATE_END_': 'Date end',
    '_NEW_COMPETITION_': 'New competition',
    '_FINISHED_': 'Finished',
    '_UNLIMITED_': 'Does not apply',
    '_EDIT_PART_USED_COMPETITIONS_ONLY_': 'For a linked competition you can just add products and postpone the end date',
    '_ATHLETE_LOG_NOT_ALLOWED_': 'Log by athlete not allowed',
    '_THERE_IS_NO_VALID_PRODUCT_': 'Buy a product to participate',
    '_ATHLETE_YOUTUBE_URL_REQUIRED_': 'Youtube url for logging by athlete required',
    '_END_RANKING_': 'End ranking',
    '_POSITION_PER_WOD_': 'Position per wod',
    '_RANKING_DETAILS_': 'Ranking details',
    '_THESE_ATHLETES_HAVE_SAME_NUMBER_': 'These athletes have the same number of points',
    '_COULD_NOT_CHANGE_SCORE_AND_POINTS_AT_ONCE_': 'It is not possible to change the score and points at once, we did not save the points',
    '_AFTER_CHANGING_COMPETITION_POSITION_UNABLE_TO_CHANGE_WOD_SCORE_': 'After editing the endranking it is not possible to edit individual wod scores',
    '_NO_COMPETITIONS_': 'No competitions',
    '_RESET_ENDRANKING_': 'Reset endranking',
    '_PAYING_CUSTOMERS_PER_PRODUCT_': 'Paying customers per product',
    '_NON_PAYING_CUSTOMERS_PER_PRODUCT_': 'Non paying customers with product',
    '_EXPORT_LIST_': 'Export this list',
    '_ENTER_NUMBER_HOURS_': 'number',
    '_HOURS_': 'hour',
    '_EMPTY_': 'empty',
    '_MANAGE_SALES_': 'Manage sales',
    '_TRANSACTIONS_': 'Transactions',
    '_CREDITS_AND_DEBITS_': 'Credits and debits',
    '_ACCOUNTANCY_': 'Accountancy',
    '_FAILED_PAYMENTS_': 'Failed payments',
    '_DAY_OVERVIEW_': 'Day overview',
    '_DAY_INCOME_': 'Day income',
    '_PAYMENTS_': 'Payments',
    '_ADD_SEPA_DIRECT_DEBIT_': 'Add subscription',
    '_VIEW_EDIT_': 'View/Edit',
    '_SUBSCRIPTIONS_DIRECT_DEBITS_': 'Registrations',
    '_SALES_': 'Sales',
    '_SHOW_OVERVIEWS_': 'Show overviews',
    '_VAT_': 'Vat',
    '_MANAGE_REFUNDS_': 'Manage refunds',
    '_REFUND_SALES_': 'Refund sales',
    '_PRODUCTS_TO_REFUND_': 'Selected to refund',
    '_TOTAL_AMOUNT_REFUNDS_': 'Total amount refunds',
    '_REFUNDS_HISTORY_': 'Refunds history',
    '_SORT_NAME_CUSTOMER_A_Z_': 'Name customer: a-z',
    '_SORT_NAME_CUSTOMER_Z_A_': 'Name customer: z-a',
    '_DATE_ASC_': 'Date: oldest',
    '_DATE_DESC_': 'Date: newest',
    '_REVENUE_': 'Revenue',
    '_FINANCIAL_': 'Financial',
    '_DATE_TO_': 'to',
    '_REVENUE_OVER_SELECTED_PERIOD_': 'total incl. VAT',
    '_SOLD_OVER_SELECTED_PERIOD_': 'sold including VAT',
    '_SUBSCRIPTIONS_SEPA_': 'Subscriptions',
    '_VAT_': 'VAT',
    '_INCLUDING_': 'including',
    '_INCLUDING_SHORT_': 'incl.',
    '_DOWNLOAD_INVOICES_': 'Download invoices',
    '_DOWNLOAD_INVOICE_': 'Download invoice',
    '_DIRECT_DEBITS_OVER_PERIOD_': 'from chosen period',
    '_DIRECT_DEBITS_TOTAL_': 'Amount in circulation',
    '_NOT_ACTIVE_': 'not active',
    '_ACTIVE_FIRST_': 'Active: first',
    '_ACTIVE_LAST_': 'Active: last',
    '_MANAGE_DIRECT_DEBIT_': 'Manage subscription',
    '_COMPANY_NAME_': 'Company name',
    '_VAT_NUMBER_': 'VAT number',
    '_CITY_': 'City',
    '_IBAN_': 'IBAN',
    '_BIC_': 'BIC',
    '_CONNECT_TO_CUSTOMER_': 'Connect to customer',
    '_DATA_CREDITOR_': 'Data customer',
    '_FIRST_PROCESS_DATE_DIRECT_DEBIT_': 'First installment date',
    '_UPCOMING_': 'Upcoming',
    '_VAT_SHIFTED_ABROAD_': 'VAT shifted abroad',
    '_SEARCH_CUSTOMER_': 'Search customer',
    '_CHOSE_AN_OPTION_': 'Pick an option',
    '_NOT_REQUIRED_': 'Not Required',
    '_ACTIVATE_AFTER_PAYMENT_': 'After payment',
    '_ACTIVATE_NOW_': 'Now',
    '_ACTIVATE_WHEN_': 'Activate',
    '_DIRECT_DEBIT_PERMISSION_': 'I\'m authorized for this direct debit',
    '_DECLARATION_OF_CONSENT_': 'Declaration of consent',
    '_PRODUCT_DATA_': 'Product information',
    '_CUSTOMER_DATA_': 'Customer data',
    '_PAYMENT_DATA_': 'Payment data',
    '_UPCOMING_INSTALLMENT_': 'Upcoming installment',
    '_PAYBACKS_': 'Paybacks',
    '_INSTALLMENTS_': 'Installments',
    '_INSTALLMENT_': 'Installment',
    '_OTHER_ACTIONS_': 'Other actions',
    '_BACK_TO_PREVIOUS_ACTIONS_': 'Back to previous actions',
    '_SUBSCRIPTIONS_NOT_ACTIVE_': 'This subscription is not active',
    '_SUBSCRIPTIONS_IS_PAUSED_': 'This subscription is not pauzed',
    '_SUBSCRIPTION_WIL_BE_RESUMED_': 'subscription wil be resumed',
    '_DEACTIVATE_BY_': 'Deactivate by',
    '_SELECTED_PERIOD_WIL_NOT_BE_PROCESSED_': 'Selected period wil not be processed',
    '_IMMEDIATELY_': 'Immediately',
    '_REACTIVATE_BY_': 'Reactivate by',
    '_SELECTED_PERIOD_WIL_BE_PROCESSED_': 'Selected date wil be processed',
		'_NOT_DEACTIVATE_': 'Don\'t deactivate',
    '_NOT_REACTIVATE_': 'Don\'t reactivate',
    '_PLAN_DEACTIVATION_': 'Stop/pause',
    '_PLAN_ACTIVATION_': 'Plan activation',
    '_CHANGE_SUBSCRIPTION_': 'Change subscription',
    '_BY_': 'By',
    '_SUBSCRIPTIONS_IS_FINISHED_': 'This subscription is finished',
    '_NEVER_REACTIVATE_': 'Never',
    '_DATE_AT_': 'Date at',
    '_ENTER_DATE_': 'Enter date',
    '_SAVE_SETTINGS_': 'Save settings',
    '_REMOVE_DEACTIVATION_': 'Remove de-activation',
    '_INFO_': 'Info',
    '_INVOICES_': 'Invoices',
    '_MANAGE_DIRECT_DEBIT_INSTALLMENTS_': 'Termijnen',
    '_MADE_ON_': 'Made on',
    '_REFUND_PERIOD_': 'Refund period',
    '_REASON_': 'Reason',
    '_PERIOD_': 'Period',
    '_INVOICE_': 'Invoice',
    '_FAILED_': 'Failed',
    '_OUTSTANDING_BILLS_': 'Oustanding bills',
    '_REFUNDS_': 'Refunds',
    '_REMINDER_': 'Reminder',
    '_IS_SEND_': 'is send',
    '_SHOW_MAIL_': 'Show mail',
    '_CREDIT_INVOICE_': 'Credit invoice',
    '_FAILED_DIRECT_DEBITS_': 'Failed payments',
    '_FAILED_REFUNDS_': 'Failed refunds',
    '_SALE_': 'Sale',
    '_INVOICE_NUMBER_': 'Invoice Number',
    '_SUBSCRIPTION_DEACTIVATED_BY_SYSTEM_': 'Due a technical reason it has no use to process this direct debit, so we deactivated it.',
    '_NO_FAILED_DIRECT_DEBITS_': 'There are no failed payments :)',
    '_NO_FAILED_REFUNDS_': 'There are no failed refunds :)',
    '_PAY_BEHIND_LINK_DESCRIPTION_': 'Payment can be done via link',
    '_FAILED_REFUNDS_DESCRIPTION_': 'Failed refunds due to lack of funds. Remove this and do the transfer yourself to your client. The credit note has already been made.',
    '_NO_FAILED_DIRECT_DEBITS_DESCRIPTION_': 'When crediting, the amount is waived and the invoice disappears from the overview.',
    '_COSTS_': 'Costs',
    '_INVOICE_LANGUAGE_': 'Invoice language',
    '_PIN_': 'PIN',
    '_HANDLE_PIN_PAYMENT_': 'Handle a PIN Payment',
    '_PIN_NOT_ENABLED_TEXT_': 'The PIN module is not enabled!<br><br>Do you want a pinmachine? Contact us for more information.',
    '_DATA_CUSTOMER_': 'Data customer',
    '_SEARCH_A_PRODUCT_': 'Find a product',
    '_PRODUCT_NAME_': 'Product name',
    '_LINK_TO_CUSTOMER_CHOOSE_AN_OPTION_': 'Connect to customer? Pick an option',
    '_PAYMENT_NOT_LINK_TO_CUSTOMER_': 'Do not connect payment to customer',
    '_PAYMENT_LINK_TO_CUSTOMER_': 'Connect payment to customer',
    '_SELECTED_PRODUCTS_': 'Selected products',
    '_SEND_TO_PIN_DEVICE': 'Send to PIN',
    '_WAITING_FOR_TRANSACTION_': 'Waiting for transaction',
    '_TRANSACTIONS_SUCCESSFUL_': 'The transaction was successful',
    '_TRANSACTIONS_EXPIRED_': 'The transaction is expired',
    '_TRANSACTIONS_CANCELLED_': 'The transaction is cancelled',
    '_TRANSACTIONS_ERROR_': 'The transaction failed, try again later',
    '_BANK_BALANCE_': 'Bank balance',
    '_RECEIVABLE_BALANCE_': 'Receivable balance',
    '_WITHDRAWAL_': 'Withdrawal',
    '_DOWNLOAD_TRANSACTIONS_': 'Download transactions',
    '_REFERENCE_': 'Reference',
    '_TRANSACTION_COSTS_': 'Transaction costs',
    '_EXPORT_SELECTED_PERIOD_': 'Export selected period',
    '_MANAGE_TRANSACTIONS_': 'Transactions',
    '_MANAGE_WITHDRAWAL_': 'Withdrawal',
    '_NEXT_WITHDRAWAL_': 'Next withdrawal',
    '_RESERVE_FOR_REFUNDS_': 'Withhold amount (will not be paid)',
    '_RESERVATIONS_': 'Transactioncosts and refunds',
    '_AVAILABLE_SOON_': 'Available soon',
    '_SEND_MAIL_WHEN_': 'Send me an e-mail when',
    '_COMPANY_BANK_TRANSFER_': 'Bank transfer',
    '_NO_RECORDS_AVAILABLE_': 'No records available',
    '_CHECK_CONTROL_DIRECT_DEBIT_DATA_': 'Please, check and adjust the following direct debits',
    '_PAYMENT_VALIDATION_NEEDED_': 'Payment information not validated yet',
    '_CONNECTED_TO_': 'Connected to',
    '_NUMBER_INSTALLEMENTS_': 'Number of terms',
    '_NUMBER_INSTALLEMENTS_LEFT_': 'Number of terms left',
    '_SALE_NUMBER_': 'Sale number',
    '_CUSTOM_EMAIL_': 'Send custom email',
    '_ENABLE_CUSTOM_EMAIL_': 'Enable custom email',
    '_TITLE_': 'Title',
    '_CATEGORIES_SHOP_': 'Shop categories',
    '_ORDER_SHOP_': 'Order shop',
    '_ORDER_SHOP_HOMEPAGE_': 'Order on homepage',
    '_TEXT_BOOKING_PAGE_': 'Text booking form',
    '_TEXT_BOOKING_PAGE_INFO_': 'Enter the title and text below in the available languages. These will be visible on the booking form page.',
		'_TEXT_BOOKING_EMAIL_INFO_': 'You can change the subject and text of the confirmation email that your customer receives after placing the booking.',
    '_COMPANY_ACCOUNT_': 'Company account',
    '_BANK_': 'Bank',
    '_CHAMBER_OF_COMMERCE_NUMBER_': 'Chamber of commerce number',
    '_BANK_ACCOUNT_': 'Bank account',
    '_BANK_ACCOUNT_OWNER_': 'Bank account owner',
    '_REGISTRANT_': 'Registrant',
    '_UBO_DESCRIPTION_': '* UBO = The natural person who:<br/> has an interest of more than 25% of the capital of the legal entity;<br/> more than 25% of the voting rights may exercise at the general meeting or<br/> beneficiary of more than 25% of the assets of the corporation.',
    '_AUTHORIZED_TO_SIGN_': 'Authorized to sign',
    '_ADD_EXTRA_SIGNEE_': 'Add signee',
    '_ADD_DOCUMENTS_': 'Add documents',
    '_MORE_SIGNEES_': 'More signees',
    '_SOLE_PROPRIETORSHIP_': 'Sole proprietorship',
    '_DIFFERENT_PROPRIETORSHIP_': 'Different proprietorship',
    '_SIGNEE_': 'Signee',
    '_AGREE_TERMS_': 'I agree to the terms',
    '_PLEASE_ADD_DOCUMENTS_FOR_VALIDATION_': 'ACTION: Please add documents for validation',
    '_PAY_ATTENTION_NO_CLEARING_': 'Pay attention: if you change the data, the account needs to be revalidated. In the meantime there will be no withdrawals.',
    '_PAY_ATTENTION_NO_CLEARING_2_': 'If you change the data, the account needs to be revalidated. In the meantime there will be no withdrawals. Do you still want to edit?',
    '_PAY_TERMS_': 'Pay terms',
    '_PAYNPLAN_TERMS_': 'Pay n Plan terms',
    '_PAYNPLAN_RATES_': 'Pay n Plan rates',
    '_AGREE_RATES_': 'I agree to the rates',
    '_LEGAL_FORM_': 'Legal form',
    '_SIGNEES_': 'Signees',
    '_CHANGE_COMPANY_INFORMATION_': 'Change company information',
    '_CHANGE_DOCUMENTS_': 'Change documents',
    '_CHECK_DATA_AND_SEND_': 'Check the information below and send',
    '_SIGN_INDEPENDENTLY_': 'Sign independently',
    '_SHARED_AUTHORIZED_': 'Shared authorized',
    '_CATEGORY_': 'Category',
    '_PAYPAL_EMAIL_': 'PayPal e-mailaddress',
    '_PAYPAL_ACCOUNT_': 'PayPal account',
    '_OPTINAL_': 'Optional',
    '_COMPANY_TYPE_': 'Company type',
    '_DASHBOARD_': 'Dashboard',
    '_CREATE_LOGIN_': 'Create password',
    '_CREATE_LOGIN_VIA_BOOKING_': 'Confirm your booking',
    '_NEW_CUSTOMER_': 'Add',
    '_INVITE_CUSTOMER_TO_APP_': 'Send an invitation through e-mail to customer to use the app.',
    '_DELETE_DIRECT_DEBIT_TRANSFER_': 'Do not transfer',
    '_PRODUCT_CHANGE_MESSAGE_': 'If you change a product the new product will be activate for the agenda directly. If it needs to be active starting at the next installment date, actived this subscription and create a new one.',
    '_UBO_PERCENTAGE_': 'Ubo percentage',
    '_SOLE_PROPRIETORSHIP_WITH_AUTHORIZED_': 'Sole proprietorship with authorized person',
    '_LINK_TO_ORDER_DETAILS_PAGE_': 'Order details URL (invoices)',
    '_TOKEN_ORDER_DETAILS_PAGE_': 'Security token order details page',
    '_DATE_START_': 'Date start',
    '_DATE_START_EVENT_': 'Date start',
    '_DATE_END_MESSAGE_': 'No date end = unlimited',
    '_DATE_START_MESSAGE_': 'No date start = today',
    '_VIEW_PAYMENT_MODULE_': 'View payment module',
    '_CLEARING_COSTS_MESSAGE_': 'Withdrawing is free once a month, after that fee will be charged.',
    '_BOOKING_OPTIONS_': 'Booking form?',
    '_HAVE_ACCOUNT_': 'I have an account already',
    '_NOT_HAVE_ACCOUNT_': 'I do not have an account',
    '_CHOOSE_DATE_': 'Choose a date',
    '_PLAN_APPOINTMENT_LATER_': 'I plan the event later',
    '_BOOK_': 'Book',
    '_LANGUAGE_CHANGED_': 'Language changed',
    '_NO_DATES_AVAILABLE_': 'No dates available',
    '_LOGIN_DETAILS_': 'Login details',
    '_DETAILS_': 'Details',
    '_DATETIME_START_': 'Start',
    '_DATETIME_END_': 'End',
    '_TAGS_': 'Tags',
    '_ORDER_CONFIRMATION_EMAIL_': 'Order confirmation email',
    '_BOOKING_CONFIRMATION_EMAIL_': 'Booking confirmation email',
    '_COLLECT_EVERY_': 'Collect every',
    '_SURE_TO_SET_DEFAULT_BACK_': 'Are you sure to set back default text?',
    '_SURE_REMOVE_TEXT_': 'Sure to remove the text?',
    '_BOOKING_LINK_': 'Booking link',
    '_ORDER_LINK_': 'Order link',
    '_BOOK_PRODUCT_TEXT_': 'Through this link below, existing customers and new customers can plan an event and pay directly.',
    '_CHOOSE_PRODUCT_': 'Pick a product',
    '_SUBSCRIPTION_': 'Subscription',
    '_ENTER_TEXT_HERE_': 'Enter text here',
    '_VIEW_AVAILABLE_TAGS_': 'Below you will find the available tags, usable in the text.',
    '_REQUIRED_': 'Required',
    '_OPTIONAL_': 'Optional',
    '_HOW_MUCH_SALES_PER_CUSTOMER_': 'Max per customer',
    '_NUMBER_WEEKS_VALID_': 'Number weeks valid',
    '_MORE_INFORMATION_CONTACT_US_': 'For more information, contact us',
    '_BOOK_WITH_ANOTHER_ACCOUNT_THAN_': 'Book with an account other than the account of',
    '_BOOKING_THANKS_': 'Thanks for your booking!',
    '_ALMOST_READY_': 'Well done, you are almost there!',
    '_IT_WORKED_': 'Het is worked!',
    '_BACK_TO_AGENDA_': 'Back to agenda',
    '_CONFIRMATION_SETTINGS_': 'Confirmation settings',
    '_NEED_CONFIRMATION_': 'Appointment needs customer confirmation',
    '_CONFIRMATION_BOX_TEXT_': 'Confirmation checkbox text',
    '_CONFIRM_BEFORE_SUBSCRIBE_': 'Agree with the conditions before you can subscribe',
    '_FREE_TRIAL_': 'Free trial',
    '_ACTIVATION_PLANNED_': 'Activation planned',
    '_PAUSE_PLANNED_': 'Pause planned',
    '_DEACTIVATION_PLANNED_': 'Deactivation planned',
    '_PAUSED_': 'Paused',
    '_SUBSCRIPTION_WIL_BE_DEACTIVATED_': 'subscription will be deactivated',
    '_INVOICE_EMAIL_DESIRABLE_': 'If invoice via email desirable',
    '_SPECIFICATIONS_': 'Specifications',
    '_TRANSACTION_COSTS_RESERVATION_': 'transaction costs reservation',
    '_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_': 'transaction costs',
    '_INVOICED_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_': 'invoiced transaction costs',
    '_NOT_INVOICED_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_': 'not invoiced transaction costs',
    '_INVOICED_': 'invoiced',
    '_FOR_TRANSACTION_': 'For transaction',
    '_COST_INVOICE_': 'Cost invoice',
    '_NOT_INVOICED_YET_': 'not invoiced yet',
    '_AMOUNTS_INCLUDING_VAT_': 'Amounts including VAT',
    '_NO_EFFECTS_ON_PAYMENT_': 'Changes will effect the agenda only, not payment.',
    '_ACCOUNT_NOT_ALLOWED_': 'Disable create account',
    '_LIST_': 'List',
    '_DIRECT_DEBITS_TOTAL_NUMBER_ITEMS_': 'Number of subscriptions',
    '_REVENUE_PRODUCTS_': 'Revenue per product',
    '_REVENUE_CUSTOMERS_': 'Revenue per customer',
    '_NEW_SALES_': 'New Sales',
    '_DIRECT_DEBITS_VS_DEBITS_': 'Direct debits vs. debits',
    '_UNSUBCRIBERS_': 'Unsubscribers',
    '_ACTIVE_VS_INACTIVE_': 'Active/inactive customers',
    '_NEW_SUBSCRIBERS_': 'New subscritions',
    '_EXPIRED_CREDITS_': 'Expired credits',
    '_TRANSFER_PRODUCTS_': 'Transfer in products',
    '_MONTH_': 'Month',
    '_BAR_': 'Bar',
    '_LINE_': 'Line',
    '_PIE_': 'Pie',
    '_EXTRA_INFO_': 'Extra information',
    '_SHOP_SETTINGS_': 'Shop settings',
    '_SALE_SETTINGS_': 'Sale settings',
    '_PAYMENT_': 'Payment',
    '_AGENDA_SETTINGS_': 'Agenda settings',
    '_UNABLE_TO_SUBSCRIBE_YET_': 'It is not yet possible to subscribe to this appointment',
    '_APPOINTMENT_IN_PAST_': 'Appointment past',
    '_APPOINTMENT_FULL_': 'The appointment is full',
    '_NO_PRODUCTS_AVAILABLE_TO_BOOK_APPOINTMENT_': 'No products available for you to book the appointment',
    '_BOOK_TO_BUY_PRODUCT_AND_SUBSCRIBE_DIRECTLY_': 'Click \'book\' to buy a product and sign up immediately',
    '_DISCOUNT_CODES_': 'Discount codes',
    '_MANAGE_DISCOUNT_CODES_': 'Manage discount codes',
    '_CODE_': 'Code',
    '_DISCOUNT_AMOUNT_': 'Discount amount per product',
    '_DISCOUNT_PERCENTAGE_': 'Discount percentage',
    '_MAX_TIMES_PER_ORDER_': 'Max product types per order',
    '_MAX_ORDERS_PER_USER_': 'Max. gebruik per klant',
    '_VALID_FROM_': 'Valid from',
    '_AVAILABLE_FOR_FOLLOWING_PRODUCTS_': 'Available for the following products',
    '_CHECKIN_ALLOWED_WITH_FOLLOWING_PRODUCTS_': 'Inchecken allowed with',
    '_DISCOUNT_CODE_': 'Discount code',
    '_ENTER_CONFIRMATION_TEXT_HERE_': 'Enter confirmation text here',
    '_PRODUCT_CONNECTED_BY_COMPANY_': 'Manually linked by the company',
    '_REGISTER_': 'Register',
    '_CASH_': 'Cash',
    '_CALCULATE_': 'Calculate',
    '_GET_AMOUNT_': 'Received amount',
    '_RETURN_AMOUNT_': 'Return',
    '_SEND_INVOICE_': 'Send invoice',
    '_ON_TAB_': 'On tab',
    '_NO_PIN_ACCESS_': 'You do not have access to this payment method. If you would like to know more about our Pin devices, you can contact us.',
    '_LEDGER_': 'Ledger',
    '_VIEW_CASH_PAYMENTS_': 'View cash payments',
    '_IN_REGISTER_': 'In register',
    '_CASH_BALANCE_': 'Cash balance',
    '_AMOUNT_TO_WITHDRAWAL_': 'Amount',
    '_REASON_WITHDRAWAL_': 'Reason for withdrawal',
    '_INDIVIDUAL_SALES_': 'Individual sale',
    '_SALESPERSON_': 'Salesperson',
    '_SALES_OVER_PERIOD_': 'Sale over period',
    '_WITHDRAWALS_OVER_PERIOD_': 'Withdrawals over period',
    '_UNPAID_INVOICES_': 'Unpaid invoices',
    '_UNPAID_INVOICES_DESCRIPTION_': 'By crediting the invoice is canceled and disappears from the list.',
    '_NO_UNPAID_INVOICES_': 'There are no unpaid invoices :)',
    '_PAID_INVOICES_': 'Paid invoices',
    '_PAID_INVOICES_DESCRIPTION_': 'Paid invoices.',
    '_NO_PAID_INVOICES_': 'There are no paid invoices :)',
    '_FAILED_OUTSTANDING_BILLS_': 'Failed or open payments',
    '_ENTER_VALID_AMOUNT_': 'Enter valid amount',
    '_REFUND_': 'Refund',
    '_CHOOSE_PAYMENT_METHOD_': 'Choose payment method',
    '_MIN_PRODUCTS_IN_CART_': 'Min. products for discount',
    '_CUSTOMER_UNSUBSCRIBE_SELF_': 'Unsubscribe by customer allowed',
    '_SUBSCRIBED_UNSUBSCRIBE_SELF_NOT_ALLOWED_': 'Registered, unsubscribe yourself is not possible',
    '_MY_APPOINTMENTS_': 'My appointments',
    '_REGISTERED_PARTNER_': 'Registered partner',
    '_CHARGED_': 'charged',
    '_VIEW_APPOINTMENT_': 'View appointment',
    '_ACTIVE_PRODUCTS_': 'Active',
    '_ARCHIVED_PRODUCTS_': 'Archived',
    '_RESTORE_': 'Restore',
    '_ARCHIVE_': 'Archive',
    '_OPEN_ARCHIVE_': 'Open archive',
    '_SUBSCRIBE_AND_BUY_NOT_POSSIBLE_IF_ARCHIVE_': 'If the product is archived, the product can no longer be purchased and can no longer be used to register for appointments.',
    '_NO_REFUNDING_MESSAGE_': 'Attention! Do not make refunds here, because there will not be any credit invoices and your bookkeeping will be incorrect. For refunds go to Financial - Sales - Manage Refunds.',
    '_CASH_WITHDRAWAL_': 'Cash withdrawal',
    '_SUBSCRIBE_AND_BUY_NOT_POSSIBLE_IF_ARCHIVE_': 'If the product is archived, the product can no longer be purchased and can no longer be used to register for appointments.',
    '_REGISTER_PAYMENT_': 'Register',
    '_UNPAID_': 'Unpaid',
    '_PAID_': 'Paid',
    '_PAY_NOW_': 'Pay',
    '_INVOICE_SEND_ON_': 'Invoice is send on',
    '_INVOICE_MESSAGE_': 'The invoice will be send to your customer. You will receive a message when the payment is transfered.',
    '_INVOICE_MESSAGE_INSTALLMENT_': 'The invoice will be send to your customer. You have an installment in the shoppingcart. The first term of this product will be handled right now. The next terms are paid with direct debit. If you send an invoice you will receive a message when the first payment is transfered.',
    '_INVOICE_DESCRIPTION_': 'Description on invoice',
    '_FUTURE_DEACTIVATIONS_': 'Planned deactivations',
    '_DEPOSIT_': 'Deposit',
    '_REASON_DESPOSIT_': 'Reason for deposit',
    '_MANAGE_DEPOSIT_': 'Manage deposit',
    '_ACTIVATE_ON_DIRECT_DEBIT_DATE_': 'Direct debit date',
    '_MAX_NUMBER_PER_ORDER_': 'Max. per product type',
    '_SUBSCRIPTION_WIL_BE_STARTED_': 'subscription will be started',
    '_EDITING_NOT_POSSIBLE_': 'editing not possible',
    '_PAUSE_': 'Pause',
    '_ASCENDING_INSTALLMENT_': 'Created on ascending',
    '_DESCENDING_INSTALLMENT_': 'Created on descending',
    '_CREATED_ON_ASC_': 'Created on ascending',
    '_CREATED_ON_DESC_': 'Created on descending',
    '_CREATED_ON_': 'Created on',
    '_REMOVE_INSTALLMENT_': 'Remove Installment',
    '_PASS_DESCRIPTION_': 'Your password must be at least 6 characters long!\nWe advise you to use a strong password with strange characters, capital letters and numbers.',
    '_PASS_DESCRIPTION_VIA_BOOKING_': 'Choose a password of at least 6 characters to confirm your booking!\nWe advise you to use a strong password with strange characters, capital letters and numbers.',
    '_MESSAGES_': 'Messages',
    '_NO_SUBJECT_': 'No subject',
    '_NEW_MESSAGE_': 'New message',
    '_VIEW_NOW_': 'View now',
    '_VIEW_LATER_': 'View later',
    '_OPEN_': 'Outstanding',
    '_NAME_ATHLETE_': 'Name athlete',
    '_COPY_TO_CLIPBOARD_': 'Copy to clipboard',
    '_COPIED_TO_CLIPBOARD_': 'Copied to clipboard',
    '_AGREE_WITH_TERMS1_': 'I agree with the',
    '_AGREE_WITH_TERMS2_': 'user terms of',
    '_TERMS_': 'Terms of agreement',
    '_COMPANY_TERMS_': 'Terms of agreement with company',
    '_AGREE_WITH_COMPANY_TERMS1_': 'I agree with the',
    '_AGREE_WITH_COMPANY_TERMS2_': 'terms and conditions of',
    '_MANAGE_CUSTOMER_DATA_': 'Manage customer data',
    '_NO_LOGOUT_': "No, logout",
    '_CONFIRM_': "Confirm",
    '_USER_CONDITIONS_CHANGED_': "User conditions have changed",
    '_I_CONFIRM_WITH_CHANGED_USER_CONDITIONS_': "I agree with the changed user conditions of use of",
    '_USER_CONDITIONS_': "User conditions",
    '_AND_I_CONFIRM_WITH_SHARING_DATA_WITH_CONNECTED_COMPANIES_': "In addition, I agree that my personal information will be shared with the companies to which I am affiliated",
		'_CUSTOMER_IS_BLOCKED': 'This customer is blocked.',
    '_BLOCK_CUSTOMER_': 'Are you sure that you want to block this customer? The customer cannot longer participate in your companies activities.',
    '_BLOCK_CUSTOMER_ACHIVED_': 'Are you sure that you want to block this customer? He/she cannot apply to your company with this emailaddress anymore',
    '_ACCEPT_CUSTOMER_': 'Do you want to add the customer to your customerlist?',
    '_ARCHIVE_CUSTOMER_': 'Are you sure that you want to archive this customer? The customer cannot longer participate in your companies activities.',
    '_ARCHIVED_': 'Archived',
    '_INVITED_ON_': 'Invited on',
    '_SYNC_MESSAGE_': 'Customer edited data',
    '_SYNC_BTN_TEXT_': 'Sync data',
    '_IGNORE_SYNC_DATA_': 'Ignore',
    '_IGNORE_SYNC_DATA_ALWAYS_' : 'Always',
    '_CUSTOMER_RECORDS_': 'Record customer:',
    '_SYNC_ALL_': 'Syncronize all records',
    '_DELETE_CUSTOMER_': 'With this action you will delete this customer and all his data from the system (invoices, statistics and sales will be retained without a connection with the customer). There is no option to restore these records. Are you sure that you want to remove this customer?',
    '_RETURN_CUSTOMER_TO_LIST_': 'Are you sure you want to make this customer visible again in your customerList? You have to send an invite to connnect again.',
    '_SEND_INVITE_': 'Send invite',
    '_INVITED_ON_': 'Invited on',
    '_UNBLOCK_CUSTOMER_': 'Do you want to unblock this customer?',
    '_SYNC_CUSTOMER_': 'Syncronize records',
    '_IGNORE_SYNC_MESSAGE_': 'Ignore changes that have been made by the customer now or always',
    '_IGNORE_SYNC_MESSAGE_NEW_MAIL_': 'Emailaddress is changed. If you ignore the changes it might be possible that the emails send by the system won\'t be rceived. Ignore changes now or always',
    '_NOT_ARCHIVED_': 'Not archived',
    '_UNBLOCK_': 'Unblock',
    '_UNDO_': 'Undo',
    '_ADD_CUSTOMER_': 'Add customer',
    '_AWARE_PERSONAL_DATA_': 'By connecting your personal data will be shared with this company',
    '_ADD_': 'Add',
    '_ACCEPT_INVITE_': 'Accept invite',
    '_INVITE_': 'Invite',
    '_UNDO_IGNORE_SYNC_': 'Undo ignore customer edits',
    '_UNDO_IGNORE_SYNC_MESSAGE_': 'Makes the edits made by the customer visible again',
    '_CREATE_NEW_ACCOUNT_GYM_': 'Create your account to subscribe directly with',
    '_INVITE_CUSTOMER_': 'Invite',
    '_GERMANY_': 'Germany',
    '_FRANCE_': 'France',
    '_NOW_': 'Now',
    '_CHECK_MAIL_': 'Is ',
    '_CHECK_MAIL2_': ' the right emailaddress? The data you have filled out will be visible for the receiver when you send an invite.',
    '_SEND_NEW_INVITE_': 'send new invite',
    '_CHANGED_TO_': 'Changed to',
    '_VERSION_COMPANY_TERMS_': 'Version company terms',
    '_INVITE_WILL_BE_SEND_TO_': 'Invite will be send to',
    '_FOLLOWING_DATA_WILL_BE_SHARED_': 'The following data will be shared',
    '_HAVE_CHECKED_ADRESSEE_AND_AGREE_CURRENT_AND_FUTURE_DATA_WILL_BE_SHARED_': 'I have checked the addressee and I approve that the current and future data will be shared with the customer',
    '_SYNC_FIRST_': 'Syncronize customer changes first. Do you want to do that now?',
    '_TERMS_CHANGED_': 'The terms have changed',
    '_MANAGER_': 'Manager',
    '_ARCHIVE_USER_': 'Archive',
  	'_ARCHIVE_PRODUCT_': 'Archive',
    '_CONNECTED_': 'connected',
    '_INVITED_': 'Invited',
    '_FIND_CUSTOMER_WHO_CAN_RECEIVE_MESSAGES_': 'Find customers who can receive messages',
    '_JUST_CONNECTED_CUSTOMERS_CAN_RECEIVE_MESSAGES_': 'Only users that allow pushmessages on their device will receive a notification!',
    '_NO_ACCOUNT_': 'Without account',
    '_BLOCKED_': 'Blocked',
    '_TO_TOP_': 'To top',
    '_AGREE_WITH_COMPANY_TERMS_': 'I agree with the terms and conditions of',
  	'_QUESTION_REMOVE_SELECTED_ITEMS_': 'Are you sure to archive the selected customer(s)?',
  	'_NO_ITEMS_SELECTED_': 'No items selected',
  	'_NO_ITEMS_SELECTED_FOR_EXPORT_': 'Select items to export',
  	'_FILTER_': 'Filter',
  	'_FILTERS_': 'Filters',
  	'_DONE_': 'Done',
  	'_WARNING_': 'Warning',
  	'_NEW_DIRECT_DEBIT_DATE_': 'New collection date',
  	'_EDIT_MULTIPLE_SUBSCRIPTIONS_' : "Edit multiple",
  	'_EDIT_SUBSCRIPTIONS_': "Edit subscriptions",
  	'_CHANGE_OPTION_': "Change option",
  	'_DIRECT_DEBITS_WILL_BE_EDITED_': "subscriptions will be edited. Rolling back is not possible. If a lot of data has to be saved, this may take some time. Please wait.",
  	'_NO_DIRECT_DEBITS_SELECTED_': "No subscriptions selected",
  	'_NO_DATA_AVAILABLE_': "No data available",
  	'_HISTORY_': "History",
  	'_CHANGE_CONFLICT_': "Change conflict",
  	'_FORMER_CHANGE_': "Former change",
  	'_NEW_CHANGE_': "New change",
  	'_CONFLICT_MESSAGE_': "There are changes that conflict with each other, choose which change you want to make.",
  	'_SAVE_CHANGES_': "Save changes",
  	'_CHANGE_PLANNED_': "Change planned",
  	'_STARTING_DATE_': "Date start",
    '_PLANNED_CHANGES_': "Planned changes",
    '_SAVE_DATA_WAIT_': 'If a lot of data has to be saved, this may take some time. Please wait.',
		'_FETCH_DATA_WAIT_': 'If a lot of data has to be fetched, this may take some time. Please wait.',
    '_ZIPCODE_CITY_': 'Zipcode and city',
    '_WARNING_STATS_WILL_DISAPPEAR_': 'Check Dashboard for more records. Soon all records will be found there and the stats will disappear.',
    '_SPAIN_': 'Spain',
    '_ZIP_PLACEHOLDER_': '1101',
    '_CITY_PLACEHOLDER_': 'Amsterdam',
    '_READ_': 'I have read the above',
    '_MY_PRODUCTS_': 'My products',
    '_SHOW_CART_': 'Show shoppingcart',
    '_MAX_ALLOWED_SUBSCRIBE_PER_DAY_': 'Max times allowed to subscribe per day',
    '_MAX_NUMBER_SUBSCRIPTIONS_PER_DAY_REACHED_': 'You have reached the max subscriptions per day',
    '_ENABLE_MANAGEMENT_SUMMARY_': 'Enable management summary',
    '_YOU_NEED_A_PRODUCT_CONTACT_COMPANY_': 'You need a product for participation. Contact',
    '_AGENDA_VIEW_': 'Agenda view',
    '_PICK_DASHBOARD_': 'Dashboard',
    '_COLOR_BACKGROUND_AGENDA_': 'Color background agenda',
    '_PICK_AGENDA_': 'Pick agenda',
    '_SETTINGS_PER_AGENDA_': 'Settings per agenda',
    '_AGENDA_NOT_VISIBLE_FOR_CUSTOMER_': 'Hide agenda for customer',
    '_APPOINTMENT_TYPES_': 'Appointment types',
    '_COLOR_BACKGROUND_APPOINTENTS_': 'Color background appointments',
    '_COLOR_TEXT_APPOINTENTS_': 'Color text appointments',
    '_CUSTOMER_CAN_OVERWRITE_SETTING_': 'Customer can overwrite agenda view',
    '_TEMP_COMMENT_': 'Temporary comment (e.g. injury)',
    '_OTHER_COMMENTS_': 'Other comments',
    '_FIRST_SUBSCRIPTION_': 'Subscribed for the first time',
    '_NEW_': 'New',
    '_FAQ_SHORT_': 'FAQ',
    '_PROFILE_IMAGE_': 'Profile image',
    '_COMPANY_': 'Company',
		'_YOU_WERE_INVITED_BY_': 'your were invited by',
		'_PICK_YOUR_COLOR_': 'Pick your color',
		'_THEME_COLOR_': 'Theme color',
		'_BG_COLOR_NAVBAR_': 'Background color header',
		'_COLOR_NAVBAR_': 'Font color header',
		'_BG_COLOR_BUTTONS_': 'Background color buttons',
		'_COLOR_BUTTONS_': 'Font color buttons',
		'_EXPORT_': 'Export',
		'_NEW_': 'New',
		'_OLD_': 'Old',
		'_CATEGORIES_': 'Categories',
		'_SUBSCRIPTION_': 'Subscription',
		'_SECURED_BEHIND_LOGIN_': 'This page is secured, login to acces it!',
		'_BOOKING_FORM_SETTINGS_': 'Create booking form?',
		'_ENABLE_': 'Enable',
		'_BOOKING_FORM_INFO_': 'Via the booking form, customers can directly book and pay for your workshop or event. After you save the product you will be able to find the link to the form.',
		'_SALE_SETTINGS_INFO_': 'Only enable the Disable account creation option if no account needs to be created and the sale does not have to be linked to the customer.',
		'_TEXT_': 'Text',
		'_CREDITS_SETTINGS_': 'Credits settings',
    '_EMAIL_FOR_CHANGES_': 'To change records, e-mail to',
    '_NO_INFORMATION_AVAILABLE_': 'No information available',
    '_SHOPPING_CART_': 'Shoppingcart',
    '_MY_PURCHASES_': 'My purchases',
		'_EMPTY_IS_UNLIMITED_': 'empty is unlimited',
    '_NOTHING_IN_CART_': 'There is nothing in your shoppingcart',
    '_VIEW_TERMS_': 'View company terms',
    '_THERE_IS_': 'There is',
    '_UNPAID_INVOICE_': 'unpaid invoice',
    '_DOWNLOAD_': 'Download',
    '_DISCOUNT_OPTIONS_': 'Discount settings',
    '_DISCOUNT_OPTIONS_INFO_': 'Discount options',
		'_MAX_TIMES_PER_ORDER_INFO_': '<b> Max. products per order</b><br>Only applicable with discount percentage, discount will be applied to the most expensive product! How many products per order can the discount be applied to?',
		'_MAX_NUMBER_PER_ORDER_INFO_': '<b> Max. per product type</b> <br>Only applicable with discount percentage! Example. You give a 10% discount on a ride ticket. How often can your customer purchase this trip card incl. The discount?',
		'_MAX_ORDERS_PER_USER_INFO_': '<b> Max. use per customer</b> <br>How often can your customer use the discount code?',
		'_MIN_PRODUCTS_IN_CART_INFO_': '<b> Min. discount products</b> <br>How many of the products you have chosen must be purchased by a customer to get the discount. This can also be 2 or more of the same product (unless set otherwise at max. Per product type).',
		'_ACCOUNTANCY_': 'Accountancy',
    '_SWITCH_WOD_': 'Pick WOD',
    '_GROUP_': 'Group',
    '_GO_TO_DASHBOARD_': 'View your dashboard',
    'SHOW_ONLY_SELECTED_CATEGORY_': 'Show only selected category',
    '_NO_RESULTS_': 'No results',
    '_NO_RESULTS_YET_': 'No results yet',
		'_CLEARING_THRESHOLD_LEVEL_INFO_TITLE_': 'Leave for repayments?',
		'_CLEARING_THRESHOLD_LEVEL_INFO_': 'If you want to have the withdrawable balance fully transferred, enter no amount below! The amount entered here will remain in the account for possible refunds and will not be paid.',
		'_CLEARING_PERIOD_': 'Pay out how often?',
		'_CLEARING_PERIOD_INFO_TITLE_': 'How do the payouts work?',
		'_CLEARING_PERIOD_INFO_': 'If you opt for monthly or weekly, your payout will automatically continue, you don\'t have to do anything else. With the once option you can always make single recordings yourself. Pay attention! Withdrawing money more than once a month, there are additional costs involved.',
		'_CLEARING_ON_HOLD_MESSAGE_': 'Your payout is on-hold, your company details may have been adjusted. Contact support for more information.',
		'_ORIGINAL_SALE_': 'Original sale',
		'_REPAY_': 'Repay',
    '_PUBLISH_NOW_': 'Now',
    '_PUBLISH_OR_NOT_': 'You can publish the WOD directly or do it later',
    '_LATER_': 'Later',
		'_TYPE_': 'Type',
		'_SEND_INVOICES_VIA_REGISTER_': 'Send via register',
		'_INVOICE_DATA_': 'Invoice data',
		'_SELECTED_CUSTOMER_': 'Selected customer',
    '_SHOW_REPORTING_': 'View intake + reporting',
    '_SELECT_DEVICE_': 'Select terminal',
		'_CHANGE_DIRECT_DEBIT_AMOUNTS_': 'Change direct debit amounts',
		'_DIRECTS_DEBIT_TO_CHANGE_': 'Subscription to change',
		'_CREATE_DIRECTS_DEBIT_': 'Create Direct Debit',
    '_DESCRIPTION_RECEIPT_': 'Description on receipt',
		'_AGENDA_STATUS_': 'Agenda status',
		'_DIRECT_DEBIT_STATUS_': 'Direct Debit status',
    '_CATEGORY_MESSAGE_': 'Create categories for the shop, so your products can be ordered. If "visible in shop" is set for the product, you can connect it with a category.',
    '_DISCOUNT_MESSAGE_': 'Sometimes you want to give your customers something extra. Here you can create discount codes they can use if they order a product.',
    '_QR_EVENT_MESSAGE_': 'Create your QR code events here, these can be scanned, the system checks whether your customer has the right product for participation!',
		'_PRODUCT_INACTIVE_': 'This product is not active',
		'_PRODUCT_ACTIVE_': 'This product is active',
		'_ONGOING_PRODUCTS_': 'Ongoing products',
		'_OTHER_PRODUCTS_': 'Other products',
    '_NO_PRODUCTS_MESSAGE_': 'You have no products yet. Buy one in the shop or ask the company for the options.',
    '_NO_INTAKE_MESSAGE_': 'You haven\'t done an intake. Ask the company for the options.',
    '_NO_REPORT_MESSAGE_': 'There are no reports yet. Ask the company for the options.',
    '_SELECTED_': 'Selected',
    '_NEW_APPOINTMENT_': 'New appointment',
    '_FREQUENCY_': 'Frequency',
    '_SINGLE_APPOINTMENT_': 'Single appointment',
    '_INFO_ABOUT_PRODUCTS_': 'Choose the products you want to connect to this appointment type. Your customers can only use the chosen products to subscribe to appointments with this appointment type.',
    '_INFO_ABOUT_PRIVACY_': 'Attention, if you select \'yes\', all of your customers can see who is subscribed to the appointment.',
    '_INFO_ABOUT_SETTINGS_': '<b>Family members</b><br>Customers can add family members to the calendar. Your customer paid for the family members. Watch out with unlimited subscriptions. <br> <br> <b> Waiting list </b> <br> Customers can register on the waiting list if the appointment is full. They are enrolled and receive notification of this when space becomes available.',
    '_REMOVE_APPOINTMENT_TYPE?_': 'Are you sure you want to remove this appoinmenttype?',
    '_APPOINTMENT_SETTINGS_': 'Appointment settings',
    '_WITH_PRODUCT_': 'With product',
    '_HOW_TO_SUBSCRIBE_': 'Subscribe your customer with his/her product. You can also choose to subscribe them for free.',
    '_FROM_LOWER_': 'from',
		'_COLOR_SETTINGS_': 'Color settings',
		'_SELECT_APPOINTMENT_TYPE_TO_EDIT': 'Select an appointment type to edit',
		'_EDIT_APPOINTMENT_': 'Edit appointment',
		'_WAITING_': 'waiting',
		'_YOU_ARE_SUBSCRIBED_': 'You are subscribed',
		'_NOT_ENOUGH_CREDITS_FOR_APPOINTMENT_': 'There is insufficient credit for participation',
		'_YOU_WILL_BE_SUBSCIBED_AFTER_PAYMENT_': 'Choose your product, after the payment you will be automatically registered for the appointment.',
		'_SUBSCIBE_QUESTION_WHO_': 'Who do you want to subscribe?',
		'_MYSELF': 'Myself',
		'_SUBSCIBE_WHO_ERROR_': 'Select who you want to subscribe',
		'_SEND_MERCHANT_QUESTION_': 'Did you check everything and want to send the merchant data?',
		'_EARN_MONEY_WITH_': 'Earn money with',
		'_I_WANT_TO_KNOW_MORE_': 'I want to know more',
		'_PRESENT_': 'present',
		'_MARK_AS_PRESENT_': 'Mark as present',
		'_MARK_AS_ABSENT_': 'Mark as absent',
		'_SHOW_CUSTOMER_': 'Show customer',
		'_SHOW_CUSTOMER_': 'Show customer',
		'_ICE_NAME_': 'Emergency contact name',
		'_ICE_PHONE_': 'Emergency contact phone',
		'_ICE2_NAME_': 'Emergency second contact name',
		'_ICE2_PHONE_': 'Emergency second contact phone',
		'_ICE_': 'Emergency contact',
		'_ICE2_': 'Emergency contact 2',
		'_TIME_MINUTES_': 'Number minutes',
		'_TIME_SECONDS_': 'Number seconds',
		'_EMPLOYEES_INSTRUCTORS_': 'Trainers (supervisors)',
		'_TRAINERS_': 'Trainers',
		'_FILTER_APPOINTMENTS_': 'Filter appointments',
		'_APPLY_': 'Apply',
		'_HOURS_OVERVIEW_': 'Hours overview',
		'_TOTALS_OVER_SELECTED_PERIOD_': 'Totals over selected period',
		'_NUMBER_APPOINTMENTS_': 'Number appointments',
		'_NUMBER_SUBSCRIPTIONS_': 'Number subscriptions',
		'_DURATION_IN_HOURS_': 'Number hours',
		'_N0_TRAINERS_CONNECTED_TO_AGENDAS_': 'No trainers available',
		'_N0_TRAINERS_CONNECTED_TO_AGENDAS_CONTENT_': 'First link trainers to the agenda (s) and then to the appointments to view hours',
		'_ACTIVATE_MODULE_': 'Activate this module',
		'_ACTIVATE_MODULE_PREFIX_': 'Activate this module for only',
		'_ACTIVATE_MODULE_POSTFIX_': 'per month',
		'_YOUR_MONTHLY_AMOUNT_WILL_BE_CHANGED_': 'Your monthly amount will be adjusted after activation',
		'_THANKS_URL_INFO_': 'You can enter the URL to your personal thank you page here! Please note, this does not work from the shoppingcart.',
		'_THANKS_URL_PlACEHOLDER_': 'https://',
		'_THANKS_URL_': 'Thank you page URL',
		'_THANKS_PAGE_TITLE_': 'Own thank you page URL',
		'_SEND_MESSAGE_': 'Send message',
    '_SEND_MESSAGE_INFO_TEXT_': 'Do you want to send a message about a specific selection? For example, all people with a certain product?\nUsing the dashboards you can make your selection and send your a message!',
		'_INSTRUCTION_VIDEOS_': 'Instruction videos (only in Dutch)',
		'_PUSH_MESSAGE_AND_EMAIL_': 'Pushmessage and email',
		'_PUSH_MESSAGE_': 'Pushmessage',
		'_INSERT_LINK_URL_': 'Insert Link URL',
		'_ACTIVECAMPAIGN_CONNECTION_': 'ActiveCampaign Settings',
		'_API_ACCOUNT_': 'API Account Name',
		'_API_KEY_': 'API Key',
		'_NO_ACTIVE_CAMPAIGN_ACCOUNT_YET_': 'No ActiveCampaign account yet',
		'_CREATE_YOUR_ACTIVE_CAMPAIGN_ACCOUNT_': 'Create your account',
		'_CONNECTED_WITH_ACCOUNT_': 'Connected with account',
		'_DISCONNECT_CONNECTION_': 'Disconnect',
		'_QUESTION_DISCONNECT_API_CONNECTION_': 'Are you sure? The connection including all triggers will be removed.',
		'_QUESTION_REMOVE_TRIGGER_': 'Are you sure? The trigger will be removed.',
		'_CONNECTION_NOT_ACTIVE_': 'The connection is not active, action is required.',
		'_RECONNECT_': 'Reconnect',
		'_WITH_': 'with',
		'_TRIGGERS_': 'triggers',
		'_TRIGGER_': 'Trigger',
		'_ACTION_': 'Action',
		'_CREATE_NEW_TRIGGER_': 'Create new trigger',
		'_EDIT_TRIGGER_': 'Edit trigger',
		'_NAME_YOUR_TRIGGER_': 'Name your trigger',
		'_SELECT_EVENT_': 'Select an event',
		'_SELECT_ACTION_': 'Select an action',
		'_WHICH_LIST_': 'Which list?',
		'_WHICH_TAG_': 'Which tag?',
		'_TAG_': 'Tag',
		'_SELECT_LIST_': 'Select a mailing list',
		'_SELECT_TAG_': 'Select a tag',
		'_SELECT_TRIGGER_PRODUCTS_': 'For which product (s)? None is always!',
		'_INFO_ABOUT_TRIGGER_PRODUCTS_': 'The trigger is only executed if one of the selected products has been sold. If you do not choose products, the trigger is always executed regardless of the product that has been sold.',
		'_SELECT_TRIGGER_TRIALS_': 'At which trials(s)? None is always!',
		'_INFO_ABOUT_TRIGGER_TRIALS_': 'The trigger is only executed if one of the selected trials has been booked. If you do not choose a trial, the trigger will always be executed regardless of the type of trial that has been booked.',
		'_WHICH_ACTIONS_NEEDS_TO_HAPPEN_': 'Which action should be taken?',
		'_MY_INVOICES_': 'My invoices',
    '_YOUR_INVOICES_EXPLAINED_': 'You can download the invoices that you pay to Pay n Plan via the link below. Click on the link and use the security token to download your invoices!',
    '_COPY_TOKEN_TO_CLIPBOARD_': 'Copy token to clipboard',
    '_YOUR_INVOICES_IN_WElCOME_MAIl_': 'In the personal welcome email that you received from us after placing your order, you will find the invoice link with which you can download the invoices that you have paid to us.',
    '_END_YEAR_BALANCES_': 'Year-end balance',
    '_SEPA_IMPORT_SETTINGS_': 'SEPA import settings',
    '_IMPORT_MATCH_ID_': 'Unique ID for import Direct Debits',
    '_NUMBER_REMINDER_MESSAGES_SEND_': 'Send (reminder) messages',
    '_SHOW_MESSAGE_': 'Show message',
    '_WAY_OF_PAYMENT_': 'How will your customer pay his installments?',
    '_WAY_OF_PAYMENT_INFO_': 'You choose here whether you want to debit periodically via SEPA Direct Debit or send your customer an email with a payment link. You usually use the last option when your customer does not want an automatic bank debit!',
    '_PAY_VIA_SEPA_': 'Automatic debit via SEPA Direct Debit',
    '_PAY_VIA_INVITE_': 'Customer pays himself via payment link in mail',
    '_CLEARING_SCHEDULED_FOR_': 'Payout scheduled on',
    '_SCHEDULED_YOUR_CLEARING_': 'Schedule your payout via management/payments/transactions!',
    '_BALANCE_TOTAL_': 'Total balance',
    '_BALANCE_AVAILABLE_': 'Withdrawable balance',
    '_TO_ADMIN_ENV_': 'To admin environment',
    '_TO_CUSTOMER_ENV_': 'Visit customer area',
    '_NEWEST_SALES_': 'Newest sales',
    '_LATEST_TRANSACTIONS_': 'Last Transactions',
    '_VIEW_ALL_SALES_': 'View all sales',
    '_VIEW_ALL_TRANSACTIONS_': 'View all transactions',
    '_I_WANT_TO_UPGRADE_PAY': 'Upgrade and enable payment system',
    '_THE_PAYMENY_MODULE_IS_DISABLED_': 'The payment module is disabled! Do you also want to automate your payments?',
    '_TO_SUBSCRIPTION_MANAGEMENT_': 'To subscription management',
    '_TO_ACCOUNTING_': 'To accounting',
    '_AMOUNT_OUTSTANDING_': 'Amount outstanding',
    '_UPCOMING_APPOINTMENTS_': 'Upcoming appointments',
    '_TO_AGENDA_': 'To the calendar',
    '_NO_NEW_APPOINTMENTS_': 'No new appointments have been scheduled (yet).',
    '_YOUR_LATEST_PURCHASES_': 'Latest purchases',
    '_YOUR_STILL_HAVE_': 'there are',
    '_VIEW_YOUR_PURCHASES_': 'View your purchases',
    '_NO_PURCHASES_YET_': 'Je have no purchases yet.',
    '_TO_ALL_PRODUCTS_': 'View all products',
    '_FROM_THE_ASSORTMENT_': 'Products from the assortment',
    '_PRODUCT_OFFER_': 'Product offer',
    '_STOCK_MANAGEMENT_': 'Stock management (for e.g. ticket sales)',
    '_SHOW_STOCK_ON_PRODUCTPAGE_': 'Show stock on product page?',
    '_DECREASE_STOCK_BY_': 'Decrease inventory by (enter number)',
    '_INCREASE_STOCK_BY_': 'Increase inventory by (enter number)',
    '_STOCK_INFO_': 'You can enable stock management here! Please note, always enter a positive number for the amount you want to add or subtract from the current stock. You can view the current stock in your list of products by clicking the product open.',
    '_THE_CURRENT_SUPPLY_IS_': 'The current supply is',
    '_SEND_MESSAGE_': 'Send message',
    '_APPOINTMENT_REMINDER_DIVIDER_': 'Send client appointment reminder?',
    '_INFO_ABOUT_APPOINTMENT_REMINDER_': 'If you enable this option, all your customers will receive reminders by email and push notification. Your customer cannot unsubscribe from this and this cannot be turned off per customer!\n\nExplanation minutes:\n 1 hour = 60 minutes\n12 hours = 720 minutes\n24 hours = 1440 minutes',
    '_ENABLE_APPOINTMENT_REMINDER_': 'Enable',
    '_NUMBER_MINUTES_BEFORE_APPOINTMENT_REMINDER': 'Number of minutes before (24 hours = 1440 minutes)',
    '_ENTER_HERE_APPOINTMENT_REMINDER_': 'Enter the number of minutes',
    '_BUTTON_ADD_TO_CALENDAR_': 'Add to own calendar',
    '_QR_EVENTS_': 'QR Events',
    '_CHECKINS_': 'Check-ins',
    '_OPEN_QR_SCANNER_': 'Open QR scanner',
    '_QR_ACCESS_EVENT_': 'Event with QR code access',
    '_EVENT_HAS_START_END_DATE_': 'Add a start and end date?',
    '_CHECKIN_WITH_QR_': 'Check-in with QR Code',
    '_SELECT_QR_EVENT_': 'what do you want to check in for?',
    '_SELECT_QR_INCHECK_PRODUCT_OPTION_': 'What do you check in with?',
    '_SHOW_QR_CODE_': 'To QR Code Check-in >',
    '_SELECT_QR_INCHECK_PRODUCTS_TITLE_': 'Which product do you want to check in with?',
    '_SCAN_QR_CODE_': 'Scan the QR Code for access',
    '_PAGE_NOT_FOUND_': 'Page not found!',
    '_SELECT_CAMERA_': 'Select your camera',
    '_ENABLE_CAMERA_': 'Enable camera',
    '_CLICK_ENABLE_CAMERA_': 'Click here to enable camera',
    '_CLICK_DISABLE_CAMERA_': 'Click here to disable camera',
    '_SELECT_A_CAMERA_FIRST_': 'Select your camera first',
    '_WAITING_FOR_CAMERA_': 'Waiting for camera',
    '_WERE_CHECKING_YOUR_QR_NOW_': 'Please wait, we are checking your QR code...',
    '_QR_CHECK_FAILED_': 'Check in failed, invalid QR code!',
    '_QR_CHECKIN_PLAY_SOUND_': 'Play sound after every checkin',
    '_EVENT_NAME_': 'Event',
    '_SORT_EVENT_NAME_A_Z_': 'Name event: a-z',
    '_SORT_EVENT_NAME_Z_A_': 'Name event: z-a',
    '_SORT_CHECKIN_DATE_DESC_': 'Date check in latest',
    '_SORT_CHECKIN_DATE_ASC_': 'Date check in olders',
    '_TOTAL_CHECKINS_': 'Total Checkins',
    '_SEND_TO_COLLECTION_AGENCY_': 'Sent to collection agency on',
    '_TO_COLLECTION_AGENCY_TITLE_': 'Transfer to collection agency?',
    '_MORE_INFO_': 'More information',
    '_COLLECTION_AGENCY_ENABLED_LINK_': 'Collection agency module is enabled',
    '_COLLECTION_AGENCY_INFO_TITLE_': 'Collection agency information',
    '_ENABLE_MODULE_': 'Enable the module',
    '_DISABLE_MODULE_': 'Disable the module',
    '_DISABLE_REMINDERS_': 'Disable reminders',
    '_ENABLE_REMINDERS_': 'Enable reminders',
    '_REMINDERS_ARE_DISABLED_FOR_OPEN_INVOICE_': 'You disabled reminders for this unpaid invoice',
    '_INSTALL_APP_': 'Install App',
    '_INSTALL_APP_ALREADY_BUTTON_': 'Hide button, i already installed the app',
	},

  nl_NL: {
    '_HOME_': 'Home',
    '_MANAGEMENT_': 'Beheer',
    '_DEFAULT_APPOINTMENT_DURATION_MINUTES_': 'Standaard afspraakduur in minuten',
    '_MY_ACCOUNT_': 'Mijn account',
    '_REMOVE_ACCOUNT_': 'Verwijder account',
    '_REMOVE_ACCOUNT_CONFIRM_': 'Al je gegevens worden permanent verwijderd, dit kan niet ongedaan gemaakt worden!',
    '_REMOVE_MY_ACCOUNT_': 'Verwijder mijn account',
    '_REMOVE_ACCOUNT_INFO_': 'Via onderstaande knop kun je jouw account verwijderen.\n\nAl je gegevens worden definitief verwijderd, dit kan niet meer ongedaan gemaakt worden.! ',
    '_MY_COMPANIES_': 'Mijn bedrijven',
    '_LANGUAGE_': 'Taal',
    '_LOGIN_': 'Inloggen',
    '_LOGOUT_': 'Uitloggen',
    '_SETTINGS_': 'Instellingen',
    '_CHANGE_SETTINGS_': 'Instellingen aanpassen',
		'_CHANGE_STATUS_': 'Status veranderen',
    '_CLOSE_': 'Sluit',
    '_SAVE_': 'Opslaan',
    '_SELECT_LANGUAGE_': 'Kies je taal',
    '_SELECT_DATE_': 'Kies datum',
    '_SELECT_DATE_TIME_': 'Kies datum en tijd',
    '_SETTINGS_SAVED_': 'Je instellingen zijn opgeslagen',
    '_NOTICE_': 'Melding',
    '_YOUR_USERNAME_': 'Gebruikersnaam / E-mail',
    '_YOUR_PASSWORD_': 'Wachtwoord',
    '_EMAIL_': 'E-mail',
    '_YOUR_EMAIL_': 'Jouw e-mailadres',
    '_NO_ACCOUNT_YET_': 'Nog geen account',
    '_FORGOT_PASSWORD_': 'Wachtwoord vergeten',
    '_CREATE_NEW_ACCOUNT_': 'Account maken',
    '_CREATE_NEW_ACCOUNT_TEXT_': 'Maak je account aan en zoek naar het bedrijf waar je lid van wilt worden.',
    '_CREATE_NEW_ACCOUNT_TEXT_VA_': 'Maak hier je account aan.',
    '_SEND_DATA_': 'Verstuur gegevens',
    '_FORGOT_PASS_TEXT_': 'Na het aanvragen van je nieuwe wachtwoord ontvang je een mail, vergeet niet op de link te klikken in de mail om het nieuwe wachtwoord te activeren!',
    '_USERNAME_': 'Gebruikersnaam',
    '_MOBILE_': 'Mobiel',
    '_PASSWORD_': 'Wachtwoord',
    '_PASSWORD2_': 'Herhaal wachtwoord',
    '_BIRTH_DATE_': 'Geboortedatum',
    '_FIRST_NAME_': 'Voornaam',
    '_MIDDLE_NAME_': 'Tussenvoegsel',
    '_LAST_NAME_': 'Achternaam',
    '_GENDER_': 'Geslacht',
    '_MALE_': 'Man',
    '_FEMALE_': 'Vrouw',
    '_REGISTER_': 'Aanmelden',
    '_SEARCH_': 'Zoeken',
    '_SEARCH_YOUR_COMPANY_': 'Zoek jouw bedrijf',
    '_PRODUCT_MODULE_DESCRIPTION_': 'Beschikbaar en afgenomen',
    '_FIND_COMPANY_TITLE_': 'Zoek jouw bedrijf',
    '_ZIPCODE_': 'Postcode',
    '_NAME_': 'Naam',
    '_SEARCH_ON_': 'Zoek op',
    '_THE_NETHERLANDS_': 'Nederland',
    '_BELGIUM_': 'België',
    '_COUNTRY_': 'Land',
    '_ZIPCODE_OR_NAME_': 'Postcode of naam',
    '_FILL_IN_HERE_': 'Hier invullen',
    '_FIND_COMPANY_TEXT_': 'Vind het bedrijf en wordt lid om in kunnen schrijven op afspraken',
    '_ADD_COMPANY_': 'Voeg bedrijf toe',
    '_COMPANY_CONTACT_ME_': 'Neem contact met mij op',
    '_COMPANY_CONTACT_ME_CONFIRM_': 'Je gegevens worden doorgestuurd aan het bedrijf zodat ze contact met je op kunnen nemen.',
    '_CANCEL_': 'Annuleer',
    '_OK_': 'Ok',
    '_USERS_': 'Gebruikers',
    '_ADD_USER_': 'Gebruiker toevoegen',
    '_SUNDAY_': 'zondag',
    '_MONDAY_': 'maandag',
    '_TUESDAY_': 'dinsdag',
    '_WEDNESDAY_': 'woensdag',
    '_THURSDAY_': 'donderdag',
    '_FRIDAY_': 'vrijdag',
    '_SATURDAY_': 'zaterdag',
    '_SUN_': 'zo',
    '_MON_': 'ma',
    '_TUE_': 'di',
    '_WED_': 'wo',
    '_THU_': 'do',
    '_FRI_': 'vr',
    '_SAT_': 'za',
    '_JAN_': 'jan',
    '_FEB_': 'feb',
    '_MAR_': 'mrt',
    '_APR_': 'apr',
    '_MAY_': 'mei',
    '_JUNE_': 'jun',
    '_JULY_': 'jul',
    '_AUG_': 'aug',
    '_SEPT_': 'sep',
    '_OCT_': 'ok',
    '_NOV_': 'nov',
    '_DEC_': 'dec',
    '_JAN_FULL_': 'januari',
    '_FEB_FULL_': 'februari',
    '_MAR_FULL_': 'maart',
    '_APR_FULL_': 'april',
    '_MAY_FULL_': 'mei',
    '_JUNE_FULL_': 'juni',
    '_JULY_FULL_': 'juli',
    '_AUG_FULL_': 'augustus',
    '_SEPT_FULL_': 'september',
    '_OCT_FULL_': 'oktober',
    '_NOV_FULL_': 'november',
    '_DEC_FULL_': 'december',
    '_WEEK_': 'Week',
    '_DAY_': 'Dag',
    '_AGENDA_': 'Agenda',
    '_MANAGEMENT_': 'Beheer',
    '_APPOINTMENT_': 'Afspraak',
    '_EDIT_': 'Bewerken',
    '_CREATE_APPOINTMENT_TYPE_': 'Maak nieuw afspraaktype aan',
    '_FREE_': 'Gratis',
    '_WITH_CREDITS_': 'Met rittenkaart',
    '_WITH_INSTALLMENTS_': 'Met abonnement',
    '_BOOK_CREDITS_': 'Ritten afboeken',
    '_CHOOSE_NUMBER_CREDITS_': 'Kies aantal ritten',
    '_SUBSCRIBE_POSSIBLE_WITH_': 'Inschrijven kan',
    '_CUSTOMER_SEES_SUBSCRIPTIONS_': 'Klant ziet inschrijvingen',
    '_YES_': 'Ja',
    '_NO_': 'Nee',
    '_PRIVACY_': 'Privacy',
    '_CHOOSE_APPOINTMENT_TYPE_': 'Kies afspraaktype',
    '_MANAGE_APPOINTMENT_TYPES_': 'Afspraaktypes beheren',
    '_ENABLE_WAITINGLIST_': 'Wachtlijst inschakelen',
    '_SUBSCRIPTION_SETTINGS_': 'Inschrijf instellingen',
    '_SUBSCRIBE_POSSIBLE_FROM_': 'Inschrijven vanaf',
    '_IN_ADVANCE_': 'uur van tevoren',
    '_IN_ADVANCE_PLAIN_': 'van tevoren',
    '_DAY_LOWER_': 'dag',
    '_DAYS_LOWER_': 'dagen',
    '_HOUR_': 'uur',
    '_WEEKS_': 'weken',
    '_WEEK_LOWER_': 'week',
    '_NO_LIMITATION_': 'Geen beperking',
    '_SUBSCRIBE_MAX_HOURS_': 'Inschrijven tot',
    '_UNSUBSCRIBE_MAX_HOURS_': 'Uitschrijven tot',
    '_UNSUBSCRIBE_PAID_FROM_HOURS_': 'Betaald uitschrijven vanaf',
    '_APPOINTMENT_BEGINS_': 'Afspraak begint',
    '_DELETE_': 'Verwijderen',
    '_ENTER_NAME_': 'Vul een naam in',
    '_EDIT_APPOINTMENT_TYPE_': 'Bewerk afspraaktypes',
    '_ARE_YOU_SURE_': 'Weet je het zeker?',
    '_ONLY_THIS_': 'Alleen deze',
    '_THIS_AND_FUTURE_': 'Deze en toekomstige',
    '_REMOVE_APPOINTMENT_': 'Verwijder afspraak',
    '_REMOVE_APPOINTMENT_LEGEND_': 'Afspraak verwijderen',
    '_SUBSCRIPTIONS_TO_APPOINTMENT_': 'Inschrijvingen op afspraak',
    '_SUBSCRIPTIONS_TO_WAITINGLIST_': 'Inschrijvingen op wachtlijst',
    '_THERE_ARE_': 'Er zijn',
    '_SUBSCRIPTIONS_': 'inschrijvingen',
    '_SUBSCRIBE_CUSTOMER_': 'Klant inschrijven',
    '_SUBSCRIBE_': 'Inschrijven',
    '_SUBSCRIBE_FREE_': 'Gratis inschrijven',
    '_APPOINTMENT_MANAGEMENT_': 'Afspraak beheer',
    '_SELECT_': 'Selecteer',
    '_APPOINTMENT_TYPE_': 'Afspraaktype',
    '_MAKE_APPOINTMENT_TYPES_FIRST_': 'Voor je afspraken kunt inplannen moet je tenminste 1 afspraaktype maken. Sluit dit venster en klik onderaan op de beheer tab om dit te doen!',
    '_ENTER_NUMBER_': 'Vul een getal in',
    '_ENTER_NUMBER_POSITIVE_': 'Vul een positief getal in',
    '_MAX_SUBSCRIPTIONS_': 'Max inschrijvingen',
    '_DATE_FROM_': 'Van datum',
    '_DATE_UNTIL_': 'Tot datum',
    '_LOCATION_': 'Locatie',
    '_ENTER_LOCATION_': 'Vul locatie in',
    '_ENTER_DESCRIPTION_': 'Vul omschrijving in',
    '_CHANGE_': 'Wijzigen',
    '_ONLY_THIS_APPOINTMENT_': 'Alleen deze afspraak',
    '_THIS_APPOINTMENT_AND_FUTURE_': 'Deze en toekomstige in reeks',
    '_REPEAT_': 'Herhalen',
    '_SINGLE_APPOINTMENT_CLICK_CHANGE_': 'Eenmalige afspraak (klik om te wijzigen)',
    '_DAILY_': 'Dagelijks',
    '_WORKING_DAY_': 'Iedere werkdag (ma-vrij)',
    '_WEEKLY_': 'Wekelijks',
    '_MONTHLY_': 'Maandelijks',
    '_EVERY_': 'elk(e)',
    '_ST_': 'e',
    '_AT_DAY_': 'op dag',
    '_YEARLY_': 'Jaarlijks',
    '_AT_': 'op',
    '_DESCRIPTION_': 'Omschrijving',
    '_FROM_': 'Van',
    '_UNTIL_': 'Tot',
    '_UNTIL_LOWER_': 'tot',
    '_SPOTS_AVAILABLE_INFO_': 'Beschikbare plekken',
    '_SPOTS_AVAILABLE_': 'beschikbaar',
    '_SPOTS_LEFT_': 'over',
    '_SUBSCRIBE_TO_APPOINTMENT_': 'Inschrijven',
    '_YOU_NEED_A_PRODUCT_': 'Koop een product',
    '_UNSUBSCRIBE_FROM_APPOINTMENT_': 'Uitschrijven',
    '_UNSUBSCRIBE_NO_LONGER_POSSIBLE_': 'Uitschrijven is niet meer mogelijk',
    '_SUBSCRIBE_NO_LONGER_POSSIBLE_': 'Inschrijven is niet meer mogelijk',
    '_WHEN_SUBSCRIBING_': 'Bij inschrijving worden er',
    '_CREDITS_BOOKED_SUBSCIPTION_HOLDERS_': 'ritten afgeboekt, behalve bij abonnementhouders.',
    '_CREDITS_BOOKED_': 'ritten afgeboekt',
    '_HOURS_BEFORE_START_': 'uur voor aanvang',
    '_SUBSCRIBE_WAITINGLIST_': 'Inschrijven op wachtlijst',
    '_UNSUBSCRIBE_WAITINGLIST_': 'Uitschrijven voor wachtlijst',
    '_VIEW_SUBSCRIPTIONS_': 'Bekijk inschrijvingen',
    '_SUBSCRIBE_ALLOWED_FROM_': 'Inschrijven kan vanaf',
    '_YOUR_CUSTOMERS_': 'Jouw klanten',
    '_CUSTOMER_MANAGEMENT_': 'Klantbeheer',
    '_AGENDA_MANAGEMENT_': 'Agendabeheer',
    '_CUSTOMERS_': 'Klanten',
    '_CUSTOMER_SINCE_': 'Klant sinds',
    '_BLOCK_': 'Blokkeren',
    '_ACCEPT_': 'Accepteren',
    '_INVITE_CUSTOMERS_VIA_LINK_': 'Uitnodigen via link',
		'_INVITE_CUSTOMERS_VIA_LINK_EXPLAINED_': 'Kopieer onderstaande link en stuur deze naar je klanten om ze rechtstreeks uit te nodigen bij jou lid te worden.',
    '_EXPORT_': 'Exporteer',
    '_STATS_': 'Stats',
    '_SORT_AND_FILTER_': 'Sorteer en filter',
    '_SORT_NAME_A_Z_': 'Naam: a-z',
    '_SORT_NAME_Z_A_': 'Naam: z-a',
    '_SORT_CUSTOMER_SINCE_NEWEST_': 'Klant sinds: nieuwste',
    '_SORT_CUSTOMER_SINCE_OLDEST_': 'Klant sinds: oudste',
    '_BIRTHDAYS_': 'jarig',
    '_BIRTHDAYS_TITLE_': 'Jarigen',
    '_STATS_CUSTOMER_SUMMARY_TEXT_': 'Je hebt [1] klanten waarvan [2] ([3]%) met een actief product',
    '_STATS_NO_PRODUCTS_TEXT_': '[1] klant(en) hebben geen product',
    '_STATS_NO_MORE_VALID_PRODUCTS_TEXT_': '[1] klant(en) hebben geen actief product meer',
    '_STATS_ALMOST_EXPIRED_PRODUCTS_TEXT_': '[1] klant(en) hebben bijna verlopen product(en)',
    '_SHOW_PRODUCTS_': 'Bekijk producten',
    '_PURCHASED_ON_': 'Gekocht op',
    '_EXPIRES_ON_': 'Verloopt op',
    '_PRODUCT_INFO_': 'Productinformatie',
    '_AGENDA_INFO_': 'Agenda informatie',
    '_STATS_NEVER_SUBSCRIBED_BEFORE_TEXT_': '[1] klant(en) ([2]%) hebben nooit ingescheven',
    '_STATS_LAST_SUBSCRIBED_ON_': 'Laatst ingeschreven op',
    '_STATS_CUSTOMER_SUMMARY_TEXT_': 'Je hebt [1] klanten waarvan [2] ([3]%) met een actief product',
    '_STATS_SUBSCRIBED_LONG_TIME_AGO_TEXT_': '[1] klant(en) ([2]%) hebben lang niet ingeschreven',
    '_TO_EXPORT_DATA_': 'Te exporteren gegevens',
    '_DATE_BIRTH_': 'Geboortedatum',
    '_ADDRESS_': 'Adres',
    '_PRODUCT_FILTERS_': 'Product Filters',
    '_COMPANY_INFO_': 'Bedrijfsinformatie',
    '_COMPANY_INFO_DESCRIPTION_': 'Vertel iets over je bedrijf',
    '_PHONE_': 'Telefoon',
    '_UNKNOWN_': 'Onbekend',
    '_LENGTH_': 'Lengte (cm)',
    '_BORN_AT_': 'Geboren op',
    '_APPOINTMENTS_': 'Afspraken',
    '_SHOW_APPOINTMENTS_': 'Bekijk afspraken',
    '_REGISTERED_FREE_': 'Gratis ingeschreven',
    '_REGISTERED_WITH_SUBSCRIPTION_': 'Ingeschreven met abonnement',
    '_POINT_OF_TIME_': 'Tijdstip',
    '_REGISTERED_WITH_': 'Ingeschreven met',
    '_CREDITS_': 'ritten',
    '_CREDIT_': 'rit',
    '_SHOW_': 'Bekijken',
    '_BACKGROUND_': 'Achtergrond',
    '_HELP_GOALS_': 'Hulpvraag/doelen',
    '_MEDICAL_BACKGROUND_': 'Mediche achtergrond',
    '_INJURIES_': 'Blessures',
    '_DOCTOR_TREATMENTS_': 'Doktersbehandelingen/operaties',
    '_DISEASES_CONDITIONS_': 'Ziektes/aandoeningen',
    '_MEDICATIONS_': 'Medicatie',
    '_AGREEMENTS_PROGRAM_': 'Afspraken (programma)',
    '_PROGRAM_': 'Programma',
    '_OTHER_INFO_': 'Overige informatie',
    '_NOT_VISIBLE_FOR_CUSTOMER_': 'Niet zichtbaar voor klant',
    '_GOALS_': 'Doelen',
    '_ACTIONS_': 'Acties',
    '_NEW_INTAKE_': 'Nieuwe intake',
    '_INTAKE_': 'Intake',
    '_NEW_REPORTING_': 'Nieuwe rapportage',
    '_REPORTING_': 'Rapportage',
    '_DATE_': 'Datum',
    '_ENTER_HERE_': 'Hier invullen',
    '_SUBJECT_': 'Onderwerp',
    '_INTAKE_REPORTING_': 'Intake + rapportage',
    '_TRAININGSCHEDULES_': 'Trainingschema\'s',
    '_TRAINING_DAY_': 'Trainingsdag',
    '_EXERCISES_': 'Oefeningen',
    '_EXERCISE_': 'Oefening',
    '_PERFORMED_': 'Uitgevoerd',
    '_WEIGHT_': 'Gewicht',
    '_NUMBER_SETS_': 'Aantal sets',
    '_TEMPO_': 'Tempo',
    '_NUMBER_REPEATS_': 'Aantal herhalingen',
    '_DURATION_': 'Duur',
    '_PAUZE_': 'Pauze',
    '_EXPLANATION_': 'Uitleg',
    '_START_TIMER_': 'Start de tijd',
    '_RESET_TIMER_': 'Reset de tijd',
    '_EXPLAIN_EXERCISE_TITLE_': 'Uitleg oefening',
    '_PERFORM_EXERCISES_ON_TIME_TXT_': 'Voer de oefeningen op volgorde uit!',
    '_GENERAL_': 'Algemeen',
    '_BREAKFAST_': 'Ontbijt',
    '_LUNCH_': 'Lunch',
    '_SNACK_': 'Tussendoor',
    '_SNACK_EVENING_': 'Snack',
    '_DINER_': 'Diner',
    '_CREATE_NEW_': 'Maak nieuwe aan',
    '_CREATE_PRODUCT_': 'Product maken',
		'_CREATE_CATEGORY_': 'Product categorie maken',
    '_NUTRITION_SCHEDULES_': 'Voedingsschema\'s',
    '_NUTRITION_DIARIES_': 'Voedingsdagboeken',
    '_YOUTUBE_URL_': 'Youtube URL',
    '_NUTRITION_SCHEDULE_': 'Voedingsschema',
    '_YOUTUBE_': 'Youtube',
    '_LOAD_NUTRITION_SCHEDULE_': 'Standaard schema inladen',
    '_DIARY_': 'Dagboek',
    '_ADVICE_': 'Advies',
    '_SCHEDULES_': 'Schema\'s',
    '_SHOW_DATA_': 'Bekijk gegevens',
    '_SHOW_SETTINGS_': 'Bekijk instellingen',
    '_VIDEO_': 'Video',
    '_KCAL_': 'kcal',
    '_PROTEIN_': 'eiwit',
    '_CARBS_': 'kolh',
    '_FAT_': 'vet',
    '_TOTAL_DAY_': 'Totaal van de dag',
    '_COMMENTS_': 'Opmerkingen',
    '_VIEW_COMMENTS_': 'Bekijk opmerkingen',
    '_NO_COMMENTS_': 'Geen opmerkingen',
    '_TOTAL_': 'Totaal',
    '_NUTRITION_DIARY_EXTRA_INFO_': 'Wat is er gegeten/gedronken?',
    '_COMMENTS_GENERAL_': 'Opmerkingen algemeen',
    '_CREATE_NEW_DIARY_': 'Dagboek invoeren',
    '_NUTRITION_DIARY_': 'Voedingsdagboek',
    '_PRODUCTS_': 'Producten',
    '_ENTER_COMMENTS_HERE_': 'Opmerkingen hier invullen',
    '_NUTRITION_EXPLAIN_UNITS_': 'Aantal (1= 100 gram of 1 stuks)',
    '_SEARCH_NUTRITION_PRODUCTS_': 'Voedingsmiddelen',
    '_THIS_PRODUCT_CONTAINS_': 'Dit product bevat per',
    '_MANAGE_PROGRESS_': 'Beheer voortgang',
    '_VIEW_PROGRESS_': 'Bekijk voortgang',
    '_VIEW_MEASUREMENT_CARD_': 'Meetkaart',
    '_VIEW_SKINFOLD_MEASUREMENT_': 'Huidplooimeting',
    '_VIEW_GRAPH_': 'Bekijk grafiek',
    '_MANAGE_PROGRESS_EXTRA_INFO_': 'Voortgang invoeren',
    '_VIEW_MEASUREMENT_CARD_EXTRA_INFO_': 'Omtrek in centimeters',
    '_VIEW_SKINFOLD_MEASUREMENT_EXTRA_INFO_': 'Gemeten millimeters',
    '_VIEW_GRAPH_EXTRA_INFO_': 'Voortgang in grafiek',
    '_PROGRESS_': 'Voortgang',
    '_INSERT_PROGRESS_': 'Voortgang invoeren',
    '_FAT_PERCENTAGE_': 'Vetpercentage',
    '_VISCERAL_FAT_LEVEL_': 'Visceraal vetniveau',
    '_FAT_FREE_MASS_': 'Vetvrije massa',
    '_MUSCLE_MASS_': 'Spiermassa',
    '_BONE_MASS_': 'Botmassa',
    '_BODY_FLUID_': 'Lichaamsvocht',
    '_BLOOD_GLUCOSE_VALUE_': 'Bloedglucosewaarde',
    '_MEASUREMENT_CARD_LEGEND_': 'Meetkaart (omtrek cm)',
    '_TOP_WIDTH_': 'Bovenwijdte',
    '_STOMACH_': 'Maag',
    '_WAIST_': 'Taille',
    '_BELLY_': 'Buik',
    '_HIPS_': 'Heupen',
    '_LEFT_THIGH_': 'Dij links',
    '_RIGHT_THIGH_': 'Dij rechts',
    '_LEFT_KNEE_': 'Knie links',
    '_RIGHT_KNEE_': 'Knie rechts',
    '_LEFT_CALF_': 'Kuit links',
    '_RIGHT_CALF_': 'Kuit rechts',
    '_LEFT_ARM_': 'Arm links',
    '_RIGHT_ARM_': 'Arm rechts',
    '_SKINFOLD_MEASUREMENT_LEGEND_': 'Huidplooimeting (millimeters)',
    '_CHIN_': 'Kin',
    '_CHEEK_': 'Wang',
    '_CHEST_': 'Borst',
    '_BICEPS_': 'Biceps',
    '_TRICEPS_': 'Triceps',
    '_SCACAPULA_': 'Schouderblad',
    '_SIDE_RIBS_': 'Zijkant ribben',
    '_HIP_BONE_': 'Heupbeen',
    '_NAVEL_': 'Navel',
    '_KNEE_': 'Knie',
    '_CALF_': 'Kuit',
    '_QUADRICEPS_': 'Quadriceps',
    '_HAMSTRING_': 'Hamstring',
    '_WEEK_': 'Week',
    '_TOTAL_': 'Totaal',
    '_FIRST_': 'Eerste',
    '_PREVIOUS_': 'Vorige',
    '_NEXT_': 'Volgende',
    '_LAST_': 'Laatste',
    '_DIFF_PREVIOUS_': 'Verschil vorige',
    '_DIFF_FIRST_': 'Verschil eerste',
    '_SIZES_': 'Maten',
    '_MILLIMETERS_': 'Millimeters',
    '_SKINFOLD_MILLIMETERS_TITLE_': 'Huidplooimeting (mm)',
    '_CALVES_': 'Kuiten',
    '_BMI_': 'BMI',
    '_MEASUREMENT_CARD_CM_TITLE_': 'Meetkaart omtrek cm',
    '_SHOW_LIST_': 'Bekijk lijst',
    '_PROGRESS_GRAPH_': 'Voortgangsgrafiek',
    '_WOD_MANAGEMENT_': 'WOD Beheer',
    '_NOT_PUBLISHED_': 'Niet gepubliceerd',
    '_LAST_PUBLISHED_': 'Gepubliceerd',
    '_SHARE_WOD_ADMIN_LABEL_': 'WOD delen buiten de app',
    '_SHARE_WOD_ADMIN_TEXT_': 'Via onderstaande link kun je de WOD delen buiten de app, er moet wel minimaal 1 publicatie zijn!',
    '_PUBLISH_': 'Publiceren',
    '_DATE_WOD_DESC_': 'Gepubliceerd: nieuwste',
    '_DATE_WOD_ASC_': 'Gepubliceerd: oudste',
    '_VIEW_ONLY_PUBLISHED_WODS_': 'Je kunt deze WOD niet aanpassen omdat er al publicaties op het whiteboard staan!',
    '_WOD_SCORE_AS_': 'Scoren als',
    '_WOD_RX_LABEL_': 'RX',
    '_WOD_RANK_CATEGORIES_': 'Ranking categorieën',
    '_OTHER_OPTIONS_': 'Overige opties',
    '_WOD_BENCHMARK_LABEL_': 'Benchmark',
    '_WOD_OVERALL_RANKING_LABEL_': 'Overall ranking',
    '_WOD_FUNDAMENTAL_LABEL_': 'Fundamental',
    '_DISABLE_SHARING_': 'Delen uitschakelen (social media)',
    '_SAVE_AND_PUBLISH_': 'Opslaan en publiceren',
    '_PUBLISH_WOD_': 'WOD Publiceren',
    '_BY_DATE_': 'Op datum',
    '_WOD_': 'WOD',
    '_ONLY_PUBLISH_WOD_': 'Je kunt de WOD alleen publiceren, niet bewerken! Kies je publicatiedatum, optioneel een YouTube URL en klik op de publiceren knop.',
    '_WHITEBOARD_PUBLICATIONS_HISTORY_': 'Whiteboard publicaties',
    '_MANAGE_WHITEBOARD_PUBLICATION_': 'Beheer whiteboard publicatie',
    '_RANKING_': 'Ranking',
    '_MY_WOD_': 'Mijn WOD',
    '_SHARE_': 'Delen',
    '_YOUR_COMMENT_': 'Jouw opmerkingen',
    '_RESULTS_': 'resultaten',
    '_VIEW_HISTORY_': 'Bekijk geschiedenis',
    '_VIEW_WOD_': 'Bekijk WOD',
    '_VIEW_BENCHMARK_': 'Bekijk Benchmark',
    '_YOUR_PERSONAL_RECORD_': 'Jouw persoonlijk record',
    '_LOG_YOUR_RESULT_': 'Log je resultaat',
    '_DELETE_YOUR_RESULT_': 'Verwijder resultaat',
    '_LOG_ATHLETE_RESULT_': 'Resultaat van je atleet',
    '_RANKING_CATEGORY_': 'Categorie',
    '_TIME_': 'Tijd',
    '_RX_OR_SCALED_': 'RX of Scaled',
    '_DELETE_BUTTON_DAY_': 'Verwijder dag',
    '_DELETE_BUTTON_ALL_': 'Verwijder alles',
    '_REMOVE_ATHLETE_RESULT_TXT_': 'Hieronder kunt je het resultaat verwijderen van deze altleet!<br />- Alles=alles inclusief overall ranking<br />- Dag=alleen van deze WOD',
    '_DAY_RANKING_': 'Dag Ranking',
    '_SCORE_': 'Score',
    '_OVERALL_RANKING_': 'Overall Ranking',
    '_MEN_ONLY_': 'Alleen mannen',
    '_WOMAN_ONLY_': 'Alleen vrouwen',
    '_MEN_AND_WOMAN_': 'Mannen en vrouwen',
    '_OVERALL_': 'Overall',
    '_SHOW_RANKING_CATEGORIES_': 'Toon Ranking categorie&euml;n',
    '_SEARCH_WOD_': 'WOD zoeken',
    '_PRODUCT_MANAGEMENT_': 'Productbeheer',
    '_PRODUCT_CATEGORIES_': 'Productcategorie&euml;n',
    '_MANAGE_PRODUCT_CATEGORIES_': 'Beheer productcategorie&euml;n',
    '_PRODUCT_CUSTOMER_FORM_': 'Productformulier',
    '_CONNECT_PRODUCT_TO_CUSTOMERS_': 'Koppel producten aan klanten',
    '_PRODUCT_SEPA_FORM_': 'SEPA batch maken',
    '_CREATE_SEPA_FILE_FROM_PRODUCTS_': 'Maak batch van producten',
    '_MANAGE_PRODUCTS_': 'Producten beheren',
    '_PRODUCT_CATEGORY_': 'Productcategorie',
    '_CARD_WITH_CREDITS_': 'Rittenkaart',
    '_OTHER_PRODUCT_': 'Los product',
    '_LEFT_EMPTY_': 'Niet ingevuld',
    '_PRODUCT_TYPE_': 'Soort product',
    '_PERIOD_VALID_': 'Geldigheidsduur',
    '_CONTINUOUSLY_': 'Doorlopend',
    '_MONTHS_': 'Maanden',
    '_MONTHS_LOWER_': 'maanden',
    '_MONTH_LOWER_': 'maand',
    '_VALID_UNLIMITED_': 'Onbeperkt',
    '_NUMBER_MONTHS_VALID_': 'Aantal maanden geldig',
    '_ATHORIZATION_': 'Machtiging',
    '_ATHORIZATION_MULTIPLE_PERIODS_': 'Automatische incasso',
    '_NUMBER_INSTALLMENTS_': 'Aantal termijnen',
    '_ORDER_': 'Volgorde',
    '_NOT_SET_': 'Niet ingesteld',
    '_VAT_PERCENTAGE_': 'BTW percentage',
    '_MONTHLY_AMOUNT_': 'Maandelijks bedrag',
    '_VAT_PERCENTAGE_': 'BTW percentage',
    '_AMOUNT_FIRST_PERIOD_': 'Bedrag eerste termijn incl. BTW',
    '_AMOUNT_OTHER_PERIODS_': 'Bedrag overige termijnen incl. BTW',
    '_AMOUNT_PERIODS_IN_VAT_': 'Termijnbedrag incl. BTW',
    '_AMOUNT_': 'Bedrag',
    '_NUMBER_CREDITS_': 'Aantal ritten',
    '_NOT_VISIBLE_IN_SHOP_': 'Niet zichtbaar in shop',
    '_ENABLED_': 'Aan',
    '_DISABLED_': 'Uit',
    '_DISABLE_IN_SHOP_': 'Uit omloop halen',
    '_DISABLED_IN_SHOP_': 'Uit omloop gehaald',
    '_SHARE_AND_SELL_PRODUCT_': 'Delen en verkopen buiten de app',
    '_SHARE_AND_SELL_PRODUCT_TEXT_': 'Via onderstaande link kun je jouw product buiten de app aanbieden! Na de aankoop wordt de klant automatisch gekoppeld aan jouw bedrijf en is hij terug te vinden in je klantenlijst!',
    '_SHARE_TRIAL_PRODUCT_TEXT_': 'Met deze link kunnen zowel bestaande als nieuwe klanten een afspraak plannen en ontvangen ze hiervoor een kennismakingsproduct.',
    '_PRODUCT_': 'Product',
    '_ORDER_EXPLAIN_NUMBER_': 'Vul getal in (laagste komt eerst)',
    '_NO_CREDITS_OTHER_PRODUCT_': 'Geen rittenkaart, los product',
    '_NUMBER_MONTHS_VALID_VALID_': 'Aantal maanden geldig',
    '_PAYMENT_METHOD_': 'Betaalmethode',
    '_AMOUNT_IN_VAT_': 'Bedrag incl. BTW',
    '_PRIVACY_SETTINGS_': 'Privacy instellingen',
    '_PAYMENT_ONCE_LINK_': 'Eenmalige betaling (klik om te wijzigen)',
    '_PAYMENT_SUBSCRIPTION_LINK_': 'Abonnement (klik om te wijzigen)',
    '_NOT_APPLICABLE_': 'n.v.t',
    '_NUMBER_SUBSCRIPTIONS_PER_WEEK_': 'Max afspraken per week',
    '_NUMBER_SUBSCRIPTIONS_PER_MONTH_': 'Max afspraken per maand',
    '_ONGOING_': 'Doorlopend',
    '_ATHORIZATION_SEPA_': 'SEPA Incasso',
    '_MANAGE_IMAGE_': 'Open fotobeheer',
    '_IMG_UPLOAD_INFO_': 'Na het opslaan van dit product kun je een foto uploaden via product wijzigen.',
    '_NO_IMG_YET_': 'Er is nog geen foto toegevoegd!',
    '_UPLOAD_IMAGE_': 'Foto uploaden',
    '_FILE_NAME_': 'Bestandsnaam',
    '_FILE_SIZE_': 'Bestandsgrootte',
    '_FILE_TYPE_': 'Bestandstype',
    '_CHOOSE_FILE_': 'Kies je bestand',
    '_BROWSE_IMAGES_': 'Bladeren door foto\'s',
    '_DELETE_IMAGE_': 'Verwijder foto',
		'_UPLOAD_NEW_IMAGE_': 'Upload nieuwe foto',
		'_CURRENT_IMAGE_': 'Huidige foto',
    '_UPLOAD_': 'Uploaden',
    '_DELETE_FILE_': 'Verwijder bestand',
    '_UPLOADED_': 'geupload',
    '_NO_FILE_SELECTED_': 'Er is geen bestand gekozen',
    '_CONNECT_PRODUCT_TO_CUSTOMER_EXPLAIN_': "Let op! Instellingen die je hier doet zijn voor de koppeling met de agenda en hebben geen invloed op betalingen.",
    '_FIND_CUSTOMER_': 'Klant zoeken',
    '_ACTIVE_': 'Actief',
    '_INACTIVE_': 'Niet actief',
    '_CONNECTED_PRODUCT_': 'Zelf gekoppeld',
    '_ACTIVATE_': 'Activeren',
    '_DEACTIVATE_': 'Deactiveren',
    '_CONNECTED_PRODUCTS_': 'Reeds gekoppelde producten',
    '_CONNECT_NEW_PRODUCT_': 'Nieuw product koppelen',
    '_CONNECT_FREE_PRODUCT_': 'Product gratis koppelen',
    '_SELECT_PRODUCT_': 'Kies een product',
    '_CONNECT_PRODUCT_': 'Koppel product',
    '_CURRENT_PRODUCT_': 'Huidig product',
    '_AVAILABLE_': 'Beschikbaar',
    '_VALID_UNTIL_': 'Geldig tot',
    '_VALID_TM_': 'Geldig t/m',
    '_CHANGE_CONNECTED_PRODUCT_': 'Gekoppeld product wijzigen',
    '_SHOP_': 'Shop',
    '_PURCHASED_': 'Gekocht',
    '_PMTH_': 'p/mnd',
    '_ITEMS_': 'items',
    '_AMOUNT_FIRST_': 'Bedrag eerste termijn',
    '_AMOUNT_OTHERS_': 'Bedrag overige termijnen',
    '_ORDER_PRODUCT_': 'Bestellen',
    '_IN_SHOPPING_CART_': 'In winkelwagen',
    '_NUMBER_ITEMS_': 'Aantal',
    '_PRODUCTS_IN_SHOPPING_CART_': 'Producten in winkelwagen',
    '_PAY_SHOPPINGCART_': 'Winkelwagen afrekenen',
    '_UPDATE_CART_': 'Bijwerken',
    '_PAY_': 'Afrekenen',
    '_PAY_ALL_': 'Alles afrekenen',
    '_CREDITS_LEFT_': 'ritten over',
    '_STATUS_': 'Status',
    '_WHAT_IS_TOUR_GOAL_': 'Wat is je doel?',
    '_SEND_': 'Versturen',
    '_FORM_TRAINING_DISTANCE_': 'Leefstijladvies',
    '_DESCRIPTION_TRAINING_DISTANCE_': 'Via dit formulier kun je bij deze trainer een voedings en/of trainingsadvies aanvragen.',
    '_FORM_FREE_ADVICE_': 'Gratis adviesgesprek',
    '_DESCRIPTION_FREE_ADVICE_': 'Via dit formulier vraag je een gratis adviesgesprek aan. Tijdens het adviesgesprek wordt er gekeken naar wat er nodig is om je conditie te verbeteren, jou gezonder te laten leven en indien nodig blijvend af te laten vallen.',
    '_MESSAGE_': 'Bericht',
    '_CONTACT_': 'Contact',
    '_MY_MANAGEMENT_': 'Mijn beheer',
    '_MY_MANAGEMENT_DESCRIPTION_': 'Voor het bedrijf',
    '_SEND_PUSHMESSAGE_': 'Bericht sturen',
    '_ALL_MY_CUSTOMERS_': 'Al mijn klanten',
    '_SEND_MESSAGE_TO_': 'Verstuur naar',
		'_SEND_MESSAGE_AS_': 'Verstuur als',
    '_SELECTION_CUSTOMERS_': 'Selectie klanten',
    '_FORGOT_PASS_': 'Wachtwoord vergeten',
    '_CREATE_ACCOUNT_': 'Account maken',
    '_DESCRIPTION_COMPANY_': 'Omschrijf je bedrijf',
    '_STREET_': 'Straat',
    '_HOUSENUMBER_': 'Huisnummer',
    '_HOUSENUMBER_EXTRA_': 'Huisnummer toevoeging',
    '_RESIDENCE_': 'Woonplaats',
    '_MY_PASSWORD_': 'Wachtwoord wijzigen',
    '_CURRENT_PASSWORD_': 'Huidig wachtwoord',
    '_NEW_PASSWORD_': 'Nieuw wachtwoord',
    '_REPEAT_PASSWORD_': 'Herhaal wachtwoord',
    '_COMPANY_SETTINGS_': 'Bedrijfsinstellingen',
    '_PERSONAL_SETTINGS_': 'Persoonlijke instellingen',
    '_FAQ_': 'FAQ',
    '_EMAIL_AGENDA_EVENTS_': 'E-mail agenda gebeurtenissen',
    '_URL_COMPANY_TERMS_': 'URL algemene voorwaarden',
    '_ACCEPT_USERS_FIRST_': 'Klant eerst accepteren',
    '_ACCEPT_WAITING_CUSTOMER_': 'Klant wacht op acceptatie.',
    '_SEND_NEW_USER_EMAIL_': 'Stuur mail bij aanmelding nieuwe klant',
    '_UPLOAD_PROFILE_IMAGE_': 'Profielfoto uploaden',
    '_UPLOAD_PROFILE_IMAGE_DESCRIPTION_': 'Je kunt het beste een vierkante foto uploaden (jpeg, niet groter dan 200 KB)',
    '_WHOOPS_': 'Oeps',
    '_SOMETHING_WENT_WRONG_TEXT_': 'Er is helaas iets misgegaan, probeer het later nog een keer!',
    '_GO_TO_HOME_PAGE_': 'Naar het beginscherm',
    '_COMPANY_STYLES_': 'Eigen huisstijl',
    '_COMPANY_ACCESS_SETTINGS_': 'Toegang gebruikers',
    '_COMPANY_PICTURES_': 'Bedrijfslogos',
    '_HEADER_LOGO_': 'Header logo',
    '_LOGO_RATIO_': 'Ongeveer deze verhouding, mag ook groter',
    '_HEADER_LOGO_SUB_TITLE_': 'Wordt weergegeven in te bovenbalk op je persoonlijke login/nieuw account pagina en de startpagina van je bedrijf in de app. Een laag en langwerpig horizontaal logo wordt het mooiste weergegeven.',
    '_COMPANY_LOGO_': 'Logo bovenaan bedrijfspagina',
    '_COMPANY_LOGO_SUB_TITLE_': 'Wordt weergegeven aan de bovenkant van je bedrijfspagina onder de bovenbalk en evt. op facturen, een horizontale foto van max 140px hoog wordt het mooiste weergegeven.',
    '_BG_LOGO_': 'Achtergrondfoto',
    '_BG_LOGO_SUB_TITLE_': 'Wordt weergegeven als achtergrondfoto op je persoonlijke login/nieuw account pagina. Kies een mooie grote foto van JPEG formaat.',
    '_LOGO_RATIO_CHOOSE_YOURSELF_': 'Kies zelf een afbeeldingsverhouding die mooi past (deze wordt gecentreerd over de hele paginabreedte)',
    '_IMAGES_': 'Plaatjes',
    '_STYLES_': 'Kleuren',
    '_COMPONENTS_': 'Onderdelen',
    '_COLOR_HEADER_BORDER_': 'Kleur streep bovenkant',
    '_COLOR_OUTLINE_BUTTONS_': 'Kleur omlijning knoppen app',
    '_COLOR_OUTLINE_TEXT_BUTTONS_': 'Tekstkleur in omlijnde knoppen app',
    '_COLOR_SP_BG_BUTTONS_BG_': 'Achtergrondkleur knoppen login/nieuw account pagina',
    '_COLOR_SP_BUTTONS_TEXT_': 'Tekstkleur in knoppen login/nieuw account pagina',
    '_COLOR_SP_HEADERS_': 'Tekstkleur koptitels login/nieuw account pagina',
    '_FAQ_Q_INTEGRATE_APP_': 'Kan ik de app ook in mijn website integreren?',
    '_FAQ_A_INTEGRATE_APP_': 'Ja dat is mogelijk, hier zijn verschillende opties voor.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_': 'Kopieer 1 van onderstaande code voorbeelden in de code van je website, als je dit niet zelf lukt vraag het aan je webbouwer of neem contact met ons op.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_1_': 'De agenda op je website:',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_2_': 'De agenda op je website beginnend in de huidige week in lijstweergave:',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_2_EXTRA_': 'Gebruik mode=week voor week, mode=day voor dag en mode=listView voor de lijstweergave',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_3_': 'Formaten van de app aanpassen:',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_3_EXTRA_': 'Gebruik width=500 voor de breedte, height=600 voor de hoogte (pas deze waardes naar wens aan) en fullscreen=true voor volledig scherm.<br> Indien de breedte wordt weggelaten pakt hij automatische de breedte van het omvattende element',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_4_': 'Ik heb meerdere agenda\'s en wil er 1 direct tonen.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_5_': 'Ik wil de app als geheel implementeren.',
    '_FAQ_A_INTEGRATE_APP_PASTE_CODE_EXAMPLE_5_EXTRA_': 'Gebruik hiervoor onderstaand voorbeeld.',
    '_FAQ_MEMBER_FORGOT_PASS_': 'Mijn klant is de inloggegevens kwijt, hoe kan ik helpen?',
    '_FAQ_A_MEMBER_FORGOT_PASS_': 'Je klant kan via de gegevens kwijt functie een nieuw wachtwoord aanvragen. Let op, het wachtwoord moet via de link in de e-mail bevestigd worden voor ermee ingelogd kan worden.',
    '_FAQ_MEMBER_CHILDREN_AGENDA_': 'Kan mijn klant ook voor de kinderen een rittenkaart aanschaffen?',
    '_FAQ_A_MEMBER_CHILDREN_AGENDA_': 'Een rittenkaart is persoonsgebonden, de ouder moet voor ieder kind een apart account maken, inloggen met het account van het kind en vervolgens de rittenkaart kopen.',
    '_FAQ_MEMBER_SUBSCRIBE_AGENDA_': 'Mijn klant kan niet aanmelden op afspraken terwijl hij wel betaald heeft, wat moet ik doen?',
    '_FAQ_A_MEMBER_SUBSCRIBE_AGENDA_': 'Controleer bij de afspraak waar de klant probeert aan te melden of alle producten wel staan aangevinkt. Controleer of de rittenkaart en/of abonnement van je klant niet is verlopen.',
    '_FAQ_NOT_WORKING_': 'Mijn vraag staat hier niet tussen en ik wil graag hulp kan dat?',
    '_FAQ_A_NOT_WORKING_': 'Jazeker, je kunt contact met ons opnemen via:',
    '_SLOGAN_APP_': 'planning, betalingen en klantbeheer voor je bedrijf',
    '_SLOGAN_APP_TOOLBAR_': 'Ledenadministratie, betalingen en reserveringssysteem voor jouw bedrijf!',
    '_LOGIN_NEW_ACCOUNT_': 'Login/nieuw account',
    '_TO_SHOP_': 'Naar de webshop',
    '_REMOVE_COMPANY_': 'Bedrijf verwijderen',
    '_REMOVE_AS_START_PAGE_': 'Verwijder als startpagina',
    '_ADD_AS_START_PAGE_': 'Startpagina toevoegen',
    '_ACCOUNT_SUCCESS_LOGIN_VIA_APP_': 'Je account is aangemaakt, je kunt met dit account inloggen in de app',
    '_REGISTER_VIA_WEBSITE_': 'Registreren via de website',
    '_NON_COMMERCIAL_USE_': 'Deze gegevens zijn minimaal benodigd voor een werkend account en zullen niet aan derden verstrekt worden. Je e-mail is benodigd voor het inloggen en als je jouw wachtwoord vergeten bent.',
    '_OTHER_': 'Overige',
    '_AGENDA_APPOINTMENTS_VIEW_': 'Agenda status weergave',
    '_APPOINTMENTS_FULL_COLOR_': 'Volledig gekleurd',
    '_APPOINTMENTS_LINE_COLOR_': 'Lijn onderkant',
    '_UPDATING_APP_': 'Updates uitvoeren',
    '_FAMILY_MEMBERS_': 'Familieleden',
    '_UNSUBSCRIBE_OUTSIDE_TIME_': 'Er wordt geen krediet teruggeboekt',
    '_SUBSCRIBE_NOT_POSSIBLE_': 'Inschrijven is niet mogelijk',
    '_NONE_': 'Geen',
    '_NUMBER_FAMILY_MEMBERS_ALLOWED_': 'Aantal familieleden toestaan',
    '_ADD_FAMILY_MEMBERS_': 'Voeg familieleden toe',
    '_NO_SHOW_REGISTRATION_': 'No show inschrijving, geen krediet teruggeboekt bij uitschrijving',
    '_NO_SHOW_REGISTRATION_CUSTOMER_MESSAGE_': 'Geannuleerd na de uitschrijfperiode, geen krediet teruggeboekt bij uitschrijving',
    '_NO_SHOWS_APPOINTMENT_': 'No shows op afspraak',
    '_NO_SHOWS_': 'no shows',
    '_QUESTION_REMOVE_NO_SHOW_': 'Het krediet zal worden teruggeboekt',
    '_QUESTION_COLOR_OK_': 'Is deze kleur naar wens?',
    '_CHOOSE_AN_OPTION_': 'Kies een optie',
		'_NOTICE_ADMIN_UNSUBSCRIBE_APPOINTMENT_': 'Wil je het krediet terugboeken?',
    '_UNSUBSCRIBE': 'Uitschrijven',
    '_QUESTION_UNSUBSCRIBE_NO_CREDITS_RETURNED_': 'Je schrijf uit buiten de tijd, er wordt geen krediet teruggeboekt',
    '_UNSUBSCRIBE_NO_LONGER_POSSIBLE_': 'Uitschrijven is niet meer mogelijk',
    '_CAN_LOG_TO_': 'Loggen kan tot',
    '_TO_DATE_': 'Tot datum',
    '_SINGLE_WOD_': 'Enkele wod',
    '_COMPETITION_WOD_': 'Competitie',
    '_COMPETITION_': 'Competitie',
    '_CLICK_MANAGE_COMPETITIONS_': 'Klik hier om de competities te beheren',
    '_MANAGE_COMPETITIONS_': 'Beheer competities',
    '_CLICK_MANAGE_RANKINGS_': 'Klik hier om de rankings te beheren',
    '_MANAGE_RANKINGS_': 'Beheer rankings',
    '_LOGGING_POSSIBLE_WITH_': 'Loggen kan met',
    '_WOD_TYPE_': 'Soort WOD',
    '_MANAGE_COMPETITION_': 'Beheer competitie',
    '_EDIT_NOT_USED_RANKING_ONLY_': 'Je kan alleen de ongebruikte rankings bewerken',
    '_ADD_RANKING_CATEGORY_': 'Nieuwe ranking categorie',
    '_DATE_END_': 'Einddatum',
    '_NEW_COMPETITION_': 'Nieuwe competitie',
    '_FINISHED_': 'Afgelopen',
    '_UNLIMITED_': 'n.v.t.',
    '_EDIT_PART_USED_COMPETITIONS_ONLY_': 'Voor een gekoppelde competitie kan je alleen de einddatum verlengen en producten toevoegen',
    '_ATHLETE_LOG_NOT_ALLOWED_': 'Loggen door de atleet niet toegestaan',
    '_THERE_IS_NO_VALID_PRODUCT_': 'Schaf een product aan om deel te nemen',
    '_ATHLETE_YOUTUBE_URL_REQUIRED_': 'Loggen met youtube url door atleet verplicht',
    '_END_RANKING_': 'Eind ranking',
    '_POSITION_PER_WOD_': 'Positie per wod',
    '_RANKING_DETAILS_': 'Ranking details',
    '_THESE_ATHLETES_HAVE_SAME_NUMBER_': 'Deze atleten hebben evenveel punten',
    '_AFTER_CHANGING_COMPETITION_POSITION_UNABLE_TO_CHANGE_WOD_SCORE_': 'Na het bewerken van de eindranking is het niet meer mogelijk om wod scores aan te passen',
    '_NO_COMPETITIONS_': 'Geen competities',
    '_RESET_ENDRANKING_': 'Reset eindranking',
    '_PAYING_CUSTOMERS_PER_PRODUCT_': 'Betalende klanten per product',
    '_NON_PAYING_CUSTOMERS_PER_PRODUCT_': 'Niet betalende klanten met product',
    '_EXPORT_LIST_': 'Exporteer deze lijst',
    '_ENTER_NUMBER_HOURS_': 'aantal',
    '_HOURS_': 'uur',
    '_EMPTY_': 'Leeg',
    '_MANAGE_SALES_': 'Beheer verkopen',
    '_TRANSACTIONS_': 'Transacties',
    '_CREDITS_AND_DEBITS_': 'Bij- en afschrijvingen',
    '_ACCOUNTANCY_': 'Accountancy',
    '_FAILED_PAYMENTS_': 'Mislukte betalingen',
    '_DAY_OVERVIEW_': 'Dagoverzicht',
    '_DAY_INCOME_': 'Bekijk dagopbrengsten',
    '_PAYMENTS_': 'Betalingen',
    '_ADD_SEPA_DIRECT_DEBIT_': 'Abonnement toevoegen',
    '_VIEW_EDIT_': 'Bekijken/aanpassen',
    '_SUBSCRIPTIONS_DIRECT_DEBITS_': 'Abonnementen',
    '_SALES_': 'Verkopen',
    '_SHOW_OVERVIEWS_': 'Bekijk overzichten',
    '_VAT_': 'BTW',
    '_MANAGE_REFUNDS_': 'Terugbetalingen beheren',
    '_REFUND_SALES_': 'Aankopen terugbetalen',
    '_PRODUCTS_TO_REFUND_': 'Geselecteerd om terug te betalen',
    '_TOTAL_AMOUNT_REFUNDS_': 'Totaal terugbetaald',
    '_REFUNDS_HISTORY_': 'Geschiedenis terugbetalingen',
    '_SORT_NAME_CUSTOMER_A_Z_': 'Naam klant: a-z',
    '_SORT_NAME_CUSTOMER_Z_A_': 'Naam klant: z-a',
    '_DATE_ASC_': 'Datum: oudste',
    '_DATE_DESC_': 'Datum: nieuwste',
    '_FINANCIAL_': 'Financieel',
    '_REVENUE_': 'Omzet',
    '_DATE_TO_': 'tot',
    '_REVENUE_OVER_SELECTED_PERIOD_': 'totaal incl. BTW',
    '_SOLD_OVER_SELECTED_PERIOD_': 'verkocht inclusief BTW',
    '_SUBSCRIPTIONS_SEPA_': 'Abonnementen',
    '_VAT_': 'BTW',
    '_INCLUDING_': 'inclusief',
    '_INCLUDING_SHORT_': 'incl.',
    '_DOWNLOAD_INVOICES_': 'Download facturen',
    '_DOWNLOAD_INVOICE_': 'Download factuur',
    '_DIRECT_DEBITS_OVER_PERIOD_': 'over gekozen periode',
    '_DIRECT_DEBITS_TOTAL_': 'Bedrag in omloop',
    '_NOT_ACTIVE_': 'niet actief',
    '_ACTIVE_FIRST_': 'Actief: eerst',
    '_ACTIVE_LAST_': 'Actief: laatst',
    '_MANAGE_DIRECT_DEBIT_': 'Abonnement beheren',
    '_COMPANY_NAME_': 'Bedrijfsnaam',
    '_VAT_NUMBER_': 'BTW nummer',
    '_CITY_': 'Stad',
    '_IBAN_': 'IBAN',
    '_BIC_': 'BIC',
    '_CONNECT_TO_CUSTOMER_': 'Koppelen aan klant',
    '_DATA_CREDITOR_': 'Gegevens klant',
    '_FIRST_PROCESS_DATE_DIRECT_DEBIT_': 'Eerstvolgende termijndatum',
    '_UPCOMING_': 'Eerstvolgende',
    '_VAT_SHIFTED_ABROAD_': 'BTW verlegd buitenland',
    '_SEARCH_CUSTOMER_': 'Klant zoeken',
    '_CHOSE_AN_OPTION_': 'Maak een keuze',
    '_NOT_REQUIRED_': 'Niet verplicht',
    '_ACTIVATE_AFTER_PAYMENT_': 'Na betaling',
    '_ACTIVATE_NOW_': 'Direct',
    '_ACTIVATE_WHEN_': 'Activeren',
    '_DIRECT_DEBIT_PERMISSION_': 'Ik ben gemachtigd voor deze incasso',
    '_DECLARATION_OF_CONSENT_': 'Toestemmingsverklaring',
    '_PRODUCT_DATA_': 'Productinformatie',
    '_CUSTOMER_DATA_': 'Klantgegevens',
    '_PAYMENT_DATA_': 'Betalingsgegevens',
    '_UPCOMING_INSTALLMENT_': 'Eerstvolgende termijn',
    '_PAYBACKS_': 'Terugbetalingen',
    '_INSTALLMENTS_': 'Termijnen',
    '_INSTALLMENT_': 'Termijn',
    '_OTHER_ACTIONS_': 'Overige acties',
    '_BACK_TO_PREVIOUS_ACTIONS_': 'Terug naar overige acties',
    '_SUBSCRIPTIONS_NOT_ACTIVE_': 'Dit abonnement is niet actief',
    '_SUBSCRIPTIONS_IS_PAUSED_': 'Dit abonnement is gepauzeerd',
    '_SUBSCRIPTION_WIL_BE_RESUMED_': 'wordt het abonnement hervat',
    '_CHANGE_RESUME_DATE_': 'wordt het abonnement hervat',
    '_DEACTIVATE_BY_': 'Deactiveren per',
    '_SELECTED_PERIOD_WIL_NOT_BE_PROCESSED_': 'Gekozen termijn wordt niet meer geincasseerd',
    '_IMMEDIATELY_': 'Per direct',
    '_REACTIVATE_BY_': 'Heractiveren per',
    '_SELECTED_PERIOD_WIL_BE_PROCESSED_': 'Abonnement gaat lopen vanaf gekozen datum',
    '_NOT_DEACTIVATE_': 'Niet deactiveren',
    '_NOT_REACTIVATE_': 'Niet heractiveren',
    '_PLAN_DEACTIVATION_': 'Stoppen/pauzeren',
    '_PLAN_ACTIVATION_': 'Activatie plannen',
    '_CHANGE_SUBSCRIPTION_': 'Abonnement wijzigen',
    '_BY_': 'Per',
    '_SUBSCRIPTIONS_IS_FINISHED_': 'Dit abonnement is voltooid',
    '_NEVER_REACTIVATE_': 'Nooit heractiveren',
    '_DATE_AT_': 'Datum naar keuze',
    '_ENTER_DATE_': 'Kies datum',
    '_SAVE_SETTINGS_': 'Instellingen opslaan',
    '_REMOVE_DEACTIVATION_': 'Verwijder deactivatie',
    '_INFO_': 'Info',
    '_INVOICES_': 'Facturen',
    '_MANAGE_DIRECT_DEBIT_INSTALLMENTS_': 'Termijnen',
    '_MADE_ON_': 'Aangemaakt op',
    '_REFUND_PERIOD_': 'Termijn terugbetalen',
    '_REASON_': 'Reden',
    '_PERIOD_': 'Termijn',
    '_INVOICE_': 'Factuur',
    '_FAILED_': 'Mislukt',
    '_OUTSTANDING_BILLS_': 'Openstaande betalingen',
    '_REFUNDS_': 'Terugbetalingen',
    '_REMINDER_': 'Reminder',
    '_IS_SEND_': 'is verstuurd',
    '_SHOW_MAIL_': 'Mail bekijken',
    '_CREDIT_INVOICE_': 'Factuur crediteren',
    '_FAILED_DIRECT_DEBITS_': 'Mislukte betalingen',
    '_FAILED_REFUNDS_': 'Mislukte terugbetalingen',
    '_SALE_': 'Verkoop',
    '_INVOICE_NUMBER_': 'Factuurnummer',
    '_SUBSCRIPTION_DEACTIVATED_BY_SYSTEM_': 'Deze verkoop is gedeactiveerd voor toekomstige incasso\'s, waarschijnlijk heeft je klant een blokkade bij de bank gedaan.',
    '_NO_FAILED_DIRECT_DEBITS_': 'Er zijn geen mislukte betalingen :)',
    '_NO_FAILED_REFUNDS_': 'Er zijn geen mislukte terugbetalingen :)',
    '_PAY_BEHIND_LINK_DESCRIPTION_': 'Betalen kan via onderstaande link',
    '_FAILED_REFUNDS_DESCRIPTION_': 'Mislukte terugbetalingen wegens te weinig saldo. Verwijder deze en doe zelf de overboeking naar je klant. De creditfactuur is reeds gemaakt.',
    '_NO_FAILED_DIRECT_DEBITS_DESCRIPTION_': 'Bij crediteren wordt het bedrag kwijtgescholden en verdwijnt de factuur uit het overzicht.',
    '_COSTS_': 'Kosten',
    '_INVOICE_LANGUAGE_': 'Taal factuur',
    '_PIN_': 'Pinnen',
    '_HANDLE_PIN_PAYMENT_': 'Voer een pinbetaling uit',
    '_PIN_NOT_ENABLED_TEXT_': 'De PIN module is niet ingeschakeld!<br><br>Wil jij een pinapparaat? Neem contact met ons op, we helpen je graag.',
    '_DATA_CUSTOMER_': 'Gegevens klant',
    '_SEARCH_A_PRODUCT_': 'Zoek een product',
    '_PRODUCT_NAME_': 'Productnaam',
    '_LINK_TO_CUSTOMER_CHOOSE_AN_OPTION_': 'Koppelen aan klant? Maak een keuze',
    '_PAYMENT_NOT_LINK_TO_CUSTOMER_': 'Losse betaling, niet koppelen aan klant',
    '_PAYMENT_LINK_TO_CUSTOMER_': 'Betaling koppelen aan klant',
    '_SELECTED_PRODUCTS_': 'Gekozen producten',
    '_SEND_TO_PIN_DEVICE': 'Naar PIN versturen',
    '_WAITING_FOR_TRANSACTION_': 'Wachten op transactie',
    '_TRANSACTIONS_SUCCESSFUL_': 'De transactie is geslaagd',
    '_TRANSACTIONS_EXPIRED_': 'De transactie is verlopen, probeer het opnieuw',
    '_TRANSACTIONS_CANCELLED_': 'De transactie is geannuleerd',
    '_TRANSACTIONS_ERROR_': 'De transactie is mislukt, probeer het later opnieuw',
    '_BANK_BALANCE_': 'Saldo',
    '_RECEIVABLE_BALANCE_': 'Opneembaar saldo',
    '_WITHDRAWAL_': 'Opnemen',
    '_DOWNLOAD_TRANSACTIONS_': 'Download transacties',
    '_REFERENCE_': 'Referentie',
    '_TRANSACTION_COSTS_': 'Transactie kosten',
    '_EXPORT_SELECTED_PERIOD_': 'Exporteer geselecteerde periode',
    '_MANAGE_TRANSACTIONS_': 'Transacties',
    '_MANAGE_WITHDRAWAL_': 'Uitbetalen',
    '_NEXT_WITHDRAWAL_': 'Volgende uitbetaling',
    '_RESERVE_FOR_REFUNDS_': 'Bedrag achterhouden (wordt niet uitgekeerd)',
    '_RESERVATIONS_': 'Transactiekosten/terugbetalingen',
    '_SEND_MAIL_WHEN_': 'Stuur mij een e-mail als',
    '_AVAILABLE_SOON_': 'Bijna beschikbaar',
    '_COMPANY_BANK_TRANSFER_': 'Overstappen bank',
    '_NO_RECORDS_AVAILABLE_': 'Geen records beschikbaar',
    '_CHECK_CONTROL_DIRECT_DEBIT_DATA_': 'Controleer en vul aan',
    '_PAYMENT_VALIDATION_NEEDED_': 'Betalingsinformatie niet gevalideerd',
    '_CONNECTED_TO_': 'Gekoppeld aan',
    '_NUMBER_INSTALLEMENTS_': 'Aantal termijnen',
    '_NUMBER_INSTALLEMENTS_LEFT_': 'Aantal termijnen resterende',
    '_SALE_NUMBER_': 'Verkoopnummer',
    '_CUSTOM_EMAIL_': 'Eigen e-mail sturen',
    '_ENABLE_CUSTOM_EMAIL_': 'Inschakelen eigen e-mail sturen',
    '_TITLE_': 'Titel',
    '_CATEGORIES_SHOP_': 'Shop categorieën',
    '_ORDER_SHOP_': 'Volgorde shop',
    '_ORDER_SHOP_HOMEPAGE_': 'Volgorde weergave voorpagina',
    '_TEXT_BOOKING_PAGE_': 'Tekst voor op boekingsformulier',
    '_TEXT_BOOKING_PAGE_INFO_': 'Vul hieronder de titel en tekst in binnen de beschikbare talen. Deze zullen zichtbaar worden op de boekingsformulier pagina.',
    '_TEXT_BOOKING_EMAIL_INFO_': 'Je kunt hier het onderwerp en de tekst aanpassen van de bevestigingsmail die je klant ontvangt na het boeken.',
    '_COMPANY_ACCOUNT_': 'Bedrijfsaccount',
    '_BANK_': 'Bank',
    '_CHAMBER_OF_COMMERCE_NUMBER_': 'KVK nummer',
    '_BANK_ACCOUNT_': 'Bankrekening',
    '_BANK_ACCOUNT_OWNER_': 'Rekeninghouder',
    '_REGISTRANT_': 'Registrant',
    '_UBO_DESCRIPTION_': '* UBO = Een natuurlijk persoon die:<br/> meer dan 25% van het vermogen in de onderneming bezit;<br/> meer dan 25% van het stemrecht heeft of<br/> begunstigde is van meer dan 25% van het vermogen van de onderneming.',
    '_AUTHORIZED_TO_SIGN_': 'Tekenbevoegd',
    '_ADD_EXTRA_SIGNEE_': 'Toevoegen tekenbevoegde',
    '_ADD_DOCUMENTS_': 'Toevoegen documenten',
    '_MORE_SIGNEES_': 'Meer tekenbevoegden',
    '_SOLE_PROPRIETORSHIP_': 'Eenmanszaak',
    '_DIFFERENT_PROPRIETORSHIP_': 'Andere rechtsvorm',
    '_SIGNEE_': 'Tekenbevoegde',
    '_AGREE_TERMS_': 'Ik ga akkoord met algemene voorwaarden',
    '_PLEASE_ADD_DOCUMENTS_FOR_VALIDATION_': 'ACTIE: Voeg de documenten toe om de validatie compleet te maken',
    '_PAY_ATTENTION_NO_CLEARING_': 'Let op: als je gegevens aanpast moet het account opnieuw gevalideerd worden. Tussentijds zal er geen uitbetaling plaats vinden.',
    '_PAY_ATTENTION_NO_CLEARING_2_': 'Als je gegevens aanpast moet het account opnieuw gevalideerd worden. Tussentijds zal er geen uitbetaling plaats vinden. Wil je nog steeds wijzigingen doen?',
    '_PAY_TERMS_': 'Algemene voorwaarden Pay',
    '_PAYNPLAN_TERMS_': 'Algemene voorwaarden Pay n Plan',
    '_PAYNPLAN_RATES_': 'Pay n Plan tarieven',
    '_AGREE_RATES_': 'Ik ga akkoord  met de tarieven',
    '_LEGAL_FORM_': 'Rechtsvorm',
    '_SIGNEES_': 'Tekenbevoegden',
    '_CHANGE_COMPANY_INFORMATION_': 'Bewerk bedrijfsinformatie',
    '_CHANGE_DOCUMENTS_': 'Bewerk documenten',
    '_CHECK_DATA_AND_SEND_': 'Controleer de onderstaande gegevens en verstuur',
    '_SIGN_INDEPENDENTLY_': 'Individueel tekenbevoegd',
    '_SHARED_AUTHORIZED_': 'Gedeeld tekenbevoegd',
    '_CATEGORY_': 'Categorie',
    '_PAYPAL_EMAIL_': 'PayPal e-mailadres',
    '_PAYPAL_ACCOUNT_': 'PayPal account',
    '_OPTINAL_': 'Optioneel',
    '_COMPANY_TYPE_': 'Bedrijfstype',
    '_DASHBOARD_': 'Dashboard',
    '_CREATE_LOGIN_': 'Wachtwoord maken',
    '_CREATE_LOGIN_VIA_BOOKING_': 'Bevestig jouw boeking',
    '_NEW_CUSTOMER_': 'Toevoegen',
    '_INVITE_CUSTOMER_TO_APP_': 'Nodig klant per e-mail uit voor de app.',
    '_DELETE_DIRECT_DEBIT_TRANSFER_': 'Niet overnemen',
    '_PRODUCT_CHANGE_MESSAGE_': 'Zodra je het product wijzigt kan er direct met het nieuwe product ingeschreven worden. Als je dit pas vanaf de eerstvolgende termijn datum in wil laten gaan, deactiveer dan dit abonnement en maak een nieuwe aan.',
    '_UBO_PERCENTAGE_': 'Ubo percentage',
    '_SOLE_PROPRIETORSHIP_WITH_AUTHORIZED_': 'Eenmanszaak met gemachtigde',
    '_LINK_TO_ORDER_DETAILS_PAGE_': 'Order details URL (klantfacturen)',
    '_TOKEN_ORDER_DETAILS_PAGE_': 'Beveiligingstoken order detail pagina',
    '_DATE_START_': 'Ingangsdatum',
    '_DATE_START_EVENT_': 'Startdatum',
    '_DATE_END_MESSAGE_': 'Geen einddatum = onbeperkt',
    '_DATE_START_MESSAGE_': 'Geen ingangsdatum = vandaag',
    '_VIEW_PAYMENT_MODULE_': 'Zie betaal module',
    '_CLEARING_COSTS_MESSAGE_': '1 keer per maand opnemen is gratis, daarna worden er kosten in rekening gebracht.',
    '_BOOKING_OPTIONS_': 'Boekingsformulier?',
    '_HAVE_ACCOUNT_': 'Ik heb al een account',
    '_NOT_HAVE_ACCOUNT_': 'Ik heb nog geen account',
    '_CHOOSE_DATE_': 'Kies een datum',
    '_PLAN_APPOINTMENT_LATER_': 'Ik kies later een datum',
    '_BOOK_': 'Boek',
    '_LANGUAGE_CHANGED_': 'Taal aangepast',
    '_NO_DATES_AVAILABLE_': 'Geen datums beschikbaar',
    '_LOGIN_DETAILS_': 'Login gegevens',
    '_DETAILS_': 'Gegevens',
    '_DATETIME_START_': 'Begintijd',
    '_DATETIME_END_': 'Eindtijd',
    '_TAGS_': 'Tags',
    '_ORDER_CONFIRMATION_EMAIL_': 'Order bevestigings e-mail',
    '_BOOKING_CONFIRMATION_EMAIL_': 'Boeking bevestigings e-mail',
    '_COLLECT_EVERY_': 'Incasseer elk(e)',
    '_SURE_TO_SET_DEFAULT_BACK_': 'Weet je zeker dat je de standaard tekst terug wil zetten?',
    '_SURE_REMOVE_TEXT_': 'Weet je zeker dat je de tekst wil verwijderen?',
    '_BOOKING_LINK_': 'Boekingslink',
    '_ORDER_LINK_': 'Product link',
    '_BOOK_PRODUCT_TEXT_': 'Met deze link kunnen zowel bestaande als nieuwe klanten direct een afspraak plannen bij het aankopen van een product.',
    '_CHOOSE_PRODUCT_': 'Kies een product',
    '_SUBSCRIPTION_': 'Abonnement',
    '_ENTER_TEXT_HERE_': 'Vul de tekst hier in',
    '_VIEW_AVAILABLE_TAGS_': 'Hieronder vind je de beschikbare tags, te gebruiken in de tekst.',
    '_REQUIRED_': 'Verplicht',
    '_OPTIONAL_': 'Optioneel',
    '_HOW_MUCH_SALES_PER_CUSTOMER_': 'Max per klant',
    '_NUMBER_WEEKS_VALID_': 'Aantal weken geldig',
    '_MORE_INFORMATION_CONTACT_US_': 'Voor meer informatie, neem contact met ons op',
    '_BOOK_WITH_ANOTHER_ACCOUNT_THAN_': 'Boek met een ander account dan die van',
    '_BOOKING_THANKS_': 'Bedankt voor je inschrijving!',
    '_ALMOST_READY_': 'Goed gedaan, je bent er bijna!',
    '_IT_WORKED_': 'Het is gelukt!',
    '_BACK_TO_AGENDA_': 'Terug naar de agenda',
    '_CONFIRMATION_SETTINGS_': 'Bevestigingsinstellingen',
    '_NEED_CONFIRMATION_': 'De klant moet akkoord gaan met de voorwaarden',
    '_CONFIRMATION_BOX_TEXT_': 'Bevestigingsbox tekst',
    '_CONFIRM_BEFORE_SUBSCRIBE_': 'Ga akkoord met de algemene voorwaarden voordat je in kan schrijven',
    '_FREE_TRIAL_': 'Kennismakingsproduct',
    '_VIEW_PAYMENT_MODULE_': 'Zie betalingen',
    '_CLEARING_COSTS_MESSAGE_': '1 keer per maand opnemen is gratis, daarna worden er kosten in rekening gebracht.',
    '_ACTIVATION_PLANNED_': 'Activatie gepland',
    '_PAUSE_PLANNED_': 'Pauze gepland',
    '_DEACTIVATION_PLANNED_': 'Deactivatie gepland',
    '_PAUSED_': 'Gepauzeerd',
    '_SUBSCRIPTION_WIL_BE_DEACTIVATED_': 'wordt dit abonnement gedeactiveerd',
    '_INVOICE_EMAIL_DESIRABLE_': 'Voor kassabon via e-mail',
    '_SPECIFICATIONS_': 'Specificaties',
    '_TRANSACTION_COSTS_RESERVATION_': 'gereserveerde transactiekosten',
    '_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_': 'transactiekosten',
    '_INVOICED_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_': 'gefactureerde kosten',
    '_NOT_INVOICED_TRANSACTION_COSTS_OVER_SELECTED_PERIOD_': 'nog te factureren kosten',
    '_INVOICED_': 'gefactureerd',
    '_FOR_TRANSACTION_': 'Voor transactie',
    '_COST_INVOICE_': 'Kostenfactuur',
    '_NOT_INVOICED_YET_': 'nog niet gefactureerd',
    '_AMOUNTS_INCLUDING_VAT_': 'Bedragen zijn inclusief BTW',
    '_ACCOUNT_NOT_ALLOWED_': 'Account maken uitschakelen',
    '_NO_EFFECTS_ON_PAYMENT_': 'Wijzigingen hebben alleen effect op de agenda, niet op de betalingen.',
    '_LIST_': 'Lijst',
    '_DIRECT_DEBITS_TOTAL_NUMBER_ITEMS_': 'Aantal in omloop',
    '_REVENUE_PRODUCTS_': 'Omzet per product',
    '_REVENUE_CUSTOMERS_': 'Omzet per klant',
    '_NEW_SALES_': 'Nieuwe verkopen',
    '_DIRECT_DEBITS_VS_DEBITS_': 'Incasso vs. losse aankopen',
    '_UNSUBCRIBERS_': 'Uitschrijvingen',
    '_ACTIVE_VS_INACTIVE_': 'Actieve/inactieve klanten',
    '_NEW_SUBSCRIBERS_': 'Nieuwe inschrijvingen',
    '_EXPIRED_CREDITS_': 'Verlopen rittenkaarten',
    '_TRANSFER_PRODUCTS_': 'Overstap in producten',
    '_MONTH_': 'Maand',
    '_BAR_': 'Staaf',
    '_LINE_': 'Lijn',
    '_PIE_': 'Taart',
    '_EXTRA_INFO_': 'Extra informatie',
    '_SHOP_SETTINGS_': 'Shop instellingen',
    '_SALE_SETTINGS_': 'Verkoop instellingen',
    '_PAYMENT_': 'Betaling',
    '_AGENDA_SETTINGS_': 'Agenda instellingen',
    '_UNABLE_TO_SUBSCRIBE_YET_': 'Het is nog niet mogelijk om in te schrijven op deze afspraak',
    '_APPOINTMENT_IN_PAST_': 'Deze afspraak is al geweest',
    '_APPOINTMENT_FULL_': 'Deze afspraak is vol',
    '_NO_PRODUCTS_AVAILABLE_TO_BOOK_APPOINTMENT_': 'Er zijn geen producten voor je beschikbaar om de afspraak te boeken',
    '_BOOK_TO_BUY_PRODUCT_AND_SUBSCRIBE_DIRECTLY_': 'Klik op \'boek\' om een product te kopen en direct in te schrijven op de afspraak',
    '_DISCOUNT_CODES_': 'Kortingscodes',
    '_MANAGE_DISCOUNT_CODES_': 'Beheer kortingscodes',
    '_CODE_': 'Code',
    '_DISCOUNT_AMOUNT_': 'Kortingsbedrag per product',
    '_DISCOUNT_PERCENTAGE_': 'Kortingspercentage',
    '_MAX_TIMES_PER_ORDER_': 'Max. producten per order',
    '_MAX_ORDERS_PER_USER_': 'Max. gebruik per klant',
    '_VALID_FROM_': 'Geldig vanaf',
    '_AVAILABLE_FOR_FOLLOWING_PRODUCTS_': 'Beschikbaar op de volgende producten',
    '_CHECKIN_ALLOWED_WITH_FOLLOWING_PRODUCTS_': 'Inchecken toegestaan met',
    '_DISCOUNT_CODE_': 'Kortingscode',
    '_ENTER_CONFIRMATION_TEXT_HERE_': 'Vul de voorwaarden hier in',
    '_PRODUCT_CONNECTED_BY_COMPANY_': 'Handmatig gekoppeld door het bedrijf',
    '_REGISTER_': 'Registreren',
    '_CASH_': 'Contant',
    '_CALCULATE_': 'Berekenen',
    '_GET_AMOUNT_': 'Ontvangen bedrag',
    '_RETURN_AMOUNT_': 'Terug',
    '_SEND_INVOICE_': 'Verstuur factuur',
    '_ON_TAB_': 'Op rekening',
    '_NO_PIN_ACCESS_': 'Je hebt geen toegang tot deze betaalmethode. Mocht je meer informatie willen over onze Pin apparaten, kan je contact met ons opnemen.',
    '_LEDGER_': 'Kasboek',
    '_VIEW_CASH_PAYMENTS_': 'Bekijk contante betalingen',
    '_IN_REGISTER_': 'In kas',
    '_AMOUNT_TO_WITHDRAWAL_': 'Bedrag',
    '_REASON_WITHDRAWAL_': 'Reden voor opname',
    '_INDIVIDUAL_SALES_': 'Losse verkoop',
    '_SALESPERSON_': 'Verkoper',
    '_SALES_OVER_PERIOD_': 'Verkoop over periode',
    '_WITHDRAWALS_OVER_PERIOD_': 'Opnames over periode',
    '_UNPAID_INVOICES_': 'Onbetaalde facturen',
    '_UNPAID_INVOICES_DESCRIPTION_': 'Bij crediteren wordt het bedrag kwijtgescholden en verdwijnt de factuur uit het overzicht.',
    '_NO_UNPAID_INVOICES_': 'Er zijn geen openstaande facturen :)',
    '_PAID_INVOICES_': 'Betaalde facturen',
    '_PAID_INVOICES_DESCRIPTION_': 'Betaalde facturen',
    '_NO_PAID_INVOICES_': 'Er zijn geen betaalde facturen',
    '_FAILED_OUTSTANDING_BILLS_': 'Mislukte of openstaande betalingen',
    '_ENTER_VALID_AMOUNT_': 'Vul een geldig bedrag in',
    '_REFUND_': 'Terugbetaling',
    '_CHOOSE_PAYMENT_METHOD_': 'Kies een betaalmethode',
    '_MIN_PRODUCTS_IN_CART_': 'Min. producten voor korting',
    '_CUSTOMER_UNSUBSCRIBE_SELF_': 'Klant mag zelf uitschrijven',
    '_SUBSCRIBED_UNSUBSCRIBE_SELF_NOT_ALLOWED_': 'Ingeschreven, zelf uitschrijven is niet mogelijk',
    '_MY_APPOINTMENTS_': 'Mijn afspraken',
    '_REGISTERED_PARTNER_': 'Ingeschreven partner',
    '_CHARGED_': 'afgeboekt',
    '_VIEW_APPOINTMENT_': 'Bekijk afspraak',
    '_ACTIVE_PRODUCTS_': 'Actief',
    '_ARCHIVED_PRODUCTS_': 'Gearchiveerd',
    '_RESTORE_': 'Terugzetten',
    '_ARCHIVE_': 'Archief ',
    '_OPEN_ARCHIVE_': 'Archief openen ',
    '_SUBSCRIBE_AND_BUY_NOT_POSSIBLE_IF_ARCHIVE_': 'Als het product gearchiveerd wordt kan het product niet meer worden gekocht en er kan niet meer mee worden ingeschreven.',
    '_NO_REFUNDING_MESSAGE_': 'Let op! Hier geen terugbetalingen doen, want dan wordt er geen credit factuur aangemaakt en klopt de boekhouding niet. Voor terugbetalen ga naar Financieel - Verkopen - Beheer Terugbetalingen.',
    '_CASH_WITHDRAWAL_': 'Geld opname',
    '_REGISTER_PAYMENT_': 'Kassa',
    '_UNPAID_': 'Openstaand',
    '_PAID_': 'Betaald',
    '_PAY_NOW_': 'Betalen',
    '_INVOICE_SEND_ON_': 'Factuur is verstuurd op',
    '_INVOICE_MESSAGE_': 'De factuur wordt naar je klant gestuurd.',
    '_INVOICE_MESSAGE_INSTALLMENT_': 'De factuur wordt naar je klant gestuurd. Je hebt een abonnement in de winkelwagen. Het eerste termijn wordt nu voldaan. De volgende termijnen lopen via incasso. Als je een factuur verstuurd ontvang je een bericht als de eerste betaling is voldaan.',
    '_INVOICE_DESCRIPTION_': 'Omschrijving op factuur',
    '_FUTURE_DEACTIVATIONS_': 'Geplande deactivatie',
    '_DEPOSIT_': 'Geld inleggen',
    '_REASON_DESPOSIT_': 'Reden voor inleg',
    '_MANAGE_DEPOSIT_': 'Inleggen',
    '_ACTIVATE_ON_DIRECT_DEBIT_DATE_': 'Per termijndatum',
    '_MAX_NUMBER_PER_ORDER_': 'Max. per productsoort',
    '_SUBSCRIPTION_WIL_BE_STARTED_': 'wordt het abonnement gestart',
    '_EDITING_NOT_POSSIBLE_': 'wijzigen niet mogelijk',
    '_PAUSE_': 'Pauze',
    '_ASCENDING_INSTALLMENT_': 'Aanmaakdatum oplopend',
    '_DESCENDING_INSTALLMENT_': 'Aanmaakdatum aflopend',
    '_CREATED_ON_ASC_': 'Aanmaakdatum oplopend',
    '_CREATED_ON_DESC_': 'Aanmaakdatum aflopend',
    '_DESCENDING_INSTALLMENT_': 'Aanmaakdatum aflopend',
    '_CREATED_ON_': 'Aangemaakt op',
    '_REMOVE_INSTALLMENT_': 'Abonnement verwijderen',
    '_PASS_DESCRIPTION_': 'Je wachtwoord moet minimaal 6 karakters lang zijn!\nWe adviseren je om een sterk wachtwoord te gebruiken met vreemde tekens, hoofdletters en cijfers.',
    '_PASS_DESCRIPTION_VIA_BOOKING_': 'Kies een wachtwoord van minimaal 6 tekens lang om je boeking te bevestigen!\nWe adviseren je om een sterk wachtwoord te gebruiken met vreemde tekens, hoofdletters en cijfers.',
    '_MESSAGES_': 'Berichten',
    '_NO_SUBJECT_': 'Geen onderwerp',
    '_NEW_MESSAGE_': 'Nieuw bericht',
    '_VIEW_NOW_': 'Nu bekijken',
    '_VIEW_LATER_': 'Later bekijken',
    '_OPEN_': 'Openstaand',
    '_NAME_ATHLETE_': 'Naam atleet',
    '_COPY_TO_CLIPBOARD_': 'Kopieer naar klembord',
    '_COPIED_TO_CLIPBOARD_': 'Gekopieerd naar klembord',
    '_AGREE_WITH_TERMS1_': 'Ik ga akkoord met de',
    '_AGREE_WITH_TERMS2_': 'gebruikersvoorwaarden van',
    '_TERMS_': 'Algemene voorwaarden',
    '_COMPANY_TERMS_': 'Algemene voorwaarden bedrijf',
    '_AGREE_WITH_COMPANY_TERMS1_': 'Ik ga akkoord met de',
    '_AGREE_WITH_COMPANY_TERMS2_': 'algemene voorwaarden van',
    '_MANAGE_CUSTOMER_DATA_': 'Beheer klant gegevens',
    '_NO_LOGOUT_': "Nee, log uit",
    '_CONFIRM_': "Akkoord",
    '_USER_CONDITIONS_CHANGED_': "De gebruikersvoorwaarden zijn gewijzigd",
    '_I_CONFIRM_WITH_CHANGED_USER_CONDITIONS_': "Ik ga akkoord met de gewijzigde gebruikersvoorwaarden van",
    '_USER_CONDITIONS_': "Gebruikersvoorwaarden",
    '_AND_I_CONFIRM_WITH_SHARING_DATA_WITH_CONNECTED_COMPANIES_': "Daarnaast ga ik er mee akkoord dat mijn persoonlijke gegevens gedeeld worden met de bedrijven waarbij ik ben aangesloten",
		'_CUSTOMER_IS_BLOCKED': 'Deze klant is geblokkeerd',
    '_BLOCK_CUSTOMER_': 'Weet je zeker dat je deze klant wilt blokkeren? De klant kan niet langer gebruik maken van de activiteiten van het bedrijf.',
    '_BLOCK_CUSTOMER_ACHIVED_': 'Weet je zeker dat je deze klant wilt blokkeren? Hij/zij kan zichzelf niet meer toevoegen met dit emailadres.',
    '_ACCEPT_CUSTOMER_': 'Wil je deze klant aan je klantenlijst toevoegen?',
    '_ARCHIVE_CUSTOMER_': 'Weet je zeker dat je deze klant wilt archiveren? De klant heeft hierna geen toegang meer tot de activiteiten van het bedrijf.',
    '_ARCHIVED_': 'Gearchiveerd',
    '_INVITED_ON_': 'Uitgenodigd op',
    '_SYNC_MESSAGE_': 'Klant heeft gegevens aangepast.',
    '_SYNC_BTN_TEXT_': 'Gegevens synchroniseren',
    '_IGNORE_SYNC_DATA_': 'Negeren',
    '_IGNORE_SYNC_DATA_ALWAYS_' : 'Altijd',
    '_CUSTOMER_RECORDS_': 'Gegevens klant:',
    '_SYNC_ALL_': 'Alle gegevens overnemen',
    '_DELETE_CUSTOMER_': 'Met deze actie verwijder je de klant en alle data van de klant uit het systeem. Er is geen mogelijkheid om deze gegevens te herstellen (facturen, verkopen en statistieken zullen behouden blijven zonder connectie met de klant). Weet je zeker dat je deze klant wilt verwijderen?',
    '_RETURN_CUSTOMER_TO_LIST_': 'Weet je zeker dat je de klant zichtbaar wilt maken in je klantenlijst? Om een connectie te maken is het nodig om de klant opnieuw uit te nodigen.',
    '_SEND_INVITE_': 'Verstuur uitnodiging',
    '_INVITED_ON_': 'Uitgenodigd op',
    '_UNBLOCK_CUSTOMER_': 'Weet je zeker dat je deze klant wilt deblokkeren?',
    '_SYNC_CUSTOMER_': 'Synchroniseer gegevens',
    '_IGNORE_SYNC_MESSAGE_': 'Negeer wijzigen die door de klant gedaan zijn nu of altijd',
    '_IGNORE_SYNC_MESSAGE_NEW_MAIL_': 'Het emailadres is veranderd. Als je de wijziging negeert is het mogelijk dat de emails die worden verzonden door het systeem niet altijd aankomen. Negeer wijzigingen voor nu of altijd',
    '_NOT_ARCHIVED_': 'Niet gearchiveerd',
    '_UNBLOCK_': 'Deblokkeren',
    '_UNDO_': 'Ongedaan maken',
    '_ADD_CUSTOMER_': 'Klant toevoegen',
    '_AWARE_PERSONAL_DATA_': 'Door je aan te melden zullen je persoonsgegevens gedeeld worden met dit bedrijf',
    '_ADD_': 'Toevoegen',
    '_ACCEPT_INVITE_': 'Accepteer uitnodiging',
    '_INVITE_': 'Uitnodiging',
    '_UNDO_IGNORE_SYNC_': 'Negeren synchronisatie ongedaan maken',
    '_CREATE_NEW_ACCOUNT_GYM_': 'Maak je account aan om je direct aan te kunnen melden bij',
    '_UNDO_IGNORE_SYNC_': 'Negeren wijzigingen ongedaan maken',
    '_UNDO_IGNORE_SYNC_MESSAGE_': 'Maak de wijzigen die de klant aanbrengt weer zichtbaar',
    '_INVITE_CUSTOMER_': 'Uitnodigen',
    '_GERMANY_': 'Duitsland',
    '_FRANCE_': 'Frankrijk',
    '_NOW_': 'Nu',
    '_CHECK_MAIL_': 'Is ',
    '_CHECK_MAIL2_': ' het juiste emailadres? De gegevens die je hebt ingevuld zullen zichtbaar zijn voor de ontvanger als je een uitnodiging stuurt.',
    '_SEND_NEW_INVITE_': 'verstuur nieuwe',
    '_CHANGED_TO_': 'Gewijzigd naar',
    '_VERSION_COMPANY_TERMS_': 'Versienummer algemene voorwaarden',
    '_INVITE_WILL_BE_SEND_TO_': 'De uitnodiging wordt verzonden naar',
    '_FOLLOWING_DATA_WILL_BE_SHARED_': 'De volgende gegevens worden gedeeld',
    '_HAVE_CHECKED_ADRESSEE_AND_AGREE_CURRENT_AND_FUTURE_DATA_WILL_BE_SHARED_': 'Ik heb de gegevens van de geadresseerde gecontroleerd en ik geef toestemming dat huidige en toekomstige gegevens met de klant worden gedeeld',
    '_SYNC_FIRST_': 'Synchronizeer de gewijzigde klantgegevens eerst. Wil je dit nu doen?',
    '_TERMS_CHANGED_': 'De voorwaarden zijn gewijzigd',
    '_MANAGER_': 'Beheerder',
    '_ARCHIVE_USER_': 'Archiveren',
  	'_ARCHIVE_PRODUCT_': 'Archiveren',
    '_CONNECTED_': 'Aangesloten',
    '_INVITED_': 'Uitgenodigd',
		'_JUST_CONNECTED_CUSTOMERS_CAN_RECEIVE_MESSAGES_': 'Alleen gebruikers die pushberichten toestaan op hun toestel ontvangen een notificatie!',
    '_NO_ACCOUNT_': 'Zonder account',
    '_BLOCKED_': 'Geblokkeerd',
    '_TO_TOP_': 'Naar boven',
    '_AGREE_WITH_COMPANY_TERMS_': 'Ik ga akkoord met de voorwaarden van',
    '_QUESTION_REMOVE_SELECTED_ITEMS_': 'Weet je zeker dat je de geselecteerde klanten wilt archiveren?',
    '_NO_ITEMS_SELECTED_': 'Je hebt geen items geselecteerd',
    '_NO_ITEMS_SELECTED_FOR_EXPORT_': 'Selecteer items om te exporteren',
    '_FILTER_': 'Filter',
    '_FILTERS_': 'Filters',
    '_DONE_': 'Gereed',
    '_WARNING_': 'Waarschuwing',
    '_NEW_DIRECT_DEBIT_DATE_': 'Nieuwe termijn datum',
    '_EDIT_MULTIPLE_SUBSCRIPTIONS_' : "Meerdere bewerken",
    '_EDIT_SUBSCRIPTIONS_': "Bewerk abonnementen",
    '_CHANGE_OPTION_': "Wijzigingsoptie",
    '_DIRECT_DEBITS_WILL_BE_EDITED_': "abonnementen\'s zullen gewijzigd worden. Herstellen is niet mogelijk. Indien er veel data opgeslagen moet worden kan dit even duren. Even geduld alsjeblieft.",
    '_NO_DIRECT_DEBITS_SELECTED_': "Er zijn geen abonnementen\'s geselecteerd",
    '_NO_DATA_AVAILABLE_': "Geen data beschikbaar",
    '_HISTORY_': "Historie",
    '_CHANGE_CONFLICT_': "Wijzigingsconflict",
    '_FORMER_CHANGE_': "Eerdere wijziging",
    '_NEW_CHANGE_': "Nieuwe wijziging",
    '_CONFLICT_MESSAGE_': "Er zijn wijzigingen die met elkaar conflicteren, kies welke wijziging je wilt doorvoeren.",
    '_SAVE_CHANGES_': "Wijzigingen opslaan",
    '_CHANGE_PLANNED_': "Wijziging gepland",
    '_STARTING_DATE_': "Ingangsdatum",
    '_PLANNED_CHANGES_': "Geplande wijzigingen",
    '_SAVE_DATA_WAIT_': 'Indien er veel data opgeslagen moet worden kan dit even duren. Even geduld alsjeblieft.',
    '_FETCH_DATA_WAIT_': 'Indien er veel data opgehaald moet worden kan dit even duren!',
    '_ZIPCODE_CITY_': 'Postcode en stad',
    '_WARNING_STATS_WILL_DISAPPEAR_': 'Kijk voor meer gegevens in het Dashboard. Binnenkort zullen alle gegevens daar terug te vinden zijn en zullen de stats verdwijnen.',
    '_SPAIN_': 'Spanje',
    '_ZIP_PLACEHOLDER_': '1101',
    '_CITY_PLACEHOLDER_': 'Amsterdam',
    '_READ_': 'Gelezen',
    '_MY_PRODUCTS_': 'Mijn producten',
    '_SHOW_CART_': 'Bekijk winkelwagen',
    '_MAX_ALLOWED_SUBSCRIBE_PER_DAY_': 'Max aantal keer inschrijven per dag toegestaan',
    '_MAX_NUMBER_SUBSCRIPTIONS_PER_DAY_REACHED_': 'Je hebt het max aantal keer inschrijven per dag bereikt',
    '_ENABLE_MANAGEMENT_SUMMARY_': 'Geef management samenvatting weer',
    '_YOU_NEED_A_PRODUCT_CONTACT_COMPANY_': 'Je hebt een product nodig voor deelname. Neem contact op met',
    '_AGENDA_VIEW_': 'Agenda weergave',
    '_PICK_DASHBOARD_': 'Dashboard',
    '_COLOR_BACKGROUND_AGENDA_': 'Kleur achtergrond agenda',
    '_PICK_AGENDA_': 'Kies agenda',
    '_SETTINGS_PER_AGENDA_': 'Instellingen per agenda',
    '_AGENDA_NOT_VISIBLE_FOR_CUSTOMER_': 'Agenda verbergen voor klant',
    '_APPOINTMENT_TYPES_': 'Afspraaktypes',
    '_COLOR_BACKGROUND_APPOINTENTS_': 'Kleur achtergrond afspraken',
    '_COLOR_TEXT_APPOINTENTS_': 'Kleur tekst afspraken',
    '_CUSTOMER_CAN_OVERWRITE_SETTING_': 'Klant kan agenda weergave overschrijven',
    '_TEMP_COMMENT_': 'Tijdelijke opmerking (bv. blessure)',
    '_OTHER_COMMENTS_': 'Overige opmerkingen',
    '_FIRST_SUBSCRIPTION_': 'Voor het eerst ingeschreven',
    '_NEW_': 'Nieuw',
    '_FAQ_SHORT_': 'FAQ',
    '_PROFILE_IMAGE_': 'Profielfoto',
    '_COMPANY_': 'Bedrijf',
    '_YOU_WERE_INVITED_BY_': 'Je bent uitgenodigd door',
		'_PICK_YOUR_COLOR_': 'Kies je kleur',
		'_THEME_COLOR_': 'Thema kleur',
		'_BG_COLOR_NAVBAR_': 'Achtergrondkleur bovenbalk',
		'_COLOR_NAVBAR_': 'Tekstkleur bovenbalk',
		'_BG_COLOR_BUTTONS_': 'Achtergrondkleur knoppen',
		'_COLOR_BUTTONS_': 'Tekstkleur knoppen',
		'_EXPORT_': 'Exporteren',
		'_NEW_': 'Nieuw',
		'_OLD_': 'Oud',
		'_CATEGORIES_': 'Categorieën',
		'_SUBSCRIPTION_': 'Abonnement',
		'_SECURED_BEHIND_LOGIN_': 'Deze pagina is beveiligd, log in om toegang te verkrijgen!',
		'_BOOKING_FORM_SETTINGS_': 'Boekingsformulier maken?',
		'_BOOKING_FORM_INFO_': 'Via het boekingsformulier kan er direct geboekt en betaald worden voor jouw workshop, event of proefles. De link naar het formulier vind je na het opslaan van het product.',
		'_ENABLE_': 'Inschakelen',
		'_SALE_SETTINGS_INFO_': 'Schakel de "Account maken uitschakelen" optie alleen in wanneer er geen account aangemaakt hoeft te worden en de verkoop niet gekoppeld hoeft te worden aan de klant.',
		'_TEXT_': 'Tekst',
		'_CREDITS_SETTINGS_': 'Rittenkaart instellingen',
    '_EMAIL_FOR_CHANGES_': 'Om gegevens te wijzigen, mail ze naar',
    '_NO_INFORMATION_AVAILABLE_': 'Geen informatie beschikbaar',
    '_SHOPPING_CART_': 'Winkelwagen',
    '_MY_PURCHASES_': 'Mijn aankopen',
    '_EMPTY_IS_UNLIMITED_': 'leeg is onbeperkt',
    '_NOTHING_IN_CART_': 'Je hebt nog niets in je winkelmandje',
    '_VIEW_TERMS_': 'Bekijk algemene voorwaarden',
    '_THERE_IS_': 'Er is',
    '_UNPAID_INVOICE_': 'openstaande factuur',
    '_DOWNLOAD_': 'Download',
		'_DISCOUNT_OPTIONS_': 'Kortingsinstellingen',
		'_MAX_TIMES_PER_ORDER_INFO_': '<b>Max. producten per order</b><br>Alleen van toepassing bij kortingspercentage, korting wordt toegepast op het duurste product! Op hoeveel producten per order mag de korting toegepast worden?',
		'_MAX_NUMBER_PER_ORDER_INFO_': '<b>Max. per productsoort</b><br>Alleen van toepassing bij kortingspercentage! V.b. Je geeft 10% korting op een rittenkaart. Hoe vaak mag je klant deze rittenkaart aankopen incl. de korting?',
		'_MAX_ORDERS_PER_USER_INFO_': '<b>Max. gebruik per klant</b><br> Hoe vaak mag je klant de kortingscode gebruiken?',
		'_MIN_PRODUCTS_IN_CART_INFO_': '<b>Min. producten voor korting</b><br>Hoeveel van de door jou gekozen producten moet een klant kopen om de korting te krijgen. Dit kunnen ook 2 of meer van hetzelfde product zijn (tenzij anders ingesteld bij max. per productsoort).',
		'_ACCOUNTANCY_': 'Boekhouding',
    '_SWITCH_WOD_': 'Kies WOD',
    '_GROUP_': 'Groep',
    '_GO_TO_DASHBOARD_': 'Bekijk je dashboard',
    'SHOW_ONLY_SELECTED_CATEGORY_': 'Laat alleen de geselecteerde categorie zien',
    '_NO_RESULTS_': 'Geen resultaten',
    '_NO_RESULTS_YET_': 'Nog geen resultaten',
		'_CLEARING_THRESHOLD_LEVEL_INFO_TITLE_': 'Achterlaten voor terugbetalingen?',
		'_CLEARING_THRESHOLD_LEVEL_INFO_': 'Indien je het opneembaar saldo volledig overgeboekt wilt hebben laat je het veld hieronder leeg! Het bedrag dat ingevuld staat blijft achter in het account voor eventuele terugbetalingen en wordt niet uitgekeerd.',
		'_CLEARING_PERIOD_': 'Hoe vaak uitbetalen?',
		'_CLEARING_PERIOD_INFO_TITLE_': 'Hoe loopt de uitbetaling?',
		'_CLEARING_PERIOD_INFO_': 'Indien je kiest voor maandelijks of wekelijks schuift je uitbetaling automatisch door, je hoeft verder niets te doen. Met de optie eenmalig kun je zelf steeds losse opnames doen. Let op! Meer dan 1x per maand geld opnemen, daar zijn extra kosten aan verbonden.',
		'_CLEARING_ON_HOLD_MESSAGE_': 'Je uitbetaling is on-hold, mogelijk zijn je bedrijfsgegevens aangepast. Neem contact op met support voor meer informatie.',
		'_ORIGINAL_SALE_': 'Oorspronkelijke aankoop',
		'_REPAY_': 'Terugbetalen',
    '_PUBLISH_NOW_': 'Nu',
    '_PUBLISH_OR_NOT_': 'Je kan de WOD nu publiceren of later',
    '_LATER_': 'Later',
		'_TYPE_': 'Type',
		'_SEND_INVOICES_VIA_REGISTER_': 'Verstuurd via kassa',
		'_INVOICE_DATA_': 'Factuurgegevens',
		'_SELECTED_CUSTOMER_': 'Gekozen klant',
    '_SHOW_REPORTING_': 'Bekijk intake + rapportage',
    '_SELECT_DEVICE_': 'Selecteer terminal',
    '_CHANGE_DIRECT_DEBIT_AMOUNTS_': 'Incasso bedragen wijzigen',
		'_DIRECTS_DEBIT_TO_CHANGE_': 'Te wijzigen abonnementen',
		'_CREATE_DIRECTS_DEBIT_': 'Abonnement toevoegen',
    '_DESCRIPTION_RECEIPT_': 'Omschrijving op bon',
		'_AGENDA_STATUS_': 'Agenda status',
		'_DIRECT_DEBIT_STATUS_': 'Incasso status',
    '_CATEGORY_MESSAGE_': 'Maak hier categorieën aan voor de shop, zodat je product kunnen worden onderverdeeld. Als "zichtbaar in shop" aangevinkt is bij je product kan je het product aan de categorie koppelen.',
    '_DISCOUNT_MESSAGE_': 'Soms wil je je klanten iets extra\'s geven. Hier kan je kortingscodes maken die ze kunnen gebruiken bij het aanschaffen van een product.',
    '_QR_EVENT_MESSAGE_': 'Maak hier je QR code events aan, deze kunnen gescand worden, het systeem checked of jouw klant het juiste product heeft voor deelname!',
		'_PRODUCT_INACTIVE_': 'Dit product is niet actief',
		'_PRODUCT_ACTIVE_': 'Dit product is actief',
		'_ONGOING_PRODUCTS_': 'Lopende producten',
		'_OTHER_PRODUCTS_': 'Overige producten',
    '_NO_PRODUCTS_MESSAGE_': 'Je hebt nog geen producten. Koop een product in de shop of vraag het bedrijf naar de mogelijkheden.',
    '_NO_INTAKE_MESSAGE_': 'Je hebt nog geen intake gedaan. Vraag het bedrijf naar de mogelijkheden.',
    '_NO_REPORT_MESSAGE_': 'Er zijn nog geen rapportages. Vraag het bedrijf naar de mogelijkheden.',
    '_SELECTED_': 'Geselecteerd',
    '_NEW_APPOINTMENT_': 'Nieuwe afspraak',
    '_FREQUENCY_': 'Frequentie',
    '_SINGLE_APPOINTMENT_': 'Eenmalige afspraak',
    '_INFO_ABOUT_PRODUCTS_': 'Kies de producten die je wilt koppelen aan het afspraaktype. Je klanten kunnen alleen de gekozen producten gebruiken om zich in te schrijven op een afspraak met dit afspraaktype.',
    '_INFO_ABOUT_PRIVACY_': 'Let op, indien je \'ja\' kiest kunnen al je klanten zien wie er op de afspraak staat ingeschreven.',
    '_INFO_ABOUT_SETTINGS_': '<b>Familieleden</b><br>Klanten kunnen familieleden toevoegen in de agenda. Jouw klant betaald voor de familieleden. Let op met onbeperkt abonnementen.<br><br><b>Wachtlijst</b><br>Klanten kunnen zich inschrijven op de wachtlijst als de afspraak vol is. Ze worden ingeschreven en ontvangen hier bericht van als er plek vrijkomt.',
    '_REMOVE_APPOINTMENT_TYPE?_': 'Weet je zeker dat je dit afspraaktype wilt verwijderen?',
    '_APPOINTMENT_SETTINGS_': 'Afspraak instellingen',
    '_WITH_PRODUCT_': 'Met product',
    '_HOW_TO_SUBSCRIBE_': 'Schrijf je klant in met het product of kies voor gratis, dan er worden er geen credits afgeboekt.',
    '_FROM_LOWER_': 'van',
    '_COLOR_SETTINGS_': 'Kleurinstellingen',
		'_EDIT_APPOINTMENT_': 'Afspraak bewerken',
		'_WAITING_': 'wachtenden',
		'_YOU_ARE_SUBSCRIBED_': 'Je bent ingescheven',
		'_NOT_ENOUGH_CREDITS_FOR_APPOINTMENT_': 'Er is onvoldoende krediet voor deelname',
		'_YOU_WILL_BE_SUBSCIBED_AFTER_PAYMENT_': 'Kies je product, na de betaling wordt je automatisch ingeschreven op de afspraak.',
		'_SUBSCIBE_QUESTION_WHO_': 'Wie wil je inschrijven?',
		'_MYSELF': 'Mijzelf',
		'_SUBSCIBE_WHO_ERROR_': 'Kies wie je in wilt schrijven',
		'_SEND_MERCHANT_QUESTION_': 'Heb je alles gecontroleerd en wil je de merchantgegevens versturen?',
		'_EARN_MONEY_WITH_': 'Geld verdienen met',
		'_I_WANT_TO_KNOW_MORE_': 'Ik wil hier meer van weten',
		'_PRESENT_': 'aanwezig',
		'_MARK_AS_PRESENT_': 'Markeer als aanwezig',
		'_MARK_AS_ABSENT_': 'Markeer als afwezig',
		'_SHOW_CUSTOMER_': 'Toon klantinfo',
		'_ICE_': 'Noodcontact',
		'_ICE2_': 'Noodcontact 2',
		'_ICE_NAME_': 'Noodcontact naam',
		'_ICE_PHONE_': 'Noodcontact telefoon',
		'_ICE2_NAME_': 'Noodcontact 2 naam',
		'_ICE2_PHONE_': 'Noodcontact 2 telefoon',
		'_TIME_MINUTES_': 'Aantal minuten',
		'_TIME_SECONDS_': 'Aantal seconden',
		'_EMPLOYEES_INSTRUCTORS_': 'Trainers (begeleiders)',
		'_TRAINERS_': 'Trainers',
		'_FILTER_APPOINTMENTS_': 'Afspraken filteren',
		'_APPLY_': 'Toepassen',
		'_HOURS_OVERVIEW_': 'Uren overzicht',
		'_TOTALS_OVER_SELECTED_PERIOD_': 'Totalen over geselecteerd periode',
		'_NUMBER_APPOINTMENTS_': 'Aantal afspraken',
		'_NUMBER_SUBSCRIPTIONS_': 'Aantal inschrijvingen',
		'_DURATION_IN_HOURS_': 'Aantal uren',
		'_N0_TRAINERS_CONNECTED_TO_AGENDAS_': 'Er zijn geen trainers beschikbaar',
		'_N0_TRAINERS_CONNECTED_TO_AGENDAS_CONTENT_': 'Koppel eerst trainers aan de agenda(s) en vervolgens aan de afspraken om uren te kunnen bekijken',
		'_ACTIVATE_MODULE_': 'Activeer deze module',
		'_ACTIVATE_MODULE_PREFIX_': 'Activeer deze module voor slechts',
		'_ACTIVATE_MODULE_POSTFIX_': 'per maand',
		'_YOUR_MONTHLY_AMOUNT_WILL_BE_CHANGED_': 'Je maandbedrag wordt aangepast na activatie',
		'_THANKS_URL_INFO_': 'Je kunt hier de URL invullen naar je persoonlijke bedankpagina! Let op, dit werkt niet vanuit de winkelwagen',
		'_THANKS_URL_PlACEHOLDER_': 'https://',
		'_THANKS_URL_': 'Bedankpagina URL',
		'_THANKS_PAGE_TITLE_': 'Eigen bedankpagina URL',
		'_SEND_MESSAGE_': 'Bericht sturen',
		'_INSTRUCTION_VIDEOS_': 'Instructie video\'s',
		'_PUSH_MESSAGE_AND_EMAIL_': 'Pushbericht en e-mail',
		'_PUSH_MESSAGE_': 'Pushbericht',
		'_INSERT_LINK_URL_': 'Voeg link URL in',
		'_ACTIVECAMPAIGN_CONNECTION_': 'ActiveCampaign koppeling',
		'_API_ACCOUNT_': 'API Accountnaam',
		'_API_KEY_': 'API Sleutel',
		'_NO_ACTIVE_CAMPAIGN_ACCOUNT_YET_': 'Nog geen ActiveCampaign account',
		'_CREATE_YOUR_ACTIVE_CAMPAIGN_ACCOUNT_': 'Maak je account aan',
		'_CONNECTED_WITH_ACCOUNT_': 'Verbonden met account',
		'_DISCONNECT_CONNECTION_': 'Verbreek verbinding',
		'_QUESTION_DISCONNECT_API_CONNECTION_': 'Weet je het zeker? De verbinding inclusief alle triggers wordt verwijderd.',
		'_QUESTION_REMOVE_TRIGGER_': 'Weet je het zeker? De trigger zal worden verwijderd.',
		'_CONNECTION_NOT_ACTIVE_': 'De verbinding is niet actief, actie vereist.',
		'_RECONNECT_': 'Opnieuw verbinden',
		'_WITH_': 'met',
		'_TRIGGERS_': 'triggers',
		'_TRIGGER_': 'Trigger',
		'_ACTION_': 'Actie',
		'_CREATE_NEW_TRIGGER_': 'Nieuwe trigger aanmaken',
		'_EDIT_TRIGGER_': 'Trigger bewerken',
		'_NAME_YOUR_TRIGGER_': 'Geef je trigger een naam',
		'_SELECT_EVENT_': 'Kies gebeurtenis',
		'_SELECT_ACTION_': 'Kies een actie',
		'_LISTS_': 'Lijsten',
		'_WHICH_LIST_': 'Welke lijst?',
		'_WHICH_TAG_': 'Welke tag?',
		'_SELECT_LIST_': 'Kies een mailinglijst',
		'_SELECT_TAG_': 'Kies een tag',
		'_SELECT_TRIGGER_PRODUCTS_': 'Bij welke product(en)? Geen is altijd!',
		'_INFO_ABOUT_TRIGGER_PRODUCTS_': 'De trigger wordt alleen uitgevoerd als één van de geselecteerde producten is verkocht. Als je geen product kiest dan wordt de trigger altijd uitgevoerd ongeacht het product dat is verkocht.',
		'_SELECT_TRIGGER_TRIALS_': 'Bij welke kennismaking(en)? Geen is altijd!',
		'_INFO_ABOUT_TRIGGER_TRIALS_': 'De trigger wordt alleen uitgevoerd als één van de geselecteerde kennismakingen is geboekt. Als je geen kennismaking kiest dan wordt de trigger altijd uitgevoerd ongeacht het soort kennismaking dat is geboekt.',
		'_WHICH_ACTIONS_NEEDS_TO_HAPPEN_': 'Welke actie moet er worden genomen?',
    '_MY_INVOICES_': 'Mijn facturen',
    '_YOUR_INVOICES_EXPLAINED_': 'Via onderstaande link kun je de facturen downloaden die je aan ons betaald. Klik op de link en gebruik de beveiligingstoken om jouw facturen te downloaden!',
    '_COPY_TOKEN_TO_CLIPBOARD_': 'Kopieer de token naar klembord',
    '_YOUR_INVOICES_IN_WElCOME_MAIl_': 'In de persoonlijke welkomstmail die je van ons hebt ontvangen na het plaatsen van je bestelling vind je de factuurlink waarmee je de facturen die je aan ons betaald kunt downloaden.',
    '_END_YEAR_BALANCES_': 'Eindejaarsbalans',
    '_SEPA_IMPORT_SETTINGS_': 'SEPA import instellingen',
    '_IMPORT_MATCH_ID_': 'Uniek ID importeren SEPA incasso\'s',
    '_NUMBER_REMINDER_MESSAGES_SEND_': 'Verstuurde (reminder) berichten',
    '_SHOW_MESSAGE_': 'Toon bericht',
    '_WAY_OF_PAYMENT_': 'Hoe wil je dat je klant de termijnen betaald?',
    '_WAY_OF_PAYMENT_INFO_': 'Je kiest hier of je periodiek wilt afschrijven via SEPA Incasso of dat je de klant een mail wilt sturen met een betaallink. De laatste optie gebruik je meestal wanneer je klant geen automatische bankafschrijving wenst!',
    '_PAY_VIA_SEPA_': 'Automatisch afschrijven via SEPA Incasso',
    '_PAY_VIA_INVITE_': 'Klant betaald zelf via betaallink in mail',
    '_CHANGE_TO_VIA_INVITE_INFO_': 'Je kunt de facturatie laten doorlopen, we zullen niet meer incasseren maar in plaats daarvan voor elke termijn een mail sturen met een betaallink erin.\n\nDruk op de knop om de automatische afschrijving om te zetten naar een termijnfactuur via e-mail.',
    '_CHANGE_TO_VIA_INVITE_': 'Abonnement omzetten naar betalen via e-mail',
    '_CLEARING_SCHEDULED_FOR_': 'Uitbetaling gepland op',
    '_SCHEDULED_YOUR_CLEARING_': 'Plan je uitbetaling via beheer/betalingen/transacties!',
    '_BALANCE_TOTAL_': 'Totaal saldo',
    '_BALANCE_AVAILABLE_': 'Opneembaar saldo',
    '_TO_ADMIN_ENV_': 'Naar beheeromgeving',
    '_TO_CUSTOMER_ENV_': 'Bezoek klantomgeving',
    '_NEWEST_SALES_': 'Nieuwste verkopen',
    '_LATEST_TRANSACTIONS_': 'Laatste transacties',
    '_VIEW_ALL_SALES_': 'Bekijk alle verkopen',
    '_VIEW_ALL_TRANSACTIONS_': 'Bekijk alle transacties',
    '_I_WANT_TO_UPGRADE_PAY': 'Upgraden en betaalsysteem laten inschakelen',
    '_THE_PAYMENY_MODULE_IS_DISABLED_': 'De betaalmodule is uitgeschakeld! Wil je jouw betalingen ook automatiseren?',
    '_TO_SUBSCRIPTION_MANAGEMENT_': 'Naar abonnementsbeheer',
    '_TO_ACCOUNTING_': 'Naar boekhouding',
    '_AMOUNT_OUTSTANDING_': 'Bedrag openstaand',
    '_UPCOMING_APPOINTMENTS_': 'Aankomende afspraken',
    '_TO_AGENDA_': 'Naar de agenda',
    '_NO_NEW_APPOINTMENTS_': 'Er zijn (nog) geen nieuwe afspraken gepland.',
    '_YOUR_LATEST_PURCHASES_': 'Laatste aankopen',
    '_YOUR_STILL_HAVE_': 'nog',
    '_VIEW_YOUR_PURCHASES_': 'Bekijk aankopen',
    '_NO_PURCHASES_YET_': 'Je hebt nog geen aankopen gedaan.',
    '_TO_ALL_PRODUCTS_': 'Bekijk alle producten',
    '_FROM_THE_ASSORTMENT_': 'Producten uit het assortiment',
    '_PRODUCT_OFFER_': 'Productaanbod',
    '_STOCK_MANAGEMENT_': 'Voorraadbeheer (voor bijv. ticketverkoop)',
    '_SHOW_STOCK_ON_PRODUCTPAGE_': 'Voorraad tonen op productpagina?',
    '_DECREASE_STOCK_BY_': 'Voorraad verminderen met (vul getal in)',
    '_INCREASE_STOCK_BY_': 'Voorraad vermeerderen met (vul getal in)',
    '_STOCK_INFO_': 'Je kunt hier voorraadbeheer inschakelen! Let op, vul voor af en bijboeken altijd een positief getal in voor het aantal dat je van de huidige voorraad af of bij wilt boeken. De actuele voorraad kun je bekijken in je lijst van producten door het product open te klikken.',
    '_THE_CURRENT_SUPPLY_IS_': 'De huidige voorraad is',
    '_SEND_MESSAGE_': 'Verstuur bericht',
    '_SEND_MESSAGE_INFO_TEXT_': 'Wil je een specifieke groep klanten een bericht sturen? Bijvoorbeeld alle mensen met een bepaald product?\nVia de dashboards kun je jouw selectie maken en een bericht sturen!',
    '_APPOINTMENT_REMINDER_DIVIDER_': 'Klant afspraakherinnering sturen?',
    '_INFO_ABOUT_APPOINTMENT_REMINDER_': 'Als je deze optie inschakelt ontvangen al je klanten herinneringen per e-mail en pushbericht. Je klant kan zich hier niet voor afmelden en dit kan niet uitgezet worden per klant!\n\nUitleg minuten:\n 1 uur = 60 minuten\n12 uur = 720 minuten\n24 uur = 1440 minuten',
    '_ENABLE_APPOINTMENT_REMINDER_': 'Inschakelen',
    '_NUMBER_MINUTES_BEFORE_APPOINTMENT_REMINDER': 'Aantal minuten van tevoren (24 uur = 1440 minuten)',
    '_ENTER_HERE_APPOINTMENT_REMINDER_': 'Vul het aantal minuten in',
    '_BUTTON_ADD_TO_CALENDAR_': 'Toevoegen aan eigen agenda',
    '_QR_EVENTS_': 'QR Events',
    '_CHECKINS_': 'Check-ins',
    '_OPEN_QR_SCANNER_': 'QR scanner openen',
    '_QR_ACCESS_EVENT_': 'Event met QR code toegang',
    '_EVENT_HAS_START_END_DATE_': 'Start- en einddatum toevoegen?',
    '_CHECKIN_WITH_QR_': 'Inchecken met QR Code',
    '_SELECT_QR_EVENT_': 'Waarvoor wil je inchecken?',
    '_SELECT_QR_INCHECK_PRODUCT_OPTION_': 'Waarmee check je in?',
    '_SHOW_QR_CODE_': 'Naar QR Code Check-in >',
    '_SELECT_QR_INCHECK_PRODUCTS_TITLE_': 'Met welk product wil je inchecken?',
    '_SCAN_QR_CODE_': 'Scan de QR Code voor toegang',
    '_PAGE_NOT_FOUND_': 'Pagina niet gevonden!',
    '_SELECT_CAMERA_': 'Kies je camera',
    '_ENABLE_CAMERA_': 'Camera inschakelen',
    '_CLICK_ENABLE_CAMERA_': 'Klik hier om de camera in te schakelen',
    '_CLICK_DISABLE_CAMERA_': 'Klik hier om de camera uit te schakelen',
    '_SELECT_A_CAMERA_FIRST_': 'Kies eerst je camera',
    '_WAITING_FOR_CAMERA_': 'Wachten op camera',
    '_WERE_CHECKING_YOUR_QR_NOW_': 'Moment geduld, we controleren je QR code ...',
    '_QR_CHECK_FAILED_': 'Check-in mislukt, ongeldig QR code!',
    '_QR_CHECKIN_PLAY_SOUND_': 'Speel geluid af na elke check-in',
    '_EVENT_NAME_': 'Event',
    '_SORT_EVENT_NAME_A_Z_': 'Naam event: a-z',
    '_SORT_EVENT_NAME_Z_A_': 'Naam event: z-a',
    '_SORT_CHECKIN_DATE_DESC_': 'Datum check-in nieuwste',
    '_SORT_CHECKIN_DATE_ASC_': 'Datum check-in oudste',
    '_TOTAL_CHECKINS_': 'Aantal Check-ins',
    '_SEND_TO_COLLECTION_AGENCY_': 'Naar incassobureau verstuurd op',
    '_TO_COLLECTION_AGENCY_TITLE_': 'Naar incassobureau doorzetten?',
    '_MORE_INFO_': 'Meer informatie',
    '_COLLECTION_AGENCY_ENABLED_LINK_': 'Incassobureau module is ingeschakeld',
    '_COLLECTION_AGENCY_INFO_TITLE_': 'Incasssobureau informatie',
    '_ENABLE_MODULE_': 'Schakel de module in',
    '_DISABLE_MODULE_': 'Schakel de module uit',
    '_DISABLE_REMINDERS_': 'Reminders uitschakelen',
    '_ENABLE_REMINDERS_': 'Reminders inschakelen',
    '_REMINDERS_ARE_DISABLED_FOR_OPEN_INVOICE_': 'Je hebt de reminders uitgeschakeld voor deze onbetaalde factuur',
    '_INSTALL_APP_': 'Installeer App',
    '_INSTALL_APP_ALREADY_BUTTON_': 'Knop verbergen, ik heb de app al geinstalleerd',
  },

  // Overwrite languagetags per app when needed!
  tagsPerApp: {
    wodapp: {
      en_GB: {
        '_CREATE_NEW_ACCOUNT_TEXT_': 'Create your account to use this service.',
        '_SEARCH_YOUR_COMPANY_': 'Search your CrossFit Box',
        '_FIND_COMPANY_TITLE_': 'Find your CrossFit Box',
        '_SLOGAN_APP_': 'planning, payments and WOD management for your CrossFit Box',
      },

      nl_NL: {
        '_CREATE_NEW_ACCOUNT_TEXT_': 'Maak je account aan om gebruik te kunnen maken van deze dienst.',
        '_SEARCH_YOUR_COMPANY_': 'Zoek jouw CrossFit Box',
        '_FIND_COMPANY_TITLE_': 'Zoek jouw bedrijf',
        '_SLOGAN_APP_': 'plannen, betalingen en WOD loggen voor je CrossFit Box',
      }
    },

    ptapp: {
      en_GB: {
        '_SLOGAN_APP_': 'planning, payments and WOD management for your PT Company',
      },
      nl_NL: {
        '_SLOGAN_APP_': 'plannen, betalingen en klantbeheer voor je fitness bedrijf',
      }
    },

		paynplan: {
      en_GB: {

      },
      nl_NL: {

      }
    },

    recurme: {
      en_GB: {

      },
      nl_NL: {

      }
    }
  },

  init: function() {
    //Merge the tags per app here!
    this.en_GB = $.extend(this.en_GB, this.tagsPerApp[$Config.app.currentApp].en_GB);
    this.nl_NL = $.extend(this.nl_NL, this.tagsPerApp[$Config.app.currentApp].nl_NL);
  }
};
