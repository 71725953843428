import PanelRightPage from '../components/panel-right.vue';
import CreateLogin from '../pages/create-login.vue';
import CompanyInvite from '../pages/company-invite.vue';
import LoginNewAccount from '../pages/login-new-account.vue';
import RemoveAccount from '../pages/remove-account.vue';
import CompanyMyInvoices from '../pages/company-my-invoices.vue';
import MyAccount from '../pages/my-account.vue';
import NotFound from '../pages/not-found.vue';
import Home from '../pages/home.vue';
import Company from '../pages/company.vue';
import FindCompany from '../pages/find-company.vue';
import CompanyFound from '../pages/company-found.vue';
import Messages from '../pages/messages.vue';
import Faq from '../pages/faq.vue';
import InstructionVideos from '../pages/instruction-videos.vue';
import ChangePassword from '../pages/change-password.vue';
import ProfileImage from '../pages/profile-image.vue';
import SettingsOther from '../pages/settings-other.vue';
import MyCompanies from '../pages/my-companies.vue';
import AgendaList from '../pages/agenda-list.vue';
import Agenda from '../pages/agenda.vue';
import CustomerAgendaMyAppointments from '../pages/customer-agenda-my-appointments.vue';
import Booking from '../pages/booking.vue';
import BookingConfirm from '../pages/booking-confirm.vue';
import PromoteUS from '../pages/promote-us.vue';
import CustomerProgress from '../pages/customer-progress/index.vue';

// Management Pages here!
import ManageTimeOverviewEmployees from '../pages/manage-time-overview-employees.vue';
import ManageCompany from '../pages/manage-company.vue';
import ManageCompanySettings from '../pages/manage-company-settings/index.vue';
import ManageAccessSettings from '../pages/manage-access-settings/index.vue';
import ManageCompanyStyles from '../pages/manage-company-styles/index.vue';
import ManageCustomers from '../pages/manage-customers.vue';
import ManageProducts from '../pages/manage-products/index.vue';
import ManageApiConnect from '../pages/manage-api-connect/index.vue';
import ManageQRAccess from '../pages/manage-qr-access/index.vue';
import ManagePayments from '../pages/manage-payments.vue';
import ManageTransactions from '../pages/manage-transactions.vue';
import ManageDirectDebits from '../pages/manage-direct-debits.vue';
import ManageSales from '../pages/manage-sales.vue';
import ManageWod from '../pages/manage-wod.vue';
import ManageAccountancy from '../pages/manage-accountancy/index.vue';
import ManageDashboard from '../pages/manage-dashboard/index.vue';
import ManagePushMessage from '../pages/manage-pushmessage.vue';
import ManageRegister from '../pages/manage-register/index.vue';
import ManageQRCheckinScanner from '../pages/manage-qr-checkin-scanner.vue';

//Customer pages here!
import CustomerQRCheckin from '../pages/customer-qr-checkin.vue';
import CustomerShop from '../pages/customer-shop.vue';
import CustomerMyPurchases from '../pages/customer-my-purchases/index.vue';
import CustomerWod from '../pages/customer-wod/index.vue';
import CustomerReporting from '../pages/customer-reporting/index.vue';

// Import event bus
import { EventBus } from './eventBus';

// Set the page transition type here
const transition = $Config.app.theme == 'ios' ? 'f7-parallax' : '';

// Get window url query params used for external calls
const params = $FunctionsHelper.getAllQueryParams(window.location.href);

function initPushwoosh() {
	// We can not support this anymore!
	return false;

	if(!$Config.app.onDevice || $PushWooshInitiated) return false;

	const pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');

	if(pushwoosh) {
		// Watch for incoming notifications
		document.addEventListener('push-receive', (event) => {
			const currentRoute = $ViewHelper.router.getCurrentRoute();
			const idm = event.notification.userdata.idm;
			const subject = (event.notification.userdata.subject.length > 0 ? event.notification.userdata.subject.substring(0, 35) : event.notification.message.substring(0, 35));
			
			const buttons = [
				{text: $LanguageHelper.translate('_VIEW_NOW_'), onClick: () => {
					// Send user to the messages page if he is not already on there!
					if(!currentRoute.name || currentRoute.name != 'messages') return $ViewHelper.router.navigate(`/messages/idm/${idm}`, { animate: false });

					// Message page is already open, emit new message event and let message page handle it!
					EventBus.$emit('push-notification', idm);
				}},

				{text: $LanguageHelper.translate('_VIEW_LATER_'), onClick: () => { return false; }},
			];

			$ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_NEW_MESSAGE_'), subject);
		});

		pushwoosh.onDeviceReady({
			appid: $Config.apps[$Config.app.currentApp].push.pushwoosh,
			projectid: $Config.apps[$Config.app.currentApp].push.gmc
		});

		pushwoosh.registerDevice(
			(status) => {
				$AjaxHelper.doRequest('pushMessage', 'registerDevice', {code: status.pushToken}, {hideIndicator: true, callback: () => {
					$PushWooshInitiated = true;
				}});
			}
		);
	}
}

function agendaRoute({ to }) {
	// This is an external page with id agenda in it, send directly to agenda page
	if(params['external'] && params['id_agenda'] && params['id_agenda'] > 0) return $ViewHelper.router.navigate(`/agenda/idc/${params.idc}/id/${params.id_agenda}/manage/0`, { animate: false, reloadCurrent: true });

	$AjaxHelper.doRequest('agenda', 'getAgendas', { idc: to.params.idc }, {callback: (data) => {
		// Multiple agendas? Send to agenda list page
		if(data.resultset.length > 1) {
			// Store agenda list in the global window space since there is no other way to pass this data :(
			window.agendaList = data.resultset;
			return $ViewHelper.router.navigate(`/agenda-list/idc/${to.params.idc}/manage/${to.params.manage}`, { animate: !params['external'], reloadCurrent: params['external'] ? true: false });
		}

		// Company has only 1 agenda, send directly to agenda page
		if(data.resultset.length == 1 && data.resultset[0].id_agenda) return $ViewHelper.router.navigate(`/agenda/idc/${to.params.idc}/id/${data.resultset[0].id_agenda}/manage/${to.params.manage}`, { animate: !params['external'], reloadCurrent: params['external'] ? true: false });

		// No agenda send to my appointments page!
		return $ViewHelper.router.navigate(`/customer-agenda-my-appointments/idc/${to.params.idc}`);
	}});
}

function beforeRoute({ to, resolve }, component) {
  // Load a page based on an external URL, check for page param to know which page to load
	if(params && params['external'] && params['page']) {
		//List of page options
		let pages = [
			{name: 'create-login', component: CreateLogin},
			{name: 'company-invite', component: CompanyInvite},
			{name: 'agenda', path: `/agenda-routing/idc/${params.idc}/manage/0`},
			{name: 'booking', component: Booking}, // Example URL => ?page=booking&external=1&embedded=1&id=1808&pid=9895
			{name: 'booking-confirm', component: BookingConfirm}, // Example URL => ?page=booking-confirm&external=1&id=1808&rid=30578,
			{name: 'customer-qr-checkin', component: CustomerQRCheckin}, // Example URL => ?page=customer-qr-checkin&external=1
			{name: 'qr-checkin-scanner', component: ManageQRCheckinScanner}, // Example URL => ?page=qr-checkin-scanner&external=1
		];

		// Find the page we are looking for
		pages = pages.filter((page) => {
			if(params['page'] == page.name) return page;
		});

		// If page was found redirect to page if path was given, otherwise resolve the component!
		if(pages[0]) {
			if(pages[0].component) return resolve({ component: pages[0].component });

			/* Use the path so props will be passed. Prevent loop by checking the / so we know it's the initial load */
			if(pages[0].path && to.path == '/') return $ViewHelper.router.navigate(pages[0].path, { animate: false} );
		}
	}

	// Send user to login new account page if he has no access
  if(!$UserHelper.isLoggedIn() && !to.route.openAccess) return $ViewHelper.router.navigate('/login-new-account/', { reloadCurrent: true, animate: false });

	// Stuff we need to do when we enter the home page
	if(to.name && to.name == 'home') {
		// Init pushwoosh
		//initPushwoosh();

		// Get the profile image here and throw an event!
		$AjaxHelper.doRequest('user', 'loadProfileImage', {}, { hideIndicator: true, callback: (data) => {
			EventBus.$emit('user-profile-image-changed', data.resultset.imageURL);
		}});
	}
	
	// Count page visits
	$PageVisits ++;

	return resolve({ component: component });
}

export default [
	{
    path: '/panel-right/',
    component: PanelRightPage,
  },

	{
		name: 'home',
		path: '/',
		async: (args) => beforeRoute(args, Home),
		noStyles: true
  },

	{
		name: 'home',
		path: '/home/idc/:idc',
		async: (args) => beforeRoute(args, Home),
		noStyles: true
  },

	{
		path: '/remove-account/',
    async: ({ resolve }) => {
			resolve({ component: RemoveAccount });
		},
		name: 'RemoveAccount'
  },

	{
		path: '/company-my-invoices/idc/:idc',
    async: ({ resolve }) => {
			resolve({ component: CompanyMyInvoices });
		},
		name: 'CompanyMyInvoices'
  },

	{
		path: '/login-new-account/',
    async: ({ resolve }) => {
			$PushWooshInitiated = false;
			resolve({ component: LoginNewAccount });
		},
		name: 'LoginNewAccount'
  },

	{
		path: '/create-login/',
    async: (args) => beforeRoute(args, CreateLogin),
	},

	{
		path: '/company-invite/',
    async: (args) => beforeRoute(args, CompanyInvite),
	},

	{
		path: '/change-password/',
    async: (args) => beforeRoute(args, ChangePassword),
		options: { transition: transition },
	},

  {
		path: '/messages/',
    async: (args) => beforeRoute(args, Messages),
		name: 'messages',
		options: { transition: transition },
  },

	{
		path: '/messages/idm/:idm',
    async: (args) => beforeRoute(args, Messages),
		name: 'messages',
		options: { transition: transition },
  },

  {
    path: '/my-account/',
    async: (args) => beforeRoute(args, MyAccount),
		options: { transition: transition },
  },

	{
		path: '/profile-image/',
    async: (args) => beforeRoute(args, ProfileImage),
		options: { transition: transition },
	},

  {
    path: '/password/',
    async: (args) => beforeRoute(args, Password),
		options: { transition: transition },
  },

  {
    path: '/faq/idc/:idc/no-styles/:noStyles',
    async: (args) => beforeRoute(args, Faq),
		options: { transition: transition },
  },

	{
    path: '/instruction-videos/idc/:idc',
    async: (args) => beforeRoute(args, InstructionVideos),
		options: { transition: transition },
  },

	{
    path: '/promote-us/idc/:idc',
    async: (args) => beforeRoute(args, PromoteUS),
		options: { transition: transition },
  },

  {
		name: 'company',
		path: '/company/idc/:idc',
    async: (args) => beforeRoute(args, Company),
		options: { transition: transition },
  },

  {
		path: '/find-company/',
    async: (args) => beforeRoute(args, FindCompany),
		options: { transition: transition },
  },

  {
		path: '/company-found/idc/:idc',
    async: (args) => beforeRoute(args, CompanyFound),
		options: { transition: transition },
  },

  {
    path: '/my-companies/',
    async: (args) => beforeRoute(args, MyCompanies),
		options: { transition: transition },
  },

  {
    path: '/settings-other/idc/:idc/no-styles/:noStyles',
    async: (args) => beforeRoute(args, SettingsOther),
		options: { transition: transition },
  },

	{
		path: '/agenda-routing/idc/:idc/manage/:manage',
    async: (args) => agendaRoute(args),
		options: { transition: transition },
  },

	{
    path: '/agenda-list/idc/:idc/manage/:manage',
    async: (args) => beforeRoute(args, AgendaList),
		options: { transition: transition },
		openAccess: true
  },

	{
    path: '/agenda/idc/:idc/id/:id/manage/:manage',
    async: (args) => beforeRoute(args, Agenda),
		options: { transition: transition },
		openAccess: true
  },

	{
    path: '/booking',
    async: (args) => beforeRoute(args, Booking),
  },

	{
    path: '/customer-qr-checkin/idc/:idc/ide/:ide/ids/:ids/t/:t',
    async: (args) => beforeRoute(args, CustomerQRCheckin),
  },

	{
    path: '/booking-confirm',
    async: (args) => beforeRoute(args, BookingConfirm),
  },

	{
    path: '/customer-agenda-my-appointments/idc/:idc',
    async: (args) => beforeRoute(args, CustomerAgendaMyAppointments),
		options: { transition: transition },
  },

	{
		path: '/manage-company/idc/:idc',
    async: (args) => beforeRoute(args, ManageCompany),
		options: { transition: transition },
  },

  {
    path: '/manage-company-settings/idc/:idc',
    async: (args) => beforeRoute(args, ManageCompanySettings),
		options: { transition: transition },
  },

  {
    path: '/manage-access-settings/idc/:idc',
    async: (args) => beforeRoute(args, ManageAccessSettings),
		options: { transition: transition },
  },

  {
    path: '/manage-company-styles/idc/:idc',
    async: (args) => beforeRoute(args, ManageCompanyStyles),
		options: { transition: transition },
  },

	{
    path: '/manage-customers/idc/:idc',
    async: (args) => beforeRoute(args, ManageCustomers),
		options: { transition: transition },
  },

	{
    path: '/manage-invoice_product/idc/:idc',
    async: (args) => beforeRoute(args, ManageProducts),
		options: { transition: transition },
  },

	{
    path: '/manage-api_connect/idc/:idc',
    async: (args) => beforeRoute(args, ManageApiConnect),
		options: { transition: transition },
  },

	{
    path: '/manage-qr_access/idc/:idc',
    async: (args) => beforeRoute(args, ManageQRAccess),
		options: { transition: transition },
  },
	
	{
    path: '/manage-payments/idc/:idc',
    async: (args) => beforeRoute(args, ManagePayments),
		options: { transition: transition },
  },

	{
    path: '/manage-transactions/idc/:idc',
    async: (args) => beforeRoute(args, ManageTransactions),
		options: { transition: transition },
  },

	{
    path: '/manage-sales/idc/:idc',
    async: (args) => beforeRoute(args, ManageSales),
		options: { transition: transition },
  },

	{
    path: '/manage-direct-debits/idc/:idc',
    async: (args) => beforeRoute(args, ManageDirectDebits),
		options: { transition: transition },
  },

	{
    path: '/manage-wod/idc/:idc',
    async: (args) => beforeRoute(args, ManageWod),
		options: { transition: transition },
	},

	{
		path: '/manage-accountancy/idc/:idc',
    async: (args) => beforeRoute(args, ManageAccountancy),
		options: { transition: transition },
  },

	{
		path: '/manage-dashboard/idc/:idc',
    async: (args) => beforeRoute(args, ManageDashboard),
		options: { transition: transition },
  },

	{
		path: '/manage-pushmessage/idc/:idc',
    async: (args) => beforeRoute(args, ManagePushMessage),
		options: { transition: transition },
  },

	{
		path: '/manage-register/idc/:idc',
    async: (args) => beforeRoute(args, ManageRegister),
		options: { transition: transition },
  },

	{
		path: '/manage-time_overview_employees/idc/:idc',
    async: (args) => beforeRoute(args, ManageTimeOverviewEmployees),
		options: { transition: transition },
  },

	{
    path: '/customer-invoice_product/idc/:idc',
    async: (args) => beforeRoute(args, CustomerShop),
		options: { transition: transition },
  },

	{
    path: '/customer-my-purchases/idc/:idc',
    async: (args) => beforeRoute(args, CustomerMyPurchases),
		options: { transition: transition },
  },

	{
    path: '/customer-my-purchases/idc/:idc/tabInvoicesActive/:tabInvoicesActive',
    async: (args) => beforeRoute(args, CustomerMyPurchases),
		options: { transition: transition },
  },

	{
    path: '/customer-wod/idc/:idc',
    async: (args) => beforeRoute(args, CustomerWod),
		options: { transition: transition },
  },

	{
    path: '/customer-reporting/idc/:idc',
    async: (args) => beforeRoute(args, CustomerReporting),
		options: { transition: transition },
  },

	{
    path: '/customer-progress/idc/:idc',
    async: (args) => beforeRoute(args, CustomerProgress),
		options: { transition: transition },
  },

  {
		path: '(.*)',
    component: NotFound,
  }
];
