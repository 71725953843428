<template>
	<f7-list inset>
		<f7-list-item accordion-item v-for="(item, key, index) in appointments"
			:key="index"
			:title="`${$FunctionsHelper.specialChars(item.name)}${item.no_show && item.no_show == 1 ? ' (No Show)': ''}`"
			:footer="item.date_start"
			>

			<f7-accordion-content>
	      <f7-block>
					<div v-if="agendas && agendas.resultset.length > 0" class="item">{{$LanguageHelper.translate('_AGENDA_')}} {{$FunctionsHelper.specialChars(item.agenda_name)}}</div>
					<div v-if="item.id_partner > 0" class="item">{{$LanguageHelper.translate('_REGISTERED_PARTNER_')}} {{$FunctionsHelper.specialChars(item.username)}}</div>
					<div v-if="item.location > 0" class="item">{{$LanguageHelper.translate('_LOCATION_')}} {{$FunctionsHelper.specialChars(item.location)}}</div>
					<div class="item">{{$LanguageHelper.translate('_DATETIME_START_')}}:  {{`${item.time_start} ${$LanguageHelper.translate('_HOUR_')}`}}</div>
					<div class="item">{{$LanguageHelper.translate('_DATETIME_END_')}}:  {{`${item.time_end} ${$LanguageHelper.translate('_HOUR_')}`}}</div>
					<div class="item">{{$LanguageHelper.translate('_REGISTERED_WITH_')}}:  {{(item.product_name && item.product_name != '' ? $FunctionsHelper.specialChars(item.product_name) : '')}}</div>
					<div v-if="item.number_credits > 0" class="item">{{`${item.number_credits} ${item.number_credits > 1 ? $LanguageHelper.translate('_CREDITS_') : $LanguageHelper.translate('_CREDIT_')}`}} {{$LanguageHelper.translate('_CHARGED_')}}</div>
					<div v-else-if="item.installments !=1" class="item">{{$LanguageHelper.translate('_REGISTERED_FREE_')}}</div>
					<div v-if="item.no_show && item.no_show == 1" class="item">{{$LanguageHelper.translate('_NO_SHOW_REGISTRATION_')}}</div>
				</f7-block>
    	</f7-accordion-content>
		</f7-list-item>
	</f7-list>
</template>

<script>
export default {
	props: {
		idc: {required: true},
		id_customer: {required: true},
		pageEndReached: {type: Boolean, required: true, default: false},
	},

	data() {
		return {
			agendas: null,
			appointments: [],
			infiniteDone: false,
		}
	},

	methods: {
		_appointmentsInit(data) {
			this.appointments = data.resultset;
			this.agendas = data.agendas ? data.agendas : null;
		},

		_appointmentsInfinite(data) {
			if(data.resultset && data.resultset.length > 0) {
				this.appointments.push(...data.resultset);

				//Let our parent know to reset the page end status!
				this.$emit('page-end-loaded');
			} else this.infiniteDone = true;
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.appointments = [];
			}

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('agenda', 'appointmentsUser', {idc: this.idc, id_user: this.id_customer}, {callback: this[firstOrReset ? '_appointmentsInit' : '_appointmentsInfinite']});
		},

		destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('appointmentsUser');
		},
	},

	watch: {
		pageEndReached(val) {
			if(val) this.load();
		}
	},

	created() {
		this.load(true);
	}
};
</script>
