<template>
	<popup v-if="render" :title="$LanguageHelper.translate('_END_YEAR_BALANCES_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<f7-list media-list inset v-if="render">
			<list-item v-for="(item, key) in list" type="media"
				:key="`key_${key}`" 
				:title="item.date"
				:subtitle="`&euro; ${item.balance}`"
				>
			</list-item>
		</f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListItem from '../components/smart/list-item.vue';

export default {
	components: {
    'popup': Popup,
		'list-item': ListItem,
	},

	props: {
		idc: {required: true},
  },

	data() {
		return {
			list: [],
			render: false,
			opened: false	
		}
	},

	created() {
		$AjaxHelper.doRequest('payment', 'getEndYearBalances', {idc: this.idc}, {callback: (data) => {
			this.render = true;
			this.list = data.resultset;
		}});
	}
};
</script>

<style scoped>
table.balanceInfo {width:400px;}
</style>
