<template>
	<popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form v-if="render" :params="form" @submitted="submitted" @changed="changed" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		filters: {type: Object, required: false, default: null},
  },

	data() {
		return {
      render: false,
			opened: false,
			title: 'Filter',
			product_options: '',
			product_options_id: null,
			sort_options: '',
			sort_options_id: null,

			form: {
				fields: {
					product_options: {type: 'select', name: '', title: '', render: false, options: []},
          sort_options: {type: 'select', name: '', title: '', render: true, options: []},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		}
	},

	methods: {
		changed(field, option) {
			switch(field.name) {
				case 'productOption':
					if(option.value) this.product_options = option.value;
					if(option.id) this.product_options_id = option.id;
				break;

				case 'sortOption':
					if(option.value) this.sort_options = option.value;
					if(option.id) this.sort_options_id = option.id;
				break;
			}
		},

		submitted() {
			let data = [];
			data['product_options'] = this.product_options;
			data['product_options_id'] = this.product_options_id;
			data['sort_options'] = this.sort_options;
			data['sort_options_id'] = this.sort_options_id;

			this.$emit('submitted', data);
			this.opened = false;
		},

    setFilters() {
			if(this.filters.sort_options) {
				$.each(this.filters.sort_options.options, (index, obj) => {
					if(obj.selected == 1) this.sort_options = obj.systemName;
					if(obj.selected == 1) this.sort_options_id = obj.id;
					this.form.fields.sort_options.options.push({value: obj.systemName, label: obj.name, id: obj.id, selected: (obj.selected == 1 ? true : false)});
				});

				this.form.fields.sort_options.title = this.filters.sort_options.name;
				this.form.fields.sort_options.name = 'sortOption';
			}

			if(this.filters.product_options) {
				$.each(this.filters.product_options.options, (index, obj) => {
					if(obj.selected == 1) this.product_options = obj.systemName;
					if(obj.selected == 1) this.product_options_id = obj.id;
					this.form.fields.product_options.options.push({value: obj.systemName, label: obj.name, id: obj.id, selected: (obj.selected == 1 ? true : false)});
				});

				this.form.fields.product_options.title = this.filters.product_options.name;
				this.form.fields.product_options.name = 'productOption';
				this.form.fields.product_options.render = true;
			}

			this.render = true;
    },
	},

  created() {
		this.setFilters();
  },
};
</script>
