<template>
	<popup
		:title="$LanguageHelper.translate('_MANAGE_DIRECT_DEBIT_')"
		:toolbar="toolbar"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<template #directDebit v-if="toolbar.tabs.directDebit.active">
			<tab-direct-debit :idc="idc" :id="params.id" :fab="fab" :openedViaCustomer="openedViaCustomer" @changed="changed" />
		</template>

		<template #installments v-if="toolbar.tabs.installments.active">
			<tab-installments :idc="idc" :id="params.id_invoice_product_sale_group" />
		</template>
	</popup>
</template>

<script>
import Popup from '../../components/smart/popup.vue';
import TabDirectDebit from './tab-direct-debit.vue';
import TabInstallments from './tab-installments.vue';
import TabHistory from './tab-history.vue';

export default {
	components: {
    'popup': Popup,
    'tab-direct-debit': TabDirectDebit,
    'tab-installments': TabInstallments,
    'tab-history': TabHistory,
	},

	props: {
		idc: {required: true},
		openedViaCustomer: {required: false, default: false},
		params: {required: true},
  },

	data() {
    return {
			opened: false,

			// Floating action buttons
			fab: {
				// Name of tab this FAB object belongs to!
				info: {
					position: 'right-bottom',
					buttons: {
						// The button properties get set inside the tab component!
						edit: {label: '_CHANGE_SUBSCRIPTION_', icon: 'icon ionEdit', render: false, clicked: false},
						plan_activation: {label: '_PLAN_ACTIVATION_', icon: 'icon ionPlay', render: false, clicked: false},
						plan_deactivation: {label: '_PLAN_DEACTIVATION_', icon: 'ionPause', render: false, clicked: false},
					}
				}
			},

			// Tabs
			toolbar: {
				tabs: {
					directDebit: {text: '_INFO_', icon: 'icon ionInformationCircle', render: true, active: true},
					installments: {text: '_INSTALLMENTS_', icon: 'icon ionRefresh', render: true, active: false},
					/*history: {text: '_HISTORY_', icon: 'icon ionList', render: true, active: false},*/
				}
			}
    };
  },

	methods: {
		changed(id) {
			this.$emit('changed', id);

			// We need this timeout so other popup (editing direct debit) can close first!
			setTimeout(() => this.opened = false, 20);
		}
	},

	created() {
		// Store the FABs in the correct tab, we need those on our tab page and watch if they are clicked as well!
		this.toolbar.tabs.directDebit.fab = this.fab.info;
	}
};
</script>
