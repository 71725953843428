<template>
	<popup :title="`${$LanguageHelper.translate('_MESSAGE_')} ${number_reminder}`" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<div class="pageContent" v-if="render">
			<f7-card>
				<f7-card-header>{{reminder.output.title}}</f7-card-header>

				<f7-card-content>
					<div v-html="reminder.output.contents" />
				</f7-card-content>
			</f7-card>
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup,
	},

	props: {
		idc: {required: true},
		id_invoice: {required: true},
		number_reminder: {required: true},
  },

	data() {
    return {
			render: false,
			opened: false,
			reminder: {}
		};
  },

	methods: {
		_reminder(data) {
			this.reminder = data.resultset;
			this.render = true;
		},
	},

	created() {
		$AjaxHelper.doRequest('payment', 'getReminderMail', {idc: this.idc, id: this.id_invoice, number_reminder: this.number_reminder}, {callback: this._reminder});
	}
};
</script>
