import Bowser from 'bowser';

export default {
	uid() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
	},

	removeParameterFromURL(url, parameter) {
	  return url
	    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
	    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
	},

	getAllQueryParams(url) {
		if(!url) {
			return {};
		}

		// get query string from url (optional) or window
		var queryString = url.split('?')[1];

		// we'll store the parameters here
		var obj = {};

		// if query string exists
		if (queryString) {
			// stuff after # is not part of query string, so get rid of it
			queryString = queryString.split('#')[0];

			// split our query string into its component parts
			var arr = queryString.split('&');

			for (var i=0; i<arr.length; i++) {
				// separate the keys and the values
				var a = arr[i].split('=');

				// in case params look like: list[]=thing1&list[]=thing2
				var paramNum = undefined;
				var paramName = a[0].replace(/\[\d*\]/, function(v) {
					paramNum = v.slice(1,-1);

					return '';
				});

				// set parameter value (use 'true' if empty)
				var paramValue = typeof(a[1])==='undefined' ? true : a[1];

				// if parameter name already exists
				if (obj[paramName]) {
					// convert value to array (if still string)
					if (typeof obj[paramName] === 'string') {
					obj[paramName] = [obj[paramName]];
				}

				// if no array index number specified...
				if (typeof paramNum === 'undefined') {
					// put the value on the end of the array
					obj[paramName].push(paramValue);
				}
				// if array index number specified...
				else {
					// put the value at that index number
					obj[paramName][paramNum] = paramValue;
				}
			}	else {
					obj[paramName] = paramValue;
				}
			}
		}

		return obj;
	},

	specialChars(str) {
  	let textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  },

	nl2br(str) {
		return str.replace(/\n/g,"<br>");
	},

	ucFirst(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	},

	lcFirst(str) {
		return str.charAt(0).toLowerCase() + str.slice(1);
	},

	mobileToWhatsApp(number, countryID = null) {
		let mobile = number.replace(/ +?/g, '').replace(/\(+?/g, '').replace(/\)+?/g, '').replace(/\-+?/g, '');

		let countryCode = '';
		if(mobile.substring(0, 1) == '+') countryCode = mobile.substring(1, 3);
		if(mobile.substring(0, 2) == '00') countryCode = mobile.substring(2, 4);

		mobile = mobile.replace(/^00/, '').replace(/^\+/, '');

		// If country code in number, take it of
		if(countryCode.length > 0) mobile = mobile.substring(countryCode.length, mobile.length);

		// Get country code based on country id if it was not in the phone number
		else if(countryID && countryID > 0) countryCode = $LanguageHelper.getPhoneCountryCode(countryID);

		// See if we can identify the country in the number itself, last resort!
		else {
			if(mobile.substring(0, 2) == '06') {
				countryCode = 31;
			} else if(mobile.substring(0, 2) == '04') {
				countryCode = 32;
			}
		}

		// Strip of first zero!
		mobile = mobile.replace(/^0/, '').replace(/^\+/, '');

		if(countryCode.length == '') return ''

		// Return valid whatsapp number here!
		return `+${countryCode}${mobile}`;
	},

	isAndroid() {
		return /Android/i.test(navigator.userAgent);
	},

	isIOS() {
		const systemInfo = Bowser.parse(window.navigator.userAgent);
		return (systemInfo.browser.name.toLowerCase() == 'safari' || systemInfo.os.name.toLowerCase() == 'ios');
	},

	isAndroidInChrome() {
		return $FunctionsHelper.isAndroid() && FunctionsHelper.isChrome();
	},

	colors: {
		getRGBArrayFromString(str) {
			var str = str.replace(/\s/g,'');
			var match = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.?\d?))\))?/);
			return match ? [
				match[1],
				match[2],
				match[3],
				match[4],
			] : [];
		}
	},
}
