<template>
	<popup :title="$LanguageHelper.translate('_DISCOUNT_CODE_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import FormCheckboxGroupAction from '../js/mixins/formCheckboxGroupAction';
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	mixins: [FormCheckboxGroupAction],

	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id_discount_code: {required: false},
  },

	data() {
    return {
			opened: false,

			form: {
				fields: [],

				submit: {text: '_SAVE_', render: false},
				values: {},
			}
		};
  },

	methods: {
		changed(el, action) {
			// We need to loop this because our fields are in an array and we don't know the index number!
			this.form.fields.forEach((field) => {
				if(field.name == 'id_invoice_product') this.handleCheckboxGroupAction(el, field.items, action);
			});
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			if(this.id_discount_code) data.id_discount_code = this.id_discount_code;
			$AjaxHelper.doRequest('invoiceProduct', 'saveDiscountCode', Object.assign({idc: this.idc}, data), {callback: this._saved});
		},

		renderForm(discountProductOptions) {
			if(!discountProductOptions.invoice_products || discountProductOptions.invoice_products.length == 0) return;

			this.form.fields.push({name:'code', type: 'input', label: '_CODE_', placeholder: '_ENTER_HERE_', render: true, value: ''});
			this.form.fields.push({name: 'amount', type: 'input', label: '_DISCOUNT_AMOUNT_', placeholder: '_ENTER_HERE_', render: true, value: ''});
			this.form.fields.push({name: 'percentage', type: 'input', label: '_DISCOUNT_PERCENTAGE_', placeholder: '_ENTER_HERE_', render: true, value: ''});

			// Set the divider with the info contents to explain the different settings!
			let info = `
			${$LanguageHelper.translate('_MAX_TIMES_PER_ORDER_INFO_')}
			<br /><br />
			${$LanguageHelper.translate('_MAX_NUMBER_PER_ORDER_INFO_')}
			<br /><br />
			${$LanguageHelper.translate('_MAX_ORDERS_PER_USER_INFO_')}
			<br /><br />
			${$LanguageHelper.translate('_MIN_PRODUCTS_IN_CART_INFO_')}
			`;

			this.form.fields.push({type: 'divider', title: '_DISCOUNT_OPTIONS_', info: info, render: true});

			// Set select options here (max_times_per_order, max_number_per_order, max_orders_per_user, min_products_in_cart)
			let optionsMaxTimesPerOrder = [];
			optionsMaxTimesPerOrder.push({value: 0, selected: true, label: $LanguageHelper.translate('_VALID_UNLIMITED_')});
			for(var i = 1; i <= 10; i ++) optionsMaxTimesPerOrder.push({value: i, label: i, selected: false});
			this.form.fields.push({name: 'max_times_per_order', type: 'select', title: '_MAX_TIMES_PER_ORDER_', render: true, options: optionsMaxTimesPerOrder});

			let optionsMaxNumberPerOrder = [];
			optionsMaxNumberPerOrder.push({value: 0, selected: true, label: $LanguageHelper.translate('_VALID_UNLIMITED_')});
			for(var i = 1; i <= 10; i ++) optionsMaxNumberPerOrder.push({value: i, label: i, selected: false});
			this.form.fields.push({name: 'max_number_per_order', type: 'select', title: '_MAX_NUMBER_PER_ORDER_', render: true, options: optionsMaxNumberPerOrder});

			let optionsMaxOrdersPerUser = [];
			optionsMaxOrdersPerUser.push({value: 0, selected: true, label: $LanguageHelper.translate('_VALID_UNLIMITED_')});
			for(var i = 1; i <= 10; i ++) optionsMaxOrdersPerUser.push({value: i, label: i, selected: false});
			this.form.fields.push({name: 'max_orders_per_user', type: 'select', title: '_MAX_ORDERS_PER_USER_', render: true, options: optionsMaxOrdersPerUser});

			let optionsMinProductsInCart = [];
			optionsMinProductsInCart.push({value: 0, selected: true, label: $LanguageHelper.translate('_VALID_UNLIMITED_')});
			for(var i = 1; i <= 10; i ++) optionsMinProductsInCart.push({value: i, label: i, selected: i == 1});
			this.form.fields.push({name: 'min_products_in_cart', type: 'select', title: '_MIN_PRODUCTS_IN_CART_', render: true, options: optionsMinProductsInCart});

			this.form.fields.push({name: 'valid_from', type: 'date', label: '_VALID_FROM_', placeholder: '_SELECT_DATE_', render: true, value: ''});
			this.form.fields.push({name: 'valid_until', type: 'date', label: `${$LanguageHelper.translate('_VALID_UNTIL_')} (${$LanguageHelper.translate('_EMPTY_IS_UNLIMITED_')})`, placeholder: '_SELECT_DATE_', render: true, value: ''});
			this.form.fields.push({name: 'available_for_products', type: 'divider', title: '_AVAILABLE_FOR_FOLLOWING_PRODUCTS_', render: true});

			// Create the product type with product checkbox fields
			let items = [];

			discountProductOptions.invoice_products.forEach((item, index) => {
				// Product types
				var productType = {
					title: item.label,
					ref: index, // Mandatory field for handleCheckboxGroupAction to work
					level: 1, // Mandatory field for handleCheckboxGroupAction to work
					checked: false, // Mandatory field for handleCheckboxGroupAction to work
					toggled: false, // Mandatory field for handleCheckboxGroupAction to work
					class: 'themeLink',
					render: true
				};

				items.push(productType);

				// Products
				item.products.forEach((product) => {
					// If product is selected, make sure toggled and checked are set to true on level 1 item!
					if(product.selected == 1) {
						productType.checked = true;
						productType.toggled = true;
					}

					items.push({
						ref: index, // Mandatory field for handleCheckboxGroupAction to work
						level: 2, // Mandatory field for handleCheckboxGroupAction to work
						checked: product.selected == 1, // Mandatory field for handleCheckboxGroupAction to work
						title: product.name,
						value: product.id_invoice_product,
						render: item.selected && item.selected == 1,
					});
				});
			});

			// Render the grouped checkboxes here
			this.form.fields.push({name: 'id_invoice_product', type: 'checkbox_group', items: items, render: true});

			this.form.submit.render = true;
		},

		_new(data) {
			// Render the form
			this.renderForm(data.discountOptions);
		},

		_discountCode(data) {
			// Set values
			this.form.values = data.resultset;

			// Render the form
			this.renderForm(data.discountOptions);
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Let our parent know there has been changes so it reloads the list!
				this.$emit('changed');

				// Close popup here, were done!
				this.opened = false;
			}
		}
	},

	created() {
		if(this.id_discount_code)  {
			$AjaxHelper.doRequest('invoiceProduct', 'discountCode', {idc: this.idc, id_discount_code: this.id_discount_code}, {callback: this._discountCode});
		} else {
			$AjaxHelper.doRequest('invoiceProduct', 'discountOptions', {idc: this.idc, id_discount_code: this.id_discount_code}, {callback: this._new});
		}
	}
};
</script>
