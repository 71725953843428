<template>
  <page :title="$LanguageHelper.translate('_COMPANY_STYLES_')" :toolbar="toolbar" @initiated="go">
		<template #styles v-if="toolbar.tabs.styles && toolbar.tabs.styles.active">
			<tab-styles :idc="idc"></tab-styles>
		</template>

		<template #images v-if="toolbar.tabs.images && toolbar.tabs.images.active">
			<tab-images :idc="idc"></tab-images>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabStyles from './tab-styles.vue';
import TabImages from './tab-images.vue';

export default {
  components: {
    'page': Page,
    'tab-styles': TabStyles,
    'tab-images': TabImages
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			toolbar: {
				tabs:{
				styles: {text: $LanguageHelper.translate('_STYLES_'), icon: 'icon ionColorPalette', render: $UserHelper.hasAccess('companySettings', 'change', this.idc), active: false},
				images: {text: $LanguageHelper.translate('_IMAGES_'), icon: 'icon ionImages', render: $UserHelper.hasAccess('files', null, this.idc), active: false}
			}}
    };
  },

	methods: {
		go() {
			if(this.toolbar.tabs.styles.render) this.toolbar.tabs.styles.active = true;
			else if(this.toolbar.tabs.images.render) this.toolbar.tabs.images.active = true;
		}
	}
};
</script>
