<template>
  <page :title="$LanguageHelper.translate('_REMOVE_ACCOUNT_')">
    <f7-card inset>
      <f7-card-content>
        <div class="nl2br">{{$LanguageHelper.translate('_REMOVE_ACCOUNT_INFO_')}}</div>

        <div class="marginTop20">
          <f7-button fill raised small @click="removeAccount" class="red">{{$LanguageHelper.translate('_REMOVE_MY_ACCOUNT_')}}</f7-button>
        </div>
      </f7-card-content>
    </f7-card>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },
  data() {
    return {
      companies: [],
    };
  },

  methods: {
    removeAccount() {
      $ViewHelper.confirm({method: () => {
          $AjaxHelper.doRequest('user', 'removeMe', {}, {callback: (data) => {
            if(data.status == 'OK') {
              // Log user out!
              $UserHelper.logout();

              // Send user to login new account page
              return $ViewHelper.router.navigate(`/login-new-account/`, { animate: false });
            }
          }});
      }}, $LanguageHelper.translate('_ARE_YOU_SURE_'), $LanguageHelper.translate('_REMOVE_ACCOUNT_CONFIRM_'));
    },
  },
};
</script>
