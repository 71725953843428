<template>
  <div v-if="render">
    <f7-list inset media-list>
      <!-- Select linked to customer or not-->
      <f7-list-item>
        <select class="regular" @change="selectLinked($event.target.value)">
          <option value="0">{{$LanguageHelper.translate('_LINK_TO_CUSTOMER_CHOOSE_AN_OPTION_')}}</option>
          <option value="1" :selected="linked == 1">{{$LanguageHelper.translate('_PAYMENT_LINK_TO_CUSTOMER_')}}</option>
          <option value="2">{{$LanguageHelper.translate('_PAYMENT_NOT_LINK_TO_CUSTOMER_')}}</option>
        </select>
      </f7-list-item>

      <!-- Autocomplete customer-->
      <template v-if="showSearchCustomer">
        <template v-if="showAutocompleteCustomer">
          <li class="item-content item-input">
            <list-autocomplete-form-field
              :label="$LanguageHelper.translate('_SEARCH_CUSTOMER_')"
              :placeholder="$LanguageHelper.translate('_NAME_')"
              :val="customerSearchedValue"
              @changed="customerSearchedValue=$event"
              @searched="customerSearched"
            />
          </li>

          <!-- Autocomplete result-->
          <template v-for="(customer, index) in customers">
             <f7-list-item v-if="customers.length > 0" class="autocompleteResult pointer"
              :key="index"
              :title="customer.name"
              :text="customer.email"
              @click="customerSelected(customer)"
            />
          </template>
        </template>

        <!-- Chosen customer result-->
        <f7-list-item v-if="chosenCustomer">
          <span class="customerSelected">{{chosenCustomer}}<f7-link class="link theme action" @click="removeCustomer" :title="$LanguageHelper.translate('_DELETE_')"><f7-icon class="icon ionTrash"></f7-icon></f7-link></span>
        </f7-list-item>
      </template>

      <!-- Autocomplete product-->
      <template v-if="showSearchProduct">
        <li class="item-content item-input">
          <list-autocomplete-form-field
            :label="$LanguageHelper.translate('_SEARCH_A_PRODUCT_')"
            :placeholder="$LanguageHelper.translate('_PRODUCT_')"
            :val="searchedItemValue"
            @changed="searchedItemValue=$event"
            @searched="productSearched"
          />
        </li>

        <!-- Autocomplete product items result-->
        <template v-for="(product, index) in products">
          <f7-list-item v-if="products.length > 0" class="autocompleteResult pointer"
            :key="index"
            :title="$FunctionsHelper.specialChars(product.name)"
            @click="productSelected(product)"
          />
        </template>
      </template>
    </f7-list>

    <!-- shoppingCart-->
    <div v-if="showCart">
      <shopping-cart
        :idc="idc"
        :outsideCartData="registerCart"
				:title="$LanguageHelper.translate('_SELECTED_PRODUCTS_')"
        @removeItemClicked="removeShoppingCartItemClicked"
        @changed="shoppingCartChanged"
      />
    </div>

    <!-- select paymentMethod-->
    <f7-list v-if="showPaymentMethod" inset class="noMarginTop">
      <f7-list-item  divider>{{$LanguageHelper.translate('_PAYMENT_METHOD_')}}</f7-list-item>
      <f7-list-item>
        <select class="regular" @change="selectPaymentMethod($event.target.value)">
          <option value="">{{$LanguageHelper.translate('_CHOSE_AN_OPTION_')}}</option>
          <option v-if="pinEnabled" value="pin">{{$LanguageHelper.translate('_PIN_')}}</option>
          <option value="cash">{{$LanguageHelper.translate('_CASH_')}}</option>
          <option value="invoice">{{$LanguageHelper.translate('_SEND_INVOICE_')}}</option>
        </select>
      </f7-list-item>
    </f7-list>

    <!-- If paymentMethod is cash show calculator-->
    <f7-card inset v-if="showCalculator">
      <f7-card-header>{{$LanguageHelper.translate('_CALCULATE_')}}</f7-card-header>
      <f7-card-content>
        <f7-block class="marginTop5 marginBottom10">
          <f7-row class="marginBottom10">
            <f7-col width="50"><b>{{$LanguageHelper.translate('_AMOUNT_')}}</b></f7-col>
            <f7-col width="50"><b>{{$LanguageHelper.translate('_GET_AMOUNT_')}}</b></f7-col>
          </f7-row>

          <f7-row>
            <f7-col width="50">€ {{registerCart.total_amount_in_vat}}</f7-col>
            <f7-col width="50">
              <f7-input type="text" placeholder="0.00" @input="amount=$event.target.value" class="inputReceivedCash" />
            </f7-col>
          </f7-row>

          <f7-row class="marginTop20 marginBottom10">
            <f7-col width="50">{{$LanguageHelper.translate('_RETURN_AMOUNT_')}}: € {{returnAmount}}</f7-col>
            <f7-col width="50">
              <f7-link @click="calculateAmount" class="theme">{{$LanguageHelper.translate('_CALCULATE_')}}</f7-link>
            </f7-col>
          </f7-row>
				</f7-block>
      </f7-card-content>
    </f7-card>

    <!-- Show form fields based on chosen paymentmethod -->
    <list-form :params="form" @changed="changed" />

    <!-- Checkout payment button -->
    <f7-block v-if="showButton">
			<f7-button fill raised @click="submittedClicked">{{$LanguageHelper.translate('_SEND_')}}</f7-button>
		</f7-block>
  </div>
</template>

<script>
import ListAutocompleteFormField from '../../components/smart/list-autocomplete-form-field.vue';
import ShoppingCart from '../../components/shopping-cart.vue';
import ListForm from '../../components/smart/list-form.vue';

export default {
  components: {
		'list-autocomplete-form-field': ListAutocompleteFormField,
    'shopping-cart': ShoppingCart,
    'list-form': ListForm,
	},

  props: {
		idc: {required: true}
	},

  data() {
    return {
			render: false,
      pinEnabled: false,
      showCart: false,
      showSearchProduct: false,
      showPaymentMethod: false,
      showCalculator: false,
      showSearchCustomer: false,
      showAutocompleteCustomer: false,
			showButton: false,
			linked: 0, // Linked to customer?
      products: [], // From search result
      customers: [], // From search result
      registerCart: {}, // Cart data
      amount: 0.00, // For cash payments
      returnAmount: 0.00, // For cash payments
      searchedItemValue: '', // Search for products with this value
      customerSearchedValue: '', // Search for customers with this value
      terminal: '', // Unique terminal name
      paymentMethod: '', // Payment method selected
      chosenCustomer: '', // Customer name selected from autocomplete search
      id_country: 1,
      id_language: 1,
      id_user: null, // Connected user id

      form: {
        fields: {
          form_description: {name: 'form_description', type: 'description', text: '_CUSTOMER_DATA_', class: 'bold', render: false},
          company: {name: 'company', type: 'input', label: '_COMPANY_NAME_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          vat_number: {name: 'vat_number', type: 'input', label: '_VAT_NUMBER_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          firstname: {name: 'firstname', type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          lastname: {name: 'lastname', type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          email: {name: 'email', type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          street: {name: 'street', type: 'input', label: '_STREET_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          housenumber: {name: 'housenumber', type: 'input', label: '_HOUSENUMBER_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          zipcode: {name: 'zipcode', type: 'input', label: '_ZIPCODE_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          city: {name: 'city', type: 'input', label: '_CITY_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          id_country: {name: 'id_country', type: 'select', title: '_COUNTRY_', options: [], render: false, optional: true},
          id_language: {name: 'id_language', type: 'select', title: '_INVOICE_LANGUAGE_', options: [], render: false, optional: true},
          iban: {name: 'iban', type: 'input', label: '_IBAN_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          description_invoice: {name: 'description_invoice', type: 'input', label: '_INVOICE_DESCRIPTION_', placeholder: '_ENTER_HERE_', render: false, value: '', optional: true},
          message: {name: 'description', type: 'description', text: '_INVOICE_MESSAGE_', render: false, optional: true},
          terminal: {name: 'terminal', type: 'select', title: '_SELECT_DEVICE_', options: [], render: false, optional: true},
        },

        values: {},
      }
    };
  },

  methods: {
    // Set fields for linked or not linked customer
    selectLinked(val) {
			this.setDefaults();
			this.emptyForm();

			switch (val) {
        // First option
        case '0':
          this.linked = 0;
        break;

        // Linked to customer
        case '1':
          this.linked = 1;
          this.showSearchCustomer = true;
          this.showAutocompleteCustomer = true;
        break;

        // Not linked
        case '2':
          this.linked = 0;
					this.showSearchCustomer = false;
					this.showAutocompleteCustomer = false;
          this.showSearchProduct = true;
        break;
      }
    },

    // Handle changes fields in form
    changed(field, option) {
      switch (field.name) {
        case 'id_country':
          this.id_country = option.value;
        break;

				case 'id_language':
          this.id_language = option.value;
        break;

				case 'terminal':
					this.terminal = option.value;
        break;
      }
    },

    selectPaymentMethod(val) {
			this.hideFormFields();

			// Stop here if nothing was selected
			if(val == '') return;

			this.paymentMethod = val;
			Object.values(this.form.fields).forEach((field) => {
				field.render = true;

				if(this.linked == 0) {
					// Disable non needed fields
					if(val != 'invoice' && field.optional) {
						field.render = false;
						field.placeholder = '_NOT_REQUIRED_';
					}

					if(field.name == 'form_description') field.text = '_INVOICE_EMAIL_DESIRABLE_';
					if(field.name == 'description_invoice') field.text = '_DESCRIPTION_RECEIPT_';
				}
			});

			// Show or hide pin terminal field here
			this.form.fields.terminal.render = val == 'pin' && this.form.fields.terminal.options.length > 1;

			// Only show the message field when payment method invoice was selected
			this.form.fields.message.render = val == 'invoice';

			// Set a different description for invoice payment
			if(val == 'invoice') this.form.fields.form_description.text = '_CUSTOMER_DATA_';

			// Show installment message
			if(this.registerCart.has_installment_product && this.registerCart.has_installment_product == 1) {
				this.form.fields.message.render = true;
				this.form.fields.message.text = '_INVOICE_MESSAGE_INSTALLMENT_';
			}

			// Show the calculator if we handle cash
			this.showCalculator = val == 'cash';

			// Show the button
			this.showButton = true;
    },

    customerSearched() {
      if(this.customerSearchedValue.length > 0) $AjaxHelper.doRequest('Payment', 'getUsersByNameForPIN', {idc: this.idc, name: this.customerSearchedValue, linked: this.linked}, {callback: this._customerSearched});
      // Empty list customers if autocomplete is empty
      else this.customers = [];
    },

    customerSelected(data) {
      //set data
      this.chosenCustomer = `${data.firstname} ${data.lastname} (${data.email})`;
      this.id_user = data.id_user;

      // Empty searched customer variables, otherwise it will loop
      this.customerSearchedValue = '';
      this.customers = [];

      // Fetch records + shoppingcart for selected customer
      $AjaxHelper.doRequest('Payment', 'registerPayment', {idc: this.idc, id_user: this.id_user, action: 'get-user'}, {callback: this._fetchedCustomer});
    },

    removeCustomer() {
			this.setDefaults();
			this.emptyForm();

			// Make customer fields visible again
			this.showAutocompleteCustomer = true;
			this.showSearchCustomer = true;
    },

    productSearched() {
      if(this.searchedItemValue.length > 0) $AjaxHelper.doRequest('Payment', 'searchProductsForPIN', {idc: this.idc, name: this.searchedItemValue, linked: this.linked}, {callback: this._productSearched});
			else this.products = [];
    },

    productSelected(data) {
      if(data) $AjaxHelper.doRequest('Payment', 'registerPayment', {idc: this.idc, action: 'add-to-cart', cartAction: 1, id_invoice_product: data.id_product, id_user: this.id_user, link_to_customer: this.linked}, {callback: this._updateCart});
    },

    removeShoppingCartItemClicked(id) {
      $AjaxHelper.doRequest('Payment', 'registerPayment', {idc: this.idc, action: 'remove-from-cart', cartAction: 1, id_invoice_product: id, id_user: this.id_user, link_to_customer: this.linked}, {callback: this._updateCart});
    },

    shoppingCartChanged(data) {
			$AjaxHelper.doRequest('Payment', 'registerPayment', {idc: this.idc, action: 'update-cart', cartAction: 1, id_invoice_product: data, id_user: this.id_user, link_to_customer: this.linked}, {callback: this._updateCart});
    },

    calculateAmount(val) {
      if(this.amount) {
        let amount = this.amount.replace('.', '').replace(',', '.');

  			let totalAmount = this.registerCart.total_amount_in_vat.replace('.', '').replace(',', '.');
        if($.isNumeric(amount) == false) {
  				this.amount = 0.00;
  				return $ViewHelper.alert($LanguageHelper.translate('_ENTER_VALID_AMOUNT_'));
  			}

  			this.returnAmount = amount - totalAmount;
  			this.returnAmount = this.returnAmount.toFixed(2);

        let lang = $LanguageHelper.getCurrentLanguageObj();
  			if(lang.code == 'nl_NL') this.returnAmount = this.returnAmount.replace('.', ',');
      }
    },

    submittedClicked(action) {
			// Default data
      let data = {
				payment_method: this.paymentMethod,
				action: action && action.length > 0 ? action : 'register-payment',
				link_to_customer: this.linked,
				invoice: {}, // Not connected
				connected: {}, // Connected
				id_invoice_product: {}
			};

			// Set product id's with number of items
			$.each(this.registerCart.products, (index, obj) => {
				data.id_invoice_product[obj.id_invoice_product] = obj.number_items;
      });

			let dataKey = this.linked == 1 ? 'connected' : 'invoice'; // Connected to a customer or just invoice data
			$.each(this.form.fields, (fieldName, obj) => {
				if(fieldName == 'description_invoice') data.description_invoice = obj.value ? obj.value : '';
				else if(obj.render && obj.hasOwnProperty('value')) data[dataKey][fieldName] = obj.value;
			});

			// Set country and language id's
			data[dataKey]['id_country'] = this.id_country;
			data[dataKey]['id_language'] = this.id_language;

			// Set the terminal used for payment
			if(this.paymentMethod == 'pin') data.terminal = this.terminal;

			// Set the user id, has to be send en though it's empty!
			data.id_user = this.id_user;

			// Ugly hack but we need this field in the back-end
      if(!data.connected.email) data.connected['email'] = '';

			$AjaxHelper.doRequest('Payment', 'registerPayment', Object.assign({idc: this.idc}, data), {callback: this._paymentSend});
    },

    setCountries(data) {
			this.form.fields.id_country.options = [];
      $.each(data, (index, obj) => {
        this.form.fields.id_country.options.push(obj);
      });
    },

    setLanguages(data) {
			this.form.fields.id_language.options = [];
      $.each(data, (index, obj) => {
        this.form.fields.id_language.options.push(obj);
      });
    },

    setTerminals(terminals) {
			$.each(terminals, (index, terminal) => {
				if(terminal.main == 1 && this.terminal.length == 0) this.terminal = terminal.terminal_id;

				if(terminals.length > 1) {
					this.form.fields.terminal.options.push({
	          name: terminal.name,
	          label: terminal.name,
	          value: terminal.terminal_id,
	          selected: (terminal.main == 1 ? true : false),
	        });
				}
      });
    },

    setDefaults() {
      this.id_user = null;
			this.searchedItemValue = '';
      this.customerSearchedValue = '',
			this.chosenCustomer = '';
      this.products = [];
      this.customers = [];
      this.registerCart = {};
			this.amount = 0.00;
			this.returnAmount = 0.00;
			this.terminal = '';
			this.paymentMethod = '';
    },

    emptyForm() {
      this.hideFormFields();
			this.showAutocompleteCustomer = false;
			this.showSearchCustomer = false;
			this.showSearchProduct = false;
      this.showCart = false;
      this.showPaymentMethod = false;
      this.showCalculator = false;
      this.showButton = false;
    },

		hideFormFields() {
			$.each(this.form.fields, (fieldName, obj) => {
        obj.render = false;
      });
		},

		pinPollStatus(orderID, th) {
			$ViewHelper.preloader.dialog.show($LanguageHelper.translate('_WAITING_FOR_TRANSACTION_'));

			let numberRequests = 0;
			let interval = setInterval(() => {
				numberRequests ++;
				$AjaxHelper.doRequest('Payment', 'pinCheckStatus', {th: th, numberRequests: numberRequests}, {hideIndicator: true, callback: (dataStatusPIN) => {
					if(dataStatusPIN.done == 1) {
						// Stop the interval
						clearInterval(interval);

						// Hide indicator
						$ViewHelper.preloader.dialog.hide();

						if(dataStatusPIN.state == 'error' || dataStatusPIN.state == 'expired' || dataStatusPIN.state == 'cancelled') {
							$AjaxHelper.doRequest('Payment', 'removeOrderPIN', {idc: this.idc, oid: orderID}, {callback: () => {}});
						}

						// Store message
						let message = ''
						if(dataStatusPIN.state == 'error') message = $LanguageHelper.translate('_TRANSACTIONS_ERROR_');
						if(dataStatusPIN.state == 'expired') message = $LanguageHelper.translate('_TRANSACTIONS_EXPIRED_');
						if(dataStatusPIN.state == 'cancelled') message = $LanguageHelper.translate('_TRANSACTIONS_CANCELLED_');
						if(dataStatusPIN.state == 'approved') message = $LanguageHelper.translate('_TRANSACTIONS_SUCCESSFUL_');

						// Show message
						if(message.length > 0) $ViewHelper.alert(message, $LanguageHelper.translate('_NOTICE_'));

						// Payment ok? Reset form again!
						if(dataStatusPIN.state == 'approved') this.paymentOK();
					}
				}});
			}, 4000);
		},

    _paymentSend(data) {
			if(data.status == 'OK') {
				// We need to poll pin status
				if(data.resultset.send_to_terminal && data.resultset.th) {
					this.pinPollStatus(data.resultset.id_invoice_order, data.resultset.th);
				} else {
					// Everything went fine
					this.paymentOK();
				}
      }
    },

		paymentOK() {
			// Go back to initial state
			this.setDefaults();
			this.emptyForm();
			this.init();
		},

		init() {
      $AjaxHelper.doRequest('Payment', 'registerPayment', {idc: this.idc, action: 'init'}, {callback: (data) => {
				if(data.resultset) {
					this.pinEnabled = data.resultset.has_pin_device == 1;
	        this.setCountries(data.resultset.countries);
	        this.setLanguages(data.resultset.languages);

					this.setDefaults();
					this.emptyForm();

					this.linked = 0;
	        this.render = true;
	      }
			}});
    },

    _customerSearched(data) {
      if(data.resultset) this.customers = data.resultset;
    },

    _fetchedCustomer(data) {
      this.registerCart = {};

			if(data.resultset) {
				// Check for PIN terminals
        if(this.pinEnabled && data.resultset.terminals && this.form.fields.terminal.options.length == 0) this.setTerminals(data.resultset.terminals);

				this.showAutocompleteCustomer = false;
				this.showSearchProduct = true;

        // Fill in user values
        $.each(this.form.fields, (fieldName, obj) => {
          $.each(data.resultset.user, (index, item) => {
            if(fieldName == index) obj.value = item;
          });
        });

				// Set the right country value
        $.each(this.form.fields.id_country.options, (index, option) => {
          option.selected = false;
          if(option.value == data.resultset.user.id_country) {
						option.selected = true;
					}
        });

				if(data.resultset.user.id_country) this.id_country = data.resultset.user.id_country;

				// Handle cart values
				this.registerCart = data.resultset.shoppingCart;
        if(data.resultset.shoppingCart.products.length > 0) {
          this.showCart = true;
          this.showPaymentMethod = true;
        }
      }
    },

    _productSearched(data) {
      if(data.resultset) this.products = data.resultset;
    },

    _updateCart(data) {
      if(data.resultset) {
				// Empty product search values
				this.products = [];
				this.searchedItemValue = '';

				// Fill cart data
				this.registerCart = data.resultset.shoppingCart;

				if(data.resultset.shoppingCart.products.length > 0) {
          // Check for PIN terminals
          if(this.pinEnabled && data.resultset.terminals && this.form.fields.terminal.options.length == 0) this.setTerminals(data.resultset.terminals);

          this.form.fields.message.text = '_INVOICE_MESSAGE_';
          if(this.registerCart.has_installment_product && this.registerCart.has_installment_product == 1) {
            this.form.fields.message.text = '_INVOICE_MESSAGE_INSTALLMENT_';
          }

          this.showCart = true;
          this.showPaymentMethod = true;
          this.showButton = true;
        }
				// No items in cart, set defaults
				else {
					this.hideFormFields();

					this.paymentMethod = '';
					this.showCalculator = false;
					this.showCart = false;
					this.showPaymentMethod = false;
          this.showButton = false;
        }
      }
    }
  },

  created() {
    this.init();
  }
};
</script>

<style scoped>
.customerSelected {font-size: 15px;}
</style>

<style>
.inputReceivedCash input[type="text"] {padding-bottom: 5px;}
</style>
