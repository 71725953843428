<template>
  <div v-show="visible">
    <f7-toolbar position="top" class="toolbarTop agenda backgroundColorWhite no-shadow">
      <!-- Clickable date  and navigation -->
      <div class="wrapperPeriod">
        <table class="center">
          <tr>
            <td class="arrow"><a @click.prevent="getWOD(pagination.prev)"><i v-if="pagination.prev" class="icon ionArrowDropLeft" /></a></td>
            <td class="titleTD"><span class="title">{{wod.date_wod}}</span></td>
            <td class="arrow"><a @click.prevent="getWOD(pagination.next)"><i v-if="pagination.next" class="icon ionArrowDropRight" /></a></td>
          </tr>
        </table>
      </div>
    </f7-toolbar>

    <list-form v-if="showSwitch" :params="form" @changed="changed" class="marginTop65"></list-form>
    <f7-card inset :class="{marginTop65: !showSwitch}">
      <f7-card-header>{{wod.name}}</f7-card-header>
      <f7-card-content>
        <f7-block class="marginTop5 paddingBottom20">
          <div class="item" v-html="wod.description"></div>
        </f7-block>

        <f7-block v-if="wod.youtube" class="paddingBottom20">
          <div class="wrapperYouTube">
            <div class="videoContainerResponsive">
        			<iframe :src="`https://youtube.com/embed/${wod.youtube}`" frameborder="0" allowfullscreen=""></iframe>
        		</div>
          </div>
        </f7-block>

        <f7-block v-if="appuserResult && appuserResult.comment" class="paddingBottom20">
          <div class="item"><b>{{$LanguageHelper.translate('_YOUR_COMMENT_')}}</b></div>
          <div class="item" v-html="appuserResult.comment"></div>
        </f7-block>

        <f7-block v-if="wod.number_results" class="paddingBottom20">
          <f7-link @click="$emit('loadRanking', IDWod)">{{$LanguageHelper.translate('_THERE_ARE_')}} {{wod.number_results}} {{$LanguageHelper.translate('_RESULTS_')}} ...</f7-link>
        </f7-block>

        <f7-block v-if="wod.history && wod.history.length > 1" class="paddingBottom20">
          <f7-link @click="toggleHistory">{{$LanguageHelper.translate('_VIEW_HISTORY_')}}</f7-link>
          <div v-if="viewHistory">
            <f7-row v-for="(result, index) in wod.history" :key="index">
              <f7-col width="40">
                <div>{{result.date_wod}}</div>
              </f7-col>
              <f7-col width="60">
                <f7-link @click="getWOD(result.id_wod_whiteboard)">{{$LanguageHelper.translate('_VIEW_WOD_')}}</f7-link>
              </f7-col>
            </f7-row>
          </div>
        </f7-block>

        <f7-block v-if="wod.benchmark && wod.benchmark.results && wod.benchmark.results.length > 0" class="paddingBottom20">
          <f7-link @click="toggleBenchmark">{{$LanguageHelper.translate('_VIEW_BENCHMARK_')}} ...</f7-link>
          <div v-if="viewBenchmark">
            <div class="item">{{$LanguageHelper.translate('_YOUR_PERSONAL_RECORD_')}}: {{wod.benchmark.pr}}</div>
            <f7-row v-for="(result, index) in wod.benchmark.results" :key="index" no-gap>
              <f7-col width="40">
                <div>{{result.date_wod}}</div>
              </f7-col>
              <f7-col width="60">
                <f7-link v-if="wod.benchmark.results.length > 1" @click="getWOD(result.id_wod_whiteboard)">{{result.score}} {{result.type}}</f7-link>
                <div v-else>{{result.score}} {{result.type}}</div>
              </f7-col>
            </f7-row>
          </div>
        </f7-block>

        <template v-if="wod.has_next_whiteboard == 0">
          <f7-block v-if="wod.logging_expired == 0" class="marginTop10 marginBottom10">
            <f7-row v-if="wod.athlete_log_not_allowed == 0">
              <f7-col>
                <f7-button @click="logResult(wod.buy_product)" raised fill small>{{$LanguageHelper.translate('_LOG_YOUR_RESULT_')}}</f7-button>
              </f7-col>

              <f7-col v-if="appuserResult != null">
                <f7-button @click="deleteResult(IDWod)" raised fill small>{{$LanguageHelper.translate('_DELETE_YOUR_RESULT_')}}</f7-button>
              </f7-col>
            </f7-row>
          </f7-block>

          <f7-block v-if="wod.change_wod_score_disabled != 1 && $UserHelper.hasAccess('wod', 'logResult')" class="marginTop10 marginBottom10">
            <f7-row >
              <f7-col>
                <f7-button @click="adminLogResult" raised fill small>{{$LanguageHelper.translate('_LOG_ATHLETE_RESULT_')}}</f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
        </template>

      </f7-card-content>
    </f7-card>

    <customer-log-result v-if="openLogResult"
	    :idc="idc"
	    :admin="admin"
	    :wodData="wodData"
	    @resultSaved="resultSaved"
	    @close="openLogResult=false" />

	    <search-wod v-if="openSearchWod"
	    :idc="idc"
	    @close="openSearchWod=false"
	    @searchResult="searchResult"
    />

  </div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import CustomerLogResult from '../../popups/customer-log-result.vue';
import CustomerSearch from '../../popups/customer-search-wod.vue';

export default {
  components: {
    'list-form': ListForm,
    'customer-log-result': CustomerLogResult,
    'search-wod': CustomerSearch,
  },

  props: {
    idc: {required: true},
    idWod: {required: false},
    fab: {type: Object, required: true},
    searchWod: {type: Boolean, required: false},
  },

  data() {
    return {
      visible: false,
      showSwitch: false,
      viewHistory: false,
      viewBenchmark: false,
      openLogResult: false,
      openSearchWod: false,
      admin: false,
      IDWod: null,
      wod: {},
      appuserResult: {},
      wodData: {},
      pagination: {},

      form: {
        fields: {
          switchWod: {type: 'select', name: 'switch-wod', title: '_SWITCH_WOD_', render: false, options: []},
        }
      }
    };
  },

  computed: {
    orderBy() {
			return (Object.values(this.fab.options).find(option => option.selected)).value;
		}
  },

  watch: {
    searchWod(val) {
      if(val) this.openSearchWod = true;
    }
  },

  methods: {
    searchResult(val) {
			// Search for wod
      this.$emit('closeSearchWod');
			if(val) this.getWOD(val);
		},

    changed(field, option) {
      if(field.name == 'switch-wod') {
        if(option) {
          this.getWOD(option.value)
        }
      }
    },

    toggleHistory() {
      if(this.viewHistory == false) {
        this.viewHistory = true;
      } else {
        this.viewHistory = false;
      }
    },

    toggleBenchmark() {
      if(this.viewBenchmark == false) {
        this.viewBenchmark = true;
      } else {
        this.viewBenchmark = false;
      }
    },

    resultSaved(val) {
      this.getWOD(val);
    },

    logResult(val) {
      this.admin = false;
      if(val == 1) {
        $ViewHelper.alert($LanguageHelper.translate('_THERE_IS_NO_VALID_PRODUCT_'), $LanguageHelper.translate('_NOTICE_'));
      } else {
        this.openLogResult = true;
      }
    },

    adminLogResult() {
        this.openLogResult = true;
        this.admin = true;
    },

    setSwitchWod(data) {
      var that = this;
      $.each(data.wod.same_day, function (item, obj) {
        that.form.fields.switchWod.options.push({
          name: obj.name,
          value: obj.id_wod_whiteboard,
          label: obj.name,
          selected: (data.wod.id_wod_whiteboard == obj.id_wod_whiteboard ? true : false) ,
        });
      });
      this.form.fields.switchWod.render = true;
      this.showSwitch = true;
    },

    _initSearchResult(data) {
      if(data.status == 'OK') {
        if(data.resultset) this.searchResult = data.resultset;
      }
    },

    _initWhiteboard(data) {
      //empty data
      this.wodData = {};
      this.wod = {};
      this.appuserResult = {};
      this.form.fields.switchWod.options = [];
      this.form.fields.switchWod.render = false;
      this.viewHistory = false;

      if(data.status == 'OK') {

        //set switch wod
        if(data.wod.same_day && data.wod.same_day.length > 1) this.setSwitchWod(data)

        //add data
        this.wodData = data;

        //get wod data
        this.wod = data.wod;

        //set wod id
        this.IDWod = data.wod.id_wod_whiteboard;

        //set users
        this.appuserResult = data.appuser_result;

        //set pagination
        this.pagination = data.pagination;

        //emit ID wod for changing tabs
        this.$emit('saveIDWod', this.IDWod);

        //emit name_url for disabled_sharing
        this.$emit('nameUrl', this.wod.name_url);

        this.visible = true;
      }
    },

    deleteResult(val) {
      var paramsToSend = {idc: this.idc, id_appuser: $UserHelper.getID(), command: 'remove-result', page: 'workout', admin: 0, id_wod_whiteboard: val};
      $ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('WOD', 'logResult', Object.assign(paramsToSend), {callback: this._initWhiteboard})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
    },

    getWOD(val) {
      var data = [];
      if(val) data['id_wod_whiteboard'] = val;
      var paramsToSend = {idc: this.idc, id_appuser: $UserHelper.getID(), version_wod: 1.0, page: 'workout'};
		  $AjaxHelper.doRequest('WOD', 'whiteboard', Object.assign(paramsToSend, data), {callback: this._initWhiteboard});
    }
  },

  created() {
    this.getWOD(this.idWod);
	},
}
</script>

<style scoped>
.wrapperPeriod {
  margin: auto;
}
</style>
