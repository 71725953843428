<template>
  <div v-if="showInstallPrompt && !isAlreadyInstalled" class="wrapperInstallPrompt">
    <a class="button button-fill button-small button-raised button" @click="installPWA"> {{$LanguageHelper.translate('_INSTALL_APP_')}} <i class="icon ionDownload"> </i></a>
    <a v-if="isIos()" class="link" @click.stop="alreadyInstalledClicked">{{$LanguageHelper.translate('_INSTALL_APP_ALREADY_BUTTON_')}}</a>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  setup() {
    let deferredPrompt;
    const showInstallPrompt = ref(false);
    const isAlreadyInstalled = ref(false);

    const installAllowed = () => {
      if($Config.app.currentApp == 'recurme') return false;
      if($Config.app.onDevice) return false;
      if(!$UserHelper.isLoggedIn()) return false;
      if(isExternalPage()) return false;

      if(isIos()) {
        return true;
      }

      if(isAndroid()) {
        return true;
      }
    };

    const installPWA = () => {
      if(isIos()) {
        const iosDownload = $Config.app.currentApp == 'paynplan' ? 'https://itunes.apple.com/us/app/pay-n-plan/id966875330?l=nl&ls=1&mt=8' : 'https://itunes.apple.com/us/app/wod-app/id919053357?l=nl&ls=1&mt=8';
        return $ViewHelper.toBrowser(iosDownload, window.open());
      }

      if (deferredPrompt) {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          deferredPrompt = null;
        });
     }};

    const isAndroid = () =>  {
      return $FunctionsHelper.isAndroid();
    };

    const isIos = () =>  {
      return $FunctionsHelper.isIOS();
    };

    const isExternalPage = () => {
      return Boolean($FunctionsHelper.getAllQueryParams(window.location.href).external);
    };

    const alreadyInstalledClicked = () => {
      isAlreadyInstalled.value = true;
      localStorage.setItem('iosAppInstalledOnHomeScreen', 1);
    };

    onMounted(() => {
      // Listen to the beforeinstallprompt only on Android
      if(isAndroid()) {
        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          deferredPrompt = e;
          showInstallPrompt.value = installAllowed();
        });
      } else {
        if(isIos()) {
          isAlreadyInstalled.value = localStorage.getItem('iosAppInstalledOnHomeScreen') == 1;
          showInstallPrompt.value = installAllowed();
        }
      }
    });

    return {
      showInstallPrompt,
      installPWA,
      isAlreadyInstalled,
      alreadyInstalledClicked,
      isIos,
    };
  }
};
</script>

<style scoped>
div.wrapperInstallPrompt {
  padding:20px;
}

div.wrapperInstallPrompt a {
  width: 300px;
  margin: auto;
}

div.wrapperInstallPrompt a.link {
  display: block;
  text-align: center;
  margin-top: 10px;
}

.icon {
  font-size: 16px;
  margin-left: 20px;
}
</style>
