<template>
	<popup :title="$LanguageHelper.translate('_INVITE_CUSTOMERS_VIA_LINK_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<div class="pageContent">
			<f7-card>
				<f7-card-header>{{$LanguageHelper.translate('_INVITE_CUSTOMERS_VIA_LINK_')}}</f7-card-header>
				<f7-card-content>
					<div>{{$LanguageHelper.translate('_INVITE_CUSTOMERS_VIA_LINK_EXPLAINED_')}}</div>

					<h4><a :href="link" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{link}}</a></h4>

					<f7-button raised fill button-small class="button-small orange action" @click="copyToClipboard(link)">{{$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')}}</f7-button>
				</f7-card-content>
			</f7-card>
		</div>
	</popup>
</template>

<script>
import CopyToClipboard from '../js/mixins/copyToClipboard';
import Popup from '../components/smart/popup.vue';

export default {
	mixins: [CopyToClipboard],

	components: {
    'popup': Popup,
	},

	props: {
		idc: {required: true}
	},

	data() {
		return {
			opened: false,
			link: `${$Config.apps[$Config.app.currentApp].appversionURL}/account?id=${this.idc}`,
		}
	}
};
</script>
