<template>
  <label class="file-select">
    <div class="select-button button button-fill button-raised orange button-small">
      <span>{{$LanguageHelper.translate('_BROWSE_IMAGES_')}}</span>
    </div>
    <input type="file" @change="handleFileChange" accept="image/*" />
  </label>
</template>

<script>
export default {
  props: {
    value: File
  },

  methods: {
    handleFileChange(e) {
      this.$emit('selected', e.target.files[0]);
		},
  }
}
</script>

<style scoped>
.file-select > input[type="file"] {
  display: none;
}
</style>
