<template>
	<popup v-if="render"
		:title="$LanguageHelper.translate('_COMPANY_INFO_')"
		:opened="opened"
		:backgroundImage="backgroundImage"
		pageContentClass="companyBackground"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<div class="pageContent">
			<f7-card inset v-if="info">
				<f7-card-header>
					<div>{{$FunctionsHelper.specialChars(info.gym_name)}}</div>
				</f7-card-header>

				<f7-card-content>
					<div class="item nl2br" v-html="info.description" />

	        <f7-row class="contentItem marginTop10">
	          <f7-col width="10">
	            <div class="appointmentIcon"><f7-icon class="icon ionLocation themeDetail"></f7-icon></div>
	          </f7-col>
	          <f7-col width="90">
							<div class="item-title">{{$FunctionsHelper.specialChars(info.street)}} {{$FunctionsHelper.specialChars(info.housenumber)}}{{$FunctionsHelper.specialChars(info.housenumber_extra)}}</div>
							<div class="item-title">{{$FunctionsHelper.specialChars(info.zipcode)}} {{$FunctionsHelper.specialChars(info.city)}}</div>
	          </f7-col>
	        </f7-row>

					<f7-row class="contentItem marginTop10" v-if="info.companyTerms.url_terms_and_conditons">
	          <f7-col width="10">
	            <div class="appointmentIcon"><f7-icon class="icon ionClipBoard themeDetail"></f7-icon></div>
	          </f7-col>
	          <f7-col width="90">
							<f7-link :href="info.companyTerms.url_terms_and_conditons" class="external theme" :target="$Config.app.onDevice ? '_system' : '_blank'">{{$LanguageHelper.translate('_VIEW_TERMS_')}}</f7-link>
	          </f7-col>
	        </f7-row>
				</f7-card-content>
			</f7-card>
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup,
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			render: false,
			backgroundImage: '',
			logo: '',
			opened: false,
			info: {},
		};
  },

	methods: {
		_getCompanyComplete(data) {
			if(data.status == "OK") {
				if(data.resultset) {
					this.info = data.resultset;
				}

				// Set background images if there are any
				if(data.companyImages) {
					this.backgroundImage = data.companyImages.bg;
					this.logo = data.companyImages.logo;
				}
				this.render = true;
			}
		},
	},

	created() {
		$AjaxHelper.doRequest('gym', 'getGymComplete', {id: this.idc, idc: this.idc, companyImages: 1}, {callback: this._getCompanyComplete});
	}
};
</script>
