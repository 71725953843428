<template>
	<popup v-if="render" :title="$LanguageHelper.translate('_WITHDRAWAL_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<f7-list list inset>
			<f7-list-item>
				<div class="toplistInfo">
					<table class="balanceInfo">
						<tr>
							<td class="left">&euro; {{clearing.bank_balance ? clearing.bank_balance : '0.00'}}</td>
							<td>{{$LanguageHelper.translate('_BANK_BALANCE_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{clearing.receivable_balance ? clearing.receivable_balance : '0.00'}}</td>
							<td>{{$LanguageHelper.translate('_RECEIVABLE_BALANCE_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{clearing.reservations ? clearing.reservations : '0.00'}}</td>
							<td>{{$LanguageHelper.translate('_RESERVATIONS_')}}</td>
						</tr>

						<tr v-if="clearing.held_days_direct_debit > 0">
							<td>&euro; {{clearing.held_direct_debit_funds ? clearing.held_direct_debit_funds : '0.00'}}</td>
							<td>{{$LanguageHelper.translate('_AVAILABLE_SOON_')}}</td>
						</tr>
					</table>
				</div>
			</f7-list-item>
		</f7-list>
		<list-form :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
  },

	data() {
		return {
			render: false,
			opened: false,

			clearing: {},

			form: {
				fields: {
					clearing_on_hold: {type: 'description', text: '_CLEARING_ON_HOLD_MESSAGE_', class: 'textPayAttention marginBottom10', render: false},
					withdrawal_date: {type: 'date', label: '_NEXT_WITHDRAWAL_', placeholder: '_SELECT_DATE_', render: true, value: ''},
					clearing_threshold_level_info: {type: 'divider', title: '_CLEARING_THRESHOLD_LEVEL_INFO_TITLE_', render: true, info: '_CLEARING_THRESHOLD_LEVEL_INFO_'},
					clearing_threshold_level: {type: 'input', label: '_RESERVE_FOR_REFUNDS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					clearing_period_info: {type: 'divider', title: '_CLEARING_PERIOD_INFO_TITLE_', render: true, info: '_CLEARING_PERIOD_INFO_'},
					id_clearing_period: {type: 'select', name: 'id_clearing_period', title: '_CLEARING_PERIOD_', render: true, options: []},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		}
	},

	methods: {
		submitted() {
			$AjaxHelper.doRequest('payment', 'saveClearing', Object.assign({idc: this.idc}, JSON.parse(JSON.stringify(this.form.values))), {callback: this._clearingSaved});
		},

		_clearingSaved(data) {
			// Close popup after were done...
			if(data.status == 'OK') {
				// Setting opened to false will trigger the popup close event so this component will be destroyed!
				this.opened = false;
			}
		},

		_clearing(data) {
			// Store data
			if(data.resultset && data.resultset.clearing) {
				this.clearing = data.resultset.clearing;
			}

			// Show clearing on-hold notice if needed!
			this.form.fields.clearing_on_hold.render = data.resultset.payment_on_hold && data.resultset.payment_on_hold == 1;

			// Set form values
			this.form.values = Object.assign({}, this.clearing);

			// Set clearing period options
			data.resultset.periods.forEach((option) => {
				option.selected = option.selected == 1;
				this.form.fields.id_clearing_period.options.push(option);
			});

			// Show popup now!
			this.render = true;
		},

		_extraLoginClosedByUser() {
			// If extra login was closed by user then fire close event so my parent can kill me and render me again later on!
			this.$emit('close');
		},
	},

	created() {
		$AjaxHelper.doRequestExtraLogin('payment', 'getClearing', {idc: this.idc}, {callback: this._clearing}, this._extraLoginClosedByUser);
	}
};
</script>

<style scoped>
table.balanceInfo {width:400px;}
</style>
