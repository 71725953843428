<template>
  <page
    :title="$LanguageHelper.translate('_WOD_')"
    :infinite="true"
    :search="true"
    :sort-options="sortOptions"
    @sort-selected="sortSelected"
    @infinite-scroll-page-end="pageEndReached"
    @searched="searched"
    :fab="fab.new_wod"
    @fab-clicked="fabClicked"
		@initiated="go"
  >
    <f7-list media-list inset v-if="visible">
			<f7-list-item accordion-item v-for="(item, key) in list"
				:key="key"
				:title="$FunctionsHelper.specialChars(item.name)"
				:subtitle="(item.date_wod ? $LanguageHelper.translate('_LAST_PUBLISHED_') + ': ' + item.date_wod : $LanguageHelper.translate('_NOT_PUBLISHED_'))"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
            <div class="item" v-html="item.description"></div>
            <br/>
            <template v-if="item.date_last_published">
              <div class="item"><b>{{$LanguageHelper.translate('_SHARE_WOD_ADMIN_LABEL_')}}</b></div>
              <div class="item">{{$LanguageHelper.translate('_SHARE_WOD_ADMIN_TEXT_')}}</div>
              <div class="link theme">{{url + '/wod/' + item.name_url + '/' + item.id_wod}}</div><a class="link theme" @click="copyToClipboard(url + '/wod/' + item.name_url + '/' + item.id_wod)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a>
            </template>
					</f7-block>

          <f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('wod', 'publish', idc)">
								<f7-button @click="publishWod(item.id_wod)" raised fill small>{{$LanguageHelper.translate('_PUBLISH_')}}</f7-button>
							</f7-col>

							<f7-col v-if="$UserHelper.hasAccess('wod', 'save', idc)">
								<f7-button @click="editWod(item.id_wod)" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
							</f7-col>

              <f7-col v-if="$UserHelper.hasAccess('wod', 'remove', idc)">
								<f7-button @click="removeWod(item.id_wod)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>

			</f7-list-item>
		</f7-list>

    <!--add wod component-->
    <manage-wod v-if="addWod" :idc="idc" :idwod="idwod" :publish="publish" @savedWod="savedWod" @close="addWod=false" />
  </page>
</template>

<script>
import CopyToClipboard from '../js/mixins/copyToClipboard';
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';
import ManageWod from '../popups/manage-wod.vue';

export default {
  mixins: [CopyToClipboard],

	components: {
    'page': Page,
    'list-form': ListForm,
    'manage-wod': ManageWod,
  },

  props: {
		idc: {required: true},
	},

  data() {
    return {
      visible: false,
      opened: false,
			infiniteDone: false,
      list: [],
      url: $Config.apps[$Config.app.currentApp].siteURL,
      addWod: false,
      idwod: null,
      publish: false,

      sortOptions: {
				title: '_SORT_AND_FILTER_',
				options: {
					name_asc: {value: 'name_asc', label: '_SORT_NAME_A_Z_', selected: true},
					name_desc: {value: 'name_desc', label: '_SORT_NAME_Z_A_', selected: false},
					date_wod_asc: {value: 'date_wod_asc', label: '_DATE_WOD_ASC_', selected: false},
					date_wod_desc: {value: 'date_wod_desc', label: '_DATE_WOD_DESC_', selected: false},
				}
			},

      fab: {
        new_wod: {
          position: 'right-bottom',
          buttons: {
            add: {name:'add', icon: 'icon ionAdd', label: $LanguageHelper.translate('_CREATE_NEW_'), render: $UserHelper.hasAccess('wod', 'save', this.idc), clicked: false},
          }
        }
      },

    };
  },

  watch: {
    addWod(val) {
      if(!val) {
        this.publish = false;
        this.idwod = null;
      }
    }
  },

  computed: {
    orderBy() {
			return (Object.values(this.sortOptions.options).find(option => option.selected)).value;
		},
  },

  methods: {
    fabClicked(fab) {
      if(fab.name == 'add') this.addWod = true;
		},

    sortSelected() {
			this.list = [];
			// Load the new results
			this.load(true);
		},

    searched(val) {
			// Empty current list
			this.list = [];

			// Search for wod
			if(val.length > 0) $AjaxHelper.doRequest('WOD', 'searchWODAdmin', {search: val, order: this.orderBy, idc: this.idc, version_wod: 1.0}, {callback: this._initWod});
			// No input, reset the list!
			else this.load(true);
		},

    publishWod(val) {
      this.publish = true;
      this.idwod = val;
      this.addWod = true;
    },

    editWod(val) {
      this.idwod = val;
      this.addWod = true;
    },

    destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getWODS');
		},

    pageEndReached() {
			this.load();
		},

    savedWod(data) {
      this.list = [];
      setTimeout(() => this.list = data, 500);
    },

    removeWod(val) {
      $ViewHelper.confirm({method: () => this.remove(val)}, $LanguageHelper.translate('_NOTICE_'));
    },

    remove(val) {
      this.list = [];
      $AjaxHelper.doRequest('WOD', 'removeWOD', {idc: this.idc, id_wod: val}, {callback: this._initWod});
    },

    _initWod(data) {
      if(data.status == 'OK') {
        if(data.resultset) this.list = data.resultset;
        this.visible = true;
      }
    },

    _Wod(data) {
      if(data.status == 'OK') {
        if(data.resultset.length > 0)  {
					this.list.push(...data.resultset);
				} else this.infiniteDone = true;
      }
    },

    load(firstOrReset = false) {
			if(firstOrReset) {
        this.destroyInfinite();
        this.list = [];
      }

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('WOD', 'getWODS', {idc: this.idc, order: this.orderBy, version_wod: 1.0}, {callback: this[firstOrReset ? '_initWod' : '_Wod']});
		},

		go() {
			this.load(true)
		}
  }
};
</script>
