<template>
	<popup :title="$LanguageHelper.translate('_SHOPPING_CART_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<div class="pageContent">
			<shopping-cart :idc="idc" @changed="changed"  @close="opened=false" />
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ShoppingCart from '../components/shopping-cart.vue';

export default {
	components: {
    'popup': Popup,
		'shopping-cart': ShoppingCart,
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
		};
  },

	methods: {
		changed(data) {
			this.$emit('changed', data)
		}
	}
};
</script>
