<template>
  <div v-if="visible">
    <fixed-top>
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
          <table v-if="transactions">
						<tr>
							<td width="80">&euro; {{transactions.cash_balance}}</td>
							<td>{{$LanguageHelper.translate('_IN_REGISTER_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{transactions.plus_amount}}</td>
							<td>{{$LanguageHelper.translate('_SALES_OVER_PERIOD_')}}</td>
						</tr>

						<tr>
							<td>&euro; {{transactions.min_amount}}</td>
							<td>{{$LanguageHelper.translate('_WITHDRAWALS_OVER_PERIOD_')}}</td>
						</tr>
					</table>
				</f7-list-item>
			</f7-list>

			<list-form :params="form" @changed="changed"></list-form>
		</fixed-top>

    <f7-list accordion-list inset>
			<list-item v-for="(transaction, key) in cashTransactions" type="accordion"
				:key="key"
				:title="`${transaction.saleInfo.firstname ? transaction.saleInfo.firstname + ' ' + transaction.saleInfo.lastname : (transaction.saleInfo.reason_withdrawal ? (transaction.saleInfo.amount.replace(',', '.') < 0 ? $LanguageHelper.translate('_CASH_WITHDRAWAL_') : $LanguageHelper.translate('_DEPOSIT_')) : $LanguageHelper.translate('_INDIVIDUAL_SALES_'))}`"
				:after="`€ ${transaction.saleInfo.amount}`"
				:subtitle="transaction.saleInfo.created_on"
				>
			
				<f7-accordion-content>
					<f7-block class="marginBottom10">
            <div class="item" v-if="transaction.saleInfo.reason_withdrawal">{{$LanguageHelper.translate('_REASON_')}}: {{transaction.saleInfo.reason_withdrawal}}</div>
						<div class="item" v-if="transaction.saleInfo.sale_id && transaction.saleInfo.sale_id.length > 0">{{$LanguageHelper.translate('_SALE_NUMBER_')}}: {{transaction.saleInfo.sale_id}}</div>
						<div class="item" v-if="transaction.saleInfo.invoice_number && transaction.saleInfo.invoice_number.length > 0">{{$LanguageHelper.translate('_NAME_')}}: {{transaction.saleInfo.invoice_number}}</div>
            <div v-for="(product, index) in transaction.products" :key="index">
              <div class="item"><b>{{product.name}}</b></div>
              <div class="item" v-if="product.name">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}: {{product.number_items}}</div>
            </div>
						<div class="item" v-html="`${$LanguageHelper.translate('_SALESPERSON_')}: ${transaction.saleInfo.appuser_admin_name}`"></div>

						<template v-if="transaction.saleInfo.order_details_url && transaction.saleInfo.order_details_token">
							<div class="item">
								{{$LanguageHelper.translate('_LINK_TO_ORDER_DETAILS_PAGE_')}}&nbsp;&nbsp;<a class="link theme" @click="copyToClipboard(transaction.saleInfo.order_details_url)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a><br>
								<a :href="transaction.saleInfo.order_details_url" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{transaction.saleInfo.order_details_url}}</a>

								<div class="item marginTop10"><b>{{$LanguageHelper.translate('_TOKEN_ORDER_DETAILS_PAGE_')}}</b>&nbsp;&nbsp;<a class="link theme" @click="copyToClipboard(transaction.saleInfo.order_details_token)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a></div>
								<div class="item">{{transaction.saleInfo.order_details_token}}</div>
							</div>
						</template>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>

    <!-- Load if withdraw FAB is clicked -->
		<manage-register-withdraw v-if="withdrawOpened"
			:title="$LanguageHelper.translate('_WITHDRAWAL_')"
			:idc="idc"
      @withdrawalSubmitted="withdrawalSubmitted"
			@close="withdrawOpened=false"
		/>

    <!-- Load if deposit FAB is clicked -->
		<manage-register-deposit v-if="depositOpened"
			:title="$LanguageHelper.translate('_MANAGE_DEPOSIT_')"
			:idc="idc"
      @depositSubmitted="depositSubmitted"
			@close="depositOpened=false"
		/>
  </div>
</template>

<script>
import CopyToClipboard from '../../js/mixins/copyToClipboard';
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';
import ListItem from '../../components/smart/list-item.vue';
import ManageRegisterWithdraw from '../../popups/manage-register-withdraw.vue';
import ManageRegisterDeposit from '../../popups/manage-register-deposit.vue';

export default {
	mixins: [CopyToClipboard],

  components: {
		'list-form': ListForm,
    'fixed-top': FixedTop,
    'list-item': ListItem,
    'manage-register-withdraw': ManageRegisterWithdraw,
    'manage-register-deposit': ManageRegisterDeposit,
  },

	props: {
		idc: {required: true},
		pageEndReached: {type: Boolean, required: true, default: false},
    sortSelected: {type: String, required: true, default: ''},
		searched: {type: String, required: true, default: ''},
    fab: {type: Object, required: true},
	},

  data() {
    return {
			visible: false,
			infiniteDone: false,
      withdrawOpened: false,
      depositOpened: false,
      transactions: {
        cash_balance: 0,
        plus_amount: 0,
        min_amount: 0,
      },
      cashTransactions: [],

			form: {
				fields: {
					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: true,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},
    };
  },

  watch: {
		searched(val) {
			// Empty current list
			this.cashTransactions = [];

			// Search for customers
			if(val.length > 0) {
        $AjaxHelper.doRequest('payment', 'searchCashTransactions', {idc: this.idc, order: this.orderBy, date_range: this.dateRange, search: val}, {callback: this._searchTransactions});
			}
			// No input, reset the list!
			else {
				this.getLedger(true);
			}
		},

		sortSelected(val) {
			this.getLedger(true);
		},

    fabExportClicked(fab) {
      if(!fab) return;
      this.fab.buttons.export.clicked = false;
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=exportCashTransactions&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&date_start=${this.dateRange.start}&date_end=${this.dateRange.end}&language=${$LanguageHelper.current}`);
		},

    fabDepositClicked(fab) {
      if(!fab) return;
			this.depositOpened = true;
      this.fab.buttons.deposit.clicked = false;
		},

    fabWithdrawalClicked(fab) {
      if(!fab) return;
			this.withdrawOpened = true;
      this.fab.buttons.withdrawal.clicked = false;
		},

		pageEndReached(val) {
			if(val) this.getLedger();
		}
	},

  computed: {
		orderBy() {
			return this.sortSelected;
		},

    dateRange() {
      var dateRange = {
        start: this.form.fields.date.fields.date_start.value,
        end: this.form.fields.date.fields.date_end.value,
      };

			return dateRange;
		},

		fabExportClicked() {
			return this.fab.buttons.export.clicked;
		},

    fabDepositClicked() {
      return this.fab.buttons.deposit.clicked;
		},

    fabWithdrawalClicked() {
      return this.fab.buttons.withdrawal.clicked;
		},
  },

  methods: {
    changed(field, option) {
      if(field.name == 'dateRange') this.getLedger(true);
    },

    withdrawalSubmitted() {
      this.getLedger(true);
    },

    depositSubmitted() {
      this.getLedger(true);
    },

    destroyInfinite() {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite('getLedger');
		},

    getLedger(firstOrReset = false) {
      if(firstOrReset) {
				this.destroyInfinite();
				this.cashTransactions = [];
			}

      if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getLedger', {idc: this.idc, date_range: this.dateRange, order: this.orderBy}, {callback: firstOrReset ? this._initLedger : this._addToLedger});
    },

    _searchTransactions(data) {
      if(data.resultset) this.cashTransactions = data.resultset.cash_transactions;
    },

    _addToLedger(data) {
      if(data.resultset.cash_transactions && data.resultset.cash_transactions.length > 0) {
        this.cashTransactions.push(...data.resultset.cash_transactions);

        //Let our parent know to reset the page end status!
        this.$emit('page-end-loaded');
      } else this.infiniteDone = true;
    },

    _initLedger(data) {
      if(data.resultset) {
        this.transactions.cash_balance = data.resultset.cash_balance;
        this.transactions.min_amount = data.resultset.min_amount;
        this.transactions.plus_amount = data.resultset.plus_amount;
        this.cashTransactions = data.resultset.cash_transactions;

        this.visible = true;
      }

			//Let our parent know to reset the page end status!
			this.$emit('page-end-loaded');
    }
  },

  created() {
    this.getLedger(true);
  }
};
</script>
