export default {
	// This mixin is used for setting data we need in other components as well such as the extra info popup and so fort!
	data() {
    return {
			filterDefaults: {
				type: 'dashboard', // Possible values: dashboard, summary, export
				tab: '', // Possible values: inancial, customer, agenda
				dashboard: '', // Dashboard name
				chart: '', // Possible values: pie, line
				customConditions: [], // Custom filters that gets send to back-end (product id's for example)
				dateRange: [], // Used for from until date select (contains array with single date range object in it)
				modes: '', // Possible values: month, week, day
				summaryItem: '', // Summary item
			},

			filters: {},

			// Data fetched from back-end
			data: null
		};
  },

	methods: {
		setFiltersDefaults(tab = '') {
			// Set the defauls for when we come in!
			this.filters = Object.assign(this.filterDefaults, {tab: tab, type: 'dashboard'});
		}
	}
};
