<template>
  <page
		:title="$LanguageHelper.translate('_PRODUCTS_')"
		:toolbar="toolbar"
		@sort-selected="setSortSelected"
		@searched="setSearched"
		@initiated="go"
	>

		<template #products v-if="toolbar.tabs.products && toolbar.tabs.products.active">
			<tab-products :idc="idc" :fab="fab.products" :sort-selected="sortSelected" :searched="searched" />
		</template>

		<template #categories v-if="toolbar.tabs.categories && toolbar.tabs.categories.active">
			<tab-categories :idc="idc" :fab="fab.categories" />
		</template>

		<template #discount_codes v-if="toolbar.tabs.discount_codes && toolbar.tabs.discount_codes.active">
			<tab-discount-codes :idc="idc" :fab="fab.discount_codes" />
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabProducts from './tab-products.vue';
import TabCategories from './tab-categories.vue';
import TabDiscountCodes from './tab-discount-codes.vue';

export default {
  components: {
    'page': Page,
    'tab-products': TabProducts,
    'tab-categories': TabCategories,
    'tab-discount-codes': TabDiscountCodes
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			sortOptions: null,
			sortSelected: 'name_asc',
			searched: '',
			fabClicked: null,

			fab: {
				products: {
					position: 'right-bottom',
					buttons: {
						add: {name:'add', label: $LanguageHelper.translate('_CREATE_PRODUCT_'), icon: 'icon ionAdd', render: $UserHelper.hasAccess('invoice_product', 'save', this.idc), clicked: false},
						archive: {name:'archive', label: $LanguageHelper.translate('_OPEN_ARCHIVE_'), icon: 'icon ionTrash', render: $UserHelper.hasAccess('invoice_product', 'remove', this.idc), clicked: false},
					}
				},

				categories: {
					position: 'right-bottom',
					buttons: {
						add: {name:'add', icon: 'icon ionAdd', label: $LanguageHelper.translate('_CREATE_NEW_'), render: $UserHelper.hasAccess('invoice_product', 'saveCategories', this.idc), clicked: false},
					}
				},

				discount_codes: {
					position: 'right-bottom',
					buttons: {
						add: {name:'add', icon: 'icon ionAdd', label: $LanguageHelper.translate('_CREATE_NEW_'), render: $UserHelper.hasAccess('invoice_product', 'save', this.idc), clicked: false},
					}
				}
			},

			toolbar: {
				tabs: {
					products: {text: $LanguageHelper.translate('_PRODUCTS_'), icon: 'icon ionCart', render: false, search: true, sortOptions: null, active: false},
					categories: {text: $LanguageHelper.translate('_CATEGORIES_'), icon: 'icon ionList', render: false, active: false},
					discount_codes: {text: $LanguageHelper.translate('_DISCOUNT_CODES_'), icon: 'icon ionPaper', render: false, active: false}
				}
			}
    };
  },

	methods: {
		setSearched(val) {
			this.searched = val;
		},

		setSortSelected(val) {
			this.sortSelected = val;
		},

		go() {
			// Set floating action buttons per tab
			this.toolbar.tabs.products.fab = this.fab.products;
			this.toolbar.tabs.categories.fab = this.fab.categories;
			this.toolbar.tabs.discount_codes.fab = this.fab.discount_codes;

			// Set sort options for the product tab
			this.toolbar.tabs.products.sortOptions = {
				title: '_SORT_AND_FILTER_',
				options: {
					name_asc: {value: 'name_asc', label: '_SORT_NAME_A_Z_', selected: true},
					name_desc: {value: 'name_desc', label: '_SORT_NAME_Z_A_', selected: false},
					credits_desc: {value: 'credits_desc', label: '_CARD_WITH_CREDITS_', selected: false},
					installments_desc: {value: 'installments_desc', label: '_SUBSCRIPTION_', selected: false},
					single_desc: {value: 'single_desc', label: '_OTHER_PRODUCT_', selected: false},
					free_trial_desc: {value: 'free_trial_desc', label: '_FREE_TRIAL_', selected: false},
					date_sale_desc: {value: 'date_sale_desc', label: '_DATE_DESC_', selected: false},
					date_sale_asc: {value: 'date_sale_asc', label: '_DATE_ASC_', selected: false},
					order_shop_asc: {value: 'order_shop_asc', label: '_ORDER_SHOP_', selected: false},
				}
			};

			// Only render discount and categories tab if payments are enabled
			$AjaxHelper.doRequest('gym', 'payAndOrPlanEnabled', {idc: this.idc}, {callback: (data) => {
				this.toolbar.tabs.products.render = $UserHelper.hasAccess('invoice_product', 'show', this.idc);
				this.toolbar.tabs.categories.render = $UserHelper.hasAccess('invoice_product', 'showCategories', this.idc) && (data.modules.payments && data.modules.payments == 1);
				this.toolbar.tabs.discount_codes.render = $UserHelper.hasAccess('invoice_product', 'show', this.idc) && (data.modules.payments && data.modules.payments == 1);

				// Set active tab
				if(this.toolbar.tabs.products.render) this.toolbar.tabs.products.active = true;
				else if(this.toolbar.tabs.categories.render) this.toolbar.tabs.categories.active = true;
				else if(this.toolbar.tabs.discount_codes.render) this.toolbar.tabs.discount_codes.active = true;

				this.render = true;
			}});
		}
	}
};
</script>
