<template>
	<div v-if="render">
		<popup
			:title="$LanguageHelper.translate('_PRODUCT_')"
			:opened="opened"
			:fab="fab"
			@fab-clicked="openFotoUpload"
			@mounted="opened=true"
			@close="$emit('close')"
			>

			<list-form :params="form" @submitted="submitted" @changed="changed">
				<template #extra-items>
					<template v-if="booking.render">
						<!-- Welcome info divider -->
						<f7-list-item divider>
							<div class="item-inner">
								<span>{{$LanguageHelper.translate('_TEXT_BOOKING_PAGE_')}}</span>
								<f7-link class="link theme action" popover-open=".popover_booking_welcome"><i class="icon ionInformationCircle"></i></f7-link>
								<f7-popover class="popover_booking_welcome">
									<div class="content">{{$LanguageHelper.translate('_TEXT_BOOKING_PAGE_INFO_')}}</div>
								</f7-popover>
							</div>
						</f7-list-item>

						<!-- Welcome info language dropdown here -->
						<f7-list-item
							:title="$LanguageHelper.translate(booking.booking_welcome.languages.label)"
							:smart-select-params="{openIn: 'popover', closeOnSelect: true}"
							smart-select
							>
							<select @change="switchBookingLanguage('page')">
								<option v-for="(option, key) in booking.booking_welcome.languages.options" :key="key" :value="option.code" :selected="option.selected">{{$LanguageHelper.translate(option.label)}}</option>
							</select>
						</f7-list-item>

						<!-- Welcome info title and text here -->
						<template v-for="(languageBookingWelcome, key) in booking.booking_welcome.languages.options" :key="key">
							<f7-list-input v-show="languageBookingWelcome.code == booking.booking_welcome.language"
								:label="$LanguageHelper.translate(booking.booking_welcome.fields[languageBookingWelcome.code].booking_welcome_title.label)"
								:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
								:value="booking.booking_welcome.fields[languageBookingWelcome.code].booking_welcome_title.value"
								@input="booking.booking_welcome.fields[languageBookingWelcome.code].booking_welcome_title.value=$event.target.value"
								clear-button
							/>

							<f7-list-input v-show="languageBookingWelcome.code == booking.booking_welcome.language"
								type="textarea"
								:label="$LanguageHelper.translate(booking.booking_welcome.fields[languageBookingWelcome.code].booking_welcome_text.label)"
								:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
								:value="booking.booking_welcome.fields[languageBookingWelcome.code].booking_welcome_text.value"
								@input="booking.booking_welcome.fields[languageBookingWelcome.code].booking_welcome_text.value=$event.target.value"
								clear-button
							/>
						</template>

						<!-- Email info divider -->
						<f7-list-item divider>
							<div class="item-inner">
								<span>{{$LanguageHelper.translate('_BOOKING_CONFIRMATION_EMAIL_')}}</span>
								<f7-link class="link theme action" popover-open=".popover_booking_email"><i class="icon ionInformationCircle"></i></f7-link>
								<f7-popover class="popover_booking_email">
									<div class="content">
										{{$LanguageHelper.translate('_TEXT_BOOKING_EMAIL_INFO_')}}<br /><br />
										{{$LanguageHelper.translate('_VIEW_AVAILABLE_TAGS_')}}<br /><br />
										<div>
											<b>{{$LanguageHelper.translate('_REQUIRED_')}}</b>
											<div v-for="(tag, key) in booking.booking_email.tags.required" :key="key">{{tag}}</div>
										</div>
										<br />

										<div>
											<b>{{$LanguageHelper.translate('_OPTIONAL_')}}</b>
											<div v-for="(tag, key) in booking.booking_email.tags.optional" :key="key">{{tag}}</div>
										</div>
									</div>
								</f7-popover>
							</div>
						</f7-list-item>

						<!-- Email language dropdown here -->
						<f7-list-item
							:title="$LanguageHelper.translate(booking.booking_email.languages.label)"
							:smart-select-params="{openIn: 'popover', closeOnSelect: true}"
							smart-select
							>
							<select @change="switchBookingLanguage('mail')">
								<option v-for="(option, key) in booking.booking_email.languages.options" :key="key" :value="option.code" :selected="option.selected">{{$LanguageHelper.translate(option.label)}}</option>
							</select>
						</f7-list-item>

						<!-- Email title and text here -->
						<template v-for="(languageBookingMail, key) in booking.booking_email.languages.options" :key="key">
							<f7-list-input v-show="languageBookingMail.code == booking.booking_email.language"
								:label="$LanguageHelper.translate(booking.booking_email.fields[languageBookingMail.code].booking_email_title.label)"
								:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
								:value="booking.booking_email.fields[languageBookingMail.code].booking_email_title.value"
								@input="booking.booking_email.fields[languageBookingMail.code].booking_email_title.value=$event.target.value"
								clear-button
							/>

							<f7-list-input v-show="languageBookingMail.code == booking.booking_email.language"
								type="textarea"
								:label="$LanguageHelper.translate(booking.booking_email.fields[languageBookingMail.code].booking_email_text.label)"
								:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
								:value="booking.booking_email.fields[languageBookingMail.code].booking_email_text.value"
								@input="booking.booking_email.fields[languageBookingMail.code].booking_email_text.value=$event.target.value"
								clear-button
							/>
						</template>
					</template>
				</template>
			</list-form>
		</popup>

		<image-upload v-if="uploadOpened"
			:title="$LanguageHelper.translate('_UPLOAD_IMAGE_')"
			:currentImageURL="imageURL"
			:idc="idc"
			:name="`${id_product}`"
			type="invoiceProduct"
			@close="uploadOpened=false"
		/>
	</div>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import ImageUpload from '../components/smart/image-upload.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm,
		'image-upload': ImageUpload
	},

	props: {
		idc: {required: true},
		id_product: {required: false},
		index: {required: false},
  },

	data() {
    return {
			fab: {
				position: 'right-bottom',
				buttons: {
					upload: {name:'upload', label: $LanguageHelper.translate('_UPLOAD_IMAGE_'), icon: 'icon ionImage', render: false, clicked: false},
				}
			},

			edit: false,
			stock: {
				enable: false,
				public: false,
				currentSupply: 0,
			},

			uploadOpened: false,
			opened: false,
			render: false,
			showImportMatchIDField: false,

			renderFunctions: [
				'renderSubscription',
				'renderCardWithCredits',
				'renderFreeTrial',
				'renderOther',
			],

			imageURL: '',

			agendaEnabled: false,

			productTypeID: null,

			productTypeDescriptions: [],

			categories: [],

			bookingCustomTexts: null, // Comes from server
			booking: {
				render: false,
				booking_welcome: {language: '', fields: {}},
				booking_email: {language: '', fields: {}, tags: {}},
			},

			form: {
				fields: {
					explain_product_type: {type: 'description', text: '', class: 'textPayAttention', render: false},

					id_invoice_product_type: {type: 'select', name: 'id_invoice_product_type', title: '_PRODUCT_TYPE_', render: false, options: []},
					name: {type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					description: {type: 'textarea', label: '_DESCRIPTION_', placeholder: '_ENTER_HERE_', render: false, value: ''},

					number_installments: {type: 'select', name: 'number_installments', title: '_NUMBER_INSTALLMENTS_', render: false, options: []},
					collect_every: {type: 'combined', title: '_COLLECT_EVERY_', render: false,
						fields: {
							length: {type: 'select', class: 'small', name: 'length', options: []},
							period: {type: 'select', class: 'medium', name: 'period', options: []},
						}
					},

					amount_in_vat_first_installment: {type: 'input', label: '_AMOUNT_FIRST_PERIOD_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					amount_in_vat: {type: 'input', label: '_AMOUNT_OTHER_PERIODS_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					amount: {type: 'input', label: '_AMOUNT_IN_VAT_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					vat_percentage: {type: 'input', label: '_VAT_PERCENTAGE_', placeholder: '_ENTER_HERE_', render: false, value: ''},

					payment_method: {type: 'divider', title: '_PAYMENT_METHOD_', render: false},

					// We will set the labels later, it comes from the server
					pm_1: {type: 'checkbox', title: '', name: 'pm_1', added: false, checked: false, value: 1, render: false},
					pm_2: {type: 'checkbox', title: '', name: 'pm_2', added: false, checked: false, value: 1, render: false},
					pm_3: {type: 'checkbox', title: '', name: 'pm_3', added: false, checked: false, value: 1, render: false},
					pm_4: {type: 'checkbox', title: '', name: 'pm_4', added: false, checked: false, value: 1, render: false},
					pm_5: {type: 'checkbox', title: '', name: 'pm_5', added: false, checked: false, value: 1, render: false},
					pm_6: {type: 'checkbox', title: '', name: 'pm_6', added: false, checked: false, value: 1, render: false},
					pm_7: {type: 'checkbox', title: '', name: 'pm_7', added: false, checked: false, value: 1, render: false},
					pm_8: {type: 'checkbox', title: '', name: 'pm_8', added: false, checked: false, value: 1, render: false},
					pm_9: {type: 'checkbox', title: '', name: 'pm_9', added: false, checked: false, value: 1, render: false},
					pm_10: {type: 'checkbox', title: '', name: 'pm_10', added: false, checked: false, value: 1, render: false},

					credits_settings: {type: 'divider', title: '_CREDITS_SETTINGS_', render: false},
					number_credits: {type: 'select', name: 'number_credits', title: '_NUMBER_CREDITS_', render: false, options: []},
					period_valid: {type: 'select', name: 'period_valid', title: '_PERIOD_VALID_', render: false, options: []},

					thanks_url_divider: {type: 'divider', title: '_THANKS_PAGE_TITLE_', info: '_THANKS_URL_INFO_', render: false},
					thanks_url: {type: 'input', label: '_THANKS_URL_', placeholder: '_THANKS_URL_PlACEHOLDER_', render: false, value: ''},

					sale_settings: {type: 'divider', title: '_SALE_SETTINGS_', info: '_SALE_SETTINGS_INFO_', render: false},
					account_not_allowed: {type: 'checkbox', name: 'account_not_allowed', title: '_ACCOUNT_NOT_ALLOWED_', checked: false, value: 1, render: false},
					max_sales_per_customer: {type: 'select', name: 'max_sales_per_customer', title: '_HOW_MUCH_SALES_PER_CUSTOMER_', render: false, options: []},

					shop_settings: {type: 'divider', title: '_SHOP_SETTINGS_', render: false},
					order_me: {type: 'input', label: '_ORDER_SHOP_', placeholder: '_ORDER_EXPLAIN_NUMBER_', render: false, value: ''},
					order_me_homepage: {type: 'input', label: '_ORDER_SHOP_HOMEPAGE_', placeholder: '_ORDER_EXPLAIN_NUMBER_', render: false, value: ''},
					shop_categories: {type: 'divider', title: '_CATEGORIES_SHOP_', render: false},
					id_invoice_product_category: {type: 'checkbox_group', items: [], render: false},

					privacy_settings: {type: 'divider', title: '_PRIVACY_SETTINGS_', render: false},
					hide_from_shop: {type: 'checkbox', title: '_NOT_VISIBLE_IN_SHOP_', checked: false, value: 1, render: false},
					disabled_from_shop: {type: 'checkbox', title: '_DISABLE_IN_SHOP_', checked: false, value: 1, render: false},
					disabled_sharing: {type: 'checkbox', title: '_DISABLE_SHARING_', checked: false, value: 1, render: false},

					agenda_settings: {type: 'divider', title: '_AGENDA_SETTINGS_', render: false},
					number_appointments_week: {type: 'select', name: 'number_appointments_week', title: '_NUMBER_SUBSCRIPTIONS_PER_WEEK_', render: false, options: []},
					number_appointments_month: {type: 'select', name: 'number_appointments_month', title: '_NUMBER_SUBSCRIPTIONS_PER_MONTH_', render: false, options: []},

					stock_settings: {type: 'divider', title: '_STOCK_MANAGEMENT_', info: '_STOCK_INFO_', render: false},
					stock_enabled: {type: 'select', name: 'stock_enabled', title: '_ENABLE_', render: false, options: []},
					stock_is_public: {type: 'select', name: 'stock_is_public', title: '_SHOW_STOCK_ON_PRODUCTPAGE_', render: false, options: []},

					stock_current_supply: {type: 'divider', title: '', info: '', render: false},
					stock_plus: {type: 'input', label: '_INCREASE_STOCK_BY_', placeholder: '_ENTER_NUMBER_POSITIVE_', render: false, value: ''},
					stock_minus: {type: 'input', label: '_DECREASE_STOCK_BY_', placeholder: '_ENTER_NUMBER_POSITIVE_', render: false, value: ''},

					advanced_settings: {type: 'divider', title: '_SEPA_IMPORT_SETTINGS_', render: false},
					import_id_product_match: {type: 'input', label: '_IMPORT_MATCH_ID_', placeholder: '_ENTER_HERE_', render: false, value: ''},

					booking_form_settings: {type: 'divider', title: '_BOOKING_FORM_SETTINGS_', info: '_BOOKING_FORM_INFO_', render: false},
					booking_option: {type: 'select', name: 'booking_option', title: '_ENABLE_', render: false, options: []},
					text_booking_page: {type: 'divider', title: '_TEXT_BOOKING_PAGE_', info: '_TEXT_BOOKING_PAGE_INFO_', render: false},
				},

				submit: {text: '_SAVE_', render: false},
				values: {},
			}
		};
  },

	watch: {
		// Set the description for requested product type
		productTypeID(id) {
			/* Ignore when nog description available or other product description when agenda not enabled */
			if(this.productTypeDescriptions.length == 0 || (!this.agendaEnabled && id == 4)) return;
			this.form.fields.explain_product_type.text = this.productTypeDescriptions[id -1];
			this.form.fields.explain_product_type.render = true;
		}
	},

	methods: {
		changed(field, option) {
			if(!field.name) return;

			// Check which option was selected and render form fields needed!
			switch(field.name) {
				case 'id_invoice_product_type':
					// Disable all fields first!
					for(var field in this.form.fields) {
						if(field == 'id_invoice_product_type') continue;
						this.form.fields[field].render = false;
						this.form.submit.render = false;
					}

					// Disable booking fields here!
					this.toggleBooking(false, false);
					
					// No valid option selected, do nothing...
					if(option.value == 0) return;

					// Set the product type id user switched to
					this.productTypeID = option.value;

					if(this.productTypeID != 4) {
						this.addBookingFields();
					}

					// Only render stock settings field for none subscriptions
					if(this.productTypeID != 1) {
						this.form.fields.stock_settings.render = true;
						this.form.fields.stock_enabled.render = true;
					}

					// Enable stock plus and minus number fields here
					this.toggleStock(this.stock.enabled);

					// Reset the book default selected booking option to no when we switch
					this.form.fields.booking_option.options.forEach((option) => option.selected = option.value == 3);

					// Call the render function we need!
					this[this.renderFunctions[option.value -1]]();

					break;

				case 'stock_enabled':
					// Enable or Disable stock plus and minus number fields here
					this.toggleStock(option.value == 1);

					break;

				case 'account_not_allowed':
					// Do nothing, this type has no booking and no agenda either
					if(this.productTypeID == 4) return;

					// Reset the book default selected booking option and toggle booking
					this.form.fields.booking_option.options.forEach((option) => option.selected = option.value == 3);
					if(field.checked) {
						this.toggleBooking(false, false);
					} else {
						this.toggleBooking(true, false);
					}

					this.toggleAgendaSettings(!field.checked);

					break;

				case 'booking_option':
					this.form.values.booking_option = option.value;
					this.toggleBooking(true, option.value != 3);

					break;
			}
		},

		openFotoUpload() {
			this.uploadOpened = true;
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			if(this.id_product) data.id_invoice_product = this.id_product;
			if(this.productTypeID) data.id_invoice_product_type = this.productTypeID;

			// Back-end expects these fields formatted this way :(
			if(this.productTypeID == 1) {
				data.id_invoice_product_type = this.productTypeID;
				data.pm_installments_autoincasso = 1;
				data.amount_installments = this.form.values.amount_in_vat;
				data.vat_percentage_installments = this.form.values.vat_percentage;
			}

			// Set booking values if booking was enabled
			if((this.form.values.booking_option && this.form.values.booking_option != 3) || this.productTypeID == 3 /* Free trial */) {
				data = Object.assign(data, this.getBookingValues());
			}

			// Fix installment length
			data['length_period'] = this.form.values.length;

			$AjaxHelper.doRequest('invoiceProduct', 'saveProduct', Object.assign({idc: this.idc}, data), {callback: this._saved});
		},

		renderSubscription(data) {
			if(this.agendaEnabled) {
				// Subscribe settings for installment product (times per month or week)
				this.setAgendaSubscribeSettings();

				// Render agenda setting fields
				var bookingAndAgendaSettingsEnabled = true;
				if(data && data.resultset && data.resultset.account_not_allowed && data.resultset.account_not_allowed) bookingAndAgendaSettingsEnabled = false;

				this.toggleAgendaSettings(bookingAndAgendaSettingsEnabled);

				// Render booking fields
				this.toggleBooking(bookingAndAgendaSettingsEnabled, (data && data.resultset && data.resultset.booking_option != 3));
			}

			// Enable all fields that should be rendered
			this.form.fields.name.render = true;
			this.form.fields.description.render = true;
			this.form.fields.number_installments.render = true;
			this.form.fields.collect_every.render = true;
			this.form.fields.vat_percentage.render= true;
			this.form.fields.amount_in_vat_first_installment.render = true;
			this.form.fields.amount_in_vat.render = true;

			this.form.fields.shop_settings.render = true;
			this.form.fields.thanks_url_divider.render = true
			this.form.fields.thanks_url.render = true;
			this.form.fields.order_me.render = true;
			this.form.fields.order_me_homepage.render = true;
			this.form.fields.shop_categories.render = this.categories.length > 0 ? true : false;
			this.form.fields.id_invoice_product_category.render = true;

			this.form.fields.privacy_settings.render = true;
			this.form.fields.hide_from_shop.render = true;
			this.form.fields.disabled_from_shop.render = true;
			this.form.fields.disabled_sharing.render = true;
			this.form.fields.sale_settings.render = true;
			this.form.fields.account_not_allowed.render = true;
			this.form.fields.max_sales_per_customer.render = true;
			
			if(this.showImportMatchIDField) {
				this.form.fields.advanced_settings.render = true;
				this.form.fields.import_id_product_match.render = true;
			}
			
			this.form.submit.render = true;
		},

		renderCardWithCredits(data) {
			if(this.agendaEnabled) {
				// Render booking fields
				this.toggleBooking(true, (data && data.resultset.booking_option != 3));
			}

			// Enable all fields that should be rendered
			this.form.fields.name.render = true;
			this.form.fields.description.render = true;
			this.form.fields.payment_method.render = true;
			this.form.fields.amount.render = true;
			this.form.fields.vat_percentage.render = true;


			this.form.fields.thanks_url_divider.render = true
			this.form.fields.thanks_url.render = true

			this.form.fields.shop_settings.render = true;
			this.form.fields.order_me.render = true;
			this.form.fields.order_me_homepage.render = true;
			this.form.fields.shop_categories.render = this.categories.length > 0 ? true : false;
			this.form.fields.id_invoice_product_category.render = true;

			this.form.fields.privacy_settings.render = true;
			this.form.fields.hide_from_shop.render = true;
			this.form.fields.disabled_from_shop.render = true;
			this.form.fields.disabled_sharing.render = true;
			this.form.fields.sale_settings.render = true;
			this.form.fields.max_sales_per_customer.render = true;

			this.form.fields.credits_settings.render = true;
			this.form.fields.number_credits.render = true;
			this.form.fields.period_valid.render = true;

			// Clear the info on this field
			this.form.fields.sale_settings.info = '';

			this.enablePaymentMethods();
			this.setCardWithCreditsSettings();

			this.form.submit.render = true;
		},

		renderFreeTrial() {
			// Render booking fields
			this.booking.render = true;

			// Enable all fields that should be rendered
			this.form.fields.name.render = true;
			this.form.fields.description.render = false;
			this.form.fields.sale_settings.render = true;
			this.form.fields.max_sales_per_customer.render = true;
			this.form.fields.number_credits.render = true;
			this.form.fields.period_valid.render = true;
			this.form.fields.thanks_url_divider.render = true;
			this.form.fields.thanks_url.render = true;

			this.setCardWithCreditsSettings();

			this.form.submit.render = true;
		},

		renderOther() {
			// Enable all fields that should be rendered
			this.form.fields.name.render = true;
			this.form.fields.description.render = true;
			this.form.fields.payment_method.render = true;
			this.form.fields.amount.render = true;
			this.form.fields.vat_percentage.render = true;

			this.form.fields.shop_settings.render = true;
			this.form.fields.thanks_url_divider.render = true;
			this.form.fields.thanks_url.render = true;
			this.form.fields.order_me.render = true;
			this.form.fields.order_me_homepage.render = true;
			this.form.fields.shop_categories.render = this.categories.length > 0 ? true : false;
			this.form.fields.id_invoice_product_category.render = true;

			this.form.fields.privacy_settings.render = true;
			this.form.fields.hide_from_shop.render = true;
			this.form.fields.disabled_from_shop.render = true;
			this.form.fields.disabled_sharing.render = true;
			this.form.fields.sale_settings.render = true;
			this.form.fields.max_sales_per_customer.render = true;
			this.form.fields.account_not_allowed.render = true;

			this.enablePaymentMethods();

			this.form.submit.render = true;
		},

		toggleAgendaSettings(render) {
			this.form.fields.agenda_settings.render = render;
			this.form.fields.number_appointments_week.render = render;
			this.form.fields.number_appointments_month.render = render;
		},

		toggleBooking(renderTitleWithOptions, renderForm) {
			// If enabled, we need to show these always
			this.form.fields.booking_form_settings.render = renderTitleWithOptions;
			this.form.fields.booking_option.render = renderTitleWithOptions;

			this.booking.render = renderForm;
		},

		toggleStock(render) {
			// If enabled, we need to show these always
			this.form.fields.stock_minus.render = render;
			this.form.fields.stock_plus.render = render;
			this.form.fields.stock_is_public.render = render;
			
			this.form.fields.stock_current_supply.title = `${$LanguageHelper.translate('_THE_CURRENT_SUPPLY_IS_')} ${this.stock.currentSupply}`;
			this.form.fields.stock_current_supply.render = render;
		},

		// Call this function when we switch the product type
		addBookingFields() {
			var bookingWelcome = null;
			var bookingEmail = null;

			// When editing we need the right product type id because the contents it in here
			if(this.productTypeID > 0) {
				bookingWelcome = this.bookingCustomTexts.booking_welcome.invoice_product_types[this.productTypeID  -1];
				bookingEmail = this.bookingCustomTexts.booking_email.invoice_product_types[this.productTypeID  -1];
			}
			// When creating we can just take the first one, it's empty anyways...
			else {
				bookingWelcome = this.bookingCustomTexts.booking_welcome.invoice_product_types[0];
				bookingEmail = this.bookingCustomTexts.booking_email.invoice_product_types[0];
			}

			// Fall back
			if(!bookingWelcome) bookingWelcome = this.bookingCustomTexts.booking_welcome.invoice_product_types[0];
			if(!bookingEmail) bookingEmail = this.bookingCustomTexts.booking_email.invoice_product_types[0];

			// Set booking welcome defaults
			this.booking.booking_welcome = {
				languages: {label: '_SELECT_LANGUAGE_', options: bookingWelcome.languages},
				id_custom_text_type: bookingWelcome.id_custom_text_type,
				language: '',
				texts: {},
				fields: {},
			};

			// Set the current language for the booking welcome
			bookingWelcome.languages.forEach((language) => {
				if(language.selected == 1) {
					this.booking.booking_welcome.language = language.code;
				}
			});

			// Set booking email defaults
			this.booking.booking_email = {
				languages: {label: '_SELECT_LANGUAGE_', options: bookingEmail.languages},
				id_custom_text_type: bookingEmail.id_custom_text_type,
				language: '',
				texts: {},
				fields: {},
				tags: bookingEmail.tags
			};

			// Set the current language for the booking email
			bookingEmail.languages.forEach((language) => {
				if(language.selected == 1) {
					this.booking.booking_email.language = language.code;
				}
			});

			// Set the values for available languages
			bookingWelcome.languages.forEach((language) => {
				// Set welcome text values
				this.booking.booking_welcome.fields[language.code] = {
					booking_welcome_title: {label: '_TITLE_', value: bookingWelcome.texts.custom[language.code] ? bookingWelcome.texts.custom[language.code].title : ''},
					booking_welcome_text: {label: '_TEXT_BOOKING_PAGE_', value: bookingWelcome.texts.custom[language.code] ? bookingWelcome.texts.custom[language.code].text : ''},
				}

				this.booking.booking_email.fields[language.code] = {
				booking_email_title: {label: '_SUBJECT_', value: bookingEmail.texts.custom[language.code] ? bookingEmail.texts.custom[language.code].title : bookingEmail.texts.templates[language.code].title},
					booking_email_text: {label: '_TEXT_', value: bookingEmail.texts.custom[language.code] ? bookingEmail.texts.custom[language.code].text : bookingEmail.texts.templates[language.code].text},
				}

				// Set the default template text including tags so user can revert to it!
				this.booking.booking_email.texts[language.code] = {
					templates: bookingEmail.texts.templates[language.code] ? bookingEmail.texts.templates[language.code]: '',
				};
			});
		},

		switchBookingLanguage(type) {
			let languageCode = $(event.target).find('option:selected').attr('value');
			if(type == 'page') {
				this.booking.booking_welcome.language = languageCode;
			} else if(type == 'mail') {
				this.booking.booking_email.language = languageCode;
			}
		},

		enablePaymentMethods() {
			if(this.form.fields.pm_1.added) this.form.fields.pm_1.render = true;
			if(this.form.fields.pm_2.added) this.form.fields.pm_2.render = true;
			if(this.form.fields.pm_3.added) this.form.fields.pm_3.render = true;
			if(this.form.fields.pm_4.added) this.form.fields.pm_4.render = true;
			if(this.form.fields.pm_5.added) this.form.fields.pm_5.render = true;
			if(this.form.fields.pm_6.added) this.form.fields.pm_6.render = true;
			if(this.form.fields.pm_7.added) this.form.fields.pm_7.render = true;
			if(this.form.fields.pm_8.added) this.form.fields.pm_8.render = true;
			if(this.form.fields.pm_9.added) this.form.fields.pm_9.render = true;
			if(this.form.fields.pm_10.added) this.form.fields.pm_10.render = true;
		},

		setPaymentMethods(paymentMethods, data) {
			paymentMethods.forEach((paymentMethod) => {
				if(this.form.fields[paymentMethod.name]) {
					this.form.fields[paymentMethod.name].title = paymentMethod.label;
					this.form.fields[paymentMethod.name].added = true;
					this.form.fields[paymentMethod.name].checked = (data && data[paymentMethod.name] && data[paymentMethod.name] == 1 ? true: false);
				}
			});
		},

		setBookingEnableOptions(productTypes) {
			// Pick the first product type with booking options if there is no productTypeID to select from
			productTypes[0].bookingOptions.forEach((option) => {
				this.form.fields.booking_option.options.push({
					label: option.label,
					value: option.id,
					selected: option.id == 3
				});
			});
		},

		setAgendaSubscribeSettings() {
			this.form.fields.number_appointments_week.options.push({value: 0, selected: true, label: $LanguageHelper.translate('_UNLIMITED_')});
			this.form.fields.number_appointments_month.options.push({value: 0, selected: true, label: $LanguageHelper.translate('_UNLIMITED_')});

			for(var i = 1; i <= 60; i ++) {
			  this.form.fields.number_appointments_week.options.push({value: i, label: i, selected: false});
			}

			for(var i = 1; i <= 60; i ++) {
			  this.form.fields.number_appointments_month.options.push({value: i, label: i, selected: false});
			}
		},

		setCardWithCreditsSettings() {
			this.form.fields.number_credits.options.push({value: 0, label: '_CHOSE_AN_OPTION_', selected: true});
			// Number of credits
			for(var i = 1; i <= 500; i ++) {
			  this.form.fields.number_credits.options.push({value: i, label: i, selected: false});
			}

			// period valid
			this.form.fields.period_valid.options.push({value: 0, label: '_VALID_UNLIMITED_', selected: true});
			for (var i = 1; i <= 24; i ++) {
				this.form.fields.period_valid.options.push({value: 'w_' + i, label: i + ' ' + (i == 1 ? $LanguageHelper.translate('_WEEK_LOWER_') : $LanguageHelper.translate('_WEEKS_')), selected: false});
			}

			for (var i = 1; i <= 36; i ++) {
        this.form.fields.period_valid.options.push({value: i, label: i + ' ' + (i == 1 ? $LanguageHelper.translate('_MONTH_LOWER_') : $LanguageHelper.translate('_MONTHS_LOWER_')), selected: false});
      }
		},

		setInstallmentPeriodSettings(periods, length = 1) {
			periods.forEach((option) => {
				this.form.fields.collect_every.fields.period.options.push({
					label: option.label,
					value: option.id,
					selected: option.selected == 1
				});
			});

			// Number times period
			for(var i = 1; i <= 24; i ++) {
				this.form.fields.collect_every.fields.length.options.push({value: i, label: i, selected: length == i});
			}

			// Set the number of installments, the selected value is overwritten when the form values are set.
			this.form.fields.number_installments.options.push({value: 0, label: $LanguageHelper.translate('_ONGOING_'), selected: true});
			for(var i = 1; i <= 60; i ++) {
			  this.form.fields.number_installments.options.push({value: i, label: i, selected: false});
			}
		},

		setProductTypes(types) {
			if(types.length > 1) {
				// Push first option!
				this.form.fields.id_invoice_product_type.options.push({
					name: 'enter_choise',
					label: '_CHOSE_AN_OPTION_',
					value: 0,
					selected: true
				});

				// Push other ones from DB
				types.forEach((option) => {
					// Set descriptions
					this.productTypeDescriptions.push(option.description);

					// Dismiss the credits and free trial types if agenda is not enabled
					var allowed = true;
					if(!this.agendaEnabled && (option.id_invoice_product_type == 2 || option.id_invoice_product_type == 3)) allowed = false;

					if(allowed) {
						// Push options
						this.form.fields.id_invoice_product_type.options.push({
							name: option.systemName,
							label: option.label,
							value: option.id_invoice_product_type,
							selected: false
						});
					}
				});

				// Render the field only if there were multiple options available!
				this.form.fields.id_invoice_product_type.render = true;
			}
		},

		setStockOptions() {
			this.form.fields.stock_enabled.options.push({
				name: 'stock_enabled',
				label: '_YES_',
				value: 1,
				selected: this.stock.enabled ? true : false,
			});

			this.form.fields.stock_enabled.options.push({
				name: 'stock_enabled',
				label: '_NO_',
				value: 0,
				selected: !this.stock.enabled ? true : false,
			});

			this.form.fields.stock_is_public.options.push({
				name: 'stock_is_public',
				label: '_YES_',
				value: 1,
				selected: this.stock.public ? true : false,
			});

			this.form.fields.stock_is_public.options.push({
				name: 'stock_is_public',
				label: '_NO_',
				value: 0,
				selected: !this.stock.public ? true : false,
			});
		},

		setCategories(selected = []) {
			this.categories.forEach((item) => {
				this.form.fields.id_invoice_product_category.items.push({
					title: item.name,
					value: item.id_invoice_product_category,
					checked: selected.indexOf(item.id_invoice_product_category) > -1,
					render: true
				});
			});
		},

		setMaxSalesPerCustomer() {
			this.form.fields.max_sales_per_customer.options.push({value: 0, label: '_VALID_UNLIMITED_', selected: true});
			for(var i = 1; i <= 100; i ++) {
				this.form.fields.max_sales_per_customer.options.push({value: i, label: i, selected: false});
			}
		},

		getBookingValues() {
			let data = {
				booking_welcome: [],
				booking_email: [],
			};

			this.booking.booking_welcome.languages.options.forEach((language, i) => {
				data.booking_welcome.push({
					id_custom_text_type: this.booking.booking_welcome.id_custom_text_type,
					language: language.code,
					title: this.booking.booking_welcome.fields[language.code].booking_welcome_title.value,
					text: this.booking.booking_welcome.fields[language.code].booking_welcome_text.value,
				});

				data.booking_email.push({
					id_custom_text_type: this.booking.booking_email.id_custom_text_type,
					language: language.code,
					title: this.booking.booking_email.fields[language.code].booking_email_title.value,
					text: this.booking.booking_email.fields[language.code].booking_email_text.value,
				});
			});

			return data;
		},

		_product(data) {
			// Set the product type id
			this.productTypeID = data.resultset.id_invoice_product_type;
			this.agendaEnabled = data.agendaEnabled && data.agendaEnabled == 1 ? true : false;
			if(data.resultset.imageURL) this.imageURL = data.resultset.imageURL;
			if(data.customTexts) this.bookingCustomTexts = data.customTexts;

			// Back-end naming conventions mistake, fix it here!
			if(data.resultset.period_number) data.resultset.period = data.resultset.period_number;

			// Needs to have booking in the product type
			if(this.productTypeID !=4) {
				this.addBookingFields();
			}

			this.setProductTypes(data.productTypes);
			this.setBookingEnableOptions(data.productTypes);
			this.setMaxSalesPerCustomer();
			this.setStockOptions();
			
			if(data.periods) this.setInstallmentPeriodSettings(data.periods, data.resultset.length);
			if(data.categories && data.categories.length > 0)  {
				this.categories = data.categories;
				this.setCategories(data.selectedCategories ? data.selectedCategories : []);
			}

			if(data.paymentMethods) this.setPaymentMethods(data.paymentMethods, data.resultset);

			/*
			Set the the number weeks of months a card with credits is valid!
			We have to manipulate the resultset so it will automaticly select the right period
			*/
			if(data.resultset.number_weeks_valid && data.resultset.number_weeks_valid > 0) data.resultset['period_valid'] = `w_${data.resultset.number_weeks_valid}`;
			if(data.resultset.number_months_valid && data.resultset.number_months_valid > 0) data.resultset['period_valid'] = data.resultset.number_months_valid;

			// Fix, amount comes back as amount_in_vat
			if(data.resultset.amount_in_vat) data.resultset['amount'] = data.resultset.amount_in_vat;

			// Fix categories
			if(data.selectedCategories) {
				data.resultset['id_invoice_product_category'] = data.selectedCategories;
			}

			// Show the import field yes or no!
			this.showImportMatchIDField = data.sepa_import_needed == 1;

			// Set the regular form values
			this.form.values = data.resultset;

			// Set stock values here
			this.stock.enabled = data.resultset.stock_enabled && data.resultset.stock_enabled == 1 ? true : false;
			this.stock.public = data.resultset.stock_is_public && data.resultset.stock_is_public == 1 ? true : false;
			this.stock.currentSupply = data.resultset.stock_current_supply ? data.resultset.stock_current_supply : 0;
			this.form.fields.stock_minus.value = data.resultset.stock_minus ? data.resultset.stock_minus : '';
			this.form.fields.stock_plus.value = data.resultset.stock_plus ? data.resultset.stock_minus : '';

			// Only render the stock fields for non subscriptions
			if(this.productTypeID != 1) {
				this.form.fields.stock_settings.render = true;
				this.form.fields.stock_enabled.render = true;
			}
			
			// Set special individual checkbox values here, those are not set automaticly...
			this.form.fields.account_not_allowed.checked = data.resultset.account_not_allowed && data.resultset.account_not_allowed == 1 ? true : false;
			this.form.fields.disabled_from_shop.checked = data.resultset.disabled_from_shop && data.resultset.disabled_from_shop == 1 ? true : false;
			this.form.fields.disabled_sharing.checked = data.resultset.disabled_sharing && data.resultset.disabled_sharing == 1 ? true : false;
			this.form.fields.hide_from_shop.checked = data.resultset.hide_from_shop && data.resultset.hide_from_shop == 1 ? true : false;

			// Call the render function we need!
			this[this.renderFunctions[data.resultset.id_invoice_product_type -1]](data);

			// Render FAB to show the upload action button
			this.fab.buttons.upload.render = true;

			// Set enabled or disabled default for stock
			this.toggleStock(this.stock.enabled);

			this.edit = true;
			this.render = true;
		},

		_new(data) {
			this.agendaEnabled = data.agendaEnabled && data.agendaEnabled == 1 ? true : false;
			this.bookingCustomTexts = data.customTexts;
			this.setProductTypes(data.productTypes);
			this.setBookingEnableOptions(data.productTypes);
			this.setMaxSalesPerCustomer();
			this.setStockOptions();

			if(data.periods) this.setInstallmentPeriodSettings(data.periods);
			if(data.categories) this.setCategories(data.categories);
			if(data.paymentMethods) this.setPaymentMethods(data.paymentMethods);

			if(data.categories && data.categories.length > 0)  {
				this.categories = data.categories;
				this.setCategories();
			}

			// Show the import field yes or no!
			this.showImportMatchIDField = data.sepa_import_needed == 1;

			this.edit = false;
			this.render = true;
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Let our parent know there has been changes so it reloads the list!
				if(this.edit) {
					$AjaxHelper.doRequest('invoiceProduct', 'productAdmin', {idc: this.idc, id_invoice_product: this.id_product}, {callback: this._productEdited});
				} else {
					$AjaxHelper.doRequest('invoiceProduct', 'productAdmin', {idc: this.idc, id_invoice_product: data.id_invoice_product}, {callback: this._productAdded});
				}
			}
		},

		_productEdited(data) {
			this.$emit('edited', Object.assign({index: this.index}, data.resultset));

			// Close popup here, were done!
			this.opened = false;
		},

		_productAdded(data) {
			this.$emit('added', data.resultset);
			// Close popup here, were done!
			this.opened = false;
		}
	},

	created() {
		if(this.id_product) {
			$AjaxHelper.doRequest('invoiceProduct', 'productAdmin', {idc: this.idc, id_invoice_product: this.id_product}, {callback: this._product});
		} else {
			$AjaxHelper.doRequest('invoiceProduct', 'productCategories', {idc: this.idc}, {callback: this._new});
		}
	}
};
</script>

<style scoped>
.popover .content {
	padding: 12px;
}
</style>
