<template>
  <div v-show="visible">
    <list-form :params="form" @submitted="submitted">
    </list-form>
  </div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';

export default {
  components: {
    'list-form': ListForm,
  },

  props: {
    idc: {required: true},
  },

  data() {
    return {
      visible: false,
      form: {
        fields: {
          description: {type: 'textarea', label: '_DESCRIPTION_COMPANY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
        },

        values: {},
				submit: {text: '_SAVE_', render: true},
      }
    };
  },

  methods: {
    submitted() {
      $AjaxHelper.doRequest('gym', 'saveGymInfo', Object.assign({idc: this.idc}, JSON.parse(JSON.stringify(this.form.values))));
    },

    _initInfo(data) {
      if(data.status == 'OK') {
        if(data.resultset) this.form.values = data.resultset;
        this.visible = true;
      }
    },
  },

  created() {
    $AjaxHelper.doRequest('gym', 'getGymComplete', {idc: this.idc, id: this.idc}, {callback: this._initInfo});
  },
}
</script>
