<template>
	<div v-if="render" class="pageContent">
		<f7-card>
			<f7-card-content>
				<!-- Important notices -->
				<div v-if="this.hasRemarks()" class="item fontBold" :class="getStatusClass()">{{directDebit.info.status.language}}</div>
				<div v-if="directDebit.info.deactivated_by_system == 1" class="fontRed marginBottom10">{{$LanguageHelper.translate('_SUBSCRIPTION_DEACTIVATED_BY_SYSTEM_')}}</div>
				<div v-if="processing" class="fontRed marginBottom10">{{directDebit.info.status_description}}, {{$LanguageHelper.translate('_EDITING_NOT_POSSIBLE_')}}!</div>

				<template v-if="directDebit.info.finished == 0">
					<div class="item fontRed marginBottom10" v-if="directDebit.info.status.id == 3 || directDebit.info.status.id == 5">
						{{$FunctionsHelper.ucFirst($LanguageHelper.translate('_AT_'))}} {{directDebit.info.date_deactivation_end_display}} {{$LanguageHelper.translate('_SUBSCRIPTION_WIL_BE_RESUMED_')}}
					</div>

					<div class="item fontRed marginBottom10" v-if="directDebit.info.status.id == 2 || directDebit.info.status.id == 4">
						{{$FunctionsHelper.ucFirst($LanguageHelper.translate('_AT_'))}} {{directDebit.info.date_deactivation_start_display}} {{$LanguageHelper.translate('_SUBSCRIPTION_WIL_BE_DEACTIVATED_')}}
					</div>
				</template>

				<template v-else>
					<div class="item fontBold marginBottom10">{{$LanguageHelper.translate('_SUBSCRIPTIONS_IS_FINISHED_')}}</div>
				</template>

				<!-- General info -->
				<div class="item fontBold">{{$LanguageHelper.translate('_GENERAL_')}}</div>
				<div v-if="directDebit.info.appuser_name && directDebit.info.appuser_name.length > 0" class="item">{{$LanguageHelper.translate('_CONNECTED_TO_')}}: {{$FunctionsHelper.specialChars(directDebit.info.appuser_name)}}</div>
				<div class="item">{{$FunctionsHelper.specialChars(directDebit.info.name)}}</div>
				<div class="item">{{$LanguageHelper.translate('_STATUS_')}}: {{directDebit.info.status.language.toLowerCase()}}</div>

				<!-- Planned changes -->
				<template v-if="directDebit.plannedChanges.length > 0 && renderPlannedChanges">
					<div class="item fontBold marginTop10 marginBottom15 fontOrange">{{$LanguageHelper.translate('_PLANNED_CHANGES_')}}</div>
					<f7-list class="marginBottom5">
						<f7-list-item accordion-item v-for="(change, key) in directDebit.plannedChanges" :key="key" :title="`${change.process_date.label} ${change.process_date.val}`" class="smallTitle summaryItem">
							<f7-accordion-content>
								<div>{{change.change.label}} {{change.change.val}}</div>
								<div class="item">{{change.start_date.label}} {{change.start_date.val}}</div>

								<f7-block class="marginTop10 marginBottom20">
									<f7-button @click="undoFutureChange(change.id.val)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
								</f7-block>
			        </f7-accordion-content>
			      </f7-list-item>
					</f7-list>
				</template>

				<!-- Data creditor (invoice data) -->
				<div class="item fontBold marginTop10">{{$LanguageHelper.translate('_DATA_CREDITOR_')}}</div>
				<div v-if="directDebit.info.company && directDebit.info.company.length > 0" class="item">{{$FunctionsHelper.specialChars(directDebit.info.company)}}</div>
				<div class="item">{{directDebit.info.firstname}} {{$FunctionsHelper.specialChars(directDebit.info.lastname)}}</div>
				<div class="item">{{directDebit.info.street}} {{$FunctionsHelper.specialChars(directDebit.info.housenumber)}}</div>
				<div class="item">{{directDebit.info.zipcode}} {{$FunctionsHelper.specialChars(directDebit.info.city)}}</div>
				<div class="item">{{directDebit.info.country}}</div>

				<!-- Payment structure data -->
				<div class="item fontBold marginTop10">{{$LanguageHelper.translate('_PAYMENT_DATA_')}}</div>
				<div v-if="nextInstallment.length > 0" class="item">{{$LanguageHelper.translate('_UPCOMING_INSTALLMENT_')}}: {{nextInstallment}}</div>
				<div v-if="directDebit.info.iban != ''" class="item">{{$LanguageHelper.translate('_IBAN_')}}: {{directDebit.info.iban}}</div>
				<div class="item">{{$LanguageHelper.translate('_AMOUNT_PERIODS_IN_VAT_')}}: &euro; {{directDebit.info.amount_in_vat}}</div>
				<div class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{directDebit.info.number_installments > 0 ? directDebit.info.number_installments : $LanguageHelper.translate('_CONTINUOUSLY_')}}</div>
				<div class="item">{{$LanguageHelper.translate('_PAYMENT_')}}: {{$LanguageHelper.translate('_EVERY_')}} {{directDebit.info.length > 1 ? directDebit.info.length : ''}} {{directDebit.info.period}}</div>
				<div class="item">{{$LanguageHelper.translate('_CREATED_ON_')}}: {{directDebit.info.created_on}}</div>

				<!-- Sale data -->
				<div class="item fontBold marginTop10">{{$LanguageHelper.translate('_SALE_')}}</div>
				<div class="item">{{$LanguageHelper.translate('_SALE_NUMBER_')}}: {{directDebit.info.sale_id}}</div>
				<div class="item">
					{{$LanguageHelper.translate('_LINK_TO_ORDER_DETAILS_PAGE_')}}&nbsp;&nbsp;<a class="link theme" @click="copyToClipboard(directDebit.info.order_details_url)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a><br>
					<a :href="directDebit.info.order_details_url" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{directDebit.info.order_details_url}}</a>
				</div>

				<!-- Order detail token -->
				<div class="item marginTop10"><b>{{$LanguageHelper.translate('_TOKEN_ORDER_DETAILS_PAGE_')}}</b>&nbsp;&nbsp;<a class="link theme" @click="copyToClipboard(directDebit.info.order_details_token)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a></div>
				<div class="item">{{directDebit.info.order_details_token}}</div>
			</f7-card-content>
		</f7-card>

		<!-- Manage Direct Debit Deactivation -->
		<manage-direct-debit-deactivation v-if="fab.info.buttons.plan_deactivation.clicked"
			:id="id"
			:idc="idc"
			:date_deactivation_start_options="directDebit.info.deactivation_options"
			:date_deactivation_end_options="directDebit.info.activation_options"
			@changed="$emit('changed', $event)"
			@close="fab.info.buttons.plan_deactivation.clicked=false"
		/>

		<!-- Manage Direct Debit Activation -->
		<manage-direct-debit-activation v-if="fab.info.buttons.plan_activation.clicked"
			:id="id"
			:idc="idc"
			:date_deactivation_end="directDebit.info.date_deactivation_end && directDebit.info.date_deactivation_end.length > 0 ? directDebit.info.date_deactivation_end : ''"
			@changed="$emit('changed', $event)"
			@close="fab.info.buttons.plan_activation.clicked=false"
		/>

		<!-- Manage Direct Debit Data -->
		<manage-direct-debit-data v-if="fab.info.buttons.edit.clicked"
			:idc="idc"
			:directDebit="directDebit"
			:openedViaCustomer="openedViaCustomer"
			@changed="$emit('changed', $event)"
			@close="fab.info.buttons.edit.clicked=false"
		/>
	</div>
</template>

<script>
import CopyToClipboard from '../../js/mixins/copyToClipboard';
import ManageDirectDebitActivation from '../manage-direct-debit-activation.vue';
import ManageDirectDebitDeactivation from '../manage-direct-debit-deactivation.vue';
import ManageDirectDebitData from '../manage-direct-debit-data.vue';

export default {
	mixins: [CopyToClipboard],

	components: {
		'manage-direct-debit-activation': ManageDirectDebitActivation,
		'manage-direct-debit-deactivation': ManageDirectDebitDeactivation,
		'manage-direct-debit-data': ManageDirectDebitData,
	},

	props: {
		idc: {required: true},
		id: {required: true},
		openedViaCustomer: {required: false, default: false},
		fab: {required: true},
	},

	data() {
		return {
			render: false,
			renderPlannedChanges: false,
			directDebit: {
				info: {},
				plannedChanges: []
			}
		}
	},

	computed: {
		activate() {
			// Direct Debit is processing an installment now!
			if(this.processing) return false;

			if(this.directDebit.info.deactivated_by_system != 1 && this.directDebit.info.reactivating != 1) {
				if(this.directDebit.info.status.id == 3 || this.directDebit.info.status.id == 5) return true;
				else if(this.directDebit.info.status.id == 6 && this.directDebit.info.finished == 0) return true;
			}

			return false;
		},

		deactivate() {
			// Direct Debit is processing an installment now!
			if(this.processing) return false;

			if(this.directDebit.info.deactivated_by_system != 1 && this.directDebit.info.reactivating != 1) {
				if(this.directDebit.info.status.id == 1 || this.directDebit.info.status.id == 2 || this.directDebit.info.status.id == 4) return true;
			}

			return false;
		},

		edit() {
			// Direct Debit is processing an installment now!
			if(this.processing) return false;

			if(this.directDebit.info.deactivated_by_system != 1 && this.directDebit.info.reactivating != 1) {
				if(this.directDebit.info.status.id == 1 || this.directDebit.info.status.id == 2 || this.directDebit.info.status.id == 3 || this.directDebit.info.status.id == 5) return true;
			}

			return false;
		},

		nextInstallment() {
			if(this.directDebit.info.installments_active == 1 && this.directDebit.info.date_process_direct_debit && this.directDebit.info.date_process_direct_debit.length > 0) {
        return (this.directDebit.info.date_process_direct_debit == this.directDebit.info.date_deactivation_start ? (this.directDebit.info.date_deactivation_end && this.directDebit.info.date_deactivation_end.length > 0 && this.directDebit.date_deactivation_end != '0000-00-00' ? this.directDebit.info.date_deactivation_end : '') : $LanguageHelper.formatDate(this.directDebit.info.date_process_direct_debit));
      }

			return '';
		},

		processing() {
			// Direct Debit is processing an installment at the moment?
			return this.directDebit.info.id_payment_status == 1
		},
	},

	methods: {
		load() {
			// Reset planned changes!
			this.renderPlannedChanges = false;
			this.directDebit.plannedChanges = [];

			$AjaxHelper.doRequest('payment', 'getSubscription', {idc: this.idc, id: this.id}, {callback: this._load});
		},

		undoFutureChange(id) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequestExtraLogin('payment', 'removeChange', {idc: this.idc, id: id, id_sale: this.directDebit.info.id}, {callback: this._futureChangeRemoved})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		setPlannedChanges(changes) {
			// We have to convert this weird structure here we got from the back-end!
			changes.forEach((change) => {
				let arrValues = Object.values(change);
				let arrKeys = Object.keys(change);

				this.directDebit.plannedChanges.push({
					process_date: {label: arrKeys[2], val: arrValues[2]},
					start_date: {label: arrKeys[3], val: arrValues[3]},
					change: {label: arrKeys[0], val: arrValues[0]},
					id: {label: arrKeys[1], val: arrValues[1]},
				});
			});
		},

		getStatusClass() {
			if(!this.hasRemarks) return '';
			if(this.directDebit.info.status.id != 1 && this.directDebit.info.status.id != 6) return 'fontOrange';
			if(this.directDebit.info.status.id == 6) return 'fontRed';
		},

		hasRemarks() {
			if(this.directDebit.info.status.id != 1 && this.directDebit.info.status.id != 6) return true;
			if(this.directDebit.info.status.id == 6) return true;
			return false;
		},

		_load(data) {
			// Store the data here
			this.directDebit.info = data.resultset.sale;
			this.directDebit.countries = data.resultset.countries;
			this.directDebit.languages = data.resultset.languages;
			this.directDebit.products = data.resultset.products;
			this.directDebit.periods = data.resultset.periods;
			this.directDebit.agendaEnabled = data.agendaEnabled;

			// Only render the buttons if the direct debit has no installment currently in progress!
			if(!this.processing) {
				this.fab.info.buttons.edit.render = this.edit;
				this.fab.info.buttons.plan_deactivation.render = this.deactivate;
				this.fab.info.buttons.plan_activation.render = this.activate;
			}

			// Set future planned changes!
			if(data.resultset.sale.changes && data.resultset.sale.changes.length > 0) {
				this.setPlannedChanges(data.resultset.sale.changes);
			}

			// Render page
			this.render = true;
			this.renderPlannedChanges = true
		},

		_futureChangeRemoved(data) {
			if(data.status == 'OK') this.load();
		}
	},

	created() {
		this.load();
	}
};
</script>
