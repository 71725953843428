<template>
	<popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<account-form @close="opened=false"></account-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import AccountForm from '../components/account-form.vue';

export default {
	components: {
    'popup': Popup,
    'account-form': AccountForm
	},

	data() {
		return {
			opened: false
		}
	},

	props: {
		title: {type: String, required: true},
  }
};
</script>
