<template>
	<popup
	:title="$LanguageHelper.translate('_EDIT_')"
	:opened="opened"
	@mounted="opened=true"
	@close="$emit('close')"
	>
		<list-form :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		category: {required: true},
  },

	data() {
    return {
			opened: false,

			form: {
				fields: {
					name: {type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			},
		};
  },

	methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			let paramsSend = [];
			paramsSend['ranking_categories'] = {};
			paramsSend.ranking_categories[this.category.id_wod_custom_ranking_category] = data.name;
			$AjaxHelper.doRequest('WOD', 'saveRankingCategories', Object.assign({idc: this.idc}, paramsSend), {callback: this._rankingsSubmitted});
		},

		_rankingsSubmitted(data) {
			if(data.status == 'OK') {
				this.opened = false;
				this.$emit('refresh');
			}
		}
	},

	created() {
		if(this.category) this.form.fields.name.value = this.category.name;
	}
};
</script>
