<template>
	<f7-list accordion-list inset>
		<list-item v-for="(event, key) in events.list" type="accordion"
			:key="`key_${key}`" 
			:title="event.name"
			:footer="event.created_on"
			>
			
			<f7-accordion-content>
				<f7-block class="marginBottom10">
					<div class="item">{{$LanguageHelper.translate('_CREATED_ON_')}}: {{event.created_on}}</div>
					<div class="item" v-if="event.date_start">{{$LanguageHelper.translate('_DATE_START_EVENT_')}}: {{event.date_start}}</div>
					<div class="item" v-if="event.date_end">{{$LanguageHelper.translate('_DATE_END_')}}: {{event.date_end}}</div>
				</f7-block>
					
				<f7-block class="marginBottom10">
					<f7-row class="marginBottom25">
						<f7-col v-if="$UserHelper.hasAccess('qr_access', 'scan', idc)">
							<f7-button @click="toQRScanner" raised fill small>{{$LanguageHelper.translate('_OPEN_QR_SCANNER_')}}</f7-button>
						</f7-col>
					</f7-row>

					<f7-row>
						<f7-col v-if="$UserHelper.hasAccess('qr_access', 'save', idc)">
							<f7-button @click="event.opened = true" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
						</f7-col>

						<f7-col v-if="$UserHelper.hasAccess('qr_access', 'remove', idc)">
							<f7-button @click="archive(event.id_qr_event, key)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
						</f7-col>
					</f7-row>
				</f7-block>
			</f7-accordion-content>

			<!-- Manage qr event edit popup here, contents is only rendered when opened is true -->
			<manage-qr-event v-if="event.opened"
				:idc="idc"
				:index="key"
				:id="event.id_qr_event"
				@edited="replaceListItem"
				@close="event.opened=false"
			/>
		</list-item>
	</f7-list>

	<!-- Add product popup here-->
	<manage-qr-event v-if="fab.buttons.add.clicked"
		:idc="idc"
		@added="addListItem"
		@close="fab.buttons.add.clicked=false"
	/>

	<!-- Add product archive popup here-->
	<manage-qr-event-archive v-if="fab.buttons.archive.clicked"
		:idc="idc"
		@added="addListItemFromArchive"
		@close="fab.buttons.archive.clicked=false"
	/>
</template>

<script>
import ListItem from '../../components/smart/list-item.vue';
import ManageQREvent from '../../popups/manage-qr-event.vue';
import ManageQREventArchive from '../../popups/manage-qr-event-archive.vue';

export default {
	components: {
		'list-item': ListItem,
		'manage-qr-event': ManageQREvent,
		'manage-qr-event-archive': ManageQREventArchive,
	},

	props: {
		idc: {required: true},
		sortSelected: {type: String, required: true, default: ''},
		searched: {type: String, required: true, default: ''},
		fab: {type: Object, required: true},
  },

	data() {
    return {
			render: false,
			events: {
				list: []
			}
		};
  },

	computed: {
		orderBy() {
			return this.sortSelected;
		}
	},

	methods: {
		toQRScanner() {
			return $ViewHelper.toBrowser(`${$Config.apps[$Config.app.currentApp].appversionURL}?page=qr-checkin-scanner&idc=${this.idc}&external=1`, !$Config.app.onDevice ? window.open() : null);
		},

		load() {
			$AjaxHelper.doRequest('eventWithScanAccess', 'getEvents', {idc: this.idc, order: this.orderBy}, {callback: this._events});
		},

		search(val) {
			if(val.length > 0) {
				$AjaxHelper.doRequest('eventWithScanAccess', 'searchEvents', {idc: this.idc, search: val, order: this.orderBy}, {callback: this._events});
			} else {
				this.load();
			}
		},

		renderList(items) {
			this.events.list = items;
		},

		replaceListItem(event) {
			this.events.list[event.index] = Object.assign(this.events.list[event.index], event);
		},

		removeListItem(key) {
			let list = this.events.list;

			list.splice(key, 1);
			this.events.list = [];

			// Wait a bit so accordion is closed!
			setTimeout(() => {this.events.list = list}, 100);
		},

		addListItem(event) {
			// Added needed props needed for opening the popup
			event['opened'] = false;

			// Add item to beginning of the list
			this.events.list.unshift(event);
		},

		addListItemFromArchive(event) {
			// Added needed props needed for opening the popup
			event['opened'] = false;
			event['sold'] = 1;

			// Add item to beginning of the list
			this.events.list.unshift(event);
		},

		archive(id, key) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('eventWithScanAccess', 'archiveEvent', {idc: this.idc, id: id, key: key}, {callback: this._archived})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		_events(data) {
			// Empty the list first
			this.events.list = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj, key) => {obj.opened = false});

				this.renderList(data.resultset);
			}
		},

		_archived(data) {
			if(data.status == 'OK') this.removeListItem(data.params_send.key);
		}
	},

	watch: {
		searched(val) {
			// Search for customers
			if(val.length > 0) {
				$AjaxHelper.doRequest('eventWithScanAccess', 'searchEvents', {search: val, order: this.orderBy, idc: this.idc}, {callback: this._events});
			}
			// No input, reset the list!
			else {
				this.load();
			}
		},

		sortSelected() {
			this.load();
		},
	},

	created() {
		// Get the products here!
		this.load();
	}
};
</script>
