<template>
	<popup :title="$LanguageHelper.translate('_SEND_PUSHMESSAGE_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<pushmessage-sender :idc="idc" :selectedList="selectedList" @submitted="opened=false" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import PushMessageSender from '../components/pushmessage-sender.vue';

export default {
  components: {
    'popup': Popup,
    'pushmessage-sender': PushMessageSender,
  },

	props: {
		idc: {required: true},
		selectedList: {required: false}, /* List of pre selected customers */
	},

	data() {
		return {
			opened: false,
		};
	},
};
</script>
