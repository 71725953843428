<template>
  <f7-navbar>
		<f7-nav-title v-if="title">{{title}}</f7-nav-title>

    <f7-nav-right>
			<f7-link v-if="search" class="searchbar-enable filterOption search" @click="searchEnabled=true"><i class="icon ionSearch"></i></f7-link>
			<f7-link v-if="filter" class="filterOption filter" @click="showFiltersClicked"><i class="icon ionFunnel" /></f7-link>
			<f7-link v-if="sortOptions" class="filterOption sort" popover-open="#popoverSort"><i class="icon ionArrowRoundDown" /><i class="icon ionArrowRoundUp" /></f7-link>

      <a href="#" class="link closePopup" @click="close">{{$LanguageHelper.translate('_CLOSE_')}}</a>
    </f7-nav-right>

		<!-- Search form -->
		<search-expandable v-if="search" :enabled="searchEnabled" @disable-searchbar="searchEnabled=false" @searched="searched" />

		<!-- Filter sort options -->
		<f7-popover v-if="sortOptions" id="popoverSort" ref="popoverSort">
			<f7-list no-hairlines>
				<f7-list-item>{{$LanguageHelper.translate(sortOptions.title)}}</f7-list-item>
				<f7-list-item radio v-for="(option, key) in sortOptions.options"
					:key="key"
					:value="option.value"
					:checked="option.selected"
					:title="$LanguageHelper.translate(option.label)"
					@change="sortOptionChanged(option)"
					name="sortOptions"
				/>
			</f7-list>
		</f7-popover>
  </f7-navbar>
</template>

<script>
import navbarPageAndPopup from '../../js/mixins/navbarPageAndPopup';

export default {
	mixins: [navbarPageAndPopup],

	props: {
		uid: {required: true},
		opened: {required: true}
  },

	watch: {
		opened(val) {
			// Emit close event to parent popup component if the opened value changes to false.
			if(!val) this.close();
		}
	},

	methods: {
		close() {
			// Emit close event
			this.$emit('close', this.uid);
		}
	}
};
</script>
