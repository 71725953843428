<template>
  <f7-navbar :back-link="back && $Config.app.onDevice">

		<!-- We need this custom backbutton here in the web enviroment because we can not change the default back button behaviour -->
		<div class="left" v-if="back && !$Config.app.onDevice">
			<a class="link icon-only ripple-inset customBackButton" href="#" @click="backButtonClicked"><i class="icon icon-back"></i></a>
		</div>

		<f7-nav-title v-if="title">{{title}}</f7-nav-title>

		<language-select-right-corner v-if="language" />
		
		<template v-if="dynamicComponent">
			<component :is="dynamicComponent.name" @changed="dynamicComponentChanged" :params="dynamicComponent.params ? dynamicComponent.params : {}" />
		</template>

		<f7-nav-right v-if="panel">
			<f7-link v-if="search" class="searchbar-enable filterOption search" @click="searchEnabled=true"><i class="icon ionSearch"></i></f7-link>
			<f7-link v-if="filter" class="filterOption filter" @click="showFiltersClicked"><i class="icon ionFunnel" /></f7-link>
			<f7-link v-if="sortOptions" class="filterOption sort" @click.stop="togglePopover"><span v-if="sortOptions.icon"></span><i :class="sortOptions.icon" /><span v-if="!sortOptions.icon"><i class="icon ionArrowRoundDown" /><i class="icon ionArrowRoundUp" /></span></f7-link>

			<f7-link panel-open="right">
        <f7-icon class="icon ionMenu">
          <f7-badge v-if="numberNotifications > 0" color="red" class="numberNotifications">{{numberNotifications}}</f7-badge>
        </f7-icon>
      </f7-link>
		</f7-nav-right>

		<!-- Search form -->
		<search-expandable v-if="search" :enabled="searchEnabled" @disable-searchbar="searchEnabled=false" @searched="searched" />

		<!-- Custom build popover element -->
		<popover :opened="popoverOpened" :right=60 :popOverEl="popOverEl" ref="popoverEl" @close="popoverOpened=false" @click.stop>
			<f7-list no-hairlines>
					<f7-list-item>{{$LanguageHelper.translate(sortOptions.title)}}</f7-list-item>
					<f7-list-item radio v-for="(option, key) in sortOptions.options"
						:key="key"
						:value="option.value"
						:checked="option.selected"
						:title="$LanguageHelper.translate(option.label)"
						@change="sortOptionChanged(option)"
						name="sortOptions"
					/>
				</f7-list>
		</popover>
	</f7-navbar>
</template>

<script>
import NavbarPageAndPopup from '../../js/mixins/navbarPageAndPopup';
import Notifications from '../../js/mixins/notifications';
import LanguageSelect from '../../components/language-select-right-corner.vue';
import Popover from '../../components/smart/popover.vue';

// These imports are for the dynamic components, if we have more then we should add them to the list. I was unable to find a way to import them dynamically with Vite :(
import AgendaNavbarSwitch from '../../components/agenda-navbar-switch.vue';
import ShoppingCartNavbar from '../../components/shopping-cart-navbar.vue';

export default {
	mixins: [NavbarPageAndPopup, Notifications],

	components: {
		'language-select-right-corner': LanguageSelect,
		'agenda-navbar-switch': AgendaNavbarSwitch,
		'shopping-cart-navbar': ShoppingCartNavbar,
		'popover': Popover
  },

  props: {
		language: {type: Boolean, required: false, default: false},
		panel: {type: Boolean, required: false, default: true},
		back: {type: Boolean, required: false, default: true},
  },

	data() {
    return {
      popOverEl: null
    };
  },	

	methods: {
		backButtonClicked() {
			// When custom back button is clicked, use regular history back so that the native history stack stays intact!
			if(!$Config.app.onDevice) {
				return $ViewHelper.router.nativeHistory.back();
			}

			// Native app behaviour
			return $ViewHelper.router.backButtonClicked();
		},

		togglePopover() {
			this.popoverOpened = !this.popoverOpened;
		}
	},

	computed: {
		dynamicComponentFile() {
			return this.dynamicComponent.name;
		},
	},

	mounted() {
    this.popOverEl = this.$refs.popOverEl;
  }
};
</script>

<style scoped>
.ios .ionMenu {
  font-size:28px;
  margin-right: 5px;
}

a.customBackButton {
	width: 40px!important;
}

</style>
