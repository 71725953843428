<template>
	<page
		:title="$LanguageHelper.translate('_MY_APPOINTMENTS_')"
		:infinite="true"
		class="myAppointments"
		@infinite-scroll-page-end="pageEndReached=true"
		@initiated="render=true"
	>
  	<agenda-my-appointments v-if="render" :idc="idc" :page-end-reached="pageEndReached" @page-end-loaded="pageEndReached=false" @changed="$.emit('changed')" />
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import AgendaMyAppointments from '../components/agenda-my-appointments.vue';

export default {
  components: {
    'page': Page,
		'agenda-my-appointments': AgendaMyAppointments,
  },

	props: {
		idc: {required: true},
	},

	data() {
		return {
			render: false,
			pageEndReached: false,
		}
	}
};
</script>
