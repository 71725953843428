<template>
	<page
		:title="title"
		:back="false"
		:panel="false"
		:language="true"
		:toolbar-branding="true"
		:backgroundImage="backgroundImage"
		:visible="visible"
		pageContentClass="companyBackground">

		<f7-card v-if="visible" class="marginTop35">
			<f7-card-header>{{header}}</f7-card-header>

			<f7-card-content>
				<span class="nl2br">{{responseData.message.length > 0 ? responseData.message : $LanguageHelper.translate('_BOOKING_THANKS_')}}</span>
			</f7-card-content>
		</f7-card>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
	components: {
    'page': Page,
  },

  data() {
    return {
			visible: false,
			backgroundImage: '',
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href), /* Query params from URL, there might be a company id in there */
			header: $LanguageHelper.translate('_ALMOST_READY_'),
			title: this.$Config.apps[this.$Config.app.currentApp].displayName,
			responseData: null
		};
  },

	//subscribedDirectly

  methods: {
		_bookingConfirmed(data) {
			if(data.status == 'OK') {
				// Set background images if there are any
				if(data.companyImages && data.companyImages.bg) this.backgroundImage = data.companyImages.bg;

				// Set company name in navbar title if it's there
				if(data.gyminfo) this.title = data.gyminfo.name;

				// If the existing user was subscribed directly and no further action is needed change the header message
				if(!data.params_send.id_reservation || data.params_send.id_reservation == 0) this.header = $LanguageHelper.translate('_IT_WORKED_');

				this.responseData = data;
				this.visible = true;
			}
		}
  },

	created() {
		$AjaxHelper.doRequest('booking', 'getBookingConfirmation', {id_reservation: (this.queryParams.rid ? this.queryParams.rid : 0), idc: this.queryParams.id, companyImages: 1}, {callback: this._bookingConfirmed});
	}
};
</script>
