<template>
	<popup :title="$LanguageHelper.translate('_PLAN_ACTIVATION_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id: {required: true},
		date_deactivation_end: {required: true},
  },

	data() {
    return {
			opened: false,

			form: {
				fields: {
					activate: {type: 'select', name: 'activate', title: '_REACTIVATE_BY_', render: true, options: []},
					date_deactivation_end: {type: 'date', label: '_SELECTED_PERIOD_WIL_BE_PROCESSED_', placeholder: '_SELECT_DATE_', render: this.date_deactivation_end.length > 0, value: this.date_deactivation_end},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		};
  },

	methods: {
		changed(field, option) {
			if(field.name == 'activate') this.form.fields.date_deactivation_end.render = option.value == 1;
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			// Empty reaction date if option for not reactivating is selected!
			if(data.activate == 0) data['date_deactivation_end'] = '';

			$AjaxHelper.doRequestExtraLogin('payment', 'activateSubscription', Object.assign({idc: this.idc, id: this.id}, data), {callback: this._saved});
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Let our parent know there has been changes so it reloads the list!
				this.$emit('changed', this.id);

				// Close popup here, were done!
				this.opened = false;
			}
		},
	},

	created() {
		this.form.fields.activate.options.push({
			label: $LanguageHelper.translate('_NEVER_REACTIVATE_'),
			value: 0,
			selected: this.date_deactivation_end.length == 0
		});

		this.form.fields.activate.options.push({
			label: $LanguageHelper.translate('_DATE_AT_'),
			value: 1,
			selected: this.date_deactivation_end.length > 0
		});
	}
};
</script>
