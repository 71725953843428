<template>
  <div>
		<f7-card v-if="showInfo" class="extraLogin">
			<f7-card-content>
				<div><b>{{$LanguageHelper.translate('_COMPANY_INFO_')}}</b></div>
        <div>{{records.name}}</div>
        <div>{{records.street + ' ' + records.housenumber + '' + records.housenumber_extra}}</div>
        <div>{{records.zipcode + ' ' + records.city}}</div>
        <div>{{records.country}}</div>
        <div>{{$LanguageHelper.translate('_VAT_NUMBER_') + ': ' + records.vat_number}}</div>
        <div>{{$LanguageHelper.translate('_CHAMBER_OF_COMMERCE_NUMBER_') + ': ' + records.chamber_of_commerce_number}}</div>
        <br />

        <div><b>{{$LanguageHelper.translate('_BANK_ACCOUNT_')}}</b></div>
        <div>{{records.iban}}</div>
        <div>{{records.bic}}</div>
        <div v-if="records.paypal_email">{{$LanguageHelper.translate('_PAYPAL_EMAIL_') + ': ' + records.paypal_email}}</div>
        <br />

        <div><b>{{$LanguageHelper.translate('_SIGNEES_')}}</b></div>
        <div>{{records.firstname + ' ' + records.lastname}}</div>
        <div>{{(records.gender == 'm') ? $LanguageHelper.translate('_MALE_') : $LanguageHelper.translate('_FEMALE_')}}</div>
        <div>{{records.birthday}}</div>

        <template v-for="(field, key) in records.signees" :key="key">
          <br />
          <div>{{field.firstname + ' ' + field.lastname}}</div>
          <div>{{(field.gender == 'm') ? $LanguageHelper.translate('_MALE_') : $LanguageHelper.translate('_FEMALE_')}}</div>
          <div>{{field.birthday}}</div>
        </template>
        <br />

        <div v-if="!editable" class="fontRed">{{$LanguageHelper.translate('_EMAIL_FOR_CHANGES_')}}: {{email}}</div>

				<template v-if="editable">
					<f7-button fill raised @click="edit" class="marginBottom20">{{$LanguageHelper.translate('_CHANGE_COMPANY_INFORMATION_')}}</f7-button>
					<f7-button fill raised @click="sendMerchant">{{$LanguageHelper.translate('_SEND_')}}</f7-button>
				</template>
			</f7-card-content>
		</f7-card>

		<div v-if="showForm">
	    <list-form :params="form" @submitted="submitted" @changed="changed">

	      <template #extra-items>
	        <f7-list class="noMarginTop">
	          <li class="marginLeft15 paddingTop10">
	            <f7-link @click.prevent="downloadPay">{{$LanguageHelper.translate('_PAY_TERMS_')}}</f7-link>
	          </li>
	          <li class="marginLeft15 marginTop5">
	            <f7-link @click.prevent="downloadPaynplan">{{$LanguageHelper.translate('_PAYNPLAN_TERMS_')}}</f7-link>
	          </li>
	          <li class="marginLeft15 marginTop5 paddingBottom10">
	            <f7-link @click.prevent="downloadRates">{{$LanguageHelper.translate('_PAYNPLAN_RATES_')}}</f7-link>
	          </li>
	        </f7-list>
	      </template>
	    </list-form>
  	</div>

    <f7-card v-if="showLogin" class="extraLogin">
			<f7-card-header>{{$LanguageHelper.translate('_LOGIN_')}}</f7-card-header>
			<f7-card-content>
				<div>{{$LanguageHelper.translate('_SECURED_BEHIND_LOGIN_')}}</div>
			</f7-card-content>

			<f7-card-footer class="footer">
				<f7-button fill raised @click="fetchMerchant">{{$LanguageHelper.translate('_LOGIN_')}}</f7-button>
			</f7-card-footer>
		</f7-card>
	</div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';

export default {
  components: {
    'list-form': ListForm,
  },

  props: {
    idc: {required: true},
  },

  data() {
    return {
      records: [],
      showInfo: false,
			showForm: false,
      showLogin: true,
      editable: false,
      email: this.$Config.apps[this.$Config.app.currentApp].siteEmail,

      form: {
        fields: {
          company_info: {type: 'divider', title: '_COMPANY_INFO_', render: true},
          name: {type: 'input', label: '_COMPANY_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          id_company_type: {type: 'select', title: '_COMPANY_TYPE_', render: true, options: {}},
          street: {type: 'input', label: '_STREET_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          housenumber: {type: 'input', label: '_HOUSENUMBER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          housenumber_extra: {type: 'input', label: '_HOUSENUMBER_EXTRA_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          zipcode: {type: 'input', label: '_ZIPCODE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          city: {type: 'input', label: '_CITY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          id_country: {type: 'select', title: '_COUNTRY_', render: true, options: {}},
          telephone: {type: 'input', label: '_PHONE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          vat_number: {type: 'input', label: '_VAT_NUMBER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          chamber_of_commerce_number: {type: 'input', label: '_CHAMBER_OF_COMMERCE_NUMBER_', placeholder: '_ENTER_HERE_', render: true, value: ''},

          bank_account: {type: 'divider', title: '_BANK_ACCOUNT_', render: true},
          bank_account_owner: {type: 'input', label: '_BANK_ACCOUNT_OWNER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          iban: {type: 'input', label: '_IBAN_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          bic: {type: 'input', label: '_BIC_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          paypal_account: {type: 'divider', title: '_PAYPAL_ACCOUNT_', render: true},
          paypal_email: {type: 'input', label: '_PAYPAL_EMAIL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          sole_proprietorship: {type: 'select', title: '_LEGAL_FORM_', render: true,
            options: {
              0: {name: 'sole_proprietorship', value: '1', label: '_SOLE_PROPRIETORSHIP_', selected: true},
              1: {name: 'sole_proprietorship_authorized', value: '2', label: '_SOLE_PROPRIETORSHIP_WITH_AUTHORIZED_', selected: false},
              2: {name: 'diffrent_proprietorship', value: '0', label: '_DIFFERENT_PROPRIETORSHIP_', selected: false},
            }
          },

          //single signee
          signee: {type: 'divider', title: '_SIGNEE_', render: true},
          gender: {type: 'select', title: '_GENDER_', render: true,
            options: {
              m: {name: 'm', value: 'm', label: '_MALE_', selected: true},
              v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false},
            }
          },
          firstname: {type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          lastname: {type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          email: {type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          birthdate: {type: 'date', label: '_BIRTH_DATE_', placeholder: '_SELECT_DATE_', render: true, value: ''},
          authorized: {type: 'select', title: '_AUTHORIZED_TO_SIGN_', render: false,
            options: {
              0: {name: 'no', value: '0', label: '_NO_', selected: true},
              1: {name: 'independent', value: '1', label: '_SIGN_INDEPENDENTLY_', selected: false},
              2: {name: 'shared', value: '2', label: '_SHARED_AUTHORIZED_', selected: false},
            }
          },
          ubo: {type: 'checkbox', title: 'UBO*', checked: false, value: 1, render: false},
          ubo_percentage: {type: 'input', label: '_UBO_PERCENTAGE_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          ubo_description: {type: 'description', text: '_UBO_DESCRIPTION_', render: false},
          add_signee: {type: 'button', title: '_ADD_EXTRA_SIGNEE_', name: 'add_signee', id: 1, render: false},

          //second signee
          signee_1: {type: 'divider', title: '_SIGNEE_', id: 1, render: false, action: {icon: 'ionTrash', name: 'remove_signee', id: 1, title: '_DELETE_'}},
          gender_1: {type: 'select', title: '_GENDER_', id: 1, render: false, excludeFromValues: false,
            options: {
              m: {name: 'm', value: 'm', label: '_MALE_', selected: true},
              v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false},
            }
          },
          firstname_1: {type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', id: 1, render: false, excludeFromValues: false, value: ''},
          lastname_1: {type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', id: 1, render: false, excludeFromValues: false, value: ''},
          email_1: {type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', id: 1, render: false, excludeFromValues: false, value: ''},
          birthdate_1: {type: 'date', label: '_BIRTH_DATE_', placeholder: '_SELECT_DATE_', id: 1, render: false, excludeFromValues: false, value: ''},
          authorized_1: {type: 'select', title: '_AUTHORIZED_TO_SIGN_', id: 1, render: false, excludeFromValues: false,
            options: {
              0: {name: 'no', value: '0', label: '_NO_', selected: true},
              1: {name: 'independent', value: '1', label: '_SIGN_INDEPENDENTLY_', selected: false},
              2: {name: 'shared', value: '2', label: '_SHARED_AUTHORIZED_', selected: false},
            }
          },
          ubo_1: {type: 'checkbox', title: 'UBO', checked: false, value: 1, id: 1, render: false, excludeFromValues: false},
          ubo_percentage_1: {type: 'input', label: '_UBO_PERCENTAGE_', placeholder: '_ENTER_HERE_', id: 1, render: false, excludeFromValues: false, value: ''},
          add_signee_1: {type: 'button', title: '_ADD_EXTRA_SIGNEE_', name: 'add_signee', id: 2, render: false},

          //third signee
          signee_2: {type: 'divider', title: '_SIGNEE_', id: 2, render: false, action: {icon: 'ionTrash', name: 'remove_signee', id: 2, title: '_DELETE_'}},
          gender_2: {type: 'select', title: '_GENDER_', id: 2, render: false, excludeFromValues: false,
            options: {
              m: {name: 'm', value: 'm', label: '_MALE_', selected: true},
              v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false},
            }
          },
          firstname_2: {type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', id: 2, render: false, excludeFromValues: false, value: ''},
          lastname_2: {type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', id: 2, render: false, excludeFromValues: false, value: ''},
          email_2: {type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', id: 2, render: false, excludeFromValues: false, value: ''},
          birthdate_2: {type: 'date', label: '_BIRTH_DATE_', placeholder: '_SELECT_DATE_', id: 2, render: false, excludeFromValues: false, value: ''},
          authorized_2: {type: 'select', title: '_AUTHORIZED_TO_SIGN_', id: 2, render: false, excludeFromValues: false,
            options: {
              0: {name: 'no', value: '0', label: '_NO_', selected: true},
              1: {name: 'independent', value: '1', label: '_SIGN_INDEPENDENTLY_', selected: false},
              2: {name: 'shared', value: '2', label: '_SHARED_AUTHORIZED_', selected: false},
            }
          },
          ubo_2: {type: 'checkbox', title: 'UBO', checked: false, value: 1, id: 2, render: false, excludeFromValues: false},
          ubo_percentage_2: {type: 'input', label: '_UBO_PERCENTAGE_', placeholder: '_ENTER_HERE_', id: 2, render: false, excludeFromValues: false, value: ''},
          add_signee_2: {type: 'button', title: '_ADD_EXTRA_SIGNEE_', name: 'add_signee', id: 3, render: false},

          //fourth signee
          signee_3: {type: 'divider', title: '_SIGNEE_', id: 3, render: false, action: {icon: 'ionTrash', name: 'remove_signee', id: 3, title: '_DELETE_'}},
          gender_3: {type: 'select', title: '_GENDER_', id: 3, render: false, excludeFromValues: false,
            options: {
              m: {name: 'm', value: 'm', label: '_MALE_', selected: true},
              v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false},
            }
          },
          firstname_3: {type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', id: 3, render: false, excludeFromValues: false, value: ''},
          lastname_3: {type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', id: 3, render: false, excludeFromValues: false, value: ''},
          email_3: {type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', id: 3, render: false, excludeFromValues: false, value: ''},
          birthdate_3: {type: 'date', label: '_BIRTH_DATE_', placeholder: '_SELECT_DATE_', id: 3, render: false, excludeFromValues: false, value: ''},
          authorized_3: {type: 'select', title: '_AUTHORIZED_TO_SIGN_', id: 3, render: false, excludeFromValues: false,
            options: {
              0: {name: 'no', value: '1', label: '_NO_', selected: true},
              1: {name: 'independent', value: '2', label: '_SIGN_INDEPENDENTLY_', selected: false},
              2: {name: 'shared', value: '3', label: '_SHARED_AUTHORIZED_', selected: false},
            }
          },
          ubo_3: {type: 'checkbox', title: 'UBO', checked: false, value: 1, id: 3, render: false, excludeFromValues: false},
          ubo_percentage_3: {type: 'input', label: '_UBO_PERCENTAGE_', placeholder: '_ENTER_HERE_', id: 3, render: false, excludeFromValues: false, value: ''},

          terms: {type: 'divider', title: '_TERMS_', render: true},
          agreed: {type: 'checkbox', title: '_AGREE_TERMS_', checked: false, value: 1, render: true},
          agreed_rates: {type: 'checkbox', title: '_AGREE_RATES_', checked: false, value: 1, render: true},
        },

        values: {},
				submit: {text: '_SAVE_', render: true},
      }
    };
  },

  methods: {
    changed(field, option) {
			let formData = JSON.parse(JSON.stringify(this.form.fields));
      let item = JSON.parse(JSON.stringify(field));

      if(item.id && item.name == 'add_signee') {
        $.each(formData, function (field, val) {
          if(val.id == item.id) {
            formData[field].render = true;
            formData[field].excludeFromValues = false;
          }
          if(val.name == 'add_signee' && val.id == item.id) {
            formData[field].render = false;
          }
          var IDButton = item.id + 1;
          if(val.name == 'add_signee' && val.id == IDButton) {
            formData[field].render = true;
            formData[field].excludeFromValues = false;
          }
        });
      }

      if(item.id && item.name == 'remove_signee') {
        $.each(formData, function (field, val) {
          if(val.id == item.id) {
            formData[field].render = false;
            formData[field].excludeFromValues = true;
          }

          if(val.name == 'add_signee') {
            formData[field].render = false;

            if(val.id <= item.id) {
              formData[field].render = false;
              formData[field].excludeFromValues = true;
            }

            if(val.id == item.id) {
              formData[field].render = true;
              formData[field].excludeFromValues = false;
            }
          }
        });
      }

      this.form.fields = formData;

      if(option) {
        if(option.name == "sole_proprietorship") {
          this.form.fields.authorized.render = false;
          this.form.fields.ubo.render = false;
          this.form.fields.ubo_percentage.render = false;
          this.form.fields.ubo_description.render = false;
          this.form.fields.add_signee.render = false;
        }
        if(option.name == "sole_proprietorship_authorized" || option.name == "diffrent_proprietorship") {
          this.form.fields.authorized.render = true;
          this.form.fields.ubo.render = true;
          this.form.fields.ubo_percentage.render = true;
          this.form.fields.ubo_description.render = true;
          this.form.fields.add_signee.render = true;
        }
      }
		},

		edit() {
			this.showInfo = false;
			this.showForm = true;
		},

    submitted() {
      let formData = JSON.parse(JSON.stringify(this.form.values));

			//set data correctly
      let data = [];
      data.signees = [];
      let cnt = 0;
      $.each(formData, function (field, val) {
        if(field.includes('_1')) {
          field = field.replace('_1', '');
            if(!data.signees[cnt]) data.signees[cnt] = {};
            data.signees[cnt][field] = val;
        } else if(field.includes('_2')) {
          field = field.replace('_2', '');
            if(!data.signees[cnt + 1]) data.signees[cnt + 1] = {};
            data.signees[cnt + 1][field] = val;
        } else if(field.includes('_3')) {
          field = field.replace('_3', '');
            if(!data.signees[cnt + 2]) data.signees[cnt + 2] = {};
            data.signees[cnt + 2][field] = val;
        } else {
          if(field == 'authorized') field = 'registrant_authorized';
          if(field == 'ubo') field = 'registrant_ubo';
          data[field] = val;
        }
      });

      if(!data.id_country) data['id_country'] = 1;
      if(!data.id_company_type) data['id_company_type'] = 1;
      if(!data.sole_proprietorship) data['sole_proprietorship'] = 1;

      $AjaxHelper.doRequestExtraLogin('Payment', 'saveMerchant', Object.assign({idc: this.idc}, data), {callback: this._merchantSaved});
    },

		sendMerchant() {
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('Payment', 'sendMerchant', {idc: this.idc}, {callback: this._merchantSaved});
			}}, $LanguageHelper.translate('_ARE_YOU_SURE_'), $LanguageHelper.translate('_SEND_MERCHANT_QUESTION_'));
		},

    downloadPay() {
      $ViewHelper.toBrowser('https://www.paynplan.nl/algemene-voorwaarden-pay.pdf');
    },

    downloadPaynplan() {
      $ViewHelper.toBrowser('https://www.paynplan.nl/algemene-voorwaarden.pdf');
    },

    downloadRates() {
      let url = $Config.app.baseURL + '?service=Payment&method=downloadPaynPlanRates&id_gym=' + this.idc + '&id_appuser_li=' + $UserHelper.getID() + '&token=' + $UserHelper.getToken();
			$ViewHelper.toBrowser(url);
    },

    setCountries(countries) {
			countries.forEach((item, key) => {
				item.name = item.code;
				this.form.fields.id_country.options[item.code] = item;
        this.form.fields.id_country.options[item.code].selected = (item.selected == 1 ? true : false);
        if(item.selected == 1) {
          this.records.country = item.label;
        }
			});
		},

    setCompanyType(companyTypes) {
			companyTypes.forEach((item, key) => {
        item.name = item.label;
				this.form.fields.id_company_type.options[item.name] = item;
        this.form.fields.id_company_type.options[item.name].selected = (item.selected == 1 ? true : false);
        if(item.selected == 1) {
					this.records.company_type = item.label;
        }
			});
		},

    setProprietorship(value) {
      let options = JSON.parse(JSON.stringify(this.form.fields.sole_proprietorship.options));
      let items = [];
      $.each(options, function (index, obj) {
        obj.selected = false;
        if(obj.value == value) obj.selected = true;
        items.push(obj);
      });

      this.form.fields.sole_proprietorship.options = items;
    },

    setSignees(data) {
      if(data.sole_proprietorship == "0" || data.sole_proprietorship == "2") {
        this.form.fields.authorized.render = true;
        this.form.fields.ubo.render = true;
        this.form.fields.ubo.checked = (data.ubo == 1 ? true : false);
        this.form.fields.ubo_percentage.render = true;
        this.form.fields.ubo_description.render = true;

        let form = this.form;
        if(data.signees.length > 0) {
          let cnt = 0;
          data.signees.forEach((items, key) => {
            if(items.birthdate_day != '') {
              if($LanguageHelper.current == 'nl_NL' && this.records) {
                this.records.signees[key].birthday = items.birthdate_day + '-' + items.birthdate_month + '-' + items.birthdate_year;
              }
						}
            cnt ++;

            $.each(items, function (index, obj) {
              let name = index + '_' + cnt;
              let signee = 'signee' + '_' + cnt;
              form.fields[signee].render = true;
              let addSignee = 'add_signee' + '_' + cnt;
              form.fields[addSignee].render = true;
              if(index == 'authorized' || index == 'gender') {
                let options = form.fields[name].options;
                $.each(options, function (index, key) {
                  key.selected = false;
                  if(key.value == obj) {
                    key.selected = true;
                  }
                });
              }

              if(index == 'ubo') {
                form.fields[name].checked = (obj == 1 ? true : false);
              }

              if(form.fields[name]) {
                form.fields[name].value = obj;
                form.fields[name].render = true;
              }
            });
    			});
        }
      }
		},

    setBirthdate(data) {
      if(data.birthdate_day && data.birthdate_day != '') {
        if(data.birthdate_day.toString().length < 2) {
          data.birthdate_day = '0' + data.birthdate_day;
        }
        if(data.birthdate_month.toString().length < 2) {
          data.birthdate_month = '0' + data.birthdate_month;
        }
        return data.birthdate_year + '-' + data.birthdate_month + '-' + data.birthdate_day;
      }
    },

    fetchMerchant() {
			this.showForm = false;
      this.showInfo = false;

      $AjaxHelper.doRequestExtraLogin('Payment', 'getMerchant', {idc: this.idc}, {callback: this._initMerchant});
    },

    _initMerchant(data) {
			this.showLogin = false;
      this.records = {};

      if(data.status == "OK") {
				this.records = data.resultset;

				this.setProprietorship(data.resultset.sole_proprietorship);

				// Existing one
				if(data.resultset.firstname && data.resultset.firstname != '') {
					this.showInfo = true;
					if(data.resultset.editable == 1) {
						this.editable = true;
						this.showForm = true;
					}
				}
				// New one
				else {
					this.showInfo = false;
					this.editable = true;
					this.showForm = true;
				}

				//@TODO remove this
				/*this.showInfo = false;
				this.editable = true;
				this.showForm = true;*/
				//@TODO remove this

				//set birthdate for info
        if($LanguageHelper.current == 'nl_NL') {
          this.records.birthday = data.resultset.birthdate_day + '-' + data.resultset.birthdate_month + '-' + data.resultset.birthdate_year;
        }

        // Add the countries
  			this.setCountries(data.resultset.countries);

        //add the companytypes
        this.setCompanyType(data.resultset.company_types);

        // Add the signees
        this.setSignees(data.resultset);

				// Set the form values
				this.form.values = data.resultset;
			}
    },

    _merchantSaved(data) {
      if(data.status == 'OK') {
        this.fetchMerchant();
      }
    }
  },

	created() {
		this.fetchMerchant();
  }
}
</script>

<style scoped>
.extraLogin {margin-top: 35px;}
.extraLogin .footer {padding: 15px;}
.extraLogin .footer .button {width: 100%;}
</style>
