<template>
  <div v-show="visible">
    <fixed-top :resized="render">
      <list-form :params="form" @changed="changed"></list-form>
    </fixed-top>

    <f7-list v-if="products.length > 0" inset>
      <list-item v-for="(product, key) in products" type="accordion"
        :key="key"
        :title="product.name"
        :footer="product.created_on"
        >

        <f7-accordion-content>
					<f7-block class="marginBottom10">
            <div class="item">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}: {{product.number_items ? product.number_items : 1}}</div>
            <div class="item">{{$LanguageHelper.translate('_AMOUNT_')}}: € {{product.amount_in_vat}}</div>

            <template v-if="product.credits == 1">
							<div class="item">{{$LanguageHelper.translate('_VALID_UNTIL_')}}: {{product.expires_on_date == 0 ? $LanguageHelper.translate('_VALID_UNLIMITED_') : product.expires_on_date}}</div>
              <div class="item">{{$LanguageHelper.translate('_NUMBER_CREDITS_')}}: {{product.number_credits_org}}</div>
              <div class="item">{{$LanguageHelper.translate('_CREDITS_LEFT_')}}: {{product.number_credits}}</div>
            </template>

            <template v-if="product.installments == 1">
							<div v-if="product.number_installments > 0" class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{product.number_installments}}</div>
              <div v-else class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{$LanguageHelper.translate('_CONTINUOUSLY_')}}</div>
              <div class="item">{{$LanguageHelper.translate('_ATHORIZATION_SEPA_')}}: {{$LanguageHelper.translate('_EVERY_')}} {{product.length > 1 ? product.length + ' ' : ''}} {{product.period}}</div>
            </template>

            <div class="item" v-if="product.active != 1">{{$LanguageHelper.translate('_STATUS_')}}: {{$LanguageHelper.translate('_INACTIVE_')}}</div>
            <div v-if="product.description" class="item" v-html="`${$LanguageHelper.translate('_DESCRIPTION_')} ${product.description}`"></div>
            <div v-if="!product.sale_id && !product.paypro_id_product_sale && product.free_trial != 1" class="item"><i>{{$LanguageHelper.translate('_PRODUCT_CONNECTED_BY_COMPANY_')}}</i></div>
          </f7-block>
        </f7-accordion-content>
      </list-item>
    </f7-list>

    <f7-card v-else class="pageContent">
			<f7-card-content>
				<div>{{$LanguageHelper.translate('_NO_PRODUCTS_MESSAGE_')}}</div>
			</f7-card-content>
		</f7-card>
  </div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';
import ListItem from '../../components/smart/list-item.vue';

export default {
	components: {
		'list-form': ListForm,
		'fixed-top': FixedTop,
    'list-item': ListItem,
	},

  props: {
    idc: {required: true},
  },

  data() {
    return {
      render: false,
      visible: false,
			products: {},

			form: {
				fields: {
					type: {type: 'select', name: 'type', title: '_TYPE_', render: true, options: []},
				}
			}
    };
  },

	computed: {
		productType() {
			return (Object.values(this.form.fields.type.options).find(option => option.selected)).value;
		}
	},

  methods: {
		changed(field, val) {
			// Reload the page, with selected type!
			if(field.name == 'type') this.load();
		},

    _myProducts(data) {
      if(data.resultset) {
        this.products = data.resultset;
        this.render = true;
        this.visible = true;
      }
    },

		load() {
			$AjaxHelper.doRequest('invoiceProduct', 'userProducts', {idc: this.idc, type: this.productType}, {callback: this._myProducts});
		},
  },

  created() {
		this.form.fields.type.options.push({
			label: '_ONGOING_PRODUCTS_',
			value: 'ongoing',
			selected: true
		});

		this.form.fields.type.options.push({
			label: '_OTHER_PRODUCTS_',
			value: 'other',
			selected: false
		});

    this.load();
	},
}
</script>
