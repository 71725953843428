<!-- The default F7 VUE searchbar component doesn't work that well, so we had to make our own -->
<template>
	<form v-show="enabled" class="searchbar searchbar-expandable" :class="enabled ? 'searchbar-enabled' : 'searchbar-disabled'" @submit.prevent="() => {return false;}">
		<div class="searchbar-inner">
			<div class="searchbar-input-wrap">
				<input type="search" ref="search" :placeholder="$LanguageHelper.translate('_SEARCH_')" @input="value=$event.target.value" :value="value"  />
				<i class="searchbar-icon"></i>
				<span class="input-clear-button"></span>
			</div>
			<span class="searchbar-disable-button" @click="disable">{{$LanguageHelper.translate('_CANCEL_')}}</span>
		</div>
	</form>
</template>

<script>
import search from '../../js/mixins/search';

export default {
	mixins: [search],

	methods: {
		disable() {
			this.value = '';
			this.$emit('disable-searchbar');
		}
	}
};
</script>
