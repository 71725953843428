<template>
	<popup
		v-if="visible"
		:title="title"
		:toolbar="toolbar"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>

		<template #manage v-if="toolbar.tabs.manage.active">
			<tab-manage :idc="idc" :id_customer="id_customer" :fab="fab.new" />
		</template>

		<template #measurements v-if="toolbar.tabs.measurements.active">
			<tab-measurements :idc="idc" :id_customer="id_customer" />
		</template>

		<template #skinfold v-if="toolbar.tabs.skinfold.active">
			<tab-skinfold :idc="idc" :id_customer="id_customer" />
		</template>
	</popup>
</template>

<script>
import Popup from '../../components/smart/popup.vue';
import TabManage from './tab-manage.vue';
import TabMeasurements from './tab-measurements.vue';
import TabSkinfold from './tab-skinfold.vue';

export default {
	components: {
    'popup': Popup,
    'tab-manage': TabManage,
    'tab-measurements': TabMeasurements,
    'tab-skinfold': TabSkinfold,
	},

	props: {
		index: {required: false},
		title: {type: String, required: true},
		idc: {required: true},
		id_customer: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,

			// Floating action buttons
			fab: {
				// Name of tab this FAB object belongs to!
				new: {
					position: 'right-bottom',
					buttons: {
						new: {name:'add', label: $LanguageHelper.translate('_CREATE_NEW_'), icon: 'icon ionAdd', render: true, clicked: false},
					}
				}
			},

			// Tabs
			toolbar: {
				tabs: {
					manage: {text: $LanguageHelper.translate('_MANAGEMENT_'), icon: 'icon ionSettings', render: true, active: true},
					measurements: {text: $LanguageHelper.translate('_VIEW_MEASUREMENT_CARD_'), icon: 'icon ionList', render: true, active: false},
					skinfold: {text: $LanguageHelper.translate('_VIEW_SKINFOLD_MEASUREMENT_'), icon: 'icon ionList', render: true, active: false},
				}
			}
    };
  },

  created() {
		//set fab for toolbar
    this.toolbar.tabs.manage.fab = this.fab.new;

		this.visible = true;
	}
};
</script>
