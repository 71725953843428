import { EventBus } from '../eventBus.js';
import FAB from '../../components/smart/fab.vue';

export default {
	components: {
    'fab': FAB,
  },

	props: {
		visible: {type: Boolean, required: false, default: true},
		infinite: {type: Boolean, required: false, default: false},
		title: {type: String, required: false},
		search: {type: Boolean, required: false, default: false},
		filter: {type: Boolean, required: false, default: false},
		component: {type: Object, required: false},
		back: {type: Boolean, required: false, default: true},
		language: {type: Boolean, required: false, default: false},
    toolbar: {type: Object, required: false},
    fab: {type: Object, required: false},
		sortOptions: {type: Object, required: false},
		dynamicNavbarComponent: {required: false},
	},

	data() {
		return {
			_uid: Date.now(), // Give the component a unique id
			searchEnabled: this.search,
			filterEnabled: this.filter,
			sortOptionsData: this.sortOptions,
			scrolling: false,
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href),
			pageInits: [] // List of pages initiated
		}
	},

  methods: {
		dynamicNavbarComponentChanged(val) {
			this.$emit('dynamic-navbar-component-changed', val);
		},

		tabClicked(tab) {
			// Do nothing if this tab is active already!
			if(tab.active) return;

			Object.values(this.toolbar.tabs).forEach(obj => obj.active = false);
			tab.active = true;

			// If a tab is clicked reset the scolling so the FAB will be visible!
			this.scrolling = false;

			this.$emit('tab-clicked', tab);
		},

		fabClicked(fab) {
			this.$emit('fab-clicked', fab);
		},

		searched(val) {
			this.$emit('searched', val);
		},

		sortSelected(val) {
			this.$emit('sort-selected', val);
		},

		showFiltersClicked() {
			this.$emit('show-filters-clicked');
		},

		infiniteScrollPageEnd() {
			// Only emit event if preloader is not visible (in other words a server request is still running)
			if(!$ViewHelper.preloader.isVisible()) this.$emit('infinite-scroll-page-end');
		},

		// Detect when user scrolls end stops scrolling!
		watchScroll() {
			let el = $('.page-content', $(`#ref_${this._uid}`));
			let lastScrollTop = 0;

			el.off('scroll');
			el.on('scroll', (el) => {
				let st = $(el.target).scrollTop();

				// Prevent FAB from flickering on iOS when it reaches the bottom
				let bottomNotReachedYet = (Math.ceil(el.target.scrollHeight - st) > el.target.clientHeight);

				// Scroll height has to be greater then zero, could become lower in safari
				if (st > 0 && st > lastScrollTop){
			  	// Down scroll
					this.scrolling = true;
			   } else {
			   		// Up scroll
						this.scrolling = !bottomNotReachedYet;
			   }
			   lastScrollTop = st;
	    });
		},
	},

	watch: {
		// Hide elements with the hideOnScroll class name when user is scrolling!
		scrolling(val) {
			let el = $('.hideOnScroll', $(`#ref_${this._uid}`));
			if(!el[0]) return;
			val ? el.hide() : el.show();
		},

		// Set search, filter and sort option settings per tab!
		toolbar: {
	    immediate: true,
			deep: true,
	    handler(toolbar) {
				if(!toolbar) return;
				this.searchEnabled = false;
				this.filterEnabled = false;
				this.sortOptionsData = null;
				for(var prop in toolbar.tabs) {
					if(!toolbar.tabs[prop].active) continue;

					// Enable or disable search
					this.searchEnabled = toolbar.tabs[prop].search ? true : false;

					// Enable or disable filter
					this.filterEnabled = toolbar.tabs[prop].filter ? true : false;

					// Set or unset sort options
					this.sortOptionsData = toolbar.tabs[prop].sortOptions ? toolbar.tabs[prop].sortOptions : null;

					// Activate the scroll watcher on the tab page when tab page is rendered
					setTimeout(() => this.watchScroll(), 1);
				}
	    }
	  }
	},

	mounted() {
		// Watch scoll event for this component!
		this.watchScroll();

		// Force scrolling to be set to false, this is used on autoscroll so hideOnScroll elements will show again
		EventBus.$off('auto-scroll-done');
		EventBus.$on('auto-scroll-done', () => this.scrolling = false);

		// Emit event when initiation (page animation) is done!
		EventBus.$off('initiated');
		EventBus.$on('initiated', (e) => {
			// Emit initiated event only if page has not been initiated before
			if(!this.pageInits.includes(`ref_${this._uid}`)) {
				this.pageInits.push(e.target.id);
				this.$emit('initiated');
			}
		});

		// Emit mounted event so popup knows when to open, otherwise there will be no slide effect!
		this.$emit('mounted');
	}
};
