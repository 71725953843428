<template>
	<popup
		:title="$LanguageHelper.translate('_EDIT_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
		v-if="visible"
		>
		<list-form :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		publicationItem: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,

			form: {
				fields: {
					youtube: {type: 'input', label: '_YOUTUBE_URL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			},
		};
  },

	methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			data['id_wod'] = this.publicationItem.id_wod;
			data['id_wod_whiteboard'] = this.publicationItem.id_wod_whiteboard;
			$AjaxHelper.doRequest('WOD', 'saveWhiteboardPublication', Object.assign({idc: this.idc}, data), {callback: this._saved});
		},

		_saved(data) {
			if(data.status == 'OK') this.opened = false;
		},

		_publication(data) {
			if(data.status == 'OK') {
				if(data.resultset && data.resultset.youtube) this.form.fields.youtube.value = data.resultset.youtube;
				this.visible = true;
			}
		}
	},

	created() {
		if(this.publicationItem) $AjaxHelper.doRequest('WOD', 'getWhiteboardPublication', Object.assign({idc: this.idc}, this.publicationItem), {callback: this._publication});
	}
};
</script>
