<template>
	<div class="item-inner">
		<div class="item-title item-label">{{label}}</div>
    <div class="item-input-wrap">
      <input type="text"
				:placeholder="placeholder"
				:value="value"
				@input="input"
				@searched="searched"
			/>
    </div>
  </div>
</template>

<script>
import search from '../../js/mixins/search';

export default {
	mixins: [search],

	props: {
    label: {label: true},
    placeholder: {required: true},
    val: {required: true},
    className: {required: false},
  },

	methods: {
		input(event) {
			this.value = event.target.value;
			this.$emit('changed', this.value);
		},
	},

	watch: {
		val(val) {
			this.value = val;
		}
	},

	created() {
		this.value = this.val;
	}
};
</script>
