<template>
	<popup :title="$LanguageHelper.translate('_QR_ACCESS_EVENT_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import FormCheckboxGroupAction from '../js/mixins/formCheckboxGroupAction';
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	mixins: [FormCheckboxGroupAction],

	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id: {required: false},
		index: {required: false},
  },

	data() {
    return {
			opened: false,

			form: {
				fields: [],
				submit: {text: '_SAVE_', render: false},
				values: {},
			}
		};
  },

	methods: {
		changed(el, action) {
			// We need to loop this because our fields are in an array and we don't know the index number!
			this.form.fields.forEach((field) => {
				if(field.name == 'id_invoice_product') this.handleCheckboxGroupAction(el, field.items, action);

				if(el.name == 'has_start_end_date') {
					this.form.fields.forEach((field2) => {
						if(field2.name == 'date_start') {
							field2.render = el.options.yes.selected;
						}

						if(field2.name == 'date_end') {
							field2.render = el.options.yes.selected;
						}
					});
				}
			});
		},

		submitted() {
			const data = JSON.parse(JSON.stringify(this.form.values));
			
			if(this.id) data.id = this.id;
				$AjaxHelper.doRequest('eventWithScanAccess', 'saveEvent', Object.assign({idc: this.idc}, data), {callback: this._saved});
			},

		renderForm(productOptions, playSoundOnChecking = false) {
			if(!productOptions.invoice_products || productOptions.invoice_products.length == 0) return;

			const showDates = this.form.values.date_start && this.form.values.date_start.length > 0;
			const startEndDateOptions = { no: { name: 'no', label: '_NO_', value: 0, selected: !showDates }, yes: { name: 'yes', label: '_YES_', value: 1, selected: showDates } };

			this.form.fields.push({name:'name', type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''});
			this.form.fields.push({name: 'has_start_end_date', type: 'select', title: '_EVENT_HAS_START_END_DATE_', render: true, options: startEndDateOptions});
			this.form.fields.push({name: 'play_sound_on_checkin', type: 'checkbox', title: '_QR_CHECKIN_PLAY_SOUND_', checked: playSoundOnChecking, value: 1, render: true});
			this.form.fields.push({name: 'date_start', type: 'datetime', label: '_DATE_FROM_', placeholder: '_SELECT_DATE_', render: showDates, value: ''});
			this.form.fields.push({name: 'date_end', type: 'datetime', label: `${$LanguageHelper.translate('_DATE_UNTIL_')}`, placeholder: '_SELECT_DATE_', render: showDates, value: ''});
			this.form.fields.push({name: 'available_for_products', type: 'divider', title: '_CHECKIN_ALLOWED_WITH_FOLLOWING_PRODUCTS_', render: true});
			
			// Create the product type with product checkbox fields
			let items = [];

			productOptions.invoice_products.forEach((item, index) => {
				// Product types
				var productType = {
					title: item.label,
					ref: index, // Mandatory field for handleCheckboxGroupAction to work
					level: 1, // Mandatory field for handleCheckboxGroupAction to work
					checked: false, // Mandatory field for handleCheckboxGroupAction to work
					toggled: item.open && item.open == 1, // Mandatory field for handleCheckboxGroupAction to work
					class: 'themeLink',
					render: true
				};

				items.push(productType);

				// Products
				item.products.forEach((product) => {
					// If product is selected, make sure toggled and checked are set to true on level 1 item!
					if(product.selected == 1) {
						productType.checked = true;
						productType.toggled = true;
					}

					items.push({
						ref: index, // Mandatory field for handleCheckboxGroupAction to work
						level: 2, // Mandatory field for handleCheckboxGroupAction to work
						checked: product.selected == 1, // Mandatory field for handleCheckboxGroupAction to work
						title: product.name,
						value: product.id_invoice_product,
						render: item.open && item.open == 1,
					});
				});
			});

			// Render the grouped checkboxes here
			this.form.fields.push({name: 'id_invoice_product', type: 'checkbox_group', items: items, render: true});

			this.form.submit.render = true;
		},

		_new(data) {
			// Render the form
			this.renderForm(data.options);
		},

		_event(data) {
			// Set values
			this.form.values = data.resultset;

			// Render the form
			this.renderForm(data.options, Boolean(data.resultset.play_sound_on_checkin));
		},

		_saved(data) {
			if(data.status == 'OK') {
				if(this.id) {
					$AjaxHelper.doRequest('eventWithScanAccess', 'getEvent', {idc: this.idc, id: this.id}, {callback: this._eventEdited});
				} else {
					$AjaxHelper.doRequest('eventWithScanAccess', 'getEvent', {idc: this.idc, id: data.id}, {callback: this._eventAdded});
				}
			}
		},

		_eventEdited(data) {
			this.$emit('edited', Object.assign({index: this.index}, data.resultset));

			// Close popup here, were done!
			this.opened = false;
		},

		_eventAdded(data) {
			this.$emit('added', data.resultset);

			// Close popup here, were done!
			this.opened = false;
		}
	},

	created() {
		if(this.id)  {
			$AjaxHelper.doRequest('eventWithScanAccess', 'getEvent', {idc: this.idc, id: this.id}, {callback: this._event});
		} else {
			$AjaxHelper.doRequest('eventWithScanAccess', 'productOptions', {idc: this.idc}, {callback: this._new});
		}
	}
};
</script>
