// Just quit if we cannot support!
exitIfBrowserNotSupported();

// Global imports
import './imports';

// Flatpickr CSS
import 'flatpickr/dist/flatpickr.css';

// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Ionic Icons CSS => https://unpkg.com/ionicons@4.5.5/dist/css/ionicons.css
import '../css/ionicons.css';

// Framework7 icons needed for text editor, we use ionicons for the rest
import '../css/icons.css';

// Custom App CSS
import '../css/app.css';

// Theme based CSS, either auto detection or the theme set in config! Has to require otherwise webpack will compile both
if($Config.app.theme == 'ios') {
	import('../css/theme/app.ios.css');
} else {
	import('../css/theme/app.md.css');
}

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
const app = createApp(App);

//Add needed helpers to the global Vue scope so we can access them from every component!
app.config.globalProperties.$Config = $Config;
app.config.globalProperties.$LanguageHelper = $LanguageHelper;
app.config.globalProperties.$ViewHelper = $ViewHelper;
app.config.globalProperties.$FunctionsHelper = $FunctionsHelper;
app.config.globalProperties.$UserHelper = $UserHelper;
app.config.globalProperties.moment = moment;

// Register Framework7 Vue components
registerComponents(app);

// Mount the app
app.mount('#appRoot');

// function isChromeOnAndroid() {
// 	const userAgent = navigator.userAgent;
// 	const chromeRegex = /Chrome\/[.0-9]*/i;
// 	const phoneBrands = /SamsungBrowser|EdgA|CriOS|FxiOS|OPR|UCBrowser|QQBrowser|VivoBrowser|HuaweiBrowser|OppoBrowser|MiuiBrowser|Brave|DuckDuckGo|TorBrowser/i;
// 	const otherChromeLikeBrowsers = /EdgA|CriOS|FxiOS|OPR/i;
// 	return chromeRegex.test(userAgent) && !phoneBrands.test(userAgent) && !otherChromeLikeBrowsers.test(userAgent) && /Mobile/i.test(userAgent) && typeof window.chrome !== "undefined";
// }

function isChromeOnAndroid() {
	const userAgent = navigator.userAgent;
	const chromeRegex = /Chrome\/[.0-9]*/i;
	const phoneBrands = /SamsungBrowser|EdgA|CriOS|FxiOS|OPR|UCBrowser|QQBrowser|VivoBrowser|HuaweiBrowser|OppoBrowser|MiuiBrowser|Brave|DuckDuckGo|TorBrowser/i;
	const otherChromeLikeBrowsers = /EdgA|CriOS|FxiOS|OPR/i;
	const isAndroid = /Android/i.test(userAgent);
	return chromeRegex.test(userAgent) && isAndroid && !phoneBrands.test(userAgent) && !otherChromeLikeBrowsers.test(userAgent) && typeof window.chrome !== "undefined";
}

// This method will check the browser support end exits if not supported
function exitIfBrowserNotSupported() {
	let browser = {
		supported() {
			// Check if CSS variables feature is available, if not then we can't support this non modern browser!
			return window.CSS && CSS.supports('color', 'var(--fake-var)');
		}
	};

	// We don't want people to use other browsers than Chrome on Android
	if(/Android/i.test(navigator.userAgent) && !isChromeOnAndroid()) {
		var output = '';

		output += '<div style="font-family:arial; padding: 15px;">';
		output += '<h1>Your browser does not support this application!</h1>';
		output += '<h3>Use Google Chrome browser</h3>';
		output += '<h3><a href="https://play.google.com/store/apps/details?id=com.android.chrome&pcampaignid=web_share" target="_blank">Click here to download Google Chrome Browser...</a></h3>';
		output += '<div>';

		$('body').html(output);

		throw ('Browser not supported, use Google Chrome!');
	}

	if(!browser.supported()) {
		var output = '';

		output += '<div style="font-family:arial; padding: 15px;">';
		output += '<h1>Your browser does not support this application!</h1>';
		output += '<h3>We recommend to use Google Chrome</h3>';
		output += '<h3><a href="https://www.google.com/chrome/browser/desktop" target="_blank">Click here to download Google Chrome Browser...</a></h3>';
		output += '<div>';

		$('body').html(output);

		throw ('Browser not supported, we recommend to use Google Chrome!');
	}
}
