<template>
  <popup :title="$LanguageHelper.translate('_SETTINGS_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
    <list-form v-if="render" :params="form" @submitted="submitted" @changed="changed" />

		<call-to-action v-if="callToAction.opened" :module="callToAction.module" :idc="idc" @close="callToAction.opened=false" />
  </popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import CallToAction from '../popups/call-to-action.vue';

export default {
  components: {
    'popup': Popup,
    'list-form': ListForm,
    'call-to-action': CallToAction,
  },

  props: {
		idc: {required: true},
    id_agenda: {required: true},
	},

  data() {
    return {
      opened: false,
      render: false,
			callToAction: {
				opened: false,
				module: {}
			},
      formData: {},
			selectedAgendaID: null,

      form: {
        fields: {
          email_appointment_subscriptions: {name: 'email_appointment_subscriptions', type: 'input', label: '_EMAIL_AGENDA_EVENTS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          divider: {name: 'divider', type: 'divider', title: '_SETTINGS_PER_AGENDA_', info: $LanguageHelper.translate('_CUSTOMER_CAN_OVERWRITE_SETTING_'), render: false},
          agenda: {name: 'agenda', type: 'select', title: '_PICK_AGENDA_', render: false, options: []},
          init_view_mode: {name: 'init_view_mode', type: 'select', title: '_AGENDA_VIEW_', render: true,
            options: {
              week: {name: 'week', value: 1, label: '_WEEK_', selected: true},
              day: {name: 'day', value: 2, label: '_DAY_', selected: false},
              list: {name: 'list', value: 3, label: '_LIST_', selected: false},
            }
          },

          color_background: {name: 'color_background', type: 'color', label: '_COLOR_BACKGROUND_AGENDA_', placeholder: '_ENTER_HERE_', render: true, value: 'rgba(247,247,247,0.4)'},
          private: {name: 'private', type: 'checkbox', title: '_AGENDA_NOT_VISIBLE_FOR_CUSTOMER_', checked: false, value: 1, render: true},

					employees_divider: {type: 'divider', title: '_EMPLOYEES_INSTRUCTORS_', render: false},
					employees_module_cta: {name: 'cta', type: 'cta', text: '', action: {module: '', name: 'cta'}, render: false},
					employees: {type: 'checkbox_group', items: [], render: false},
        },

        submit: {text: '_SAVE_', render: true},
				values: {},
      }
    };
  },

  methods: {
    changed(field, option) {
      if(field.name == 'agenda') {
				this.selectedAgendaID = option.value;
        this.storeSettings();
      }

			if(field.name == 'cta') {
				this.callToAction.opened = true;
			}
    },

    submitted() {
      let data = JSON.parse(JSON.stringify(this.form.values));
			data.private = data.private ? 1 : 0;
			data.agenda = this.selectedAgendaID;
			data.employees = data.employees && data.employees.length > 0 ? data.employees : '';

      $AjaxHelper.doRequest('agenda', 'saveAgendaSettings', Object.assign({idc: this.idc}, data), {callback: this._agendaSettingsSaved});
    },

		// Store settings for selected agenda
    storeSettings() {
			if(this.selectedAgendaID > 0) {
				Object.values(this.formData.resultset).forEach((agenda, key) => {
	        if(this.selectedAgendaID == agenda.id_agenda) {

						// Show users with levels (employees) so they can be connected to the appointment
						this.form.fields.employees.items = [];
						if(agenda.employees && agenda.employees.length > 0) {
							agenda.employees.forEach((item) => {
								this.form.fields.employees.items.push({
									title: item.name,
									value: item.id_appuser,
									checked: item.selected == 1,
									render: true
								});
							});

							this.form.fields.employees_divider.render = true;
							this.form.fields.employees.render = true;
						}

						// Other settings
	          this.formData.resultset.selected_view = agenda.init_view_mode;
	          this.form.fields.private.checked = agenda.private == 1 ? true : false;
	          this.form.fields.color_background.value = agenda.color_background != '' ? agenda.color_background : 'rgba(255,255,255,1)';
					}
        });
      }

			Object.values(this.form.fields.init_view_mode.options).forEach((mode, key) => {
        mode.selected = (this.formData.resultset.selected_view == mode.value ? true : false);
      });
    },

    storeAgendas() {
			this.form.fields.agenda.options = []
      this.formData.agendas.forEach((agenda, key) => {
        this.form.fields.agenda.options.push({
          value: agenda.id_agenda,
          label: agenda.name,
          selected: agenda.id_agenda == this.selectedAgendaID,
        });
      });

      this.storeSettings();

      if(this.formData.agendas && this.formData.agendas.length > 1) {
        this.form.fields.agenda.render = true;
        this.form.fields.divider.render = true;
      }
    },

    _agendaSettingsSaved(data) {
      if(data.status == 'OK') {
				this.fetchAgendaSetting();
				this.$emit('changed');
			}
    },

    _agendaSettings(data) {
			// Show to action to do a module upgrade?
			if($UserHelper.isAdmin(this.idc) && data.module && data.module.trigger) {
				this.callToAction.module = data.module;
				this.form.fields.employees_module_cta.text = data.module.trigger;
				this.form.fields.employees_module_cta.action.module = data.module.name_system;
				this.form.fields.employees_module_cta.render = true;
			}

			if(data.email_appointment_subscriptions) this.form.fields.email_appointment_subscriptions.value = data.email_appointment_subscriptions;

			if(data.resultset) {
        this.formData = data;
        this.form.fields.private.checked = data.resultset.selected_private == 1 ? true : false;
        this.form.fields.color_background.value = data.resultset.selected_background ? data.resultset.selected_background : 'rgba(247,247,247,0.4)';

				this.storeAgendas();
      }

      this.render = true;
    },

		fetchAgendaSetting() {
			$AjaxHelper.doRequest('agenda', 'getManageAgendaSettings', {idc: this.idc, id_agenda: this.selectedAgendaID}, {callback: this._agendaSettings});
		}
  },

  created() {
		this.selectedAgendaID = this.id_agenda;
    this.fetchAgendaSetting();
  }
};
</script>
