<template>
	<popup v-if="render" :title="$LanguageHelper.translate('_MANAGE_REFUNDS_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<f7-list list inset v-if="renderContents">
			<f7-list-item class="info displayBlockItemInner">
				<div class="item"><b>{{$LanguageHelper.translate('_ORIGINAL_SALE_')}}</b></div>
				<div v-if="sale.info.username" class="item">{{sale.info.username}}</div>
				<div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: {{sale.info.invoice_number}}</div>
				<div class="item">{{$LanguageHelper.translate('_AMOUNT_')}} &euro; {{sale.info.amount}}</div>
				<div class="item"><a class="link theme" @click="downloadInvoice(sale.info.id_invoice)">{{$LanguageHelper.translate('_DOWNLOAD_INVOICE_')}}...</a></div>
			</f7-list-item>

			<!-- Refund form with dropdown number items allowed to refund -->
			<f7-list-item class="displayBlockItemInner">
				<div class="item info"><b>{{$LanguageHelper.translate('_REFUND_SALES_')}}</b></div>
				<table class="products">
					<tr>
						<td>{{$LanguageHelper.translate('_NAME_')}}</td>
						<td width="110">{{$LanguageHelper.translate('_AMOUNT_')}}</td>
						<td width="70">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}</td>
					</tr>

					<tr v-for="(product, key) in sale.products" :key="key">
						<td>{{$FunctionsHelper.specialChars(product.name)}}</td>
						<td>&euro; {{product.amount}}</td>
						<td>
							<select @change="calculateRefund(product)" class="regular numberItems">
								<option value="0">0</option>
								<option v-for="(number, index) in parseInt(product.number_refunds_allowed)" :value="number" :key="index">{{number}}</option>
							</select>
						</td>
					</tr>
				</table>
			</f7-list-item>

			<!-- Refund example with refund button -->
			<f7-list-item v-if="refund.products.length > 0">
				<table class="products refundExample">
					<tr>
						<td colspan="3"><b>{{$LanguageHelper.translate('_PRODUCTS_TO_REFUND_')}}</b></td>
					</tr>

					<tr>
						<td>{{$LanguageHelper.translate('_NAME_')}}</td>
						<td width="110">{{$LanguageHelper.translate('_AMOUNT_')}}</td>
						<td width="40">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}</td>
					</tr>

					<tr v-for="(product, key) in refund.products" :key="key">
						<td>{{$FunctionsHelper.specialChars(product.name)}}</td>
						<td>&euro; - {{product.amount}}</td>
						<td>- {{product.numberItems}}</td>
					</tr>

					<tr>
						<td>&nbsp;</td>
						<td colspan="2" align="right" class="totalAmount">{{$LanguageHelper.translate('_TOTAL_')}} &euro; - {{refund.totalAmount.toFixed(2)}}</td>
					</tr>

					<tr>
						<td colspan="3"><b>{{$LanguageHelper.translate('_PAYMENT_METHOD_')}}</b></td>
					</tr>

					<tr>
						<td colspan="3">
							<select class="regular paymentMethod" @change="paymentMethodSelected">
								<option v-for="(paymentMethod, key) in refund.paymentMethods" :value="paymentMethod.value" :key="key">{{paymentMethod.name}}</option>
							</select>
						</td>
					</tr>

					<tr v-if="sale.info.addIBAN && sale.info.addIBAN == 1 && refund.paymentMethodSelected == 'refund'">
						<td colspan="3" class="ibanLabel"><b>{{$LanguageHelper.translate('_IBAN_')}}</b></td>
					</tr>

					<tr v-if="sale.info.addIBAN && sale.info.addIBAN == 1 && refund.paymentMethodSelected == 'refund'">
						<td colspan="3" class="iban">
							<input type="text" :placeholder="$LanguageHelper.translate('_ENTER_HERE_')" @input="refund.iban=$event.target.value" />
						</td>
					</tr>

					<tr>
						<td colspan="3" class="buttonWrapper">
							<f7-button fill raise @click="submitted">{{$LanguageHelper.translate('_REFUND_SALES_')}}</f7-button>
						</td>
					</tr>
				</table>
			</f7-list-item>

			<!-- Refund history -->
			<f7-list-item class="displayBlockItemInner">
				<div class="item info"><a class="link theme refundHistory" @click="refundHistoryOpened = !refundHistoryOpened">{{$LanguageHelper.translate('_REFUNDS_HISTORY_')}}...</a></div>
				<table class="products" v-if="refundHistoryOpened">
					<template v-for="(refund, key) in credits.refunds" :key="key">
						<tr>
							<td colspan="3">
								{{$LanguageHelper.translate('_DATE_')}}: {{refund.created_on}}
							</td>
						</tr>

						<tr>
							<td colspan="3">
								{{$LanguageHelper.translate('_IBAN_')}}: {{refund.iban}}
							</td>
						</tr>

						<tr>
							<td colspan="3">
								{{$LanguageHelper.translate('_INVOICE_NUMBER_')}} <a class="link theme" @click="downloadInvoice(refund.id_invoice)">{{refund.invoice_number}}</a>
							</td>
						</tr>

						<!-- Products here -->
						<tr>
							<td colspan="3">{{$LanguageHelper.translate('_PRODUCTS_')}}</td>
						</tr>

						<tr>
							<td>{{$LanguageHelper.translate('_NAME_')}}</td>
							<td width="70">{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}</td>
							<td width="110">{{$LanguageHelper.translate('_AMOUNT_')}}</td>
						</tr>

						<tr v-for="(product, key) in refund.products" :key="key">
							<td>{{$FunctionsHelper.specialChars(product.name)}}</td>
							<td>{{product.number_items}}</td>
							<td>&euro; {{product.amount}}</td>
						</tr>

						<tr>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<td class="total">&euro; {{refund.amount}}</td>
						</tr>

						<tr>
							<td colspan="3"></td>
						</tr>
					</template>

					<tr>
						<td class="totalAmount amount" colspan="3" align="right">{{$LanguageHelper.translate('_TOTAL_AMOUNT_REFUNDS_')}} &euro; {{credits.amount}}</td>
					</tr>
				</table>
			</f7-list-item>
		</f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id_invoice: {required: true}
  },

	data() {
		return {
			render: false,
			renderContents: false,
			opened: false,
			refundHistoryOpened: false,

			sale: {
				info: {},
				products: []
			},

			// Refund history
			credits: {
				amount: 0,
				refunds: [],
			},

			// To be refunded
			refund: {
				totalAmount: 0,
				products: [],
				paymentMethods: [
					{name: $LanguageHelper.translate('_BANK_'), value: 'refund'},
					{name: $LanguageHelper.translate('_CASH_'), value: 'cash'}
				],

				paymentMethodSelected: 'refund',
				iban: ''
			}
		}
	},

	methods: {
		downloadInvoice(id) {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoice&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&id=${id}&language=${$LanguageHelper.current}`);
		},

		paymentMethodSelected() {
			this.refund.paymentMethodSelected = $(event.target).find('option:selected').attr('value');
		},

		calculateRefund(product) {
			let numberItems = $(event.target).find('option:selected').attr('value');
			let item = {id: product.id, name: product.name, amount: product.amount, numberItems: numberItems};
			this.refund.products.forEach((product, key) => {
				if(product.id == item.id) this.refund.products.splice(key, 1);
			});

			// Push the item into the products array!
			if(numberItems > 0) this.refund.products.push(item);

			// Calculate total here!
			this.refund.totalAmount = 0;
			this.refund.products.forEach((product, key) => {
				this.refund.totalAmount += parseFloat(product.amount) * parseInt(product.numberItems);
			});
		},

		load() {
			$AjaxHelper.doRequestExtraLogin('payment', 'getSale', {idc: this.idc, id: this.id_invoice}, {callback: this._sale}, this._extraLoginClosedByUser);
		},

		submitted() {
			//Format the data here for the back-end!
			let data = {
				'refund': 1,
				'payment_method': this.refund.paymentMethodSelected,
				idc: this.idc
			};

			this.refund.products.forEach((product, key) => {
				data[`refund_${product.id}`] = product.numberItems;
				if(this.refund.iban && this.refund.iban.length > 0) data['iban'] = this.refund.iban;
			});

			$AjaxHelper.doRequest('payment', 'refundSale', data, {callback: this._refundSaved});
		},

		_refundSaved(data) {
			// Close popup after were done...
			if(data.status == 'OK') {
				// Reload the screen again!
				this.load();
			}
		},

		_sale(data) {
			this.refund.products = [];
			this.render = false;
			this.renderContents = false;

			this.sale.info = data.resultset.saleInfo;
			this.sale.products = data.resultset.products;

			// Set the credit history if there is any
			if(data.resultset.credits && data.resultset.credits.refunds.length > 0) {
				this.credits.amount = data.resultset.credits.amount;
				this.credits.refunds = data.resultset.credits.refunds;
			}

			// Show popup with contents now!
			this.render = true;
			this.renderContents = true;
		},

		_extraLoginClosedByUser() {
			// If extra login was closed by user then fire close event so my parent can kill me and render me again later on!
			this.$emit('close');
		},
	},

	created() {
		this.load();
	}
};
</script>

<style scoped>
.info {font-size: 14px;}
.info .item {line-height: 25px;}
table {
	border-spacing: 0;
  border-collapse: separate;
}
table.products {width: 100%; font-size: 14px;}
table.products td {height: 25px;}
.totalAmount {padding-top: 15px;}
.refundHistoryItem {
	padding-top: 10px;
}

.link.refundHistory {padding-bottom: 10px;}

.total {
	border-top: 1px solid #E7E7E7;
}

select.numberItems {
	width: 70px;
	height: 30px;
	margin-bottom: 5px;
	margin-top: 7px;
	padding-left: 5px;
	padding-right: 15px;
	padding-bottom: 2px;
}

select.paymentMethod {
	width: 100%;
	margin-top: 5px;
}

.ibanLabel {
	padding-top: 15px;
	padding-bottom: 5px;
}

.iban input {
	padding: 0;
	padding-left: 5px;
	border: 0.5px solid #E7E7E7;
	height: 30px;
}

.buttonWrapper {
	padding-top: 20px;
}
</style>
