/*
import Vue from 'vue';
export const EventBus = new Vue();
*/

// EventBus was removed from VUE3 so we use this lib now.
import mitt from 'mitt';

// Make the methods backwards compatible with with VUE 2
const emitter = mitt();
emitter.$on = emitter.on;
emitter.$off = emitter.off;
emitter.$emit = emitter.emit;

export const EventBus = emitter;





