var AppointmentTableHelper = function (appointmentList, agendaWidth) {
  this.appointments = [];
  this.columns = [];
  this.index = {};

  agendaWidth = agendaWidth || 100;

  var i, appointment;
  for (i in appointmentList) {
    var appObj = appointmentList[i];
    appointment = new Appointment(appObj);
    this.appointments.push(appointment);
  }

  var appointmentSort = function(a, b) {
    if (a.startTime < b.startTime) return -1;
    if (a.startTime > b.startTime) return 1;
    if (a.endTime > b.endTime) return -1;
    if (a.endTime < b.endTime) return 1;
    if (a.id > b.id) return -1;
    if (a.id < b.id) return 1;

    return 0;
  };

  this.appointments.sort(appointmentSort);
  for (i in this.appointments) {
    appointment = this.appointments[i];

    this.calculatePosition(appointment);
    this.index[appointment.id] = i;
  }
  this.columns = [];

  var startTime, columnId, c;
  var columns = [];
  var endTimeConcurrent = [];
  var startTimes = [];

  for (i in this.appointments) {
    appointment = this.appointments[i];
    startTime = appointment.startTime;
    var clear = true;
    for (c in columns) {
      var active = columns[c];
      if (active && active.endTime <= startTime) {
        columns[c] = null;
        active = null;
      }

      if (active) {
        clear = false;
      }
    }

    if (clear) {
      endTimeConcurrent.push({
        'startTime': startTime,
        'columns': columns.length
      });
      columns = [];
    }

    columnId = appointment.position;
    columns[columnId] = appointment;
    if (!startTimes[columnId]) {
      startTimes[columnId] = [];
    }
    startTimes[columnId].push(startTime);
  }

  endTimeConcurrent.push({
    'startTime': 24 * 60 + 1,
    'columns': columns.length
  });

  columns = [];
  for (i in this.appointments) {
  	appointment = this.appointments[i];
    startTime = appointment.startTime;
    for (c in columns) {
      active = columns[c];
      if (active && active.endTime < startTime) {
        columns[c] = null;
      }
    }

    columnId = appointment.position;
    columns[columnId] = appointment;
    startTimes[columnId].shift();

    while (startTime >= endTimeConcurrent[0].startTime) {
      endTimeConcurrent.shift();
    }
    var concurrent = endTimeConcurrent[0].columns;

    var cellWidth = agendaWidth / concurrent;
    var left = columnId * cellWidth;
    for (var colspan = 1; colspan + columnId < concurrent; colspan++) {
      var pos = colspan + columnId;
      if (columns[pos]) {
        break;
      }

      if (!startTimes[pos] || !startTimes[pos][0]) {
        continue;
      }

      var st = startTimes[pos][0];
      if (st < appointment.endTime ) {
        break;
      }
    }

		appointment.width = colspan * cellWidth;
  	appointment.left = left;
  }
};

AppointmentTableHelper.prototype.calculatePosition = function (appointment) {
  var active, columnId;
  for (columnId in this.columns) {
    active = this.columns[columnId];
    if (!active) {
      continue;
    }

    if (active.endTime <= appointment.startTime) {
      this.columns[columnId] = null;
    }
  }

  while (this.columns.length > 0) {
    active = this.columns.pop();
    if (active) {
      this.columns.push(active);
      break;
    }
  }

  for (columnId in this.columns) {
    active = this.columns[columnId];
    if (!active) {
      appointment.concurrent = this.columns.length;
      appointment.position = columnId;
      this.columns[columnId] = appointment;

      return;
    }
  }

  columnId = this.columns.length;
  appointment.position = columnId;
  this.columns[columnId] = appointment;

  for (columnId in this.columns) {
    active = this.columns[columnId];

    active.updateConcurrent(this.columns.length);
  }
};

AppointmentTableHelper.prototype.getAlignment = function (id) {
  var idx = this.index[id];

  var appointment = this.appointments[idx];
  var frames = 24 * 60;

  return {
    'left': appointment.left,
    'top': (appointment.startTime/frames) * 100,
    'width': appointment.width,
    'height': ((appointment.duration/frames) * 100).toFixed(2) -0.2
  };
};

var Appointment = function (data) {
  this.id = data.id_appointment;
  this.startTime = this.toMinutes(data.real_date_start_hour, data.real_date_start_minute);
  this.endTime = this.toMinutes(data.real_date_end_hour, data.real_date_end_minute);
  this.duration = this.endTime - this.startTime;

  this.position = 0;
  this.concurrent = 0;
  this.width = 0;
  this.left = 0;
};

Appointment.prototype.toMinutes = function (hours, minutes) {
	var h = parseInt(hours);
	var m = parseInt(minutes);

	return h * 60 + m;
};

Appointment.prototype.updateConcurrent = function (concurrent) {
  this.concurrent = Math.max(concurrent, this.concurrent);
};

export default AppointmentTableHelper;
