export default {
	methods: {
		handleCheckboxGroupAction(el /* Element clicked on */, items, action) {
			let checkTopLevel = false;
			let topLevelEl = null;

			items.forEach((item) => {
				// Uncheck if disabled is set to true!
				if(item.disabled) item.checked = false;

				// Check or uncheck all, mother of all has been clicked.
				if(el.level == 0 && item.parentRef == el.parentRef) {
					item.checked = el.checked;
				} else {
					// Top level item found in field items array, we need it later on!
					if(item.level == 1 && item.ref == el.ref) topLevelEl = item;

					// If top level element is clicked open/close or check/uncheck depended on the type (checkbox or title click)
					if(el.level == 1) {
						if(item.level == 2 && item.ref == el.ref) {
							if(action == 'toggled') item.render = el.toggled;
							if(action == 'checked') item.checked = el.checked;
						}
					}

					// If one checked sub level item is found then the top level checkbox needs to be checked
					if(item.level == 2 && item.ref == el.ref && item.checked) checkTopLevel = true;
				}
			});

			// Check or uncheck top level checkbox here!
			if(topLevelEl) topLevelEl.checked = checkTopLevel;
		}
	}
};
