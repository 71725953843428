<template>
	<page
		:title="title"
		:back="false"
		:panel="false"
		:language="true"
		:toolbar-branding="true"
		:backgroundImage="backgroundImage"
		:visible="visible"
		pageContentClass="companyBackground">

		<div v-if="visible" class="wrapperBookingForm marginTop35 marginBottom35">
			<template v-if="data.status == 'OK'">
				<div class="wrapperProductInfo">
					<div v-if="customTitle.length > 0" class="item"><b v-html="customTitle"></b></div>
					<!--<div v-if="customText.length > 0" class="item" v-html="customText"></div>-->
					<div v-if="customText.length > 0" class="item nl2br">{{$FunctionsHelper.specialChars(customText)}}</div>

					<div v-if="data.product" class="marginTop15">
						<template v-if="data.product.id_invoice_product_type != 3">
							<div v-if="data.product.installment == 1"><b>{{$FunctionsHelper.specialChars($LanguageHelper.translate('_SUBSCRIPTION_'))}}</b></div>
							<div v-else-if="data.product.number_credits > 1"><b>{{$LanguageHelper.translate('_CARD_WITH_CREDITS_')}}</b></div>
						</template>

						<div v-if="data.product.id_invoice_product_type != 3 && data.product.description.length > 0" class="item" v-html="data.product.description"></div>
						<div v-if="data.product.id_invoice_product_type == 1 && data.product.amount_in_vat_first_installment > 0" class="item">{{$LanguageHelper.translate('_AMOUNT_FIRST_')}}: &euro; {{data.product.amount_in_vat_first_installment}}</div>
						<div v-if="data.product.id_invoice_product_type != 3 && data.product.amount_in_vat > 0" class="item">{{$LanguageHelper.translate('_AMOUNT_')}}: &euro; {{data.product.amount_in_vat}}</div>
						<div v-if="(data.product.id_invoice_product_type == 2 || data.product.id_invoice_product_type == 3) && data.product.number_credits > 1" class="item">{{$LanguageHelper.translate('_NUMBER_CREDITS_')}}: {{data.product.number_credits}}</div>
						<div v-if="(data.product.id_invoice_product_type == 2 || data.product.id_invoice_product_type == 3) && data.product.number_credits > 1 && data.product.number_weeks_valid > 0" class="item">{{$LanguageHelper.translate('_NUMBER_WEEKS_VALID_')}}: {{data.product.number_weeks_valid}}</div>
						<div v-if="(data.product.id_invoice_product_type == 2 || data.product.id_invoice_product_type == 3) && data.product.number_credits > 1 && data.product.number_months_valid > 0" class="item">{{$LanguageHelper.translate('_NUMBER_MONTHS_VALID_')}}: {{data.product.number_months_valid}}</div>
						<div v-if="data.product.id_invoice_product_type == 1 && data.product.number_installments > 0" class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{data.product.number_installments}}</div>
						<div v-if="data.product.id_invoice_product_type == 1 && data.product.installments == 1" class="item">{{$LanguageHelper.translate('_ATHORIZATION_SEPA_')}}: {{$LanguageHelper.translate('_EVERY_')}} {{data.product.length > 1 ? data.product.length : ''}} {{data.product.period}}</div>
					</div>
				</div>

				<list-form :params="form" @changed="changed" @submitted="submitted" :class="form.className">
					<!-- Add special (non standard) form elements to the bottom of the form inside the extra slot -->
					<template v-if="form.showTerms" #extra-items>
						<li v-if="terms.userTerms" class="terms first">
							<checkbox-agree-to-terms
								:link="terms.userTerms.userTermsURL"
								:text-label="$LanguageHelper.translate('_AGREE_WITH_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_TERMS2_')"
								:text-link="$Config.apps[$Config.app.currentApp].displayName"
								:checked="form.userTermsAgreed"
								@changed="(val) => form.userTermsAgreed = val"
							/>
						</li>

						<li class="terms" v-if="terms.companyTerms && terms.companyTerms.url_terms_and_conditons">
							<checkbox-agree-to-terms v-if="terms.companyTerms"
								:link="terms.companyTerms.url_terms_and_conditons"
								:text-label="$LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS2_')"
								:text-link="terms.companyTerms.name"
								:checked="form.companyTerms"
								@changed="(val) => form.companyTermsAgreed = val"
							/>
						</li>
					</template>
				</list-form>

				<!-- Bottom left options -->
				<div class="bottomLeftOptions">
					<!-- User is logged in -->
					<div v-if="$UserHelper.isLoggedIn()"><a class="link theme" @click="logout">{{$LanguageHelper.translate('_BOOK_WITH_ANOTHER_ACCOUNT_THAN_')}} {{$FunctionsHelper.specialChars($UserHelper.getUser().firstname)}}</a></div>

					<!-- User is not logged in, show options -->
					<div v-if="form.fields.username.render"><a class="link theme" @click="renderRegisterAccount">{{$LanguageHelper.translate('_NOT_HAVE_ACCOUNT_')}}</a></div>
					<div v-if="form.fields.username.render"><a class="link theme" @click="forgotPassOpened=true">{{$LanguageHelper.translate('_FORGOT_PASS_')}}?</a></div>
					<div v-if="form.fields.firstname.render"><a class="link theme" @click="renderLogin">{{$LanguageHelper.translate('_HAVE_ACCOUNT_')}}</a></div>
				</div>

				<!-- Forgot password popup -->
				<forgot-password v-if="forgotPassOpened" :title="$LanguageHelper.translate('_FORGOT_PASSWORD_')" @close="forgotPassOpened=false" />
			</template>

			<div v-else class="notice">{{data.notice}}</div>
		</div>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';
import CheckboxAgreeToTerms from '../components/checkbox-agree-to-terms.vue';
import ForgotPassword from '../popups/forgot-password.vue';

export default {
	components: {
    'page': Page,
		'list-form': ListForm,
		'checkbox-agree-to-terms': CheckboxAgreeToTerms,
		'forgot-password': ForgotPassword,
  },

  data() {
    return {
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href), /* Query params from URL, there might be a company id in there */
			visible: false,
			forgotPassOpened: false,
			title: this.$Config.apps[this.$Config.app.currentApp].displayName,
			backgroundImage: '',
			terms: {},
			data: null,
			selectedAppointment: null,

			form: {
				showTerms: false,
				userTermsAgreed: false,
				companyTermsAgreed: false,

				fields: {
					// Appointment options
					id_appointment: {name: 'id_appointment', type: 'select-regular', title: '_CHOOSE_DATE_',  options: [], stayVisible: true, render: false},

					// Appointment info
					appointmentInfo: {type: 'description', class: 'appointmentInfo', text: '', stayVisible: true, render: false},

					// Login
					username: {type: 'input', label: '_YOUR_USERNAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					pass: {type: 'password', label: '_PASSWORD_', placeholder: '_ENTER_HERE_', render: false, value: ''},

					// Register account
					firstname: {type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					middlename: {type: 'input', label: '_MIDDLE_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					lastname: {type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					email: {type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					mobile: {type: 'input', label: '_PHONE_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					//gender: {type: 'select', title: '_GENDER_', render: false, options: {m: {name: 'm', value: 'm', label: '_MALE_', selected: true}, v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false}}},
					//birthdate: {type: 'date', label: '_BIRTH_DATE_', placeholder: '_SELECT_DATE_', render: false, value: ''},

					// Buttons
					hasNoAccount: {name: 'hasNoAccount', type: 'button', title: '_NOT_HAVE_ACCOUNT_', render: false},
					hasAccount: {name: 'hasAccount', type: 'button', title: '_HAVE_ACCOUNT_', render: false},

					// Partner users
					id_partner: {type: 'select-regular', name: 'id_partner', title: '_SUBSCRIBE_', render: false, options: []},
				},

				submit: {text: '_BOOK_', render: false},
				values: {},
			}
		};
  },

	computed: {
		customTitle() {
			let product = this.data.product ? this.data.product : null;
			if(product && product.custom_text && product.custom_text.booking_welcome && product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code] && product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code].title != '') {
				return product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code].title[0].toUpperCase() + product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code].title.substring(1);
			}

			return '';
		},

		customText() {
			let product = this.data.product ? this.data.product : null;
			if(product && product.custom_text && product.custom_text.booking_welcome && product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code] && product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code].text != '') {
				return product.custom_text.booking_welcome[$LanguageHelper.getCurrentLanguageObj().code].text;
			}

			return '';
		}
	},

  methods: {
		changed(field, item) {
			if(field.name == 'id_appointment') {
				this.selectedAppointment = null;
				this.form.fields.appointmentInfo.render = false;
				if(item && item.value != null) {
					this.selectedAppointment = item;
					this.renderAppointmentSelected();
				}
			}

			if(field.name == 'hasAccount') this.renderLogin();
			if(field.name == 'hasNoAccount') this.renderRegisterAccount();
		},

		submitted() {
			let formValues = Object.assign({}, {idc: this.queryParams.id, id_invoice_product: this.queryParams.pid}, JSON.parse(JSON.stringify(this.form.values)));

			// Login attempt, call the available appointments again, back-end wil recognise this as a login call!
			if(this.form.fields.username.render) {
				this.login(formValues);
			}
			// Book appointment call (either paid or non paid, as logged in user or with new account data)
			else {
				// Get id appointment and starting position from JSON string value and store them
				if(formValues.id_appointment) {
					let appointmentInfo = JSON.parse(formValues.id_appointment);
					if(appointmentInfo.id_appointment > 0) {
						formValues.id_appointment = appointmentInfo.id_appointment;
						formValues.position_start = appointmentInfo.position_start;
					}
				}

				let data = Object.assign(JSON.parse(JSON.stringify(formValues)), {
					type: 'productBooking',
					account: $UserHelper.isLoggedIn() ? 1 : 0,
					date_format_lang: 'en_GB'
				});

				// User agreed with the terms?
				if(this.terms.userTerms) data.userTerms = + this.form.userTermsAgreed;
				if(this.terms.companyTerms) data.companyTerms = + this.form.companyTermsAgreed;

				// Give no user selected error!
				if(data.hasOwnProperty('id_partner') && data.id_partner == 0) {
					return $ViewHelper.alert($LanguageHelper.translate('_SUBSCIBE_WHO_ERROR_'), $LanguageHelper.translate('_NOTICE_'));
				}

				$AjaxHelper.doRequest('booking', 'saveBooking', data, {callback: (data) => {
					if(data.status == 'OK') {
						// Send user to payment screen!
						if(data.needsPayment && data.needsPayment == 1) return $ViewHelper.toBrowser(data.redirectURL);

						// Send user to custom URL if we get this from the back-end (used when a user with an existing account was directly booked into the trial)
						if(data.toCustomURL && data.toCustomURL == 1) return $ViewHelper.toBrowser(data.redirectURL);

						// Send user to confirmation page with reservation id (reservation will be fetched and proper message wil be displayed)
						if(data.rid && data.rid > 0) return $ViewHelper.toBrowser(`?page=booking-confirm&external=1&id=${this.queryParams.id}&rid=${data.rid}`); // Use same window, no target blank

						// Free trial with account so no reservation id, just send user to the confirmation page
						return $ViewHelper.toBrowser(`?page=booking-confirm&external=1&id=${this.queryParams.id}`); // Use same window, no target blank
					}
				}});
			}
		},

		renderAppointmentSelected() {
			let appointment = this.selectedAppointment;
			let output = '';

			if(appointment) {
				// Render partner users here
				this.form.fields.id_partner.options = [];
				this.form.fields.id_partner.render = false;

				if($UserHelper.isLoggedIn() && appointment.partners && appointment.partners.length > 0) {
					// Push first (question) option
					this.form.fields.id_partner.options.push({
						label: $LanguageHelper.translate('_SUBSCIBE_QUESTION_WHO_'),
						value: 0,
						selected: true
					});

					appointment.partners.forEach((partner, key) => {
						this.form.fields.id_partner.options.push({
							label: partner.user && partner.user == 1 ? $LanguageHelper.translate('_MYSELF') : partner.firstname, // First partner is always the user himself, change firstname to myself!
							value: partner.id_appuser_partner_user > 0 ? partner.id_appuser_partner_user : null,
							selected: false
						});
					});

					// If only me is left then it makes no sense to show a list of partners (back-end always gives partners with the user itself as first item)!
					if(appointment.partners.length == 1) {
						if(appointment.partners[0].user && appointment.partners[0].user == 1) {
							// Empty the list again!
							this.form.fields.id_partner.options = [];
						}
					}

					// Only render if there are options
					this.form.fields.id_partner.render = this.form.fields.id_partner.options.length > 0;
				}

				// Set appointment info output
				if(appointment.location && appointment.location != '') {
					output += `<div><b>${$LanguageHelper.translate('_LOCATION_')}</b><br />${appointment.location}</div>`;
				}

				if(appointment.description && appointment.description != '') {
					output += `<div><b>${$LanguageHelper.translate('_DESCRIPTION_')}</b><br />${appointment.description}</div>`;
				}
			}

			this.form.fields.appointmentInfo.text = output.length > 0 ? `<div class="wrapperAppointmentInfo">${output}</div>` : '';
			this.form.fields.appointmentInfo.render = output.length > 0;
		},

		renderRegisterAccount() {
			this.resetForm();
			this.form.submit.text = '_BOOK_';
			this.form.fields.firstname.render = true;
			this.form.fields.middlename.render = true;
			this.form.fields.lastname.render = true;
			this.form.fields.email.render = true;
			this.form.fields.mobile.render = true;
			//this.form.fields.gender.render = true;
			//this.form.fields.birthdate.render = true;
			this.form.showTerms = true;
			this.form.submit.render = true;
		},

		renderLogin() {
			this.resetForm();
			this.form.submit.text = '_LOGIN_';
			this.form.fields.username.render = true;
			this.form.fields.pass.render = true;
			this.form.submit.render = true;
		},

		renderInitLoggedIn() {
			this.resetForm();
			this.renderAppointmentSelected();
			this.form.submit.text = '_BOOK_';
			this.form.fields.id_appointment.render = true;
			this.form.showTerms = true;
			this.form.submit.render = true;
		},

		renderInitNotLoggedIn() {
			this.resetForm();
			this.form.fields.hasAccount.render = true;
			this.form.fields.hasNoAccount.render = true;
		},

		renderError() {
			this.resetForm();
			this.visible = true;
		},

		resetForm() {
			this.form.className = '';
			this.form.submit.render = false;
			this.form.showTerms = false;
			Object.values(this.form.fields).forEach((field) => {
				if(!field.stayVisible) field.render = false;
			});
		},

		renderNoAppointmentsFound() {
			this.resetForm();
			this.form.fields.appointmentInfo.text = `<div class="wrapperAppointmentInfo">${$LanguageHelper.translate('_NO_DATES_AVAILABLE_')}</div>`;
			this.form.fields.appointmentInfo.render = true;
		},

		renderAppointmentsList() {
			// Empty the list first
			this.form.fields.id_appointment.options = [];

			// If there are multiple options then push the make a choise option first
			if(this.data.appointments.length > 1) {
				this.form.fields.id_appointment.options.push({
					chooseOption: true,
					label: '_CHOOSE_DATE_',
					value: null,
					selected: !this.selectedAppointment
				});
			}

			let cnt = 0;
			this.data.appointments.forEach((appointment) => {
				this.form.fields.id_appointment.options.push({
					id_appointment: appointment.id_appointment,
					position_start: appointment.position_start,
					label: appointment.title,
					description: appointment.description,
					location: appointment.location,
					end: appointment.end,
					partners: appointment.partners,
					value: JSON.stringify({id_appointment: appointment.id_appointment, position_start: appointment.position_start}),

					// Select previous remembered option or if first if there ar no others
					selected: (this.selectedAppointment && this.selectedAppointment.id_appointment == appointment.id_appointment && this.selectedAppointment.position_start == appointment.position_start) || (this.data.appointments.length == 1)
				});

				cnt ++;
			});

			this.form.fields.id_appointment.render = true;

			// If there is only one item then show the appointment info always
			if(this.data.appointments.length == 1) this.renderAppointmentSelected();
		},

		login(data) {
			$AjaxHelper.doRequest('user', 'login', Object.assign(data, {gcl: 1 /* Get company levels too */}), {callback: this._login});
		},

		logout() {
			$UserHelper.logout();

			// Fetch appointments again, form will be rest automaticly in the callback!
			this.fetchAvailableAppointments();
		},

		fetchAvailableAppointments() {
			$AjaxHelper.doRequest('booking', 'getProductWithAvailableAppointments', {idc: this.queryParams.id, id_invoice_product: this.queryParams.pid, account: $UserHelper.isLoggedIn() ? 1 : 0, companyImages: 1}, {callback: this._availableAppointments});
		},

		_availableAppointments(data) {
			// Set background images if there are any
			if(data.companyImages && data.companyImages.bg) this.backgroundImage = data.companyImages.bg;

			// Set company name in navbar title if it's there
			if(data.gyminfo) this.title = data.gyminfo.name;

			// Store the terms, only if not set already
			if(data.userTerms && data.userTerms.userTermsURL && !this.terms.userTerms) this.terms.userTerms = data.userTerms;
			if(data.companyTerms && !this.terms.companyTerms) this.terms.companyTerms = data.companyTerms;

			// Store general data
			this.data = data;

			if(this.data.status == 'OK') {
				if(this.data.appointments && this.data.appointments.length > 0) {
					this.renderAppointmentsList();
					if($UserHelper.isLoggedIn()) this.renderInitLoggedIn();
					else this.renderInitNotLoggedIn();
				}

				else this.renderNoAppointmentsFound();
			}

			this.visible = true;
		},

		_login(data) {
			if(data.status == 'OK') {
				// Log user in and fetch the appointments again!
				$UserHelper.login(data);
				this.fetchAvailableAppointments();
			}
		},
  },

	created() {
		this.fetchAvailableAppointments();
	}
};
</script>

<style>
.wrapperBookingForm .list {margin: 0;}
.wrapperBookingForm .block {margin-top:15px; margin-bottom: 15px;}
.wrapperBookingForm .wrapperButton .listButton {margin-bottom: 0;}
.wrapperBookingForm .wrapperSelectRegular {margin-bottom: 5px;}
.wrapperBookingForm .wrapperSelectRegular .item-content {padding-left: 12px;}
.wrapperBookingForm .wrapperSelectRegular .item-inner::after, .wrapperBookingForm .wrapperButton .item-inner::after {display: none;}
.wrapperBookingForm .terms.first {padding-top: 15px;}
.wrapperBookingForm .terms .item-content {min-height: 33px;}

.wrapperBookingForm .wrapperAppointmentInfo {
	color: rgba(33,33,33,1)!important;
	padding-bottom: 15px;
	font-size: 16px;
}

.wrapperBookingForm .appointmentInfo .item-inner {padding-bottom: 0; padding-top: 0;}
.wrapperBookingForm .appointmentInfo .item-inner::after {display: none;}

.wrapperBookingForm .wrapperAppointmentInfo div {margin-bottom: 10px;}
.wrapperBookingForm .wrapperAppointmentInfo div:last-child {margin-bottom: 0}
</style>

<style scoped>
.notice {
	padding-left: 20px;
	padding-right: 20px;
}

.wrapperBookingForm {
	font-size: 16px;
	background: #fff;
	padding-top: 10px;
	padding-bottom: 15px;
	border-radius: var(--f7-card-border-radius);
}

.wrapperBookingForm .item {line-height: 27px!important;}
.wrapperBookingForm .wrapperProductInfo .title {margin-bottom: 10px;}

.wrapperBookingForm .wrapperProductInfo {
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: 12px;
}

.wrapperBookingForm .bottomLeftOptions {
	padding-left: 20px;
	line-height: 27px;
	font-size: 15px;
}
</style>
