<template>
  <popup v-if="render"
    :title="title"
    :opened="opened"
    @mounted="opened=true"
    @close="$emit('close')"
  >
    <list-form :params="form" @changed="changed" @submitted="submitted" />
	</popup>

</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import FormCheckboxGroupAction from '../js/mixins/formCheckboxGroupAction';

export default {
  mixins: [FormCheckboxGroupAction],

  components: {
    'popup': Popup,
    'list-form': ListForm
  },

  props: {
    idc: {required: true},
    selectFilters: {required: true},
    filters: {required: true},
  },

  data() {
    return {
      render: false,
      opened: false,
      title: $LanguageHelper.translate('_FILTERS_'),

      form: {
        fields: [],

        values: {},
        submit: {text: '_SAVE_', render: true},
      },
    }
  },

  methods: {
    changed(el, action) {
      // We need to loop this because our fields are in an array and we don't know the index number!
      this.form.fields.forEach((field) => {
        if(field.name == 'items') this.handleCheckboxGroupAction(el, field.items, action);
      });
    },

    submitted() {
      let data = [];
      let values = [];

      // Get selected values
      this.form.fields.forEach((field) => {
        // For select boxes
        if(field.items) {
          values = JSON.parse(JSON.stringify(this.form.values.items));
        } else {
          $.each(this.form.values, (index, obj) => {
            // For single select
            if(field.name == index) {
              if(this.selectFilters[index].options) {
                $.each(this.selectFilters[index].options, (option, value) => {
                  if(value.value == this.form.values[index]) {
                    this.selectFilters[index].value = value.id;
                  }
                });
              }
            }

            // For combined select
            else {
              $.each(this.selectFilters, (options, values) => {
                $.each(values.fields, (option, fields) => {
                  if(index == fields.systemName) {
                    fields.value = this.form.values[index];
                  }
                });
              });
            }
          });
        }
      });

      // Replace selected values
      $.each(this.selectFilters, (index, obj) => {
        obj.values = [];
        obj.values = values;
      });

      this.filters.customConditions = this.selectFilters;

			this.$emit('filtersSelected');
      this.opened = false;
    },

    renderCombinedSelect(obj) {
      var fields = {};
      var items = [];
      $.each(obj.fields, (index, item) => {
        let options = [];
        $.each(item.options, (index, option) => {
          //if numbers add option directly
          if(!option.name) {
            options.push({
              name: option,
              label: $FunctionsHelper.specialChars(option),
              value: option,
              selected: (option == item.value ? true : false),
            });
          } else {
            //if deeper layer do this
            options.push({
              id: option.id,
              name: option.name,
              label: $FunctionsHelper.specialChars(option.name),
              value: option.id,
              selected: (option.id == item.value ? true : false),
            });
          }
        });

        // Set fields
        fields[item.systemName] = [];
        items = {
          type: 'select',
          class: (item.class == 'length_period' ? 'small' : 'medium'),
          name: item.systemName,
          options: options,
        };

				fields[item.systemName] = items;
      });

      // Render the combined fields here
      this.form.fields.push({name: obj.systemName, type: 'combined', title: obj.name, fields: fields, render: true});
    },

    renderSelect(obj) {
      let options = [];
      $.each(obj.options, (index, item) => {
        options.push({
          id: item.id,
          name: item.name,
          label: $FunctionsHelper.specialChars(item.name),
          value: item.value,
          selected: (item.id == obj.value ? true : false),
        });
      });

      // Render the select fields here
      this.form.fields.push({name: obj.systemName, type: 'select', title: obj.name, options: options, render: true});
    },

    renderCheckboxGroup(obj) {
      let items = [];
			let atLeastOneIsSelected = false;

			// Set field select all
      if(obj.name) {
        var name = {
          title: obj.name,
          ref: 1,
          parentRef: 0,
          level: 1,
          checked: false,
          class: 'fontBold',
          render: true,
          value: '',
        };

        items.push(name);
      }

      // Set the other fields
      $.each(obj.options, (index, item) => {
        let selected = null;
        $.each(obj.values, (index, value) => {
          if(value == item.id) {
						atLeastOneIsSelected = true;
						selected = item.id;
					}
        });

        items.push({
          title: $FunctionsHelper.specialChars(item.name),
          render: true,
          checked: (selected == item.id ? true : false),
          value: item.id,
          ref: 1,
          parentRef: 0,
          level: 2,
        })
      });

			// Check the top checkbox if at least one sub one is checked
			if(atLeastOneIsSelected) items[0].checked = true;

			// Render the grouped checkboxes here
      this.form.fields.push({name: 'items', type: 'checkbox_group', items: items, render: true});
    },

    setFilters() {
      $.each(this.selectFilters, (index, obj) => {
				if(obj.type == 'select') this.renderSelect(obj);
				if(obj.type == 'multi_select') this.renderCombinedSelect(obj);
				if(obj.type == 'multi_checkbox') this.renderCheckboxGroup(obj);
      });

      this.render = true;
    },
  },

  created() {
    this.setFilters();
  },
};
</script>
