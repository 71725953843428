<template>
  <popup v-if="render" :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<!-- Existing appointment that is not over yet and management is allowed -->
		<template v-if="appointment && manageAppointmentAllowed && !appointmentOver">
			<f7-list media-list inset>
        <!-- Remove appointment buttons-->
        <template v-if="$UserHelper.hasAccess(`agenda_${id_agenda}`, 'removeAppointment', idc)">
          <f7-list-item v-if="this.appointment.standalone!=1" class="smallTitle">{{$LanguageHelper.translate('_REMOVE_APPOINTMENT_')}}</f7-list-item>

					<f7-list-item>
            <f7-button v-if="this.appointment.standalone==1" fill small raised @click="remove(false)" class="listButton">{{$LanguageHelper.translate('_REMOVE_APPOINTMENT_')}}</f7-button>

						<f7-row v-else>
              <f7-col>
                <f7-button fill small raised @click="remove(false)" class="listButton">{{$LanguageHelper.translate('_ONLY_THIS_')}}</f7-button>
              </f7-col>

              <f7-col>
                <f7-button fill small raised @click="remove(true)" class="listButton">{{$LanguageHelper.translate('_THIS_AND_FUTURE_')}}</f7-button>
              </f7-col>
            </f7-row>
					</f7-list-item>
        </template>
			</f7-list>
				
			<!-- Show subscribed users (appointment, no show and waitinglist)-->
			<agenda-manage-subscribers-list v-if="$UserHelper.hasAccess(`agenda_${id_agenda}`, 'viewSubscriptions', idc)" :idc="idc" :id_agenda="id_agenda" :params="appointment" :numberChanges="numberChanges" type="subscribed" @changed="fetchAppointmentAndEmitChanged" @checkPresentToggled="checkPresentToggled"/>
			<agenda-manage-subscribers-list v-if="!appointmentOver && $UserHelper.hasAccess(`agenda_${id_agenda}`, 'viewWaitinglist', idc)" :idc="idc" :id_agenda="id_agenda" :params="appointment" :numberChanges="numberChanges" type="waiting" @changed="fetchAppointmentAndEmitChanged" />
			<agenda-manage-subscribers-list v-if="$UserHelper.hasAccess(`agenda_${id_agenda}`, 'viewSubscriptions', idc)" :idc="idc" :id_agenda="id_agenda" :params="appointment" :numberChanges="numberChanges" type="noShow" @changed="fetchAppointmentAndEmitChanged" />

			<!-- Edit appointment form -->
			<list-form :params="form" @submitted="submitted" @changed="changed" />
		</template>

		<!-- Existing appointment where management is not allowed or appointment is over -->
		<f7-card v-if="appointment && (!manageAppointmentAllowed || appointmentOver)">
      <f7-card-content>
        <div v-if="appointmentOver" class="contentItem marginBottom20">
          <div class="item-title fontOrange"><b>{{$LanguageHelper.translate('_APPOINTMENT_IN_PAST_')}}</b></div>
        </div>

				<!-- Subscribe users form here if we have the needed access -->
				<list-form v-if="$UserHelper.hasAccess(`agenda_${id_agenda}`, 'subscribeAppointment', idc)" :params="form" @submitted="submitted" @changed="changed" class="listFormAppointmentOver" :class="manageAppointmentAllowed && employees && employees.length > 0 ? 'marginBottom40' : ''" />

				<!-- List of subscribers here -->
				<div v-if="$UserHelper.hasAccess(`agenda_${id_agenda}`, 'viewSubscriptions', idc)" class="list media-list contentItem appointmentOverManageSubscribers">
					<agenda-manage-subscribers-list :idc="idc" :id_agenda="id_agenda" :params="appointment" :numberChanges="numberChanges" type="subscribed" @changed="fetchAppointmentAndEmitChanged" @checkPresentToggled="checkPresentToggled" />
					<agenda-manage-subscribers-list v-if="!appointmentOver && $UserHelper.hasAccess(`agenda_${id_agenda}`, 'viewWaitinglist', idc)" :idc="idc" :id_agenda="id_agenda" :params="appointment" :numberChanges="numberChanges" type="waiting" @changed="fetchAppointmentAndEmitChanged" />
					<agenda-manage-subscribers-list :idc="idc" :id_agenda="id_agenda" :params="appointment" :numberChanges="numberChanges" type="noShow" @changed="fetchAppointmentAndEmitChanged" />
				</div>

				<!-- General appointment info -->
				<f7-row class="contentItem">
	        <f7-col width="10">
	          <div class="appointmentIcon"><f7-icon class="icon ionClock themeDetail"></f7-icon></div>
	        </f7-col>

	        <f7-col width="90">
	          <div class="item-title"><b>{{$LanguageHelper.translate('_DATE_')}}</b></div>
						<div class="item-title">{{$LanguageHelper.formatDate(moment(appointment.date_start).format('YYYY-MM-DD'))}} {{$LanguageHelper.translate('_FROM_LOWER_')}} {{moment(appointment.date_start).format('HH:mm')}} {{$LanguageHelper.translate('_UNTIL_LOWER_')}} {{moment(appointment.date_end).format('HH:mm')}} {{$LanguageHelper.translate('_HOUR_')}}</div>
	        </f7-col>
	      </f7-row>

				<f7-row class="contentItem">
          <f7-col width="10">
            <div class="appointmentIcon"><f7-icon class="icon ionInformationCircle themeDetail"></f7-icon></div>
          </f7-col>

          <f7-col width="90">
            <div class="item-title"><b>{{$LanguageHelper.translate('_DESCRIPTION_')}}</b></div>
            <div class="item-title">{{$FunctionsHelper.specialChars(appointment.name)}}</div>
            <div v-if="appointment.description.length > 0" class="item-title">{{$FunctionsHelper.specialChars(appointment.description)}}</div>
          </f7-col>
        </f7-row>

				<f7-row v-if="appointment.location && appointment.location.length > 0" class="contentItem">
          <f7-col width="10">
            <div class="appointmentIcon"><f7-icon class="icon ionLocation themeDetail"></f7-icon></div>
          </f7-col>

          <f7-col width="90">
            <div class="item-title"><b>{{$LanguageHelper.translate('_LOCATION_')}}</b></div>
            <div class="item-title">{{$FunctionsHelper.specialChars(appointment.location)}}</div>
          </f7-col>
        </f7-row>

				<!-- Spots available info -->
				<f7-row class="contentItem">
          <f7-col width="10">
            <div class="appointmentIcon"><f7-icon :class="`icon ${appointment.spotsLeft == 0 || !moment(appointment.date_start).isAfter(moment()) || appointment.subscriptions.total >= appointment.max_subscriptions ? 'ionClose' : 'ionCheckmark'} themeDetail`"></f7-icon></div>
          </f7-col>

          <f7-col width="90">
            <div class="item-title"><b>{{$LanguageHelper.translate('_SPOTS_AVAILABLE_INFO_')}}</b></div>
            <div class="item-title">{{$LanguageHelper.translate('_SPOTS_AVAILABLE_')}} {{appointment.max_subscriptions}}, {{$LanguageHelper.translate('_SPOTS_LEFT_')}} {{appointment.spotsLeft}}</div>
          </f7-col>
        </f7-row>
			</f7-card-content>
		</f7-card>

		<!--  New appointment where management is allowed -->
		<list-form v-if="!appointment && manageAppointmentAllowed" :params="form" @submitted="submitted" @changed="changed" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import AgendaManageSubscribersList from '../components/agenda-manage-subscribers-list.vue';

export default {
  components: {
    'popup': Popup,
    'list-form': ListForm,
    'agenda-manage-subscribers-list': AgendaManageSubscribersList,
  },

  props: {
		idc: {required: true},
    id_agenda: {required: true},
    params: {required: false}, // Contains appointment data
	},

  data() {
    return {
      title: '',
      opened: false,
      render: false,
			numberChanges: 0,
			appointment: null, // If we have an existing appointment it will contain fetched data from back-end
			appointmentDurationMinutes: 60,
			appointmentTypes: null,
			employees: [],
			showSubscriptions: false,

      form: {
        fields: {
          subscribe_customer: {type: 'autocomplete', name: 'subscribe_customer', label: '_SUBSCRIBE_CUSTOMER_', placeholder: '_SEARCH_CUSTOMER_', render: false, value: '', items: []},
          appointment_divider: {name: 'appointmentDivider', type: 'divider', title: '_EDIT_APPOINTMENT_', render: false},
          name: {name: 'name', type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          description: {name: 'description', type: 'input', label: '_DESCRIPTION_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          location: {name: 'location', type: 'input', label: '_LOCATION_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          settings_divider: {name: 'settings_divider', type: 'divider', title: '_APPOINTMENT_SETTINGS_', render: false},
          id_appointment_type: {name: 'appointment_type', type: 'select', title: '_APPOINTMENT_TYPE_', render: false, options: []},
          max_subscriptions: {name: 'max_subscriptions', type: 'input', label: '_MAX_SUBSCRIPTIONS_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					date_start: {type: 'datetime', name: 'date_start', label: '_FROM_', placeholder: '_DATE_FROM_', render: false, value: ''},
					date_end: {type: 'datetime', name: 'date_end', label: '_UNTIL_', placeholder: '_DATE_UNTIL_', render: false, value: ''},

					employees_divider: {type: 'divider', title: '_EMPLOYEES_INSTRUCTORS_', render: false},
					employees: {type: 'checkbox_group', items: [], render: false},

					repeat_frequency: {name: 'repeat_frequency', type: 'select', title: '_FREQUENCY_', render: false, options: [{label: '_SINGLE_APPOINTMENT_', value: 1, selected: false}, {label: '_REPEAT_', value: 2, selected: false}]},

					id_appointment_repeat: {name: 'id_appointment_repeat', type: 'select', title: '_REPEAT_', render: false, options: {
	          daily: {name: 'daily', label: '', value: 2, selected: true},
	          working_day: {name: 'working_day', label: '', value: 3, selected: false},
	          weekly: {name: 'weekly', label: '', value: 4, selected: false},
	          monthly_weekday: {name: 'monthly_weekday', label: '', value: 5, selected: false},
	          monthly_monthday: {name: 'monthly_monthday', label: '', value: 6, selected: false},
	          yearly: {name: 'yearly', label: '', value: 7, selected: false},
          }},

          series_warning: {name: 'series_warning', type: 'description', class: 'textPayAttention', text: '', render: false},
        },

        submit: {text: '_SAVE_', render: false},

				values: {},
      },
    };
  },

  computed: {
		manageAppointmentAllowed() {
			return $UserHelper.hasAccess(`agenda_${this.id_agenda}`, 'saveAppointment', this.idc);
		},

		appointmentOver() {
			return moment(this.appointment.date_start).isBefore(moment());
		},
  },

  methods: {
    changed(field, option) {
			if(field.name == 'appointment_type') {
				// Set the default appointment duration if it' set
				this.appointmentDurationMinutes = 60;
				this.appointmentTypes.forEach((appointmentType) => {
					if(appointmentType.id_appointment_type == option.value) {
						
						if(appointmentType.default_appointment_duration_minutes > 0) {
							// Set the new appointment duration
							this.appointmentDurationMinutes = appointmentType.default_appointment_duration_minutes;
							return;
						}
					}
				});

				// (Re)set the date end value
				this.setDateEndField();
			}

			// If new appointment, if date_start is changed set date_end to same day + 1 hour
			if(field.name == 'date_start') {
				this.setDateEndField();

				// Set the repeat labels again, the date has changed
				this.setRepeatLabels();
			}

			// Recalculate duration
			if(field.name == 'date_end') this.appointmentDurationMinutes = moment.duration(moment(this.form.fields.date_end.value).diff(moment(this.form.fields.date_start.value))).asMinutes();

      // Repeat option (2) selected? Then render, otherwise hide!
      if(field.name == 'repeat_frequency') this.form.fields.id_appointment_repeat.render = option.value == 2;

      // Search customer or select one
      if(field.name == 'subscribe_customer') {
        //pick customer
        if(option && option.autocompleteSelected) {
					// Subscribe the selected user
					this.subscribeUser(option, field);
				}
        // Search customer
        else {
          if(field.value && field.value.length > 0) {
            $AjaxHelper.doRequest('agenda', 'getUsersByName', {name: field.value, idc: this.idc, id_agenda: this.id_agenda}, {callback: this._foundUsers});
          } else {
						// Empty list again
						this.form.fields.subscribe_customer.items = [];
					}
        }
      }
    },

		setDateEndField() {
			this.form.fields.date_end.value = moment(this.form.fields.date_start.value).add(this.appointmentDurationMinutes, 'minutes').format('YYYY-MM-DD HH:mm');
			this.form.fields.date_end.render = false;
			setTimeout(() => this.form.fields.date_end.render = true, 1);
		},

		remove(all) {
			$ViewHelper.confirm({method: () => {
				let data = {
					id: this.appointment.id_appointment,
					idc: this.idc,
					id_agenda: this.id_agenda,
					date_start_org: moment(this.params.date_start).format('YYYY-MM-DD HH:mm'), // Needed for date cancel in back-end to end the series
					date_end_org: moment(this.params.date_end).format('YYYY-MM-DD HH:mm'),
					remove: all ? 'all' : 'single'
				};

				$AjaxHelper.doRequest('agenda', 'removeAppointment', data, {callback: this._appointmentSubmitted});
			}});
		},

    subscribeUser(item, field) {
			let buttons = [
        // Subscribe
        {text: $LanguageHelper.translate('_WITH_PRODUCT_'), onClick: () => {
          this.sendSubscribe(item, false);

					// Empty the field here so it will empty the autocomplete results
					field.value = '';
        }},

				// Subscribe for free
        {text: $LanguageHelper.translate('_FREE_'), onClick: () => {
          this.sendSubscribe(item, true);

					// Empty the field here so it will empty the autocomplete results
					field.value = '';
        }},

				// Reset start value!
        {text: $LanguageHelper.translate('_CLOSE_'),
        onClick: () => {
          return;
        }},
      ];

      $ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_HOW_TO_SUBSCRIBE_'));
    },

    // Set appointmenttypes for select
    setAppointmentTypes() {
			// Default
      this.form.fields.id_appointment_type.options.push({
        name: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        value: '',
        label: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        selected: false,
      });

      // Options
      this.appointmentTypes.forEach((appointmentType) => {
        this.form.fields.id_appointment_type.options.push({
          name: appointmentType.name,
          value: appointmentType.id_appointment_type,
          label: appointmentType.name,
          selected: false,
        });
      });
    },

    setRepeatLabels() {
			let date = moment(this.form.fields.date_start.value != '' ? this.form.fields.date_start.value : null, "YYYY-MM-DD");
      let day = date.date();
      let month = date.month();
      let weekDay = date.isoWeekday();

			// Get the weekday and month label
      let weekdayLabel = $LanguageHelper.getDayLabel(weekDay - 1);
      let monthLabel = $LanguageHelper.getMonthLabel(month);

      // Set labels for repeat
      this.form.fields.id_appointment_repeat.options.daily.label = $LanguageHelper.translate('_DAILY_');
      this.form.fields.id_appointment_repeat.options.working_day.label = $LanguageHelper.translate('_WORKING_DAY_');
      this.form.fields.id_appointment_repeat.options.weekly.label = `${$LanguageHelper.translate('_WEEKLY_')} (${weekdayLabel})`;
      this.form.fields.id_appointment_repeat.options.monthly_weekday.label = `${$LanguageHelper.translate('_EVERY_')} ${Math.ceil((day) / 7)}${$LanguageHelper.translate('_ST_')} ${weekdayLabel}`;
      this.form.fields.id_appointment_repeat.options.monthly_monthday.label = `${$LanguageHelper.translate('_MONTHLY_')} (${$LanguageHelper.translate('_AT_DAY_')} ${day})`;
      this.form.fields.id_appointment_repeat.options.yearly.label = `${$LanguageHelper.translate('_YEARLY_')} (${$LanguageHelper.translate('_AT_')} ${day} ${monthLabel})`;
    },

		renderForm() {
			// Render the subscribe customer field if user has access to it.
			this.form.fields.subscribe_customer.render = $UserHelper.hasAccess(`agenda_${this.id_agenda}`, 'subscribeAppointment', this.idc) && this.params /* Existing appointment */;

			// Render these fields only if user is allowed to see them and appointment is not over yet
			if((this.appointment && !this.appointmentOver && this.manageAppointmentAllowed) || (!this.appointment && this.manageAppointmentAllowed)) {
				// Set the repeat labels and appointment types
				this.setRepeatLabels();
				this.setAppointmentTypes();

				// Render fields here
				this.form.fields.appointment_divider.render = this.appointment /* Existing appointment */;
				this.form.fields.name.render = true;
				this.form.fields.description.render = true;
				this.form.fields.location.render = true;
				this.form.fields.settings_divider.render = true;
        this.form.fields.max_subscriptions.render = true;
        this.form.fields.date_start.render = true;
        this.form.fields.date_end.render = true;
				this.form.fields.id_appointment_type.render = true;

				// Only show repeat options if this is not a standalone
				this.form.fields.repeat_frequency.render = false;
				this.form.fields.id_appointment_repeat.render = false;
				if((this.appointment && this.appointment.standalone != 1) || !this.appointment) {
					this.form.fields.repeat_frequency.render = true;
					this.form.fields.id_appointment_repeat.render = (this.appointment && this.appointment.id_appointment_repeat > 1);
				}

				// Show series warning if present
				if(this.appointment && this.appointment.series_warning) {
					this.form.fields.series_warning.text = this.appointment.series_warning;
					this.form.fields.series_warning.render = true;
				}

				this.form.submit.render = true;
			}

			// Fields that should also be visible when the appointment is over
			if(this.manageAppointmentAllowed) {
				// New appointment or existing not in the past yet or existing in the past that is standalone
				if(!this.appointment || (this.appointment && (!this.appointmentOver || (this.appointmentOver && this.appointment.standalone == 1)))) {
					// Show users with levels (employees) so they can be connected to the appointment
					this.form.fields.employees.items = [];
					if(this.employees && this.employees.length > 0) {
						this.employees.forEach((item) => {
							this.form.fields.employees.items.push({
								title: item.name,
								value: item.id_appuser,
								checked: item.selected == 1,
								render: true
							});
						});

						this.form.fields.employees_divider.render = true;
						this.form.fields.employees.render = true;
						this.form.submit.render = true
					}
				}
			}
		},

    // Save appointment
    submitted() {
			let dateStart = moment(this.form.values.date_start);
			let dateEnd = moment(this.form.values.date_end);

			let data = {
				// Needed id's
				idc: this.idc,
				id_agenda: this.id_agenda,

				// Back-end expects repeat field
				repeat: this.form.values.id_appointment_repeat ? this.form.values.id_appointment_repeat : 1,

				// Employees
				employees: this.form.values.employees && this.form.values.employees.length > 0 ? this.form.values.employees : '',

				// Start data
				date_start_year: dateStart.format('YYYY'),
				date_start_month: dateStart.format('MM'),
				date_start_day: dateStart.format('DD'),
				date_start_hour: dateStart.format('HH'),
				date_start_minute: dateStart.format('mm'),

				// End data
				date_end_year: dateEnd.format('YYYY'),
				date_end_month: dateEnd.format('MM'),
				date_end_day: dateEnd.format('DD'),
				date_end_hour: dateEnd.format('HH'),
				date_end_minute: dateEnd.format('mm'),

				// Time values
				date_start_time: dateStart.format('HH:mm'),
				date_end_time: dateEnd.format('HH:mm'),

				// New Full dates
				date_start: dateStart.format('YYYY-MM-DD HH:mm'),
				date_end: dateEnd.format('YYYY-MM-DD HH:mm'),
			};

			// If this is an existing appointment add aditional needed data!
			if(this.appointment) {
				// Add the id appointment
				data.id = this.appointment.id_appointment;

				// Add the dates where the appointment originaly came from (back-end needs it)
				data.date_start_org = moment(this.params.date_start).format('YYYY-MM-DD HH:mm');
				data.date_end_org = moment(this.params.date_end).format('YYYY-MM-DD HH:mm');

				// Call the method to save the appointment if the appointment is not over yet
				if(!this.appointmentOver) {
					$AjaxHelper.doRequest('agenda', 'saveAppointment', Object.assign({}, JSON.parse(JSON.stringify(this.form.values)), data), {callback: this._appointmentSubmitted});
				}
				// Existing appointment is over, call the save employees method
				else {
					$AjaxHelper.doRequest('agenda', 'saveAppointmentEmployees', Object.assign({}, JSON.parse(JSON.stringify(this.form.values)), data), {callback: this._appointmentSubmitted});
				}
			}
			// New appointment
			else {
				$AjaxHelper.doRequest('agenda', 'saveAppointment', Object.assign({}, JSON.parse(JSON.stringify(this.form.values)), data), {callback: this._appointmentSubmitted});
			}
    },

		fetchAppointmentAndEmitChanged() {
			// Emit changed to appointments view will be fetched again!
			this.$emit('changed');

			// Fetch the appointment again!
			this.fetchAppointment();
		},

		checkPresentToggled() {
			// Fetch the appointment again!
			this.fetchAppointment();
		},

    fetchAppointment() {
			let data = {
				id: this.appointment.id_appointment,
				date_start: moment(this.appointment.date_start).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.appointment.date_end).format('YYYY-MM-DD HH:mm'),
			};

      $AjaxHelper.doRequest('agenda', 'appointment', Object.assign({idc: this.idc, id_agenda: this.id_agenda}, data), {callback: this._appointment});
    },

    appointmentNew() {
      $AjaxHelper.doRequest('agenda', 'appointmentNew', {idc: this.idc, id_agenda: this.id_agenda}, {callback: this._appointmentNew});
    },

		sendSubscribe(user, free = false) {
			$AjaxHelper.doRequest('agenda', 'adminSubscribeUser', Object.assign({idc: this.idc, action: 'subscribe', free: free ? 1 : 0, id_agenda: this.id_agenda, id: this.appointment.id_appointment, date_start_org: this.appointment.date_start, date_end_org: this.appointment.date_end}, user), {callback: this._appointmentChanged});
    },

    _foundUsers(data) {
      // Empty fields here!
			this.form.fields.subscribe_customer.items = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((user, key) => {
					this.form.fields.subscribe_customer.items.push({
						autocompleteSelected: true,
						title: user.name,
						footer: user.email,
						name: user.name,
						email: user.email,
						id_appuser: user.id_user,
            id_partner: user.id_partner,
					});
				});
			}
    },

    _appointmentChanged(data) {
      if(data.status == 'OK') {
				// Store the (newly created) id appointment here so it fetches the right one!
				this.appointment.id_appointment = data.resultset.id_appointment;

				// Get the appointment and emit change event
				this.fetchAppointmentAndEmitChanged();
      }
    },

    _appointmentSubmitted(data) {
			if(data.status == 'OK') {
				this.opened = false;
				this.$emit('changed');
      }
    },

    _appointment(data) {
			// Ad one to the number of changes so our children components will update
			this.numberChanges ++;

			// Remove the date props, we want to keep the original ones, they are incorrect if we have a series appointment!
			delete data.resultset.date_start;
			delete data.resultset.date_end;

			// Store the popup title
      this.title = data.resultset.name;

			// Store appointment types
			this.appointmentTypes = data.resultset.appointment_types;

			// Store the employees
			this.employees = data.resultset.employees ? data.resultset.employees : [];

			// Store appointment data
			this.appointment = Object.assign(this.appointment, data.resultset);

			// Set the repeat frequency => repeated = 2 or single = 1
			this.appointment.repeat_frequency = this.appointment.id_appointment_repeat > 1 ? 2 : 1;

			// Store the spots left
			this.appointment.spotsLeft = (parseInt(this.appointment.max_subscriptions) - parseInt(this.appointment.subscriptions.total)) < 0 ? 0 : (parseInt(this.appointment.max_subscriptions) - parseInt(this.appointment.subscriptions.total));

			// Store form values, clone the object by giving {} als first param, otherwise form will strip out the reference values
			this.form.values = Object.assign({}, this.appointment, {date_start: moment(this.appointment.date_start).format('YYYY-MM-DD HH:mm'), date_end: moment(this.appointment.date_end).format('YYYY-MM-DD HH:mm')});

			// Calulate the appointment duration
			this.appointmentDurationMinutes = moment.duration(moment(this.appointment.date_end).diff(moment(this.appointment.date_start))).asMinutes()

			// Render the form
			this.renderForm();

			// Render the page
			this.render = true;
    },

    _appointmentNew(data) {
			// Set popup title
      this.title = $LanguageHelper.translate('_NEW_APPOINTMENT_');

			// Store appointment types
			this.appointmentTypes = data.resultset.appointment_types;

			// Store the employees
			this.employees = data.resultset.employees ? data.resultset.employees : [];

			// Set default values for end dates, round up start time 30 minutes
			const start = moment();
			const remainder = 30 - (start.minute() % 30);
			this.form.fields.date_start.value = moment(start).add(remainder, 'minutes').format('YYYY-MM-DD HH:mm');
			this.form.fields.date_end.value = moment(this.form.fields.date_start.value).add(this.appointmentDurationMinutes, 'minutes').format('YYYY-MM-DD HH:mm');

			// Render the form
			this.renderForm();

			// Render the page
      this.render = true;
    },
  },

  created() {
		// Call for existing appointment
    if(this.params) {
			// Copy first appointment data from params so we can fetch the rest from back-end
			this.appointment = this.params;

			this.fetchAppointment();
		}

    // Call for new appointment
    else this.appointmentNew();
  }
};
</script>

<style>
/** Clear paddings for appointment over, wrapper subscribers list  **/
.appointmentOverManageSubscribers {margin-left: 0!important;}
.appointmentOverManageSubscribers section.image {line-height: 45px;}
.appointmentOverManageSubscribers section.postContent {line-height: 30px;}
.appointmentOverManageSubscribers .toggleTitle {padding-left: 0!important;}
.appointmentOverManageSubscribers .item-content {padding-left: 0!important;}
.appointmentOverManageSubscribers .wrapperSubscribers {margin-top: -12px;}
.appointmentOverManageSubscribers .wrapperSubscribers .subscribers {padding-bottom: 5px;}
.appointmentOverManageSubscribers .wrapperSubscribers .subscribers:last-child {padding-bottom: 10px;}

/** Clear paddings for appointment over list **/
.listFormAppointmentOver {margin-bottom: 15px;}

.listFormAppointmentOver .list {
	margin-left: 0!important;
	margin-right: 0!important;
}

.listFormAppointmentOver .list .item-content {padding-left: 0!important}
</style>

<style scoped>
/** Appointment past or no access styles **/
.contentItem {
  margin-bottom: 10px;
}

i.icon {
  margin-top: 5px;
}
</style>
