<template>
	<popup
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
		v-if="visible"
		>

		<list-form
			:idc="idc"
			:params="form"
			@changed="changed"
      @submitted="submitted"
		/>

		<f7-list inset v-if="history">
			<f7-list-item v-for="(item, index) in history" :key="index">
				<div>{{item.date_wod}}
					<f7-link class="link theme action" :title="$LanguageHelper.translate('_EDIT_')" @click="openEditPublication(item)"><i class="icon ionEdit"></i></f7-link>
					<f7-link class="link theme action" :title="$LanguageHelper.translate('_DELETE_')" @click="deletePublication(item.id_wod_whiteboard, item.id_wod)"><i class="icon ionTrash"></i></f7-link>
				</div>
			</f7-list-item>
		</f7-list>

		<manage-edit-publication-wod v-if="editPublication" :idc="idc" :publicationItem="publicationItem" @close="editPublication=false" />
		<manage-categories-wod v-if="editCategories" :idc="idc" @getCategories="getCategories" @close="editCategories=false" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import ManageCategoriesWod from '../popups/manage-categories-wod.vue';
import ManageEditPublicationWod from '../popups/manage-edit-publication-wod.vue';

export default {
	components: {
    'popup': Popup,
    'list-form': ListForm,
		'manage-categories-wod': ManageCategoriesWod,
		'manage-edit-publication-wod': ManageEditPublicationWod,
	},

	props: {
		idc: {required: true},
		idwod: {required: false},
		publish: {required: false},
  },

	data() {
    return {
			visible: false,
			opened: false,
			editCategories: false,
			editPublication: false,
			publicationItem: {},
			score_as: null,
			title: $LanguageHelper.translate('_CREATE_NEW_'),
			history: {},
			categories: {},
			form: {
				fields: {
					published_description: {type: 'description', text: '_VIEW_ONLY_PUBLISHED_WODS_', class: 'textPayAttention', render: false},
					name: {type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					description: {type: 'textarea', label: '_DESCRIPTION_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					athlete_log_not_allowed: {type: 'checkbox', title: '_ATHLETE_LOG_NOT_ALLOWED_', checked: false, value: 1, render: true},
					athlete_youtube_url_required: {type: 'checkbox', title: '_ATHLETE_YOUTUBE_URL_REQUIRED_', checked: false, value: 1, render: true},
					id_wod_score_as: {type: 'select', name: 'score_as', title: '_WOD_SCORE_AS_', render: true, options: {}},
					ranking_divider: {type: 'divider', title: '_WOD_RANK_CATEGORIES_', render: true, action: {icon: 'ionEdit', name: 'edit_categories', title: '_EDIT_'}},
					id_wod_ranking_category: {type: 'checkbox_group', items: [], render: true},
					other_divider: {type: 'divider', title: '_OTHER_OPTIONS_', render: true},
					has_overall_ranking: {type: 'checkbox', title: '_WOD_OVERALL_RANKING_LABEL_', checked: false, value: 1, render: true},
					has_benchmark: {type: 'checkbox', title: '_WOD_BENCHMARK_LABEL_', checked: false, value: 1, render: true},
					rx: {type: 'checkbox', title: '_WOD_RX_LABEL_', checked: false, value: 1, render: true},
					fundamental: {type: 'checkbox', title: '_WOD_FUNDAMENTAL_LABEL_', checked: false, value: 1, render: true},
					privacy_divider: {type: 'divider', title: '_PRIVACY_', render: true},
					disabled_sharing: {type: 'checkbox', title: '_DISABLE_SHARING_', checked: false, value: 1, render: true},
					publish_wod_divider: {type: 'divider', title: '_PUBLISH_WOD_', render: true},
					publish_now: {name: 'publish_now', type: 'checkbox', title: '_PUBLISH_NOW_', checked: true, value: 1, render: true},
					date_wod: {type: 'date', label: '_BY_DATE_', placeholder: '_SELECT_DATE_', render: true, value: ''},
					date_can_log_to: {type: 'date', label: '_CAN_LOG_TO_', placeholder: '_SELECT_DATE_', render: true, value: ''},
					youtube: {type: 'input', label: '_YOUTUBE_URL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
    };
  },


	methods: {
		openEditPublication(data) {
			this.publicationItem = data;
			this.editPublication = true;
		},

		deletePublication(val, val2) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('WOD', 'removeWhiteboardPublication', {idc: this.idc, id_wod_whiteboard: val, id_wod: val2}, {callback: this._deletedPublished})}}, $LanguageHelper.translate('_NOTICE_'));
		},

		changed(field, option) {
			if(field.name == 'edit_categories') this.editCategories = true;
			if(field.name == 'publish_now') {
				if(field.checked) {
					this.form.fields.date_wod.render = true;
					this.form.fields.date_can_log_to.render = true;
					this.form.fields.youtube.render = true;
				} else {
					this.form.fields.date_wod.render = false;
					this.form.fields.date_can_log_to.render = false;
					this.form.fields.youtube.render = false;
				}
			}
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			//check if existing wod
			if(this.idwod) {
				data['id_wod'] = this.idwod;
				//if this is a publication
				if(this.publish) $AjaxHelper.doRequest('WOD', 'publishToWhiteBoard', Object.assign({idc: this.idc}, data), {callback: this._published});
				else $AjaxHelper.doRequest('WOD', 'saveWOD', Object.assign({idc: this.idc}, data), {callback: this._initSaved});
			}
			//if new wod
			else {
				if(data.publish_now) data['publish'] = 1;
				$AjaxHelper.doRequest('WOD', 'saveWOD', Object.assign({idc: this.idc}, data), {callback: this._initSaved});
			}
		},

		getCategories(data) {
			this.form.fields.id_wod_ranking_category.items = [];
			this.setRankingCategories(data);
		},

		setScoreAs(data) {
			let options = [];
			data.forEach((item, index) => {
				options.push({
					value: item.id_wod_score_as,
					selected: (item.id_wod_score_as == this.score_as ? true : (index == 0 ? true : false)),
					label: item.name,
					name: item.name,
				});
			});
			this.form.fields.id_wod_score_as.options = options;
		},

		setRankingCategories(data) {
			//set for edit
			this.categories = data;
			//set for group checkboxes
			data.forEach((item, index) => {
				this.form.fields.id_wod_ranking_category.items.push({
					value: item.id_wod_ranking_category,
					checked: (item.selected && item.selected == 1 ? true : false),
					title: item.name,
					render: true
				});
			});
		},

		setData(data) {
			this.form.values = data;
			if(data.athlete_log_not_allowed == 1) this.form.fields.athlete_log_not_allowed.checked = true;
			if(data.athlete_youtube_url_required == 1) this.form.fields.athlete_youtube_url_required.checked = true;
			if(data.has_overall_ranking == 1) this.form.fields.has_overall_ranking.checked = true;
			if(data.has_benchmark == 1) this.form.fields.has_benchmark.checked = true;
			if(data.rx == 1) this.rx = this.form.fields.rx.checked = true;
			if(data.fundamental == 1) this.form.fields.fundamental.checked = true;
			if(data.disabled_sharing == 1) this.form.fields.disabled_sharing.checked = true;
			this.form.fields.publish_now.render = false;
			this.form.fields.publish_wod_divider.render = false;
			this.form.fields.date_wod.render = false;
			this.form.fields.date_can_log_to.render = false;
			this.form.fields.youtube.render = false;

			this.title = $LanguageHelper.translate('_EDIT_');
		},

		setDataPublish(data) {
			this.form.values = data;

			var that = this;
			$.each(that.form.fields, function (index, obj) {
				that.form.fields[index].render = false;
			});

			this.form.fields.date_wod.render = true;
			this.form.fields.date_can_log_to.render = true;
			this.form.fields.youtube.render = true;

			this.title = $LanguageHelper.translate('_PUBLISH_');
		},

		setDataPublished(data) {
			var that = this;
			$.each(that.form.fields, function (index, obj) {
				that.form.fields[index].render = false;
			});

			this.form.fields.published_description.render = true;
			this.form.submit.render = false;

			this.title = $LanguageHelper.translate('_LAST_PUBLISHED_');

			this.history = data;
		},

		_published(data) {
			if(data.status == 'OK') this.opened = false;
		},

		_deletedPublished(data) {
			if(data.status == 'OK') {
				this.history = {};
				this.history = data.history;

				if(this.history.length == 0) {
					var that = this;
					$.each(that.form.fields, function (index, obj) {
						that.form.fields[index].render = true;
					});

					this.form.fields.published_description.render = false;
					this.form.submit.render = true;
					this.setData(data.resultset);
				}
			}
		},

		_initSaved(data) {
			if(data.status == 'OK') {
				if(data.resultset) this.$emit('savedWod', data.resultset);
				this.opened = false;
			}
		},

		_initWod(data) {
			this.form.fields.id_wod_score_as.options = [];
			this.form.fields.id_wod_ranking_category.items = [];

			if(data.status == 'OK') {
				if(data.resultset && this.idwod) {
					//set data for published wod
					if(data.history && data.history.length > 0 && !this.publish) this.setDataPublished(data.history);

					//set data for publish wod
					else if(this.publish || (data.history && data.history.length > 0 && this.publish)) this.setDataPublish(data.resultset);

					//set data for edit wod
					else this.setData(data.resultset);

				}

				//set select fields
				if(data.score_as) this.setScoreAs(data.score_as);
				if(data.ranking_categories) this.setRankingCategories(data.ranking_categories);

				this.visible = true;
			}
		},

		fetchWod(val) {
			$AjaxHelper.doRequest('WOD', 'getWOD', {idc: this.idc, id_wod: val}, {callback: this._initWod});
		},

		getWod() {
			$AjaxHelper.doRequest('WOD', 'wodOptions', {idc: this.idc}, {callback: this._initWod});
		}
	},

	created() {
		if(this.idwod) this.fetchWod(this.idwod);
		else this.getWod();
	}
};
</script>

<style scoped>
.item-inner .link.action {
	padding-left: 6px;
}
</style>
