<template>
  <div v-show="visible">
    <list-form :params="form" @submitted="submitted">
    </list-form>
  </div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';

export default {
  components: {
    'list-form': ListForm,
  },

  props: {
    idc: {required: true},
  },

  data() {
    return {
      visible: false,
      form: {
        fields: {
          management_summary_enabled: {type: 'checkbox', title: $LanguageHelper.translate('_ENABLE_MANAGEMENT_SUMMARY_'), checked: true, value: 1, render: true},
          url_terms_and_conditons : {type: 'input', label: '_URL_COMPANY_TERMS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          version_terms_and_conditions : {type: 'input', label: '_VERSION_COMPANY_TERMS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
          accept_users_first: {type: 'checkbox', title: $LanguageHelper.translate('_ACCEPT_USERS_FIRST_'), checked: true, value: 1, render: true},
          send_new_user_mail: {type: 'checkbox', title: $LanguageHelper.translate('_SEND_NEW_USER_EMAIL_'), checked: true, value: 1, render: true},
        },

        values: {},
				submit: {text: '_SAVE_', render: true},
      }
    };
  },

  methods: {
    submitted() {
      $AjaxHelper.doRequest('User', 'editAdminSettings', $.extend({idc: this.idc}, JSON.parse(JSON.stringify(this.form.values))), {callback: this._settingsSubmitted});
    },

    fetchSettings() {
  		$AjaxHelper.doRequest('User', 'loadEditAccountData', {idc: this.idc}, {callback: this._initSettings});
  	},

    _initSettings(data) {
      if(data.status == 'OK') {
        if(data.admin_settings) {

          this.form.values = data.admin_settings;

          //set form items
          this.form.fields.management_summary_enabled.checked = (data.admin_settings.management_summary_enabled == 1 ? true : false);
          this.form.fields.accept_users_first.checked = (data.admin_settings.accept_users_first == 1 ? true : false);
          this.form.fields.send_new_user_mail.checked = (data.admin_settings.send_new_user_mail == 1 ? true : false);

          this.visible = true;
        }
      }
    },

    _settingsSubmitted(data) {
			if(data.status == 'OK') {
        this.form.values = {};
				this.fetchSettings();
			}
		},
  },

  created() {
    this.fetchSettings();
  },
}
</script>
