<template>
	<popup
		:title="$LanguageHelper.translate('_ARCHIVED_')"
		:opened="opened"
		:search="true"
		@mounted="opened=true"
		@searched="searched"
		@close="$emit('close')"
	>

		<f7-list inset>
			<f7-list-item accordion-item v-for="(customer, key) in customers"
				:key="key"
				:title="$FunctionsHelper.specialChars(customer.name)"
				:footer="customer.email"
				>
				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$FunctionsHelper.specialChars(customer.name)}}</div>
						<div class="item">{{customer.email}}</div>
						<div class="item">{{$LanguageHelper.translate('_CUSTOMER_SINCE_')}} {{customer.customer_since}}</div>
					</f7-block>

					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col>
		            <f7-button @click="restore(customer)" raised fill small>{{$LanguageHelper.translate('_RESTORE_')}}</f7-button>
		          </f7-col>

		          <f7-col v-if="customer.id_appuser_status != 9">
		           	<f7-button @click="block(customer)" raised fill small>{{$LanguageHelper.translate('_BLOCK_')}}</f7-button>
		          </f7-col>

		          <f7-col v-if="customer.id_appuser_status == 9">
		            <f7-button @click="unblock(customer)" raised fill small>{{$LanguageHelper.translate('_UNBLOCK_')}}</f7-button>
		          </f7-col>
						</f7-row>

						<f7-row class="marginTop20">
							<f7-col v-if="customer.id_appuser_status != 9">
								<f7-button @click="remove(customer)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup,
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
			customers: []
		};
  },

	methods: {
		searched(val) {
			if(val.length > 0) {
				$AjaxHelper.doRequest('user', 'searchUserForAdmin', {idc: this.idc, search: val, active: 'archived'}, {callback: this._getArchivedUsers})
			} else {
				this.getArchivedUsers();
			}
		},

		remove(customer) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('user', 'deleteCustomer', {idc: this.idc, id_appuser: customer.id_user}, {callback: this._setCustomerStatus})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_DELETE_CUSTOMER_'));
		},

		restore(customer) {
			$ViewHelper.confirm({method: () => {this.setCustomerStatus('remove_from_archive', customer)}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_RETURN_CUSTOMER_TO_LIST_'));
		},

		block(customer) {
			$ViewHelper.confirm({method: () => {this.setCustomerStatus('blocked', customer)}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_BLOCK_CUSTOMER_ACHIVED_'));
		},

		unblock(customer) {
			$ViewHelper.confirm({method: () => {this.setCustomerStatus('unblock', customer)}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_UNBLOCK_CUSTOMER_'));
		},

		setCustomerStatus(status, customer) {
			$AjaxHelper.doRequest('user', 'setAppuserStatus', {idc: this.idc, id_appuser: customer.id_user, action: status}, {callback: this._setCustomerStatus});
		},

		getArchivedUsers() {
			$AjaxHelper.doRequest('user', 'getArchivedUsers', {idc: this.idc}, {callback: this._getArchivedUsers});
		},

		_getArchivedUsers(data) {
			this.customers = [];
			if(data.resultset) {
				this.customers = data.resultset;
			}
		},

		_setCustomerStatus(data) {
			if(data.status == 'OK') this.getArchivedUsers();
		}
	},

	created() {
		this.getArchivedUsers();
	}
};
</script>

<style>
li.green .item-title-row span {color: #4cd964; font-weight: bold;}
li.red .item-title-row span {color: #ff3b30; font-weight: bold;}
</style>
