<template>
	<customer-progress-list :idc="idc" :manage="false" />
</template>

<script>
import CustomerProgressList from '../../components/customer-progress-list.vue';

export default {
  components: {
		'customer-progress-list': CustomerProgressList,
	},

	props: {
		idc: {required: true}
	},
};
</script>
