<template>
	<div v-if="render" class="pageContent">
		<template v-if="connection.paid == 1">
			<template v-if="connection.exists == 1">
				<fixed-top :resized="render" v-show="render">
					<f7-card>
						<f7-card-content v-if="connection.active == 1">
							<div class="marginBottom10" v-if="connection.comments && connection.comments.length > 0" v-html="connection.comments" />

							<i class="icon ionCheckmarkCircle colorGreenOK" /> {{$LanguageHelper.translate('_CONNECTED_WITH_ACCOUNT_')}} {{connection.user.email}} {{$LanguageHelper.translate('_WITH_')}} {{connection.triggers.length}} {{$LanguageHelper.translate('_TRIGGERS_')}}

							<div class="marginTop5">
								<f7-link fill raised @click="disconnect" class="theme link"><b>{{$LanguageHelper.translate('_DISCONNECT_CONNECTION_')}}...</b></f7-link>
							</div>
						</f7-card-content>

						<f7-card-content v-if="connection.active != 1 && !fixingConnection">
							<div class="marginBottom15"><i class="icon ionClose fontRed" /> {{$LanguageHelper.translate('_CONNECTION_NOT_ACTIVE_')}}</div>

							<f7-button fill raised @click="fixingConnection=true">{{$LanguageHelper.translate('_RECONNECT_')}}</f7-button>
						</f7-card-content>
					</f7-card>
				</fixed-top>
			</template>

			<template v-if="connection.exists == 0 || fixingConnection">
				<f7-card>
					<f7-card-header>{{$LanguageHelper.translate('_NO_ACTIVE_CAMPAIGN_ACCOUNT_YET_')}}?</f7-card-header>
					<f7-card-content>
						<f7-button fill raised @click="toPage(signupURL)">{{$LanguageHelper.translate('_CREATE_YOUR_ACTIVE_CAMPAIGN_ACCOUNT_')}}</f7-button>
					</f7-card-content>
				</f7-card>

				<list-form :params="form" @submitted="submitted" />
			</template>

			<f7-list v-if="connection.triggers && connection.exists == 1 && !fixingConnection" inset>
				<list-item v-for="(trigger, key) in connection.triggers" type="accordion" 
					:key="key" 
					:title="trigger.name" 
					:footer="`${trigger.triggerHumanName} -> ${trigger.triggerActionHumanName}`"
					>
					
					<f7-accordion-content>
						<f7-block class="marginTop10 marginBottom10 paddingBottom10">
							<f7-row>
								<f7-col>
			           	<f7-button @click="editTriggerID=trigger.id" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
			          </f7-col>

			          <f7-col>
			            <f7-button @click="removeTrigger(trigger.id)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
			          </f7-col>
							</f7-row>
						</f7-block>
					</f7-accordion-content>
				</list-item>
			</f7-list>

			<!-- New Trigger Popup -->
			<manage-api-connect-trigger v-if="fab.buttons.add.clicked"
				:title="$LanguageHelper.translate('_CREATE_NEW_TRIGGER_')"
				:idc="idc"
				:type="'AC'"
				@changed="fetchConnection"
				@close="fab.buttons.add.clicked=false"
			/>

			<!-- Edit Trigger Popup -->
			<manage-api-connect-trigger v-if="editTriggerID > 0"
				:title="$LanguageHelper.translate('_EDIT_TRIGGER_')"
				:idc="idc"
				:id="editTriggerID"
				:type="'AC'"
				@changed="fetchConnection"
				@close="editTriggerID=null"
			/>
		</template>

		<template v-else>
			<fixed-top :resized="render" v-show="render">
				<f7-card>
					<f7-card-content>
						<div class="marginBottom15" v-html="connection.moduleInfo" />
						<f7-button fill raised @click="toPage(connection.paymentURL)">{{connection.paymentCTA}}</f7-button>
					</f7-card-content>
				</f7-card>
			</fixed-top>

		</template>
	</div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import ListItem from '../../components/smart/list-item.vue';
import ManageApiConnectTrigger from '../../popups/manage-api-connect-trigger.vue';
import FixedTop from '../../components/smart/fixed-top.vue';

export default {
	components: {
		'list-form': ListForm,
		'list-item': ListItem,
		'manage-api-connect-trigger': ManageApiConnectTrigger,
		'fixed-top': FixedTop,
	},

	props: {
		idc: {required: true},
		fab: {type: Object, required: true},
  },

	data() {
    return {
			render: false,
			newApiConnectTriggerOpened: false,
			fixingConnection: false,

			signupURL: '',
			paymentURL: '',

			connection: {},
			editTriggerID: null,

			form: {
				fields: {
					account: {type: 'input', label: '_API_ACCOUNT_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					key: {type: 'input', label: '_API_KEY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		};
  },

	methods: {
		toPage(url) {
			if(url && url.length > 0) $ViewHelper.toBrowser(url, !$Config.app.onDevice ? window.open() : null);
		},

		submitted() {
			$AjaxHelper.doRequest('apiConnect', 'saveConnection', Object.assign({idc: this.idc, type: 'AC'}, JSON.parse(JSON.stringify(this.form.values))), {callback: (data) => {
				if(data.status == 'OK') {
					this.fetchConnection();
				}
			}});
		},

		removeTrigger(id) {
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('apiConnect', 'removeTrigger', {idc: this.idc, id: id, type: 'AC'}, {callback: (data) => {
					if(data.status == 'OK') {
						this.fetchConnection();
					}
				}});
			}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_QUESTION_REMOVE_TRIGGER_'));
		},

		disconnect() {
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('apiConnect', 'removeConnection', {idc: this.idc, type: 'AC'}, {callback: (data) => {
					if(data.status == 'OK') {
						this.fetchConnection();
					}
				}});
			}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_QUESTION_DISCONNECT_API_CONNECTION_'));
		},

		fetchConnection() {
			if(this.connection.triggers) this.connection.triggers = [];
			$AjaxHelper.doRequest('apiConnect', 'getConnection', {idc: this.idc, type: 'AC'}, {callback: (data) => {
				this.signupURL = data.signupURL;
				this.paymentURL = data.paymentURL;

				this.connection = data.connection;

				// Render FAB Add button only if the connection is active
				this.fab.buttons.add.render = this.connection.active == 1;
				this.render = true;
			}});
		}
	},

	created() {
		this.fetchConnection();
	}
};
</script>
