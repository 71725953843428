<template>
	<popup
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<list-form v-if="render" :params="form" @submitted="submitted" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id_customer: {required: true},
		id_progress: {required: false},
	},

  data() {
    return {
			title: $LanguageHelper.translate('_CREATE_NEW_'),
			opened: false,
      render: false,

			form: {
				fields: {
					created_on: {name: 'created_on', type: 'date', label: '_DATE_', placeholder: '_SELECT_DATE_', render: true, value: moment().format('YYYY-MM-DD')},

					general: {type: 'divider', title: '_GENERAL_', render: true},

					weight: {type: 'input', label: '_WEIGHT_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					fatpercentage: {type: 'input', label: '_FAT_PERCENTAGE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					visceral_fat: {type: 'input', label: '_VISCERAL_FAT_LEVEL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					fat_free_mass: {type: 'input', label: '_FAT_FREE_MASS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					muscle_mass: {type: 'input', label: '_MUSCLE_MASS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					bone_mass: {type: 'input', label: '_BONE_MASS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					body_fluids: {type: 'input', label: '_BODY_FLUID_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					blood_glucose: {type: 'input', label: '_BLOOD_GLUCOSE_VALUE_', placeholder: '_ENTER_HERE_', render: true, value: ''},

					measurements: {type: 'divider', title: '_MEASUREMENT_CARD_LEGEND_', render: true},

					chest: {type: 'input', label: '_TOP_WIDTH_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					stomach: {type: 'input', label: '_STOMACH_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					waist: {type: 'input', label: '_WAIST_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					belly: {type: 'input', label: '_BELLY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					hips: {type: 'input', label: '_HIPS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					thighs_left: {type: 'input', label: '_LEFT_THIGH_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					thighs_right: {type: 'input', label: '_RIGHT_THIGH_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					knees_left: {type: 'input', label: '_LEFT_KNEE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					knees_right: {type: 'input', label: '_RIGHT_KNEE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					calves_left: {type: 'input', label: '_LEFT_CALF_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					calves_right: {type: 'input', label: '_RIGHT_CALF_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					arms_left: {type: 'input', label: '_LEFT_ARM_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					arms_right: {type: 'input', label: '_RIGHT_ARM_', placeholder: '_ENTER_HERE_', render: true, value: ''},

					skinfold: {type: 'divider', title: '_SKINFOLD_MEASUREMENT_LEGEND_', render: true},

					php_chin: {type: 'input', label: '_CHIN_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_cheek: {type: 'input', label: '_CHEEK_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_chest: {type: 'input', label: '_CHEST_', placeholder: '_CHEST_', render: true, value: ''},
					php_biceps: {type: 'input', label: '_BICEPS_', placeholder: '_BICEPS_', render: true, value: ''},
					php_triceps: {type: 'input', label: '_TRICEPS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_shoulder_blade: {type: 'input', label: '_SCACAPULA_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_ribs: {type: 'input', label: '_SIDE_RIBS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_hips: {type: 'input', label: '_HIP_BONE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_navel: {type: 'input', label: '_NAVEL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_knee: {type: 'input', label: '_KNEE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_calves: {type: 'input', label: '_CALF_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_quadriceps: {type: 'input', label: '_QUADRICEPS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					php_hamstring: {type: 'input', label: '_HAMSTRING_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
    };
  },

  methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			if(this.id_progress && this.id_progress > 0) data['id_progress'] = this.id_progress;
			$AjaxHelper.doRequest('gym', 'adminSendProgressUser', Object.assign({idc: this.idc, id_appuser: this.id_customer}, data), {callback: (data) => {
				this.$emit('submitted');
				this.opened = false;
			}});
		},
  },

  created() {
		if(this.id_progress && this.id_progress > 0) {
			$AjaxHelper.doRequest('gym', 'getProgressItemUserForAdmin', {idc: this.idc, id_appuser: this.id_customer, id_progress: this.id_progress}, {callback: (data) => {
				this.form.values = data.resultset;
				this.render = true;
			}});
		} else this.render = true;
  }
};
</script>
