import { EventBus } from '../eventBus.js';
import Bowser from 'bowser';

var ViewHelper = {
	fixF7PopupBackdropBug() {
		// F7 has a bug where the overlay gets removed even if there is a popup still open
		if($('.popup.modal-in').length > 0) {
			// Add the backdrop-in class back!
			$('.popup-backdrop').removeClass('backdrop-in').addClass('backdrop-in');
		}
	},

	panel: {
		close() {
			if($f7 && $f7.panel) $f7.panel.close();
		}
	},

	preloader: {
		show() {
			$f7.preloader.show(''); // Empty string te prevent F7 to attach a default color class name to it!
		},

		hide() {
			$f7.preloader.hide();
		},

		isVisible() {
			return $('.preloader').length > 0
		},

		dialog: {
			show(msg) {
				$f7.dialog.preloader(msg);
			},

			hide() {
				$f7.dialog.close();
			}
		}
	},

  alert(text, title, callback) {
    $f7.dialog.alert(text, title, callback);
  },

	confirm(callback, title, question, buttonCancelText, buttonOKText) {
		$f7.dialog.create({
      title: title || $LanguageHelper.translate('_NOTICE_'),
      text: question || $LanguageHelper.translate('_ARE_YOU_SURE_'),
      buttons: [
				{text: buttonCancelText || $LanguageHelper.translate('_CANCEL_'), onClick: function() {}},
				{text: buttonOKText || $LanguageHelper.translate('_YES_'), onClick: function() {callback.method(callback.params ? callback.params : null);}},
			]
    }).open();
	},

	prompt(text, title, callbackOk, callbackCancel, defaultValue) {
		$f7.dialog.prompt(text, title, callbackOk, callbackCancel, defaultValue);
	},

  confirmMultipleButtons(buttons, title, question) {
    $f7.dialog.create({
      title: title || $LanguageHelper.translate('_NOTICE_'),
      text: question || $LanguageHelper.translate('_ARE_YOU_SURE_'),
      buttons: buttons
    }).open();
  },

	popover: {
		close(el) {
			$f7.popover.close(el);
		}
	},

	getParamValueFromPage(param) {
		// Check internal component routing params first
		const currentRouter = $ViewHelper.router.getCurrentRoute();
		if(currentRouter.params) {
			return currentRouter.params[param];
		}

		// Check query params from window href
		const params = $FunctionsHelper.getAllQueryParams(window.location.href);
		if(param && params && params[param]) {
			return params[param];
		}

		//Return all window location params if no name was given
		if(!param && params) {
			return params;
		}

		return null;
	},

	router: {
		nativeHistory: {
			reloadFromPageback: false,
			preventBack: false,

			back(preventBack = false) {
				if($Config.app.onDevice) return;
				
				// If set to true, popstate even will be triggered but it ignores all routing, needed with close button for when we close a popup for example!
				$ViewHelper.router.nativeHistory.preventBack = preventBack;
				
				// Call the native history back method
				history.back();
			},

			addItem() {
				if($Config.app.onDevice) return;

				if (!sessionStorage.getItem('itemsInHistory')) {
					sessionStorage.setItem('itemsInHistory', 0);
				}
				sessionStorage.setItem('itemsInHistory', parseInt(sessionStorage.getItem('itemsInHistory')) + 1);
				history.pushState({ path: 'dummy' }, '', '');
			},
			
			// Everytime the popstate event is called an item is automatically removed but we nee to keep track of the amount of items
			itemRemoved() {
				if($Config.app.onDevice) return;

				if (!sessionStorage.getItem('itemsInHistory')) {
					sessionStorage.setItem('itemsInHistory', 0);
				} else if(sessionStorage.getItem('itemsInHistory') > 0) {
					sessionStorage.setItem('itemsInHistory', parseInt(sessionStorage.getItem('itemsInHistory')) - 1);
				}
			},

			getNumberOfItems() {
				return sessionStorage.getItem('itemsInHistory') ? parseInt(sessionStorage.getItem('itemsInHistory')) : 0;
			},

			resetItems() {
				if($Config.app.onDevice) return;
				
				// Go back in history until there are no more items left, it's the only way (hack) to clear the native browser history
				const numberItems = $ViewHelper.router.nativeHistory.getNumberOfItems();
				for (let i = 0; i < numberItems; i++) {
					$ViewHelper.router.nativeHistory.back();
					$ViewHelper.router.nativeHistory.itemRemoved();
				}
			}
		},

		getRouter() {
			if(window.$f7 && $f7.views && $f7.views.main && $f7.views.main.router) return $f7.views.main.router;

			return null
		},

		getCurrentRoute() {
			const router = $ViewHelper.router.getRouter();
			if(router) return router.currentRoute;

			return {};
		},

		backButtonClicked() {
			// If it's on ios and runs native then exit!
			if($Config.app.onDevice && $Config.app.platform == 'ios') return;
			
			// Check for open popups, close them first before we navigate back pages!
			const popups = $('.popup');

			if(popups && popups.length > 0) {
				// Get the popup to close from the stack
				let toClose = $(popups.toArray().reverse().shift());

				// Vue component popup? Emit close event so component can close it
				if(toClose.hasClass('vuePopup')) return EventBus.$emit('popup-close', toClose.attr('id'));

				// Close the extra login popup here
				if(!$Config.app.onDevice) {
					// Set callback param to true so the _extraLoginClosedByUser is called to emit the close event for native history to keep working!
					$ViewHelper.popup.close(toClose.attr('id').replace('popup_', ''), true); 
				} else {
					$ViewHelper.popup.close(toClose.attr('id').replace('popup_', ''));
				}
			}

			// Go back one page or exit the app!
			const currentRoute = $ViewHelper.router.getCurrentRoute();

			if(currentRoute) {
				// Exit app if were on the home page!
				if($Config.onDevice) {
					if(navigator.app && (currentRoute.name == 'home' || currentRoute.name == 'LoginNewAccount')) {
						return navigator.app.exitApp();
					}
				}

				// Go one page back
				return $ViewHelper.router.getRouter().back();
			}
		},

		navigate(path, options) {
			const interval = setInterval(() => {
				const router = $ViewHelper.router.getRouter();
				// Wait until F7 is mounted
				if(router) {
					clearInterval(interval);
					const optionsMerged = Object.assign({ ignoreCache: true }, options);
					router.navigate(path, optionsMerged);
				}
			}, 1);
		},

		refreshPage() {
			const router = $ViewHelper.router.getRouter();
			if(router) {
				router.refreshPage();
			}
		},

		getParams() {
			const currentRoute = $ViewHelper.router.getCurrentRoute();
			if(currentRoute) {
				return currentRoute.params;
			}

			return {};
		},
	},

	reloadWindow(replaceParams) {
		var pieces = window.location.toString().split('?');
		var mainUrl = pieces[0];

		var urlObj = new URL(window.location);
		var params = new URLSearchParams(urlObj.search);

		if(replaceParams && pieces.length > 1) {
			Object.keys(replaceParams).map(function(objectKey, index) {
				if(params.has(objectKey)) {
					params.set(objectKey, replaceParams[objectKey]);
				}
			});

			// Go to current location with replaced param values
			return window.location.href = mainUrl + '?' + params.toString();
		}

		// Just go to current window
		return window.location.href = mainUrl;
	},

	toWindow(url) {
		window.location.href = url;
	},

	scrollTo(topVal) {
    $('.page-content', $('.page-current')).scrollTop(topVal);
  },

	toBrowser(url, newWindow /* Should be new window.open() object */, hideLocation) {
		// Use cordova in app browser
	  if($Config.app.onDevice) {
			return cordova.InAppBrowser.open(url, '_system', 'location=' + (hideLocation ? 'no' : 'yes'));
		}

		// Popup blocker workaround, the window object we use should be created right after the event triggers, we later change it's location
		if(newWindow) return newWindow.location.replace(url);

		// Use parent window if it's here
		if(window.parent) return window.parent.location = url;

		// Regular same window redirect
		return window.location = url;
	},

	isApple() {
		const systemInfo = Bowser.parse(window.navigator.userAgent);
		return (systemInfo.browser.name.toLowerCase() == 'safari' || systemInfo.os.name.toLowerCase() == 'ios');
	},

	isIos() {
		const systemInfo = Bowser.parse(window.navigator.userAgent);
		return systemInfo.os.name.toLowerCase() == 'ios';
	},

	detectTheme() {
		return (this.isApple() ? 'ios' : 'md')
	},

	popup: {
		callback: null,

		removeMeFromNativeHistoryStack() {
			if(!$Config.app.onDevice) {
				// Remove from native history stack without doing any routing
				$ViewHelper.router.nativeHistory.back(true /* Prevents routing */);
				
				// A popup close does not trigger the page:afterin event, so we need to reset the reloadFromPageback manually to false
				$ViewHelper.router.nativeHistory.reloadFromPageback = false;
			}
		},

		close(id, runCallback = false, removeMeFromNativeHistoryStack = false) {
			const popup = $f7.popup.get(`#${id}`);
			popup.close();

			// F7 has a bug where the overlay gets removed even if there is a popup still open
			$ViewHelper.fixF7PopupBackdropBug();

			// Run callback function when close button was clicked by user
			if(this.callback && runCallback) this.callback();
			
			// Stuff we need to do to keep the native history stack in check for the back button bahaviour
			if(removeMeFromNativeHistoryStack) $ViewHelper.popup.removeMeFromNativeHistoryStack();
		},

		loginWithEnter(e) {
			if(e && e.code && e.code.length > 0 && e.code.toLowerCase() == 'enter') {
				$AjaxHelper.sendExtraLogin(document.getElementById('password').value);
			}

			return false;
		},

		showExtraLogin(callback) {
			this.callback = callback;

			// Default close button for when we live on a device±
			let closeButton = `<a href="#" class="link" onClick="$ViewHelper.popup.close('popupExtralogin', true, true)">${$LanguageHelper.translate('_CLOSE_')}</a>`;

			// We need to add an extra item here to the native window stack in order to maintain correct page history!
			if(!$Config.app.onDevice) {
				$ViewHelper.router.nativeHistory.addItem();
			}

			const output = `
			<div class="popup" id="popupExtralogin">
				<div class="view">
					<div class="page">
						<div class="navbar">
							<div class="navbar-inner sliding">
								<div class="title">${$LanguageHelper.translate('_LOGIN_')}</div>
								<div class="right">
									${closeButton}
								</div>
								</div>
							</div>

							<div class="page-content">
								<div class="listForm">
									<div class="list inset">
										<ul>
											<li>
												<div class="item-content item-input">
													<div class="item-inner">
														<div class="item-title item-label">${$LanguageHelper.translate('_LOGIN_')}</div>
														<div class="item-input-wrap">
															<form onsubmit="return false;"><input type="password" id="password" placeholder="${$LanguageHelper.translate('_PASSWORD_')}" autocomplete="false" onkeyup="$ViewHelper.popup.loginWithEnter(event)"></form>
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>

									<div class="block">
										<a href="#" class="button button-fill button-raised" onclick="$AjaxHelper.sendExtraLogin(document.getElementById('password').value)">${$LanguageHelper.translate('_LOGIN_')}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>`;

			$f7.popup.open(output);
		},
	},
}

export default ViewHelper;
