<template>
	<page :title="$LanguageHelper.translate('_MY_ACCOUNT_')" @initiated="render=true">
		<account-form v-if="render" />
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import AccountForm from '../components/account-form.vue';

export default {
	components: {
    'page': Page,
    'account-form': AccountForm,
	},

	data() {
    return {
      render: false,
    };
  },
};
</script>
