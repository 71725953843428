<template>
	<f7-list media-list inset v-if="render">
		<f7-list-item accordion-item v-for="(history, key) in history.list"
			:key="key"
			:title="`${history.info[0].label} ${history.info[0].value}`"
			>

			<f7-accordion-content>
	      <f7-block class="marginBottom10">
					<div v-for="(editInfo, key) in history.editInfo" :key="key" class="item">{{editInfo.label}}{{editInfo.value && editInfo.value.length > 0 ? `: ${editInfo.value}` : ''}}</div>
					<div v-for="(edit, key) in history.edit" :key="key" class="item">{{edit.label}}: {{edit.value}}</div>
				</f7-block>
    	</f7-accordion-content>
		</f7-list-item>
	</f7-list>
</template>

<script>
export default {
	props: {
		idc: {required: true},
		id: {required: true},
	},

	data() {
		return {
			render: false,
			history: {
				list: []
			},
		}
	},

	methods: {
		storeHistory(data) {
			$.each(data, (index, sale) => {
				let item = {};

				item['info'] = [];
				$.each(sale.info, (indexInfo, saleInfo) => {
					if(!isNaN(Date.parse(saleInfo.value))) saleInfo.value = $LanguageHelper.formatDate(saleInfo.value);
					item['info'].push({label: saleInfo.label, value: saleInfo.value});
        });

				item['edit'] = [];
				$.each(sale.edit, (indexEdit, saleEdit) => {
					if(!isNaN(Date.parse(saleEdit.value))) saleEdit.value = $LanguageHelper.formatDate(saleEdit.value);
					item['edit'].push({label: saleEdit.label, value: saleEdit.value});
        });

				item['editInfo'] = [];
				$.each(sale.editInfo, (indexEditInfo, editInfo) => {
					if(!isNaN(Date.parse(editInfo.value))) editInfo.value = $LanguageHelper.formatDate(editInfo.value);
					item['editInfo'].push({label: editInfo.label, value: editInfo.value});
				});

				this.history.list.push(item);
			});
		},

		load() {
			this.history.list = [];
			$AjaxHelper.doRequest('payment', 'getSubscriptionHistory', {idc: this.idc, id: this.id}, {callback: this._history});
		},

		_history(data) {
			if(data.resultset.history && data.resultset.history.length > 0) this.storeHistory(data.resultset.history);
			this.render = true;
		},
	},

	created() {
		this.load();
	}
};
</script>
