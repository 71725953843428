<template>
	<f7-list media-list inset v-if="render">
		<f7-list-item accordion-item v-for="(item, key) in installments.list"
			:key="key"
			:title="`${$LanguageHelper.translate('_PERIOD_')} ${item.installment.number_installment}, ${item.installment.date_created}`"
			:after="`€ ${item.installment.amount}`"
			:subtitle="item.installment.status"
			>

			<f7-accordion-content>
	      <f7-block>
					<div v-for="(invoice, key) in item.invoices" :key="key" class="marginBottom10">
						<div class="item fontBold">{{invoice.type}}</div>
						<div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: <a @click="downloadInvoice(invoice.id_invoice)" :title="$LanguageHelper.translate('_DOWNLOAD_INVOICE_')" class="link theme">{{invoice.invoice_number}}</a></div>
						<div class="item">{{$LanguageHelper.translate('_MADE_ON_')}}: {{invoice.date_created}}</div>
						<div class="item" v-if="invoice.unique_id && invoice.unique_id.length > 0">{{$LanguageHelper.translate('_REFERENCE_')}}: {{invoice.unique_id}}</div>
						<div class="item">{{$LanguageHelper.translate('_STATUS_')}}: {{$FunctionsHelper.lcFirst(invoice.status)}}</div>
						<div class="item" v-if="invoice.failed_description && invoice.failed_description.length > 0">{{$LanguageHelper.translate('_REASON_')}}: {{invoice.failed_description}}</div>
					</div>
				</f7-block>

				<f7-block v-if="$UserHelper.hasAccess('payments', 'refund', idc) && item.installment.refund_allowed == 1 && item.invoices[0]" class="marginBottom10">
					<f7-row>
						<f7-col>
							<f7-button @click="refundInstallment(item.invoices[0].id)" raised fill small>{{$LanguageHelper.translate('_REFUND_PERIOD_')}}</f7-button>
						</f7-col>
					</f7-row>
				</f7-block>
    	</f7-accordion-content>
		</f7-list-item>
	</f7-list>
</template>

<script>
export default {
	props: {
		idc: {required: true},
		id: {required: true},
	},

	data() {
		return {
			render: false,
			installments: {
				list: []
			},
		}
	},

	methods: {
		load() {
			this.installments.list = [];
			$AjaxHelper.doRequest('payment', 'getSubscriptionInstallments', {idc: this.idc, id: this.id}, {callback: this._installments});
		},

		refundInstallment(id) {
			let data = {
				idc: this.idc,
				refund: 1
			};

			data[`refund_${id}`] = 1;
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequestExtraLogin('payment', 'refundSale', data, {callback: this._refundSaved})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		downloadInvoice(id) {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoice&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&id=${id}&language=${$LanguageHelper.current}`);
		},

		_installments(data) {
			this.installments.list = data.resultset;
			this.render = true;
		},

		_refundSaved(data) {
			if(data.status == 'OK') this.load();
		},
	},

	created() {
		this.load();
	}
};
</script>
