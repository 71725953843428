import { EventBus } from '../eventBus.js';

export default {
	data() {
		return {
			numberNotifications: localStorage.getItem('numberNotifications')
		}
	},

	created() {
		// Update unread notifications when we receive the event
		EventBus.$on('notifications', numberNotifications => {
			this.numberNotifications = numberNotifications;
			localStorage.setItem('numberNotifications', numberNotifications);
		});
	}
};
