<template>
  <page
		:title="$LanguageHelper.translate('_ACTIVECAMPAIGN_CONNECTION_')"
		:toolbar="toolbar"
	>
		<template #info v-if="toolbar.tabs.info && toolbar.tabs.info.active">
			<tab-info :idc="idc" :tabs="toolbar.tabs" />
		</template>

		<template #settings v-if="toolbar.tabs.settings && toolbar.tabs.settings.active">
			<tab-settings :idc="idc" :fab="fab.settings" />
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabInfo from './tab-info.vue';
import TabSettings from './tab-settings.vue';

export default {
  components: {
    'page': Page,
    'tab-info': TabInfo,
    'tab-settings': TabSettings,
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			fab: {
				settings: {
					position: 'right-bottom',
					buttons: {
						add: {name:'add', label: $LanguageHelper.translate('_CREATE_NEW_TRIGGER_'), icon: 'icon ionAdd', render: false, clicked: false},
					}
				}
			},

			toolbar: {
				tabs: {
					info: {text: $LanguageHelper.translate('_INFO_'), icon: 'icon ionInformationCircle', render: true, active: false},
					settings: {text: $LanguageHelper.translate('_SETTINGS_'), icon: 'icon ionSettings', render: true, active: false},
				}
			}
    };
  },

	created() {
		$AjaxHelper.doRequest('apiConnect', 'isConnected', {idc: this.idc, type: 'AC'}, {callback: (data) => {
			if(data.status == 'OK') {
				this.toolbar.tabs.settings.fab = this.fab.settings;

				if(data.isConnected == 0) this.toolbar.tabs.info.active = true;
				else this.toolbar.tabs.settings.active = true;
			}
		}});
	}
};
</script>
