<template v-if="render">
	<div v-if="this.$Config.apps[this.$Config.app.currentApp].logoCompany" class="topImage home"><img :src="`./static/img/${$Config.app.currentApp}/logo-home.png`"></div>
	<f7-list media-list inset>
		<f7-list-item v-for="(manageCompany) in manageCompanies" :key="manageCompany.idc" :link="toUrlManageCompany(manageCompany)" :title="$FunctionsHelper.specialChars(manageCompany.name)" :subtitle="$LanguageHelper.translate('_MANAGEMENT_')">
			<template #media><f7-icon icon="ionCog marginTop5"></f7-icon></template>
		</f7-list-item>
	</f7-list>

	<f7-list inset>
		<f7-list-item link="/find-company/" :title="$LanguageHelper.translate('_SEARCH_YOUR_COMPANY_')">
			<template #media><f7-icon icon="ionSearch marginTop5"></f7-icon></template>
		</f7-list-item>
	</f7-list>

	<f7-list v-if="render" media-list inset>
		<f7-list-item v-for="(company) in companies" :key="company.id_gym" :link="toUrlCompany(company)" :title="$FunctionsHelper.specialChars(company.name)" :subtitle="$FunctionsHelper.specialChars(company.city)">
			<template #media><img :src="`./static/img/${$Config.app.currentApp}/logo-list.png`" width="44" height="44" /></template>
		</f7-list-item>
	</f7-list>
</template>

<script>
export default {
	props: {
		pageData: {},
  },

  data() {
    return {
			render: false,
      companies: [],
			manageCompanies: [],
    };
  },

  methods: {
    toUrlCompany(company) {
      return `/company/idc/${company.id_gym}/`;
    },

		toUrlManageCompany(manageCompany) {
      return `/manage-company/idc/${manageCompany.idc}/`;
    },
  },

	created() {
		this.manageCompanies = $UserHelper.accesslevels.getCompaniesUserManages();
		if(this.pageData) {
			// Redirect user to his start page on first load, only if he has no access levels!
			if($PageVisits == 1 && this.pageData.settings_user.settings.id_gym > 0 && this.manageCompanies.length == 0) {
				return $ViewHelper.router.navigate(`/company/idc/${this.pageData.settings_user.settings.id_gym }`, {animate: false});
			}

			this.companies = this.pageData.companies;
			this.render = true;
		}
	}
};
</script>
