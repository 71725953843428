<template>
  <page class="customerShop"
		:idc="idc"
		:dynamic-navbar-component="shoppingCartNavbar"
		@dynamic-navbar-component-changed="shoppingCartOpened=true"
		@initiated="go"
		>

		<div v-if="render">
	    <f7-block v-if="selectedFilters">
	       <f7-link @click="shopFiltersOpened=true" class="marginRight10"><f7-icon class="icon ionFunnel themeButton" /></f7-link>
	       <f7-chip class="marginBottom10" v-if="selectedFilters.productOption" :text="selectedFilters.productOption" />
	       <f7-chip class="marginBottom10" v-if="selectedFilters.sortOption" :text="selectedFilters.sortOption" />
	     </f7-block>

	     <div v-if="hasCategories">
	       <div v-for="(items, key1) in items" :key="`A-${key1}`" class="cardCenter">
	         <f7-block-title class="theme">{{key1}}</f7-block-title>
	         <f7-block>
	           <f7-row v-for="(item, key2) in items" :key="`B-${key2}`">
	             <f7-col v-for="(product, key3) in item" :key="`C-${key3}`">
	               <f7-card class="widthShopCard">
	                 <f7-card-content :padding="false">
	                  <div class="image" :style="{ 'background-image': 'url(' + product.imageURL + ')' }" @click="itemClicked(product.id_invoice_product)"></div>
	                   <div class="information" @click="itemClicked(product.id_invoice_product)">
	                     <div class="textOverflow" v-html="product.name"></div>
	                     <div class="theme"><b>€ {{product.amount_in_vat}}</b></div>
	                   </div>
	                 </f7-card-content>
	               </f7-card>
	             </f7-col>
	             <f7-col v-if="item.length < 2" :key="`D-${key2}`"></f7-col>
	           </f7-row>
	         </f7-block>
	       </div>
	     </div>

	     <div v-else>
	       <div class="cardCenter">
	         <f7-block class="noMarginTop">
	           <f7-row v-for="(items, key4) in items" :key="`E-${key4}`">
	               <f7-col v-for="(item, key5) in items" :key="`F-${key5}`">
	                 <f7-card class="widthShopCard">
	                   <f7-card-content :padding="false">
	                       <div class="image" :style="{ 'background-image': 'url(' + item.imageURL + ')' }" @click="itemClicked(item.id_invoice_product)"></div>
	                     		<div class="information" @click="itemClicked(item.id_invoice_product)">
	                       <div class="textOverflow" v-html="item.name"></div>
	                       <div class="theme"><b>€ {{item.amount_in_vat}}</b></div>
	                     </div>
	                   </f7-card-content>
	                 </f7-card>
	               </f7-col>
	               <f7-col v-if="items.length < 2" :key="`G-${key4}`"></f7-col>
	           </f7-row>
	         </f7-block>
	       </div>
	     </div>
   	</div>

		<filter-shop v-if="shopFiltersOpened"
		  :filters="filters"
			@submitted="filterSubmitted"
			@close="shopFiltersOpened=false"
		/>

		<product-information v-if="productInfoOpened"
		  :idc="idc"
		  :id="productID"
		  @shoppingCartChanged="renderShoppingCartNavbar"
			@close="productInfoOpened=false"
		/>

		<shopping-cart v-if="shoppingCartOpened"
		  :idc="idc"
			@changed="renderShoppingCartNavbar"
		  @close="shoppingCartOpened=false"
		/>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import FilterShop from '../popups/filter-shop.vue';
import ProductInformation from '../popups/product-information.vue';
import ShoppingCart from '../popups/shopping-cart.vue';

export default {
  components: {
    'page': Page,
    'product-information': ProductInformation,
    'shopping-cart': ShoppingCart, // Opens if navbar item is clicked
    'filter-shop': FilterShop,
  },

  props: {
		idc: { required: true },
  },

  data() {
    return {
      render: false,
      productInfoOpened: false,
      shoppingCartOpened: false,
      shopFiltersOpened: false,
			hasCategories: false,
			productID: null, // ID of currently opened product
			items: [], // Product items

      // Navbar component, clickable shopping cart item
      shoppingCartNavbar: {
        name: 'shopping-cart-navbar',
        params: {
          price: '',
          numberItems: 0,
        }
      },

			// Filters
			filters: {}, // Received form back-end
			selectedFilters: {}, // Used for showing selected filter chips
    };
  },

	watch: {
		render(val) {
			if(val) {
				setTimeout(() => this.resize(), 10)
				$(window).resize(() => {
					this.resize();
				});
			}
		}
	},

  methods: {
		resize() {
			let el = $('.image');
			el.height(el.width());
		},

		itemClicked(id) {
      this.productID = id;
      this.productInfoOpened = true;
    },

		filterSubmitted(filters) {
			this.getItems(filters);
		},

		renderShoppingCartNavbar(data) {
			// Store the items so navbar gets updated
      if(!data.total_amount_in_vat || data.total_amount_in_vat == 0) data.total_amount_in_vat = '0.00';
      this.shoppingCartNavbar.params.price = data.total_amount_in_vat;
      this.shoppingCartNavbar.params.numberItems = data.number_items;
    },

    storeItemImageURL(data) {
			return !data.imageURL || data.imageURL.length == 0 ? '../../../static/img/no-image.png' : data.imageURL;
  	},

  	storeItems(data) {
  		let products = [];
			let cnt = 0;
  		let cntRows = 0;
  		let productsInRow = [];

  		if(data && data.resultset) {
        $.each(data.resultset, (index, product) => {
  				// Categories
  				if(product.length > 0 && typeof index == 'string') {
  					let length = product.length;
  					products[index] = [];

            product.forEach((item, index2) => {
  						cnt ++;
  						item.imageURL = this.storeItemImageURL(item);

  						// Collect row with 2 products
  						productsInRow.push(item);

  						cntRows ++;
  						if(cntRows == 2 || product.length == cnt) {
  							products[index].push(productsInRow);
  							productsInRow = [];
  							cntRows = 0;
  							if(product.length == cnt) cnt = 0;
  						}
  					});
  				}

					// No categories
					else {
  					cnt ++;
  					product.imageURL = this.storeItemImageURL(product);

  					//Collect row with 2 products
  					productsInRow.push(product);

  					cntRows ++;
  					if(cntRows == 2 || data.resultset.length == cnt) {
  						products.push(productsInRow);
  						productsInRow = [];
  						cntRows = 0;
  					}
  				}
  			});
  		}

  		this.items = Object.assign({}, products);
  	},

    sortFilters(data) {
      if(data.product_options) {
        data.product_options.options.forEach( (obj, index) => {
          if(obj.selected == 1) this.selectedFilters.productOption = obj.name;
        });
      }

      data.sort_options.options.forEach((obj, index) => {
        if(obj.selected == 1 && obj.systemName != 'none') this.selectedFilters.sortOption = obj.name;
      });

      // Set filters for filter popup
      this.filters = data;
    },

    getItems(filters) {
			$AjaxHelper.doRequest('invoiceProduct', 'productItemsShop', Object.assign({idc: this.idc, id_appuser: $UserHelper.getID(), userList: 1, shoppingCart: 1, category: 1}, filters), {callback: this._initShop});
    },

    _initShop(data) {
      if(data.status == 'OK') {
				this.items = [];

				this.hasCategories = data.category;

				// Store the products in correct structure with or without categories
				this.storeItems(data);

				// Set filters
        if(data.filters && data.filters.customConditions) this.sortFilters(data.filters.customConditions);

        // Store navbar shopping cart
        if(data.shoppingCart) this.renderShoppingCartNavbar(data.shoppingCart);

				// Render the page
        this.render = true;
      }
    },

		go() {
			this.getItems();
		}
  }
};
</script>

<style>
.information {
  margin-left: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
}

.customerShop .navbar .shoppingcart, .ios .navbar .shoppingcart {
  text-align: center;
  width: 100%;
}
</style>

<style scoped>
.widthShopCard {
  max-width:341px;
}

.information {cursor: pointer;}

.image {
  border: 1px solid #e7e7e7;
  height: 342px;
  object-fit: contain;
  cursor: pointer;
  background-size: cover;
	background-position: center;
}

.info {
  text-align: left;
  clear: both;
  margin: 5px;
  max-width: 340px;
}

.card-header:after {background-color: #fff;}

.card-footer:before {background-color: #fff;}

.theme {color: var(--theme-color);}

.themeButton {color: var(--btn-bg-color);}

.block:first-child {
  margin-top: 20px;
  margin-bottom: 0px;
}

.block-title:first-child {margin-top: 10px;}
</style>
