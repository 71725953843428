<template>
	<popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		title: {type: String, required: true},
  },

	data() {
		return {
			opened: false,

			form: {
				fields: {
					email: {type: 'input', label: '_YOUR_EMAIL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SEND_DATA_', render: true},
				values: {},
			}
		}
	},

	methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			$AjaxHelper.doRequest('user', 'lostAccount', data, {callback: this._forgotPass});
		},

		_forgotPass(data) {
			// Close popup after were done...
			if(data.status == 'OK') {
				// Setting opened to false will trigger the popup close event so this component will be destroyed!
				this.opened = false;
			}
		}
	}
};
</script>
