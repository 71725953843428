<template>
	<f7-list class="languageSelect languageSelectRightCorner">
		<f7-list-item :title="$LanguageHelper.translate('_LANGUAGE_')" smart-select :smart-select-params="{openIn: 'popover', closeOnSelect: true}">
			<language-select-options />
		</f7-list-item>
	</f7-list>
</template>

<script>
import LanguageSelectOptions from '../components/language-select-options.vue';

export default {
  components: {
		'language-select-options': LanguageSelectOptions
  }
};
</script>

<style>
.languageSelectRightCorner {
	width: 150px;
	position: absolute;
	right:0;
	top: calc(-35px + var(--f7-safe-area-top));
	background: var(--navbar-bg-color);
}

.languageSelectRightCorner.list ul, .languageSelectRightCorner.list ul::before, .languageSelectRightCorner.list ul::after {
	background: var(--navbar-bg-color)!important;
}

.languageSelectRightCorner .item-title {
	display: none;
}

.languageSelectRightCorner .item-after {
	bottom: 4px;
	color: var(--navbar-color);
}
.languageSelectRightCorner .item-inner {
	height:20px!important;
	padding-bottom:0;
}

.languageSelectRightCorner .item-inner::before {
	color: var(--navbar-color)!important;
	margin-top:-6px!important;
}

.ios .languageSelectRightCorner .item-inner::before {
	margin-top:-7px!important;
}
</style>
