<template>
  <page :title="$LanguageHelper.translate('_MESSAGES_')" @initiated="getMessages">
		<f7-list media-list inset v-if="render">
			<list-item v-for="(message, key) in messages" type="media" class="clickable"
				:key="key"  
				:title="message.name_sender"
				:after="message.created_on"
				:subtitle="message.subject"
				:titleClass="message.read == 0 ? 'bold' : ''"
				:afterClass="message.read == 0 ? 'bold' : ''"
				@click="messageClicked(message.id)">
      </list-item>
		</f7-list>

    <!-- Message popup -->
    <message v-if="messageOpened" :id="messageID" :close="close" @removed="removed" @close="closeMessage()" />
  </page>
</template>

<script>
import { EventBus } from '../js/eventBus';
import Page from '../components/smart/page.vue';
import ListItem from '../components/smart/list-item.vue';
import Message from '../popups/message.vue';

export default {
  components: {
    'page': Page,
    'list-item': ListItem,
    'message': Message,
  },

	props: {
		idm: {required: false},
	},

  data() {
    return {
      render: false,
      close: false,
      messageOpened: false,
      messageID: null,
      messageNextID: null,
      messages: [],
    }
  },

  methods: {
    messageClicked(id) {
			// Reset the message next id
			this.messageNextID = null;

			// Set the new message id and open the popup
			this.messageID = id;
      this.messageOpened = true;
    },

    closeMessage() {
			this.messageOpened = false;
			this.close = false;
			$.each(this.messages, (index, message) => {
        if(message.id == this.messageID) {
					message.read = 1;
				}
      });

			// Open the next message if set!
			if(this.messageNextID > 0) this.getMessages()
    },

    removed() {
      this.messages = [];
      this.getMessages();
    },

    getMessages() {
			$AjaxHelper.doRequest('pushMessage', 'getMessages', {}, {callback: (data) => {
				this.messages = data.resultset;
        this.render = true;

				// Notification came in, open message here!
				if(this.messageNextID) this.messageClicked(this.messageNextID);
			}});
    }
  },

	created() {
		// If the id message is set in route then open the message!
		if(this.idm > 0) {
			this.messageNextID = this.idm;
			this.getMessages();
		}

		// Listen for notifications that comes in!
		EventBus.$off('push-notification');
		EventBus.$on('push-notification', (idm) => {
			// Set the id for the next message to be fetched
			this.messageNextID = idm;

			// Empty messages list
			this.messages = [];

			// Close current open message if there one and set the id of message to be opened next!
			if(this.messageOpened) {
				// Close the popup, the close function will automaticly fetch the new list
				this.close = true;
			} else {
				this.getMessages();
			}
		});
	}
};
</script>

<style>
.messages .bold {font-weight: bold!important;}
</style>
