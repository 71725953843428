<template>
	<div v-if="visible" class="pageContent">
		<f7-card>
		  <f7-card-content>
				<f7-list class="marginTop5">
					<template v-if="outOfSync" class="comments">
						<div class="marginLeft15 marginBottom10">
							<i class="icon ionWarning red" />
							<span class="fontSize14">{{$LanguageHelper.translate('_SYNC_MESSAGE_')}}</span>
						</div>
						<f7-list-item>
							<f7-button @click="openSync" raised fill small class="button-small orange action">{{$LanguageHelper.translate('_SYNC_BTN_TEXT_')}}</f7-button>
						</f7-list-item>
					</template>

					<template v-if="customer.id_appuser_status == 2">
						<div class="marginLeft15 marginBottom10">
							<i class="icon ionWarning red" />
							<span class="fontSize14">{{$LanguageHelper.translate('_ACCEPT_WAITING_CUSTOMER_')}}</span>
						</div>
						<f7-list-item>
							<f7-button @click="waitingCustomerButtonClicked" raised fill button-small class="button-small orange action">{{$LanguageHelper.translate('_CHOSE_AN_OPTION_')}}</f7-button>
						</f7-list-item>
					</template>

					<template v-if="customer.id_appuser_status == 4 || customer.id_appuser_status == 1 || customer.id_appuser_status == 7" class="comments">
						<template v-if="customer.date_last_invitation_send.length > 0">
							<div class="marginLeft15 marginBottom5">
								<i class="icon ionPaperPlane grey" />
								<span class="fontSize14">{{$LanguageHelper.translate('_INVITED_ON_')}} {{customer.date_last_invitation_send}}</span>
							</div>
						</template>

						<f7-list-item>
							<f7-button @click="sendCustomerInviteButtonClicked" raised fill button-small class="button-small orange action">{{$LanguageHelper.translate('_SEND_INVITE_')}}</f7-button>
						</f7-list-item>
					</template>
				</f7-list>

				<f7-row no-gap class="marginTop10">
					<f7-col>
						<div>
							<img v-if="customer.imageURL" :src="customer.imageURL" class="customerImage" />
							<f7-icon v-else class="icon ionPerson"></f7-icon>
						</div>
						<div class="marginTop14">
							<div>{{$FunctionsHelper.specialChars(customer.name)}}</div>
							<div v-if="customer.birthdate.length > 0">{{customer.birthdate}}</div>
							<div><a :href="`mailto:${customer.email}`" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{customer.email}}</a></div>
						</div>
					</f7-col>
				</f7-row>

				<f7-row no-gap>
					<f7-col>
						<div v-if="customer.street.length > 0">{{$FunctionsHelper.specialChars(customer.street)}} {{$FunctionsHelper.specialChars(customer.housenumber)}}{{customer.housenumber_extra.length > 0 ? ' ' + $FunctionsHelper.specialChars(customer.housenumber_extra) : ''}}</div>
						<div v-if="customer.zipcode.length > 0">{{customer.zipcode}} {{customer.city}} {{customer.country}}</div>
						<div v-if="customer.mobile.length > 0"><a :href="`tel://${customer.mobile}`" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{customer.mobile}}</a></div>
						<div v-if="customer.phone.length > 0"><a :href="`tel://${customer.phone}`" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{customer.phone}}</a></div>

						<div v-if="customer.ice_phone.length > 0" class="marginTop5">
							<b>{{$LanguageHelper.translate('_ICE_')}}</b>
							<div v-if="customer.ice_name.length > 0">{{customer.ice_name}}</div>
							<a :href="`tel://${customer.ice_phone}`" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{customer.ice_phone}}</a>
						</div>

						<div v-if="customer.ice2_phone.length > 0" class="marginTop5">
							<b>{{$LanguageHelper.translate('_ICE2_')}}</b>
							<div v-if="customer.ice2_name.length > 0">{{customer.ice2_name}}</div>
							<a :href="`tel://${customer.ice2_phone}`" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{customer.ice2_phone}}</a>
						</div>
					</f7-col>
				</f7-row>

				<f7-row v-if="customer.partners" no-gap class="marginTop10">
					<f7-col>
						<div><b>{{$LanguageHelper.translate('_FAMILY_MEMBERS_')}}</b></div>
						<div v-for="(partner, index) in customer.partners" :key="index">{{$FunctionsHelper.specialChars(partner.firstname)}} {{$FunctionsHelper.specialChars(partner.lastname)}}</div>
					</f7-col>
				</f7-row>

				<f7-row no-gap class="marginTop10">
					<f7-col class="info">
						<div v-if="customer.id_appuser_status == 5" class="comments"><i class="icon ionInformationCircle red" /> {{$LanguageHelper.translate('_CUSTOMER_IS_BLOCKED')}}</div>
						<div v-if="customer.comments.length > 0" class="comments"><i class="icon ionInformationCircle orange" /> {{$FunctionsHelper.specialChars(customer.comments)}}</div>
						<div v-if="customer.temp_comment.length > 0" class="comments"><i class="icon ionInformationCircle orange" /> {{$FunctionsHelper.specialChars(customer.temp_comment)}}</div>
					</f7-col>
				</f7-row>

				<f7-row v-if="reporting" no-gap class="marginTop10">
					<f7-col class="info">
						<f7-link @click="openReporting=true" class="theme">{{$LanguageHelper.translate('_SHOW_REPORTING_')}}</f7-link>
					</f7-col>
				</f7-row>

				<f7-row v-if="progress" no-gap class="marginTop10">
					<f7-col class="info">
						<f7-link @click="openProgress=true" class="theme">{{$LanguageHelper.translate('_VIEW_PROGRESS_')}}</f7-link>
					</f7-col>
				</f7-row>
			</f7-card-content>

			<f7-card-footer class="footer">
				<div class="footerContent">
					<a v-if="customer.mobile.length > 0" :href="`tel://${customer.mobile}`" class="external" :target="$Config.app.onDevice ? '_system' : '_blank'"><i class="icon ionCall" /></a>
					<a v-if="customer.whatsapp.length > 0" :href="`${whatsappURL}${customer.whatsapp}`" class="external" :target="$Config.app.onDevice ? '_system' : '_blank'"><i class="icon ionWhatsapp" /></a>
					<a v-if="customer.mobile.length > 0" :href="`sms://${customer.mobile}`" class="external" :target="$Config.app.onDevice ? '_system' : '_blank'"><i class="icon ionText" /></a>
					<a v-if="customer.email.length > 0" :href="`mailto:${customer.email}`" class="external" :target="$Config.app.onDevice ? '_system' : '_blank'"><i class="icon ionMail" /></a>
				</div>
			</f7-card-footer>
		</f7-card>

		<!-- Load if customer edit FAB is clicked -->
		<manage-customer-edit v-if="openEdit"
			:title="customer.name"
			:idc="idc"
			:id_customer="id_customer"
			@edited="loadEditedCustomer"
			@close="editedClosed"
		/>

		<!-- Load if customer sync FAB is clicked -->
		<manage-customer-sync v-if="fab.buttons.sync.clicked"
			:title="customer.name"
			:idc="idc"
			:id_customer="id_customer"
			@synced="loadEditedCustomer"
			@close="fab.buttons.sync.clicked=false"
		/>

		<!-- Load if customer reporting is clicked-->
		<manage-customer-reporting v-if="openReporting"
			:title="customer.name"
			:idc="idc"
			:id_customer="id_customer"
			@edited="loadEditedCustomer"
			@close="openReporting=false"
		/>

		<!-- Load if customer progress is clicked-->
		<manage-customer-progress v-if="openProgress"
			:title="customer.name"
			:idc="idc"
			:id_customer="id_customer"
			@edited="loadEditedCustomer"
			@close="openProgress=false"
		/>
	</div>
</template>

<script>
import ManageCustomerEdit from '../manage-customer-edit.vue';
import ManageCustomerSync from '../manage-customer-sync.vue';
import ManageCustomerReporting from '../manage-customer-reporting/index.vue';
import ManageCustomerProgress from '../manage-customer-progress/index.vue';

export default {
	components: {
		'manage-customer-edit': ManageCustomerEdit,
		'manage-customer-sync': ManageCustomerSync,
		'manage-customer-reporting': ManageCustomerReporting,
		'manage-customer-progress': ManageCustomerProgress,
	},

	props: {
		index: {required: false, default: null}, //Index number in list, needed to remove or replace the single item
		idc: {required: true},
		id_customer: {required: true},
		fab: {required: true},
		reporting: {required: false},
		progress: {required: false},
	},

	data() {
		return {
			visible: false,
			openEdit: false,
			edited: false,
			openReporting: false,
			openProgress: false,
			customer: {}
		}
	},

	computed: {
		whatsappURL() {
			if($Config.app.onDevice) return 'https://wa.me/';

			return 'https://web.whatsapp.com/send?phone=';
		},

		fabEditClicked() {
			return this.fab.buttons.edit.clicked;
		},

		fabInviteClicked() {
			return this.fab.buttons.invite.clicked;
		},

		fabAcceptClicked() {
			return this.fab.buttons.accept.clicked;
		},

		fabBlockClicked() {
			return this.fab.buttons.block.clicked;
		},

		fabUnBlockClicked() {
			return this.fab.buttons.unblock.clicked;
		},

		fabArchiveClicked() {
			return this.fab.buttons.archive.clicked;
		},

		fabSyncClicked() {
			return this.fab.buttons.sync.clicked;
		},

		outOfSync() {
			return (this.customer.out_of_sync == 1 && this.customer.ignore_out_of_sync == 0);
		}
	},

	watch: {
		fabEditClicked(val) {
			if(!val) return;

			if(this.outOfSync) {
				$ViewHelper.confirm({method: this.openSync}, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_SYNC_FIRST_'));
			} else {
				this.openEdit = true;
			}

			this.fab.buttons.edit.clicked = false;
		},

		fabInviteClicked(val) {
			if(!val) return;

			// Do your stufff here!
			this.sendCustomerInviteButtonClicked();
			this.fab.buttons.invite.clicked = false;
		},

		fabAcceptClicked(val) {
			if(!val) return;

			// Do your stufff here!
			this.waitingCustomerButtonClicked();
			this.fab.buttons.accept.clicked = false;
		},

		fabBlockClicked(val) {
			if(!val) return;

			// Do your stufff here!
			$ViewHelper.confirm({method: this.setCustomerStatus, params: {action: 'blocked'}}, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_BLOCK_CUSTOMER_'));
			this.fab.buttons.block.clicked = false;
		},

		fabUnBlockClicked(val) {
			if(!val) return;

			// Do your stufff here!
			$ViewHelper.confirm({method: this.setCustomerStatus, params: {action: 'unblock'}}, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_UNBLOCK_CUSTOMER_'));
			this.fab.buttons.unblock.clicked = false;
		},

		fabArchiveClicked(val) {
			if(!val) return;

			// Do your stufff here!
			$ViewHelper.confirm({method: this.setCustomerStatus, params: {action: 'archived'}}, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_ARCHIVE_CUSTOMER_'));
			this.fab.buttons.archive.clicked = false;
		},

		fabSyncClicked(val) {
			if(!val) return;

			// Do your stufff here!
			this.openSync();
		}
	},

	methods: {
		loadCustomer() {
			$AjaxHelper.doRequest('user', 'getUserForAdmin', {idc: this.idc, id_appuser: this.id_customer}, {callback: this._loadCustomer});
		},

		setCustomerStatus(params) {
			$AjaxHelper.doRequest('user', 'setAppuserStatus', {idc: this.idc, id_appuser: this.id_customer, action: params.action}, {callback: this._setCustomerStatus});
		},

		sendCustomerInvite() {
			this.edited = true;
			$AjaxHelper.doRequest('user', 'inviteCustomer', {idc: this.idc, id_appuser: this.id_customer, invite_agreement: 1}, {callback: this._setCustomerStatus});
		},

		loadEditedCustomer() {
			this.edited = true;
			this.loadCustomer();
			this.editedClosed();
		},

		editedClosed() {
			this.openEdit = false;
			this.fab.buttons.edit.clicked = false;
		},

		openSync() {
			this.fab.buttons.sync.clicked = true;
		},

		sendCustomerInviteButtonClicked() {
			$ViewHelper.confirm({method: this.sendCustomerInvite}, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_HAVE_CHECKED_ADRESSEE_AND_AGREE_CURRENT_AND_FUTURE_DATA_WILL_BE_SHARED_'));
		},

		waitingCustomerButtonClicked() {
			var buttons = [
				{text: $LanguageHelper.translate('_CANCEL_')},

				// Accept the customer
				{text: $LanguageHelper.translate('_YES_'), onClick: () => this.setCustomerStatus({action: 'connected'})},

				// Archive the customer
				{text: $LanguageHelper.translate('_NO_'), onClick: () => this.setCustomerStatus({action: 'archived'})},
			];

			$ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_ACCEPT_CUSTOMER_'));
		},

		_loadCustomer(data) {
			this.customer = data.resultset;

			// Store whatsapp number
			this.customer.whatsapp = this.customer.mobile.length > 0 ? $FunctionsHelper.mobileToWhatsApp(this.customer.mobile, this.customer.id_country) : '';

			// Let our parent component know were done fetching the customer!
			this.$emit('customer-fetched', this.customer);

			// Let our parent component know we have a edited customer
			if(this.edited) {
				if(this.index != null) {
					this.$emit('edited', Object.assign({index: this.index}, this.customer));
				} else {
					this.$emit('edited', this.customer);
				}

				this.edited = false;
			}

			// Edit BTN => Should always be here!
			this.fab.buttons.edit.render = $UserHelper.hasAccess('users', 'edit', this.idc);

			// Invite BTN => Company (no account yet) or invited before
			this.fab.buttons.invite.render = ($UserHelper.hasAccess('users', 'edit', this.idc) && (this.customer.id_appuser_status == 1 || this.customer.id_appuser_status == 4 || this.customer.id_appuser_status == 7));

			// Accept BTN => Pending
			this.fab.buttons.accept.render = ($UserHelper.hasAccess('users', 'edit', this.idc) && this.customer.id_appuser_status == 2);

			// Block BTN => Pending or connected
			this.fab.buttons.block.render = ($UserHelper.hasAccess('users', 'edit', this.idc) && (this.customer.id_appuser_status == 2 || this.customer.id_appuser_status == 3));

			// Unblock BTN => Blocked
			this.fab.buttons.unblock.render = ($UserHelper.hasAccess('users', 'edit', this.idc) && this.customer.id_appuser_status == 5);

			// Sync BTN => Out of sync and not ignored
			this.fab.buttons.sync.render = ($UserHelper.hasAccess('users', 'edit', this.idc) && (this.customer.out_of_sync == 1 && this.customer.ignore_out_of_sync == 0));

			// Archive BTN => Should always be here!
			this.fab.buttons.archive.render = $UserHelper.hasAccess('users', 'edit', this.idc);

			// Make page visible
			this.visible = true;
		},

		_setCustomerStatus(data) {
			if(data.status == 'OK') {
				if(data.params_send.action == 'archived') {
					// Close popup since this customer is just archived
					this.$emit('close');
					this.$emit('archived');
				} else {
					// Set edited status so our callback function can emit the edited event!
					this.edited = true;
					this.loadCustomer();
				}
			}
		}
	},

	created() {
		this.loadCustomer();
	}
};
</script>

<style scoped>
.footer .footerContent {
	margin: auto;
}

.footer a {
	padding-right: 25px;
	vertical-align: middle;
}

.footer a i {
	font-size: 35px;
	vertical-align: middle;
	text-align: center;
}

.footer a i.ionWhatsapp {color: #25d366;}
.footer a i.ionText {color: #989797;}
.footer a i.ionCall {color: #989797;}
.footer a i.ionMail {color: #989797;}

i.ionInformationCircle {font-size: 21px;}

i.ionPerson {
	text-align: center;
	vertical-align: middle;
	font-size: 48px;
	color: #d7d7d7;
}

i.ionPerson::after {
	line-height: 68px;
}

.comments {
	margin-bottom: 10px;
}

.customerImage, .ionPerson {
	float: left;
  margin-right: 20px;
  margin-bottom: 20px;
	width: 75px;
	height: 75px;
	border-radius: 50%;
	border: 1px solid #D7D7D7;
}

@media (max-width: 340px) {
  .customerImage, .ionPerson{
		width: 50px;
		height: 50px;
	}

	i.ionPerson {
		font-size: 35px;
	}
}

.button.action {
	margin-bottom: 25px;
}
</style>
