<template>
	<popup
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="close"
	>

		<div v-if="visible">
			<f7-list v-for="(diff, key) in diffList" :key="key" inset media-list>
			  <f7-list-item
			    radio
					:name="key"
					:checked="diff.change.selected"
					:value="$FunctionsHelper.specialChars(diff.change.val)"
					:title="$FunctionsHelper.specialChars(diff.label)"
					:subtitle="diff.change.display != '' ? $FunctionsHelper.specialChars(diff.change.display) : $LanguageHelper.translate('_LEFT_EMPTY_')"
			    :after="$LanguageHelper.translate('_NEW_')"
			    class="green"
					@change="setValue(key)"
			  />

				<f7-list-item
			    radio
					:name="key"
					:checked="diff.current.selected"
					:value="$FunctionsHelper.specialChars(diff.current.val)"
			    :title="$FunctionsHelper.specialChars(diff.label)"
					:subtitle="diff.current.display != '' ? $FunctionsHelper.specialChars(diff.current.display) : $LanguageHelper.translate('_LEFT_EMPTY_')"
			    :after="$LanguageHelper.translate('_OLD_')"
			    class="red"
					@change="setValue(key)"
			  />
			</f7-list>

			<f7-block>
				<f7-button fill raised @click="submitted">{{$LanguageHelper.translate('_SAVE_CHANGES_')}}</f7-button>
			</f7-block>
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup,
	},

	props: {
		title: {type: String, required: true},
		idc: {required: true},
		id_customer: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,
			diffList: {},
			values: {}
		};
  },

	methods: {
		close() {
			this.$emit('close');
		},

		setValue(field) {
			this.values[field] = event.target.value;
		},

		submitted() {
			$AjaxHelper.doRequest('user', 'syncCustomerForAdmin', Object.assign(this.values, {idc: this.idc, id_appuser: this.id_customer, keep_birthday_format: 1}), {callback: this._syncSaved});
		},

		_getSyncChanges(data) {
			if(!data.changes) return;

			this.diffList = data.diff;
			for(var key in this.diffList) {
				this.values[key] = this.diffList[key].change.val;
			}

			this.visible = true;
		},

		_syncSaved(data) {
			if(data.status == 'OK') {
				this.$emit('synced');
				this.$emit('close');
			}
		}
	},

	created() {
		// Get changes!
		$AjaxHelper.doRequest('user', 'getSyncChanges', {idc: this.idc, id_appuser: this.id_customer}, {callback: this._getSyncChanges});
	}
};
</script>

<style>
li.green .item-title-row span {color: #4cd964; font-weight: bold;}
li.red .item-title-row span {color: #ff3b30; font-weight: bold;}
</style>
