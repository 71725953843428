<template>
	<div class="addToCalendarOptions marginBottom15">
		<a href="#" id="addToCalendarButton" @click="openOptions">
		<f7-icon class="icon ionCalendar themeDetail" /> {{$LanguageHelper.translate('_BUTTON_ADD_TO_CALENDAR_')}}
	</a>

	<div class="options" v-if="showOptions">
		<ul>
			<li v-if="urls.google" @click="syncToUserCalendar('google')">
				<span class="title">Google</span>
				<span class="logo google">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
						<path d="M152.637 47.363H47.363v105.273h105.273z" fill="#fff"></path><path d="M152.637 200L200 152.637h-47.363z" fill="#f72a25"></path>
						<path d="M200 47.363h-47.363v105.273H200z" fill="#fbbc04"></path><path d="M152.637 152.637H47.363V200h105.273z" fill="#34a853"></path>
						<path d="M0 152.637v31.576A15.788 15.788 0 0 0 15.788 200h31.576v-47.363z" fill="#188038"></path>
						<path d="M200 47.363V15.788A15.79 15.79 0 0 0 184.212 0h-31.575v47.363z" fill="#1967d2"></path>
						<path d="M15.788 0A15.79 15.79 0 0 0 0 15.788v136.849h47.363V47.363h105.274V0z" fill="#4285f4"></path>
						<path d="M68.962 129.02c-3.939-2.653-6.657-6.543-8.138-11.67l9.131-3.76c.83 3.158 2.279 5.599 4.346 7.341 2.051 1.742 4.557 2.588 7.471 2.588 2.995 0 5.55-.911 7.699-2.718 2.148-1.823 3.223-4.134 3.223-6.934 0-2.865-1.139-5.208-3.402-7.031s-5.111-2.718-8.496-2.718h-5.273v-9.033h4.736c2.913 0 5.387-.781 7.389-2.376 2.002-1.579 2.995-3.743 2.995-6.494 0-2.441-.895-4.395-2.686-5.859s-4.053-2.197-6.803-2.197c-2.686 0-4.818.716-6.396 2.148s-2.767 3.255-3.451 5.273l-9.033-3.76c1.204-3.402 3.402-6.396 6.624-8.984s7.34-3.89 12.337-3.89c3.695 0 7.031.716 9.977 2.148s5.257 3.418 6.934 5.941c1.676 2.539 2.507 5.387 2.507 8.545 0 3.223-.781 5.941-2.327 8.187-1.546 2.23-3.467 3.955-5.729 5.143v.537a17.39 17.39 0 0 1 7.34 5.729c1.904 2.572 2.865 5.632 2.865 9.212s-.911 6.771-2.718 9.57c-1.823 2.799-4.329 5.013-7.52 6.624s-6.787 2.425-10.775 2.425c-4.622 0-8.887-1.318-12.826-3.988zm56.087-45.312l-10.026 7.243-5.013-7.601 17.985-12.972h6.901v61.198h-9.847z" fill="#1a73e8"></path>
					</svg>
				</span>
			</li>

			<li v-if="urls.apple" @click="syncToUserCalendar('apple')">
				<span class="title">Apple</span>
				<span class="logo apple">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 245.657">
						<path d="M167.084 130.514c-.308-31.099 25.364-46.022 26.511-46.761-14.429-21.107-36.91-24.008-44.921-24.335-19.13-1.931-37.323 11.27-47.042 11.27-9.692 0-24.67-10.98-40.532-10.689-20.849.308-40.07 12.126-50.818 30.799-21.661 37.581-5.54 93.281 15.572 123.754 10.313 14.923 22.612 31.688 38.764 31.089 15.549-.612 21.433-10.073 40.242-10.073s24.086 10.073 40.546 9.751c16.737-.308 27.34-15.214 37.585-30.187 11.855-17.318 16.714-34.064 17.009-34.925-.372-.168-32.635-12.525-32.962-49.68l.045-.013zm-30.917-91.287C144.735 28.832 150.524 14.402 148.942 0c-12.344.503-27.313 8.228-36.176 18.609-7.956 9.216-14.906 23.904-13.047 38.011 13.786 1.075 27.862-7.004 36.434-17.376z"></path>
					</svg>
				</span>
			</li>

			<li v-if="urls.outlook" @click="syncToUserCalendar('outlook')" >
				<span class="title">Outlook.com</span>
				<span class="logo outlook">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 175">
						<path d="M178.725 0H71.275A8.775 8.775 0 0 0 62.5 8.775v9.975l60.563 18.75L187.5 18.75V8.775A8.775 8.775 0 0 0 178.725 0z" fill="#0364b8"></path>
						<path d="M197.813 96.281c.915-2.878 2.187-5.855 2.187-8.781-.002-1.485-.795-2.857-1.491-3.26l-68.434-38.99a9.37 9.37 0 0 0-9.244-.519c-.312.154-.614.325-.906.512l-67.737 38.6-.025.013-.075.044a4.16 4.16 0 0 0-2.088 3.6c.541 2.971 1.272 5.904 2.188 8.781l71.825 52.532z" fill="#0a2767"></path>
						<path d="M150 18.75h-43.75L93.619 37.5l12.631 18.75L150 93.75h37.5v-37.5z" fill="#28a8ea"></path><path d="M150 18.75h37.5v37.5H150z" fill="#50d9ff"></path>
						<path d="M150 93.75l-43.75-37.5H62.5v37.5l43.75 37.5 67.7 11.05z" fill="#0364b8"></path>
						<path d="M106.25 56.25v37.5H150v-37.5zM150 93.75v37.5h37.5v-37.5zm-87.5-75h43.75v37.5H62.5z" fill="#0078d4"></path>
						<path d="M62.5 93.75h43.75v37.5H62.5z" fill="#064a8c"></path>
						<path d="M126.188 145.113l-73.706-53.75 3.094-5.438 68.181 38.825a3.3 3.3 0 0 0 2.625-.075l68.331-38.937 3.1 5.431z" fill="#0a2767" opacity=".5"></path>
						<path d="M197.919 91.106l-.088.05-.019.013-67.738 38.588c-2.736 1.764-6.192 1.979-9.125.569l23.588 31.631 51.588 11.257v-.001c2.434-1.761 3.876-4.583 3.875-7.587V87.5c.001 1.488-.793 2.862-2.081 3.606z" fill="#1490df"></path>
						<path d="M200 165.625v-4.613l-62.394-35.55-7.531 4.294a9.356 9.356 0 0 1-9.125.569l23.588 31.631 51.588 11.231v.025a9.362 9.362 0 0 0 3.875-7.588z" opacity=".05"></path>
						<path d="M199.688 168.019l-68.394-38.956-1.219.688c-2.734 1.766-6.19 1.984-9.125.575l23.588 31.631 51.587 11.256v.001a9.38 9.38 0 0 0 3.562-5.187z" opacity=".1"></path>
						<path d="M51.455 90.721c-.733-.467-1.468-1.795-1.455-3.221v78.125c-.007 5.181 4.194 9.382 9.375 9.375h131.25c1.395-.015 2.614-.366 3.813-.813.638-.258 1.252-.652 1.687-.974z" fill="#28a8ea"></path>
						<path d="M112.5 141.669V39.581a8.356 8.356 0 0 0-8.331-8.331H62.687v46.6l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031V150h54.169a8.356 8.356 0 0 0 8.331-8.331z" opacity=".1"></path>
						<path d="M106.25 147.919V45.831a8.356 8.356 0 0 0-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031 68.75h47.919a8.356 8.356 0 0 0 8.331-8.331z" opacity=".2"></path>
						<path d="M106.25 135.419V45.831a8.356 8.356 0 0 0-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031 56.25h47.919a8.356 8.356 0 0 0 8.331-8.331z" opacity=".2"></path>
						<path d="M100 135.419V45.831a8.356 8.356 0 0 0-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0 0 50 87.5v.031-.031 56.25h41.669a8.356 8.356 0 0 0 8.331-8.331z" opacity=".2"></path>
						<path d="M8.331 37.5h83.337A8.331 8.331 0 0 1 100 45.831v83.338a8.331 8.331 0 0 1-8.331 8.331H8.331A8.331 8.331 0 0 1 0 129.169V45.831A8.331 8.331 0 0 1 8.331 37.5z" fill="#0078d4"></path>
						<path d="M24.169 71.675a26.131 26.131 0 0 1 10.263-11.337 31.031 31.031 0 0 1 16.313-4.087 28.856 28.856 0 0 1 15.081 3.875 25.875 25.875 0 0 1 9.988 10.831 34.981 34.981 0 0 1 3.5 15.938 36.881 36.881 0 0 1-3.606 16.662 26.494 26.494 0 0 1-10.281 11.213 30 30 0 0 1-15.656 3.981 29.556 29.556 0 0 1-15.425-3.919 26.275 26.275 0 0 1-10.112-10.85 34.119 34.119 0 0 1-3.544-15.744 37.844 37.844 0 0 1 3.481-16.563zm10.938 26.613a16.975 16.975 0 0 0 5.769 7.463 15.069 15.069 0 0 0 9.019 2.719 15.831 15.831 0 0 0 9.631-2.806 16.269 16.269 0 0 0 5.606-7.481 28.913 28.913 0 0 0 1.787-10.406 31.644 31.644 0 0 0-1.687-10.538 16.681 16.681 0 0 0-5.413-7.75 14.919 14.919 0 0 0-9.544-2.956 15.581 15.581 0 0 0-9.231 2.744 17.131 17.131 0 0 0-5.9 7.519 29.85 29.85 0 0 0-.044 21.5z" fill="#fff"></path>
					</svg>
				</span>
			</li>

			<li class="close">
				<a href="#" @click="closeOptions">Sluit</a>
			</li>
		</ul>
	</div>
	</div>
</template>

<script>
export default {
	props: {
		urls: {required: true},
  },

	data() {
    return {
			showOptions: false,
		};
  },

	methods: {
		closeOptions() {
			this.showOptions = false;
		},

		openOptions() {
			this.showOptions = true;
		},

		syncToUserCalendar(type) {
			// Close the options box here
			this.showOptions = false;

			// Download the .ics file so user can import it!
			if(type == 'apple' && this.urls.apple) return $ViewHelper.toBrowser(this.urls.apple);
			if(type == 'outlook' && this.urls.outlook) return $ViewHelper.toBrowser(this.urls.outlook);

			// Open user his Google calendar!
			if(type == 'google' && this.urls.google) return $ViewHelper.toBrowser(this.urls.google, !$Config.app.onDevice ? window.open() : null);
		},
	}
};
</script>

<style scoped>
.logo {
	float: left;
  margin-right: 10px;
	width: 24px;
}

.logo svg {
	height: 20px;
}

#addToCalendarButton {
	display: block;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border: 1px solid rgb(208 208 208);
	border-radius: 6px;
	font-size: 12px;;
}

#addToCalendarButton i.icon {
	margin-top: -3px!important;
	margin-right: 10px;
	font-weight: normal;
}

.addToCalendarOptions .options {
	margin-top: 20px;
}

.addToCalendarOptions ul, .addToCalendarOptions ul li {
   margin:0;
   padding: 0;
   text-indent: 0;
   list-style: none;
	 text-align: left;
	 font-weight: normal;
	 padding-top: 0;
	 padding-right: 5px;
	 cursor: pointer;;
}

.addToCalendarOptions ul, .addToCalendarOptions ul li.close {
	text-align: right;
	text-align: right;
	padding-bottom: 0;
	padding-top: 0;
	font-size: 16px;
	padding-right: 5px;
	font-weight: bold;
}

.addToCalendarOptions ul {
	border: 1px solid rgb(208 208 208);
	padding-left: 10px;
	padding-top: 15px;
	padding-bottom:10px;
	border-radius: 6px;
}

.addToCalendarOptions ul li {
	padding: 8px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.addToCalendarOptions ul li .title {
	font-size: 16px;
	line-height: 18px;
	vertical-align: top;
}

.addToCalendarOptions ul li:first-child {
	border: none;
}

</style>
