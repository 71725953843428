<template>
  <page :title="$LanguageHelper.translate('_MY_COMPANIES_')" @initiated="getMyCompanies">
    <f7-card inset>
      <f7-card-content v-for="(company, key, index) in companies" :key="index">
        <p><b>{{$FunctionsHelper.specialChars(company.name)}}</b></p>
        <p>{{company.city}}</p>
        <div class="marginTop20">
          <f7-button v-if="(!company.start_company || company.start_company == 0)" fill raised small @click="addStartPage(company.id_gym)">{{$LanguageHelper.translate('_ADD_AS_START_PAGE_')}}</f7-button>
          <f7-button v-else raised small fill @click="removeStartPage(company.id_gym)">{{$LanguageHelper.translate('_REMOVE_AS_START_PAGE_')}}</f7-button>
        </div>
        <div class="marginTop20">
          <f7-button fill raised small @click="removeCompany(company.id_gym)">{{$LanguageHelper.translate('_REMOVE_COMPANY_')}}</f7-button>
        </div>
      </f7-card-content>
    </f7-card>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },
  data() {
    return {
      companies: [],
    };
  },

  methods: {
    getMyCompanies() {
		    $AjaxHelper.doRequest('settings', 'getSettings', {}, {callback: this._initMyCompanies});
    },

    removeStartPage(idc) {
			$AjaxHelper.doRequest('gym', 'removeGymFromUser', {action: 'removeStartPage', idc: idc}, {callback: this._submitted});
    },

    addStartPage(idc) {
      $AjaxHelper.doRequest('gym', 'removeGymFromUser', {action: 'addStartPage', idc: idc}, {callback: this._submitted});
    },

    removeCompany(idc) {
      $ViewHelper.confirm({method: this.remove, params: {idc: idc}});
    },

    remove(params) {
      $AjaxHelper.doRequest('gym', 'removeGymFromUser', {action: 'remove', idc: params.idc}, {callback: this._submitted});
    },

    _initMyCompanies(data) {
      if(data.gyms && data.gyms.length > 0) {
        this.companies = data.gyms;
      }
    },

    _submitted(data) {
      $ViewHelper.toWindow('/');
    }
  },
};
</script>
