<template>
	<popup v-if="render"
		:title="title"
		:toolbar="toolbar"
		:opened="opened"
		@infinite-scroll-page-end="pageEndReached=true"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<template #info v-if="toolbar.tabs.info.active">
			<tab-info
				:index="index"
				:idc="idc"
				:id_customer="id_customer"
				:fab="fab.info"
				:reporting="reporting"
				:progress="progress"
				@close="opened=false"
				@archived="$emit('archived')"
				@edited="edited"
			/>
		</template>

		<template #products v-if="toolbar.tabs.products.active">
			<tab-products :idc="idc" :id_customer="id_customer" :fab="fab.products"  />
		</template>

		<template #appointments v-if="toolbar.tabs.appointments.active">
			<tab-appointments :idc="idc" :id_customer="id_customer" :page-end-reached="pageEndReached" @page-end-loaded="pageEndReached=false" />
		</template>
	</popup>
</template>

<script>
import Popup from '../../components/smart/popup.vue';
import TabInfo from './tab-info.vue';
import TabProducts from './tab-products.vue';
import TabAppointments from './tab-appointments.vue';

export default {
	components: {
    'popup': Popup,
    'tab-info': TabInfo,
    'tab-products': TabProducts,
    'tab-appointments': TabAppointments,
	},

	props: {
		index: {required: false},
		title: {type: String, required: true},
		idc: {required: true},
		id_customer: {required: true},
  },

	data() {
    return {
			opened: false,
			reporting: false,
			progress: false,
			render: false,
			pageEndReached: false,

			// Floating action buttons
			fab: {
				// Name of tab this FAB object belongs to!
				info: {
					position: 'right-bottom',
					buttons: {
						accept: {label: $LanguageHelper.translate('_ACCEPT_'), icon: 'icon ionCheckmark', render: false, clicked: false},
						sync: {label: $LanguageHelper.translate('_SYNC_BTN_TEXT_'), icon: 'icon ionSync', render: false, clicked: false},
						edit: {label: $LanguageHelper.translate('_EDIT_'), icon: 'icon ionCreate', render: false, clicked: false},
						invite: {label: $LanguageHelper.translate('_SEND_INVITE_'), icon: 'icon ionPaperPlane', render: false, clicked: false},
						archive: {label: $LanguageHelper.translate('_ARCHIVE_USER_'), icon: 'icon ionTrash', render: false, clicked: false},
						block: {label: $LanguageHelper.translate('_BLOCK_'), icon: 'icon ionLock', render: false, clicked: false},
						unblock: {label: $LanguageHelper.translate('_UNBLOCK_'), icon: 'icon ionUnlock', render: false, clicked: false},
					}
				},

				products: {
					position: 'right-bottom',
					buttons: {
						createDirectDebit: {label: $LanguageHelper.translate('_CREATE_DIRECTS_DEBIT_'), icon: 'icon ionSync', render: false, clicked: false},
						connectFreeProduct: {label: $LanguageHelper.translate('_CONNECT_FREE_PRODUCT_'), icon: 'icon ionCart', render: false, clicked: false},
					}
				}
			},

			// Tabs
			toolbar: {
				tabs: {
					info: {text: $LanguageHelper.translate('_INFO_'), icon: 'icon ionInformationCircle', render: $UserHelper.hasAccess('users', 'show', this.idc), active: true},
					products: {text: $LanguageHelper.translate('_PRODUCTS_'), icon: 'icon ionCart', render: $UserHelper.hasAccess('invoice_product', 'showSales', this.idc), active: false},
					appointments: {text: $LanguageHelper.translate('_APPOINTMENTS_'), icon: 'icon ionCalendar', render: false, active: false, infinite: true},
				}
			}
    };
  },

	methods: {
		// Tell parent component to replace this edited customer item
		edited(customer) {
			this.$emit('edited', customer);
		},

		_modules(data) {
			if(data.resultset) {
				data.resultset.forEach((obj) => {
					//check if agenda tab needs to be rendered
					if(obj.name_system == 'agenda') {
						this.toolbar.tabs.appointments.render = $UserHelper.hasAccess('agenda', 'appointmentsUser', this.idc);
					}

					//check if we need to create intake/reporting
					if(obj.name_system == 'reporting') this.reporting = true;
					if(obj.name_system == 'progress') this.progress = true;
				});
			}

			// Store the FABs in the correct tab, we need those on our tab page and watch if they are clicked as well!
			this.toolbar.tabs.info.fab = this.fab.info;
			this.toolbar.tabs.products.fab = this.fab.products;
			this.render = true;
		},
	},

	created() {
		$AjaxHelper.doRequest('gym', 'getModulesEnabledGym', {idc: this.idc}, {callback: this._modules});
	}
};
</script>
