<template>
	<customer-progress-list :idc="idc" :id_customer="id_customer" :fab="fab" :manage="true"/>
</template>

<script>
import CustomerProgressList from '../../components/customer-progress-list.vue';

export default {
  components: {
		'customer-progress-list': CustomerProgressList,
	},

	props: {
		idc: {required: true},
		id_customer: {required: true},
    fab: {required: true},
	},
};
</script>
