<template>
	<div v-if="render">
		<!-- Single FAB -->
		<div v-if="singleButtonName.length > 0" :class="`hideOnScroll fab fab-${fab.position}`">
			<a @click="fabClicked(fab.buttons[singleButtonName])" :title="$LanguageHelper.translate(fab.buttons[singleButtonName].label)">
				<i class="icon" :class="fab.buttons[singleButtonName].icon"></i>
			</a>
		</div>

		<!-- Group FAB -->
		<f7-fab v-else :position="fab.position" class="hideOnScroll">
			<f7-icon class="icon ionAdd"></f7-icon>
			<f7-icon class="icon ionClose"></f7-icon>

			<f7-fab-buttons position="top">
				<template v-for="(button, key) in fab.buttons">
						<f7-fab-button v-if="button.render" 
						:key="key"
						:label="$LanguageHelper.translate(button.label)"
						class="fab-close"
						@click="fabClicked(button)">

						<i class="icon" :class="button.icon"></i>
					</f7-fab-button>
				</template>
			</f7-fab-buttons>
		</f7-fab>
	</div>
</template>

<script>
export default {
	data () {
		return {
			render: false,
			singleButtonName: '',
		};
	},

	props: {
		fab: {type: Object, required: true}
  },

	methods: {
		fabClicked(fab) {
			fab.clicked = true;
			this.$emit('fab-clicked', fab);
		},

		setRenderStatus() {
			// If all buttons has set render to false, render no buttons at all
			this.render = false;
			this.singleButtonName = '';

			let cnt = 0;
			for(var button in this.fab.buttons) {
				if(this.fab.buttons[button].render) {
					this.render = true;
					this.singleButtonName = button;

					cnt ++;
				}

				// We have mulpiple buttons to render, clear single button name again
				if(cnt > 1) this.singleButtonName = '';
			}
		}
	},

	watch: {
    fab: {
			deep: true,
			immediately: true,
      handler(fab) {
				this.setRenderStatus();
      }
    }
  },

	created() {
		this.setRenderStatus();
	}
};
</script>
