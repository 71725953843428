<template>
	<popup
		:title="$LanguageHelper.translate('_MANAGE_DEPOSIT_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<list-form v-show="visible" :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
			visible: false,

			form: {
        fields: {
					cash_balance: {name: 'cash_balance', type: 'description', text: '', class: 'bold', render: true},
					amount_to_withdrawal: {name: 'amount', type: 'input', label: '_AMOUNT_TO_WITHDRAWAL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					reason_withdrawal: {name: 'reason', type: 'input', label: '_REASON_DESPOSIT_', placeholder: '_ENTER_HERE_', render: true, value: ''},
        },

				submit: {text: '_SAVE_', render: true},
				values: {},
      }
    };
  },

	methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			$AjaxHelper.doRequestExtraLogin('payment', 'saveWithdrawal', Object.assign({idc: this.idc, action: 'deposit'}, data), {callback: this._depositSubmitted});
		},

		_depositSubmitted(data) {
			if(data.status == "OK") {
				this.$emit('depositSubmitted');
				this.opened = false;
			}
		},

		_manageDeposit(data) {
			if(data.resultset && data.resultset.cash_balance) {
				this.form.fields.cash_balance.text = `${$LanguageHelper.translate('_IN_REGISTER_')}: ${data.resultset.cash_balance}`;
				this.visible = true;
			}
		}
	},

	created() {
		$AjaxHelper.doRequestExtraLogin('payment', 'getWithdrawal', {idc: this.idc, action: 'manage-deposit'}, {callback: this._manageDeposit});
	}
};
</script>
