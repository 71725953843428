<template>
  <f7-page v-if="visible"
		:id="`ref_${_uid}`"
		:class="pageClass"
		:page-content="false"
		:infinite-preloader="false"
		:infinite="infinite"
		>

		<!-- Navbar here -->
		<navbar-page v-if="renderNavbar"
			:title="title"
			:back="back"
			:panel="panel"
			:search="searchEnabled"
			:filter="filterEnabled"
			:sort-options="sortOptionsData"
			:language="language"
			:dynamic-component="dynamicNavbarComponent"
			@searched="searched"
			@sort-selected="sortSelected"
			@show-filters-clicked="showFiltersClicked"
			@dynamic-component-changed="dynamicNavbarComponentChanged"
		/>

		<slot name="navbar-action"></slot>

		<!-- Floating action buttons -->
		<fab v-if="fab" :fab="fab" @fab-clicked="fabClicked"></fab>

		<!-- Render toolbar with tabs if present -->
		<template v-if="toolbar">
			<f7-toolbar tabbar labels position="bottom">
				<template v-for="(tab, key) in toolbar.tabs">
					<f7-link  v-if="tab.render" v-show="!tab.hide"
						:key="key" :tab-link="'#tab' + key"
						:text="$LanguageHelper.translate(tab.text)"
						:tab-link-active="tab.active"
						:icon="tab.icon"
						@click="tabClicked(tab)"
					/>
				</template>
			</f7-toolbar>

			<!-- Tab contents-->
			<f7-tabs v-if="toolbar">
				<template v-for="(tab, key) in toolbar.tabs" :key="key">  
					<f7-tab :id="'tab' + key" :tab-active="tab.active" v-if="tab.render">
						<f7-page
							:page-content="false"
							:infinite="tab.infinite ? true : false"
							:infinite-preloader="false"
							:search="tab.search ? true: false"
							>

							<div class="page-content infinite-scroll-content" :class="tab.class ? tab.class: ''" @infinite="infiniteScrollPageEnd">
								<slot :name="key"></slot>
							</div>

							<!-- Floating action buttons per tab page -->
							<fab v-if="tab.fab" :fab="tab.fab" @fab-clicked="fabClicked"></fab>
						</f7-page>
				</f7-tab>
				</template>
			</f7-tabs>
		</template>

		<!-- Render regular plain slot contents if there is no toolbar -->
		<template v-else>
			<div class="page-content infinite-scroll-content" :class="pageContentClass" :style="backgroundImage ? 'background-image:url(' + backgroundImage + ');' : ''" @infinite="infiniteScrollPageEnd">
				<slot></slot>
			</div>
		</template>

		<f7-toolbar v-if="toolbarBranding" bottom :no-shadow="true" :no-hairline="true" class="toolbarBranding">
			<div class="outer">
				<div class="inner">
					<f7-link :href="toolbarBrandingAttrs.href" class="text-align-center" external :target="$Config.app.onDevice ? '_system' : '_blank'">
						<span>POWERED BY</span> <img :src="toolbarBrandingAttrs.img">
					</f7-link>
				</div>
			</div>
		</f7-toolbar>
	</f7-page>
</template>

<script>
import PageAndPopup from '../../js/mixins/pageAndPopup';
import NavBarPage from './navbar-page.vue';

export default {
	mixins: [PageAndPopup],

  components: {
    'navbar-page': NavBarPage,
  },

	props: {
		pageClass: {type: String, required: false},
		pageContentClass: {type: String, required: false},
		panel: {type: Boolean, required: false, default: true},
		renderNavbar: {type: Boolean, required: false, default: true},
		toolbarBranding: {type: Boolean, required: false, default: false},
		backgroundImage: {type: String, required: false},
	},

	data() {
		return {
			toolbarBrandingAttrs: {
				href: this.$Config.apps[this.$Config.app.currentApp].siteURL,
				img: 'static/img/' + this.$Config.apps[this.$Config.app.currentApp].name + '/logo.png',
			},
		}
	},
};
</script>

<style scoped>
.toolbarBranding {
	/*border-top: 1px solid #E7E7E7;*/
	background: #FFF;
}

body.recurme .toolbarBranding {
	background: var(--navbar-bg-color-org);
}

.toolbarBranding span {
	float: left;
  font-size: 11px;
  color: #444444;
	margin-right:10px;
}

body.recurme .toolbarBranding span {
	color: #ffffff;
}

.page-content.companyBackground {
	padding-left:20px;
	padding-right:20px;
	padding-bottom:44px;
}
</style>
