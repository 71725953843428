import SearchExpandable from '../../components/smart/search-expandable.vue';

export default {
	components: {
		'search-expandable': SearchExpandable
  },

	props: {
		title: {type: String, required: false},
		search: {type: Boolean, required: false, default: false},
		filter: {type: Boolean, required: false, default: false},
		sortOptions: {type: Object, required: false},
		dynamicComponent: {required: false},
  },

	data() {
		return {
			searchEnabled: false,
			popoverOpened: false,
		}
	},

	methods: {
		searched(val) {
			this.$emit('searched', val);
		},

		dynamicComponentChanged(val) {
			this.$emit('dynamic-component-changed', val);
		},

		sortOptionChanged(option) {
			// Unselect all sort values here
			Object.values(this.sortOptions.options).forEach(option => option.selected = false);

			// Enable the selected one
			option.selected = true;

			// Emit the sort value
			this.$emit('sort-selected', event.target.value);

			// Close popover
			this.popoverOpened = false;
		},

		showFiltersClicked() {
			this.$emit('show-filters-clicked');
		}
	},

	watch: {
		search(val) {
			// Disable search (again) if search becomes false
			if(!val) this.searchEnabled = false;
		}
	}
};
