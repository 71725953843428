<template>
	<div v-if="render" class="pageContent">
		<f7-card>
			<f7-card-header v-html="infoAbout.header" />
			<f7-card-content>
				<div v-if="infoAbout.videoURL && infoAbout.videoURL.length > 0" class="marginTop15 marginBottom35 relative wrapperVideo">
					<div style="padding:56.34% 0 0 0;position:relative;">
						<iframe :src="infoAbout.videoURL" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen"></iframe>
					</div>
				</div>

				<div v-if="infoAbout.info && infoAbout.info.length > 0" v-html="infoAbout.info" />

				<div class="marginTop15" v-if="infoAbout.toSettingsButton && infoAbout.toSettingsButton.length > 0">
					<f7-button fill raised @click="toSettingsPage">{{infoAbout.toSettingsButton}}</f7-button>
				</div>
			</f7-card-content>
		</f7-card>

		<f7-card v-if="infoAbout.course && infoAbout.course.header && infoAbout.course.header.length > 0" class="marginTop35">
			<f7-card-header v-html="infoAbout.course.header" />

			<f7-card-content>
				<div v-html="infoAbout.course.info" />
				<f7-button fill raised @click="toPage(infoAbout.course.orderCourseCTA.url)" class="marginTop15">{{infoAbout.course.orderCourseCTA.text}}</f7-button>
			</f7-card-content>
		</f7-card>
	</div>
</template>
<script>

export default {
	props: {
		tabs: {type: Object, required: true},
  },

	data() {
    return {
			render: false,
			infoAbout: {}
		};
  },

	methods: {
		toPage(url) {
			if(url && url.length > 0) $ViewHelper.toBrowser(url, !$Config.app.onDevice ? window.open() : null);
		},

		toSettingsPage() {
			this.tabs.info.active = false;
			this.tabs.settings.active = true;
		},
	},

	created() {
		$AjaxHelper.doRequest('apiConnect', 'getInfoAbout', {idc: this.idc, type: 'AC'}, {callback: (data) => {
			this.infoAbout = data.infoAbout
			this.render = true;
		}});
	}
};
</script>

<style scoped>
.wrapperVideo {
	max-width: 960px;
}
</style>
