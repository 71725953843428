<template>
	<popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		title: {type: String, required: true},
		idc: {required: true},
		id_customer: {required: true},
		id_product: {required: false},
		id_sale: {required: false},
  },

	data() {
		return {
			opened: false,

			// Action that is posted to back-end for existing connected product
			action: '',

			// Store if existing connected product is active or not
			active: 0,

			form: {
				fields: {
					// General warning
					warning: {type: 'description', text: '_CONNECT_PRODUCT_TO_CUSTOMER_EXPLAIN_', class: 'textPayAttention', render: true},

					// Status info and button field
					status_info: {type: 'divider', title: '_CHANGE_STATUS_', class: 'bold', render: false},
					status_description: {type: 'description', text: '', class: 'bold', render: false},
					change_status: {name:'change_status', type: 'button', text: '', render: false},

					// Settings divider
					settings_info: {type: 'divider', title: '_CHANGE_SETTINGS_', class: 'bold', render: false},

					// Change product (only for new one)
					id_invoice_product: {type: 'select-regular', name: 'id_invoice_product', title: '_PRODUCT_', render: false, options: []},
					selected_product: {type: 'description', text: '', class: 'bold', render: false},

					// Fields for credits!
					number_credits: {type: 'input', label: '_NUMBER_CREDITS_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					valid_until: {name: 'valid_until', type: 'date', label: '_VALID_UNTIL_', placeholder: '_SELECT_DATE_', render: false, value: ''},

					// Fields for installments product
					date_start: {name: 'date_start', type: 'date', label: '_DATE_START_', placeholder: '_SELECT_DATE_', render: false, value: moment().format('YYYY-MM-DD')},
					date_end: {name: 'date_start', type: 'date', label: '_DATE_END_', placeholder: '_SELECT_DATE_', render: false, value: ''},
				},

				submit: {text: '_SAVE_', render: false},
				values: {},
			}
		}
	},

	methods: {
		changed(field, item) {
			// The product user wants to connect has been selected
			if(field.name == 'id_invoice_product') {
				// Hide form fields first
				this.hideFields();

				// Credits product
				if(item.credits == 1) this.renderCreditsProduct(item);

				// Installments product
				if(item.installments == 1) this.renderInstallmentsProduct(item);

				// Render form if a valid option was selected
				if(item.value > 0) {
					// Show selected product!
					this.form.fields.selected_product.text = item.name;
					this.form.fields.selected_product.render = true;

					// Render the submit
					this.form.submit.render = true;
				}
			}

			// User has clicked the (de) activate button and wants to change status
			if(field.name == 'change_status') {
				// Do request here to change the status and then reload the form
				this.action = this.active == 1 ? 'deactivate' : 'activate';

				let data = Object.assign(JSON.parse(JSON.stringify(this.form.values)), this.constructData());
				$AjaxHelper.doRequest('invoiceProduct', 'saveProductCustomerForm', data, {callback: this._saved});
			}
		},

		// Hide connecting new product fields
		hideFields() {
			this.form.fields.selected_product.render = false;
			this.form.fields.number_credits.render = false;
			this.form.fields.valid_until.render = false;
			this.form.fields.date_start.render = false;
			this.form.fields.date_end.render = false;
			this.form.submit.render = false;
		},

		renderCreditsProduct(item, edit) {
			// Calculate end date
			let validUntilDate = '';
			if(!edit) {
				if(item.number_weeks_valid > 0) validUntilDate = moment().add(item.number_weeks_valid, 'weeks').format('YYYY-MM-DD');
				if(item.number_months_valid > 0) validUntilDate = moment().add(item.number_months_valid, 'months').format('YYYY-MM-DD');
			} else {
				if(item.valid_until && item.valid_until.length > 0) validUntilDate = item.valid_until;
			}

			// Calendar needs some time to set the new value!
			setTimeout(() => {
				this.form.fields.valid_until.value = validUntilDate;
				this.form.fields.valid_until.render = true;
			}, 5);

			// Number credits is only allowed on a new product
			if(!edit) {
				this.form.fields.number_credits.value = item.number_credits;
				this.form.fields.number_credits.render = true;
			}
		},

		renderInstallmentsProduct(item, edit) {
			if(edit) {
				if(item.date_start && item.date_start.length > 0) this.form.fields.date_start.value = item.date_start;
				if(item.date_end && item.date_end.length > 0) this.form.fields.date_end.value = item.date_end;
			}

			this.form.fields.date_start.render = true;
			this.form.fields.date_end.render = true;
		},

		submitted() {
			let data = Object.assign(JSON.parse(JSON.stringify(this.form.values)), this.constructData());
			$AjaxHelper.doRequest('invoiceProduct', 'saveProductCustomerForm', data, {callback: this._saved});
		},

		constructData() {
			let data = {};

			// Overwrite with prop values if they are here!
			if(this.id_product > 0) data['id_invoice_product'] = this.id_product;
			if(this.id_sale > 0) data['id_invoice_product_sale'] = this.id_sale;
			if(this.action.length > 0) data['action'] = this.action;

			// Set other needed data
			data['idc'] = this.idc;
			data['id_appuser'] = this.id_customer;

			return data;
		},

		// Used for connecting a new product
		_productsFetched(data) {
			if(data.status == 'OK') {
				this.form.fields.id_invoice_product.options.push({
					label: '_SELECT_PRODUCT_',
					value: 0,
					selected: false
				});

				data.resultset.forEach((item, index) => {
					this.form.fields.id_invoice_product.options.push({
						label: item.name,
						value: item.id_invoice_product,
						name: item.name,
						installments: item.installments,
						credits: item.credits,
						number_credits: item.number_credits,
						number_months_valid: item.number_months_valid,
						number_weeks_valid: item.number_weeks_valid,
						selected: false
					});
				});

				this.form.fields.id_invoice_product.render = true;
			}
		},

		// Used for existing connected product
		_productSaleUser(data) {
			if(data.status == 'OK' && data.resultset.connected) {
				// Store status global, we need it later!
				this.active = data.resultset.connected.active;

				// Show button for changing status
				if(data.resultset.connected.active == 1) {
					this.form.fields.change_status.title = '_DEACTIVATE_';
					this.form.fields.status_description.text = '_PRODUCT_ACTIVE_';
				} else {
					this.form.fields.change_status.title = '_ACTIVATE_';
					this.form.fields.status_description.text = '_PRODUCT_INACTIVE_';
				}

				// Show selected product!
				this.form.fields.selected_product.text = data.resultset.connected.name;
				this.form.fields.selected_product.render = true;

				// Credits product
				if(data.resultset.connected.credits == 1) {
					this.action = 'change-credits-valid-until';
					this.renderCreditsProduct(data.resultset.connected, true);
				}

				// Installments product
				if(data.resultset.connected.installments == 1) {
					this.action = 'change-installments';
					this.renderInstallmentsProduct(data.resultset.connected, true);
				}

				// Render fields
				this.form.fields.status_info.render = true;
				this.form.fields.change_status.render = true;
				this.form.fields.status_description.render = true;
				this.form.fields.settings_info.render = true;

				this.form.submit.render = true;
			}
		},

		_saved(data) {
			// Close popup after were done...
			if(data.status == 'OK') {
				//Show Dialog to unsubscribe customers appointments!
				if(data.noticeUserHasSubscriptionsAfterDate && data.noticeUserHasSubscriptionsAfterDate.length > 0) {
					//Set the end date to today if the user didn't enter it (happens when the deactivate option is clicked in prev request)
					let dataToSend = {id_invoice_product: data.params_send.id_invoice_product, id_appuser: data.params_send.id_appuser, id_gym: data.params_send.id_gym, date_end: moment().format("YYYY-MM-DD")};

					let buttons = [
						{text: $LanguageHelper.translate('_YES_'), onClick: () => {$AjaxHelper.doRequest('Payment', 'unsubscribeUserAppointmentsAfterDate', dataToSend, {callback: (data) => {}});}},
						{text: $LanguageHelper.translate('_NO_'), onClick: () => {return false;}},
					];

					$ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_NOTICE_'), data.noticeUserHasSubscriptionsAfterDate);
				}

				// Setting opened to false will trigger the popup close event so this component will be destroyed!
				this.$emit('changed');

				this.opened = false;
			}
		}
	},

	created() {
		// Existing sale (we need to fetch the requested product so user can make changes)
		if(this.id_product > 0 && this.id_sale > 0) {
			$AjaxHelper.doRequest('invoiceProduct', 'invoiceProductSaleForAdmin', {idc: this.idc, id_invoice_product: this.id_product, id_invoice_product_sale: this.id_sale, id_appuser: this.id_customer}, {callback: this._productSaleUser});
		}
		// Add new product (we need to fetch the available products here so user can select one)
		else {
			$AjaxHelper.doRequest('invoiceProduct', 'productsForAgenda', {idc: this.idc}, {callback: this._productsFetched});
		}
	}
};
</script>
