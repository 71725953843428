/** Helpers we need in the global scope **/

// Keep track if push woosh was initiated for this session
window.$PushWooshInitiated = false;

// Keep track of page visits
window.$PageVisits = 0;

// F7 default variable
window.$7 = null

// jQuery
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;

// Moment JS
import moment from 'moment';
window.moment = moment;

// Config
import Config from './config.js';
window.$Config = Config;

// Functions Helper
import FunctionsHelper from './helpers/functionsHelper.js';
window.$FunctionsHelper = FunctionsHelper;

// Language Helper
import LanguageHelper from './helpers/languageHelper.js';
window.$LanguageHelper = LanguageHelper;

// Sync Helper
import SyncHelper from './helpers/syncHelper.js';
window.$SyncHelper = SyncHelper;

// View Helper
import ViewHelper from './helpers/viewHelper.js';
window.$ViewHelper = ViewHelper;

// User Helper
import UserHelper from './helpers/userHelper.js';
window.$UserHelper = UserHelper;

// Ajax Helper
import AjaxHelper from './helpers/ajaxHelper.js';
window.$AjaxHelper = AjaxHelper;

/** Helpers we need in the global scope **/
