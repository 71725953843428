<template>
	<popup
		:title="$LanguageHelper.translate('_ARCHIVE_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
		>
		<f7-list inset v-show="show">
			<f7-list-item accordion-item v-for="(product, key) in products.list"
				:key="key"
				:title="$FunctionsHelper.specialChars(product.name)"
				:footer="getType(product)"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<!-- Order in the shop -->
						<div class="item" v-if="product.bookingOption !=2">{{$LanguageHelper.translate('_ORDER_')}}: {{(product.order_me && product.order_me >= 0 ? product.order_me : $LanguageHelper.translate('_NOT_SET_'))}}</div>

						<!-- Installment amounts -->
						<template v-if="product.installments == 1 && product.id_invoice_product_type != 3">
							<div class="item" v-if="product.amount_in_vat_first_installment > 0">{{$LanguageHelper.translate('_AMOUNT_FIRST_PERIOD_')}}: &euro; {{product.amount_in_vat_first_installment}}</div>
							<div class="item">{{$LanguageHelper.translate('_AMOUNT_IN_VAT_')}}: &euro; {{product.amount_in_vat}}</div>
							<div class="item">{{$LanguageHelper.translate('_VAT_PERCENTAGE_')}}: {{product.vat_percentage}}%</div>

							<div class="item">{{$LanguageHelper.translate('_ATHORIZATION_MULTIPLE_PERIODS_')}}: {{$LanguageHelper.translate('_YES_')}}</div>
							<div class="item">{{$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}}: {{product.number_installments == 0 ? $LanguageHelper.translate('_CONTINUOUSLY_') : product.number_installments}}</div>
							<div class="item">{{$LanguageHelper.translate('_ATHORIZATION_SEPA_')}}: {{$LanguageHelper.translate('_EVERY_')}}  {{product.length > 1 ? product.length : product.period}}</div>
						</template>

						<!-- Settings for credits -->
						<template v-if="product.credits == 1">
							<!-- Number credits and valid until here -->
							<div class="item">{{$LanguageHelper.translate('_NUMBER_CREDITS_')}}: {{product.number_credits}}</div>

							<div class="item" v-if="product.number_months_valid > 0">{{$LanguageHelper.translate('_NUMBER_MONTHS_VALID_')}}: {{product.number_months_valid}}</div>
							<div class="item" v-else-if="product.number_weeks_valid > 0">{{$LanguageHelper.translate('_NUMBER_WEEKS_VALID_')}}: {{product.number_weeks_valid}}</div>
							<div class="item" v-else>{{$LanguageHelper.translate('_PERIOD_VALID_')}}: {{$LanguageHelper.translate('_VALID_UNLIMITED_')}}</div>

							<!-- Payment method here -->
							<div class="item" v-if="product.pm_ideal == 1">iDEAL: {{$LanguageHelper.translate('_YES_')}}</div>
							<div class="item" v-if="product.pm_bancontact == 1">Bancontact: {{$LanguageHelper.translate('_YES_')}}</div>
							<div class="item" v-if="product.pm_machtiging == 1">{{$LanguageHelper.translate('_ATHORIZATION_')}}: {{$LanguageHelper.translate('_YES_')}}</div>
							<div class="item" v-if="product.paypal == 1">PayPal: {{$LanguageHelper.translate('_YES_')}}</div>
						</template>
					</f7-block>

					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('invoice_product', 'remove', idc)">
								<f7-button @click="restore(product.id_invoice_product)" raised fill small>{{$LanguageHelper.translate('_RESTORE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>
			</f7-list-item>
		</f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
			show: true,
			products: {
				list: []
			}
		};
  },

	methods: {
		load() {
			$AjaxHelper.doRequest('invoiceProduct', 'archivedProductItems', {idc: this.idc}, {callback: this._products});
		},

		renderList(items) {
			this.products.list = items;
			this.show = true;
		},

		getType(product) {
			if(product.id_invoice_product_type == 1) return $LanguageHelper.translate('_SUBSCRIPTION_');
			if(product.id_invoice_product_type == 2) return $LanguageHelper.translate('_CARD_WITH_CREDITS_');
			if(product.id_invoice_product_type == 3) return $LanguageHelper.translate('_FREE_TRIAL_');
			if(product.id_invoice_product_type == 4) return $LanguageHelper.translate('_OTHER_PRODUCT_');
		},

		restore(id) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('invoiceProduct', 'updateArchiveStatus', {idc: this.idc, id_invoice_product: id, status: 0}, {callback: this._restored})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		_products(data) {
			// Empty the list first
			this.products.list = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj, key) => {obj.opened = false});

				this.renderList(data.resultset);
			}
		},

		_product(data) {
			// Emit added event back to parent so this items get's added back to the list!
			this.$emit('added', data.resultset);

			// Reload the archive list
			this.load();
		},

		_restored(data) {
			$AjaxHelper.doRequest('invoiceProduct', 'productAdmin', {idc: this.idc, id_invoice_product: data.params_send.id_invoice_product}, {callback: this._product});
		}
	},

	created() {
		// Get the products here!
		this.load();
	}
};
</script>
