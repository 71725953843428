<template>
  <page :title="title" :backgroundImage="backgroundImage" pageContentClass="pageContentClass" @initiated="go">
    <f7-block v-if="render">
      <form v-if="page == 'company-found'">
        <f7-card class="cardCenter">
          <f7-card-header>
            <b>{{$LanguageHelper.translate('_ADD_')}}</b>
          </f7-card-header>

          <f7-card-content>
            <p>{{$LanguageHelper.translate('_AWARE_PERSONAL_DATA_')}}.</p>
          </f7-card-content>

          <f7-list>
            <li>
              <checkbox-agree-to-terms v-if="company.companyTerms && company.companyTerms.url_terms_and_conditons"
                :link="company.companyTerms.url_terms_and_conditons"
                :text-label="$LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS2_')"
                :text-link="$FunctionsHelper.specialChars(company.name)"
                :checked="form.companyTerms"
                @changed="(val) => form.companyTerms = val"
              />
            </li>
          </f7-list>

          <f7-block strong>
              <f7-button @click="add" raised small fill>{{$LanguageHelper.translate('_ADD_COMPANY_')}}</f7-button>
          </f7-block>
        </f7-card>

        <f7-card class="cardCenter">
          <f7-card-header>
            <b>{{$LanguageHelper.translate('_CONTACT_')}}</b>
          </f7-card-header>

          <f7-card-content>
            <p v-if="company.street" v-html="`${company.street} ${company.housenumber} ${company.housenumber_extra}`"></p>
            <p v-if="company.zipcode" v-html="`${company.zipcode} ${company.city}`"></p>
            <p v-if="company.telephone">{{company.telephone}}</p>
            <f7-link v-if="company.website" :href="company.website" external :target="$Config.app.onDevice ? '_system' : '_blank'">{{company.website}}</f7-link>
          </f7-card-content>

          <f7-card-content v-if="company.description">
            <p v-html="company.description"></p>
          </f7-card-content>

          <f7-block strong>
              <f7-button @click="contactMe" raised small fill>{{$LanguageHelper.translate('_COMPANY_CONTACT_ME_')}}</f7-button>
          </f7-block>
        </f7-card>
      </form>

      <form v-else-if="page == 'message'">
        <message-to-page-button :msg="msg" @click="$ViewHelper.router.navigate('/')" :btnText="$LanguageHelper.translate('_GO_TO_HOME_PAGE_')" />
      </form>
    </f7-block>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import CheckboxAgreeToTerms from '../components/checkbox-agree-to-terms.vue';
import MessageToPageButton from '../components/message-to-page-button.vue';

export default {
	components: {
    'page': Page,
    'checkbox-agree-to-terms': CheckboxAgreeToTerms,
    'message-to-page-button': MessageToPageButton,
  },

  props: {
		idc: { required: true },
  },

  data() {
    return {
			render: false,
      company: {},
      title: '',
			backgroundImage: '',
      form: {companyTerms: false},
      msg: '',
      page: 'company-found',
    };
  },

  methods: {
    // Regular methods here!
    loadData() {
      $AjaxHelper.doRequest('gym', 'getGymComplete', {
        idc: this.idc,
        id: this.idc,
        id_account: $UserHelper.getID(),
        companyImages: 1
        },{callback: this._initCompany}
      );
    },

    contactMe() {
      $ViewHelper.confirm({method: this.contact}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_COMPANY_CONTACT_ME_CONFIRM_'));
    },

    contact(data) {
      var id = this.idc;
      $AjaxHelper.doRequest('gym', 'contactMe', {idc: id})
    },

    add(event) {
      var formData = JSON.parse(JSON.stringify(this.form));
			// Cast boolean to int with the + char!
			formData.companyTerms = + formData.companyTerms;
      $AjaxHelper.doRequest('gym', 'addGymToUser', $.extend({
        idc: this.idc
      }, formData), {
        callback: this._addCompany
      });
    },

    _initCompany(data) {
      if(data && data.resultset) {
        this.company = data.resultset;
        this.title = this.company.name

				// Set background images if there are any
				if(data.companyImages && data.companyImages.bg)  this.backgroundImage = data.companyImages.bg;
				this.render = true;
      }
    },

    _addCompany(data) {
      if (data.status == 'OK') {
        // Set the response notice from the WS if it's there
  			if(data.notice) this.msg = data.notice;
				// Show the go to home page again with the message!
				this.page = 'message';
      }
    },

		go() {
			this.loadData();
		}
  },
};
</script>
