<template>
	<page :title="title" :toolbarBranding="true" :back="false" :panel="false" class="panelPage">
		<div class="fixedTop">
			<f7-list class="marginTop14 noPadding">
				<f7-list-item>
					<f7-list-button @click="toHome" view="#main-view" panel-close>
	          <f7-icon class="ionHome"></f7-icon>
	        </f7-list-button>

	        <f7-list-button link="/profile-image/" view="#main-view" panel-close>
						<img v-if="profileImageURL" :src="profileImageURL" width="44" height="44" :title="$LanguageHelper.translate('_UPLOAD_PROFILE_IMAGE_')"/>
						<f7-icon v-else class="ionProfile" :title="$LanguageHelper.translate('_UPLOAD_PROFILE_IMAGE_')"></f7-icon>
	        </f7-list-button>

	        <f7-list-button link="/messages/" view="#main-view" panel-close>
	          <f7-icon class="ionMail">
							<f7-badge v-if="numberNotifications > 0" color="red" class="marginTop10">{{numberNotifications}}</f7-badge>
						</f7-icon>
	        </f7-list-button>

	        <f7-list-button @click="logout" view="#main-view" panel-close>
	          <f7-icon class="ionLogOut"></f7-icon>
	        </f7-list-button>
				</f7-list-item>
			</f7-list>
		</div>

		<f7-list :class="[profileImageURL ? marginTop74 : marginTop45]">
			<li v-if="companies && companies.length > 1">
				<select name="companies" @change="companySelected">
					<option v-for="(company, key) in companies" :key="key" :value="company.idc" :selected="company.idc == idc">{{$FunctionsHelper.specialChars(company.name)}}</option>
				</select>
			</li>

      <f7-list-item v-if="companies && companies.length > 0" divider :title="$LanguageHelper.translate('_COMPANY_SETTINGS_')"></f7-list-item>

			<f7-list-item v-if="companies && companies.length > 0" :key="idc" :link="`/manage-company/idc/${idc}`" :title="$LanguageHelper.translate('_MANAGEMENT_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionCog"></f7-icon></template>
      </f7-list-item>

			<f7-list-item v-if="$UserHelper.hasAccess('companySettings', 'accessSettings', idc)" :link="`/manage-company-settings/idc/${idc}`" :title="$LanguageHelper.translate('_COMPANY_ACCOUNT_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionBriefcase"></f7-icon></template>
      </f7-list-item>

			<f7-list-item v-if="$UserHelper.hasAccess('companySettings', 'accessSettings', idc)" :link="`/company-my-invoices/idc/${idc}`" :title="$LanguageHelper.translate('_MY_INVOICES_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionDownload"></f7-icon></template>
      </f7-list-item>

			<f7-list-item v-if="$UserHelper.hasAccess('companySettings', 'accessSettings', idc)" :link="`/manage-access-settings/idc/${idc}`" :title="$LanguageHelper.translate('_COMPANY_ACCESS_SETTINGS_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionPeople"></f7-icon></template>
      </f7-list-item>

			<f7-list-item v-if="$UserHelper.hasAccess('companySettings', 'companySettings', idc) || $UserHelper.hasAccess('files', null, idc)" :link="`/manage-company-styles/idc/${idc}`" :title="$LanguageHelper.translate('_COMPANY_STYLES_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionColorWand"></f7-icon></template>
      </f7-list-item>

      <f7-list-item divider :title="$LanguageHelper.translate('_PERSONAL_SETTINGS_')"></f7-list-item>
			
			<f7-list-item v-if="$ViewHelper.router.getCurrentRoute().name == 'home' && $Config.apps[$Config.app.currentApp].searchCompanies" link="/find-company/" :title="$LanguageHelper.translate('_FIND_COMPANY_TITLE_')" view="#main-view" panel-close>
				<template #media><f7-icon icon="ionSearch"></f7-icon></template>
      </f7-list-item>
			
			<f7-list-item link="/my-account/" :title="$LanguageHelper.translate('_MY_ACCOUNT_')" view="#main-view" panel-close>
				<template #media><f7-icon icon="ionPerson"></f7-icon></template>
      </f7-list-item>

			<f7-list-item link="/change-password/" :title="$LanguageHelper.translate('_PASSWORD_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionLock"></f7-icon></template>
      </f7-list-item>

			<f7-list-item link="/remove-account/" :title="$LanguageHelper.translate('_REMOVE_ACCOUNT_')" view="#main-view" panel-close>
				<template #media><f7-icon icon="ionTrash"></f7-icon></template>
      </f7-list-item>

      <f7-list-item link="/my-companies/" :title="$LanguageHelper.translate('_MY_COMPANIES_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionList"></f7-icon></template>
      </f7-list-item>

      <f7-list-item :link="`/settings-other/idc/${idc}/no-styles/1`" :title="$LanguageHelper.translate('_OTHER_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionSettings"></f7-icon></template>
      </f7-list-item>

    	<f7-list-item :title="$LanguageHelper.translate('_LANGUAGE_')" smart-select :smart-select-params="{openIn: 'popover', closeOnSelect: true}">
				<language-select-options />
			</f7-list-item>

			<f7-list-item divider class="small"></f7-list-item>

      <f7-list-item :link="`/faq/idc/${idc}/no-styles/1`" :title="$LanguageHelper.translate('_FAQ_SHORT_')" view="#main-view" panel-close>
        <template #media><f7-icon icon="ionHelpCircle"></f7-icon></template>
      </f7-list-item>
		</f7-list>
  </page>
</template>

<script>
import { EventBus } from '../js/eventBus.js';
import Notifications from '../js/mixins/notifications';
import Page from '../components/smart/page.vue';
import LanguageSelectOptions from '../components/language-select-options.vue';

export default {
	mixins: [Notifications],

	components: {
		'page': Page,
		'language-select-options': LanguageSelectOptions
  },

	data() {
		return {
			idc: null,
			marginTop74: 'marginTop74',
  		marginTop45: 'marginTop45',
			title: $Config.apps[$Config.app.currentApp].displayName,
      companies: [],
			profileImageURL: '',
		}
	},

	methods: {
		toHome() {
			if(!$Config.app.onDevice) {
				return $ViewHelper.reloadWindow();
			}
			return $ViewHelper.router.navigate('/');
		},

		companySelected() {
      // Close panel and navigate to the manage company page.
			$ViewHelper.panel.close();
			return $ViewHelper.router.navigate(`/manage-company/idc/${event.target.value}/`);
		},

		setCompanyID() {
			this.idc = this.companies[0] ? this.companies[0].idc : null;
			const params = $ViewHelper.router.getParams();
			if(params && params.idc) this.idc = params.idc;
		},

		logout() {
			// Log user out!
			$UserHelper.logout();

			// Fully reload window so we get rid of the native history
			if(!$Config.app.onDevice) {
				return $ViewHelper.reloadWindow();
			}

			// Check for URL params, if there are any do a window reload, else just navigate to the login page!
			const queryParams = $FunctionsHelper.getAllQueryParams(window.location.href);
			if(Object.keys(queryParams).length > 0) {
				return $ViewHelper.reloadWindow();
			} else {
				// Send user to login new account page
				return $ViewHelper.router.navigate(`/login-new-account/`, { animate: false });
			}
		}
	},

	created() {
		// Store the companies user manages here!
		this.companies = $UserHelper.accesslevels.getCompaniesUserManages();

		// Set the default company id here
		this.setCompanyID();

		// Update the profile image when we get the global change event!
		EventBus.$on('user-profile-image-changed', url => this.profileImageURL = url);

		// Change the company id if the page has changed!
		EventBus.$on('page-changed', () => this.setCompanyID());
  }
}
</script>

<style>
.panel-right .list .list-button i {color: var(--navbar-bg-color)!important;}

.paynplan .panel-right .toolbar a.link img {height:25px;}
.wodapp .panel-right .toolbar a.link img {height:25px;}
.ptapp .panel-right .toolbar a.link img {height:20px;}

.panel-right .list .item-inner {
	padding-top: 0px;
	padding-bottom: 0px;
}

.panel-right .toolbarBranding span {
	font-size: 10px;
}
</style>

<style scoped>
select[name="companies"] {
	background-image: url(../static/img/dropdown_arrow.png);
	background-position: 230px 5px;
	background-repeat: no-repeat;
	background-color: #fff;
	width: 100%;
	height: 44px;
	margin-bottom: 0px;
	margin-top: 7px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 2px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	box-shadow: none;
	border: 0.5px solid #E7E7E7;
	border-bottom: none;
	border-right: none;
	color: var(--f7-list-item-title-text-color);
	cursor: pointer;
}

.panel-right .list img {
	border-radius: 50%;
	margin-top: 15px;
}

.list {
	padding-bottom:30px;
}

.popover .list {
	padding-bottom:0px;
}

.small {
	height:20px;
}
</style>
