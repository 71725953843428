<template>
  <page :title="$LanguageHelper.translate('_OTHER_')" @initiated="getUserSettings">
    <list-form :params="form" @submitted="submitted" v-if="render">
    </list-form>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
  components: {
    'page': Page,
    'list-form': ListForm
  },

  props: {idc: {required: false}},

  data() {
    return {
      queryParams: $FunctionsHelper.getAllQueryParams(window.location.href),
      agendaAppointmentsView: 1,
      agendaAppointmentsFullColor: 0,
      render: false,

      form: {
				fields: {
          agenda_settings_title: {type: 'divider', title: '_AGENDA_SETTINGS_', render: true},
					agenda_appointments_view: {type: 'select', title: '_AGENDA_VIEW_', render: true,
            options: {
              week: {name: 'week', value: '1', label: '_WEEK_', selected: true},
              day: {name: 'day', value: '2', label: '_DAY_', selected: false},
              list: {name: 'list', value: '3', label: '_LIST_', selected: false}
            }
          },
          agenda_appointments_fullcolor: {type: 'select', title: '_AGENDA_APPOINTMENTS_VIEW_', render: true,
            options: {
              line: {name: 'line', value: '0', label: '_APPOINTMENTS_LINE_COLOR_', selected: true},
              full: {name: 'full', value: '1', label: '_APPOINTMENTS_FULL_COLOR_', selected: false},
            }
          },
          mail_settings_title: {type: 'divider', title: '_SEND_MAIL_WHEN_', render: true},
          mail_settings: {type: 'checkbox_group', items: [], render: true},
				},

				values: {},
        submit: {text: '_SAVE_', render: true},
			}
    };
  },

  methods: {
    getUserSettings() {
      $AjaxHelper.doRequest('settings', 'getSettings', {}, {callback: this._initUserSettings});
    },

    submitted() {
      this.form.submitted = false;
      $AjaxHelper.doRequest('settings', 'saveAppSettings', Object.assign(JSON.parse(JSON.stringify(this.form.values))));
    },

    _initUserSettings(data) {
      if(data.settings_user) {

        //put mail setting items in form
        let mailSettings = [];
        $.each(data.settings_user.mail_settings, (item, key) =>  {
            mailSettings.push({
              title: key.name,
              checked: key.selected == 1 ? true : false,
              value: key.id,
							render: true
            });
        });
        this.form.fields.mail_settings.items = mailSettings;

        // Set default for agenda_appointments_view
        $.each(this.form.fields.agenda_appointments_view.options, (item, key) => {
					key.selected = data.settings_user.settings.agenda_appointments_view == key.value;
				});

        // Set default for agenda_appointments_fullcolor
        $.each(this.form.fields.agenda_appointments_fullcolor.options, (item, key) => {
					key.selected = data.settings_user.settings.agenda_appointments_fullcolor == key.value;
        });

        this.render = true;
      }
    },
  },
};
</script>

<style scoped>
.list.inset {
  margin-top: 0px;
}
</style>
