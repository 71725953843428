<template>
	<popup
		:title="$LanguageHelper.translate('_WOD_RANK_CATEGORIES_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
		v-if="visible"
		:fab="fab"
		@fab-clicked="fabClicked"
		>

		<f7-list inset>
			<template v-for="(item, index) in categories" :key="index">
				<f7-list-item v-if="item.editable == 0" >
					<div>{{item.name}}</div>
				</f7-list-item>
			</template>

			<f7-list-item divider>
				<template>
					<span>{{$LanguageHelper.translate('_EDIT_')}}</span>
					<f7-link class="link theme action" :popover-open="`.popover_ranking`"><i class="icon ionInformationCircle"></i></f7-link>
					<f7-popover :class="`popover_ranking`">
						<div class="content" v-html="$LanguageHelper.translate('_EDIT_NOT_USED_RANKING_ONLY_')" />
					</f7-popover>
				</template>
			</f7-list-item>
		
			<template v-for="(item, index) in categories" :key="index">
				<f7-list-item accordion-item v-if="item.editable == 1" :title="$FunctionsHelper.specialChars(item.name)">
					<f7-accordion-content>
						<f7-block class="marginBottom10">
							<f7-row>
								<f7-col>
									<f7-button @click="openEditCategory(item)" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
								</f7-col>

								<f7-col>
									<f7-button @click="remove(item.id_wod_ranking_category)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
								</f7-col>
							</f7-row>
						</f7-block>
					</f7-accordion-content>
				</f7-list-item>
			</template>
		</f7-list>

		<manage-add-category-wod v-if="addCategory" :idc="idc" @refresh="refresh" @close="addCategory=false" />
		<manage-edit-category-wod v-if="editCategory" :idc="idc" :category="category" @refresh="refresh" @close="editCategory=false" />

	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ManageEditCategoriesWod from '../popups/manage-edit-category-wod.vue';
import ManageAddCategoryWod from '../popups/manage-add-category-wod.vue';

export default {
	components: {
    'popup': Popup,
		'manage-edit-category-wod': ManageEditCategoriesWod,
		'manage-add-category-wod': ManageAddCategoryWod,
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,
			editCategory: false,
			addCategory: false,
			refreshed: false,
			categories: {},
			category: {},

			fab: {
				position: 'right-bottom',
				buttons: {
					add: {name:'add', icon: 'icon ionAdd', label: $LanguageHelper.translate('_CREATE_NEW_'), render: true, clicked: false},
				}
			},
    };
  },

	methods: {
		openEditCategory(data) {
			this.category = data;
			this.editCategory = true;
		},

		fabClicked() {
			this.addCategory = true;
		},

		remove(val) {
			let paramsSend = [];
			paramsSend['deleted'] = {};
			paramsSend.deleted[val] = val;

			$AjaxHelper.doRequest('WOD', 'saveRankingCategories', Object.assign({idc: this.idc}, paramsSend), {callback: this._deleted});
		},

		refresh() {
			this.refreshed = true;
			this.getWod();
		},

		_deleted(data) {
			if(data.status == 'OK') this.refresh();
		},

		_initWod(data) {
			this.categories = {};
			if(data.status == 'OK') {
				if(data.ranking_categories) this.categories = data.ranking_categories;
				//check if manage-wod needs to be refreshed
				if(this.refreshed) this.$emit('getCategories', this.categories);
				this.visible = true;
			}
		},

		getWod() {
			$AjaxHelper.doRequest('WOD', 'wodOptions', {idc: this.idc}, {callback: this._initWod});
		}
	},

	created() {
		this.getWod();
	}
};
</script>

<style scoped>
.popover .content {
	padding: 12px;
}

.item-inner .link.action {
	padding-left: 6px;
}
</style>
