<template>
  <div v-show="render" class="widthFull">
    <f7-card inset v-if="shoppingCart.number_items > 0">
      <f7-card-header>{{$FunctionsHelper.specialChars(title)}}</f7-card-header>
      <f7-card-content>
        <f7-block class="marginTop10 marginBottom10">
          <f7-row class="marginBottom10">
            <f7-col width="50"><b>{{$LanguageHelper.translate('_NAME_')}}</b></f7-col>
            <f7-col width="25" class="alignRight"><b>{{$LanguageHelper.translate('_AMOUNT_')}}</b></f7-col>
            <f7-col width="5"></f7-col>
            <f7-col width="20"><b>{{$LanguageHelper.translate('_NUMBER_ITEMS_')}}</b></f7-col>
          </f7-row>

          <f7-row v-for="(items, key) in shoppingCart.products" :key="key">
            <f7-col width="50">{{$FunctionsHelper.specialChars(items.name)}}</f7-col>
            <f7-col width="25" class="alignRight">€ {{items.amount_in_vat}}</f7-col>
            <f7-col width="5"></f7-col>
            <f7-col width="20" class="shoppingCart">
              <f7-input
                type="text"
                placeholder="1"
                :id="items.id_invoice_product"
                :value="items.number_items"
                @input="numberItemsChanged($event.target.value, items.id_invoice_product)"
                >
              </f7-input>
              <f7-link @click="removeItemClicked(items.id_invoice_product)" class="theme"><f7-icon class="icon ionTrash"></f7-icon></f7-link>
            </f7-col>
          </f7-row>

          <f7-row class="marginTop20 marginBottom20">
            <f7-col width="30"></f7-col>
            <f7-col width="45" class="alignRight">{{$LanguageHelper.translate('_TOTAL_')}} € {{shoppingCart.total_amount_in_vat}}</f7-col>
            <f7-col width="25">
              <f7-link @click="updateCartClicked" class="theme">{{$LanguageHelper.translate('_UPDATE_CART_')}}</f7-link>
            </f7-col>
          </f7-row>

          <f7-row v-if="!outsideCartData">
            <f7-col>
              <f7-button raised fill small @click="checkoutClicked">{{$LanguageHelper.translate('_PAY_ALL_')}}</f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-card-content>
    </f7-card>

    <f7-card inset v-else-if="!hideNoItemsMessage">
			<f7-card-header>{{$LanguageHelper.translate('_NOTHING_IN_CART_')}}</f7-card-header>
      <f7-card-content>
        <f7-block class="marginTop5 marginBottom5">
					<f7-button @click="$emit('close')" raised fill small>{{$LanguageHelper.translate('_SHOP_')}}</f7-button>
      	</f7-block>
      </f7-card-content>
    </f7-card>
  </div>
</template>

<script>
export default {
	props: {
		title: {required: false, default: $LanguageHelper.translate('_PRODUCTS_IN_SHOPPING_CART_')},
		idc: {required: true},
		update: {required: false, default: 0}, // Contains a number so watcher will be triggered to update the cart
		outsideCartData: {required: false}, // If cart is controlled from the outside the data will be in here
		hideNoItemsMessage: {required: false, default: false},
  },

	data() {
    return {
			render: false,
			shoppingCart: {},
		};
  },

  watch: {
    outsideCartData() {
      this.shoppingCart = {};
      this.initOutsideCart();
    },

		update(val) {
			if(val > 0) this.fetchData();
		}
  },

	methods: {
		checkoutClicked() {
			$ViewHelper.toBrowser(this.shoppingCart.purchase_url, !$Config.app.onDevice ? window.open() : null);
		},

		numberItemsChanged(numberItems, id) {
			// Set value
			this.shoppingCart.products.forEach((product) => {
				if(numberItems >= 0 && product.id_invoice_product == id) product.number_items = numberItems;
			});
		},

		removeItemClicked(id) {
			// If cart is controlled from the outside, emit so parent knows to update
			if(this.outsideCartData) return this.$emit('removeItemClicked', id);

			// Call to back-end to update the cart
			$AjaxHelper.doRequest('invoiceProduct', 'cartAction', Object.assign({idc: this.idc, id_appuser: $UserHelper.getID(), action: 'remove-from-cart', id_invoice_product: id}), {callback: this._cartUpdated});
		},

		updateCartClicked() {
			let products = {};
			this.shoppingCart.products.forEach((product) => {
				products[product.id_invoice_product] = product.number_items;
			});

			// Cart controlled from the outside, emit changed event
			if(this.outsideCartData) return this.$emit('changed', products);

			// Regular cart perform the action yourself
			$AjaxHelper.doRequest('invoiceProduct', 'cartAction', Object.assign({idc: this.idc, id_appuser: $UserHelper.getID(), action: 'update-cart'}, {id_invoice_product: products}), {callback: this._cartUpdated});
		},

		_cartUpdated(data) {
			if(data.status == 'OK') {
				this.shoppingCart = data.shoppingCart;

				// Let parent know to update
				this.$emit('changed', this.shoppingCart);
			}
		},

		// This is init method for the controlled cart from the outside
		initOutsideCart() {
			this.shoppingCart = this.outsideCartData;
      this.render = true;
    },

		fetchData() {
			let data = {idc: this.idc, id_appuser: $UserHelper.getID(), userList: 1, shoppingCart: 1, category: 1};

			$AjaxHelper.doRequest('invoiceProduct', 'productItemsShop', data, {callback: (data) => {
				if(data.status == 'OK') {
					this.shoppingCart = data.shoppingCart;
					this.render = true;
				}
			}});
		},
	},

	created() {
		// If data comes in from the outside no need to fetch data
    if(this.outsideCartData) return this.initOutsideCart();

		// Get our data here
    this.fetchData();
	},
};
</script>

<style scoped>
i.icon.ionTrash {
  bottom: 8px;
  margin-top: 5px;
}
.md i.icon.ionTrash {
  margin-top: 10px;
}
i.icon.ionRefresh {bottom: 8px;}
</style>
