<template>
	<list-form :params="form" @submitted="submitted" v-if="visible">
		<!-- Add special (non standard) form elements to the bottom of the form inside the extra slot -->
		<template #extra-items>
			<li v-if="terms.userTerms" class="terms">
				<checkbox-agree-to-terms
					:link="terms.userTerms.userTermsURL"
					:text-label="$LanguageHelper.translate('_AGREE_WITH_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_TERMS2_')"
					:text-link="$Config.apps[$Config.app.currentApp].displayName"
					:checked="form.userTermsAgreed"
					@changed="(val) => form.userTermsAgreed = val"
				/>
			</li>

			<li class="terms" v-if="terms.companyTerms">
				<checkbox-agree-to-terms v-if="terms.companyTerms"
					:link="terms.companyTerms.url_terms_and_conditons"
					:text-label="$LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS2_')"
					:text-link="terms.companyTerms.name"
					:checked="form.companyTerms"
					@changed="(val) => form.companyTermsAgreed = val"
				/>
			</li>
		</template>
	</list-form>
</template>

<script>
import CheckboxAgreeToTerms from '../components/checkbox-agree-to-terms.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
		'checkbox-agree-to-terms': CheckboxAgreeToTerms,
		'list-form': ListForm
	},

	props: {
		idc: {required: false},
		admin: {type: Boolean, required: false, default: false},
		id_customer: {required: false}
	},

	data() {
		return {
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href), /* Query params from URL, there might be a company id in here */
			visible: false,
			renderedBefore: false,
			isLoggedIn: $UserHelper.isLoggedIn(),

			terms: {},

			form: {
				userTermsAgreed: false,
				companyTermsAgreed: false,

				fields: {
					username: {type: 'input', label: '_USERNAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					email: {type: 'input', label: '_EMAIL_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					firstname: {type: 'input', label: '_FIRST_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					middlename: {type: 'input', label: '_MIDDLE_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					lastname: {type: 'input', label: '_LAST_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					phone: {type: 'input', label: '_PHONE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					mobile: {type: 'input', label: '_MOBILE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					gender: {type: 'select', title: '_GENDER_', render: true, options: {m: {name: 'm', value: 'm', label: '_MALE_', selected: true}, v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false}}},
					street: {type: 'input', label: '_STREET_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					housenumber: {type: 'input', label: '_HOUSENUMBER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					housenumber_extra: {type: 'input', label: '_HOUSENUMBER_EXTRA_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					zipcode: {type: 'input', label: '_ZIPCODE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					city: {type: 'input', label: '_CITY_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					id_country: {type: 'select', title: '_COUNTRY_', render: false, options: []},
					birthdate: {type: 'date', label: '_BIRTH_DATE_', placeholder: '_SELECT_DATE_', render: true, value: ''},
					ice_name: {type: 'input', label: '_ICE_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					ice_phone: {type: 'input', label: '_ICE_PHONE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					ice2_name: {type: 'input', label: '_ICE2_NAME_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					ice2_phone: {type: 'input', label: '_ICE2_PHONE_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					temp_comment: {type: 'input', label: '_TEMP_COMMENT_', placeholder: '_ENTER_COMMENTS_HERE_', render: false, value: ''},
					comments: {type: 'input', label: '_OTHER_COMMENTS_', placeholder: '_ENTER_COMMENTS_HERE_', render: false, value: ''},
					send_invite: {type: 'checkbox', title: '_INVITE_CUSTOMER_TO_APP_', render: false, checked: true, value: 1},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
		}
	},

	methods: {
		submitted() {
			let data = Object.assign((this.queryParams.id ? {idc: this.queryParams.id} : {}), JSON.parse(JSON.stringify(this.form.values)));

			// User agreed with the terms?
			if(this.terms.userTerms) data.userTerms = + this.form.userTermsAgreed;
			if(this.terms.companyTerms) data.companyTerms = + this.form.companyTermsAgreed;

			if(this.admin) {
				// Edit Customer
				if(this.id_customer) {
					$AjaxHelper.doRequest('user', 'saveCustomerForAdmin', Object.assign({idc: this.idc, id_appuser: this.id_customer}, data), {callback: this._editCustomer});
				}
				// New customer
				else {
					$AjaxHelper.doRequest('user', 'createUserAccountByGym', Object.assign({idc: this.idc}, data), {callback: this._customerAdded});
				}
			} else {
				// Edit my account
				if(this.isLoggedIn) {
					$AjaxHelper.doRequest('user', 'edit', data, {callback: this._editMyAccount});
				}
				// Create new account
				else {
					// This is the user himself is editing his own account
					$AjaxHelper.doRequest('user', 'create', data, {callback: this._create});
				}
			}
		},

		renderComponent() {
			if(this.admin) {
				// Edit Customer
				if(this.id_customer) {
					$AjaxHelper.doRequest('user', 'getUserForAdmin', {idc: this.idc, id_appuser: this.id_customer}, {callback: this._loadCustomer});
				}
				// New customer
				else {
					// Do a request to get the countries!
					$AjaxHelper.doRequest('gym', 'getCountries', {idc: this.idc}, {callback: this._newCustomer});
				}
			} else {
				// Edit my account
				if(this.isLoggedIn) {
					$AjaxHelper.doRequest('user', 'loadEditAccountData', {}, {callback: this._loadAccount});
				}
				// Create new account
				else {
					// Get the Pay n Plan user terms and company terms (if a company id is present in the URL)
					$AjaxHelper.doRequest('user', 'getTerms', {idc: this.queryParams.id}, {callback: this._terms});
				}
			}

			// Component has been rendered now!
			this.renderedBefore = true;
		},

		setCountries(countries) {
			countries.forEach((item, key) => {
				item.name = item.code;
				item.selected = false;
				this.form.fields.id_country.options.push(item);
			});

			this.form.fields.id_country.render = true;
		},

		_newCustomer(data) {
			// Set the countries
			this.setCountries(data.countries);

			// Make the comment fields visible!
			this.form.fields.temp_comment.render = true;
			this.form.fields.comments.render = true;
			this.form.fields.send_invite.render = true;

			// Make page visible
			this.visible = true;
		},

		_loadCustomer(data) {
			// Set values from DB
			if(data.resultset.birthdate_en.length > 0) {
				data.resultset.birthdate = data.resultset.birthdate_en;
			}

			// Add the countries and make the right one selected
			this.setCountries(data.resultset.countries);

			// Set the form values, unwanted fields will automaticly be stripped out!
			this.form.values = data.resultset;

			// Make the comment fields visible!
			this.form.fields.temp_comment.render = true;
			this.form.fields.comments.render = true;

			// Make page visible
			this.visible = true;
		},

		_loadAccount(data) {
			// Add the countries
			this.setCountries(data.countries);

			// Set the form values, unwanted fields will automaticly be stripped out!
			this.form.values = data;

			// Enable the username field, we want it here!
			this.form.fields.username.render = true;

			// Make page visible
			this.visible = true;
		},

		_terms(data) {
			// Store the terms
			if(data.resultset.user_terms) this.terms.userTerms = data.resultset.user_terms;
			if(data.resultset.company_terms) this.terms.companyTerms = data.resultset.company_terms;

			// Disable fields we don't want
			this.form.fields.phone.render = false;
			this.form.fields.street.render = false;
			this.form.fields.housenumber.render = false;
			this.form.fields.housenumber_extra.render = false;
			this.form.fields.zipcode.render = false;
			this.form.fields.city.render = false;
			this.form.fields.id_country.render = false;

			// Hide emergency contact data when user creates a new account
			this.form.fields.ice_name.render = false;
			this.form.fields.ice_phone.render = false;

			this.form.fields.ice2_name.render = false;
			this.form.fields.ice2_phone.render = false;

			// Apple privacy policy, remove fields we are not allowed to ask!
			if($Config.app.platform == 'ios' && $Config.app.onDevice) {
				this.form.fields.gender.render = false;
				this.form.fields.birthdate.render = false;
				this.form.fields.mobile.render = false;
			}

			// Make page visible
			this.visible = true;
		},

		// Callback for user who created his own account
		_create(data) {
			// Close the popup
			if(data.closePage) {
				// Let the outside world know were done here!
				this.$emit('close');
			}
		},

		// Callback for editing my account... Nothing to do, notice will be shown automaticly!
		_editMyAccount(data) {},

		// Callback for editing customer, emit edited and close event to my parent.
		_editCustomer(data) {
			// Close the popup
			if(data.status == 'OK') {
				// Let the outside world know were done here!
				this.$emit('edited');
				this.$emit('close');
			}
		},

		_customerAdded(data) {
			// Close the popup
			if(data.status == 'OK' && data.id_appuser && data.id_appuser > 0) {
				$AjaxHelper.doRequest('user', 'getUserForAdmin', {idc: this.idc, id_appuser: data.id_appuser}, {callback: this._loadJustAddedCustomer});
			}
		},

		_loadJustAddedCustomer(data) {
			// Let the outside world know were done here!
			this.$emit('added', data.resultset);
			this.$emit('close');
		}
	},

	created() {
		// Construct basic default form values here
		this.renderComponent();
	}
}
</script>

<style scoped>
li.terms label {
	min-height: 28px;
}

li.terms {
	padding-top: 5px;
}
</style>

<style>
.accountForm ul {
	padding-bottom: 15px;
}
</style>
