<template>
	<div v-if="render">
		<table v-if="data.default_card" width="100%">
			<tr>
				<td colspan="7">
					<div class="wrapperPeriod">
						<table class="center" width="100%">
							<tr>
								<td class="arrow left" align="left"><a v-if="data.pagination.prev > 0" @click="paginate(data.pagination.prev)"><i class="icon ionArrowDropLeft" /></a></td>
								<td class="arrow right" align="right"><a v-if="data.pagination.next > 0" @click="paginate(data.pagination.next)"><i class="icon ionArrowDropRight" /></a></td>
							</tr>
						</table>
					</div>
				</td>
			</tr>

			<tr>
      	<td class="title first"><b>{{$LanguageHelper.translate('_SIZES_')}}</b></td>
      	<td class="title"><b>{{$LanguageHelper.translate('_FIRST_')}}</b> {{data.first.created_on }}</td>
      	<td class="title"><b>{{$LanguageHelper.translate('_PREVIOUS_')}}</b> {{data.prev.created_on }}</td>
      	<td class="title"><b>{{$LanguageHelper.translate('_LAST_')}}</b> {{data.last.created_on }}</td>
      	<td class="title"><b>{{$LanguageHelper.translate('_DIFF_PREVIOUS_')}}</b></td>
      	<td class="title"><b>{{$LanguageHelper.translate('_DIFF_FIRST_')}}</b></td>
      </tr>

			<tr v-for="(field, key) in fields" :key="key">
				<td class="first">{{field.label}}</td>
				<td>{{data.first[field.name] && data.first[field.name].length > 0 ? data.first[field.name] : ''}}</td>
				<td>{{data.prev[field.name] && data.prev[field.name].length > 0 ? data.prev[field.name] : ''}}</td>
				<td>{{data.last[field.name] && data.last[field.name].length > 0 ? data.last[field.name] : ''}}</td>
				<td>{{data.diff_prev[field.name] && data.diff_prev[field.name].length > 0 ? data.diff_prev[field.name] : ''}}</td>
				<td>{{data.diff_first[field.name] && data.diff_first[field.name].length > 0 ? data.diff_first[field.name] : ''}}</td>
			</tr>

			<tr class="total">
				<td class="first"><b>{{$LanguageHelper.translate('_TOTAL_')}}</b></td>
				<td>{{data.first && data.first.total && data.first.total.length > 0 ? data.first.total : ''}}</td>
				<td>{{data.prev && data.prev.total && data.prev.total.length > 0 ? data.prev.total : ''}}</td>
				<td>{{data.last && data.last.total && data.last.total.length > 0 ? data.last.total : ''}}</td>
				<td>{{data.diff_prev && data.diff_prev.total && data.diff_prev.total.length > 0 ? data.diff_prev.total : ''}}</td>
				<td>{{data.diff_first && data.diff_first.total && data.diff_first.total.length > 0 ? data.diff_first.total : ''}}</td>
			</tr>
		</table>

		<table v-else width="100%">
			<tr>
				<td colspan="7">
					<div class="wrapperPeriod">
						<table class="center" width="100%">
							<tr>
								<td class="arrow left" align="left"><a v-if="data.pagination.prev > 0" @click="paginate(data.pagination.prev)"><i class="icon ionArrowDropLeft" /></a></td>
								<td class="arrow right" align="right"><a v-if="data.pagination.next > 0" @click="paginate(data.pagination.next)"><i class="icon ionArrowDropRight" /></a></td>
							</tr>
						</table>
					</div>
				</td>
			</tr>

			<tr>
      	<td class="title first"><b>{{$LanguageHelper.translate('_SIZES_')}}</b></td>
      	<td class="title"><b>{{data.result && data.result[0] ? data.result[0].created_on : ''}}</b></td>
      	<td class="title"><b>{{data.result && data.result[1] ? data.result[1].created_on : ''}}</b></td>
      	<td class="title"><b>{{data.result && data.result[2] ? data.result[2].created_on : ''}}</b></td>
      	<td class="title"><b>{{data.result && data.result[3] ? data.result[3].created_on : ''}}</b></td>
      	<td class="title"><b>{{data.result && data.result[4] ? data.result[4].created_on : ''}}</b></td>
      </tr>

			<tr v-for="(field, key) in fields" :key="key">
				<td class="first">{{field.label}}</td>
				<td>{{data.result && data.result[0] ? data.result[0][field.name] : ''}}</td>
				<td>{{data.result && data.result[1] ? data.result[1][field.name] : ''}}</td>
				<td>{{data.result && data.result[2] ? data.result[2][field.name] : ''}}</td>
				<td>{{data.result && data.result[3] ? data.result[3][field.name] : ''}}</td>
				<td>{{data.result && data.result[4] ? data.result[4][field.name] : ''}}</td>
			</tr>

			<tr class="total">
				<td class="first"><b>{{$LanguageHelper.translate('_TOTAL_')}}</b></td>
				<td>{{data.result && data.result[0] ? data.result[0].total : ''}}</td>
				<td>{{data.result && data.result[1] ? data.result[1].total : ''}}</td>
				<td>{{data.result && data.result[2] ? data.result[2].total : ''}}</td>
				<td>{{data.result && data.result[3] ? data.result[3].total : ''}}</td>
				<td>{{data.result && data.result[4] ? data.result[4].total : ''}}</td>
			</tr>
		</table>
	</div>
</template>

<script>
import ManageCustomerProgressItem from '../popups/manage-customer-progress-item.vue';

export default {
  components: {
		'manage-customer-progress-item': ManageCustomerProgressItem,
	},

	props: {
		idc: {required: true},
		id_customer: {required: false},
    fab: {required: false},
	},

  data() {
    return {
			render: false,

      data: {},

			fields: [
				{name:'chest', label: $LanguageHelper.translate('_TOP_WIDTH_')},
				{name:'stomach', label: $LanguageHelper.translate('_STOMACH_')},
				{name:'waist', label: $LanguageHelper.translate('_WAIST_')},
				{name:'belly', label: $LanguageHelper.translate('_BELLY_')},
				{name:'hips', label: $LanguageHelper.translate('_HIPS_')},
				{name:'thighs_left', label: $LanguageHelper.translate('_LEFT_THIGH_')},
				{name:'thighs_right', label: $LanguageHelper.translate('_RIGHT_THIGH_')},
				{name:'knees_left', label: $LanguageHelper.translate('_LEFT_KNEE_')},
				{name:'knees_right', label: $LanguageHelper.translate('_RIGHT_KNEE_')},
				{name:'calves_left', label: $LanguageHelper.translate('_LEFT_CALF_')},
				{name:'calves_right', label: $LanguageHelper.translate('_RIGHT_CALF_')},
				{name:'arms_left', label: $LanguageHelper.translate('_LEFT_ARM_')},
				{name:'arms_right', label: $LanguageHelper.translate('_RIGHT_ARM_')},
			]
    }
  },

  methods: {
		paginate(page) {
			this.getData(page);
		},

    _fetched(data) {
      if(data.status == 'OK') {
				this.data = data.resultset;
				this.render = true;
      }
    },

    getData(page = null) {
			let data =  {idc: this.idc, id_appuser: this.id_customer};
			if(this.manage) data['id_appuser'] = this.id_customer;
			if(page) data['page'] = page;

			if(this.manage) $AjaxHelper.doRequest('gym', 'getMeasurementCardUserForAdmin', data, {callback: this._fetched});
			else $AjaxHelper.doRequest('gym', 'getMeasurementCardUser', data, {callback: this._fetched});
    }
  },

  created() {
		this.getData();
  }
};
</script>

<style scoped>
table {
	font-size: 14px;
	border-collapse: collapse;
}

table td {
	border: 1px solid #E7E7E7;
	padding: 1px;
	padding-right: 5px;
}

table td.first {
	padding-left: 5px;
}

table tr.total td {
	padding-top: 15px;
}

.wrapperPeriod table td {
	border: none;
}

.wrapperPeriod table td.left {
	padding-left: 15px;
}

.wrapperPeriod table td.right {
	padding-right: 15px;
}

.wrapperPeriod table td.arrow a {
	color: rgba(0, 0, 0 , 0.54)!important;
}
</style>
