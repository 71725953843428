<template>
	<popup
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<!-- Search form -->
		<search v-if="searchEnabled && admin" :enabled="searchEnabled" @searched="searched" :placeholder="$LanguageHelper.translate('_ADD_CUSTOMER_')"/>

		<!-- search result -->
		<f7-card v-if="users.length > 0">
			<f7-card-content>
				<f7-block class="marginTop5 marginBottom5">
					<div v-for="(item, index) in users" :key="index">
						<f7-link @click.prevent="addUser(item.id_user)">{{$FunctionsHelper.specialChars(item.firstname)}} {{$FunctionsHelper.specialChars(item.lastname)}} ({{item.email}})</f7-link>
					</div>
				</f7-block>
			</f7-card-content>
		</f7-card>

		<!-- clicked result -->
		<f7-card v-if="user">
			<f7-card-content>
				<f7-block class="noMarginTop noMarginBottom">
					<div class="item">{{user}}</div>
				</f7-block>
			</f7-card-content>
		</f7-card>

		<list-form :params="form" @submitted="submitted" @changed="changed" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import Search from '../components/smart/search.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm,
		'search': Search,
	},

	props: {
		idc: {required: true},
    admin: {required: false},
		wodData: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,
			searchEnabled: true,
			user: '',
			userID: null,
			users: [],
      title: this.admin ? $LanguageHelper.translate('_LOG_ATHLETE_RESULT_') : $LanguageHelper.translate('_LOG_YOUR_RESULT_'),
			form: {
				fields: {
					id_appuser: {type: 'input', label: '_NAME_ATHLETE_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					gender: {type: 'select', title: '_GENDER_', render: false,
            options: {
              m: {name: 'm', value: 'm', label: '_MALE_', selected: true},
              v: {name: 'v', value: 'v', label: '_FEMALE_', selected: false},
            }
          },

					id_wod_ranking_category: {type: 'select', title: '_RANKING_CATEGORY_', render: false, options: []},
					minutes: {type: 'input', label: '_TIME_MINUTES_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					seconds: {type: 'input', label: '_TIME_SECONDS_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					roundReps: {type: 'input', label: 'Round/Reps', placeholder: '_ENTER_HERE_', render: false, value: ''},
					weight: {type: 'input', label: 'Weight (kg)', placeholder: '_ENTER_HERE_', render: false, value: ''},
					distance: {type: 'input', label: 'Distance (meters)', placeholder: '_ENTER_HERE_', render: false, value: ''},
					reps: {type: 'input', label: 'Reps', placeholder: '_ENTER_HERE_', render: false, value: ''},
					rx_or_scaled: {type: 'select', title: '_RX_OR_SCALED_', render: false, options: []},
					weight_scaled: {type: 'input', label: 'Scaled Weight (kg)', placeholder: '_ENTER_HERE_', render: true, value: ''},
					comments: {type: 'textarea', label: '_COMMENTS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					youtube: {type: 'input', label: '_YOUTUBE_URL_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					removeAthleteResult: {type: 'description', text: '_REMOVE_ATHLETE_RESULT_TXT_', render: false},
					deleteDay: {name:'deleteDay', type: 'button', title: '_DELETE_BUTTON_DAY_', render: false, value: ''},
					deleteAll: {name:'deleteAll', type: 'button', title: '_DELETE_BUTTON_ALL_', render: false, value: ''},
				},
				values: {},
				submit: {text: '_SAVE_', render: true},
			}
		};
  },

	methods: {
		changed(field, option) {
			if(field.name == 'deleteDay') {
				var paramsToSend = {idc: this.idc, id_appuser: field.value, command: 'remove-result', admin: 1, id_wod_whiteboard: this.wodData.wod.id_wod_whiteboard};
				$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('WOD', 'logResultForAdmin', Object.assign(paramsToSend), {callback: this._logResult})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
			}

			if(field.name == 'deleteAll') {
				var paramsToSend = {idc: this.idc, id_appuser: field.value, command: 'remove-result-overall', admin: 1, id_wod_whiteboard: this.wodData.wod.id_wod_whiteboard};
				$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('WOD', 'logResultForAdmin', Object.assign(paramsToSend), {callback: this._logResult})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
			}
		},

		submitted() {
			var data = JSON.parse(JSON.stringify(this.form.values));
			if(this.userID)  {
				data['id_appuser'] = this.userID;
			} else {
				data['id_appuser'] = $UserHelper.getUser().id_user;
			}

			this.logResult(data);
		},

		searched(val) {
			// Empty current list
			this.users = [];

			// Search for users
			if(val.length > 0) $AjaxHelper.doRequest('Gym', 'getUsersByName', {name: val, idc: this.idc}, {callback: this._usersInit});
		},

		//autocomplete on name
		addUser(val) {
			$AjaxHelper.doRequest('WOD', 'getUserWhiteboardResultForAdmin', {idc: this.idc, id_appuser: val, id_wod_whiteboard: this.wodData.wod.id_wod_whiteboard}, {callback: this._whiteboardResultAdmin});
		},

		//get users
    _usersInit(data) {
      if(data.resultset) this.users = data.resultset;
    },

		_logResult(data) {
			if(data.status == 'OK') {
				this.opened = false;
				this.$emit('resultSaved', this.wodData.wod.id_wod_whiteboard);
			}
		},

		_whiteboardResultAdmin(data) {
			if(data.status == 'OK') {
				this.users = [];
				this.user = '';
				if(data.userData) {
					this.userID = data.userData.id_appuser;
					this.user = data.userData.name;

					//set user specific fields
					if(data.userData.number_results_user > 0) {
						this.form.fields.removeAthleteResult.render = true;
						this.form.fields.deleteDay.render = true;
						this.form.fields.deleteAll.render = true;
						this.form.fields.deleteDay.value = data.userData.id_appuser;
						this.form.fields.deleteAll.value = data.userData.id_appuser;

					}
				}
			}
		},

		logResult(data) {
			if(this.admin) $AjaxHelper.doRequest('WOD', 'logResultForAdmin', Object.assign({idc: this.idc, command: 'log-result', admin: 1, id_wod_whiteboard: this.wodData.wod.id_wod_whiteboard}, data), {callback: this._logResult});
			else $AjaxHelper.doRequest('WOD', 'logResult', Object.assign({idc: this.idc, page: 'workout', command: 'log-result', admin: 0, id_wod_whiteboard: this.wodData.wod.id_wod_whiteboard}, data), {callback: this._logResult});
		},
	},

	created() {
		if(!this.wodData.userData.gender && !this.admin) this.form.fields.gender.render = true;
		if(this.wodData.ranking_categories && this.wodData.ranking_categories.length) {
			this.form.fields.id_wod_ranking_category.render = true;
			this.wodData.ranking_categories.forEach((item) => {
				this.form.fields.id_wod_ranking_category.options.push({
					name: item.name,
					selected: item.selected == 1 ? true : false,
					label: item.name,
					value: item.id_wod_custom_ranking_category,
				});
			});
		}
		if(this.wodData.wod.code == 1) {
			this.form.fields.minutes.render = true;
			this.form.fields.seconds.render = true;
		}
		if(this.wodData.wod.code == 2) this.form.fields.roundReps.render = true;
		if(this.wodData.wod.code == 3) this.form.fields.weight.render = true;
		if(this.wodData.wod.code == 4) this.form.fields.distance.render = true;
		if(this.wodData.wod.code == 5) this.form.fields.reps.render = true;
		if(this.wodData.wod && (this.wodData.wod.fundamental == 1 || this.wodData.wod.rx == 1)) {
			this.form.fields.rx_or_scaled.options.push({name: 'scaled', selected: true, label: 'Scaled', value: 'scaled'});

			if(this.wodData.wod.fundamental == 1) this.form.fields.rx_or_scaled.options.push({name: 'fundamental', selected: false, label: 'Fundamental', value: 'fundamental'});

			if(this.wodData.wod.rx == 1) this.form.fields.rx_or_scaled.options.push({name: 'rx', selected: false, label: 'RX', value: 'rx'});

			this.form.fields.rx_or_scaled.render = true;
		}
		if(this.wodData.wod.athlete_youtube_url_required == 1) this.form.fields.youtube.render = true;
	}
};
</script>
