<template>
	<popup
		:title="$LanguageHelper.translate('_BIRTHDAYS_TITLE_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
		>

		<f7-list inset>
			<f7-list-item v-for="(customer, key) in customers" :key="key" @click="$emit('customer-clicked', customer)" link="#">
				<div>{{$FunctionsHelper.specialChars(customer.name)}} ({{customer.email}})</div>
			</f7-list-item>
		</f7-list>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import AccountForm from '../components/account-form.vue';

export default {
	components: {
    'popup': Popup,
    'account-form': AccountForm,
	},

	props: {
		customers: {required: true},
  },

	data() {
    return {
			opened: false,
    };
  }
};
</script>
