<template>
	<page :title="$LanguageHelper.translate('_UPLOAD_IMAGE_')" @initiated="go">
		<form v-if="render">
		<div class="pageContent">
			<f7-card>
				<f7-card-header>{{$LanguageHelper.translate('_PROFILE_IMAGE_')}}</f7-card-header>
				<f7-card-content>
					<div>{{$LanguageHelper.translate('_UPLOAD_PROFILE_IMAGE_DESCRIPTION_')}}</div>

					<div v-if="image" class="image">
						<img :src="image.imageURL" />
					</div>
				</f7-card-content>

				<f7-block strong>
					<f7-button @click.prevent="imageUploadPopups.image=true" raised fill small>{{$LanguageHelper.translate('_UPLOAD_IMAGE_')}}</f7-button>
					
					<image-upload v-if="imageUploadPopups.image"
						:title="$LanguageHelper.translate('_UPLOAD_IMAGE_')"
						:currentImageURL="image.imageURL"
						:name="$UserHelper.getID().toString()"
						type="userProfile"
						requestMethod="handleFileUser"
						@close="imageUploadPopups.image=false"
						@uploadDone="(url) => changed(url, image)"
						@removeFileDone="changed('', image)"
            :removeFileMethod="removeFileMethod"
					/>
				</f7-block>
		   </f7-card>
     </div>
   </form>
 </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import ImageUpload from '../components/smart/image-upload.vue';
import { EventBus } from '../js/eventBus.js';

export default {
  components: {
    'page': Page,
    'image-upload': ImageUpload
  },

  data() {
    return {
      render: false,
      image: {},
      imageUploadPopups: {
				image: false
			},
      removeFileMethod: 'removeFileUser',
    };
  },

  methods: {
		changed(url, image) {
			image.imageURL = url;

			// Emit global event so the side panel can update the user profile image!
			EventBus.$emit('user-profile-image-changed', url);
		},

    _initImage(data) {
      if(data.status == "OK") {
        if(data.resultset.imageURL) {
          this.image = data.resultset;
        }
        this.render = true;
      }
    },

		go() {
			$AjaxHelper.doRequest('user', 'loadProfileImage', {}, {callback: this._initImage});
		}
  }
};
</script>
