<template>
  <popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<f7-card v-if="render" inset>
      <f7-card-content v-if="message">
        <f7-block class="marginTop10">
          <f7-block-title>{{$FunctionsHelper.specialChars(message.name_sender)}}</f7-block-title>
          <f7-block-header>
            <div>{{message.created_on}}</div>
            <div>{{$FunctionsHelper.specialChars(message.subject)}}</div>
          </f7-block-header>

						<div class="messageContent">
							<p v-html="message.message" />
						</div>

           <f7-block-footer></f7-block-footer>
         </f7-block>
      </f7-card-content>

      <f7-block strong>
      	<f7-button @click.prevent="remove" raised small fill>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
      </f7-block>
    </f7-card>
  </popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
  components: {
    'popup': Popup,
  },

	props: {
    id: {required: true},
    close: {required: false, default: false},
	},

  data() {
    return {
      render: false,
      opened: false,
      title: '',
      message: [],
    }
  },

  methods: {
    remove() {
      $AjaxHelper.doRequest('pushMessage', 'removeMessage', {idc: this.idc, id: this.id}, {callback: this._removeMessage});
    },

    _removeMessage(data) {
      if(data.status == 'OK')
      this.$emit('removed');
      this.opened = false;
    },

    _message(data) {
      if(data.resultset) {
        this.message = data.resultset;
        this.title = this.message.name_sender;
        this.render = true;

				// Add needed atrributes and classnames so links will open correctly
				setTimeout(() => {$('.messageContent').find('a').attr('target', $Config.app.onDevice ? '_system' : '_blank').addClass('external link theme')}, 10);
      }
    }
  },

	watch: {
		close(val) {
			// If my id is destroyed, close me!
			if(val && this.opened) {
				this.opened = false;
			}
		}
	},

  created() {
    $AjaxHelper.doRequest('pushMessage', 'getMessage', {idc: this.idc, id: this.id}, {callback: this._message});
  }
};
</script>
