<template>
	<page
		class="home"
		:class="$Config.apps[$Config.app.currentApp].logoCompany ? 'topImage' : ''"
		:title="title"
		:back="false"
		>

		<template v-if="render">
			<home-admin v-if="page == 'home-admin'" :pageData=pageData :idc=companyID @changed="initWithTimeout" />
			<home-user v-else :pageData=pageData />
		</template>
	</page>
</template>

<script>
import NavBarPage from '../components/smart/navbar-page.vue';
import Page from '../components/smart/page.vue';
import HomeAdmin from '../components/home-admin.vue';
import HomeUser from '../components/home-user.vue';

export default {
  components: {
    'navbar-page': NavBarPage,
    'page': Page,
		'home-admin': HomeAdmin,
    'home-user': HomeUser
  },

	props: {
		idc: {required: false},
  },

	data() {
    return {
			render: false,
			page: 'home-user', // Options = home-admin, home-user
			pageData: {},
			companyID: null,
			title: $Config.apps[$Config.app.currentApp].logoNavbar ? '' : $Config.apps[$Config.app.currentApp].displayName,
    };
  },

	methods: {
		initWithTimeout() {
			setTimeout(() => this.init(), 100);
		},

		init() {
			this.render = false;
			$AjaxHelper.doRequest('gym', 'whichPage', this.idc > 0 ? {idc: this.idc} : {}, {callback: (data) => {
				if(data.status == 'OK') {
					this.page = data.page;
					this.pageData = data.pageData;

					this.companyID = this.idc && this.idc > 0 ? this.idc : this.pageData.companies.length > 0 ? this.pageData.companies[0].idc : null;

					this.render = true;
				}
			}});
		},
	},

	created() {
		this.init();
	}
};
</script>
