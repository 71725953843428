<template>
  <f7-popup
		:opened="opened"
		:id="`popup_${_uid}`"
		:close-by-backdrop-click="false"
		class="vuePopup"
		>

    <f7-view>
			<f7-page v-if="visible"
				:id="`ref_${_uid}`"
				:page-content="false"
				:infinite-preloader="false"
				:infinite="infinite"
        :backgroundImage="backgroundImage"
				>

				<!-- Navbar here -->
        <navbar-popup
					:uid="_uid"
					:title="title"
					:search="searchEnabled"
					:filter="filterEnabled"
					:sort-options="sortOptionsData"
					:opened="opened"
					@searched="searched"
					@close="close"
					@sort-selected="sortSelected"
					@show-filters-clicked="showFiltersClicked"
				/>

				<!-- Floating action buttons -->
				<fab v-if="fab" :fab="fab" @fab-clicked="fabClicked"></fab>

        <!-- render toolbar with tabs if present -->
        <template v-if="toolbar">
          <f7-toolbar tabbar labels position="bottom">
						<template v-for="(tab, key) in toolbar.tabs" :key="key">
							<f7-link v-if="tab.render" v-show="!tab.hide"
								:tab-link="'#tab' + key"
								:text="$LanguageHelper.translate(tab.text)"
								:tab-link-active="tab.active"
								:icon="tab.icon"
								@click="tabClicked(tab)"
							/>
						</template>
          </f7-toolbar>

          <!-- Tab contents-->
          <f7-tabs>
						<template v-for="(tab, key) in toolbar.tabs" :key="key">
							<f7-tab :id="'tab' + key" :tab-active="tab.active" v-if="tab.render">
								<f7-page
									:page-content="false"
									:infinite="tab.infinite ? true : false"
									:infinite-preloader="false"
									:search="tab.search ? true: false"
									>

									<div class="page-content infinite-scroll-content" :class="tab.class ? tab.class: ''" @infinite="infiniteScrollPageEnd">
										<slot :name="key"></slot>
									</div>

									<!-- Floating action buttons per tab page -->
									<fab v-if="tab.fab" :fab="tab.fab" @fab-clicked="fabClicked"></fab>
								</f7-page>
							</f7-tab>
						</template>
          </f7-tabs>
        </template>

        <!-- render regular plain slot contents if there is no toolbar -->
        <template v-else>
					<div class="page-content infinite-scroll-content" :class="pageContentClass" :style="backgroundImage ? 'background-image:url(' + backgroundImage + ');' : ''" @infinite="infiniteScrollPageEnd">
						<slot></slot>
					</div>
				</template>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
import { EventBus } from '../../js/eventBus.js';
import pageAndPopup from '../../js/mixins/pageAndPopup';
import NavBarPopup from './navbar-popup.vue';

export default {
	mixins: [pageAndPopup],

  components: {
    'navbar-popup': NavBarPopup,
  },

  props: {
		opened: {type: Boolean, required: false, default: false},
    backgroundImage: {type: String, required: false},
    pageContentClass: {type: String, required: false},
  },

	methods: {
		close(id) {
			// Close the popup by it's unique id
			const popup = $f7.popup.get(`#popup_${this._uid}`);
			
			if(popup) popup.close();

			// F7 has a bug where the overlay gets removed even if there is a popup still open
			$ViewHelper.fixF7PopupBackdropBug();

			// Stuff we need to do to keep the native history stack in check for the back button bahaviour
			if(!$Config.app.onDevice) {
				// When an id is present, popup was not closed by back button and we need to prevent the back function from being triggered
				if(id) $ViewHelper.router.nativeHistory.back(true /* Prevents routing */);
				
				// A popup close does not trigger the page:afterin event, so we need to reset the reloadFromPageback manually to false
				$ViewHelper.router.nativeHistory.reloadFromPageback = false;
			}

			// Emit close event after animation is done and F7 closed event is called!
			popup.on('closed', () => {
				this.$emit('close');
			});
		}
	},

	created() {
		// Listen for global popup close event (back-button was clicked)
		EventBus.$on('popup-close', (id) => {
			if(id == `popup_${this._uid}`) this.close();
		});
	}
};
</script>
