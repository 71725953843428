<template>
  <page
		:title="$LanguageHelper.translate('_ACCOUNTANCY_')"
		:toolbar="toolbar"
		@sort-selected="setSortSelected"
		@searched="setSearched"
		@infinite-scroll-page-end="pageEndReached=true"
		@tab-clicked="pageEndReached=false"
		@initiated="go"
	>

		<template #revenue v-if="toolbar.tabs.revenue && toolbar.tabs.revenue.active">
			<tab-revenue
				:idc="idc"
				:fab="fab.revenue"
				:sort-selected="sortSelected"
				:searched="searched"
				:page-end-reached="pageEndReached"
				@page-end-loaded="pageEndReached=false"
			/>
		</template>

		<template #outstanding_invoices v-if="toolbar.tabs.outstanding_invoices && toolbar.tabs.outstanding_invoices.active">
			<tab-outstanding-invoices :idc="idc" :page-end-reached="pageEndReached" @page-end-loaded="pageEndReached=false" />
		</template>

		<template #transaction_costs v-if="toolbar.tabs.transaction_costs && toolbar.tabs.transaction_costs.active">
			<tab-transaction-costs
				:idc="idc"
				:fab="fab.transaction_costs"
				:page-end-reached="pageEndReached"
				@page-end-loaded="pageEndReached=false"
			/>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabRevenue from './tab-revenue.vue';
import TabOutstandingInvoices from './tab-outstanding-invoices.vue';
import TabTransactionCosts from './tab-transaction-costs.vue';

export default {
  components: {
    'page': Page,
		'tab-revenue': TabRevenue,
		'tab-outstanding-invoices': TabOutstandingInvoices,
		'tab-transaction-costs': TabTransactionCosts
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			sortOptions: null,
			sortSelected: 'date_sale_desc',
			searched: '',
			fabClicked: null,
			pageEndReached: false,

			toolbar: {
				tabs: {
					revenue: {text: $LanguageHelper.translate('_REVENUE_'), icon: 'icon ionLogoEuro', infinite: true, search: true, sortOptions: null, render: $UserHelper.hasAccess('payments', 'financial', this.idc), active: false},
					outstanding_invoices: {text: $LanguageHelper.translate('_UNPAID_'), icon: 'icon ionSad', infinite: true, render: $UserHelper.hasAccess('payments', 'financial', this.idc), active: false},
					transaction_costs: {text: $LanguageHelper.translate('_COSTS_'), icon: 'icon ionPaper', infinite: true, render: $UserHelper.hasAccess('payments', 'costs', this.idc), active: false}
				}
			},

			fab: {
				revenue: {
					position: 'right-bottom',
					buttons: {
						export: {name:'export', label: $LanguageHelper.translate('_EXPORT_SELECTED_PERIOD_'), icon: 'icon ionDownload', render: $UserHelper.hasAccess('payments', 'financial', this.idc) && !$Config.app.onDevice, clicked: false},
						download: {name:'download', label: $LanguageHelper.translate('_DOWNLOAD_INVOICES_'), icon: 'icon iconInvoices', render: $UserHelper.hasAccess('payments', 'financial', this.idc) && !$Config.app.onDevice, clicked: false},
					}
				},

				transaction_costs: {
					position: 'right-bottom',
					buttons: {
						download: {name:'download', label: $LanguageHelper.translate('_DOWNLOAD_INVOICES_'), icon: 'icon iconInvoices', render: $UserHelper.hasAccess('payments', 'costs', this.idc) && !$Config.app.onDevice, clicked: false},
						export: {name:'export', label: $LanguageHelper.translate('_EXPORT_SELECTED_PERIOD_'), icon: 'icon ionDownload', render: $UserHelper.hasAccess('payments', 'costs', this.idc) && !$Config.app.onDevice, clicked: false},
					}
				},
			}
    };
  },

	computed: {
    fabExportClicked() {
			return this.fab.buttons.export.clicked;
		},
  },

	methods: {
		setSearched(val) {
			this.searched = val;
		},

		setSortSelected(val) {
			this.sortSelected = val;
		},

		go() {
			// Set floating action buttons per tab
			this.toolbar.tabs.revenue.fab = this.fab.revenue;
			this.toolbar.tabs.transaction_costs.fab = this.fab.transaction_costs;

			// Set sort options for the revenue tab
			this.toolbar.tabs.revenue.sortOptions = {
				title: '_SORT_AND_FILTER_',
				options: {
					date_sale_desc: {value: 'date_sale_desc', label: '_DATE_DESC_', selected: true},
					date_sale_asc: {value: 'date_sale_asc', label: '_DATE_ASC_', selected: false},
					name_asc: {value: 'name_asc', label: '_SORT_NAME_CUSTOMER_A_Z_', selected: false},
					name_desc: {value: 'name_desc', label: '_SORT_NAME_CUSTOMER_Z_A_', selected: false},
				}
			};

			// Activate correct tab
			if(this.toolbar.tabs.revenue.render) this.toolbar.tabs.revenue.active = true;
			else if(this.toolbar.tabs.outstanding_invoices.render) this.toolbar.tabs.outstanding_invoices.active = true;
			else if(this.toolbar.tabs.transaction_costs.render) this.toolbar.tabs.transaction_costs.active = true;
		}
	}
};
</script>
