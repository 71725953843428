<template>
  <page :title="$LanguageHelper.translate('_SEND_PUSHMESSAGE_')">
		<pushmessage-sender :idc="idc" />
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import PushMessageSender from '../components/pushmessage-sender.vue';

export default {
  components: {
    'page': Page,
    'pushmessage-sender': PushMessageSender,
  },

	props: {
		idc: {required: true},
	}
};
</script>
