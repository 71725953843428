<template>
  <div id="dashboard">
    <!-- Render fixed top -->
    <fixed-top v-show="render" id="fixed" :resized="resized">
      <list-form :params="form" @changed="changed" />
    </fixed-top>

		<template v-if="render">
	    <f7-card inset>
	      <f7-card-content>
	        <!-- Render filter button -->
	        <div v-if="hasCustomFilter" class="filterButton">
	          <f7-link @click="filtersPopupOpened=true" class="marginRight10"><f7-icon class="icon ionFunnel"></f7-icon></f7-link>

						<!-- Show selected filters to user -->
						<!--<f7-chip class="marginBottom10 chipDashboard" v-if="selectedFilterChips.length >0" v-for="(item, index) in selectedFilterChips" :key="index" :text="item.name"></f7-chip>-->
	        </div>

	        <!--Render action buttons-->
	        <div v-if="buttons.length > 0" class="dashboardButtons">
	          <template v-for="button in buttons" :key="button.type">
							<div v-if="button.type == 'chart'" @click="buttonsClicked(button.action, button.type)"><f7-icon :class="button.className"></f7-icon></div>
							<div v-else @click="buttonsClicked(button.action, button.type)" :class="`marginTop3 ${button.className}`">{{button.name}}</div>
						</template>
	        </div>

	        <!-- Render chart-->
	        <chart :params="data" :render="renderChart"></chart>
	      </f7-card-content>
	    </f7-card>

	    <!-- Render summary blocks-->
	    <div id="summaryList" v-if="data.data.summary" >
	      <template v-for="(item, key) in data.data.summary">
	        <template v-if="item.type && item.type == 'extrainfo'">
	          <f7-link @click="summaryItemClicked(item)" :key="key">
	            <f7-card class="summaryInner Width185">
	              <f7-card-content class="padding10">
	                <div class="content">
	                  <div>{{$FunctionsHelper.specialChars(item.title)}}</div>
	                  <div>{{$FunctionsHelper.specialChars(item.content)}}</div>
	                </div>
	                <div class="ion-ios-arrow-forward"></div>
	              </f7-card-content>
	            </f7-card>
	          </f7-link>
	        </template>

	        <template v-else>
	          <f7-card class="summaryInner Width185" :key="key">
	            <f7-card-content class="padding10">
	              <div>{{$FunctionsHelper.specialChars(item.title)}}</div>
	              <div>{{$FunctionsHelper.specialChars(item.content)}}</div>
	            </f7-card-content>
	          </f7-card>
	       </template>
	     </template>
		 </div>

	    <!-- Extra info popup -->
	    <manage-dashboard-extra-info v-if="extraInfoPopupOpened"
		    :idc="idc"
		    :filters="filters"
		    :summaryItem="summaryItem"
		    @changed="fetchData"
		    @close="extraInfoPopupOpened=false"
	    />

	    <!-- Filters popup-->
	    <manage-dashboard-filters v-if="filtersPopupOpened"
		    :idc="idc"
		    :selectFilters="selectFilters"
		    :filters="filters"
		    @filtersSelected="fetchData"
		    @close="filtersPopupOpened=false"
	    />
		</template>
	</div>
</template>

<script>
import DashboardMixin from '../js/mixins/dashboard';
import ListForm from '../components/smart/list-form';
import FixedTop from '../components/smart/fixed-top';
import Chart from '../components/chart';
import ManageDashboardExtraInfo from '../popups/manage-dashboard-extra-info';
import ManageDashboardFilters from '../popups/manage-dashboard-filters';

export default {
	components: {
    'list-form': ListForm,
    'fixed-top': FixedTop,
    'chart': Chart,
    'manage-dashboard-extra-info': ManageDashboardExtraInfo,
    'manage-dashboard-filters': ManageDashboardFilters,
  },

	mixins: [DashboardMixin],

  props: {
    idc: {required: true},
    tab: {type: String, required: true},
    initData: {required: false},
  },

  data() {
    return {
			render: false,
			renderChart: false,
			resized: false,
			filtersPopupOpened: false,
			extraInfoPopupOpened: false,
			summaryItem: null, // If an item was clicked we will store the item to give as param when we open the extra info popup

			form: {
				fields: {
          select_dashboard: {type: 'select', name: 'select_dashboard', title: '_PICK_DASHBOARD_', render: false, options: []},
          date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: false,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				},

				values: {},
			},

			selectedFilterChips: [],

			buttons: [] // Array with action buttons => Example (bar, line, week, month, day)
		};
  },

	computed: {
		hasCustomFilter() {
			return this.data.filters.customConditions && this.data.filters.customConditions.length != 0;
		},

		selectFilters() {
			// Filters selected, comes from back-end and are updated when user filters again and submits filters popup
			return this.data.filters.customConditions;
		},

		selectedDashboard() {
			return (Object.values(this.form.fields.select_dashboard.options).find(option => option.selected)).value;
		},
	},

	methods: {
		// Form events (dashboard selected or date picked in date range)
		changed(field, item) {
			let fetchData = true;

			// (Re)set the request type to dashboard if a user has made a change in the form
			this.filters.type = 'dashboard';

			// Reset the date range
			this.filters.dateRange = [];

			// User has selected another dashboard, set the new dashboard name
			if(field.name == 'select_dashboard') {
				// Reset the counter and hide the date fields so new values can be set.
				this.form.fields.date.cnt = 0;
				this.form.fields.date.render = false;

				// A new dashboard is selected so set filters we send to back-end to default again
				this.setFiltersDefaults(this.tab);

				// Set the selected dashboard value
				this.filters.dashboard = item.value;
			}

			// User has selected a date input value, set it's value
      if(field.name == 'dateRange') {
				// Prevent server request if change was emitted while initiating date values!
				if(field.cnt <= 2) fetchData = false;

				// Set date range values
				this.filters.dateRange = [{
					start: this.form.fields.date.fields.date_start.value,
					end: this.form.fields.date.fields.date_end.value
				}];
      }

			// Fetch the new data
			if(fetchData) this.fetchData();
		},

		// Action button clicked
		buttonsClicked(action, type) {
			// Display mode button clicked (month, week or day)
			if(type == 'display_mode') {
				this.filters.modes = action;
			}
			// Chart type button clicked (bar, line or pie)
			else if(type == 'chart') {
				this.filters.chart = action;
			}

			this.fetchData();
		},

		summaryItemClicked(item) {
			// Set the summaryItem value
			this.summaryItem = item;

			// Open the popup
			this.extraInfoPopupOpened = true;
		},

		build() {
			// Store the selected filter chips so user can see which filters he used
			this.storeSelectedFilterChips();

			this.renderDashboardList();
			this.renderDateRangeInputs();
			this.renderButtons();

			// Render and trigger resize fixed top!
			this.resized = true; // Used for fixed top to determain height!
			this.renderChart = true;

			// Wait for fixed top to be resized so we don't get a ugly jumpy page load
			setTimeout(() => this.render = true, 100);
		},

		renderDashboardList() {
			// Empty the options first
			this.form.fields.select_dashboard.options = [];

			// Now store the options and render the dashboard options
			if(this.data.filters.tabs[this.tab]) {
				Object.values(this.data.filters.tabs[this.tab].dashboards).forEach((dashboard) => {
					this.form.fields.select_dashboard.options.push({
            value: dashboard.value,
  					selected: dashboard.selected == 1,
  					label: dashboard.name,
          });

					// Set the dashboard name in filters
					if(dashboard.selected == 1) this.filters.dashboard = dashboard.value;
				});
			}

			// Render only if it has options
			this.form.fields.select_dashboard.render = this.form.fields.select_dashboard.options.length > 0;
    },

		renderDateRangeInputs() {
			if(this.data.filters.periods.dates && this.data.filters.periods.dates.length > 0) {
				// Set the date end values
        this.form.fields.date.fields.date_start.value = this.data.filters.periods.dates[0].start;
        this.form.fields.date.fields.date_end.value = this.data.filters.periods.dates[0].end;

				// Render the date field
				this.form.fields.date.render = true;
      }
    },

		renderButtons() {
			this.buttons = [];
			Object.values(this.data.filters.buttons).forEach((button) => {
				this.buttons.push({name: button.name, action: button.action, className: `${button.class}${(button.selected && button.selected == 1 ? ' active' : '')}`, type: button.type});
			});
		},

		storeSelectedFilterChips() {
			this.selectedFilterChips = [];

			$.each(this.selectFilters, (key, filter) => {
				let chipValue = '';

				if(filter.type != 'multi_checkbox') {
					if(filter.options) {
						filter.options.forEach((option) => {
							if(filter.value == option.id) chipValue = option.name;
						});
	        }

					if(filter.fields) {
						let name = '';
						let value = '';

						filter.fields.forEach((field) => {
							field.options.forEach((option) => {
								if(option.id && option.id == field.value) {
									name = option.name;
								} else if(!option.id) {
									value = field.value;
								}

								chipValue = `${value} ${name}`;
							});
						});
					}

					// Push the selected filter
					if(filter.selected == 1) {
						this.selectedFilterChips.push({
							name: `${filter.name}: ${chipValue}`
						});
					}
				}
			});
		},

		fetchData() {
			// Set the resized to false so fixed top will be triggered to resize again!
			this.resized = false;

			// Destroy the chart component
			this.renderChart = false;

			// Set the filter type back to dasboard
			this.filters.type = 'dashboard';

			// Fetch the data now
			$AjaxHelper.doRequest('dashboard', 'load', {idc: this.idc, filters: this.filters}, {callback: (data) => {
				if(data.resultset)  {
					// Store data and build dashboard
					this.data = data.resultset;
					this.build();
				}
			}});
		},
	},

	created() {
		// Set filter defaults we send to back-end!
		this.setFiltersDefaults(this.tab);

		// We received the init data (first call) so we don't have to do a back-end request anymore!
		if(this.initData) {
			this.data = this.initData;

			// Build me...
			this.build();

			// Let our parent know we are done so it can destroy the initData object!
			this.$emit('constructed');
		} else {
			// No cached (init) data ,so go fetch ...
			this.fetchData();
		}
	}
}
</script>

<style scoped>
.actionButton {
	color:#c8c7cc;
	-webkit-text-stroke: 0.4px;
	font-size: 20px;
	cursor:pointer;
	padding-left: 4px;
	border-radius: 3px;
	padding-right: 4px;
	transition: 0.7s;
	float:right;
}

.actionButton.active, .icon.ionFunnel {
  color: var(--btn-bg-color);
}

#summaryList {
  display: block;
  text-align: center;
  margin: none;
  position: relative;
  margin-bottom: 80px;
}

#summaryList .link {
  vertical-align: top;
}

.summaryInner {
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-align: left;
	border-left: 5px solid var(--theme-color);
	min-height: 68px;
}

.summaryInner .ion-ios-arrow-forward {
	float:right;
	color: var(--theme-color);
}

.dashboardButtons {
  height: 30px;
  padding-top: 10px;
}

.filterButton {
	height: 30px;
  padding-top: 10px;
	float: left;
	max-width:70%;
	cursor: pointer;
}

.chipDashboard {
	margin-right: 2px;
}

.content {
  float: left;
  margin-bottom: 16px;
  color: #212121;
  max-width: 155px;
}
</style>
