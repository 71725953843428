<template>
	<div>
	<f7-list v-if="subscribers.list && subscribers.list.length > 0" media-list inset class="wrapperSubscribers">
		<li class="toggleTitle">
			<f7-link @click="show=!show" class="theme link">{{subscribers.total}} {{title()}}</f7-link>
		</li>

		<li v-if="show && $UserHelper.hasAccess('pushmessage', 'send', idc)" class="paddingLeft15 paddingRight15 paddingBottom7">
			<f7-button fill small raised class="widthFull" @click="sendMessageOpened=true">{{$LanguageHelper.translate('_SEND_MESSAGE_')}}</f7-button>
		</li>

		<template v-for="(subscriber, key) in subscribers.list" :key="key">
			<f7-list-item v-if="show" class="subscribers" :class="key == 0 ? '' : ''">
				<article class="containerOuter">
					<section class="image">
						<img v-if="subscriber.imageURL" :src="subscriber.imageURL" class="customerImage" />
						<f7-icon v-else icon="ionPerson"></f7-icon>
					</section>

					<div class="containerInner">
						<section class="postDescription">{{$FunctionsHelper.specialChars(subscriber.name)}}<span v-if="subscriber.first" class="themeDetail smallTitle marginLeft5 theme"> {{$LanguageHelper.translate('_NEW_')}}</span></section>
						<section v-if="$UserHelper.hasAccess('users', 'show', idc)" class="postContent">
							<!-- Check present or absent -->
							<template v-if="type=='subscribed' && $UserHelper.hasAccess(`agenda_${id_agenda}`, 'removeSubscriptions', idc)">
								<a @click="toggleCheckPresent(subscriber)" class="theme link checkPresent" :class="subscriber.checked_present == 1 ? '' : 'absent'" :title="$LanguageHelper.translate(`${subscriber.checked_present == 1 ?  '_MARK_AS_ABSENT_' : '_MARK_AS_PRESENT_'}`)"><i class="icon ionCheckmarkCircle"></i></a>
							</template>

							<a @click="subscriber.opened=true" class="theme link" :class="type=='subscribed' ? 'action' : ''" :title="$LanguageHelper.translate('_SHOW_CUSTOMER_')"><i class="icon ionInformationCircle"></i></a>

							<template v-if="subscriber.temp_comment">
								<f7-link class="link theme action" :popover-open="`.openComment_${key}`" :title="$LanguageHelper.translate('_COMMENTS_')"><i class="icon ionWarning orange"></i></f7-link>
								<f7-popover :class="`openComment_${key}`">
									<div class="content">{{$FunctionsHelper.specialChars(subscriber.temp_comment)}}</div>
								</f7-popover>
							</template>

							<template v-if="(type=='subscribed' && $UserHelper.hasAccess(`agenda_${id_agenda}`, 'removeSubscriptions', idc)) || (type=='noShow' && $UserHelper.hasAccess(`agenda_${id_agenda}`, 'removeSubscriptions', idc)) || (type=='waiting' && $UserHelper.hasAccess(`agenda_${id_agenda}`, 'removeSubscriptionsWaitinglist', idc))">
								<f7-link class="link theme action floatRight" @click="unsubscribe(subscriber)" :title="$LanguageHelper.translate('_DELETE_')"><f7-icon class="icon ionTrash"></f7-icon></f7-link>
							</template>
						</section>
					</div>
				</article>

				<!-- Manage customer edit popup here, contents is only rendered when opened is true -->
				<manage-customer v-if="subscriber.opened"
					:idc="idc"
					:title="$FunctionsHelper.specialChars(subscriber.name)"
					:id_customer="subscriber.id_appuser"
					@edited="$emit('changed')"
					@close="subscriber.opened=false"
				/>

			</f7-list-item>
		</template>
	</f7-list>

	<!-- Manage push message popup here, contents is only rendered when opened is true -->
	<manage-pushmessage v-if="sendMessageOpened" :idc="idc" :selectedList="subscribers.list" @close="sendMessageOpened=false" />
</div>
</template>

<script>
import ManageCustomer from '../popups/manage-customer/index.vue';
import ManagePushMessage from '../popups/manage-pushmessage.vue';

export default {
	components: {
    'manage-customer': ManageCustomer,
    'manage-pushmessage': ManagePushMessage,
  },

	props: {
		idc: {required: true},
    id_agenda: {required: true},
    numberChanges: {required: true},
    type: {required: true, type: String}, // subscribed, waiting, noShow
    params: {required: true}, // Contains appointment data
	},

	data() {
    return {
			show: false,
			sendMessageOpened: false,

			subscribers: {
				total: 0,
				list: [],
			},

			subscribed: {
				toggleTitlePostfix: $LanguageHelper.translate('_SUBSCRIPTIONS_'),
			},

			waiting: {
				toggleTitlePostfix: $LanguageHelper.translate('_WAITING_'),
			},

			noShow: {
				toggleTitlePostfix: $LanguageHelper.translate('_NO_SHOWS_'),
			},
		};
	},

	methods: {

		title() {
			let postfix = this[this.type].toggleTitlePostfix;

			if(this.type == 'subscribed' && this.subscribers.total_checked_present > 0) {
				let percentage = Math.round(this.subscribers.total_checked_present / this.subscribers.total * 100)
				postfix += `, ${this.subscribers.total_checked_present} (${percentage}%) ${$LanguageHelper.translate('_PRESENT_')}`;
			}

			return postfix;
		},

		toggleCheckPresent(subscriber) {
			subscriber.checked_present = + /* + makes boolean integer */ !subscriber.checked_present;

			let toSend = Object.assign({
				idc: this.idc,
				id_agenda: this.id_agenda,
				id_appointment: this.params.id_appointment,
			}, subscriber);

			// Send request to server and emit the changed event when it comes back
			$AjaxHelper.doRequest('agenda', 'adminToggleCheckSubscriberPresent', toSend, {callback: (data) => {this.$emit('checkPresentToggled')}});
		},

		unsubscribe(subscriber) {
			subscriber.method = 'adminUnsubscribeUser';
			subscriber.type = 'appointment';
			subscriber.action = 'unsubscribe';

			// No show or waiting unsubscribe
			if(this.type == 'noShow' || this.type == 'waiting') {
				let dialogTitle = $LanguageHelper.translate('_QUESTION_REMOVE_NO_SHOW_');

				// Waiting type has a different method
				if(this.type == 'waiting') {
					dialogTitle = $LanguageHelper.translate('_NOTICE_');
					subscriber.method = 'adminUnsubscribeWaitinglist';
					subscriber.type = 'waitinglist';
				}

				// Set the remove no show
				if(this.type == 'noShow') {
					subscriber.remove_no_show = 1;
				}

				$ViewHelper.confirm({method: this.sendUnsubscribe, params: subscriber}, dialogTitle);
			}

			// Regular unsubscribe
			else {
				let buttons = [
					// Book credits back? Yes
          {text: $LanguageHelper.translate('_YES_'), onClick:() => {
						this.sendUnsubscribe(subscriber);
					}},

					// Book credits back? No
					{text: $LanguageHelper.translate('_NO_'), onClick: () => {
						// We don't want to give the subscriber his credits back, so flag no show!
            subscriber.no_show = 1;
            this.sendUnsubscribe(subscriber);
          }},

          {text: $LanguageHelper.translate('_CANCEL_'), onClick: () => {return false;}},
        ];

        $ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_UNSUBSCRIBE'), $LanguageHelper.translate('_NOTICE_ADMIN_UNSUBSCRIBE_APPOINTMENT_'));
			}
		},

		sendUnsubscribe(data) {
			let toSend = Object.assign({
				idc: this.idc,
				id_agenda: this.id_agenda,
				date_start_org: this.params.date_start,
				date_end_org: this.params.date_end,
				id: this.params.id_appointment,
				id_appointment: this.params.id_appointment,
			}, data);

			// Send request to server and emit the changed event when it comes back
			$AjaxHelper.doRequest('agenda', data.method, toSend, {callback: (data) => {this.$emit('changed')}});
		}
	},

	watch: {
		// Special number prop to enforce auto changes to the data
		numberChanges: {
			deep: true,
			immediate: true,
			 handler() {
				// Fill the list by type
				if(this.type == 'subscribed') {
					this.subscribers.total = this.params.subscriptions.total;
					this.subscribers.total_checked_present = this.params.subscriptions.total_checked_present;
		 			this.subscribers.list = this.params.subscriptions.members;
		 		}

		 		if(this.type == 'waiting') {
		 			this.subscribers.total = this.params.waitinglist.total;
		 			this.subscribers.list = this.params.waitinglist.members;
		 		}

		 		if(this.type == 'noShow') {
		 			this.subscribers.total = this.params.no_show.total;
		 			this.subscribers.list = this.params.no_show.members;
		 		}

				// Add new reactive opened prop
				this.subscribers.list.forEach((subscriber) => {
					subscriber.opened = false;
				});
			 },
		},
	}
};
</script>

<style>
.list .subscribers .item-inner {
	padding-top: 8px!important;
	padding-bottom: 8px!important;
}

.list .subscribers.noPaddingTop .item-inner {
	padding-top:0!important;
}

.list .subscribers .action {
	margin-left: 20px!important;
}

/** Last action icon no margin right **/
.list .subscribers .action:last-child {
  margin-right: 0px;
}
</style>

<style scoped>
.wrapperSubscribers {
	margin-top: -20px;
}

.wrapperSubscribers .checkPresent.absent {
	opacity: 0.5;
}

.wrapperSubscribers .listCheckedPresent {
	margin-left: 15px;
	margin-bottom: 10px;
}

.toggleTitle {
	padding-top: 7px;
	padding-left: 15px;
	padding-bottom: 10px;
}

.contentItem {
  margin-bottom: 10px;
}

.containerOuter {
  display: flex;
  align-items: stretch; /* tells boxes to stretch vertically (default value)  */
  overflow: hidden;
}

.image {
  display: flex; /* nested flexbox, to enable flex properties */
  flex-direction: column;
  width: 65px;
  box-sizing: border-box;
}

.containerInner {
  display: flex; /* nested flexbox */
  flex-direction: column;
  width: 100%;
	padding-left: 10px;
}

.postDescription {
  display: flex; /* nested flexbox */
  height: 20px; /* fixed height */
  box-sizing: border-box;
}

.postContent {
  display: flex; /* nested flexbox */
  flex-grow: 1; /* box takes all available space (stretch, basically) */
  box-sizing: border-box;
  height: 30px;
}

.popover .content {
	padding: 12px;
}

.image img, .image .icon {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  margin-top:4px;
  border: 1px solid #e7e7e7;
  vertical-align: middle;
}

.icon.ionPerson {
  text-align: center;
  font-size: 30px;
  color: #d7d7d7;
}
</style>
