<template>
  <page :title="$LanguageHelper.translate('_DASHBOARD_')" :toolbar="toolbar" @initiated="go">
		<template #financial v-if="toolbar.tabs.financial && toolbar.tabs.financial.active">
			<tab-financial :idc="idc" :initData="initData" @constructed="initData=null"></tab-financial>
		</template>

    <template #customers v-if="toolbar.tabs.customers && toolbar.tabs.customers.active">
			<tab-customers :idc="idc" :initData="initData" @constructed="initData=null"></tab-customers>
		</template>

		<template #agenda v-if="toolbar.tabs.agenda && toolbar.tabs.agenda.active">
			<tab-agenda :idc="idc" :initData="initData" @constructed="initData=null"></tab-agenda>
		</template>
	</page>
</template>

<script>
import DashboardMixin from '../../js/mixins/dashboard';
import Page from '../../components/smart/page.vue';
import TabFinancial from './tab-financial.vue';
import TabCustomers from './tab-customers.vue';
import TabAgenda from './tab-agenda.vue';

export default {
  components: {
    'page': Page,
    'tab-financial': TabFinancial,
    'tab-customers': TabCustomers,
    'tab-agenda': TabAgenda,
  },

	mixins: [DashboardMixin],

	props: {
		idc: {required: true},
	},

  data() {
		return {
			initData: null,

      toolbar: {
				tabs: {
          financial: {text: $LanguageHelper.translate('_FINANCIAL_'), icon: 'icon iconPayments', initData: null, active: false, render: false},
          customers: {text: $LanguageHelper.translate('_CUSTOMERS_'), icon: 'icon ionPeople', initData: null, active: false, render: false},
          agenda: {text: $LanguageHelper.translate('_AGENDA_'), name: 'agenda', icon: 'icon iconAgenda', initData: null, active: false, render: false},
			  }
      },
    };
  },

	methods: {
		go() {
			$AjaxHelper.doRequest('dashboard', 'load', {idc: this.idc, filters: this.filterDefaults}, {callback: (data) => {
				// Render the tabs!
				if(data.resultset && data.resultset.filters) {
					// Set the data form this call so we can pass it and dashboard component doesn't have to do the same call again.
					this.initData = data.resultset;

					// Activate and render the correct tab
					$.each(data.resultset.filters.tabs, (name, tab) => {
						// Activate the selected tab
					 	this.toolbar.tabs[name].active = tab.info.selected == 1;

						// Only render the tabs that user has access to (left out from back-end if not)
						this.toolbar.tabs[name].render = true;
				 });
				}
			}});
		}
	}
};
</script>
