<template>
	<popup :title="$LanguageHelper.translate('_LOGIN_NEW_ACCOUNT_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<f7-block>
			<div class="loginNewAccount">
				<div class="content">
					<form>
						<f7-card>
							<f7-card-header>{{$LanguageHelper.translate('_LOGIN_')}}</f7-card-header>
							<f7-list inset>
								<f7-list-input @input="formLogin.username = $event.target.value"
									type="text"
									autocomplete="off"
									clear-button
									:label="$LanguageHelper.translate('_YOUR_USERNAME_')"
									:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
								/>

								<f7-list-input @input="formLogin.pass = $event.target.value"
									type="password"
									autocomplete="off"
									clear-button
									:label="$LanguageHelper.translate('_PASSWORD_')"
									:placeholder="$LanguageHelper.translate('_ENTER_HERE_')"
									v-on:keypress.enter="loginSubmitted"
								/>
							</f7-list>

							<f7-block strong>
								<f7-button fill raised @click="loginSubmitted">{{$LanguageHelper.translate('_LOGIN_')}}</f7-button>
							</f7-block>

							<f7-block>
								<!-- Forgot password link and popup component  -->
								<div class="wrapperForgotPass">
									<f7-link class="theme forgotPass" @click="openForgotPassword=true">{{$LanguageHelper.translate('_FORGOT_PASSWORD_')}}?</f7-link>
									<forgot-password v-if="openForgotPassword" :title="$LanguageHelper.translate('_FORGOT_PASSWORD_')" @close="openForgotPassword=false" />
								</div>
							</f7-block>
						</f7-card>
					</form>
				</div>

				<div class="content marginTop30">
					<form>
						<f7-card>
							<f7-card-header>{{$LanguageHelper.translate('_NO_ACCOUNT_YET_')}}?</f7-card-header>

							<f7-block strong>
								<a class="button button-fill button-raised external" :href="newAccountURL" :target="$Config.app.onDevice ? '_system' : '_blank'">{{$LanguageHelper.translate('_CREATE_NEW_ACCOUNT_')}}</a>
							</f7-block>
						</f7-card>
					</form>
				</div>
			</div>
		</f7-block>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ForgotPassword from '../popups/forgot-password.vue'

export default {
	components: {
    'popup': Popup,
		'forgot-password': ForgotPassword
	},

	props: {
		idc: {required: false},
  },

	data() {
		return {
			opened: false,
			formLogin: {
				username: '',
				pass: '',
			},
			openForgotPassword: false,
		};
	},

	computed: {
		newAccountURL() {
			if(this.idc > 0) return `${$Config.apps[$Config.app.currentApp].appversionURL}/account?id=${this.idc}`;

			return `${$Config.apps[$Config.app.currentApp].appversionURL}/account`;
		}
	},

	methods: {
		loginSubmitted() {
			var data = JSON.parse(JSON.stringify(this.formLogin));
			$AjaxHelper.doRequest('user', 'login', Object.assign(data, {gcl: 1 /* Get company levels too */}), {callback: this._login});
		},

		_login (data) {
			// Login and send the user to the home page!
			if(data.status == 'OK') {
				// Log the user in!
				$UserHelper.login(data);

				// Close popup and emit loggedin event
				this.opened = false;
				this.$emit('loggedin');
			}
		}
  },
};
</script>

<style scoped>
.loginNewAccount .wrapperForgotPass {text-align: center;}
.loginNewAccount .wrapperForgotPass .forgotPass {padding-top:3px; padding-bottom:10px;}
</style>
