<template>
	<div class="item-content item-input" v-if="render">
		<div class="item-media">
			<i :id="`picker_${_uid}`" class="icon list-icon colorPickerIcon"></i>
		</div>
		<div class="item-inner">
			<div class="item-title item-label">{{label}}</div>
			<div class="item-input-wrap">
				<input type="text" class="input-with-value" :id="`input_${_uid}`" :placeholder="placeholder" :value="val">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
    label: {label: true},
    placeholder: {required: true},
    value: {required: true},
  },

	data() {
		return {
			_uid: $FunctionsHelper.uid(), // Give the component a unique id
			render: true,
			val: '',
			picker: null,
		};
	},

	watch: {
		// This is needed to force field values into RGBA format
		value(val) {
			this.val = val;
			this.picker.setValue({rgba: $FunctionsHelper.colors.getRGBArrayFromString(this.val)});
		},
	},

	mounted() {
		let that = this;

		this.picker = $f7.colorPicker.create({
			inputEl: `#input_${this._uid}`,
			targetEl: `#picker_${this._uid}`,
			targetElSetBackgroundColor: true,
			sliderLabel: true,
			sliderValue: true,
			sliderValueEditable: true,
			hexLabel: true,
			hexValueEditable: true,
			closeByBackdropClick: false,
			closeByOutsideClick: false,
			navbarTitleText: $LanguageHelper.translate('_PICK_YOUR_COLOR_'),
			navbarCloseText: $LanguageHelper.translate('_DONE_'),
			modules: ['sb-spectrum', 'hue-slider', 'rgb-sliders', 'alpha-slider', 'hex'],
			openIn: 'popup',

			formatValue: (value) => {
		    return 'rgba(' + value.rgba.join(', ') + ')';
		  },

			on: {
				change() {
					// Emit selected value
					that.$emit('changed', 'rgba(' + that.picker.getValue().rgba.join(',') + ')');
				},

				close() {
					// Fix F7 Popup Backdrop bug
					$ViewHelper.fixF7PopupBackdropBug();
				}
			}
		});

		this.picker.setValue({rgba: $FunctionsHelper.colors.getRGBArrayFromString(this.value)});
	}
};
</script>
