<template>
	<form v-if="visible">
		<div class="pageContent">
			<f7-card>
				<f7-card-header>{{$LanguageHelper.translate('_COMPANY_LOGO_')}}</f7-card-header>
				<f7-card-content>
					<div>{{$LanguageHelper.translate('_COMPANY_LOGO_SUB_TITLE_')}}</div>

					<div v-if="images.logo" class="image">
						<img :src="images.logo" />
					</div>
				</f7-card-content>

				<f7-block strong>
					<f7-button @click.prevent="imageUploadPopups.logo=true" raised fill small>{{$LanguageHelper.translate('_UPLOAD_IMAGE_')}}</f7-button>
					<image-upload v-if="imageUploadPopups.logo"
						:title="$LanguageHelper.translate('_UPLOAD_IMAGE_')"
						:currentImageURL="images.logo"
						:idc="idc"
						name="logo"
						type="logo"
						@close="imageUploadPopups.logo=false"
						@uploadDone="(url) => images.logo=url"
						@removeFileDone="images.logo=''"
					/>
				</f7-block>
			</f7-card>

			<f7-card>
				<f7-card-header>{{$LanguageHelper.translate('_BG_LOGO_')}}</f7-card-header>
				<f7-card-content>
					<div>
						{{$LanguageHelper.translate('_BG_LOGO_SUB_TITLE_')}}
					</div>

					<div v-if="images.bg" class="image">
						<img :src="images.bg" />
					</div>
				</f7-card-content>

				<f7-block strong>
					<f7-button @click.prevent="imageUploadPopups.bg=true" raised fill small>{{$LanguageHelper.translate('_UPLOAD_IMAGE_')}}</f7-button>
					<image-upload v-if="imageUploadPopups.bg"
						:title="$LanguageHelper.translate('_UPLOAD_IMAGE_')"
						:currentImageURL="images.bg"
						:idc="idc"
						name="bg"
						type="bg"
						@close="imageUploadPopups.bg=false"
						@uploadDone="images.bg=images.bg + '?nc=' + Math.random()"
						@removeFileDone="images.bg=''"
					/>
				</f7-block>
			</f7-card>
		</div>
	</form>
</template>

<script>
import ImageUpload from '../../components/smart/image-upload.vue';

export default {
	components: {
    'image-upload': ImageUpload
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			visible: false,
			images: {
				logo: '',
				bg: '',
			},

			imageUploadPopups: {
				bg: false,
				logo: false
			}
    };
  },

	methods: {
		_images(data) {
			this.visible = true;

			if(data.resultset.logo) {
				this.images.logo = data.resultset.logo;
			}

			if(data.resultset.bg) {
				this.images.bg = data.resultset.bg;
			}
		}
	},

	created () {
		$AjaxHelper.doRequest('Gym', 'getCompanyImages', {idc: this.idc}, {callback: this._images});
	}
};
</script>

<style scoped>
.image {
	margin-top:20px;
}

.image img {
	max-width:260px;
}
</style>
