<template>
  <page :title="$LanguageHelper.translate('_FAQ_')" @initiated="getFaq">
    <f7-list inset>
      <f7-list-item v-for="(listItems, key, index) in faq" :key="index" accordion-item :title="listItems.category_name_tl">
          <f7-accordion-content v-for="(items, key, index) in listItems.faq" :key="index">
            <f7-list>
              <f7-list-item accordion-item :title="items.question" class="accordionTitle">
                <f7-accordion-content>
                  <f7-block class="accordionText">

                    <div v-if="items.answer">{{$FunctionsHelper.specialChars(items.answer)}} {{items.id_faq == 1 || items.id_faq == 43 ? items.siteEmail : ''}}</div>

                    <div v-if="items.show_script === 1">
                      <code v-if="items.id_faq == 11 || items.id_faq == 12">
                        &lt;script src="{{items.siteURL}}/require_app?c={{idc}}&p=agenda"&gt;&lt;/script&gt;
                      </code>

                      <code v-if="items.id_faq == 13 || items.id_faq == 14">
                        &lt;script src="{{items.siteURL}}/require_app?c={{idc}}&p=agenda&mode=listView"&gt;&lt;/script&gt;
                      </code>

                      <code v-if="items.id_faq == 15 || items.id_faq == 16">
                        &lt;script src="{{items.siteURL}}/require_app?c={{idc}}"&gt;&lt;/script&gt;
                      </code>

                      <code v-if="items.id_faq == 17 || items.id_faq == 18">
                        &lt;script src="{{items.siteURL}}/require_app?c={{idc}}&width=400&height=600"&gt;&lt;/script&gt;
                      </code>
                    </div>

                    <div v-if="items.agenda">
                      <div v-for="(agenda, key, index) in items.agenda" :key="index">
                        <p><b>{{$FunctionsHelper.specialChars(agenda.name)}}</b></p>
                        <code>
                          &lt;script src="{{items.siteURL}}/require_app?c={{idc}}&p=agenda&id_agenda={{agenda.id_agenda}}"&gt;&lt;/script&gt;
                        </code>
                      </div>
                    </div>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },

  props: {idc: {required: false}},

  data() {
    return {
      faq: [],
    };
  },

  methods: {
    getFaq() {
      $AjaxHelper.doRequest('gym', 'getFAQ', {idc: this.idc}, {callback: this._initFaq});
    },

    _initFaq(data) {
      var faq = [];
      $.each(data.resultset.faqs, function (obj, index) {
          faq.push(index);
      });

      this.faq = faq;
    },
  },
};
</script>
<style scoped>
.accordionTitle {color: var(--theme-color);}

/**fix for delay on showing first title **/
.accordionTitle {
  z-index: 99999;
  position: relative;
  -webkit-backface-visibility: hidden;
}

.accordion-item .block {
  white-space: pre-line;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.54);
}
</style>
