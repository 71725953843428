<template>
  <dashboard :idc="idc" tab="agenda" :initData="initData" @constructed="$emit('constructed')" />
</template>

<script>
import DashboardTabMixin from '../../js/mixins/dashboardTab';

export default {
	mixins: [DashboardTabMixin],
};
</script>
