<template>
	<popup :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<template v-for="(result, key) in resultset" :key="key">
			<f7-card v-if="render">
				<f7-card-header>{{result.name}}</f7-card-header>
					<f7-card-content v-for="(ranking, key) in result.rankings" :key="key">
						<div class="item">{{$LanguageHelper.translate('_COMMENTS_')}}:<br />{{ranking.comment}}</div>
					</f7-card-content>
				</f7-card>
		</template>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';

export default {
	components: {
    'popup': Popup,
	},

	props: {
		idc: {required: true},
		id_wod_whiteboard: {required: true},
		id_whiteboard_appuser: {required: true},
  },

	data() {
		return {
      opened: false,
			render: false,
			title: $LanguageHelper.translate('_COMMENTS_'),
			resultset: null
		}
	},

	methods: {

	},

  created() {
		$AjaxHelper.doRequest('WOD', 'getRankingDetails', {idc:this.idc, id_whiteboard_appuser: this.id_whiteboard_appuser, id_wod_whiteboard: this.id_wod_whiteboard}, {callback: (data) => {
			this.resultset = data.resultset;
			this.render = true;
		}});
  },
};
</script>
