<template>
	<popup
		:title="$LanguageHelper.translate('_EXPORT_')"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>
		<list-form :params="form" @submitted="submitted" v-if="visible"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
    'list-form': ListForm,
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,

			form: {
				fields: {
					email: {type: 'checkbox', title: '_EMAIL_', render: true, checked: true, value: 1},
					name: {type: 'checkbox', title: '_NAME_', render: true, checked: true, value: 1},
					mobile: {type: 'checkbox', title: '_MOBILE_', render: true, checked: false, value: 1},
					address: {type: 'checkbox', title: '_ADDRESS_', render: true, checked: false, value: 1},
					birthdate: {type: 'checkbox', title: '_BIRTH_DATE_', render: true, checked: false, value: 1},
					gender: {type: 'checkbox', title: '_GENDER_', render: true, checked: false, value: 1},
					customer_since: {type: 'checkbox', title: '_CUSTOMER_SINCE_', render: true, checked: false, value: 1},
					product_filters: {type: 'divider', title: '_PRODUCT_FILTERS_', render: true},
					invoiceProduct: {type: 'checkbox_group', items: [], render: true},
				},

				submit: {text: '_EXPORT_', render: true},
				values: {},
			}
		};
  },

	methods: {
		submitted() {
			$AjaxHelper.doRequest('user', 'exportUsersForAdmin', Object.assign({idc: this.idc}, JSON.parse(JSON.stringify(this.form.values))), {callback: this._exportUsersForAdmin});
		},

		_filtersForCustomerExport(data) {
			// Push the items into the checkbox_group field here
			if(data.resultset.products && data.resultset.products.length > 0) {
				let items = [];
				data.resultset.products.forEach((item) => {
					items.push({
						title: item.name,
						checked: false,
						value: item.id_invoice_product,
						render: true
					});
				});
				this.form.fields.invoiceProduct.items = items;
			}

			this.visible = true;
		},

		_exportUsersForAdmin(data) {
			// Close popup here!
			if(data.status == 'OK') this.opened = false;
		}
	},

	created() {
		// Get export info!
		$AjaxHelper.doRequest('user', 'filtersForCustomerExport', {idc: this.idc}, {callback: this._filtersForCustomerExport});
	}
};
</script>

<style>
li.green .item-title-row span {color: #4cd964; font-weight: bold;}
li.red .item-title-row span {color: #ff3b30; font-weight: bold;}
</style>
