<template>
  <div v-if="opened" :opened="opened" :style="popoverStyle" class="popoverCustom" ref="popover">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
	props: {
    opened: {
      type: Boolean,
      required: true,
			default: false,
    },

    openerEl: {},

    left: {
      required: false,
			default: '',
    },

    right: {
      required: false,
			default: '',
    }
  },

  data() {
    return {
      isOpen: this.opened,

      popoverStyle: {}
    };
  },

  watch: {
    opened(newVal) {
      if (newVal) {
        this.addClickListener();
      } else {
        this.removeClickListener();
      }
    }
  },

  methods: {
    addClickListener() {
      document.addEventListener('click', this.handleClickOutside);
    },
    removeClickListener() {
      document.removeEventListener('click', this.handleClickOutside);
    },

    handleClickOutside() {
     this.$emit('close');
    },
  },

  created() {
    if(this.left !== null) {
      this.popoverStyle.left = `${this.left}px`;
    }
    if(this.right !== null) {
      this.popoverStyle.right = `${this.right}px`;
    }
  },

  mounted() {
    if(this.opened) {
      this.addClickListener();
    }
  },
  
  beforeDestroy() {
    this.removeClickListener();
  },
};
</script>

<style>
.popoverCustom {
	position: absolute;
	padding:0;
	color:black;
	background: #ffffff;
  border: 1px solid #cccccc;
  top: calc(55px + var(--f7-safe-area-top));
}

.popoverCustom .list {
	margin-top:0!important;
	margin-bottom:0!important;
}
</style>
