<template>
	<select name="language" @change="change">
		<option v-for="option in $LanguageHelper.availableLanguages" :value="option.code" :key="option.code" :selected="option.code == $LanguageHelper.current">{{option.name_short}}</option>
	</select>
</template>

<script>
export default {
  methods: {
		change(event) {
			// Save the language
      $LanguageHelper.saveLanguage(event.target.value);

			// Reload the window with replaced language params if there are any!
			$ViewHelper.reloadWindow({language: event.target.value});
    },
	}
};
</script>
