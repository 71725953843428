<template>
  <page :title="$LanguageHelper.translate('_MY_INVOICES_')" @initiated="go">
    <div class="pageContent" v-if="render">
			<f7-card>
				<f7-card-header>{{$LanguageHelper.translate('_MY_INVOICES_')}}</f7-card-header>
				<f7-card-content>
          <template v-if="token !=''">
            <div>{{$LanguageHelper.translate('_YOUR_INVOICES_EXPLAINED_')}}</div>
            <h4><a :href="link" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{link}}</a></h4>

            <div><b>{{$LanguageHelper.translate('_TOKEN_ORDER_DETAILS_PAGE_')}}</b></div>
            <div>{{token}}</div>
            <f7-button raised fill button-small class="button-small orange action marginBottom15 marginTop15" @click="copyToClipboard(token)">{{$LanguageHelper.translate('_COPY_TOKEN_TO_CLIPBOARD_')}}</f7-button>
          </template>

          <template v-else>
            <div>{{$LanguageHelper.translate('_YOUR_INVOICES_IN_WElCOME_MAIl_')}}</div>
          </template>
				</f7-card-content>
			</f7-card>
		</div>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';
import CopyToClipboard from '../js/mixins/copyToClipboard';

export default {
  mixins: [CopyToClipboard],
  
  components: {
    'page': Page,
  },

  props: {
		idc: { required: true },
  },

  data() {
    return {
      render: false,
      link: '',
      token: '',
    };
  },

  methods: {
    go() {
			$AjaxHelper.doRequest('payment', 'getMyPNPInvoices', {idc: this.idc}, {callback: (data) => {
        this.link = data.payOrderDetailsURL;
        this.token = data.token;
        this.render = true
      }});
    },
  },
};
</script>
