<template :idc="idc">
	<popup
		:opened="opened"
		:title="title"
		:currentImageURL="currentImageURL"
		:name="name"
		:type="type"
		:autoClose="true"
		@mounted="opened=true"
		@close="$emit('close')">

		<div class="pageContent">
			<form enctype="multipart/form-data">
				<f7-card>
					<f7-card-header>{{$LanguageHelper.translate('_UPLOAD_NEW_IMAGE_')}}</f7-card-header>
					<f7-card-content class="wrapperCardContentUploadButton">
						<image-select v-if="opened" @selected="imageSelected"></image-select>

						<div class="wrapperUpload" v-if="fileInfo.scr !=''">
							<img :src="fileInfo.src" class="scaleWidth preview" />

							<div class="fileInfo">
								 <div class="name">{{fileInfo.name != '' ? $LanguageHelper.translate('_FILE_NAME_') + ': ' + fileInfo.name : ''}}</div>
								 <div class="size">{{fileInfo.size != '' ? $LanguageHelper.translate('_FILE_SIZE_') + ': ' + fileInfo.size : ''}}</div>
								 <div class="type">{{fileInfo.type != '' ? $LanguageHelper.translate('_FILE_TYPE_') + ': ' + fileInfo.type : ''}}</div>
						 	</div>

							<f7-button @click.prevent="upload" raised fill small class="yellow buttonUpload">{{$LanguageHelper.translate('_UPLOAD_')}}</f7-button>
						</div>
					</f7-card-content>
				</f7-card>

				<f7-card v-if="hasCurrentImage">
					<f7-card-header>{{$LanguageHelper.translate('_CURRENT_IMAGE_')}}</f7-card-header>
					<f7-card-content>
						<div>
							<img :src="currentImageURL + '?nc=' + Math.random()" class="preview">
						</div>

						<div class="fileAction delete">
							<f7-button @click.prevent="deleteImage" raised fill small class="red">{{$LanguageHelper.translate('_DELETE_IMAGE_')}}</f7-button>
						</div>
					</f7-card-content>
				</f7-card>
			</form>
		</div>
	</popup>
</template>

<script>
import Popup from './popup.vue';
import ImageSelect from './image-select.vue';

export default {
	components: {
    'popup': Popup,
		'image-select': ImageSelect
	},

	props: {
		title: {type: String, required: true},
		requestMethod: {type: String, required: false, default: 'handleFile'},
		currentImageURL: {type: String, required: false},
		name: {type: String, required: false},
		type: {type: String, required: true},
		idc: {required: false},
		autoClose: {Type: Boolean, default: true, required: false},
		removeFileMethod: {Type: String, default: 'removeFile', required: false},
  },

	data() {
		return {
			opened: false,
			file: null,
			hasCurrentImage: (this.currentImageURL && this.currentImageURL.length > 0),

			fileInfo: {
				name: '',
				type: '',
				size: '',
				src: ''
			},

			requestData: {
				token: $UserHelper.getToken(),
		    id_appuser_li: $UserHelper.getID(),
				language: $LanguageHelper.getCurrentLanguageObj().code,
		    method: this.requestMethod,
				id_gym: this.idc ? this.idc : null,
				file_name: this.name,
				type: this.type,
			}
		}
	},

	methods: {
		async imageSelected(file) {
			if(!file) return;

			// Save file to data variable!
			this.file = file;

			// Set read the file contents
			this.fileInfo.src = await this.readFileAsync(file);

			var fileSize = 0;
			if(file.size > 1024 * 1024) {
				fileSize = (Math.round(file.size * 100 / (1024 * 1024)) / 100).toString() + 'MB';
			} else {
				fileSize = (Math.round(file.size * 100 / 1024) / 100).toString() + ' KB';
			}

			this.fileInfo.name = file.name;
			this.fileInfo.type = file.type;
			this.fileInfo.size = fileSize;
		},

		deleteImage() {
			var that = this;
			$ViewHelper.confirm({method:
				function() {
					var data = {
						file_name: that.name,
						type: that.type,
						idc: that.requestData.id_gym
					}
					$AjaxHelper.doRequest('fileHandler', that.removeFileMethod, data, {callback: function() {
						// Reset current image, file browser and emit remove file done event.
						that.hasCurrentImage = false;
						that.$emit('removeFileDone');
					}});
				},}
			);
		},

		upload() {
			var that = this;
			var file = this.file;

			var fd = new FormData();
			fd.append('fileToUpload', file);

      var xhr = new XMLHttpRequest();

      // Upload complete!
      xhr.addEventListener('loadend', function(evt) {
				// If were on iOS we need this.response instead of evt.target.responseText for wkwebview
      	var response = $Config.app.onDevice && $Config.app.platform == 'ios' ? jQuery.parseJSON(this.response) : jQuery.parseJSON(evt.target.responseText);

				if(response.notice != '') {
      		$ViewHelper.alert(response.notice, $LanguageHelper.translate('_NOTICE_'));
      	}

				if(response.status == 'OK') {
					// Do stuff when upload was successful
					if(response.imageURL && response.imageURL.length > 0) {
						// Empty the file object again so it will reset
						that.file = null;

						// Let the component know we have a current image again
						that.hasCurrentImage = true;

						// Emit the upload done event here!
						that.$emit('uploadDone', response.imageURL + '?nc=' + Math.random());

						// Close popup?
						if(that.autoClose) that.$emit('close');
					}
				}
			}, false);

      // Upload failed
      xhr.addEventListener('error', function(evt) {}, false);

      // Upload aborted
      xhr.addEventListener('abort', function(evt) {}, false);

      // Create request url with options in it!
      var url = $Config.app.baseURL + '?data[service]=fileHandler';

      for (var key in this.requestData) {
  			url += '&data[' + key + ']=' + this.requestData[key];
			}

			xhr.open('POST', url);
	    xhr.send(fd);
		},

		readFileAsync(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();

				reader.onload = () => {
					resolve(reader.result);
				};

				reader.onerror = reject;

				reader.readAsDataURL(file);
			});
		}
	}
};
</script>

<style scoped>
input[type="file"] {
	display: none;
}

.fileInfo {
	padding-top: 18px;
	padding-bottom: 18px;
}

.fileAction.delete {
	margin-top: 20px;
}

.wrapperUpload {
	margin-top: 20px;
}

.buttonSelectFile {
	display:block;
	text-align: center;
	background: #ff9500;
	color:#fff;
	padding-top:5px;
	padding-bottom:5px;
	cursor:pointer;
}

img.preview {
	display: block;
  max-height: 200px;
  max-width: 250px;
  width: auto;
  margin: auto;
}
</style>
