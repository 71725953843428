<template>
  <div class="agendaList" v-if="title.length > 0">
   <f7-link v-if="params.agendas && params.agendas.length > 1" class="clickable themeNavbarTextColor displayBlock fontweightVar" @click.stop="togglePopover">
			{{title}}<f7-icon class="icon ionDropDown marginLeft10"></f7-icon>
		</f7-link>

    <div v-else class="marginLeft20">{{title}}</div>

		<!-- Custom build popover element -->
		<popover :opened="popoverOpened" :left=40 :popOverEl="popOverEl" ref="popoverEl" @click.stop @close="popoverOpened=false">
			<f7-list>
        <f7-list-item radio v-for="(agenda, index) in params.agendas"
					:key="index"
					:value="agenda.id"
					:checked="agenda.checked"
					:title="$FunctionsHelper.specialChars(agenda.title)"
					@change="changed(agenda)"
				/>
      </f7-list>
		</popover>

  </div>
</template>

<script>
import Popover from './smart/popover.vue';

export default {
	components: {
		'popover': Popover
  },

	props: {
		params: {required: true},
	},

	data() {
		return {
			title: '',
			popoverOpened: false,
			popOverEl: null
		}
	},

	methods: {
		togglePopover() {
			this.popoverOpened = !this.popoverOpened;
		},

		changed(agenda) {
			// Emit the changed value
			this.$emit('changed', agenda);

			// Close popover
			this.popoverOpened = false;
		}
	},

	watch: {
		params: {
			deep: true,
			immediate: true,
			 handler() {
				 // Set the title to current selected one.
				 for(var key in this.params.agendas) {
					 if(this.params.agendas[key].checked) this.title = this.params.agendas[key].title;
				 }
			 },
		}
	},

	mounted() {
    this.popOverEl = this.$refs.popOverEl;
  }
};
</script>
