<template>
	<popup
		v-if="visible"
		:title="title"
		:toolbar="toolbar"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>

		<template #intake v-if="toolbar.tabs.intake.active">
			<tab-intake :idc="idc" :id_customer="id_customer" :fab="fab.new" />
		</template>

		<template #reporting v-if="toolbar.tabs.reporting.active">
			<tab-reporting :idc="idc" :id_customer="id_customer" :fab="fab.new" />
		</template>
	</popup>
</template>

<script>
import Popup from '../../components/smart/popup.vue';
import TabIntake from './tab-intake.vue';
import TabReporting from './tab-reporting.vue';

export default {
	components: {
    'popup': Popup,
    'tab-intake': TabIntake,
    'tab-reporting': TabReporting,
	},

	props: {
		index: {required: false},
		title: {type: String, required: true},
		idc: {required: true},
		id_customer: {required: true},
  },

	data() {
    return {
			visible: false,
			opened: false,

			// Floating action buttons
			fab: {
				// Name of tab this FAB object belongs to!
				new: {
					position: 'right-bottom',
					buttons: {
						new: {name:'add', label: $LanguageHelper.translate('_CREATE_NEW_'), icon: 'icon ionAdd', render: true, clicked: false},
					}
				}
			},

			// Tabs
			toolbar: {
				tabs: {
					intake: {text: $LanguageHelper.translate('_INTAKE_'), icon: 'icon ionList', render: $UserHelper.hasAccess('intake', 'save', this.idc), active: true},
					reporting: {text: $LanguageHelper.translate('_REPORTING_'), icon: 'icon iconNews', render: $UserHelper.hasAccess('reporting', 'save', this.idc), active: false},
				}
			}
    };
  },

  created() {
		//set fab for toolbar
    this.toolbar.tabs.intake.fab = this.fab.new;
    this.toolbar.tabs.reporting.fab = this.fab.new;

		this.visible = true;
	}
};
</script>
