<template>
  <popup :title="$LanguageHelper.translate('_APPOINTMENT_TYPES_')"
    :opened="opened"
    @mounted="opened=true"
    @close="$emit('close')"
    :fab="fab.appointmentType"
    @fab-clicked="fabClicked"
  >
    <list-form v-if="render" :params="form" @changed="changed" @submitted="submitted"></list-form>
  </popup>
</template>

<script>
import FormCheckboxGroupAction from '../js/mixins/formCheckboxGroupAction';
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
  components: {
    'popup': Popup,
    'list-form': ListForm
  },

  mixins: [FormCheckboxGroupAction],

  props: {
		idc: {required: true}
	},

  data() {
    return {
      opened: false,
      render: false,
      first: false,
      data: {},
      creditsEnabled: false,
      installmentsEnabled: false,
      mode: 'edit',

      form: {
        fields: {
          new: {name: 'new', type: 'button', title: '_CREATE_APPOINTMENT_TYPE_', render: true},
          newDivider: {name: 'newDivider', type: 'divider', title: '_EDIT_', render: true},
          edit: {name: 'edit', type: 'button', title: '_EDIT_APPOINTMENT_TYPE_', render: false},
          editDivider: {name: 'editDivider', type: 'divider', title: '_NEW_', render: false},
          id_appointment_type: {name: 'appointment_type', type: 'select', title: '_APPOINTMENT_TYPE_', render: true, options: []},
          name: {name: 'name', type: 'input', label: '_NAME_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          default_appointment_duration_minutes: {name: 'default_appointment_duration_minutes', type: 'input', label: '_DEFAULT_APPOINTMENT_DURATION_MINUTES_', placeholder: '_ENTER_HERE_', render: false, value: ''},
          
          appointmentReminderSettings: {type: 'divider', title: '_APPOINTMENT_REMINDER_DIVIDER_', info: $FunctionsHelper.nl2br($LanguageHelper.translate('_INFO_ABOUT_APPOINTMENT_REMINDER_')), render: false},
          appointment_reminder_enabled: {name: 'appointment_reminder_enabled', type: 'select', title: '_ENABLE_APPOINTMENT_REMINDER_', render: false,
            options: {
              no: {name: 'no', label: '_NO_', value: 0, selected: true},
              yes: {name: 'yes', label: '_YES_', value: 1, selected: false},
            }
          },
          number_minutes_before_appointment_reminder: {name: 'number_minutes_before_appointment_reminder', type: 'input', label: '_NUMBER_MINUTES_BEFORE_APPOINTMENT_REMINDER', placeholder: '_ENTER_HERE_APPOINTMENT_REMINDER_', render: false, value: ''},

					subscribeDivider: {type: 'divider', title: '_SUBSCRIBE_POSSIBLE_WITH_', info: $LanguageHelper.translate('_INFO_ABOUT_PRODUCTS_'), render: false},
          free: {name: 'free', type: 'checkbox', title: '_FREE_', checked: false, value: 1, render: false},
          id_invoice_product: {name: 'id_invoice_product', type: 'checkbox_group', items: [], render: false},
          number_credits: {name: 'number_credits', type: 'select', title: '_BOOK_CREDITS_', render: false, options: []},
          privacyDivider: {type: 'divider', title: '_PRIVACY_', info: $LanguageHelper.translate('_INFO_ABOUT_PRIVACY_'), render: false},
          public: {name: 'public', type: 'select', title: '_CUSTOMER_SEES_SUBSCRIPTIONS_', render: false,
            options: {
              yes: {name: 'yes', label: '_YES_', value: 1, selected: true},
              no: {name: 'no', label: '_NO_', value: 0, selected: false},
            }
          },
          settingsDivider: {type: 'divider', title: '_SUBSCRIPTION_SETTINGS_', info: $LanguageHelper.translate('_INFO_ABOUT_SETTINGS_'), render: false},
          number_appuser_partners: {name: 'number_appuser_partners', type: 'select', title: '_NUMBER_FAMILY_MEMBERS_ALLOWED_', render: false, options: []},
          waiting_list: {name: 'waiting_list', type: 'select', title: '_ENABLE_WAITINGLIST_', render: false,
            options: {
              yes: {name: 'yes', label: '_YES_', value: 1, selected: true},
              no: {name: 'no', label: '_NO_', value: 0, selected: false},
            }
          },
          subscribe_from: {type: 'combined', title: '_SUBSCRIBE_POSSIBLE_FROM_', render: false,
						fields: {
              number_day_hours_before_subscription_opens: {type: 'select', class: 'medium', name: 'number_day_hours_before_subscription_opens', options: []},
              number_hours_before_subscription_opens: {type: 'select', class: 'medium', name: 'number_hours_before_subscription_opens', options: []},
						}
					},
          max_subsriptions_per_day: {name: 'max_subsriptions_per_day', type: 'select', title: '_MAX_ALLOWED_SUBSCRIBE_PER_DAY_', render: false, options: []},
          number_hours_subscribe: {name: 'number_hours_subscribe', type: 'select', title: '_SUBSCRIBE_MAX_HOURS_', render: false, options: []},
          number_hours_unsubscribe: {name: 'number_hours_unsubscribe', type: 'select', title: '_UNSUBSCRIBE_MAX_HOURS_', render: false, options: []},
          number_hours_unsubscribe_paid_from: {name: 'number_hours_unsubscribe_paid_from', type: 'select', title: '_UNSUBSCRIBE_PAID_FROM_HOURS_', render: false, options: []},
          unsubscribe_not_allowed: {name: 'unsubscribe_not_allowed', type: 'select', title: '_CUSTOMER_UNSUBSCRIBE_SELF_', render: false,
            options: {
              yes: {name: 'yes', label: '_YES_', value: 0, selected: true},
              no: {name: 'no', label: '_NO_', value: 1, selected: false},
            }
          },

					colors: {name: 'colors', type: 'divider', title: '_COLOR_SETTINGS_', render: false},
					type_background_color: {name: 'type_background_color', type: 'color', label: '_COLOR_BACKGROUND_APPOINTENTS_', placeholder: '_ENTER_HERE_', render: false, value: ''},
					type_text_color: {name: 'type_text_color', type: 'color', label: '_COLOR_TEXT_APPOINTENTS_', placeholder: '_ENTER_HERE_', render: false, value: ''},
        },

        submit: {text: '_SAVE_', render: false},
				values: {},
      },

      fab: {
        appointmentType: {
          position: 'right-bottom',
          buttons: {
            remove: {name:'remove', icon: 'icon ionTrash', label: $LanguageHelper.translate('_DELETE_APPOINTMENT_TYPE_'), render: false, clicked: false},
          }
        }
      }
    };
  },

  methods: {
    changed(el, action) {
      if(el.name == 'appointment_reminder_enabled') {
        if(action.name == 'yes') {
          this.form.fields.number_minutes_before_appointment_reminder.render = true;
        } else {
          this.form.fields.number_minutes_before_appointment_reminder.render = false;
          this.form.fields.number_minutes_before_appointment_reminder.value = '';
        }
      }

			// Handle product checkboxes
			$.each(this.form.fields, (index, field) => {
				if(field.name == 'id_invoice_product') {
          this.handleCheckboxGroupAction(el, field.items, action);
          
          // Render number_credits fields if credits are checked
					if(el.ref == 'credits') {
						this.creditsEnabled = false;
						this.form.fields.number_credits.render = false;
					}

					$.each(field.items, (key, item) => {
						if(el.ref == 'credits' && item.ref == 'credits') {
							if(item.checked) {
								this.creditsEnabled = true;
								this.form.fields.number_credits.render = true;
							}
            }

						// If a installments checkbox was clicked (main or product) set the installmentsEnabled
						if(el.ref == 'installments' && item.ref == 'installments' && item.level == 1)  {
							this.installmentsEnabled = item.checked; // We check the main level item, it get's auto checked when you click on a product
						}
					});
        }
			});

			// Disable the free option is credits or installments is checked
			if(action == 'checked' && (el.ref == 'credits' || el.ref == 'installments')) this.form.fields.free.checked = false;

      switch (el.name) {
        // Set fields if new appointment type button is clicked
        case 'new':
          this.mode = 'new';

					// Hide FAB remove button
          this.fab.appointmentType.buttons.remove.render = false;

          $.each(this.form.fields, (index, item) => {
            item.render = true;
            if(item.name == 'appointment_type') item.render = false;
            if(item.name == 'new') item.render = false;
            if(item.name == 'newDivider') item.render = false;
          });

          // Deselect all products
          $.each(this.data.invoice_product, (index, item) => {
            $.each(item, (index, items) => {
              items.selected = false;
            });
          });

          this.form.values = {};
          this.setFields();
          this.setProducts(this.data.invoice_product /* Give products params */);
        break;

        // Reset fields to default value if edit is clicked
        case 'edit':
          // Reset fields
					this.mode = 'edit';
          this.resetFields();
        break;

        // Appointment type selected
        case 'appointment_type':
					this.fab.appointmentType.buttons.remove.render = false;
          this.first = true;
					this.mode = 'edit';
          if(action.value != '') {
            this.first = false;
            this.getSettings(action.value);
          } else {
            // If first option without value is selected then reset the fields
						this.resetFields();
          }
        break;

				// Disable credits and installment product checkboxes if free option was selected
        case 'free':
					this.creditsEnabled = false;
					this.installmentsEnabled = false;
					this.form.fields.number_credits.render = false;

					$.each(this.form.fields.id_invoice_product.items, (index, item) => {
						item.checked = false;
						if(item.level == 1) item.toggled = false;
            if(item.level == 2) item.render = false;
          });
        break;
      }
    },

    fabClicked() {
      let data = JSON.parse(JSON.stringify(this.form.values));

      let buttons = [
        // Reset form
        {text: $LanguageHelper.translate('_CANCEL_'),
        onClick: () => {
          return;
        }},

        // Delete type
        {text: $LanguageHelper.translate('_YES_'), onClick: () => {
          this.deleteType(data);
        }},
      ];

      $ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_CHOOSE_AN_OPTION_'), $LanguageHelper.translate('_REMOVE_APPOINTMENT_TYPE?_'));
    },

    // Reset fields back to starting point
    resetFields() {
			this.creditsEnabled = false;
			this.installmentsEnabled = false;
			$.each(this.form.fields, (index, item) => {
        item.render = false;
        if(item.name == 'appointment_type') {
          this.resetAppointmentType();
          item.render = true;
        }
        if(item.name == 'new') item.render = true;
        if(item.name == 'newDivider') item.render = true;
      });

      this.form.submit.render = false;
			this.fab.appointmentType.buttons.remove.render = false;
    },

		resetToEditModeAfterCallback(data) {
			this.mode = 'edit';
			this.creditsEnabled = false;
			this.installmentsEnabled = false;
			$.each(this.form.fields, (index, item) => {
				item.render = false;
				if(item.name == 'appointment_type') {
					item.options = [];
					//item.render = true;
					this.setAppointmentTypes(data.resultset);
				}

        this.form.fields.id_appointment_type.render = true;
				if(item.name == 'new') item.render = true;
				if(item.name == 'newDivider') item.render = true;
			});

			this.form.submit.render = false;
		},

		// Set select appointmenttypes
    setAppointmentTypes(data) {
			this.form.fields.id_appointment_type.options = [];

      // Default first option
      this.form.fields.id_appointment_type.options.push({
        name: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
        label: $LanguageHelper.translate('_CHOOSE_AN_OPTION_'),
				value: '',
        selected: true,
      });

			// Push appointment types
      data.appointment_type.forEach((item) => {
        this.form.fields.id_appointment_type.options.push({
          name: item.name,
          value: item.id_appointment_type,
          label: item.name,
          selected: false,
        });
      });
    },

		// Set appointmenttype back to default, used when going back to edit mode
    resetAppointmentType() {
			this.form.fields.id_appointment_type.options.forEach((item) => item.selected = item.value == '');
    },

		// Call all the settings functions to fill and set the fields
    setFields(data) {
      if(!data) this.form.fields.name.value = '';
      this.form.fields.name.render = true;
      this.form.fields.default_appointment_duration_minutes.render = true;
      this.form.fields.subscribeDivider.render = true;
      this.form.fields.free.render = true;
      this.form.fields.settingsDivider.render = true;
      this.form.fields.colors.render = true;
      this.form.fields.default_appointment_duration_minutes.value = data && data.default_appointment_duration_minutes > 0 ? data.default_appointment_duration_minutes : 60;

      // Appointment reminder settings
      this.form.fields.appointmentReminderSettings.render = true;
      this.form.fields.appointment_reminder_enabled.render = true;
      this.form.fields.number_minutes_before_appointment_reminder.render = data && data.number_minutes_before_appointment_reminder > 0

      // Set the enable reminder to enabled or disabled if data is available!
      this.form.fields.appointment_reminder_enabled.options.no.selected = true;
      this.form.fields.appointment_reminder_enabled.options.yes.selected = false;
      this.form.fields.number_minutes_before_appointment_reminder.value = '';
      if(data && data.number_minutes_before_appointment_reminder && data.number_minutes_before_appointment_reminder > 0) {
        this.form.fields.appointment_reminder_enabled.options.no.selected = false;
        this.form.fields.appointment_reminder_enabled.options.yes.selected = true;
      }
      
      this.setPublic(data);
      this.setNumberAppuserPartners(data);
      this.setWaitinglist(data);
      this.setSubscribeFrom(data);
      this.setMaxSubscriptionsPerDay(data);
      this.setNumberHoursSubscribe(data);
      this.setNumberHoursUnsubscribe(data);
      this.setNumberHoursUnsubscribePaid(data);
      this.setNumberCredits(data);
      this.setUnsubscribeNotAllowed(data);
      this.setColors(data)
    },

    //set select public
    setPublic(data) {
      $.each(this.form.fields.public.options, (index, item) => {
        item.selected = ((!data && item.value == 1 || data && data.public == item.value) ? true : false);
      });

      this.form.fields.privacyDivider.render = true;
      this.form.fields.public.render = true;
    },

    //set select number_appuser_partners
    setNumberAppuserPartners(data) {
      this.form.fields.number_appuser_partners.options = [];
      var cnt = 0;
      for (cnt = 0; cnt <= 100; cnt ++) {
        this.form.fields.number_appuser_partners.options.push({
          name: cnt,
          value: (cnt >= 1 ? cnt : ''),
          label: (cnt >= 1 ? cnt : $LanguageHelper.translate('_NONE_')),
          selected: (!data && cnt == 0 ? true : (data && data.number_appuser_partners == cnt ? true : false))//(data && data.number_appuser_partners == cnt ? true : false),
        });
      }

      this.form.fields.number_appuser_partners.render = true;
    },

    //set select waiting_list
    setWaitinglist(data) {
      $.each(this.form.fields.waiting_list.options, (index, item) => {
        item.selected = ((!data && item.value == 1 || data && data.waiting_list == item.value) ? true : false);
      });

      this.form.fields.waiting_list.render = true;
    },

    //set select subscribe_from days/weeks
    setSubscribeFrom(data) {
      let weekItems = [];
      var hoursLeft = (data && data.number_hours_before_subscription_opens &&  data.number_hours_before_subscription_opens > 0 ? data.number_hours_before_subscription_opens : 0);
      var select = 0;

      for (var cnt = 8; cnt >= 1; cnt --) {
        var weeksText = $LanguageHelper.translate('_WEEK_LOWER_');
        if (cnt > 1) {
          var weeksText = $LanguageHelper.translate('_WEEKS_');
        }

        var hours = cnt * 168;
        var weeks = Math.floor(hoursLeft / 168);

        if(cnt == weeks) {
          select = 1;
          hoursLeft = hoursLeft % 168;
        }

        weekItems.push({
          name: 'number_day_hours_before_subscription_opens',
          value: hours,
          label: `${cnt} ${weeksText}`,
          selected: (cnt == weeks ? true : false),
        });
      }

      let dayItems = [];

      for (var cnt = 6; cnt >= 0; cnt --) {
        var daysText = $LanguageHelper.translate('_DAY_LOWER_');
        if (cnt != 1) {
          var daysText = $LanguageHelper.translate('_DAYS_LOWER_');
        }

        var hours = cnt * 24;

        if(select == 0) {
          if(hoursLeft >= 0) {
            var days = Math.floor(hoursLeft / 24);

            if(cnt == days) {
              select = 1;
              hoursLeft = hoursLeft % 24;
            }
          }
        }

        dayItems.push({
          name: 'number_day_hours_before_subscription_opens',
          value: hours,
          label: `${cnt} ${daysText}`,
          selected: (cnt == days ? true : false),
        });
      }

      let options = [...weekItems, ...dayItems];
      setTimeout(() => this.form.fields.subscribe_from.fields.number_day_hours_before_subscription_opens.options = options, 1);

      //set subscribe_from days/weeks
      var cnt = 0;
      let hoursOptions = [];
      for (var cnt = 23; cnt >= 0; cnt --) {
        hoursOptions.push({
          name: 'number_hours_before_subscription_opens',
          value: cnt,
          label: `${cnt} ${$LanguageHelper.translate('_HOUR_')}`,
          selected: (cnt == hoursLeft ? true : false),
        });
      }
      setTimeout(() => this.form.fields.subscribe_from.fields.number_hours_before_subscription_opens.options = hoursOptions, 1);

      this.form.fields.subscribe_from.render = true;
    },

    //set select number subscriptions per day
    setMaxSubscriptionsPerDay(data) {
      this.form.fields.max_subsriptions_per_day.options = [];
      var cnt = 0;
      for (var cnt = 0; cnt <= 24; cnt ++) {
        this.form.fields.max_subsriptions_per_day.options.push({
          name: cnt,
          value: (cnt >= 1 ? cnt : ''),
          label: (cnt >= 1 ? cnt : $LanguageHelper.translate('_VALID_UNLIMITED_')),
          selected: (!data && cnt == 0 ? true : (data && data.max_subsriptions_per_day == cnt ? true : false))//(data && data.number_appuser_partners == cnt ? true : false),
        });
      }

      this.form.fields.max_subsriptions_per_day.render = true;
    },

    //set select number hours subsribe
    setNumberHoursSubscribe(data) {
      this.form.fields.number_hours_subscribe.options = [];
      var cnt = 0;
      for (var cnt = 0; cnt <= 48; cnt ++) {
        this.form.fields.number_hours_subscribe.options.push({
          name: cnt,
          value: (cnt >= 1 ? cnt : ''),
          label: (cnt >= 1 ? `${cnt} ${$LanguageHelper.translate('_IN_ADVANCE_')}` : $LanguageHelper.translate('_APPOINTMENT_BEGINS_')),
          selected: (!data && cnt == 0 ? true : (data && data.number_hours_subscribe == cnt ? true : false))//(data && data.number_appuser_partners == cnt ? true : false),
        });
      }
      this.form.fields.number_hours_subscribe.render = true;
    },

    //set select number hours unsubsribe
    setNumberHoursUnsubscribe(data) {
      this.form.fields.number_hours_unsubscribe.options = [];
      var cnt = 0;
      for (var cnt = 0; cnt <= 48; cnt ++) {
        this.form.fields.number_hours_unsubscribe.options.push({
          name: cnt,
          value: (cnt >= 1 ? cnt : ''),
          label: (cnt >= 1 ? `${cnt} ${$LanguageHelper.translate('_IN_ADVANCE_')}` : $LanguageHelper.translate('_APPOINTMENT_BEGINS_')),
          selected: (!data && cnt == 0 ? true : (data && data.number_hours_unsubscribe == cnt ? true : false))
        });
      }
      this.form.fields.number_hours_unsubscribe.render = true;
    },

    // Set select number hours unsubsribe paid
    setNumberHoursUnsubscribePaid(data) {
      this.form.fields.number_hours_unsubscribe_paid_from.options = [];
      var cnt = 0;
      for (var cnt = 0; cnt <= 48; cnt ++) {
        this.form.fields.number_hours_unsubscribe_paid_from.options.push({
          name: cnt,
          value: (cnt >= 1 ? cnt : ''),
          label: (cnt >= 1 ? `${cnt} ${$LanguageHelper.translate('_IN_ADVANCE_')}` : $LanguageHelper.translate('_NOT_APPLICABLE_')),
          selected: (!data && cnt == 0 ? true : (data && data.number_hours_unsubscribe_paid_from == cnt ? true : false)),
        });
      }

      this.form.fields.number_hours_unsubscribe_paid_from.render = true;
    },

    //set select number_credits
    setNumberCredits(data) {
      this.form.fields.number_credits.options = [];
      var cnt = 0;
      for (cnt = 0; cnt <= 20; cnt ++) {
        this.form.fields.number_credits.options.push({
          name: cnt,
          value: (cnt >= 1 ? cnt : ''),
          label: (cnt >= 1 ? cnt : $LanguageHelper.translate('_CHOOSE_NUMBER_CREDITS_')),
          selected: (!data && cnt == 0 ? true : (data && data.number_credits == cnt ? true : false)),
        });
      }
    },

    //set select unsubscribe_not_allowed
    setUnsubscribeNotAllowed(data) {
      $.each(this.form.fields.unsubscribe_not_allowed.options, function (index, item) {
        item.selected = ((!data && item.value == 0 || data && data.unsubscribe_not_allowed == item.value) ? true : false);
      });

      this.form.fields.unsubscribe_not_allowed.render = true;
    },

    //setColors
    setColors(data) {
      if(data && data.type_background_color != '') this.form.fields.type_background_color.value = data.type_background_color;
      else this.form.fields.type_background_color.value = 'rgba(255,255,255,1)';

      if(data && data.type_text_color != '') this.form.fields.type_text_color.value = data.type_text_color;
      else this.form.fields.type_text_color.value = 'rgba(33,33,33,1)';

      this.form.fields.type_background_color.render = true;
      this.form.fields.type_text_color.render = true;
    },

    //create product checkbox list
    setProducts(data) {
			// Create the product type with product checkbox fields
			let items = [];
      var cnt = 0;
      this.form.fields.free.checked = true;
      this.form.fields.number_credits.render = false;

			// We want to have a custom order (installments then credits), so overwrite default by making this an index array
			let products = [];

			if(data.installments) products.push(data.installments);
			if(data.credits) products.push(data.credits);

			$.each(products, (index, item) => {
				let type = '';

				// Check if both types are here
				if(products.length == 2) {
					if(index == 0) type = 'installments';
					else type = 'credits';
				}
				else if(item[0].installments == 1) type = 'installments';
				else type = 'credits';

				var productType = {
					title: (type == 'installments' ? $LanguageHelper.translate('_WITH_INSTALLMENTS_') : $LanguageHelper.translate('_WITH_CREDITS_')),
					ref: type, // Mandatory field for handleCheckboxGroupAction to work
					level: 1, // Mandatory field for handleCheckboxGroupAction to work
					checked: false, // Mandatory field for handleCheckboxGroupAction to work
					toggled: true, // Mandatory field for handleCheckboxGroupAction to work
					class: 'themeLink',
					render: true
				};

				items.push(productType);

				// Each products and store needed settings
				item.forEach((product) => {
					// If product is selected, make sure toggled and checked are set to true on level 1 item!
					if(product.selected == 1) {
						productType.checked = true;
						productType.toggled = true;
            this.form.fields.free.checked = false;
					}

					if(product.installments == 1 && product.selected == 1) {
						this.installmentsEnabled = true;
					}

          if(product.credits == 1 && product.selected == 1) {
						this.form.fields.number_credits.render = true;
						this.creditsEnabled = true;
					}
				});

				// Store products and use settings used above
				item.forEach((product) => {
					items.push({
						ref: product.installments == 1 ? 'installments' : 'credits', // Mandatory field for handleCheckboxGroupAction to work
						level: 2, // Mandatory field for handleCheckboxGroupAction to work
						checked: product.selected == 1, // Mandatory field for handleCheckboxGroupAction to work
						title: product.name,
						value: product.id_invoice_product,
						render: /*productType.checked && productType.checked == 1*/ true,
					});
				});
			});

			// Render the grouped checkboxes here
      this.form.fields.id_invoice_product.items = items;
      this.form.fields.id_invoice_product.render = true;

			this.form.submit.render = true;
    },

    deleteType(data) {
      $AjaxHelper.doRequest('agenda', 'saveSettings', Object.assign({idc: this.idc, action: 'delete-appointment_type'}, data), {callback: this._deleted});
    },

    submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));

			// Calculate the correct amount of hours before subscription opens before we post to back-end
			data.number_hours_before_subscription_opens = parseInt(data.number_hours_before_subscription_opens) + parseInt(data.number_day_hours_before_subscription_opens);

			if(this.creditsEnabled) data.credits_enabled = 1;
			if(!data.number_credits) data['number_credits'] = '';

			if(this.installmentsEnabled) data.installments_enabled = 1;

      $AjaxHelper.doRequest('agenda', 'saveSettings', Object.assign({idc: this.idc, action: 'save-appointment_type_settings'}, data), {callback: this._saveSettings});
    },

    // Get appointment type settings
    getSettings(val) {
      let data = [];
      if(!this.first) data['id_appointment_type'] = val;
      $AjaxHelper.doRequest('agenda', 'manageSettings', Object.assign({idc: this.idc}, data), {callback: this._manageSettings});
    },

    _deleted(data) {
      if(data.status == 'OK') {
  			this.resetToEditModeAfterCallback(data);
      }
    },

    _saveSettings(data) {
			if(data.status == 'OK') {
				this.resetToEditModeAfterCallback(data);
      }

			// Emit our change
			this.$emit('changed');
    },

    _manageSettings(data) {
      this.form.values = {};
      if(data.resultset) {
				this.data = data.resultset;

        this.setAppointmentTypes(this.data);

        //if appoiment type is selected: fill all the fields and set products
        if(data.resultset.appointment_type_selected && !this.first) {
					//set data
          this.form.values = this.data.appointment_type_selected;
          this.fab.appointmentType.buttons.remove.render = true;

					// Set the fields with the selected appointment type
          this.setFields(this.data.appointment_type_selected);

          //set products
          this.setProducts(this.data.invoice_product);
        }
      }

      this.render = true;
    },
  },

  created() {
    this.first = true;
    this.getSettings();
  }
};
</script>
