<template>
  <page :title="$LanguageHelper.translate('_HOURS_OVERVIEW_')" @initiated="go">
		<fixed-top :resized="render">
			<list-form :params="form" @changed="changed" />
		</fixed-top>

		<template v-if="render">
			<template v-if="employeesAvailable">
				<f7-card class="marginTop30">
					<f7-card-header>{{$LanguageHelper.translate('_TOTALS_OVER_SELECTED_PERIOD_')}}</f7-card-header>
					<f7-card-content>
						<div>{{$LanguageHelper.translate('_NUMBER_APPOINTMENTS_')}}: {{hours.total.appointments}}</div>
						<div>{{$LanguageHelper.translate('_NUMBER_SUBSCRIPTIONS_')}}: {{hours.total.subscriptions}}</div>
						<div>{{$LanguageHelper.translate('_DURATION_IN_HOURS_')}}: {{hours.total.hours}}</div>
					</f7-card-content>
				</f7-card>
				
				<template v-for="(day, key) in hours.days" :key="key" >
					<f7-card v-if="hours.days && hours.days.length > 0">
						<f7-card-header> {{$FunctionsHelper.ucFirst($LanguageHelper.translate($LanguageHelper.dayLabels[moment(day.date).isoWeekday() - 1]))}} {{$LanguageHelper.formatDate(day.date)}}</f7-card-header>
						<f7-card-content>
							<div>{{$LanguageHelper.translate('_NUMBER_APPOINTMENTS_')}}: {{day.total}}</div>
							<div>{{$LanguageHelper.translate('_NUMBER_SUBSCRIPTIONS_')}}: {{day.subscriptions}}</div>
							<div>{{$LanguageHelper.translate('_DURATION_IN_HOURS_')}}: {{day.hours}}</div>
							<a class="link theme marginTop5" @click="day.opened= !day.opened"><b></b>{{$LanguageHelper.translate('_SHOW_APPOINTMENTS_')}}...</a>

							<div class="marginTop5" v-if="day.opened">
								<div v-for="(appointment, key2) in day.appointments" :key="key2" class="marginBottom5">
									<div><b>{{appointment.name}}</b></div>
									<div>{{$LanguageHelper.translate('_FROM_')}} {{appointment.time_start}}  {{$LanguageHelper.translate('_DATE_TO_')}} {{appointment.time_end}}</div>
									<div>{{$LanguageHelper.translate('_NUMBER_SUBSCRIPTIONS_')}}: {{appointment.subscriptions}}</div>

									<div class="marginTop10"><b>{{$FunctionsHelper.ucFirst($LanguageHelper.translate('_SUBSCRIPTIONS_'))}}</b></div>
									<div v-for="(user, index) in appointment.subscription_users" :key="index">
										{{user.fullname}} ({{user.email}})
									</div>
								</div>
							</div>
						</f7-card-content>
					</f7-card>
				</template>
			</template>

			<template v-else>
				<f7-card class="marginTop30">
					<f7-card-header>{{$LanguageHelper.translate('_N0_TRAINERS_CONNECTED_TO_AGENDAS_')}}</f7-card-header>
					<f7-card-content>
						<div>{{$LanguageHelper.translate('_N0_TRAINERS_CONNECTED_TO_AGENDAS_CONTENT_')}}</div>
					</f7-card-content>
				</f7-card>
			</template>
		</template>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import FixedTop from '../components/smart/fixed-top.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
  components: {
    'page': Page,
    'fixed-top': FixedTop,
		'list-form': ListForm,
  },

	props: {
		idc: {required: true},
	},

  data() {
    return {
			render: false,
			employeesAvailable: false,

			form: {
				fields: {
					// List with employees
					id_appuser: {type: 'select', name: 'id_appuser', title: '_TRAINERS_', render: false, options: []},

					date: {name: 'dateRange', type: 'combined', class: 'toplist date', render: false,
						fields: {
							date_start: {type: 'date', name: 'date_start', placeholder: '_DATE_FROM_', value: moment().set('date', 1).format('YYYY-MM-DD')},
							text: {type: 'label', text: '_UNTIL_LOWER_'},
							date_end: {type: 'date', name: 'date_end', placeholder: '_DATE_UNTIL_', value: moment().add(1, 'month').set('date', 1).format('YYYY-MM-DD')},
						},
					},
				}
			},

			hours: []
    };
  },

	computed: {
		employeeSelected() {
			return this.form.fields.id_appuser.options.length > 0 ? (Object.values(this.form.fields.id_appuser.options).find(option => option.selected)).value : null;
		},

    dateStart() {
			return this.form.fields.date.fields.date_start.value;
		},

		dateEnd() {
			return this.form.fields.date.fields.date_end.value;
		}
  },

	methods: {
		changed(field, option) {
			// Initial call is also send from here (change event is fired whenever date values are set)!
			if(field.name == 'dateRange' || field.name == 'id_appuser') this.load();
		},

		load() {
			$AjaxHelper.doRequest('agenda', 'getEmployeeHours', this.constructRequestData(), {callback: (data) => {
				// Set the employees
				this.form.fields.id_appuser.options = [];
				if(data.employees && data.employees.length > 0) {
					data.employees.forEach((employee) => {
						this.form.fields.id_appuser.options.push({
							label: employee.name,
							value: employee.id_appuser,
							selected: employee.selected == 1
						});
					});

					// Render the form and page
					this.hours = data.resultset;
				}

				this.employeesAvailable = data.employees && data.employees.length > 0;
				this.form.fields.id_appuser.render = this.employeesAvailable;

				this.form.fields.date.render = true;
				this.render = true;
			}});
		},

		constructRequestData() {
			return {
				idc: this.idc,
				id_appuser: this.employeeSelected,

				date_range: {
					 start: this.dateStart,
					 end: this.dateEnd
				},
			};
		},

		go() {
			this.form.fields.date.render = true;
		}
	}
};
</script>
