export default  {
	styleVars: {
		theme_color: '--theme-color',
		navbar_bg_color: '--navbar-bg-color',
		navbar_color: '--navbar-color',
		btn_bg_color: '--btn-bg-color',
		btn_color: '--btn-color',
	},

	setStyles(idc, data, apply) {
		var versionData = {
			version: data.version,
			version_pnp: data.version_pnp
		};

		delete data.version;
		delete data.version_pnp;

		var newStyles = {
			timestamp: new Date().getTime(),
			idc: idc,
			version: versionData.version,
			version_pnp: versionData.version_pnp,
			styles: data
		};

		var stylesAllCompanies = this.getStylesAllCompanies();
		if(!stylesAllCompanies) {
			stylesAllCompanies = [];
			stylesAllCompanies.push(newStyles);
		} else {
			var stylesCompany = this.getStylesCompany(idc);
			if(stylesCompany) {
				stylesAllCompanies[stylesCompany.index] = newStyles;
			} else {
				stylesAllCompanies.push(newStyles);
			}
		}

		this.storeStyles(stylesAllCompanies);

		if(apply) {
			this.applyStyles(idc)
		}
	},

	storeStyles(styles) {
		localStorage.setItem('styles', JSON.stringify(styles));
	},

	getVersion(idc) {
		var stylesCompany = this.getStylesCompany(idc);
		if(stylesCompany) {
			return {
				version: stylesCompany.version,
				version_pnp: stylesCompany.version_pnp
			};
		}

		return {
			version: 0,
			version_pnp: 0
		};
	},

	getStylesCompany(idc) {
		var stylesAllCompanies = this.getStylesAllCompanies();
		var stylesCompany = null;
		if(stylesAllCompanies && stylesAllCompanies.length > 0) {
			$.each(stylesAllCompanies, function (index, obj) {
				if(obj.idc == idc) {
					obj.index = index;
					stylesCompany = obj;

					return;
				}
			});
		}

		return stylesCompany;
	},

	getStylesAllCompanies() {
		var styles = localStorage.getItem('styles');
		if(styles) {
			return $.parseJSON(styles);
		}

		return null;
	},

	updateCompanyStyleTimestamp(idc) {
		var stylesAllCompanies = this.getStylesAllCompanies();
		var stylesCompany = this.getStylesCompany(idc);

		if(stylesCompany) {
			stylesAllCompanies[stylesCompany.index].timestamp = new Date().getTime();
			this.storeStyles(stylesAllCompanies);
		}

		var stylesCompany = this.getStylesCompany(idc);
	},

	cleanupOldStyles() {
		var stylesAllCompanies = this.getStylesAllCompanies();
		if(stylesAllCompanies && stylesAllCompanies.length > 0) {
			$.each(stylesAllCompanies, function (index, obj) {
				if(obj) {
					var lastUpdated = new Date(obj.timestamp );
					var dateToExpire = new Date(lastUpdated.getTime() + (3600000 * 24 * 7));

					//Clean it up, it has expired!
					if(new Date().getTime() > dateToExpire.getTime()) {
						stylesAllCompanies.splice(index, 1);
					}
				}
			});

			this.storeStyles(stylesAllCompanies);
		}
	},

	applyStyles(idc, reset) {
		//Update timestamp here and check if style has already been written to the dom!
		if(idc > 0) {
			this.updateCompanyStyleTimestamp(idc);
			this.cleanupOldStyles();
		}

		this.writeStyles(idc, reset);
	},

	writeStyles(idc, reset) {
		var data = this.getStylesCompany(idc);
		if(!data || reset) {
			this.resetStyles();
			return false;
		}

		// Write company styles
		this.writeStylesCompany(data.styles);
	},

	writeStylesCompany(stylesCompany) {
		// Set text colors first
		if(stylesCompany.btn_color != '') {
			this.writeCSSVar('btn_color', stylesCompany.btn_color);
		}

		if(stylesCompany.navbar_color != '') {
			this.writeCSSVar('navbar_color', stylesCompany.navbar_color);
		}

		// Set background colors, use theme color if component color is empty!
		if(stylesCompany.theme_color != '') {
			this.writeCSSVar('theme_color', stylesCompany.theme_color);
		}

		if(stylesCompany.navbar_bg_color != '') {
			this.writeCSSVar('navbar_bg_color', stylesCompany.navbar_bg_color);
		} else if(stylesCompany.theme_color != '') {
			this.writeCSSVar('navbar_bg_color', stylesCompany.theme_color);
		}

		if(stylesCompany.btn_bg_color != '') {
			this.writeCSSVar('btn_bg_color', stylesCompany.btn_bg_color);
		} else if(stylesCompany.theme_color != '') {
			this.writeCSSVar('btn_bg_color', stylesCompany.theme_color);
		}
	},

	resetStyles() {
		// Reset the styles here to original values!
		this.writeCSSVar('theme_color', 'var(--theme-color-org)');
		this.writeCSSVar('navbar_bg_color', 'var(--navbar-bg-color-org)');
		this.writeCSSVar('navbar_color', 'var(--navbar-color-org)');
		this.writeCSSVar('btn_bg_color', 'var(--btn-bg-color-org)');
		this.writeCSSVar('btn_color', 'var(--btn-color-org)');
	},

	writeCSSVar(name, value) {
		var styleEl = $('body')[0].style;
		styleEl.setProperty(this.styleVars[name], value);
	},

	getCSSvarValue(name, org) {
		var cssVarName = this.styleVars[name];
		if(org) cssVarName += '-org';

		return window.getComputedStyle($('body')[0]).getPropertyValue(cssVarName);
	}
}
