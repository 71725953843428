<template>
	<page
		:title="title"
		:back="false"
		:panel="false"
		:toolbarBranding="true"
		:backgroundImage="backgroundImage"
		:visible="visible"
		pageContentClass="companyBackground"
	>

		<f7-block>
			<form v-if="page == 'invite'">
				<f7-card class="cardCenter">
					<f7-card-header>{{$LanguageHelper.translate('_INVITE_')}}</f7-card-header>

					<f7-card-content>
						{{$LanguageHelper.translate('_YOU_WERE_INVITED_BY_')}} {{$FunctionsHelper.specialChars(company.name)}}
						<br /><br />{{$LanguageHelper.translate('_AWARE_PERSONAL_DATA_')}}.
					</f7-card-content>

					<f7-list>
						<li>
							<checkbox-agree-to-terms v-if="company.companyTerms"
								:link="company.companyTerms.url_terms_and_conditons"
								:text-label="$LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS1_') + ' ' + $LanguageHelper.translate('_AGREE_WITH_COMPANY_TERMS2_')"
								:text-link="company.name"
								:checked="form.companyTerms"
								@changed="(val) => form.companyTerms = +val /* Convert bool to int with the + char */"
							/>
						</li>
					</f7-list>

					<f7-block strong>
						<f7-button fill @click.prevent="submitted">{{$LanguageHelper.translate('_ACCEPT_INVITE_')}}</f7-button>
					</f7-block>
				</f7-card>
			</form>

			<form v-else-if="page == 'message'">
				<message-to-page-button :msg="msg" @click="$ViewHelper.toWindow('/')" :btnText="$LanguageHelper.translate('_GO_TO_HOME_PAGE_')" />
			</form>
		</f7-block>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import CheckboxAgreeToTerms from '../components/checkbox-agree-to-terms.vue';
import MessageToPageButton from '../components/message-to-page-button.vue';

export default {
	components: {
    'page': Page,
		'checkbox-agree-to-terms': CheckboxAgreeToTerms,
		'message-to-page-button': MessageToPageButton,
  },

  data() {
    return {
			page: 'invite',
			visible: false,
			queryParams: {},
			title: this.$Config.apps[this.$Config.app.currentApp].displayName,
			msg: '',
			backgroundImage: '',
			company: {},
			form: {companyTerms: false}
    };
  },

  methods: {
		submitted() {
			var formData = JSON.parse(JSON.stringify(this.form));

			// Do request to connect the user to this company
			$AjaxHelper.doRequest('gym', 'addGymToUser', Object.assign({idc: this.queryParams.idc}, formData), {callback: this._addCompanyToUser});
		},

		_addCompanyToUser(data) {
			// Set the response notice from the WS if it's there
			if(data.notice) this.msg = data.notice;

			if(data.status == 'OK') {
				// Show the go to home page again with the message!
				this.page = 'message';
			}
		},

		_getCompanyComplete(data) {
			this.visible = true;

			if(!data.resultset) return;

			//Set the company data here!
			this.company = data.resultset

			// Set background images if there are any
			if(data.companyImages && data.companyImages.bg) this.backgroundImage = data.companyImages.bg;

			// Set company name in navbar title
			this.title = this.company.name;
		},

		_validateCompanyInvite(data) {
			// If the link is invalid show the message page!
			if(data.status == 'OK') {
				if(data.notice != '') {
					this.msg = data.notice
					this.page = 'message';
					this.visible = true;

					return false;
				}

				//Log user in and get the company info.
				$UserHelper.login({
					id_user: data.id_user,
					firstname: data.firstname,
					token: data.token,
					accesslevels: data.accesslevels
				});

				// Now do get the company info to show the invite!
				$AjaxHelper.doRequest('gym', 'getGymComplete', {id: this.queryParams.idc, idc: this.queryParams.idc, companyImages: 1}, {callback: this._getCompanyComplete});
			}
		}
  },

	created() {
		// First validate the invite!
		this.queryParams = $FunctionsHelper.getAllQueryParams(window.location.href);
		$AjaxHelper.doRequest('user', 'validateCompanyInvite', {id_appuser: this.queryParams.id_appuser, invite_t: this.queryParams.invite_t, idc: this.queryParams.idc}, {callback: this._validateCompanyInvite});
	}
};
</script>
