<template>
  <page
		:title="$LanguageHelper.translate('_SUBSCRIPTIONS_SEPA_')"
		:infinite="true"
		:search="true"
		:sort-options="sortOptions"
		:fab="fab"
		@infinite-scroll-page-end="pageEndReached"
		@searched="searched"
		@sort-selected="sortSelected"
		@fab-clicked="fabClicked"
		@initiated="go"
		class="ManageDirectDebits"
		>

		<fixed-top :resized="directDebits.info.render" v-show="directDebits.info.render">
			<f7-list no-hairlines class="displayBlockItemInner toplistInfo">
				<f7-list-item>
					<div>{{$LanguageHelper.translate('_DIRECT_DEBITS_TOTAL_NUMBER_ITEMS_')}}: {{directDebits.info.number_items_active}}</div>
					<div>{{$LanguageHelper.translate('_DIRECT_DEBITS_TOTAL_')}}: &euro; {{directDebits.info.amount_total}}</div>
				</f7-list-item>
			</f7-list>
		</fixed-top>

		<f7-list media-list inset v-if="render">
			<list-item v-for="(directDebit, key) in directDebits.list" class="clickable" @click="directDebit.opened=true" type="accordion"
				:key="`key_${key}`"
				:title="$FunctionsHelper.specialChars(directDebit.name)"
				:after="`€ ${directDebit.amount}`"
				:subtitle="directDebit.date_process_direct_debit"
				:text="$FunctionsHelper.specialChars(directDebit.description)"
				:iconClass="getDirectDebitStatusClass(directDebit).length > 0 ? `ionInformationCircle orange ${getDirectDebitStatusClass(directDebit)}` : ''"
				>

				<manage-direct-debit v-if="directDebit.opened" :idc="idc" :params="directDebit" @changed="edited($event, key)" @close="directDebit.opened=false" />
			</list-item>
		</f7-list>

		<!-- We can't use the fab click here directly because we need to fetch data first before we can open the popup -->
		<manage-direct-debit-data v-if="addOpened" :idc="idc" :directDebit="directDebitNew" @changed="added" @close="addOpened=false" />

		<!-- Planned changes popup -->
		<manage-direct-debit-planned-changes v-if="fab.buttons.plannedChanges.clicked" :idc="idc" @close="fab.buttons.plannedChanges.clicked=false" />
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';
import FixedTop from '../components/smart/fixed-top.vue';
import ListForm from '../components/smart/list-form.vue';
import ListItem from '../components/smart/list-item.vue';
import ManageDirectDebit from '../popups/manage-direct-debit/index.vue';
import ManageDirectDebitData from '../popups/manage-direct-debit-data.vue';
import ManageDirectDebitPlannedChanges from '../popups/manage-direct-debit-planned-changes.vue';

export default {
  components: {
    'page': Page,
		'fixed-top': FixedTop,
		'list-form': ListForm,
		'list-item': ListItem,
		'manage-direct-debit': ManageDirectDebit,
		'manage-direct-debit-data': ManageDirectDebitData,
		'manage-direct-debit-planned-changes': ManageDirectDebitPlannedChanges,
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			render: false,
			infiniteDone: false,
			addOpened: false,

			directDebitNew: {},

			// Floating action buttons
			fab: {
				position: 'right-bottom',
				buttons: {
					add: {name: 'add', label: '_CREATE_NEW_', icon: 'icon ionAdd', render: true, clicked: false},
					plannedChanges: {name: 'plannedChanges', label: '_EDIT_MULTIPLE_SUBSCRIPTIONS_', icon: 'icon ionEdit', render: true, clicked: false},
				}
			},

			// Sort options
			sortOptions: {
				title: '_SORT_AND_FILTER_',
				options: {
					date_sale_desc: {value: 'date_sale_desc', label: '_DATE_DESC_', selected: true},
					first_asc: {value: 'first_asc', label: '_ASCENDING_INSTALLMENT_', selected: false},
					last_desc: {value: 'last_desc', label: '_DESCENDING_INSTALLMENT_', selected: false},
					name_asc: {value: 'name_asc', label: '_SORT_NAME_CUSTOMER_A_Z_', selected: false},
					name_desc: {value: 'name_desc', label: '_SORT_NAME_CUSTOMER_Z_A_', selected: false},
					active_desc: {value: 'active_desc', label: '_ACTIVE_', selected: false},
					active_asc: {value: 'active_asc', label: '_INACTIVE_', selected: false},
					pauzed_asc: {value: 'pauzed_asc', label: '_PAUSE_', selected: false},
					deactive_asc: {value: 'deactive_asc', label: '_FUTURE_DEACTIVATIONS_', selected: false},
					change_asc: {value: 'change_asc', label: '_CHANGE_PLANNED_', selected: false},
				}
			},

			// Sales data
			directDebits: {
				info: {
					render: false,
					amount_total: 0,
					number_items_active: 0
				},

				list: [],
			}
    };
  },

	computed: {
    orderBy() {
			return (Object.values(this.sortOptions.options).find(option => option.selected)).value;
		}
  },

	methods: {
		// This event method gets called when a direct debit is edited or (de)actived
		edited(id, index) {
			// Get the direct debit and then overwrite the item with the new data!
			$AjaxHelper.doRequest('payment', 'getSubscription', {idc: this.idc, id: id}, {callback: (data) => {
				// Different names are used for the list and a single item so copy the data the way the list expects it!
				let obj = {
					name: `${data.resultset.sale.firstname} ${data.resultset.sale.lastname}`,
					amount: data.resultset.sale.amount_in_vat,
					date_process_direct_debit: data.resultset.sale.date_process_direct_debit_display,
					description: data.resultset.sale.name,
					status: {id: data.resultset.sale.status.id}
				};

				this.directDebits.list[index] = Object.assign(this.directDebits.list[index], obj);
			}});
		},

		// This event is called when a new direct debit is added
		added(id, index) {
			$AjaxHelper.doRequest('payment', 'getSubscription', {idc: this.idc, id: id}, {callback: (data) => {
				// Different names are used for the list and a single item so copy the data the way the list expects it!
				let obj = {
					name: `${data.resultset.sale.firstname} ${data.resultset.sale.lastname}`,
					amount: data.resultset.sale.amount_in_vat,
					date_process_direct_debit: data.resultset.sale.date_process_direct_debit_display,
					description: data.resultset.sale.name,
					status: {id: data.resultset.sale.status.id},
					id: data.resultset.sale.id,
					id_invoice_product_sale_group: data.resultset.sale.id_invoice_product_sale_group,
					opened: false,
				};

				// Add item to beginning of the list
				this.directDebits.list.unshift(obj);
			}});
		},

		getDirectDebitStatusClass(directDebit) {
			let statusClass = '';
			if(directDebit.status.id != 1 && directDebit.status.id != 6) {
				statusClass = 'orange';
			} else if (directDebit.status.id == 6) {
				statusClass = 'red';
			}

			return statusClass;
		},

		fabClicked(fab) {
			switch(fab.name) {
			  case 'add':
					$AjaxHelper.doRequest('payment', 'subscriptionNew', {idc: this.idc}, {callback: (data) => {
						// Store the data the manage direct debit component needs!
						this.directDebitNew['countries'] = data.resultset.countries;
						this.directDebitNew['languages'] = data.resultset.languages;
						this.directDebitNew['products'] = data.resultset.products;
						this.directDebitNew['periods'] = data.resultset.periods;
						if(data.agendaEnabled) this.directDebitNew['agendaEnabled'] = data.agendaEnabled;

						// Open the popup now!
						this.addOpened = true;
					}});

			    break;
			}
		},

		sortSelected() {
			this.load(true);
		},

		pageEndReached() {
			this.load();
		},

		load(firstOrReset = false) {
			if(firstOrReset)  {
				this.destroyInfinite();
				this.directDebits.list = [];
			}

			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getSubscriptions', this.constructRequestData(), {callback: this[firstOrReset ? '_directDebitsInit' : '_directDebits']});
		},

		searched(val) {
			// Empty current list
			this.directDebits.list = [];

			// Search for sales
			if(val.length > 0) {
				$AjaxHelper.doRequest('payment', 'searchSubscriptions', Object.assign({search: val}, this.constructRequestData()), {callback: this._directDebitsInit});
			}
			// No input, reset the list!
			else {
				this.load(true);
			}
		},

		constructRequestData() {
			return {
				idc: this.idc,
				order: this.orderBy,
			};
		},

		storeData(data, init) {
			if(init) {
				this.directDebits.info.amount_total = data.resultset.amount_total;
				this.directDebits.info.number_items_active = data.resultset.number_items_active;
				this.directDebits.info.render = true;
			}

			if(data.resultset.subscriptions && data.resultset.subscriptions.length > 0) {
				data.resultset.subscriptions.forEach((obj, key) => {obj.opened = false});
				this.directDebits.list.push(...data.resultset.subscriptions);
			} else this.infiniteDone = true;
		},

		destroyInfinite() {
			this.infiniteDone = false
			$AjaxHelper.destroyInfinite('getSubscriptions');
		},

		_directDebitsInit(data) {
			this.storeData(data, true);
			this.render = true
		},

		_directDebits(data) {
			this.storeData(data);
		},

		go() {
			this.load(true);
		}
	},
};
</script>
