<template>
	<popup class="myAppointments" :title="$LanguageHelper.translate('_MY_APPOINTMENTS_')"
    :opened="opened"
		:infinite="true"
    @mounted="opened=true"
		@infinite-scroll-page-end="pageEndReached=true"
    @close="$emit('close')"
  >
		<agenda-my-appointments :idc="idc" :page-end-reached="pageEndReached" @page-end-loaded="pageEndReached=false" @changed="$emit('changed')" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import AgendaMyAppointments from '../components/agenda-my-appointments.vue';

export default {
  components: {
		'popup': Popup,
    'agenda-my-appointments': AgendaMyAppointments,
  },

  props: {
		idc: {required: true},
	},

	data() {
		return {
			opened: false,
			pageEndReached: false,
		}
	}
};
</script>
