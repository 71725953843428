<template>
	<popup v-if="render" :title="title" :opened="opened" @mounted="opened=true" @close="$emit('close')">
	  <f7-card>
	    <f7-card-content>
				<!-- Show add to own calendar button if the user is subscribed to this appointment!  -->
				<add-to-calendar-button v-if="showAddToCalendarButton" :urls="appointment.calendar_sync_urls" class="marginBottom25" />

				<!-- Appointment that is not over yet, show subscribe data and conditions -->
	      <template v-if="!appointmentOver">
	        <div v-if="appointment.subscribe_closed == 1" class="item-title fontOrange contentItem"><b>{{$LanguageHelper.translate('_SUBSCRIBE_NO_LONGER_POSSIBLE_')}}</b></div>
					<div v-if="appointment.subscriptions.total >= appointment.max_subscriptions" class="item-title fontOrange contentItem"><b>{{$LanguageHelper.translate('_APPOINTMENT_FULL_')}}</b></div>

					<!-- Subscribe not open yet -->
					<div v-if="appointment.subscribe_not_opened_yet == 1" class="item-title fontOrange contentItem"><b>{{$LanguageHelper.translate('_SUBSCRIBE_ALLOWED_FROM_')}} {{appointment.subscription_open_date}}</b></div>

					<!-- Subscribe opened -->
	        <template v-else>
	          <!-- Max per day reached -->
	          <div v-if="appointment.max_subscriptions_per_day_reached == 1" class="item-title fontOrange contentItem"><b>{{$LanguageHelper.translate('_MAX_NUMBER_SUBSCRIPTIONS_PER_DAY_REACHED_')}}</b></div>

						<!-- Subscribe yourself here -->
	          <template v-if="appointment.can_pay == 1 && appointment.subscribe_closed != 1 && appointment.subscriptions.full != 1">
	            <!-- Show how many credits wil be booked from account -->
	            <template v-if="appointment.number_credits > 0">
	              <div v-if="appointment.installments == 1" class="item-title contentItem">{{$LanguageHelper.translate('_WHEN_SUBSCRIBING_')}} {{appointment.number_credits}} {{$LanguageHelper.translate('_CREDITS_BOOKED_SUBSCIPTION_HOLDERS_')}}</div>
	              <div v-else class="item-title contentItem">{{$LanguageHelper.translate('_WHEN_SUBSCRIBING_')}} {{appointment.number_credits}} {{$LanguageHelper.translate('_CREDITS_BOOKED_')}}</div>
	            </template>

	            <f7-button v-if="appointment.subscriptions.subscribed != 1" @click="subscribe" small fill raised class="contentItem">{{$LanguageHelper.translate('_SUBSCRIBE_TO_APPOINTMENT_')}}</f7-button>
	          </template>

	          <!-- Unsubscribe yourself here -->
	          <div v-if="appointment.subscriptions.subscribed == 1" class="contentItem">
	            <div v-if="appointment.unsubscribe_not_allowed == 1" class="item-title fontOrange"><b>{{$LanguageHelper.translate('_SUBSCRIBED_UNSUBSCRIBE_SELF_NOT_ALLOWED_')}}</b></div>
	            <f7-button v-else-if="appointment.unsubscribe_closed != 1" @click="unsubscribe" small fill raised>{{$LanguageHelper.translate('_UNSUBSCRIBE_FROM_APPOINTMENT_')}}</f7-button>
	            <div v-else class="item-title"><b>{{$LanguageHelper.translate('_UNSUBSCRIBE_NO_LONGER_POSSIBLE_')}}</b></div>
	          </div>

						<!-- Waitinglist here -->
	          <div v-if="appointment.waiting_list == 1 && appointment.subscriptions.full == 1 && appointment.subscribe_closed != 1 && appointment.subscriptions.subscribed != 1" class="contentItem">
	            <f7-button v-if="appointment.subscriptions.subscribed_waiting_list == 1" @click="unsubscribeWaitinglist" small raised fill>{{$LanguageHelper.translate('_UNSUBSCRIBE_WAITINGLIST_')}}</f7-button>
	            <f7-button v-if="appointment.subscriptions.subscribed_waiting_list != 1 && appointment.can_pay == 1" @click="subscribeWaitinglist" small raised fill>{{$LanguageHelper.translate('_SUBSCRIBE_WAITINGLIST_')}}</f7-button>
	          </div>

	          <!-- Show partner users with subscribe and unsubscribe options-->
	          <template v-if="$UserHelper.isLoggedIn() && appointment.number_appuser_partners && appointment.number_appuser_partners > 0">
	            <f7-row class="contentItem marginTop25">
	              <f7-col width="10">
	                <div class="appointmentIcon"><f7-icon class="icon ionPersonAdd themeDetail"></f7-icon></div>
	              </f7-col>

	              <f7-col width="90">
	                <div class="item-title"><b>{{$LanguageHelper.translate('_FAMILY_MEMBERS_')}}</b></div>
									<template v-for="(partner, index) in appointment.partner_users" :key="index">
											<div v-if="appointment.partner_users && appointment.partner_users.length > 0">
											<div class="item-title contentItem marginTop5">{{$FunctionsHelper.specialChars(partner.firstname)}} {{$FunctionsHelper.specialChars(partner.lastname)}}</div>

											<div class="marginTop5">
												<!-- Unsubscribe to appointment-->
												<div v-if="appointment.subscriptions.partners && appointment.subscriptions.partners.includes(partner.id_appuser_partner_user)" class="contentItem">
													<div v-if="appointment.unsubscribe_not_allowed == 1" class="item-title">{{$LanguageHelper.translate('_SUBSCRIBED_UNSUBSCRIBE_SELF_NOT_ALLOWED_')}}</div>
													<f7-button v-else-if="appointment.unsubscribe_closed != 1" @click="unsubscribe(partner.id_appuser_partner_user)" small raised fill>{{$LanguageHelper.translate('_UNSUBSCRIBE_FROM_APPOINTMENT_')}}</f7-button>
													<div v-else class="item-title">{{$LanguageHelper.translate('_UNSUBSCRIBE_NO_LONGER_POSSIBLE_')}}</div>
												</div>

												<div v-else class="contentItem">
													<!-- Subscribe to appointment-->
													<f7-button v-if="appointment.can_pay == 1 && appointment.subscribe_closed != 1 && appointment.subscriptions.full != 1" @click="subscribe(partner.id_appuser_partner_user)" small raised fill>{{$LanguageHelper.translate('_SUBSCRIBE_TO_APPOINTMENT_')}}</f7-button>
													<!-- Waitinglist subscribe/unsubscribe -->
													<f7-button v-if="appointment.waiting_list == 1 && appointment.waitinglist.partners && appointment.waitinglist.partners.includes(partner.id_appuser_partner_user)" @click="unsubscribeWaitinglist(partner.id_appuser_partner_user)" small raised fill>{{$LanguageHelper.translate('_UNSUBSCRIBE_WAITINGLIST_')}}</f7-button>
													<f7-button v-else-if="appointment.waiting_list == 1 && appointment.subscriptions.full == 1 && appointment.can_pay == 1" @click="subscribeWaitinglist(partner.id_appuser_partner_user)" small raised fill>{{$LanguageHelper.translate('_SUBSCRIBE_WAITINGLIST_')}}</f7-button>
												</div>
											</div>
										</div>
									</template>

									<f7-link @click="showPartnerUsers=true" class="marginTop5"><b>{{$LanguageHelper.translate('_ADD_FAMILY_MEMBERS_')}}...</b></f7-link>
	              </f7-col>
	            </f7-row>
	          </template>
	        </template>

	        <!--User can't pay for appointment -->
	        <template v-if="appointment.can_pay != 1 && appointment.subscribe_closed != 1 && appointment.subscriptions.full != 1">
	          <template v-if="appointment.installments == 1 || appointment.number_credits > 0 || (gymInfo.bank_validated == 1 && !$UserHelper.isLoggedIn())">
	            <div class="contentItem">
	              <template v-if="gymInfo.bank_validated == 1">
									<template v-if="appointment.can_book && appointment.can_book == 1">
										<!-- Not enough credits notice with buy button -->
										<div class="item-title fontOrange marginBottom10"><b>{{$LanguageHelper.translate('_NOT_ENOUGH_CREDITS_FOR_APPOINTMENT_')}}</b></div>
										<f7-button @click="buyProductOpened=true" small raised fill>{{$LanguageHelper.translate('_YOU_NEED_A_PRODUCT_')}}</f7-button>
									</template>

									<!-- You can't buy a product to join this appointment (free trial only) -->
									<div v-else-if="appointment.no_product && appointment.no_product == 1" class="item-title fontOrange"><b>{{$LanguageHelper.translate('_NO_PRODUCTS_AVAILABLE_TO_BOOK_APPOINTMENT_')}}</b></div>
	              </template>

								<!-- Company does not have payments active, customer has to contact them -->
	              <template v-else>
	                <div class="item-title fontOrange"><b>{{$LanguageHelper.translate('_YOU_NEED_A_PRODUCT_CONTACT_COMPANY_')}} {{$FunctionsHelper.specialChars(gymInfo.name)}}</b></div>
	              </template>
	            </div>
	          </template>
	        </template>
	      </template>

				<!-- Appointment over notice -->
	      <div v-if="appointmentOver" class="contentItem">
	        <div class="item-title fontOrange"><b>{{$LanguageHelper.translate('_APPOINTMENT_IN_PAST_')}}</b></div>
	      </div>

				<!-- Appointment info -->
				<div class="marginTop20">
					<f7-row class="contentItem">
		        <f7-col width="10">
		          <div class="appointmentIcon"><f7-icon class="icon ionClock themeDetail"></f7-icon></div>
		        </f7-col>

		        <f7-col width="90">
		          <div class="item-title"><b>{{$LanguageHelper.translate('_DATE_')}}</b></div>
		          <div class="item-title">{{$LanguageHelper.formatDate(moment(appointment.date_start).format('YYYY-MM-DD'))}} {{$LanguageHelper.translate('_FROM_LOWER_')}} {{moment(appointment.date_start).format('HH:mm')}} {{$LanguageHelper.translate('_UNTIL_LOWER_')}} {{moment(appointment.date_end).format('HH:mm')}} {{$LanguageHelper.translate('_HOUR_')}}</div>
		        </f7-col>
		      </f7-row>

		      <f7-row class="contentItem">
	          <f7-col width="10">
	            <div class="appointmentIcon"><f7-icon class="icon ionInformationCircle themeDetail"></f7-icon></div>
	          </f7-col>

						<f7-col width="90">
							<div class="item-title"><b>{{$LanguageHelper.translate('_DESCRIPTION_')}}</b></div>
							<div class="item-title">{{$FunctionsHelper.specialChars(appointment.name)}}</div>
							<div v-if="appointment.description.length > 0" class="item-title">{{$FunctionsHelper.specialChars(appointment.description)}}</div>
						</f7-col>
	        </f7-row>

					<template v-if="appointment.location">
		        <f7-row class="contentItem">
		          <f7-col width="10">
		            <div class="appointmentIcon"><f7-icon class="icon ionLocation themeDetail"></f7-icon></div>
		          </f7-col>

		          <f7-col width="90">
		            <div class="item-title"><b>{{$LanguageHelper.translate('_LOCATION_')}}</b></div>
		            <div class="item-title">{{$FunctionsHelper.specialChars(appointment.location)}}</div>
		          </f7-col>
		        </f7-row>
		      </template>

					<!-- Show subscribers and subscription info here -->
		      <template v-if="$UserHelper.isLoggedIn()">
		        <f7-row class="contentItem">
		          <f7-col width="10">
		            <div class="appointmentIcon"><f7-icon :class="`icon ${appointment.spotsLeft == 0 || !moment(appointment.date_start).isAfter(moment()) || appointment.subscriptions.total >= appointment.max_subscriptions ? 'ionClose' : 'ionCheckmark'} themeDetail`"></f7-icon></div>
		          </f7-col>

		          <f7-col width="90">
		            <div class="item-title"><b>{{$LanguageHelper.translate('_SPOTS_AVAILABLE_INFO_')}}</b></div>
		            <div class="item-title">{{$LanguageHelper.translate('_SPOTS_AVAILABLE_')}} {{appointment.max_subscriptions}}, {{$LanguageHelper.translate('_SPOTS_LEFT_')}} {{appointment.spotsLeft}}</div>

								<template v-if="appointment.public == 1 && appointment.subscriptions.total > 0">
		              <f7-link @click="showSubscriptions=!showSubscriptions">{{$LanguageHelper.translate('_VIEW_SUBSCRIPTIONS_')}}...</f7-link>
									<template v-for="(member, index) in appointment.subscriptions.members" :key="index">
										<div v-if="showSubscriptions">{{$FunctionsHelper.specialChars(member.name)}}</div>
									</template>
		            </template>
		          </f7-col>
		        </f7-row>
		      </template>

		      <template v-if="appointment.subscribe_closed_date != '' || appointment.unsubscribe_closed_date != ''">
						<f7-row v-if="appointment.subscribe_closed_date != ''" class="contentItem">
	            <f7-col width="10">
	              <div class="appointmentIcon"><f7-icon class="icon iconTimer themeDetail"></f7-icon></div>
	            </f7-col>

	            <f7-col width="90">
	              <div class="item-title"><b>{{$LanguageHelper.translate('_SUBSCRIBE_MAX_HOURS_')}}</b></div>
	              <div class="item-title">{{appointment.number_hours_subscribe}} {{$LanguageHelper.translate('_HOURS_BEFORE_START_')}}</div>
	            </f7-col>
	          </f7-row>

						<f7-row v-if="appointment.unsubscribe_closed_date != ''" class="contentItem">
		          <f7-col width="10">
								<div class="appointmentIcon"><f7-icon class="icon iconTimer themeDetail"></f7-icon></div>
							</f7-col>

							<f7-col width="90">
		            <div class="item-title"><b>{{$LanguageHelper.translate('_UNSUBSCRIBE_MAX_HOURS_')}}</b></div>
		            <div class="item-title">{{appointment.number_hours_unsubscribe}} {{$LanguageHelper.translate('_HOURS_BEFORE_START_')}}</div>
		          </f7-col>
		        </f7-row>
		    	</template>

					<f7-row v-if="appointment.employees && appointment.employees.length > 0" class="contentItem">
						<f7-col width="10">
							<div class="appointmentIcon"><f7-icon class="icon ionPeople themeDetail"></f7-icon></div>
						</f7-col>

						<f7-col width="90">
							<div class="item-title"><b>{{$LanguageHelper.translate('_EMPLOYEES_INSTRUCTORS_')}}</b></div>
							<div v-for="(employee, index) in appointment.employees" :key="index">
								<div v-if="employee.selected == 1"  class="item-title">{{employee.name}}</div>
							</div>
						</f7-col>
					</f7-row>
				</div>
	    </f7-card-content>

	    <!-- Partner user popup -->
	    <customer-agenda-partner-user v-if="showPartnerUsers" :idc="idc" @changed="partnerUserChanged" @close="showPartnerUsers=false" />

			<!-- Buy product popup -->
	    <customer-agenda-buy-product v-if="buyProductOpened" :idc="idc" :closeMe="closeBuyProduct" :params="appointment" @submitted="buyProductSubmitted" @close="buyProductOpened=false" />
	  </f7-card>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import CustomerAgendaPartnerUser from '../popups/customer-agenda-partner-user.vue';
import CustomerAgendaBuyProduct from '../popups/customer-agenda-buy-product.vue';
import AddToCalendarButton from '../components/smart/add-to-calendar-button.vue';

export default {
  components: {
    'popup': Popup,
    'customer-agenda-partner-user': CustomerAgendaPartnerUser,
    'customer-agenda-buy-product': CustomerAgendaBuyProduct,
    'add-to-calendar-button': AddToCalendarButton,
  },

  props: {
		idc: {required: true},
    id_agenda: {required: true},
    params: {required: false},
	},

	computed: {
		appointmentOver() {
			return moment(this.appointment.date_start).isBefore(moment());
		},
  },

  data() {
    return {
      opened: false,
      buyProductOpened: false,
			closeBuyProduct: false,
      render: false,
      showSubscriptions: false,
      showPartnerUsers: false,
      showAddToCalendarButton: false,
			appointment: null, // This will contain fetched data from back-end
      gymInfo: {},
    };
  },

  methods: {
		buyProductSubmitted() {
			this.closeBuyProduct = true;

			// Timeout needed, buy product popup has to be closed first
			setTimeout(() => this.opened = false, 30);
		},

		partnerUserChanged() {
			this.fetchAppointment();
		},

    subscribe(partnerID = null) {
			$AjaxHelper.doRequest('agenda', 'subscribeAppointment', this.constructSubscribeRequestData('subscribe',partnerID), {callback: this._appointmentChanged});
    },

    unsubscribe(partnerID = null) {
			let data = this.constructSubscribeRequestData('unsubscribe', partnerID);
			let title = $LanguageHelper.translate('_NOTICE_');
			let text = $LanguageHelper.translate('_ARE_YOU_SURE_');

			if(this.appointment.unsubscribe_paid && this.appointment.unsubscribe_paid == 1) {
				// Change confirm text and title
				title = $LanguageHelper.translate('_ARE_YOU_SURE_');
				text = $LanguageHelper.translate('_QUESTION_UNSUBSCRIBE_NO_CREDITS_RETURNED_');

				// Set the no show flag for back-end
				data.no_show = 1;
			}

			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('agenda', 'subscribeAppointment', data, {callback: this._appointmentChanged});
			}}, title, text);
    },

		subscribeWaitinglist(partnerID = null) {
			$AjaxHelper.doRequest('agenda', 'subscribeWaitingList', this.constructSubscribeRequestData('subscribe', partnerID), {callback: this._appointmentChanged});
    },

    unsubscribeWaitinglist(partnerID = null) {
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('agenda', 'subscribeWaitingList', this.constructSubscribeRequestData('unsubscribe', partnerID), {callback: this._appointmentChanged});
			}});
    },

		constructSubscribeRequestData(action, partnerID) {
			let data = {
				idc: this.idc,
				id_agenda: this.id_agenda,
				id: this.appointment.id_appointment,
				date_start_org: this.appointment.date_start,
				date_end_org: this.appointment.date_end,
				action: action
			};

			if(partnerID > 0) data.id_partner = partnerID;

			return data;
		},

    fetchAppointment() {
			let data = {
				id: this.appointment.id_appointment,
				date_start: moment(this.appointment.date_start).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.appointment.date_end).format('YYYY-MM-DD HH:mm'),
			};

      $AjaxHelper.doRequest('agenda', 'appointment', Object.assign({idc: this.idc, id_agenda: this.id_agenda}, data), {callback: this._appointment});
    },

    _appointmentChanged(data) {
			if(data.status == 'OK') {
				// Store the (newly created) id appointment here so it fetches the right one!
				this.appointment.id_appointment = data.resultset.id_appointment;

				// Emit the changed event and refresh the appointment popup + calendar overview!
				this.$emit('changed');

				this.fetchAppointment();
			}
    },

    _appointment(data) {
			this.render = false;
			
			// Store the gym info and appointment title
      this.gymInfo = data.gyminfo;
      this.title = data.resultset.name;

			// Remove the date props, we want to keep the original ones, they are incorrect if we have a series appointment!
			delete data.resultset.date_start;
			delete data.resultset.date_end;

			// Store appointment data => assing a empty object {} first to force a redraw (data reactiveness)
      this.appointment = Object.assign({}, this.appointment, data.resultset);

			this.appointment.spotsLeft = (parseInt(this.appointment.max_subscriptions) - parseInt(this.appointment.subscriptions.total)) < 0 ? 0 : (parseInt(this.appointment.max_subscriptions) - parseInt(this.appointment.subscriptions.total));

			// Check if add to calendar button should be shown or not
			this.showAddToCalendarButton = false;
			if(this.appointment.subscriptions.subscribed == 1) this.showAddToCalendarButton = true;

			if(!this.showAddToCalendarButton) {
				this.appointment.partner_users.forEach((user) => {
					if(this.appointment.subscriptions.partners.includes(user.id_appuser_partner_user)) this.showAddToCalendarButton = true;
				});
			}
			
			// Render the page
      this.render = true;
		},
  },

  created() {
		// Copy first appointment data from params so we can fetch the rest from back-end
		this.appointment = this.params;

		// Fetch appointment data here
		this.fetchAppointment();
  }
};
</script>

<style scoped>
.contentItem {
  margin-bottom: 10px;
}

i.icon {
  margin-top: 5px;
}
</style>
