<template>
  <div v-show="visible">
    <fixed-top :resized="render">
      <list-form :params="form" @changed="changed"></list-form>
    </fixed-top>

    <f7-list inset>
      <list-item v-for="(items, key) in invoices" type="accordion"
        :key="key"
        :title="`€ ${items.invoice.amount}`"
        :footer="items.invoice.date_invoice"
        >

        <f7-accordion-content>
          <f7-block class="marginBottom10">
            <div v-if="items.invoice.id_payment_status == 3" class="item">{{items.invoice.type}}</div>
            <div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: {{items.invoice.invoice_number}}</div>
          </f7-block>

          <f7-block class="marginBottom10">
            <f7-row>
              <f7-col v-if="items.invoice.id_payment_status != 2">
                <f7-button :href="items.invoice.payment_invoice_link" :target="$Config.app.onDevice ? '_system' : '_blank'" class="external orange" raised fill small>{{$LanguageHelper.translate('_PAY_NOW_')}}</f7-button>
              </f7-col>

              <f7-col>
                <f7-button @click="downloadInvoice(items.invoice.id_invoice, items.invoice.st)" raised fill small>{{$LanguageHelper.translate('_DOWNLOAD_')}}</f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </list-item>
    </f7-list>
  </div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';
import ListItem from '../../components/smart/list-item.vue';

export default {
  components: {
    'list-form': ListForm,
    'fixed-top': FixedTop,
    'list-item': ListItem
  },

  props: {
    idc: {required: true},
    tabInvoicesActive: {required: false},
  },

  data() {
    return {
      render: false,
      visible: false,
      action: 'paid-invoices',
      iconWarning: 'icon ionWarning',
      errorClass: '',
			invoices: {},

      form: {
        fields: {
          paid: {type: 'select', name: 'paid', title: '_INVOICES_', render: true,
            options: {
              paidInvoices: {name: 'paid-invoices', value: '1', label: '_PAID_', selected: true},
              unpaidInvoices: {name: 'unpaid-invoices', value: '2', label: '_UNPAID_', selected: false},
            }
          },
        }
      }
    };
  },

  methods: {
    changed(field, option) {
      if(option) {
        if(option.name == 'paid-invoices') this.action = 'paid-invoices';
        if(option.name == 'unpaid-invoices') this.action = 'unpaid-invoices';
      }

      this.getInvoices(this.action)
    },

    downloadInvoice(val, type) {
      var url = $Config.app.baseURL.replace('ws.', '') + '/doi/idc/' + this.idc + '/idu/' + $UserHelper.getID() + '/st/' + type + '/id/' + val;
			$ViewHelper.toBrowser(url);
    },

    _myInvoices(data) {
      this.invoices = {};
      if(data.resultset) {
        this.invoices = data.resultset;

        this.render = true;
        this.visible = true;
      }
    },

    getInvoices(data) {
      $AjaxHelper.doRequest('payment', 'getCustomerInvoices', {idc: this.idc, action: this.action, order: 'date_sale_desc'}, {callback: this._myInvoices});
    }
  },

  created() {
    //check if we need the unpaid invoices
    if(this.tabInvoicesActive) {
      this.action = 'unpaid-invoices';
      this.form.fields.paid.options.paidInvoices.selected = false;
      this.form.fields.paid.options.unpaidInvoices.selected = true;
    }

    this.getInvoices();
	},
}
</script>
