<template>
	<popup :title="$LanguageHelper.translate('_CHANGE_DIRECT_DEBIT_AMOUNTS_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<template v-if="loaded">
			<list-form v-if="hasItems" :params="form" @changed="changed" @submitted="submitted"></list-form>
			<f7-block v-else>{{$LanguageHelper.translate('_NO_DATA_AVAILABLE_')}}</f7-block>
		</template>

		<template v-else>
			<f7-block>{{$LanguageHelper.translate('_FETCH_DATA_WAIT_')}}</f7-block>
		</template>
	</popup>
</template>

<script>
import FormCheckboxGroupAction from '../js/mixins/formCheckboxGroupAction';
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	mixins: [FormCheckboxGroupAction],

	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
  },

	data() {
    return {
			opened: false,
			hasItems: false,
			loaded: false,

			form: {
				fields: {
					description: {type: 'description', text: '', class: 'textPayAttention', render: true},
					date_start: {type: 'date', label: '_DATE_START_', placeholder: '_SELECT_DATE_', render: true, value: ''},
					amount_in_vat: {type: 'input', label: '_AMOUNT_IN_VAT_', placeholder: '_ENTER_HERE_', render: true, value: ''},
					deactivations: {name: 'deactivations', type: 'checkbox_group', items: [], render: true},
					warning: {type: 'description', text: '', class: 'textPayAttention', render: true},
					selected: {name: 'selected', type: 'checkbox_group', items: [], render: true},
				},

				submit: {text: '_SAVE_', render: true},

				values: {},
			}
		};
  },

	methods: {
		changed(field, action) {
			// Check/uncheck, open/close group checkboxes
			if(field.name == 'deactivations') this.handleCheckboxGroupAction(field, this.form.fields.deactivations.items, action);
			if(field.name == 'selected') this.handleCheckboxGroupAction(field, this.form.fields.selected.items, action);
		},

		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			$AjaxHelper.doRequestExtraLogin('payment', 'saveMultipleSubscriptionsNew', Object.assign({idc: this.idc}, data), {callback: this._saved});
		},

		_directDebits(data) {
			if(data.status == 'OK') {
				this.loaded = true;

				// Set the planned for deactivation (not editable) ones!
				if(data.resultset.deactivations && data.resultset.deactivations.subcontent && data.resultset.deactivations.subcontent.length > 0) {
					this.hasItems = true;

					this.form.fields.deactivations.items.push({
						name: 'deactivations',
						level: 1,
						ref: 1,
						title: data.resultset.deactivations.name,
						toggled: false,
						checked: false,
						render: true,
						disabled: true,
						class: 'themeLink',
					});

					data.resultset.deactivations.subcontent.forEach((item, index) => {
						this.form.fields.deactivations.items.push({
							name: 'deactivations',
							level: 2,
							ref: 1,
							title: item.name,
							description: item.email,
							checked: false,
							render: false,
							disabled: true,
							class: 'disabled'
						});
					});
				}

				// Set the regular (editable) ones!
				if(data.resultset.content && data.resultset.content.length > 0) {
					this.form.fields.description.text = data.resultset.description;
					this.form.fields.warning.text = data.resultset.warning;

					this.hasItems = true;

					// Main (select all) checkbox
					this.form.fields.selected.items.push({
						name: 'selected',
						level: 0,
						parentRef: 0,
						title: '_DIRECTS_DEBIT_TO_CHANGE_',
						checked: false,
						render: true,
						class: 'bold'
					});

					// Group (toggle) checkboxes
					data.resultset.content.forEach((content, index) => {
						this.form.fields.selected.items.push({
							name: 'selected',
							level: 1,
							ref: content.id,
							parentRef: 0,
							title: content.name,
							toggled: false,
							checked: false,
							render: true,
							class: 'themeLink',
						});

						// Sub item checkboxes with actual values in it!
						content.subcontent.forEach((subcontent, index) => {
							this.form.fields.selected.items.push({
								name: 'selected',
								level: 2,
								ref: content.id,
								parentRef: 0,
								title: subcontent.name,
								description: subcontent.info,
								checked: false,
								render: false,
								value: subcontent.id
							});
						});
					});
				}
			}
		},

		_saved(data) {
			if(data.status == 'OK') {
				// Close popup here, were done!
				this.opened = false;
			}
		},
	},

	created() {
		$AjaxHelper.doRequest('payment', 'getMultipleSubscriptionsForEditingNew', {idc: this.idc}, {callback: this._directDebits});
	}
};
</script>
