import StylesHelper from '../helpers/stylesHelper';

export default {
	getVersions: function(idc) {
		var versionsAccesslevels = $UserHelper.accesslevels.getCompanyVersion(idc);
		var versionStyles = StylesHelper.getVersion(idc);

		return {
			accesslevels: {
				version: versionsAccesslevels.version,
				version_pnp: versionsAccesslevels.version_pnp
			},

			//Get this from Styles helper later on!
			styles: {
				version: versionStyles.version,
				version_pnp: versionStyles.version_pnp,
			}
		};
	},

	sync: function(idc, data, applyStyles) {
		//We got back a no access, so all user accesslevels have to be reset again!
		if(data.user && data.user.expired == 1) {
			$UserHelper.accesslevels.setUserLevels(data.user.accesslevels);
		}

		//Remove company with all it's settings!
		if(data.company && data.company.remove == 1) {
			$UserHelper.removeCompany(idc);
		}

		//Renew company accesslevels
		else {
			if((data.company && data.company.accesslevels.expired == 1)) {
				$UserHelper.accesslevels.setCompanyLevels(idc, data.company.accesslevels.data);
			}
		}

		//Renew Styles
		if(data.company && data.company.styles.expired == 1) {
			StylesHelper.setStyles(idc, data.company.styles.data, applyStyles);
		}
	}
}
