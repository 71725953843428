<template>
	<!-- Flatpickr calendar, used for all date(time) form fields -->
	<div v-if="wrapperHTML" class="item-content item-input">
		<div class="item-inner">
			<div class="item-title item-label">{{$LanguageHelper.translate(label)}}</div>
			<div class="item-input-wrap">
				<flat-pickr v-model="date" :config="flatPickerConfig" :placeholder="$LanguageHelper.translate('_ENTER_HERE_')" />
			</div>
		</div>
	</div>

	<div v-else class="calendarHidden">
		<flat-pickr v-model="date" :config="flatPickerConfig" />
	</div>

</template>

<style>
/** Center the calendar **/
.calendarHidden {
	display: none;
}

.flatpickr-calendar {
	visibility: hidden!important;
	border-radius:0!important;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
	display: none!important; /* Hide ugly arrows */
}

.flatpickr-calendar.center {
	position: absolute;
  top: 50%!important;
  left: 50%!important;
	transform: translate(-50%, -50%);
	visibility: visible!important;
}
/** Center the calendar **/
.flatpickr-calendar .dayContainer {
	overflow: auto;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
	background: var(--theme-color);
	border-color: var(--theme-color);
}

.flatpickr-day.today {
	border-color: var(--theme-color);
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
	background: #fff;
}

.flatpickr-time input.flatpickr-hour, .flatpickr-time input.flatpickr-minute {
	background: #fff;
}

.shortcut-buttons-flatpickr-wrapper {
	padding-top: 5px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.shortcut-buttons-flatpickr-button {
	cursor: pointer;
	background: var(--theme-color);
	border: none;
	color: #fff;
	font-weight: bold;
	padding: 12px;
}

.shortcut-buttons-flatpickr-button {
	border-radius: 5px;
}
/** Customize the calendar **/

/** Style the backdrop **/
.calendar-backdrop {
	position: absolute;
	display: block;
	opacity: 1;
	z-index: 50000;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
}

.calendar-backdrop.hidden {
	display: none;
}

/** Style the backdrop **/
</style>

<script>
/* Flatpickr imports, vue-datetime is imported globally on the app.js */
import FlatPickr from 'vue-flatpickr-component';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr';

export default {
		props: {
		label: {type: String, required: false},
		value: {type: String, required: false},
		type: {type: String, required: true, default: 'date'},
		placeholder: {type: String, required: false},
		wrapperHTML: {type: Boolean, required: false, default: true},
  },

	components: {
		'flat-pickr': FlatPickr,
	},

	data() {
		return {
			date: '',
			prevDate: '',
			cnt: 0,
			fabVisible: false,

			flatPickerConfig: {
				locale: Dutch, // Locale for this instance only
				enableTime: this.type == 'datetime',
				altInput: true, // Show the user a readable date (as per altFormat), but return something totally different to the server.
				altFormat: this.type == 'datetime' ? `${$LanguageHelper.getDateFormatAlt()} H:i` : $LanguageHelper.getDateFormatAlt(),
				shorthandCurrentMonth: true,
				monthSelectorType: 'static',
				allowInput: false,
				disableMobile: true,

				onClose: [
					// Hier waarde veranderen en watcher triggeren?
					(selectedDates, dateStr, instance) => {
						// Remove center class to reset the visibility
						$('.flatpickr-calendar').removeClass('center');

						// Remove the backdrop
						$('.calendar-backdrop').addClass('hidden');

						// Only act when something has changed!
						if(instance.element.value != this.prevDate) {
							// Set the new and prev date
							this.date = instance.element.value;
							this.prevDate = this.date;

							// Increase the counter
							this.cnt ++;

							// Emit set event!
							this.$emit('set', this.date, this.cnt);
						}
					},
				],

				onOpen: [
					() => {
						// Remove anitmation, otherwise it will jump all over the place!
						$('.flatpickr-calendar').removeClass('animate');

						// Center the calendar with a bit of delay to prevent the open event to fire twice
						setTimeout(() => $('.flatpickr-calendar').addClass('center'), 100);

						// Add the backdrop
						setTimeout(() => $('.calendar-backdrop').removeClass('hidden'), 100);
					},
				],

				onMonthChange: [
					() => {
						this.flatPickrStayInViewport();
					},
				],

				onYearChange: [
					() => {
						this.flatPickrStayInViewport();
					},
				],

				plugins: []
			},

			flatPickerDimensions: {
				touched: false,
				orgHeightCalendar: 390,
				orgHeightDayContainer: 262,
				heightDayContainer: 0
			}
		}
	},

	watch: {
		// Emit an event whenever the value changes!
		date(val) {
			if(this.wrapperHTML) return false; // Only proceed if it's the navbar agenda calendar, flatpickr has it's own logic!

			this.cnt ++;
			this.$emit('set', val, this.cnt)
		},
	},

	computed: {
		fab() {
			return $('.fab');
		}
	},

	methods: {
		// Make sure if keyboard is open flatpicker stays in viewport
		flatPickrStayInViewport() {
			if($('.flatpickr-calendar')[0]) {
				// Make the day container smaller
				if(this.flatPickerDimensions.orgHeightCalendar >= $(window).height() - 40) {
					this.flatPickerDimensions.touched = true;
					this.flatPickerDimensions.heightDayContainer = this.flatPickerDimensions.orgHeightDayContainer - (this.flatPickerDimensions.orgHeightCalendar - ($(window).height())) - 20;
					$('.flatpickr-calendar .dayContainer').height(this.flatPickerDimensions.heightDayContainer);
				}
				// Make the daycontainer original size again!
				else {
					$('.flatpickr-calendar .dayContainer').height(this.flatPickerDimensions.orgHeightDayContainer)
				}
			}
		}
		// Make sure if keyboard is open flatpicker stays in viewport
	},

	created() {
		// Only add the backdrop element once!
		if(!$('.calendar-backdrop')[0]) {
			$('body').append('<div class="calendar-backdrop hidden" />');
		}

		// Set the start date here by giving v-model it's value!
		if(this.type == 'datetime') {
			// Format datetime
			if(this.value != '') this.date = moment(this.value).format('YYYY-MM-DD HH:mm');

			// Push the close button for time in here!
			this.flatPickerConfig.plugins.push(
				ShortcutButtonsPlugin({
						theme: 'dark',
						button: [
							{label: $LanguageHelper.translate('_DONE_')},
						],
						onClick(index, fp) {
							fp.close();
						}
					})
			);
		} else {
			// Format time
			if(this.value != '') this.date = moment(this.value).format('YYYY-MM-DD');
		}

		// Emit first set event!
		if(this.value != '')  {
			this.date = this.value;
			this.prevDate = this.date;

			// Emit first event
			this.$emit('set', this.date, this.cnt);
		}
	},

	mounted() {
		// Resize calendar daycontainer, if window is to small.
		this.flatPickrStayInViewport();
		$(window).on('resize', () => {
			this.flatPickrStayInViewport();
		});
	}
};
</script>
