<template>
	<div v-if="render">
		<f7-list v-if="codes.list.length > 0" inset>
			<f7-list-item accordion-item v-for="(item, key) in codes.list" :key="key" :title="item.code">

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_CODE_')}}: {{item.code}}</div>
						<div class="item">{{$LanguageHelper.translate('_DISCOUNT_AMOUNT_')}}: &euro; {{item.amount}}</div>
						<div class="item">{{$LanguageHelper.translate('_DISCOUNT_PERCENTAGE_')}}: {{item.percentage}} %</div>
						<div class="item">{{$LanguageHelper.translate('_MAX_TIMES_PER_ORDER_')}}: {{(!item.max_times_per_order || item.max_times_per_order == 0 ? $LanguageHelper.translate('_UNLIMITED_') : item.max_times_per_order)}}</div>
						<div class="item">{{$LanguageHelper.translate('_MAX_NUMBER_PER_ORDER_')}}: {{(!item.max_number_per_order || item.max_number_per_order == 0 ? $LanguageHelper.translate('_UNLIMITED_') : item.max_number_per_order)}}</div>
						<div class="item">{{$LanguageHelper.translate('_MAX_ORDERS_PER_USER_')}}: {{(!item.max_orders_per_user || item.max_orders_per_user == 0 ? $LanguageHelper.translate('_UNLIMITED_') : item.max_orders_per_user)}}</div>
						<div class="item">{{$LanguageHelper.translate('_MIN_PRODUCTS_IN_CART_')}}: {{item.min_products_in_cart}}</div>
						<div class="item">{{$LanguageHelper.translate('_VALID_FROM_')}}: {{item.valid_from}}</div>
						<div class="item">{{$LanguageHelper.translate('_VALID_UNTIL_')}}: {{(!item.valid_until || item.valid_until == '0000-00-00' ? $LanguageHelper.translate('_VALID_UNLIMITED_') : item.valid_until)}}</div>
					</f7-block>

					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('invoice_product', 'save', idc)">
		           	<f7-button @click="item.opened = true" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
		          </f7-col>

		          <f7-col v-if="$UserHelper.hasAccess('invoice_product', 'remove', idc)">
		            <f7-button @click="remove(item.id_discount_code)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
		          </f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>

				<!-- Manage discount code edit popup here, contents is only rendered when opened is true -->
				<manage-discount-code v-if="item.opened"
					:idc="idc"
					:index="key"
					:id_discount_code="item.id_discount_code"
					@changed="changed"
					@close="item.opened=false"
				/>
			</f7-list-item>
		</f7-list>

		<f7-card v-else class="pageContent">
			<f7-card-content>
				<div>{{$LanguageHelper.translate('_DISCOUNT_MESSAGE_')}}</div>
			</f7-card-content>
		</f7-card>

		<!-- Manage add discount popup here, contents is only rendered when fab clicked is true -->
		<manage-discount-code v-if="fab.buttons.add.clicked"
			:idc="idc"
			@changed="changed"
			@close="fab.buttons.add.clicked=false"
		/>
	</div>
</template>

<script>
import ManageDiscountCode from '../../popups/manage-discount-code.vue';

export default {
	components: {
		'manage-discount-code': ManageDiscountCode
	},

	props: {
		idc: {required: true},
		fab: {type: Object, required: true},
  },

	data() {
    return {
			render: false,
			codes: {
				list: []
			}
		};
  },

	methods: {
		load() {
			$AjaxHelper.doRequest('invoiceProduct', 'discountCodes', {idc: this.idc}, {callback: this._codes})
		},

		changed() {
			this.load();
		},

		renderList(items) {
			this.codes.list = items;
		},

		remove(id) {
			$ViewHelper.confirm({method: () => {$AjaxHelper.doRequest('invoiceProduct', 'removeDiscountCode', {idc: this.idc, id_discount_code: id}, {callback: this._removed})}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		_removed() {
			this.load();
		},

		_codes(data) {
			// Empty the list first
			this.codes.list = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj) => {obj.opened = false});

				// We need a small timeout here, otherwise the items will stay opened
				setTimeout(() => this.renderList(data.resultset), 5);
			}

			this.render = true;
		}
	},

	created() {
		// Get the discount codes here here!
		this.load();
	}
};
</script>
