<template>
  <page :title="$LanguageHelper.translate('_COMPANY_ACCESS_SETTINGS_')" :toolbar="toolbar" @initiated="go">
		<template #users v-if="toolbar.tabs.users && toolbar.tabs.users.active">
			<tab-users :idc="idc"></tab-users>
		</template>

		<template #components v-if="toolbar.tabs.components && toolbar.tabs.components.active">
			<tab-components :idc="idc"></tab-components>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabUsers from './tab-users.vue';
import TabComponents from './tab-components.vue';

export default {
  components: {
    'page': Page,
    'tab-users': TabUsers,
    'tab-components': TabComponents
  },

	props: {
		idc: {required: true}
	},

  data() {
    return {
			toolbar: {
				tabs: {
        users: {text: $LanguageHelper.translate('_USERS_'), icon: 'icon ionPeople', active: false, render: $UserHelper.hasAccess('companySettings', 'accessSettings', this.idc)},
        components: {text: $LanguageHelper.translate('_COMPONENTS_'), icon: 'icon ionOptions', active: false, render: $UserHelper.hasAccess('companySettings', 'accessSettings', this.idc)},
			}}
    };
  },

	methods: {
		go() {
			// Activate correct tab
			if(this.toolbar.tabs.users.render) this.toolbar.tabs.users.active = true;
			else if(this.toolbar.tabs.components.render) this.toolbar.tabs.components.active = true;
		}
	}
};
</script>
