<template>
	<form class="searchbar searchbarList">
		<div class="searchbar-inner">
			<div class="searchbar-input-wrap">
				<input type="search" ref="search" :placeholder="placeholder" @input="value=$event.target.value" :value="value" v-on:keypress.enter.prevent />
				<i class="searchbar-icon"></i>
				<span class="input-clear-button"></span>
			</div>
		</div>
	</form>
</template>

<script>
import Search from '../../js/mixins/search';

export default {
	mixins: [Search],

	props: {
    placeholder: {required: true},
  },
};
</script>
