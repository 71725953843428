<template>
  <div v-show="visible">
    <f7-toolbar tabbar labels position="top" class="backgroundWhite toolbarTop">
      <div>
        <f7-link v-if="pagination.prev" @click.prevent="getMyWOD(pagination.prev)" class="width50"><i class="icon ionArrowRoundBack" /></f7-link>
        <f7-link v-else class="noMouse"></f7-link>
      </div>
      <div class="fontSize17 date">{{date.month}} {{date.year}}</div>
      <div>
        <f7-link v-if="pagination.next" @click.prevent="getMyWOD(pagination.next)" class="width50"><i class="icon ionArrowRoundForward" /></f7-link>
        <f7-link v-else class="noMouse"></f7-link>
      </div>
    </f7-toolbar>

    <div class="marginTop65">
      <f7-card>
        <f7-card-header>
          <div>{{$LanguageHelper.translate('_MY_WOD_')}}</div>
        </f7-card-header>
        <f7-card-content class="marginTop20 paddingBottom30">
          <f7-block>
            <f7-row class="themeNavbar">
              <f7-col width="33" class="marginLeft5">{{$LanguageHelper.translate('_DATE_')}}</f7-col>
              <f7-col width="33">{{$LanguageHelper.translate('_NAME_')}}</f7-col>
              <f7-col width="33"></f7-col>
            </f7-row>
            <f7-row v-for="(item, key) in wod" :key="key">
              <f7-col width="33" class="marginLeft5 paddingCell">{{$LanguageHelper.current == 'nl_NL' ? item.date_wod : item.date_wod_en}}</f7-col>
              <f7-col width="33" class="paddingCell">{{item.name}}</f7-col>
              <f7-col width="33" class="paddingCell"><f7-link @click.prevent="$emit('loadWod', item.id_wod_whiteboard)">{{$LanguageHelper.translate('_SHOW_')}}</f7-link></f7-col>
            </f7-row>
          </f7-block>
        </f7-card-content>
      </f7-card>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    idc: {required: true},
  },

  data() {
    return {
      visible: false,
      wod: {},
      date: {},
      pagination: {},
    };
  },

  methods: {
    _myWOD(data) {
      if(data.status == 'OK') {
        var months = [$LanguageHelper.translate('_JAN_FULL_'), $LanguageHelper.translate('_FEB_FULL_'), $LanguageHelper.translate('_MAR_FULL_'), $LanguageHelper.translate('_APR_FULL_'), $LanguageHelper.translate('_MAY_FULL_'), $LanguageHelper.translate('_JUNE_FULL_'), $LanguageHelper.translate('_JULY_FULL_'), $LanguageHelper.translate('_AUG_FULL_'), $LanguageHelper.translate('_SEPT_FULL_'), $LanguageHelper.translate('_OCT_FULL_'), $LanguageHelper.translate('_NOV_FULL_'), $LanguageHelper.translate('_DEC_FULL_')];
        this.date = data.date;
        this.date.month = months[data.date.month - 1];
        this.pagination = data.pagination;
        this.wod = data.myWOD;
        this.visible = true;
      }
    },

    getMyWOD(data) {
  		$AjaxHelper.doRequest('WOD', 'whiteboard', Object.assign({idc: this.idc, id_appuser: $UserHelper.getID(), version_wod: 1.0, page: 'myWOD'}, data), {callback: this._myWOD});
  	},
  },

  created() {
    this.getMyWOD();
	},
}
</script>

<style scoped>
.date {
  padding-left: 20px;
  padding-right: 20px;
}

.paddingCell {
  padding-top: 15px;
  padding-bottom: 10px;
}

.themeNavbar {
  background-color: var(--navbar-bg-color);
  color: var(--navbar-color);
}

.theme {
  color: var(--theme-color);
}

.row:nth-child(even) {
  background-color: #f7f7f7;
}
</style>
