import SyncHelper from './syncHelper';
import {EventBus} from '../eventBus.js';

var AjaxHelper = {
	isActive: false,
  callFininshed: false,
  callInprogress: false,
	infinites:[],
	extraLoginData: null,
  lel: {},

	destroyInfinite(name) {
		if(this.infinites[name]) {
			delete this.infinites[name];
		}
	},

	doRequestInfinite(ws, method, data, options, requestType) {
		// Create unique infinite object
		if(!this.infinites[method]) {
			this.infinites[method] = {
				active: true,
				done: false,
				timesCalled: 0,

				tracker: {
					start: 0
				},

				settings: {
					limit: 50
				},

				reset() {
					this.tracker.start = 0;
					this.active = false;
				},

				finished() {
					this.reset();
					this.done = true;
				}
			};
		}

		// Do nothing if an Ajax Call is still in progress!
		if(!this.infinites[method].active || this.infinites[method].done) return;

		// Activate the infinate!
		this.infinites[method].active = true;

		// Set the start param the WS needs, keep in mind the naming scheme for back-end and stick to it!
		data.start = this.infinites[method].tracker.start;

		// Do the actual request
		this.doRequest(ws, method, data, options, requestType);
	},

  doRequest(ws, method, data, options, requestType) {
    this.isActive = true;

    this.start(options);
    var requestParams = {
      data: {
        service: ws,
        method: method,
      }
    }

    requestParams.data = $.extend(requestParams.data, data)

    //Send default needed params like language and app name!
    requestParams.data.app = $Config.app.currentApp;
    requestParams.data.language = $LanguageHelper.current;
    requestParams.data.version = $Config.app.version;

    if ($Config.app.onDevice) {
      requestParams.data.clientUserAgent = $Config.app.platform;
    } else {
      requestParams.data.clientUserAgent = 'browser';
    }

    if ($UserHelper.getToken() && $UserHelper.getToken().length > 0) {
      requestParams.data.token = $UserHelper.getToken();
    }

    //Force one time sync if needed!
    if (requestParams.data.idc) {
      if ($Config.app.forceSync) {
        var company = $UserHelper.getCompany(requestParams.data.idc);
        if (company && !company.forceSyncDone) {
          requestParams.data.forceSync = 1;
          $UserHelper.companyForceSyncDone(requestParams.data.idc);
        }
      }

      requestParams.data.id_gym = requestParams.data.idc;
      requestParams.data.companySyncVersions = SyncHelper.getVersions(data.idc);
    }

    //Send id of currently loggedin user!
    requestParams.data.id_appuser_li = $UserHelper.getID();

    $.ajax({
      type: $Config.app.servercallType,
      url: $Config.app.baseURL,
      data: requestParams,
      dataType: 'json',
      success(dataCallback) {
				//Set company styles if not done already!
        if (dataCallback.params_send && dataCallback.params_send.idc && dataCallback.params_send.idc > 0) {
          if (dataCallback.syncCommands) {
						var routerParams = $ViewHelper.router.getParams();
						SyncHelper.sync(dataCallback.params_send.idc, dataCallback.syncCommands, (routerParams && routerParams.idc ? true: false));
          }
        }

        // Stop app and tell user to update!
        if (dataCallback.stop_app) {
          $ViewHelper.alert($FunctionsHelper.nl2br(dataCallback.stop_app.reason), $LanguageHelper.translate('_NOTICE_'));
          throw new Error(dataCallback.stop_app.reason);
        }

				// Trigger extra login when expired
        if (dataCallback.extraLoginExpired) {
          AjaxHelper.triggerExtraLogin(ws, method, data, options);
        }

        // Show message if there is one!
        if (dataCallback.notice && !dataCallback.suppressNotice) {
					dataCallback.notice = $FunctionsHelper.nl2br(dataCallback.notice);
					$ViewHelper.alert($FunctionsHelper.nl2br(dataCallback.notice), $LanguageHelper.translate('_NOTICE_'));
        }

				// Check for unread notifications and emit global event if the number has changed!
				let unreadMessages = dataCallback.unread_messages && dataCallback.unread_messages > 0 ? dataCallback.unread_messages : 0;
				let numberNotifications = localStorage.getItem('numberNotifications');
				if(!numberNotifications || (numberNotifications != unreadMessages)) {
					EventBus.$emit('notifications', unreadMessages);
				}

        //Fatal error!
        if (dataCallback.fatalError || dataCallback.exception) {
          return this.somethingWentWrong(dataCallback);
        }

        // Run callback function if present!
        if (options && options.callback) {
					// Info for extra login
					if (dataCallback.gyminfo && dataCallback.gyminfo.lel) {
						AjaxHelper.lel = {
              lel: dataCallback.gyminfo.lel,
              lel_min: dataCallback.gyminfo.lel_min
            };
          }

					// Disable infinite scroll if there are no more results
					if(AjaxHelper.infinites[method] && AjaxHelper.infinites[method].active) {
						// Increment the start for next call!
						AjaxHelper.infinites[method].tracker.start += AjaxHelper.infinites[method].settings.limit;
					}

          options.callback(dataCallback);
        }

        // Set active to false again after were done
        AjaxHelper.isActive = false;
      },

      error(data) {
				console.log(data)
        AjaxHelper.finish();
        AjaxHelper.somethingWentWrong(data);
      },

      complete() {
        AjaxHelper.finish();
      }
    });
  },

  start(options) {
		if (!options || !options.hideIndicator) {
      $ViewHelper.preloader.show();
    }

    this.callFininshed = false;
    this.callInprogress = true;
  },

  finish() {
    $ViewHelper.preloader.hide();
    this.callFininshed = true;
    this.callInprogress = false;
  },

  somethingWentWrong(data) {
    //Show errors if we are in development mode!
    if (!$Config.app.live) {
      console.log(data);
    }

		$ViewHelper.alert($LanguageHelper.translate('_SOMETHING_WENT_WRONG_TEXT_'), $LanguageHelper.translate('_WHOOPS_'));
  },

  doRequestExtraLogin(ws, method, data, options, callback) {
    var lastMoment = null;
    if (AjaxHelper.lel && AjaxHelper.lel.lel) {
      lastMoment = moment(AjaxHelper.lel.lel);
    }

		if (!lastMoment || !lastMoment.isValid() || moment().subtract(AjaxHelper.lel.lel_min, 'm').toDate().getTime() > lastMoment.toDate().getTime()) {
      AjaxHelper.triggerExtraLogin(ws, method, data, options, callback);
    }
    //Request allowed
    else {
			this.doRequest(ws, method, data, options);
    }
  },

	sendExtraLogin(pass) {
		AjaxHelper.doRequest('user', 'loginExtra', {pass: pass}, {
			callback: function(dataCallback) {
				if (dataCallback.status && dataCallback.status == 'OK') {
					$ViewHelper.popup.close('popupExtralogin');
          $ViewHelper.popup.removeMeFromNativeHistoryStack();
					AjaxHelper.doRequest(AjaxHelper.extraLoginData.ws, AjaxHelper.extraLoginData.method, AjaxHelper.extraLoginData.data, AjaxHelper.extraLoginData.options, AjaxHelper.extraLoginData.requestType);
					AjaxHelper.extraLoginData = null;
				}
			}
		});
	},

	triggerExtraLogin(ws, method, data, options, callback) {
		AjaxHelper.extraLoginData = {ws, method, data, options};
		$ViewHelper.popup.showExtraLogin(callback);
  }
};

export default AjaxHelper;
