import ManageAgendaAppointment from '../../popups/manage-agenda-appointment.vue';
import CustomerAgendaAppointment from '../../popups/customer-agenda-appointment.vue';
import LoginNewAccount from '../../popups/login-new-account.vue';

export default {
	components: {
    'manage-agenda-appointment': ManageAgendaAppointment,
		'customer-agenda-appointment': CustomerAgendaAppointment,
		'login-new-account': LoginNewAccount,
  },

	props: {
		idc: {required: true},
		params: {required: true},
		manage: {required: true},
	},

	data() {
		return {
			queryParams: $FunctionsHelper.getAllQueryParams(window.location.href),
			manageAppointmentOpened: false,
			customerAppointmentOpened: false,
			loginNewAccountOpened: false,
			appointments: [],
			appointment: {}, // Appointment for popup open

			hours:['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],

			days: [
				$LanguageHelper.translate('_MON_'),
				$LanguageHelper.translate('_TUE_'),
				$LanguageHelper.translate('_WED_'),
				$LanguageHelper.translate('_THU_'),
				$LanguageHelper.translate('_FRI_'),
				$LanguageHelper.translate('_SAT_'),
				$LanguageHelper.translate('_SUN_'),
			],

			daysFull: [
				$LanguageHelper.translate('_MONDAY_'),
				$LanguageHelper.translate('_TUESDAY_'),
				$LanguageHelper.translate('_WEDNESDAY_'),
				$LanguageHelper.translate('_THURSDAY_'),
				$LanguageHelper.translate('_FRIDAY_'),
				$LanguageHelper.translate('_SATURDAY_'),
				$LanguageHelper.translate('_SUNDAY_'),
			],

			months: [
				$LanguageHelper.translate('_JAN_'),
				$LanguageHelper.translate('_FEB_'),
				$LanguageHelper.translate('_MAR_'),
				$LanguageHelper.translate('_APR_'),
				$LanguageHelper.translate('_MAY_'),
				$LanguageHelper.translate('_JUNE_'),
				$LanguageHelper.translate('_JULY_'),
				$LanguageHelper.translate('_AUG_'),
				$LanguageHelper.translate('_SEPT_'),
				$LanguageHelper.translate('_OCT_'),
				$LanguageHelper.translate('_NOV_'),
				$LanguageHelper.translate('_DEC_')
			],

			monthsFull: [
				$LanguageHelper.translate('_JAN_FULL_'),
				$LanguageHelper.translate('_FEB_FULL_'),
				$LanguageHelper.translate('_MAR_FULL_'),
				$LanguageHelper.translate('_APR_FULL_'),
				$LanguageHelper.translate('_MAY_FULL_'),
				$LanguageHelper.translate('_JUNE_FULL_'),
				$LanguageHelper.translate('_JULY_FULL_'),
				$LanguageHelper.translate('_AUG_FULL_'),
				$LanguageHelper.translate('_SEPT_FULL_'),
				$LanguageHelper.translate('_OCT_FULL_'),
				$LanguageHelper.translate('_NOV_FULL_'),
				$LanguageHelper.translate('_DEC_FULL_')
			]
		}
	},

	methods: {
		loggedIn() {
			// Open the appointment popup user tried to open before when he was nog logged in yet!
			this.openAppointment();
		},

		appointmentOver(appointment) {
			return moment(appointment.real_date_start).isBefore(moment());
		},

		openAppointment(item) {
			// Only store the item when it's here, if it's empty then use the previous one for user login
			if(item) {
				this.appointment = {
					id_appointment: item.id_appointment,
					date_start: item.real_date_start, // Actual date start (could be different if it's a series)
					date_end: item.real_date_end, // Actual date end (could be different if it's a series)
					position_start: item.position_in_serie, // Position in the series, we need it when buying a product
				};
			}

			if($UserHelper.isLoggedIn()) {
				if(this.manage == 0) this.customerAppointmentOpened = true;
				else if(this.manage == 1 && $UserHelper.hasAccess(`agenda_${this.params.id}`)) this.manageAppointmentOpened = true;
			}
			// Open new login/ new account popup
			else if(this.queryParams.external){
				this.loginNewAccountOpened = true;
			}
		},

		timeStart(appointment) {
			return moment(appointment.real_date_start).format('HH:mm');
		},

		timeEnd(appointment) {
			return moment(appointment.real_date_end).format('HH:mm');
		},
	},

	watch: {
		params: {
			deep: true,
			immediate: true,
			 handler() {
				 this.drawScreen();
			 },
		},
	}
};
