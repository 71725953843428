<template>
  <div class="wrapperMain">
		<!-- Fixed toolbar at the top -->
		<f7-toolbar position="top" class="toolbarTop agenda backgroundColorWhite no-shadow">
			<!-- Clickable date  and navigation -->
			<div class="wrapperPeriod">
				<table class="center">
					<tr>
						<td class="arrow"><a @click="$emit('paginated', 'backward')"><i class="icon ionArrowDropLeft" /></a></td>
						<td class="titleTD"><span class="title" :class="isToday() ? 'today' : ''" @click="$emit('period-clicked')">{{period}}</span></td>
						<td class="arrow"><a @click="$emit('paginated', 'forward')"><i class="icon ionArrowDropRight" /></a></td>
					</tr>
				</table>
			</div>
		</f7-toolbar>

		<div class="wrapperContent">
			<!-- Hours at the left side -->
			<div class="wrapperHours">
				<div v-for="(hour, key) in hours" :key="key" class="hour">
					<div class="title">{{hour}}</div>
				</div>
			</div>

			<!-- Appointments wrapper -->
			<div class="wrapperAppointments backgroundColorEditable" :style="{backgroundColor: params.settings.backgroundColor}">
				<!-- Appointments go in here -->
				<div v-for="(item, key) in appointments"
					class="appointment"
					:key="key"
					:class="`${item.status} ${params.settings.appointmentStatusFullColor ? 'statusFullcolor' : ''} ${appointmentOver(item) ? 'over' : ''}`"
					:style=" {
						height: 'calc(' + item.align.height + '% + 1px)',
						width: 'calc(' + item.align.width + '% - 2px)',
						top: item.align.top + '%',
						left: item.align.left + '%',
						backgroundColor: !params.settings.appointmentStatusFullColor ? item.type_background_color : '',
						color: !params.settings.appointmentStatusFullColor ? item.type_text_color : '',
					}"
          @click="openAppointment(item)"
					>

					<div class="relative">
						<div v-if="item.showNumberSubscription" class="numberSubscriptions" :style=" {backgroundColor: !params.settings.appointmentStatusFullColor ? item.type_background_color : ''}">
							{{item.total_subscriptions}}/{{item.max_subscriptions}}
						</div>

						<div class="name">{{$FunctionsHelper.specialChars(item.name)}}</div>
						<div class="timeStartEnd">{{timeStart(item)}} - {{timeEnd(item)}}</div>
						<div v-if="item.employee_name.length > 0" class="employee">{{item.employee_name}}</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Manage appointment popup -->
    <manage-agenda-appointment v-if="manageAppointmentOpened"
      :idc="idc"
      :id_agenda="params.id"
      :params="appointment"
      @changed="$emit('changed')"
      @close="manageAppointmentOpened=false"
    />

    <!-- Customer appointment popup -->
    <customer-agenda-appointment v-if="customerAppointmentOpened"
      :idc="idc"
      :id_agenda="params.id"
      :params="appointment"
      @changed="$emit('changed')"
      @close="customerAppointmentOpened=false"
    />

		<!-- Login new account popup -->
    <login-new-account v-if="loginNewAccountOpened" :idc="idc" @loggedin="loggedIn" @close="loginNewAccountOpened=false" />
	</div>
</template>

<script>
import AgendaViewMixin from '../js/mixins/agendaView';

export default {
	mixins: [AgendaViewMixin],

	computed: {
		period() {
			return `${this.days[moment(this.params.date).isoWeekday() - 1]} ${moment(this.params.date).date()} ${this.months[moment(this.params.date).month()]} ${moment(this.params.date).year()}`;
		},
	},

	methods: {
    isToday() {
			return moment(this.params.date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
		},

		drawScreen() {
			this.appointments = this.params.data;
		}
	}
};
</script>

<style scoped>
.wrapperContent {
	margin-top: 44px;
	height: 100%;
	display: grid;
	grid-gap: 1px;
	grid-template-columns: 30px repeat(1, 1fr);
}

/** Small screen **/
@media screen and (max-width: 400px) {
	.wrapperContent {grid-template-columns: 20px repeat(1, 1fr);}
}
</style>
