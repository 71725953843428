<template>
	<popup
		v-if="visible"
		:title="title"
		:opened="opened"
		@mounted="opened=true"
		@close="$emit('close')"
	>

		<list-form :params="form" @submitted="submitted"></list-form>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		idc: {required: true},
		id_customer: {required: true},
		edit: {required: true},
	},

  data() {
    return {
			title: $LanguageHelper.translate('_CREATE_NEW_'),
			opened: false,
      visible: false,

			form: {
				fields: {
						created_on: {name: 'created_on', type: 'date', label: '_DATE_', placeholder: '_SELECT_DATE_', render: true, value: ''},
						background: {name:'background', type: 'textarea', label: '_BACKGROUND_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            goals: {name:'goals', type: 'textarea', label: '_HELP_GOALS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            injuries: {name:'injuries', type: 'textarea', label: '_INJURIES_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            doctor_treatments: {name:'doctor_treatments', type: 'textarea', label: '_DOCTOR_TREATMENTS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            diseases: {name:'diseases', type: 'textarea', label: '_DISEASES_CONDITIONS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            medications: {name:'medications', type: 'textarea', label: '_MEDICATIONS_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            program: {name:'program', type: 'textarea', label: '_AGREEMENTS_PROGRAM_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            other_info: {name:'other_info', type: 'textarea', label: '_OTHER_INFO_', placeholder: '_ENTER_HERE_', render: true, value: ''},
            hidden_comment: {name:'hidden_comment', type: 'textarea', label: '_NOT_VISIBLE_FOR_CUSTOMER_', placeholder: '_ENTER_HERE_', render: true, value: ''},
				},

				submit: {text: '_SAVE_', render: true},
				values: {},
			}
    };
  },

  methods: {
		submitted() {
			let data = JSON.parse(JSON.stringify(this.form.values));
			if(this.edit) data['id_intake'] = this.edit;
			$AjaxHelper.doRequest('gym', 'adminSaveIntakeUser', Object.assign({idc: this.idc, id_appuser: this.id_customer}, data), {callback: this._intakeSubmitted});
		},

		getIntake() {
			$AjaxHelper.doRequest('gym', 'getIntakeUserForAdmin', {idc: this.idc, id_appuser: this.id_customer, id_intake: this.edit}, {callback: this._intake});
    },

		_intake(data) {
			if(data.resultset) {
				data.resultset.created_on = data.resultset.date_intake;
				this.form.values = data.resultset;
				this.title = $LanguageHelper.translate('_EDIT_');
		    this.visible = true;
			}
		},

    _intakeSubmitted(data) {
			this.$emit('newIntake');
			this.opened = false;
    }
  },

  created() {
		if(this.edit) this.getIntake();
		else this.visible = true;
  }
};
</script>
