<template>
	<customer-progress-skinfold :idc="idc" />
</template>

<script>
import CustomerProgressSkinFold from '../../components/customer-progress-skinfold.vue';

export default {
  components: {
		'customer-progress-skinfold': CustomerProgressSkinFold,
	},

	props: {
		idc: {required: true}
	},
};
</script>
