<template>
  <popup v-if="render"
    :title="$LanguageHelper.translate('_EXTRA_INFO_')"
    :opened="opened"
    :fab="fab.info"
    @fab-clicked="fabClicked"
    @mounted="opened=true"
    @close="$emit('close')"
  >
  	<list-form :params="form" @changed="changed" />

    <!-- Manage customer edit popup here, contents is only rendered when opened is true -->
    <manage-customer v-if="customerOpened"
      :idc="idc"
      :title="$FunctionsHelper.specialChars(customer.title)"
      :id_customer="customer.id_appuser"
      @archived="removeArchivedFromList(customer.id_appuser)"
      @edited="reloadAll"
      @close="customerOpened=false"
    />

		<!-- Manage push message popup here, contents is only rendered when opened is true -->
		<manage-pushmessage v-if="sendMessageOpened" :idc="idc" :selectedList="usersSelectedForMessage" @close="sendMessageOpened=false" />
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';
import ManageCustomer from '../popups/manage-customer/index.vue';
import FormCheckboxGroupAction from '../js/mixins/formCheckboxGroupAction';
import ManagePushMessage from '../popups/manage-pushmessage.vue';

export default {
  mixins: [FormCheckboxGroupAction],

  components: {
    'popup': Popup,
    'list-form': ListForm,
    'manage-customer': ManageCustomer,
		'manage-pushmessage': ManagePushMessage,
  },

  props: {
    idc: {required: true},
    filters: {required: true},
		summaryItem: {type: Object, required: true}, // Contains the summary item that was clicked on
  },

  data() {
    return {
      render: false,
      opened: false,
      customerOpened: false,
			sendMessageOpened: false,
			data: null, // Fetched from back-end
			allUsersFlat: [], // Flat array with all users, we need this for sending messages
			usersSelectedForMessage: [], // Flat array with all users, we need this for sending messages
			customer: null, // We fill this when we open the customer popup
			archived: [],

			form: {
        fields: {
					items: {name: 'items', type: 'checkbox_group', items: [], render: false},
				},

        values: {},
      },

      fab: {
				info: {
					position: 'right-bottom',
					buttons: {
						archive: {name:'archive', label: '_ARCHIVE_USER_', icon: 'icon ionArchive', render: false, clicked: false},
						export: {name:'export', label: '_EXPORT_', icon: 'icon ionDownload', render: false, clicked: false},
						message: {name:'message', label: '_SEND_MESSAGE_', icon: 'icon iconSend', render: false, clicked: false},
					}
				}
			},
    }
  },

  methods: {
    changed(field, itemOrAction) {
			// Handle checkboxes group action
			if(field.name == 'item') this.handleCheckboxGroupAction(field, this.form.fields.items.items, itemOrAction);

			// Open customer popup
			if(field == 'openCustomer') {
				this.customer = itemOrAction;
				this.customerOpened = true;
			}
    },

    fabClicked(fab) {
			if(fab.name == 'export') this.downloadExport();
			if(fab.name == 'message') this.openSendMessage();

			if(fab.name == 'archive') {
				let buttons = [
          {text: $LanguageHelper.translate('_CANCEL_'), onClick: () => {return false;}},
					{text: $LanguageHelper.translate('_ARCHIVE_USER_'), onClick: () => {this.archive();}},
        ];

        $ViewHelper.confirmMultipleButtons(buttons, $LanguageHelper.translate('_WARNING_'), `${$LanguageHelper.translate('_QUESTION_REMOVE_SELECTED_ITEMS_')}`);
      }
    },

    archive() {
			this.archived = this.getFormValues();

			// No items were selected
      if(this.archived.length == 0) return $ViewHelper.alert($LanguageHelper.translate('_NO_ITEMS_SELECTED_'), $LanguageHelper.translate('_NOTICE_'));

			let filters = Object.assign({}, this.filters, {type: 'archive', summaryItem: this.summaryItem.action});
			$AjaxHelper.doRequest('dashboard', 'load', {idc: this.idc, in: this.archived, filters: filters}, {callback: (data) => {
				if(data.status == 'OK') this.updateFormItems();
			}});
    },

		openSendMessage() {
			let selectedUsers = [];
			
			// Get app user id's
			this.form.fields.items.items.forEach((item, key) => {
				if(item.checked && item.id_appuser) selectedUsers.push(item.id_appuser);
			});

			// No items were selected
      if(selectedUsers.length == 0) return $ViewHelper.alert($LanguageHelper.translate('_NO_ITEMS_SELECTED_'), $LanguageHelper.translate('_NOTICE_'));

			// Now match the selected ones and create a new array with all data here...
			let usersAlreadyAdded = [];
			selectedUsers.forEach((userID) => {
				if(!usersAlreadyAdded.includes(userID)) this.usersSelectedForMessage.push(this.allUsersFlat[userID]);

				// Keep track which users are added already!
				usersAlreadyAdded.push(userID);
			});

			this.sendMessageOpened = true;
		},

		downloadExport() {
      let values = this.getFormValues();

			if(values.length == 0) return $ViewHelper.alert($LanguageHelper.translate('_NO_ITEMS_SELECTED_FOR_EXPORT_'), $LanguageHelper.translate('_NOTICE_'));

			let action = `${$Config.app.baseURL}?service=dashboard&method=load&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&language=${$LanguageHelper.current}&filters[type]=export&filters[summaryItem]=${this.summaryItem.action}&filters[dashboard]=${this.filters.dashboard}&filters[exportItem]=export`;
			
			if(this.filters.dateRange && this.filters.dateRange[0] && this.filters.dateRange && this.filters.dateRange[0].start) {
				action += `&filters[dateStart]=${this.filters.dateRange[0].start}&filters[dateEnd]=${this.filters.dateRange[0].end}`;
			}
			action += `&version=${$Config.app.version}`;

			let form = `<form action="${action}" method="POST" id="exportSubmit">`;
			values.forEach((id) => {
				form += `<input type="text" name="in[]" value="${id}">`;
			});
			form += '</form>';

			$('body').append(form);
			$('#exportSubmit').submit().remove();
  	},

		getFormValues() {
			let values = [];
			this.form.fields.items.items.forEach((item, key) => {
				if(item.value && item.checked) values.push(item.value);
			});

			return values;
		},

    removeArchivedFromList(id) {
      // Set the archived items and update the list!
      this.archived = [id];
			this.updateFormItems();
    },

		updateFormItems() {
			this.form.fields.items.items.forEach((item, key) => {
				// Remove the item if it was found in the selected array!
				if(item.value && this.archived.includes(item.value)) this.form.fields.items.items.splice(key, 1);
			});

			// Let parent dashboard component know to fetch data again and redraw
			this.$emit('changed');
		},

		reloadAll(val) {
			this.fetchData();

			// Let parent dashboard component know to fetch data again and redraw
			this.$emit('changed');
    },

    renderFabs() {
      this.fab.info.buttons.archive.render = this.data.options.actions.archive && $UserHelper.hasAccess('users', 'remove', this.idc);
      this.fab.info.buttons.message.render = this.data.options.actions.message && $UserHelper.hasAccess('users', 'remove', this.idc);
      this.fab.info.buttons.export.render = !$Config.app.onDevice;
    },

		renderForm() {
			let items = [];

			// Top level checkbox
      if(this.data.name) {
        items.push({
					name: 'item',
					parentRef: 0,
					level: this.data.options.layers && this.data.options.layers == 2 ? 0 : 1,
          title: $FunctionsHelper.specialChars(this.data.name),
					class: 'fontBold',
					checked: false,
  				render: true
        });
      }

			this.data.content.forEach((item, index) => {
        // One layer list push items!
				if(!item.subcontent) {
					items.push({
						name: 'item',
						level: 2,
						id_appuser: item.id_appuser && item.id_appuser > 0 ? item.id_appuser : '',
						title: $FunctionsHelper.specialChars(item.name),
						checked: false,
						toggled: false,
						render: true,
						action: {name: 'openCustomer', icon: 'ionInformationCircle', render: item.id_appuser && item.id_appuser > 0 && $UserHelper.hasAccess('users', 'show', this.idc)},
						description: item.info,
						value: item.id
					});
					
					// Add to flat uses array!
					if(!this.allUsersFlat.hasOwnProperty(item.id_appuser)) this.allUsersFlat[item.id_appuser] = {name: $FunctionsHelper.specialChars(item.name), email: item.email, id_appuser: item.id_appuser};
				}

				// This one has an extra layer!
				else {
					items.push({
						name: 'item',
  					ref: item.id,
  					parentRef: 0,
  					level: 1,
						title: $FunctionsHelper.specialChars(item.name),
  					checked: false,
  					toggled: false,
  					class: 'link theme',
  					render: true
  				});

					item.subcontent.forEach((obj) => {
            items.push({
							name: 'item',
							ref: item.id,
							parentRef: 0,
  						level: 2,
              id_appuser: obj.id_appuser && obj.id_appuser > 0 ? obj.id_appuser : '',
              title: $FunctionsHelper.specialChars(obj.name),
              checked: false,
              render: false,
              action: {name: 'openCustomer', icon: 'ionInformationCircle', render: obj.id_appuser && obj.id_appuser > 0 && $UserHelper.hasAccess('users', 'show', this.idc)},
              description: obj.info,
							value: obj.id,
            });

						// Add to flat uses array!
						if(!this.allUsersFlat.hasOwnProperty(obj.id_appuser)) this.allUsersFlat[obj.id_appuser] = {name: $FunctionsHelper.specialChars(obj.name), email: obj.email, id_appuser: obj.id_appuser};
          });
				}
			});

			this.form.fields.items.items = items;
			this.form.fields.items.render = true;
		},

		build() {
			this.renderFabs();
			this.renderForm();
			this.render = true;
		},

		fetchData() {
			// Clone and merge the filters (the original we got as props will stay intact)
			let filters = Object.assign({}, this.filters, {type: 'extrainfo', summaryItem: this.summaryItem.action /* Contains an ID or action depending on which summary dashboard itam was clicked */});

			$AjaxHelper.doRequest('dashboard', 'load', {idc: this.idc, filters: filters}, {callback: (data) => {
				if(data.resultset) this.data = data.resultset;
	      if(data.options) this.data.options = data.options;
	      if(data.name) this.data.name = data.name;

				// Store data and build form fields
				this.data = data.resultset;
				this.build();
			}});
		}
  },

	created() {
		this.fetchData();
	}
};
</script>
