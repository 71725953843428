<template>
  <div class="wrapperMain">
		<!-- Fixed toolbar at the top -->
		<f7-toolbar position="top" class="toolbarTop agenda backgroundColorWhite no-shadow">
			<!-- Clickable date  and navigation -->
			<div class="wrapperPeriod">
				<table class="center">
					<tr>
						<td class="arrow"><a @click="$emit('paginated', 'backward')"><i class="icon ionArrowDropLeft" /></a></td>
						<td class="titleTD"><span class="title" @click="$emit('period-clicked')">{{period}}</span></td>
						<td class="arrow"><a @click="$emit('paginated', 'forward')"><i class="icon ionArrowDropRight" /></a></td>
					</tr>
				</table>
			</div>

			<!-- Days of the week -->
			<div class="wrapperToolbar">
				<div class="toolbarDays">
					<div class="date">
						<div>w</div>
						<div><b>{{weekNumber}}</b></div>
					</div>

					<div v-for="(day, key) in appointments" :key="key" class="date" :class="isToday(key) ? 'today' : ''">
						<div>{{days[key]}}</div>
						<div><b>{{dayInWeek(key)}}</b></div>
					</div>
				</div>
			</div>
		</f7-toolbar>

		<div class="wrapperContent">
			<!-- Hours at the left side -->
			<div class="wrapperHours">
				<div v-for="(hour, key) in hours" :key="key" class="hour">
					<div class="title">{{hour}}</div>
				</div>
			</div>

			<!-- All 7 days -->
			<div v-for="(day, key) in appointments" :key="key" class="wrapperAppointments backgroundColorEditable" :style="{backgroundColor: params.settings.backgroundColor}">
				<!-- Appointments go in here -->
				<div v-for="(item, key) in day"
					class="appointment"
					:key="key"
					:class="`${item.status} ${params.settings.appointmentStatusFullColor ? 'statusFullcolor' : ''} ${appointmentOver(item) ? 'over' : ''}`"
					:style="{
						height: 'calc(' + item.align.height + '% + 1px)',
						width: 'calc(' + item.align.width + '% - 2px)',
						top: item.align.top + '%',
						left: item.align.left + '%',
						backgroundColor: !params.settings.appointmentStatusFullColor ? item.type_background_color : '',
						color: !params.settings.appointmentStatusFullColor ? item.type_text_color : '',
					}"
          @click="openAppointment(item)"
					>

					<div class="relative">
						<div v-if="item.showNumberSubscription" class="numberSubscriptions" :style="{backgroundColor: !params.settings.appointmentStatusFullColor ? item.type_background_color : ''}">
						{{item.total_subscriptions}}/{{item.max_subscriptions}}
					</div>

						<div class="name">{{$FunctionsHelper.specialChars(item.name)}}</div>
						<div class="bigScreen timeStartEnd">{{timeStart(item)}} - {{timeEnd(item)}}</div>
						<div v-if="item.employee_name.length > 0" class="bigScreen employee">{{item.employee_name}}</div>
					</div>
				</div>
			</div>
		</div>

    <!-- Manage appointment popup -->
    <manage-agenda-appointment v-if="manageAppointmentOpened"
      :idc="idc"
      :id_agenda="params.id"
      :params="appointment"
      @changed="$emit('changed')"
      @close="manageAppointmentOpened=false"
    />

    <!-- Customer appointment popup -->
    <customer-agenda-appointment v-if="customerAppointmentOpened"
      :idc="idc"
      :id_agenda="params.id"
      :params="appointment"
      @changed="$emit('changed')"
      @close="customerAppointmentOpened=false"
    />

		<!-- Login new account popup -->
    <login-new-account v-if="loginNewAccountOpened" :idc="idc" @loggedin="loggedIn" @close="loginNewAccountOpened=false" />
	</div>
</template>

<script>
import AgendaViewMixin from '../js/mixins/agendaView';

export default {
	mixins: [AgendaViewMixin],

	computed: {
		period() {
			let date = this.params.date;
			let period = `${moment(date).date()} - ${moment(date).add(6, 'd').date()} ${this.months[moment(date).add(6, 'd').month()]}. ${moment(date).year()}`;

			if(moment(date).year() < moment(date).add(6, 'd').year()) {
				period = `${moment(date).date()} ${this.months[moment(date).month()]} . ${moment(date).year()} - ${moment(date).add(6, 'd').date()} ${this.months[moment(date).add(6, 'd').month()]}. ${moment(date).add(6, 'd').year()}`;
			}

			return period;
		},

		weekNumber() {
			return moment(this.params.date).isoWeek();
		}
	},

	methods: {
    isToday(dayNumber) {
			return moment(this.params.date).add(dayNumber, 'd').format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
		},

		dayInWeek(dayNumber) {
			return moment(this.params.date).add(dayNumber, 'd').date();
		},

		drawScreen() {
			this.appointments = this.params.data;
		}
	}
};
</script>

<style scoped>
.toolbarTop {height: 85px;}

.toolbarDays {
	display: grid;
	grid-gap: 1px;
	grid-template-columns: 30px repeat(7, 1fr);
	line-height: 20px;
}

.wrapperContent {
	margin-top: 85px;
	height: 100%;
	display: grid;
	grid-gap: 1px;
	grid-template-columns: 30px repeat(7, 1fr);
}

.wrapperHours {
	background: #fff;
	grid-column: 1/2;
}

.wrapperHours .hour {
	height: 60px;
	text-align: center;
	border-bottom: 1px solid #c8c7cc6e;
}

.wrapperHours .title {
	height: 100%;
	display: flex;
  align-items: center;
  justify-content: center;
}

/** Small screen **/
@media screen and (max-width: 400px) {
	.wrapperContent, .toolbarDays {grid-template-columns: 20px repeat(7, 1fr);}
}
</style>
