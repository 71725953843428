<template>
	<f7-card class="cardCenter marginTop30">
		<f7-card-content class="nl2br">{{msg}}</f7-card-content>

		<f7-block strong>
			<f7-button fill @click="$emit('click')">{{btnText}}</f7-button>
		</f7-block>
	</f7-card>
</template>

<script>
export default {
  props: {
		msg: {type: String, required: false},
		btnText: {type: String, required: true}
  }
};
</script>
