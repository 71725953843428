<template>
	<form class="listForm" :class="className" @submit.prevent="() => {return false;}">
		<f7-list inset>
			<template v-for="(field, key) in params.fields">
				<!-- Input -->
				<f7-list-input v-if="field.render && field.type == 'input'"
					:key="key"
					:label="$FunctionsHelper.specialChars($LanguageHelper.translate(field.label))"
					:placeholder="$FunctionsHelper.specialChars($LanguageHelper.translate(field.placeholder))"
					:value="$FunctionsHelper.specialChars(field.value)"
					:class="field.class ? field.class : ''"
					clear-button
					@input="field.value=$event.target.value"
				/>

				<!-- colorpicker field -->
				<li :key="`${key + 1}`" v-else-if="field.render && field.type == 'color'">
					<color-picker-list-input
						:label="$FunctionsHelper.specialChars($LanguageHelper.translate(field.label))"
						:placeholder="$FunctionsHelper.specialChars($LanguageHelper.translate(field.placeholder))"
						:value="field.value"
						:startValue="field.startValue"
						@changed="colorPickerChanged(field, $event)"
					 />
				</li>

				<!-- Autocomplete -->
				<template v-else-if="field.render && field.type == 'autocomplete'">
					<li :key="key" class="item-content item-input">
						<list-autocomplete-form-field
							:key="key"
							:label="$FunctionsHelper.specialChars($LanguageHelper.translate(field.label))"
							:placeholder="$LanguageHelper.translate(field.placeholder)"
							:val="$FunctionsHelper.specialChars(field.value)"
							:className="field.class ? field.class : ''"
							@changed="field.value=$event"
							@searched="searched(field)"
						/>
					</li>

          <template v-if="field.items.length > 0" >
            <f7-list-item v-for="(item, indexSearchResult) in field.items" class="autocompleteResult"
              :key="indexSearchResult"
              :title="$FunctionsHelper.specialChars(item.title)"
              :footer="$FunctionsHelper.specialChars(item.footer)"
              link="#"
              @click="autocompleteSelected(field, item)"
					/>
          </template>
				</template>

				<!-- Password -->
				<f7-list-input v-else-if="field.render && field.type == 'password'"
					:key="`${key + 1}`"
					type="password"
					:label="$LanguageHelper.translate(field.label)"
					:placeholder="$LanguageHelper.translate(field.placeholder)"
					:value="field.value"
					:class="field.class ? field.class : ''"
					clear-button
					autocomplete="false"
					@input="field.value=$event.target.value"
				/>

				<!-- Textarea -->
				<f7-list-input v-else-if="field.render && field.type == 'textarea'"
					:key="`${key + 1}`"
					type="textarea"
					:label="$FunctionsHelper.specialChars($LanguageHelper.translate(field.label))"
					:placeholder="$FunctionsHelper.specialChars($LanguageHelper.translate(field.placeholder))"
					:value="$FunctionsHelper.specialChars(field.value)"
					:class="field.class ? field.class : ''"
					clear-button
					@input="field.value=$event.target.value"
				/>

				<!-- Select -->
				<f7-list-item v-else-if="field.render && field.type == 'select'"
					:key="`${key + 1}`"
					:title="$FunctionsHelper.specialChars($LanguageHelper.translate(field.title))"
					:smart-select-params="{openIn: field.openIn ? field.openIn : 'popover', popupCloseLinkText: $LanguageHelper.translate('_CLOSE_'), closeOnSelect: true}"
					smart-select
					>
					<select :class="field.class ? field.class : ''" @change="selected(key, field.options, `select_${key + 1}`)" :ref="`select_${key}`">
						<option v-for="(option, keySelectOption) in field.options" :key="`${key}_${keySelectOption}`" :name="option.name" :value="$FunctionsHelper.specialChars(option.value)" :selected="option.selected">{{$FunctionsHelper.specialChars($LanguageHelper.translate(option.label))}}</option>
					</select>
				</f7-list-item>

				<!-- Select regular -->
				<f7-list-item :key="`${key + 1}`" v-else-if="field.render && field.type == 'select-regular'" class="wrapperSelectRegular">
					<!-- Select -->
					<select class="regular" :class="field.class ? field.class : ''" @change="selected(key, field.options, `select_${key + 1}`)" :ref="`select_${key}`">
						<option v-for="(option, key) in field.options" :key="`${key + 1}`" :value="$FunctionsHelper.specialChars(option.value)" :selected="option.selected">{{$LanguageHelper.translate(option.label)}}</option>
					</select>
				</f7-list-item>

				<!-- Button -->
				<f7-list-item v-else-if="field.render && field.type == 'button'" :key="`${key + 1}`" class="wrapperButton">
					<f7-button fill small raised @click="$emit('changed', field)" class="listButton" :class="field.class ? field.class : ''">{{$FunctionsHelper.specialChars($LanguageHelper.translate(field.title))}}</f7-button>
				</f7-list-item>

				<!-- Description -->
				<f7-list-item v-else-if="field.render && field.type == 'description'" :key="`${key + 1}`" :class="field.class ? field.class : ''">
					<div v-html="$LanguageHelper.translate(field.text)" class="description" />
				</f7-list-item>

				<!-- Call to action -->
				<f7-list-item v-else-if="field.render && field.type == 'cta'" :key="`${key + 1}`" class="cta" :class="field.class ? field.class : ''">
					<template v-if="field.action && (field.action.render || !field.action.hasOwnProperty('render'))">
						<f7-link class="link cta" :title="field.action.title ? $LanguageHelper.translate(field.action.title) : ''" @click="$emit('changed', field.action)">{{field.text}}</f7-link>
					</template>
				</f7-list-item>

				<!-- Date -->
				<li v-else-if="field.render && field.type == 'date'" :key="`${key + 1}`">
					<date-time-list-input
						:label="field.label"
						:value="field.value"
						:placeholder="$LanguageHelper.translate(field.placeholder)"
						:type="field.type"
						@set="(val) => datePicked(field, val)"
					/>
				</li>

				<!-- Datetime -->
				<li v-else-if="field.render && field.type == 'datetime'" :key="`${key + 1}`">
					<date-time-list-input
						:label="field.label"
						:value="field.value"
						:placeholder="$LanguageHelper.translate(field.placeholder)"
						:type="field.type"
						@set="(val) => datePicked(field, val)"
					/>
				</li>

				<!-- Checkbox -->
				<f7-list-item v-else-if="field.render && field.type == 'checkbox'" checkbox :key="`${key + 1}`" :title="$FunctionsHelper.specialChars($LanguageHelper.translate(field.title))" :checked="field.checked" :value="$FunctionsHelper.specialChars(field.value)" :class="field.class ? field.class : ''" @change="toggle(field, 'checked')" />

				<!-- Toggle -->
				<f7-list-item v-else-if="field.render && field.type == 'toggle'" :key="`${key + 1}`">
					<span>{{$FunctionsHelper.specialChars($LanguageHelper.translate(field.title))}}</span>
					<f7-toggle :checked="field.checked" :value="$FunctionsHelper.specialChars(field.value)" :class="field.class ? field.class : ''" @change="toggle(field, 'checked')"></f7-toggle>
				</f7-list-item>

				<!-- checkbox group -->
				<template v-else-if="field.render && field.type == 'checkbox_group'">
          <template v-for="(item, keyCheckboxGroup) in field.items">
            <li v-if="item.render" :key="`${key}_${keyCheckboxGroup}`">
              <label class="item-checkbox item-content" :class="item.class ? item.class : ''">
                <input type="checkbox" :value="$FunctionsHelper.specialChars(item.value)" :checked="item.checked" @change="toggle(item, 'checked')"/>
                <i class="icon icon-checkbox"></i>
                <div class="item-inner">
                  <div class="item-title" :class="item.class ? item.class : ''" @click="item.hasOwnProperty('toggled') ? toggle(item, 'toggled', $event) : ''">

                    <!-- Action present? Draw icon with link -->
                    <div v-if="item.action && item.action.render">
                      {{$FunctionsHelper.specialChars($LanguageHelper.translate(item.title))}}
                      <a @click="$emit('changed', item.action.name, item)" class="theme link"><i :class="`icon marginLeft5 ${item.action.icon}`"></i></a>
                    </div>

                    <!-- Title (label) -->
                    <div v-else>{{$FunctionsHelper.specialChars($LanguageHelper.translate(item.title))}}</div>

                    <!-- Extra description? Could be an array or regular string -->
                    <template v-if="item.description">
                      <template v-if="Array.isArray(item.description)">
                        <div v-for="(description, keyInfo) in item.description" :key="`${key}_${keyInfo}`" v-html="description" class="contentSmallGrey" />
                      </template>

                      <template v-else>
                        <div v-html="item.description" class="contentSmallGrey" />
                      </template>
                    </template>
                  </div>
                </div>
              </label>
            </li>
          </template>
				</template>

				<!-- Divider -->
				<f7-list-item v-else-if="field.render && field.type == 'divider'" divider :key="`${key + 1}`" :class="field.wrapperClass ? field.wrapperClass : ''">
					<div class="item-inner">
						<span>{{$FunctionsHelper.specialChars($LanguageHelper.translate(field.title))}}</span>
						<template v-if="field.info">
							<f7-link class="link theme action" :class="field.class ? field.class : ''" :popover-open="`.popover_${key}`"><i class="icon ionInformationCircle"></i></f7-link>
							<f7-popover :class="`popover_${key}`">
								<div class="content" v-html="$LanguageHelper.translate(field.info)" />
							</f7-popover>
						</template>

						<template v-if="field.action && (field.action.render || !field.action.hasOwnProperty('render'))">
							<f7-link class="link theme action" :class="field.class ? field.class : ''" :title="field.action.title ? $LanguageHelper.translate(field.action.title) : ''" @click="$emit('changed', field.action)"><i :class="`icon ${field.action.icon}`"></i></f7-link>
						</template>
					</div>
				</f7-list-item>

				<!-- Combined fields that needs to be renderd next to each other -->
				<template v-else-if="field.render && field.type == 'combined'">
					<f7-list-item class="combined" :class="field.class ? field.class : ''" :key="`${key + 1}`">
						<table>
							<tr>
								<td v-if="field.title">{{$FunctionsHelper.specialChars($LanguageHelper.translate(field.title))}}</td>

								<td :key="`${key + 1}`" v-for="(item, key) in field.fields">
									<!-- Select -->
									<select v-if="item.type == 'select'" class="regular" :class="item.class ? item.class : ''" @change="selected(key, item.options, `select_${key + 1}`)" :ref="`select_${key}`">
										<option v-for="(option, keyCombinedOption) in item.options" :key="`${key}_${keyCombinedOption}`" :value="$FunctionsHelper.specialChars(option.value)" :selected="option.selected">{{$FunctionsHelper.specialChars($LanguageHelper.translate(option.label))}}</option>
									</select>

									<label v-else-if="item.type == 'label'">{{$FunctionsHelper.specialChars($LanguageHelper.translate(item.text))}}</label>

									<!-- Date -->
									<date-time-list-input v-else-if="item.type == 'date'"
										class="date"
										:key="`${key + 1}`"
										:label="item.label"
										:value="item.value"
										:placeholder="$LanguageHelper.translate(item.placeholder)"
										:type=item.type
										@set="(val) => datePicked(item, val, field)"
									/>
								</td>
							</tr>
						</table>
					</f7-list-item>
				</template>
			</template>

			<slot name="extra-items"></slot>
		</f7-list>

		<!-- Slot for buttons or other stuff -->
		<slot></slot>

		<!-- Submit button -->
		<f7-block v-if="params.submit && params.submit.render">
			<f7-button fill raised @click="submittedClicked">{{$FunctionsHelper.specialChars($LanguageHelper.translate(params.submit.text))}}</f7-button>
		</f7-block>
	</form>
</template>

<script>
import Form from '../../js/mixins/form';

export default {
	mixins: [Form]
}
</script>

<style scoped>
.description {
	font-size: var(--f7-label-font-size);
	color: var(--f7-label-text-color);
}

.popover .content {
	padding: 12px;
}

.combined td {
	padding-right: 10px;
}

.combined select {
	width: 100px;
	margin-bottom: 5px;
	margin-top: 7px;
}

.combined select.small {width: 50px;}
.combined select.medium {width: 100px;}

.listButton {
	width: 100%;
	margin-bottom: 8px;
}

.ios .listButton {
	margin-top: 7px;
}
</style>

<style>
/** CSS for toplist date range **/
.combined.toplist table {
	border-spacing: 0;
}

.combined.toplist.date .item-content .item-inner {
	padding-top: 0!important;
	padding-bottom: 0!important;
}

.combined.toplist.date .item-content {
	padding-left: 5px;
}

.combined.toplist.date td {
	padding: 0;
}

.combined.toplist.date td:first-child {
	padding-right: 0;
}

.combined.toplist.date td:nth-child(2) {
	padding-left: 6px;
}

.combined.toplist.date .item-label.item-title {
	display: none!important;
}

.md .combined.toplist.date .item-input:not(.item-input-outline) .item-input-wrap:after, .md .combined.toplist.date .input:not(.input-outline):after {
	background: none;
}

.combined.toplist.date input {
	cursor: pointer;
	font-size: 14px;
	background-image: url(../../static/img/dropdown_arrow.png);
	background-position: 100%;
	background-repeat: no-repeat;
	width: 125px;
	height: 32px;
	padding-left: 7px;
  margin-top: 3px;
	border: 1px solid #d7d7d752;
}

.ios .combined.toplist.date input {margin-top: 6px;}
.md .combined.toplist.date input {margin-top: 3px;}
.md .combined.toplist.date .item-input-wrap {padding-top: 5px;}
.combined.toplist.date .item-inner::after {display: none;}
.combined.toplist.date .item-input .item-inner {width: 125px;}

@media (max-width: 340px) {
	.combined.toplist.date input {
		font-size: 13px!important;
		width: 110px;
	}

	.combined.toplist.date .item-input .item-inner {
		width: 110px;
	}
}
/** CSS for toplist date range **/
</style>
