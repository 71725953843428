<template>
  <div v-if="visible">
    <f7-list v-if="intakes.length > 0" inset>
        <f7-list-item accordion-item v-for="(intake, key) in intakes" :key="key" :title="intake.date_intake">
					<f7-accordion-content>
            <f7-block>
              <template v-if="intake.background">
                <div class="item"><b>{{$LanguageHelper.translate('_BACKGROUND_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.background"></div>
              </template>
              <template v-if="intake.goals">
                <div class="item"><b>{{$LanguageHelper.translate('_HELP_GOALS_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.goals"></div>
              </template>
              <template v-if="intake.injuries">
                <div class="item"><b>{{$LanguageHelper.translate('_INJURIES_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.injuries"></div>
              </template>
              <template v-if="intake.doctor_treatments">
                <div class="item"><b>{{$LanguageHelper.translate('_DOCTOR_TREATMENTS_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.doctor_treatments"></div>
              </template>
              <template v-if="intake.diseases">
                <div class="item"><b>{{$LanguageHelper.translate('_DISEASES_CONDITIONS_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.diseases"></div>
              </template>
              <template v-if="intake.medications">
                <div class="item"><b>{{$LanguageHelper.translate('_MEDICATIONS_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.medications"></div>
              </template>
              <template v-if="intake.program">
                <div class="item"><b>{{$LanguageHelper.translate('_AGREEMENTS_PROGRAM_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.program"></div>
              </template>
              <template v-if="intake.other_info">
                <div class="item"><b>{{$LanguageHelper.translate('_OTHER_INFO_')}}</b></div>
                <div class="item marginBottom10" v-html="intake.other_info"></div>
              </template>
            </f7-block>
          </f7-accordion-content>
        </f7-list-item>
    </f7-list>

    <f7-card v-else class="pageContent">
			<f7-card-content>
				<div>{{$LanguageHelper.translate('_NO_INTAKE_MESSAGE_')}}</div>
			</f7-card-content>
		</f7-card>
  </div>
</template>

<script>
export default {
  props: {
		idc: { required: true },
  },

  data() {
    return {
      intakes: {},
      visible: false,
    };
  },

  methods: {
    _initIntake(data) {
      if(data.status == 'OK') {
        if(data.resultset.length > 0) {
          this.intakes = data.resultset;
        }
        this.visible = true;
      }
    },
  },

  created() {
    $AjaxHelper.doRequest('gym', 'getIntakesUser', {idc: this.idc, id_appuser: $UserHelper.getID()}, {callback: this._initIntake});
	},
};
</script>
