<template>
	<div class="pageContent">
    <f7-list inset>
      <f7-list-item accordion-item v-for="(item, key, index) in items"
        :key="key"
        :title="`${$LanguageHelper.translate('_WEEK_')} ${item.dates.week}, ${item.dates.year}` "
        >

        <f7-accordion-content>
					<f7-block class="marginBottom10">
						<template v-for="(field, key) in fields" :key="key">
	           <div class="marginTop10"><b>{{field.label}}</b></div>

						 <template v-for="(field2, key2) in fields[key].fields" :key="key2">
							 <div v-if="item.results[field2.name]" class="item">{{field2.label}}: {{item.results[field2.name]}}</div>
						 </template>
	          </template>
					</f7-block>

          <f7-block v-if="manage" class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('progress', 'save', idc)">
								<f7-button @click="item.opened=true" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
							</f7-col>

							<f7-col v-if="$UserHelper.hasAccess('progress', 'remove', idc)">
								<f7-button @click="deleteItem(item.results.id_progress)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
        </f7-accordion-content>

				<!-- Edit an item -->
				<manage-customer-progress-item v-if="manage && item.opened"
					:idc="idc"
					:id_customer="id_customer"
		      :id_progress="item.results.id_progress"
		      @submitted="submitted"
					@close="item.opened=false"
				/>
      </f7-list-item>
    </f7-list>

    <!-- Load if new FAB is clicked -->
		<manage-customer-progress-item v-if="manage && fab.buttons.new.clicked"
			:idc="idc"
			:id_customer="id_customer"
      @submitted="submitted"
			@close="fab.buttons.new.clicked=false"
		/>
  </div>
</template>

<script>
import ManageCustomerProgressItem from '../popups/manage-customer-progress-item.vue';

export default {
  components: {
		'manage-customer-progress-item': ManageCustomerProgressItem,
	},

	props: {
		idc: {required: true},
		id_customer: {required: false},
    fab: {required: false},
    manage: {required: true},
	},

  data() {
    return {
      items: [],

			fields: [
				{
					label: $LanguageHelper.translate('_GENERAL_'),
					fields:[
						{name:'weight', label: $LanguageHelper.translate('_WEIGHT_')},
						{name:'fatpercentage', label: $LanguageHelper.translate('_FAT_PERCENTAGE_')},
						{name:'visceral_fat', label: $LanguageHelper.translate('_VISCERAL_FAT_LEVEL_')},
						{name:'fat_free_mass', label: $LanguageHelper.translate('_FAT_FREE_MASS_')},
						{name:'muscle_mass', label: $LanguageHelper.translate('_MUSCLE_MASS_')},
						{name:'bone_mass', label: $LanguageHelper.translate('_BONE_MASS_')},
						{name:'body_fluids', label: $LanguageHelper.translate('_BODY_FLUID_')},
						{name:'blood_glucose', label: $LanguageHelper.translate('_BLOOD_GLUCOSE_VALUE_')},
					]
				},

				{
					label: $LanguageHelper.translate('_MEASUREMENT_CARD_LEGEND_'),
					fields:[
						{name:'chest', label: $LanguageHelper.translate('_TOP_WIDTH_')},
						{name:'stomach', label: $LanguageHelper.translate('_STOMACH_')},
						{name:'waist', label: $LanguageHelper.translate('_WAIST_')},
						{name:'belly', label: $LanguageHelper.translate('_BELLY_')},
						{name:'hips', label: $LanguageHelper.translate('_HIPS_')},
						{name:'thighs_left', label: $LanguageHelper.translate('_LEFT_THIGH_')},
						{name:'thighs_right', label: $LanguageHelper.translate('_RIGHT_THIGH_')},
						{name:'knees_left', label: $LanguageHelper.translate('_LEFT_KNEE_')},
						{name:'knees_right', label: $LanguageHelper.translate('_RIGHT_KNEE_')},
						{name:'calves_left', label: $LanguageHelper.translate('_LEFT_CALF_')},
						{name:'calves_right', label: $LanguageHelper.translate('_RIGHT_CALF_')},
						{name:'arms_left', label: $LanguageHelper.translate('_LEFT_ARM_')},
						{name:'arms_right', label: $LanguageHelper.translate('_RIGHT_ARM_')},
					]
				},

				{
					label: $LanguageHelper.translate('_SKINFOLD_MEASUREMENT_LEGEND_'),
					fields:[
						{name:'php_chin', label: $LanguageHelper.translate('_CHIN_')},
						{name:'php_cheek', label: $LanguageHelper.translate('_CHEEK_')},
						{name:'php_chest', label: $LanguageHelper.translate('_CHEST_')},
						{name:'php_biceps', label: $LanguageHelper.translate('_BICEPS_')},
						{name:'php_triceps', label: $LanguageHelper.translate('_TRICEPS_')},
						{name:'php_shoulder_blade', label: $LanguageHelper.translate('_SCACAPULA_')},
						{name:'php_ribs', label: $LanguageHelper.translate('_SIDE_RIBS_')},
						{name:'php_hips', label: $LanguageHelper.translate('_HIP_BONE_')},
						{name:'php_navel', label: $LanguageHelper.translate('_NAVEL_')},
						{name:'php_knee', label: $LanguageHelper.translate('_KNEE_')},
						{name:'php_calves', label: $LanguageHelper.translate('_CALF_')},
						{name:'php_quadriceps', label: $LanguageHelper.translate('_QUADRICEPS_')},
						{name:'php_hamstring', label: $LanguageHelper.translate('_HAMSTRING_')},
					]
				},
			]
    }
  },

  methods: {
    submitted() {
			this.getProgressItems();
    },

    deleteItem(id) {
      $ViewHelper.confirm({method: () => {
				this.items = [];
				$AjaxHelper.doRequest('gym', 'removeProgressItemUserForAdmin', {idc: this.idc, id_appuser: this.id_customer, id_progress: id}, {callback: (data) => {
					this.getProgressItems();
				}});
			}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
    },

    _items(data) {
      if(data.status == 'OK') {
				this.items = [];
				if(data.resultset.length > 0) {
          this.items = data.resultset;

					// Add reactive opened prop
					this.items.forEach((item, key) => {item.opened = false});
        }
      }
    },

    getProgressItems() {
			if(this.manage) $AjaxHelper.doRequest('gym', 'getProgressItemsUserForAdmin', {idc: this.idc, id_appuser: this.id_customer}, {callback: this._items});
			else $AjaxHelper.doRequest('gym', 'getProgressItemsUser', {idc: this.idc}, {callback: this._items});
    }
  },

  created() {
		this.getProgressItems();
  }
};
</script>
