import debounce from 'lodash.debounce';

export default {
	props: {
		enabled: {type: Boolean, required: false, default: false}
  },

	data() {
		return {
			value: '',
		}
	},

	methods: {
		disable() {
			this.value = '';
			this.$refs.search.blur();
			$(this.$refs.search).removeClass('input-with-value');
		},

		searched: debounce(function() {
			this.$emit('searched', this.value);
		}, 500),
	},

	watch: {
		enabled(val) {
			if(val) {
				setTimeout(() => this.$refs.search.focus(), 1);
			} else {
				this.disable();
			}
		},

		value() {
			this.searched();
		}
	},
};
