var UserHelper = {
	login: function (data) {
		this.logout();

		var user = {
			id_user: data.id_user,
			firstname: data.firstname,
			token: data.token,
			accesslevels: data.accesslevels,
			companies: []
		};

		this.storeUser(user);

		// Set the company component and feature levels for all companies user has levels for!
		if(data.company_levels) {
			$.each(data.company_levels, (key, obj) => {
				$UserHelper.accesslevels.setCompanyLevels(key, obj.accesslevels.data);
			});
		}
	},

	logout: function () {
		// Reset page visits for loggedin user
		$PageVisits = 0;
		this.removeUser();
	},

	hasAccess: function (component, feature, idc) {
		if(!component) {
			return true;
		}

		if(!idc) {
			var idc = this.getIDCompanyFromQuery();
		}

		if(!idc) {
			return false;
		}

		var companyAccesslevels = this.accesslevels.getCompanyLevels(idc, component, feature);
		var userAccesslevels = this.accesslevels.getUserLevels(idc);
		var hasAccess = false;
		if((!userAccesslevels || userAccesslevels.lenght == 0) || (!companyAccesslevels || companyAccesslevels.lenght == 0)) {
			return false;
		}

		$.each(userAccesslevels, function (index, userLevel) {
			if(companyAccesslevels.indexOf(userLevel) > -1) {
				hasAccess = true;
				return;
			}
		});

		return hasAccess;
	},

	isAdmin: function(idc) {
		if(!idc) {
			var idc = this.getIDCompanyFromQuery();
		}

		if(!idc) {
			return false;
		}

		var userAccesslevels = this.accesslevels.getUserLevels(idc);

		return userAccesslevels.indexOf('admin') > -1
	},

	isLoggedIn: function () {
		var user = this.getUser();

		if(user && user.id_user && user.id_user > 0) {
			return true;
		}

		return false;
	},

	companyListed: function(cid) {
		if(!cid) {
			var cid = this.getIDCompanyFromQuery();
		}

		var company = this.getCompany(cid);
		if(company) {
			return true;
		}

		return false;
	},

	companyForceSyncDone: function(idc) {
		var user = UserHelper.getUser();
		var company = UserHelper.getCompany(idc);

		if(company) {
			user.companies[company.index].forceSyncDone = true;

			UserHelper.storeUser(user);
		}
	},

	storeUser: function(user) {
		localStorage.setItem('user', JSON.stringify(user));
	},

	removeUser: function() {
		localStorage.removeItem('user');
	},

	removeCompany: function(idc) {
		var user = UserHelper.getUser();
		$.each(user.companies, function (index, obj) {
			if(obj.idc == idc) {
				user.companies.splice(index, 1);
				return;
			}
		});

		UserHelper.storeUser(user);
	},

	getUser: function () {
		var user = localStorage.getItem('user');
		if(user) {
			return $.parseJSON(user);
		}

		return null;
	},

	getID: function () {
		var user = this.getUser();
		if(user && user.id_user && user.id_user > 0) {
			return user.id_user;
		}

		return null;
	},

	getIDCompanyFromQuery: function() {
		return $ViewHelper.getParamValueFromPage('idc');
	},

	getCompany: function(idc) {
		var user = this.getUser();
		var company = null;

		if(user && user.companies) {
			$.each(user.companies, function (index, obj) {
				if(obj.idc == idc) {
					obj.index = index;
					company = obj;

					return;
				}
			});
		}

		return company;
	},

	getToken: function () {
		var user = this.getUser();
		if(user && user.token && user.token.length > 0) {
			return user.token;
		}

		return null;
	},

	accesslevels: {
		setCompanyLevels: function(idc, data) {
			var user = UserHelper.getUser();
			if(!user) return false;

			var company = UserHelper.getCompany(idc);
			if(company) {
				user.companies[company.index].accesslevels = {version: data.version, version_pnp: data.version_pnp, components: data.component};
			} else {
				if(!user.companies) {
					user.companies = [];
				}
				user.companies.push({idc: idc,  accesslevels: {version: data.version, version_pnp: data.version_pnp, components: data.component}});
			}

			UserHelper.storeUser(user);
		},

		setUserLevels: function(levels) {
			var user = UserHelper.getUser();
			user.accesslevels = levels;
			UserHelper.storeUser(user);
		},

		getCompanyLevels: function(idc, component, feature) {
			var company = UserHelper.getCompany(idc);

			if(company && company.accesslevels) {
				if(component) {
					if(feature) {
						if(company.accesslevels.components[component] && company.accesslevels.components[component].features[feature]) {
							return company.accesslevels.components[component].features[feature].levels;
						}
					} else {
						if(company.accesslevels.components[component]) {
							return company.accesslevels.components[component].levels;
						}
					}
				} else {
					return company.accesslevels;
				}
			}

			return null;
		},

		getCompanyVersion: function(idc) {
			var company = UserHelper.getCompany(idc);

			if(company && company.accesslevels) {
				return  {
					version: company.accesslevels.version,
					version_pnp: company.accesslevels.version_pnp
				};
			}

			return  {
				version: 0,
				version_pnp: 0
			};
		},

		getCompaniesUserManages: function() {
			var companies = [];
			var user = UserHelper.getUser();

			if(user && user.accesslevels && user.accesslevels.accesslevels) {
				$.each(user.accesslevels.accesslevels, function (index, obj) {
					companies.push({name: (obj.name ? obj.name : 'Company'), idc: obj.id_gym});
				});
			}

			return companies;
		},

		getUserLevels: function(idc) {
			var user = UserHelper.getUser();
			var accesslevels = [];

			if(user && user.accesslevels && user.accesslevels.accesslevels) {
				$.each(user.accesslevels.accesslevels, function (index, obj) {
					if(obj.id_gym == idc) {
						accesslevels = obj.levels;
						return;
					}
				});
			}

			return accesslevels;
		},
	}
}

export default UserHelper;
