<template>
  <page :title="$LanguageHelper.translate('_INTAKE_REPORTING_')" :toolbar="toolbar" @initiated="go">
		<template #intake v-if="toolbar.tabs.intake && toolbar.tabs.intake.active">
			<tab-intake :idc="idc"></tab-intake>
		</template>

		<template #reporting v-if="toolbar.tabs.reporting && toolbar.tabs.reporting.active">
			<tab-reporting :idc="idc"></tab-reporting>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabIntake from './tab-intake.vue';
import TabReporting from './tab-reporting.vue';

export default {
  components: {
    'page': Page,
    'tab-intake': TabIntake,
    'tab-reporting': TabReporting,
  },

	props: {
		idc: {required: true},
	},

  data() {
		return {
			toolbar: {
				tabs:{
          intake: {text: $LanguageHelper.translate('_INTAKE_'), icon: 'icon ionList', active: false, render: true},
          reporting: {text: $LanguageHelper.translate('_REPORTING_'), name: 'reporting', icon: 'icon iconNews', active: false, render: true},
			  }
      }
    };
  },

	methods: {
		go() {
			this.toolbar.tabs.intake.active = true;
		}
	}
};
</script>
