<template>
	<popup :title="$LanguageHelper.translate('_SELECT_PRODUCT_')" :opened="opened" @mounted="opened=true" @close="$emit('close')">
		<div v-if="render">
			<!-- Appointment info -->
			<div class="wrapperAppointmentInfo">
				<f7-row class="contentItem">
					<f7-col width="10">
					 <div class="appointmentIcon"><f7-icon class="icon ionClock themeDetail"></f7-icon></div>
				 </f7-col>

				 <f7-col width="90">
					 <div class="paddingTop5">
						 <div class="item-title"><b>{{$LanguageHelper.translate('_DATE_')}}</b></div>
						 <div class="item-title">{{$LanguageHelper.formatDate(moment(appointment.date_start).format('YYYY-MM-DD'))}} {{$LanguageHelper.translate('_FROM_LOWER_')}} {{moment(appointment.date_start).format('HH:mm')}} {{$LanguageHelper.translate('_UNTIL_LOWER_')}} {{moment(appointment.date_end).format('HH:mm')}} {{$LanguageHelper.translate('_HOUR_')}}</div>
					</div>
				</f7-col>
			 </f7-row>

			 <f7-row class="contentItem">
				 <f7-col width="10">
					 <div class="appointmentIcon"><f7-icon class="icon ionInformationCircle themeDetail"></f7-icon></div>
				 </f7-col>

				 <f7-col width="90">
					 <div class="paddingTop5">
						 <div class="item-title"><b>{{$LanguageHelper.translate('_DESCRIPTION_')}}</b></div>
						 <div class="item-title">{{$FunctionsHelper.specialChars(appointment.name)}}</div>
						 <div v-if="appointment.description.length > 0" class="item-title">{{$FunctionsHelper.specialChars(appointment.description)}}</div>
					 </div>
				 </f7-col>
			 </f7-row>
			</div>

			<!-- Buy product form  -->
			<list-form :params="form" @changed="changed" @submitted="submitted"></list-form>
		</div>
	</popup>
</template>

<script>
import Popup from '../components/smart/popup.vue';
import ListForm from '../components/smart/list-form.vue';

export default {
	components: {
    'popup': Popup,
		'list-form': ListForm
	},

	props: {
		closeMe: {required: true},
		idc: {required: true},
		params: {required: true},
  },

	data() {
		return {
			render: false,
			opened: false,

			appointment: null,
			partners: [],

			form: {
				fields: {
					explain_buy_and_book: {type: 'description', text: '_YOU_WILL_BE_SUBSCIBED_AFTER_PAYMENT_', class: 'textPayAttention', render: true},
					id_invoice_product: {type: 'select', name: 'id_invoice_product', title: '_PRODUCT_', render: false, options: []},
					buy_info: {type: 'description', text: '', class: 'buyInfo', render: false},
					id_partner: {type: 'select', name: 'id_partner', title: '_SUBSCRIBE_', render: false, options: []},
				},

				submit: {text: '_PAY_', render: false},
				values: {},
			},
		}
	},

	watch: {
		// This is needed so i can be closed form the outside
		closeMe(val) {
			this.opened = !val;
		}
	},

	methods: {
		changed(field, item) {
			if(field.name  == 'id_invoice_product') {
				this.form.fields.buy_info.text = '';
				this.form.fields.buy_info.render = false;
				this.form.fields.id_partner.render = false;
				this.form.submit.render = false;

				var output = '';
				let product = item;
				if(item.value > 0) {
					if(product.installments == 1) {
						output += `<div><b> ${$LanguageHelper.translate('_SUBSCRIPTION_')}</b></div>`;
					} else {
						output += `<div><b> ${$LanguageHelper.translate('_CARD_WITH_CREDITS_')}</b></div>`;
					}

					if(product.description != '') {
	          output += `<div class="description">${product.description}</div>`;
	        }

					if(product.amount_in_vat_first_installment > 0) {
	          output += `<div>${$LanguageHelper.translate('_AMOUNT_FIRST_')}: &euro; ${product.amount_in_vat_first_installment}</div>`;
	        }

					if(product.amount_in_vat > 0) {
	          output += `<div>${$LanguageHelper.translate('_AMOUNT_')}: &euro; ${product.amount_in_vat}</div>`;
	        }

					if(product.number_weeks_valid > 0) {
						output += `<div>${$LanguageHelper.translate('_NUMBER_WEEKS_VALID_')}: ${product.number_weeks_valid}</div>`;
					}

					if(product.number_months_valid > 0) {
						output += `<div>${$LanguageHelper.translate('_NUMBER_MONTHS_VALID_')}: ${product.number_months_valid}</div>`;
					}

					if(product.number_credits > 0) {
						output += `<div>${$LanguageHelper.translate('_NUMBER_CREDITS_')}: ${product.number_credits}</div>`;
					}

					if(product.number_installments > 0) {
						output += `<div>${$LanguageHelper.translate('_NUMBER_INSTALLMENTS_')}: ${product.number_installments}</div>`;
					}

					if(product.installments > 0) {
						output += `<div>${$LanguageHelper.translate('_ATHORIZATION_SEPA_')}: ${$LanguageHelper.translate('_EVERY_')} ${(product.length > 1 ? product.length : '')} ${product.period}</div>`;
					}

					this.form.fields.buy_info.text = output;
					this.form.fields.buy_info.render = true;
					this.form.fields.id_partner.render = this.form.fields.id_partner.options.length > 0;
					this.form.submit.render = true;
				}
			}
		},

		submitted() {
			let data = Object.assign(JSON.parse(JSON.stringify(this.form.values)), {
				idc: this.idc,
				id_appointment: this.params.id_appointment,
				position_start: this.params.position_start,
				type: 'agendaBooking',
				redirect_url: '', // Was used for old app, we only display a message now in the booking confirm page
				account: 1,
				userTerms: 1, // Also asked on payment page, just say yes
				companyTerms: 1, // Also asked on payment page, just say yes
			});

			let newWindow = !$Config.app.onDevice ? window.open() : null
			$AjaxHelper.doRequest('booking', 'saveBooking', data, {callback: (data) => {
				if(data.status == 'OK') {
					// Send user to payment page
					$ViewHelper.toBrowser(data.redirectURL, newWindow);

					// Emit submitted event so appointment component can close me and then himself
					this.$emit('submitted');
				}
			}});
		},

		_products(data) {
			// Set appointment and partner info here
			this.appointment = data.appointment;
			this.partners = data.partners ? data.partners : [];

			// Push partner users here
			if(this.partners.length > 0) {
				this.partners.forEach((partner, key) => {
					this.form.fields.id_partner.options.push({
						label: key == 0 ? $LanguageHelper.translate('_MYSELF') : partner.firstname, // First partner is always the user himself, change firstname to myself!
						value: partner.id_appuser_partner_user > 0 ? partner.id_appuser_partner_user : null,
						selected: key == 0 // Make first option selected
					});
				});

				// If only me is left then it makes no sense to show a list of partners (back-end always gives partners with the user itself as first item)!
				if(this.partners.length == 1) {
					if(this.partners[0].user && this.partners[0].user == 1) {
						// Empty the list again!
						this.form.fields.id_partner.options = [];
					}
				}
			}

			// Push first product option!
			this.form.fields.id_invoice_product.options.push({
				name: 'enter_choise',
				label: '_CHOSE_AN_OPTION_',
				value: 0,
				selected: true
			});

			// Push other ones from DB
			data.products.forEach((option) => {
				this.form.fields.id_invoice_product.options.push({
					name: option.name,
					description: option.description,
					installments: option.installments,
					number_installments: option.number_installments,
					length: option.length,
					period: option.period,
					amount_in_vat_first_installment: option.amount_in_vat_first_installment,
					amount_in_vat: option.amount_in_vat,
					number_credits: option.number_credits,
					number_weeks_valid: option.number_weeks_valid,
					number_months_valid: option.number_months_valid,
					number_installments: option.number_installments,

					label: option.name,
					value: option.id_invoice_product,
					selected: false
				});
			});

			// Render the needed form fields
			this.form.fields.id_invoice_product.render = true;

			// Render the page
			this.render = true;
		},
	},

	created() {
		let data = {
			idc: this.idc,
			id_appointment: this.params.id_appointment,
			position_start: this.params.position_start,
			account: 1
		};

		$AjaxHelper.doRequest('booking', 'getAppointmentWithAvailableProducts', data, {callback: this._products});
	}
};
</script>

<style scoped>
.wrapperAppointmentInfo {
	padding: 20px;
	padding-bottom: 0;
}

.contentItem {margin-bottom: 10px;}

i.icon {margin-top: 5px;}
</style>

<style>
.buyInfo .description {font-size: 14px; line-height: 22px;}
</style>
