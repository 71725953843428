<template>
	<div class="shoppingcart marginTop5" v-if="params.price">
		<span @click="$emit('changed')" class="pointer">
			<span class="marginRight10">€ {{params.price}}</span>
			<span>
				<f7-icon class="icon ionCart">
					<f7-badge v-if="params.numberItems > 0" color="red">{{params.numberItems}}</f7-badge>
				</f7-icon>
			</span>
		</span>
	</div>
</template>

<script>
export default {
  props: {
		params: {type: Object, required: false},
	},
};
</script>
