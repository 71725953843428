<template>
  <page
    :title="$LanguageHelper.translate('_WOD_')"
    :toolbar="toolbar"
    @fab-clicked="fabClicked"
		@initiated="go"
  >
		<template #wod v-if="toolbar.tabs.wod && toolbar.tabs.wod.active">
			<tab-wod :idc="idc" :fab="fab.share" @saveIDWod="saveIDWod" @loadRanking="loadRanking" :idWod="idWod" @nameUrl="nameUrl" :searchWod="searchWod" @closeSearchWod="closeSearchWod"></tab-wod>
		</template>

		<template #ranking v-if="toolbar.tabs.ranking && toolbar.tabs.ranking.active">
			<tab-ranking :idc="idc" :fab="fab.share" @saveIDWod="saveIDWod" :idWod="idWod" @nameUrl="nameUrl" :searchWod="searchWod" @closeSearchWod="closeSearchWod"></tab-ranking>
		</template>

    <template #myWod v-if="toolbar.tabs.myWod && toolbar.tabs.myWod.active">
			<tab-my-wod :idc="idc" @saveIDWod="saveIDWod" @loadWod="loadWod" :idWod="idWod"></tab-my-wod>
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabWod from './tab-wod.vue';
import TabRanking from './tab-ranking.vue';
import TabMyWod from './tab-my-wod.vue';

export default {
  components: {
    'page': Page,
    'tab-wod': TabWod,
    'tab-ranking': TabRanking,
    'tab-my-wod': TabMyWod,
  },

	props: {
		idc: {required: true},
	},

  data() {
		return {
			toolbar: {
				tabs:{
          wod: {text: $LanguageHelper.translate('_WOD_'), name: 'wod', icon: 'icon iconWOD', active: false, render: true},
          ranking: {text: $LanguageHelper.translate('_RANKING_'), name: 'ranking', icon: 'icon iconList', active: false, render: true},
          myWod: {text: $LanguageHelper.translate('_MY_WOD_'), name: 'my_wod', icon: 'icon ionPerson', active: false, render: true},
			  }
      },
      idWod: null,
      name_url: '',
      searchWod: false,

      fab: {
				share: {
					position: 'right-bottom',
					buttons: {
            search: {name:'search', label: $LanguageHelper.translate('_SEARCH_WOD_'), icon: 'icon ionSearch', render: true, clicked: false},
						/*facebook: {name:'facebook', label: 'Facebook', icon: 'icon ionFacebook', render: true, clicked: false},
						linkedin: {name:'linkedin', label: 'LinkedIn', icon: 'icon ionLinkedIn', render: true, clicked: false},
            twitter: {name:'twitter', label: 'Twitter', icon: 'icon ionTwitter', render: true, clicked: false},*/
					}
				},
			},
    };
  },

  methods: {
    nameUrl(val) {
      this.name_url = val;
    },

    closeSearchWod() {
      this.searchWod = false;
    },

    fabClicked(fab) {
      var height = $(window).height();
      var width = $(window).width();
      var shareURL = this.$Config.apps[this.$Config.app.currentApp].siteURL + '/wod/' + this.name_url + '/' + this.idWod;
			switch(fab.name) {
        case 'search':
          this.searchWod = true;
		    break;

			  case 'facebook':
          return window.open(encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${shareURL}`), 'targetWindow', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);
		    break;

				case 'linkedin':
          return window.open(encodeURI(`https://www.linkedin.com/shareArticle?mini=true&url=${shareURL}`), 'targetWindow', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);
				break;

        case 'twitter':
          return window.open(encodeURI(`https://twitter.com/home?status=${shareURL}`), 'targetWindow', "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" + width + ",height=" + height);
				break;
			}
		},

    setSearched(val) {
			this.searched = val;
		},

    saveIDWod(val) {
      if(val) this.idWod = val;
    },

    loadRanking(val) {
      if(val) this.idWod = val;
      $.each(this.toolbar.tabs, function (index, obj) {
        obj.active = (index == 'ranking' ? true : false);
      });
    },

    loadWod(val) {
      if(val) this.idWod = val;
      $.each(this.toolbar.tabs, function (index, obj) {
        obj.active = (index == 'wod' ? true : false);
      });
    },

		go() {
			this.toolbar.tabs.wod.fab = this.fab.share;
			this.toolbar.tabs.ranking.fab = this.fab.share;

			// Load first tab page
			this.toolbar.tabs.wod.active = true;
		}
  }
};
</script>
