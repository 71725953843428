<template>
	<div v-if="visible">
		<f7-list v-if="categories.list.length > 0" inset>
			<f7-list-item accordion-item v-for="(category, key) in categories.list"
				:key="key"
				:title="category.name"
				>

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col>
		           	<f7-button @click="category.opened=true" raised fill small>{{$LanguageHelper.translate('_EDIT_')}}</f7-button>
		          </f7-col>

		          <f7-col>
		            <f7-button @click="remove(category)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
		          </f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>

				<!-- Manage category edit popup here, contents is only rendered when opened is true -->
				<manage-product-category v-if="category.opened"
					:idc="idc"
					:index="key"
					:id_category="category.id_invoice_product_category"
					@changed="changed"
					@close="category.opened=false"
				/>
			</f7-list-item>
		</f7-list>

		<f7-card v-else class="pageContent">
			<f7-card-content>
				<div>{{$LanguageHelper.translate('_CATEGORY_MESSAGE_')}}</div>
			</f7-card-content>
		</f7-card>

		<!-- Manage add category popup here, contents is only rendered when fab clicked is true -->
		<manage-product-category v-if="fab.buttons.add.clicked"
			:idc="idc"
			@changed="changed"
			@close="fab.buttons.add.clicked=false"
		/>
	</div>
</template>

<script>
import ManageProductCategory from '../../popups/manage-product-category.vue';

export default {
	components: {
		'manage-product-category': ManageProductCategory
	},

	props: {
		idc: {required: true},
		fab: {type: Object, required: true},
  },

	data() {
		return {
			visible: false,
			categories: {
				list: []
			}
		}
	},

	methods: {
		load() {
			$AjaxHelper.doRequest('invoiceProduct', 'productCategoryItems', {idc: this.idc}, {callback: this._categories});
		},

		remove(category) {
			$ViewHelper.confirm({method: () => $AjaxHelper.doRequest('invoiceProduct', 'removeProductCategory', {idc: this.idc, id_invoice_product_category: category.id_invoice_product_category}, {callback: this._removed})}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		changed() {
			this.load();
		},

		renderList(items) {
			this.categories.list = items;
		},

		_removed(data) {
			this.load();
		},

		_categories(data) {
			// Empty the list first
			this.categories.list = [];

			if(data.resultset && data.resultset.length > 0) {
				data.resultset.forEach((obj, key) => {obj.opened = false});

				// We need a small timeout here, otherwise the items will stay opened
				setTimeout(() => this.renderList(data.resultset), 5);
			}

			this.visible = true;
		},
	},

	created() {
		// Load the list
		this.load();
	}
};
</script>
