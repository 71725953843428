<template>
  <form v-show="visible" class="accessComponents">
    <f7-list inset>
      <!--loop trough list-->
      <f7-list-item accordion-item v-for="(items, key, index) in featureLevels" :key="index" :title="items.name">
        <f7-accordion-content>
          <table cellspacing="0">
            <div>
              <tr>
                <!--loop trough levels-->
                <th v-for="(levelItem, key, index) in levels" :key="index" class="paddingTopBottom">
                  <div class="tableHeaderRotate">{{levelItem.name}}</div>
                </th>
              </tr>
            </div>

            <!--loop trough features-->
            <div v-for="(item, key, index) in items.features" :key="index">
              <tr>
                <td>
                  {{item.name}}
                </td>
              </tr>
              <f7-list>
                <tr>
                  <!--loop trough checkboxes-->
                  <td v-for="(level, key, index) in item.userlevel" :key="index">
                      <f7-list-item checkbox :name="level.title" :value="level.value" :checked="level.checked" @change="level.checked = !level.checked" class="width55"></f7-list-item>
                  </td>
                </tr>
              </f7-list>
            </div>
          </table>

          <f7-block strong>
            <f7-button @click.prevent="submitted(key)" raised fill small>{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
          </f7-block>

        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </form>
</template>

<script>

export default {
  props: {
    idc: {required: true},
  },

  data() {
    return {
      visible: false,
      featureLevels: [],
      levels: [],
    };
  },

  created() {
    //init
    this.getCompanyComponents();
	},

  methods: {
    getCompanyComponents() {
  		$AjaxHelper.doRequest('Gym', 'getCompanyFeatureUserLevels', {idc: this.idc}, {callback: this._initCompanyComponents});
  	},

    _initCompanyComponents(data) {
      //loop trough data.featureUserLevels to collect the levels the right way
      if(data.featureUserLevels) {
        data.featureUserLevels.forEach((items) => {
          items.features.forEach((item) => {
            item.userlevel = [];
            item.levels.forEach((obj) => {
              // push level this way
              item.userlevel.push({
                title: obj.name,
                checked: obj.checked == 1 ? true : false,
                value: obj.system_name
              });
            });
          });
        });

        this.featureLevels = data.featureUserLevels;
        this.levels = data.levels;

        this.visible = true;
      }
    },

    submitted(field) {
      //loop trough featureLevels to get the checked levels
      let data = [];
      this.featureLevels.forEach((items, key) => {
        //field is which item (main component) in array needs to be checked
        if(field == key) {
          // get id main component
          data.id_component = items.id;
          data.features = {};

          items.features.forEach((item, key) => {
            // get id feature
            data.features[item.id] = [];
            item.userlevel.forEach((level, key) => {
              if(level.checked) {
                data.features[item.id].push(level.value);
              }
            });
          });
        }
      });

      $AjaxHelper.doRequest('Gym', 'saveCompanyFeatureUserLevels', $.extend({idc: this.idc}, data));
    }
  },
}
</script>

<style>
.accessComponents label.item-content.item-radio {
  padding:0px;
}
</style>

<style scoped>
table td {
	vertical-align: top!important;
}

.width55 {
  width:55px;
}

.paddingTopBottom {
  padding-top: 20px;
  padding-bottom: 30px;
}

.tableHeaderRotate {
  transform: rotate(-45deg);
  white-space: nowrap;
}

.accordion-item-content {
  z-index: 99999;
  position: relative;
  -webkit-backface-visibility: hidden;
}
</style>
