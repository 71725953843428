<template>
	<page
		:title="`${$LanguageHelper.translate('_EARN_MONEY_WITH_')} ${$Config.apps[$Config.app.currentApp].displayName}`"
		:backgroundImage="backgroundImage"
		pageContentClass="companyBackground"
		>

		<f7-card v-if="$LanguageHelper.getCurrentLanguageObj().code == 'nl_NL'" class="cardCenter marginTop25">
			<f7-card-header>Als promotor van {{$Config.apps[$Config.app.currentApp].displayName}} kun je geld verdienen op maandelijkse basis!</f7-card-header>

			<f7-card-content>
				Wil je meer informatie over de voordelen en voorwaarden? Klik op de knop om ons een bericht te sturen en we nemen persoonlijk contact met je op.
			</f7-card-content>

			<f7-block strong>
				<a :href="`mailto:${$Config.apps[$Config.app.currentApp].supportEmail}?subject=Aanmelden als promotor`" class="widthFull button button-fill button-raised external">{{$LanguageHelper.translate('_I_WANT_TO_KNOW_MORE_')}}</a>
			</f7-block>
		</f7-card>

		<f7-card v-else>
			<f7-card-header>As a {{$Config.apps[$Config.app.currentApp].displayName}}promotor you can earn money on a monthly basis!</f7-card-header>

			<f7-card-content>
				Do you want more information about the benefits and conditions? Click on the button to send us a message and we will contact you personally.
			</f7-card-content>

			<f7-block strong>
				<a :href="`mailto:${$Config.apps[$Config.app.currentApp].supportEmail}?subject=Register as promotor`" class="widthFull button button-fill button-raised external">{{$LanguageHelper.translate('_I_WANT_TO_KNOW_MORE_')}}</a>
			</f7-block>
		</f7-card>
	</page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
	components: {
    'page': Page,
  },

	data() {
    return {
			backgroundImage: `/static/img/${$Config.apps[$Config.app.currentApp].name}/bg.jpg`,
    };
  },
};
</script>
