<template>
  <page :title="$LanguageHelper.translate('_PROGRESS_')" :toolbar="toolbar" @initiated="go">
		<template #overview v-if="toolbar.tabs.overview.active">
			<tab-overview :idc="idc" />
		</template>

		<template #measurements v-if="toolbar.tabs.measurements.active">
			<tab-measurements :idc="idc" />
		</template>

		<template #skinfold v-if="toolbar.tabs.skinfold.active">
			<tab-skinfold :idc="idc" />
		</template>
	</page>
</template>

<script>
import Page from '../../components/smart/page.vue';
import TabOverview from './tab-overview.vue';
import TabMeasurements from './tab-measurements.vue';
import TabSkinfold from './tab-skinfold.vue';

export default {
  components: {
    'page': Page,
		'tab-overview': TabOverview,
		'tab-measurements': TabMeasurements,
    'tab-skinfold': TabSkinfold,
  },

	props: {
		idc: {required: true},
	},

  data() {
		return {
			toolbar: {
				tabs: {
					overview: {text: $LanguageHelper.translate('_PROGRESS_'), icon: 'icon iconStats', render: true, active: false},
					measurements: {text: $LanguageHelper.translate('_VIEW_MEASUREMENT_CARD_'), icon: 'icon ionList', render: true, active: false},
					skinfold: {text: $LanguageHelper.translate('_VIEW_SKINFOLD_MEASUREMENT_'), icon: 'icon ionList', render: true, active: false},
				}
      }
    };
  },

	methods: {
		go() {
			this.toolbar.tabs.overview.active = true;
		}
	}
};
</script>
