import copy from 'copy-to-clipboard';

export default {
	methods: {
		copyToClipboard(content) {
			copy(content);
			$ViewHelper.alert($LanguageHelper.translate('_COPIED_TO_CLIPBOARD_'), $LanguageHelper.translate('_NOTICE_'));
		},
	}
};
