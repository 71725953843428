<template>
	<div>
		<fixed-top :resized="render">
			<list-form :params="form" @changed="changed"></list-form>

			<f7-list no-hairlines class="displayBlockItemInner toplistInfo marginTop10">
				<f7-list-item>
					<div v-if="pages.failedDirectDebits.render">{{pages.failedDirectDebits.info.notice}}</div>
					<div v-if="pages.unpaidSendInvoices.render">{{pages.unpaidSendInvoices.info.notice}}</div>
					<div v-if="pages.failedRefunds.render">{{pages.failedRefunds.info.notice}}</div>

					<template v-if="pages.failedDirectDebits.render && $UserHelper.hasAccess('payments', 'refund', idc)">
						<div v-if="pages.failedDirectDebits.collectionAgency.show == 1 && pages.failedDirectDebits.collectionAgency.enabled == 0" class="marginTop5">
							<a class="link" @click="manageCollectionAgencyInfoOpened=true">{{$LanguageHelper.translate('_TO_COLLECTION_AGENCY_TITLE_')}}</a>
						</div>

						<div v-if="pages.failedDirectDebits.collectionAgency.show == 1 && pages.failedDirectDebits.collectionAgency.enabled == 1" class="marginTop5">
							<i class="icon ionCheckmarkCircle colorGreenOK"></i><a class="link" @click="manageCollectionAgencyInfoOpened=true">{{$LanguageHelper.translate('_COLLECTION_AGENCY_ENABLED_LINK_')}}</a>
						</div>
					</template>
				</f7-list-item>
			</f7-list>
		</fixed-top>

		<!-- Failed Direct Debits Page -->
		<f7-list media-list inset v-if="pages.failedDirectDebits.render">
			 <list-item v-for="(directDebit, key) in pages.failedDirectDebits.list" :key="key" type="accordion"
        :title="directDebit.invoice.customer_name"
        :after="`€ ${directDebit.invoice.amount}`"
        :subtitle="directDebit.invoice.date_invoice"
        >

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div v-if="directDebit.invoice.date_to_collection_agency" class="item marginBottom5 red bold">
							{{$LanguageHelper.translate('_SEND_TO_COLLECTION_AGENCY_')}} {{directDebit.invoice.date_to_collection_agency}}
						</div>
						<div class="item">{{$LanguageHelper.translate('_DESCRIPTION_')}}: {{directDebit.invoice.description}}</div>
						<div class="item" v-if="directDebit.invoice.sale_id && directDebit.invoice.sale_id > 0">{{$LanguageHelper.translate('_SALE_')}}: {{directDebit.invoice.sale_id}}</div>
						<div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: <a @click="downloadInvoice(directDebit.invoice.id_invoice)" class="link theme">{{directDebit.invoice.invoice_number}}</a> {{directDebit.invoice.number_installment && directDebit.invoice.number_installment > 0 ? `(${$LanguageHelper.translate('_PERIOD_').toLowerCase()} ${directDebit.invoice.number_installment})` : ''}}</div>
						<div class="item">{{$LanguageHelper.translate('_CREATED_ON_')}}: {{directDebit.invoice.created_on}}</div>
						<div class="item" v-if="directDebit.invoice.failed_description.length > 0">{{$LanguageHelper.translate('_REASON_')}}: {{directDebit.invoice.failed_description}}</div>
						<div class="item marginTop10">
							<b>{{$LanguageHelper.translate('_PAY_BEHIND_LINK_DESCRIPTION_')}}</b> <a class="link theme" @click="copyToClipboard(directDebit.invoice.payment_behind_link)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a><br/>
							<a :href="directDebit.invoice.payment_behind_link" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{directDebit.invoice.payment_behind_link}}</a>
						</div>

						<!-- Show reminders here -->
						<div v-if="directDebit.reminders_send && directDebit.reminders_send.length > 0" class="marginTop10">
							<div class="item"><b>{{$LanguageHelper.translate('_NUMBER_REMINDER_MESSAGES_SEND_')}}</b></div>
							<div v-for="(reminder, key) in directDebit.reminders_send" :key="key">
								<div class="item">{{reminder.date_reminder_send}}</div>
								<div class="item">{{`${$LanguageHelper.translate('_MESSAGE_')} ${key +1}`}} {{$LanguageHelper.translate('_IS_SEND_')}}</div>
								<div class="item"><a class="link theme" @click="showReminder(key +1, directDebit)">{{$LanguageHelper.translate('_SHOW_MESSAGE_')}}...</a></div>
							</div>

							<!-- Show reminder popup here -->
							<manage-invoice-reminder v-if="directDebit.opened"
								:idc="idc"
								:index="key"
								:number_reminder="directDebit.numberReminder"
								:id_invoice="directDebit.invoice.id_invoice"
								@close="directDebit.opened=false"
							/>
						</div>
					</f7-block>

					<!-- Show direct debit blocked info here -->
					<f7-block class="marginBottom10" v-if="directDebit.invoice.deactivated_by_system == 1">
						<div class="item marginBottom10 fontRed">
							<b>{{$LanguageHelper.translate('_SUBSCRIPTION_DEACTIVATED_BY_SYSTEM_')}}</b>
						</div>

						<div class="item marginBottom15 nl2br">
							{{$LanguageHelper.translate('_CHANGE_TO_VIA_INVITE_INFO_')}}
						</div>

						<div class="item marginBottom20">
							<f7-button @click="changeToViaInvite(directDebit.invoice.id_invoice_product_sale_group)" raised fill small>{{$LanguageHelper.translate('_CHANGE_TO_VIA_INVITE_')}}</f7-button>
						</div>
					</f7-block>

					<!-- Credit invoice button here -->
					<f7-block class="marginBottom10">
						<div class="item marginBottom20">
							<f7-button @click="creditFailedDirectDebit(directDebit.invoice.id_invoice_line)" raised fill small>{{$LanguageHelper.translate('_CREDIT_INVOICE_')}}</f7-button>
							
							<!-- Disable or enable reminders here -->
							<template v-if="!directDebit.invoice.date_to_collection_agency && $UserHelper.hasAccess('payments', 'refund', idc)">
								<template v-if="directDebit.invoice.disable_reminders == 1">
									<div class="item marginTop15 fontRed">
										<b>{{$LanguageHelper.translate('_REMINDERS_ARE_DISABLED_FOR_OPEN_INVOICE_')}}</b>
									</div>
									<a class="link" @click="disableReminders(directDebit.invoice.id_invoice, 0)">{{$LanguageHelper.translate('_ENABLE_REMINDERS_')}} ></a>
								</template>
								
								<template v-else>
									<a class="link marginTop15" @click="disableReminders(directDebit.invoice.id_invoice, 1)">{{$LanguageHelper.translate('_DISABLE_REMINDERS_')}} ></a>
								</template>
							</template>
						</div>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>

		<!-- Unpaid Send Invoices Page -->
		<f7-list media-list inset v-if="pages.unpaidSendInvoices.render">
			 <list-item v-for="(unpaidSendInvoice, key) in pages.unpaidSendInvoices.list" :key="key" type="accordion"
        :title="unpaidSendInvoice.invoice.customer_name"
        :after="`€ ${unpaidSendInvoice.invoice.amount}`"
        :subtitle="unpaidSendInvoice.invoice.date_invoice"
        >

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: <a @click="downloadInvoice(unpaidSendInvoice.invoice.id_invoice)" class="link theme">{{unpaidSendInvoice.invoice.invoice_number}}</a></div>
						<div class="item">{{$LanguageHelper.translate('_SALE_')}}: {{unpaidSendInvoice.invoice.sale_id}}</div>
						<div class="item">{{$LanguageHelper.translate('_INVOICE_SEND_ON_')}}: {{unpaidSendInvoice.invoice.date_invoice}}</div>
						<div class="item marginTop10">
							<b>{{$LanguageHelper.translate('_PAY_BEHIND_LINK_DESCRIPTION_')}}</b> <a class="link theme" @click="copyToClipboard(unpaidSendInvoice.invoice.payment_invoice_link)" :title="$LanguageHelper.translate('_COPY_TO_CLIPBOARD_')"><i class="action icon ionCopy" /></a><br/>
							<a :href="unpaidSendInvoice.invoice.payment_invoice_link" class="link theme external" :target="$Config.app.onDevice ? '_system' : '_blank'">{{unpaidSendInvoice.invoice.payment_invoice_link}}</a>
						</div>
					</f7-block>

					<!-- Credit invoice button here -->
					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('payments', 'refund', idc)">
								<f7-button @click="creditUnpaidSendInvoice(unpaidSendInvoice.invoice.id_invoice_line)" raised fill small>{{$LanguageHelper.translate('_CREDIT_INVOICE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>

		<!-- Failed refunds Page -->
		<f7-list media-list inset v-if="pages.failedRefunds.render">
			<list-item v-for="(failedRefunds, key) in pages.failedRefunds.list" :key="key" type="accordion"
        :title="failedRefunds.invoice.customer_name"
        :after="`€ ${failedRefunds.invoice.amount}`"
        :subtitle="failedRefunds.invoice.date_invoice"
        >

				<f7-accordion-content>
					<f7-block class="marginBottom10">
						<div class="item">{{$LanguageHelper.translate('_INVOICE_NUMBER_')}}: <a @click="downloadInvoice(failedRefunds.invoice.id_invoice)" class="link theme">{{failedRefunds.invoice.invoice_number}}</a></div>
						<div class="item" v-if="failedRefunds.invoice.installment && failedRefunds.invoice.installment > 0">{{$LanguageHelper.translate('_PERIOD_')}}: {{failedRefunds.invoice.installment}}</div>
						<div class="item">{{$LanguageHelper.translate('_SALE_')}}: {{failedRefunds.invoice.sale_id}}</div>
					</f7-block>

					<!-- Remove item button here -->
					<f7-block class="marginBottom10">
						<f7-row>
							<f7-col v-if="$UserHelper.hasAccess('payments', 'refund', idc)">
								<f7-button @click="removeFailedRefund(failedRefunds.invoice.id_invoice)" raised fill small>{{$LanguageHelper.translate('_DELETE_')}}</f7-button>
							</f7-col>
						</f7-row>
					</f7-block>
				</f7-accordion-content>
			</list-item>
		</f7-list>

		<manage-collection-agency-info v-if="manageCollectionAgencyInfoOpened" :idc="idc" @close="manageCollectionAgencyInfoOpened=false" @changed="loadFailedDirectDebits" />
	</div>
</template>

<script>
import CopyToClipboard from '../../js/mixins/copyToClipboard';
import ListForm from '../../components/smart/list-form.vue';
import FixedTop from '../../components/smart/fixed-top.vue';
import ManageInvoiceReminder from '../../popups/manage-invoice-reminder.vue';
import ListItem from '../../components/smart/list-item.vue';
import ManageCollectionAgencyInfo from '../../popups/manage-collection-agency-info.vue';

export default {
	mixins: [CopyToClipboard],

  components: {
		'list-form': ListForm,
    'fixed-top': FixedTop,
    'manage-invoice-reminder': ManageInvoiceReminder,
		'list-item': ListItem,
		'manage-collection-agency-info': ManageCollectionAgencyInfo
  },

	props: {
		idc: {required: true},
		pageEndReached: {type: Boolean, required: true, default: false},
	},

  data() {
    return {
			render: false,
			infiniteDone: false,
			manageCollectionAgencyInfoOpened: false,

			form: {
				fields: {
					type: {type: 'select', name: 'type', title: '_TYPE_', render: true, options: []},
				}
			},

			pages: {
				current: {
					hasInfinite: false,
					method: 'failedDirectDebits'
				},

				failedDirectDebits: {
					render: false,
					info: {
						notice: $LanguageHelper.translate('_NO_FAILED_DIRECT_DEBITS_DESCRIPTION_')
					},

					list: [],

					collectionAgency: {
						show: 0,
						enabled: 0
					},
				},

				unpaidSendInvoices: {
					render: false,
					info: {
						notice: $LanguageHelper.translate('_UNPAID_INVOICES_DESCRIPTION_')
					},

					list: []
				},

				failedRefunds: {
					render: false,
					info: {
						notice: $LanguageHelper.translate('_FAILED_REFUNDS_DESCRIPTION_')
					},

					list: []
				},
			}
    };
  },

	methods: {
		changed(field, val) {
			// Switch page here!
			if(field.name == 'type') {
				// Stop all rendering
				this.render = false;
				this.pages.failedDirectDebits.render = false;
				this.pages.unpaidSendInvoices.render = false;
				this.pages.failedRefunds.render = false;

				// Tell parent to reset the infinite if a new page is loaded
				if(this.pages.current.hasInfinite = val.hasInfinite);

				// Set current page method
				this.pages.current.method = val.value;

				// Call the init method of page
				this[val.value](true);
			}
		},

		downloadInvoice(id) {
			return $ViewHelper.toBrowser(`${$Config.app.baseURL}?service=Payment&method=downloadInvoice&id_gym=${this.idc}&id_appuser_li=${$UserHelper.getID()}&token=${$UserHelper.getToken()}&id=${id}&language=${$LanguageHelper.current}`);
		},

		showReminder(numberReminder, directDebit) {
			directDebit.numberReminder = numberReminder;
			directDebit.opened = true;
		},

		disableReminders(invoiceID, disable) {
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequest('payment', 'disableReminders', {idc: this.idc, id_invoice: invoiceID, disable: disable}, {callback: (data) => {
					if(data.status == 'OK') {
						this.loadFailedDirectDebits();
					}
				}});
			}}, $LanguageHelper.translate('_NOTICE_'), $LanguageHelper.translate('_ARE_YOU_SURE_'));
		},

		creditFailedDirectDebit(invoiceLineID) {
			// Set the data here
			let data = {idc: this.idc};
			data[`credit_${invoiceLineID}`] = 1

			// Show confirm and make request if user confirms!
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequestExtraLogin('payment', 'creditSale', data, {callback: this._creditFailedDirectDebit});
			}});
		},

		changeToViaInvite(id) {
			// Set the data here
			let data = {idc: this.idc, id: id};
	
			// Show confirm and make request if user confirms!
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequestExtraLogin('payment', 'changeSaleToViaInvite', data, {callback: this._creditFailedDirectDebit});
			}});
		},

		creditUnpaidSendInvoice(invoiceLineID) {
			// Set the data here
			let data = {idc: this.idc};
			data[`credit_${invoiceLineID}`] = 1

			// Show confirm and make request if user confirms!
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequestExtraLogin('payment', 'creditSale', data, {callback: this._creditUnpaidSendInvoice});
			}});
		},

		removeFailedRefund(invoiceID) {
			// Show confirm and make request if user confirms!
			$ViewHelper.confirm({method: () => {
				$AjaxHelper.doRequestExtraLogin('payment', 'removeFailedRefund', {idc: this.idc, id: invoiceID}, {callback: this._removeFailedRefund});
			}});
		},

		loadFailedDirectDebits() {
			$AjaxHelper.doRequest('payment', 'getFailedDirectDebits', {idc: this.idc}, {callback: this._failedDirectDebits});
		},

		loadUnpaidSendInvoices(firstOrReset = false) {
			if(firstOrReset) {
				this.pages.unpaidSendInvoices.list = [];
				this.destroyInfinite('getUnpaidInvoices');
			}
			if(!this.infiniteDone) $AjaxHelper.doRequestInfinite('payment', 'getUnpaidInvoices', {idc: this.idc}, {callback: this._unpaidSendInvoices});
		},

		loadFailedRefunds() {
			$AjaxHelper.doRequest('payment', 'getFailedRefunds', {idc: this.idc}, {callback: this._failedRefunds});
		},

		destroyInfinite(name) {
			this.infiniteDone = false;
			$AjaxHelper.destroyInfinite(name);
		},

		_failedDirectDebits(data) {
			this.render = false;
			this.pages.failedDirectDebits.collectionAgency = data.collection_agency;

			this.pages.failedDirectDebits.list = [];
			data.resultset.forEach((obj, key) => {obj.opened = false; obj.numberReminder = 1});
			this.pages.failedDirectDebits.render = true;
			this.render = true;

			// Set timeout before adding the (new) items otherwise the next item will show opened
			setTimeout(() => {this.pages.failedDirectDebits.list.push(...data.resultset)}, 2);
		},

		_unpaidSendInvoices(data) {
			this.pages.unpaidSendInvoices.render = true;
			this.render = true;

			if(data.resultset.length > 0) {
				// Set timeout before adding the (new) items otherwise the next item will show opened
				setTimeout(() => {this.pages.unpaidSendInvoices.list.push(...data.resultset)}, 2);

				//Let our parent know to reset the page end status!
				this.$emit('page-end-loaded');
			} else this.infiniteDone = true;
		},

		_failedRefunds(data) {
			this.pages.failedRefunds.list = [];
			this.pages.failedRefunds.render = true;
			this.render = true;

			// Set timeout before adding the (new) items otherwise the next item will show opened
			setTimeout(() => {this.pages.failedRefunds.list.push(...data.resultset)}, 2);
		},

		_creditFailedDirectDebit(data) {
			if(data.status == 'OK') this.loadFailedDirectDebits();
		},

		_creditUnpaidSendInvoice(data) {
			// Load the unpaid send invoices with the reset again!
			if(data.status == 'OK') this.loadUnpaidSendInvoices(true);
		},

		_removeFailedRefund(data) {
			if(data.status == 'OK') this.loadFailedRefunds();
		}
	},

	watch: {
		pageEndReached(val) {
			if(val && this.pages.current.hasInfinite) this[this.pages.current.method]();
		}
	},

	created() {
		// Push the options for different list views!
		this.form.fields.type.options.push({
			name: 'failed-direct-debits',
			hasInfinite: false,
			label: '_UNPAID_INVOICES_',
			value: 'loadFailedDirectDebits',
			selected: true
		});

		this.form.fields.type.options.push({
			name: 'unpaid-send-invoices',
			hasInfinite: true,
			label: '_SEND_INVOICES_VIA_REGISTER_',
			value: 'loadUnpaidSendInvoices',
			selected: false
		});

		this.form.fields.type.options.push({
			name: 'failed-refunds',
			hasInfinite: false,
			label: '_FAILED_REFUNDS_',
			value: 'loadFailedRefunds',
			selected: false
		});

		// Load first default method (page)!
		this.loadFailedDirectDebits();
	}
};
</script>

<style scoped>
.icon {
	margin-right: 9px;
}
</style>
