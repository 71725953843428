<template>
	<label class="item-checkbox item-content">
		<input type="checkbox" :name="name" :value="value" :checked="checked" @change.prevent="toggle">
			<i class="icon icon-checkbox"></i>
			<div class="wrapper">
      	<div><span class="text">{{textLabel}}</span> <f7-link :href="link" external :target="$Config.app.onDevice ? '_system' : '_blank'" class="theme">{{textLink}}</f7-link></div>
    	</div>
	</label>
</template>

<script>
export default {
	props: {
		name: {type: String, required: false, default: ''},
		value: {type: String, required: false, default: ''},
		checked: {type: Boolean, required: true, default: false},
		link: {type: String, required: true},
		textLink: {type: String, required: true},
		textLabel: {type: String, required: true},
	},

	data() {
    return {
			isChecked: this.checked,
    };
  },

	methods: {
		toggle() {
			this.isChecked = !this.isChecked;
			this.$emit('changed', this.isChecked);
		}
	}
}
</script>

<style scoped>
	label {
		position: relative;
		display: block;
	}

	.wrapper {
		position: relative;
		left:0px;
	}

	label.item-checkbox {
		overflow: visible;
	}

	label.item-checkbox > .icon-checkbox {
		margin-right: 15px;
	}
	/* In iOS there is no marging, we don't want the checkbox glued to the text :( */
	.ios .wrapper {
		left:0;
	}

</style>
