<template>
  <form v-show="visible" class="users">
    <!-- Search form -->
    <search v-if="searchEnabled" :enabled="searchEnabled" @searched="searched" :placeholder="$LanguageHelper.translate('_SEARCH_')" />

    <f7-list inset>
      <f7-list-item link="#" @click="clicked(user)" v-for="(user, key) in users"
				:key="key"
				:title="user.name"
				:footer="user.email"
				>
      </f7-list-item>
    </f7-list>

    <f7-card inset v-if="searchItem">
      <f7-card-header>
        {{searchItem.name}} ({{searchItem.email}})
      </f7-card-header>

      <f7-card-content>
        <list-form :params="form" @submitted="userSubmitted(searchItem.id_user)">
          <f7-block strong>
            <f7-row>
              <f7-col>
                <f7-button @click="form.submitted=true" small raised fill>{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
              </f7-col>

							<f7-col>
                <f7-button @click="canceled" small raised fill>{{$LanguageHelper.translate('_CANCEL_')}}</f7-button>
              </f7-col>
            </f7-row>
          </f7-block>
        </list-form>
      </f7-card-content>
    </f7-card>

    <f7-list inset>
      <f7-list-item accordion-item v-for="(user, key, index) in userLevels" :key="index" :title="user.name" class="noPadding">
        <f7-accordion-content>
          <list-form :params="user.form" @submitted="submitted" v-if="visible">
            <f7-block strong>
              <f7-button @click="user.form.submitted=true" small raised fill>{{$LanguageHelper.translate('_SAVE_')}}</f7-button>
            </f7-block>
          </list-form>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
  </form>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import Search from '../../components/smart/search.vue';

export default {
  components: {
    'list-form': ListForm,
    'search': Search,
  },

  props: {
    idc: {required: true},
  },

  data() {
    return {
			searchEnabled: true,
      visible: false,
      searchItem: null,
      userLevels: [],
      users: [],

      form: {
        fields: {
          levels: {type: 'checkbox_group', items: [], render: true},
        },

        values: {},
        submitted: false,
      }
    };
  },

  methods: {
    //get levels
    getCompanyUserLevels() {
      $AjaxHelper.doRequest('Gym', 'getCompanyUserLevels', {idc: this.idc}, {callback: this._initCompanyUserLevels});
    },

    //save levels
    submitted() {
      let data = [];
      this.userLevels.forEach((items, key) => {

        if(items.form.values.levels) {
          items.form.submitted = false;
          data = {
            id_user: items.id,
            userlevel: items.form.values.levels
          };
        }

				// If no levels are selected remove item from the list
				if(items.form.values.levels == 0) {
					this.userLevels.splice(key, 1)
				}
			});

      $AjaxHelper.doRequest('Gym', 'saveCompanyUserLevels', Object.assign({idc: this.idc}, data));
    },

    //save levels new user
    userSubmitted(val) {
      let data = [];

      data.userlevel = JSON.parse(JSON.stringify(this.form.values.levels));
      data.id_user = val;
			this.form.submitted = false;
      $AjaxHelper.doRequest('Gym', 'saveCompanyUserLevels', Object.assign({idc: this.idc}, data), {callback: this._companyUserLevelsSubmitted});
    },

    searched(val) {
			// Empty current list
			this.users = [];

			// Search for users
			if(val.length > 0) {
				$AjaxHelper.doRequest('Gym', 'getUsersWithoutCustomUserLevelsByName', {name: val, idc: this.idc}, {callback: this._usersInit});
			}
		},

    clicked(val) {
      //empty users, not needed anymore
      this.users = [];
			this.searchEnabled = false;
       if(val) {
         this.searchItem = val;
       }
    },

    //hide user
    canceled() {
			this.searchEnabled = true;
      this.searchItem = null;
    },

    _initCompanyUserLevels(data) {
      //empty list
      this.userLevels = [];

      if(data.userlevels) {
        data.userlevels.forEach((items) => {
          items.form = {
            fields: {
              levels: {type: 'checkbox_group', items: [], render: true},
            },

            values: {},
            submitted: false,
          }

          //set levels for users
          items.levels.forEach((item) => {
            items.form.fields.levels.items.push({
  						title: item.name,
  						checked: item.checked == 1 ? true : false,
  						value: item.system_name,
							render: true
  					});
          });
        });


        //empty list otherswise you get double levels
        this.form.fields.levels.items = [];

        //set levels for new user
        data.levels.forEach((item) => {
          this.form.fields.levels.items.push({
            title: item.name,
            checked: false,
            value: item.system_name,
						render: true
          });
        });

        this.userLevels = data.userlevels;
      }

    	this.visible = true;
    },

    //get users
    _usersInit(data) {
      if(data.resultset) {
        this.users = data.resultset;
      }
    },

    _companyUserLevelsSubmitted(data) {
      if(data.status == 'OK') {
				// Enable search again
				this.searchEnabled = true;

        // Set everything back to default
        this.searchItem = null;
        this.getCompanyUserLevels();
      }
    }
  },

  created() {
    this.getCompanyUserLevels();
	},
}
</script>

<style>
.users .list label.item-content {
  padding-left:0px;
}
</style>
