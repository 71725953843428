<template>
  <page :title="$LanguageHelper.translate('_PAYMENTS_')">
		<f7-list inset>
      <template v-for="(item, key) in items">
        <f7-list-item  v-if="item.render" :key="key" :title="item.title" :link="item.link">
          <template #media><f7-icon :icon="item.icon"></f7-icon></template>
        </f7-list-item>
       </template>
    </f7-list>
  </page>
</template>

<script>
import Page from '../components/smart/page.vue';

export default {
  components: {
    'page': Page,
  },

	props: {
		idc: {required: true},
	},

  data() {
    return {
      items: {
				transactions: {icon: 'ionLogoEuro', title: $LanguageHelper.translate('_MANAGE_TRANSACTIONS_'), link: `/manage-transactions/idc/${this.idc}`, render: $UserHelper.hasAccess('payments', 'transactions', this.idc)},
				sales: {icon: 'ionListBox', title: $LanguageHelper.translate('_SALES_'), link: `/manage-sales/idc/${this.idc}`, render: $UserHelper.hasAccess('payments', 'sales', this.idc)},
				direct_debits: {icon: 'ionRefresh', title: $LanguageHelper.translate('_SUBSCRIPTIONS_SEPA_'), link: `/manage-direct-debits/idc/${this.idc}`, render: $UserHelper.hasAccess('payments', 'subscriptions', this.idc)},
				register_payment: {icon: 'ionCalculator', title: $LanguageHelper.translate('_REGISTER_PAYMENT_'), link: `/manage-register/idc/${this.idc}`, render: $UserHelper.hasAccess('payments', 'pin', this.idc)},
				accountancy: {icon: 'ionFiling', title: $LanguageHelper.translate('_ACCOUNTANCY_'), link: `/manage-accountancy/idc/${this.idc}`, render: $UserHelper.hasAccess('payments', 'financial', this.idc)},
			}
    };
  }
};
</script>
