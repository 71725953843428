<template>
	<li :class="className">
		<a class="item-link" :href="link ? link : '#'">
			<div class="item-content">
				<div v-if="img" class="item-media">
					<img :src="img" width="44" height="44" />
				</div>
				
				<div class="item-inner">
					<template v-if="after || link"> <!-- If the list has a link then we also need a row so the arrow will appear on the right side -->
						<div class="item-title-row">
							<div class="item-title">
								<div :class="titleClass">{{$FunctionsHelper.specialChars(title)}}<i v-if="iconClass && iconClass != ''" :class="iconClass" class="action icon extraSmall" /></div>

								<div v-if="footer" class="item-footer">
									<div :class="footerClass">{{$FunctionsHelper.specialChars(footer)}}</div>
								</div>
							</div>
							
							<!-- The after element will be outlined to the right, inside of the item-title-row element -->
							<div v-if="after" class="item-after" :class="afterClass">{{$FunctionsHelper.specialChars(after)}}</div>
						</div>
					</template>
				
					<template v-else>
						<div class="item-title">
							<div :class="titleClass">{{$FunctionsHelper.specialChars(title)}}<i v-if="iconClass && iconClass != ''" :class="iconClass" class="action icon extraSmall" /></div>

							<div v-if="footer" class="item-footer">
								<div :class="footerClass">{{$FunctionsHelper.specialChars(footer)}}</div>
							</div>
						</div>
					</template>

					<div v-if="subtitle" class="item-subtitle">
						<div :class="subtitleClass">{{$FunctionsHelper.specialChars(subtitle)}}</div>
					</div>

					<div v-if="text" class="item-text">
						<div :class="textClass">{{$FunctionsHelper.specialChars(text)}}</div>
					</div>
				</div>
			</div>
		</a>

		<slot></slot>
	</li>
</template>

<script>
// This component is built as a fix because the F7 VUE version does not exeactly do what we want so we created our own component for it!
import { ref } from 'vue';

export default {
	props: {
		type: { required: true },
    title: { required: true },
    subtitle: { required: false },
    text: { required: false },
    after: { required: false },
    footer: { required: false },
    img: { required: false },
    link: { required: false },
		className: { required: false },
    iconClass: { required: false },
    titleClass: { required: false },
    subtitleClass: { required: false },
    textClass: { required: false },
    afterClass: { required: false },
    footerClass: { required: false },
  },

	setup(props) {
		const className = ref('');

		if(props.type == 'media') className.value += 'media-item';
		if(props.type == 'accordion') className.value += 'accordion-item';
		if(props.className) className.value = ` ${props.className}`;

		return {
			className
		}
	}
};
</script>
