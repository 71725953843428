<template>
  <div v-show="visible">
    <f7-toolbar position="top" class="toolbarTop agenda backgroundColorWhite no-shadow">
      <!-- Clickable date  and navigation -->
      <div class="wrapperPeriod">
        <table class="center">
          <tr>
            <td class="arrow"><a @click.prevent="toWod(pagination.prev)"><i v-if="pagination.prev" class="icon ionArrowDropLeft" /></a></td>
            <td class="titleTD"><span class="title">{{wod.date_wod}}</span></td>
            <td class="arrow"><a @click.prevent="toWod(pagination.next)"><i v-if="pagination.next" class="icon ionArrowDropRight" /></a></td>
          </tr>
        </table>
      </div>
    </f7-toolbar>

    <div class="marginTop65">
      <f7-block v-if="selectFilters">
        <f7-link @click.prevent="filter" class="marginRight10"><f7-icon class="icon ionFunnel themeButton"></f7-icon></f7-link>
        
        <template v-for="(item, index) in selectFilters" :key="index"><f7-chip class="marginBottom10 marginRight3" v-if="item.value" :text="item.text"></f7-chip></template>
        <template v-for="(category, key) in selectFilters.id_wod_ranking_category" :key="key"><f7-chip class="marginBottom10 marginRight3" v-if="category.value" :text="category.text"></f7-chip></template>
      </f7-block>

      <f7-block-title class="noMarginTop theme" v-html="wod.name"></f7-block-title>

      <template v-for="(rankings, index) in ranking" :key="index">
        <f7-card v-if="ranking">
          <f7-card-header v-if="rankings">
            <div>{{index == 'whiteboard' ? $LanguageHelper.translate('_DAY_RANKING_') : $LanguageHelper.translate('_OVERALL_RANKING_')}}</div>
          </f7-card-header>
          
          <template v-for="(items, key) in rankings" :key="key">
            <f7-card-content v-if="items.length > 0"  class="paddingBottom30">
            <f7-block-title class="noMarginLeft noMarginTop paddingBottom20 theme">{{key}}</f7-block-title>
            <f7-block>
              <f7-row class="themeNavbar">
                <f7-col width="5"></f7-col>
                <f7-col width="10"></f7-col>
                <f7-col width="45">{{$LanguageHelper.translate('_NAME_')}}</f7-col>
                <f7-col width="20">{{$LanguageHelper.translate('_SCORE_')}}</f7-col>
                <f7-col width="20">Type</f7-col>
              </f7-row>
              <f7-row v-for="(item, key2) in items" :key="key2">
                <f7-col width="5" class="paddingCell textAlignCenter">{{item.number}}</f7-col>
                <f7-col width="10" class="textAlignCenter width50">
                  <div class="image">
                    <img v-if="item.img" :src="item.img"/>
                    <f7-icon v-else class="icon ionPerson colorGrey"></f7-icon>
                  </div>
                </f7-col>

                <f7-col v-if="index == 'whiteboard'" width="45" class="paddingCell"><a href="#" class="link theme" @click="openWODScoreDetails(item.id_wod_whiteboard, item.id_appuser)">{{item.name}}</a></f7-col>
                <f7-col v-else width="45" class="paddingCell">{{item.name}}</f7-col>

                <f7-col width="20" class="paddingCell">{{item.score}} {{item.weight_scaled > 0 ? '(' + item.weight_scaled + ' kg)' : ''}}</f7-col>
                <f7-col width="20" class="paddingCell">{{item.rx == 1 ? 'RX' : (item.fundamental == 1 ? 'FDM' : 'Scaled')}}</f7-col>
              </f7-row>
            </f7-block>
          </f7-card-content>
          </template>
          
        <f7-card-content v-if="rankings.length == 0">
          {{$LanguageHelper.translate('_NO_RESULTS_')}}
        </f7-card-content>
      </f7-card>

      </template>
  
      <f7-card v-if="ranking.length == 0">
        <f7-card-content>{{$LanguageHelper.translate('_NO_RESULTS_')}}</f7-card-content>
      </f7-card>
    </div>

    <filter-wod v-if="openFilterWod"
	    :idc="idc"
	    :IDWhiteboard="IDWhiteboard"
	    @close="openFilterWod=false"
	    @filterList="filterList"
	    :filters="filters"
	    :selectFilters="selectFilters"
    />

    <search-wod v-if="openSearchWod"
	    :idc="idc"
	    @close="openSearchWod=false"
	    @searchResult="searchResult"
    />

		<!-- WOD Score details -->
		<wod-score-details v-if="rankingDetails.opened"
			:idc="idc"
			:id_wod_whiteboard="rankingDetails.id_wod_whiteboard"
			:id_whiteboard_appuser="rankingDetails.id_whiteboard_appuser"
			@close="rankingDetails.opened=false"
		/>
  </div>
</template>

<script>
import ListForm from '../../components/smart/list-form.vue';
import FilterWod from '../../popups/filter-wod.vue';
import CustomerSearch from '../../popups/customer-search-wod.vue';
import WODScoreDetails from '../../popups/wod-score-details.vue';

export default {
  components: {
    'list-form': ListForm,
    'filter-wod': FilterWod,
    'search-wod': CustomerSearch,
    'wod-score-details': WODScoreDetails,
  },

  props: {
    idc: {required: true},
    idWod: {required: true},
    fab: {type: Object, required: true},
    searchWod: {type: Boolean, required: false},
  },

  data() {
    return {
			rankingDetails: {
				opened: false,
				id_appuser: null,
			},

      visible: false,
      wod: {},
      pagination: {},
      ranking: {},
      openFilterWod: false,
      openSearchWod: false,
      IDWhiteboard: null,
      selectFilters: {
        gender: {},
        filter_ranking_day: {},
        filter_ranking_overall: {},
        id_wod_ranking_category: {},
        filter_rx: {},
        filter_fundamental: {},
        filter_scaled: {},
      },
      filters: {},
    };
  },

  watch: {
    searchWod(val) {
      if(val) this.openSearchWod = true;
    }
  },

  methods: {
		openWODScoreDetails(whiteboardID, userID) {
			this.rankingDetails.id_wod_whiteboard = whiteboardID;
			this.rankingDetails.id_whiteboard_appuser = userID;
			this.rankingDetails.opened = true;
		},

    searchResult(val) {
			// Search for wod
      this.$emit('closeSearchWod');
			if(val) this.toWod(val);
		},

    filter() {
      this.openFilterWod = true;
    },

    filterList(data) {
      this.listFilters = data;
      this.getRanking(this.listFilters);
    },

    sortFilters(data) {
      //default this, we do not always need them
      this.selectFilters = {
        gender: {},
        filter_ranking_day: {},
        filter_ranking_overall: {},
        id_wod_ranking_category: {},
        filter_rx: {},
        filter_fundamental: {},
        filter_scaled: {},
      };

      if(data.wod.filters) {
        if(data.wod.filters.gender) {
          this.selectFilters.gender.value = data.wod.filters.gender;
          this.selectFilters.gender.text = (data.wod.filters.gender == 'm' ? $LanguageHelper.translate('_MEN_ONLY_') : (data.wod.filters.gender == 'v' ? $LanguageHelper.translate('_WOMAN_ONLY_') : $LanguageHelper.translate('_MEN_AND_WOMAN_')));
        }

        if(data.wod.has_overall_ranking) {
          if(data.wod.filters.ranking_day == 1) {
            this.selectFilters.filter_ranking_day.value = 1;
            this.selectFilters.filter_ranking_day.text = $LanguageHelper.translate('_DAY_');
          }

          if(data.wod.filters.ranking_overall == 1) {
            this.selectFilters.filter_ranking_overall.value = 1;
            this.selectFilters.filter_ranking_overall.text = $LanguageHelper.translate('_OVERALL_');
          }
        }

        if(data.wod.rx == 1) {
          this.selectFilters.filter_rx.value = (data.wod.filters.rx ? data.wod.filters.rx : 0);
          this.selectFilters.filter_rx.text = 'RX';
        }

        if(data.wod.fundamental == 1) {
          this.selectFilters.filter_fundamental.value = (data.wod.filters.fundamental ? data.wod.filters.fundamental : 0);
          this.selectFilters.filter_fundamental.text = 'FDM';
        }

        if(data.wod.rx == 1 || data.wod.fundamental == 1) {
          this.selectFilters.filter_scaled.value = (data.wod.filters.scaled ? data.wod.filters.scaled : 0);
          this.selectFilters.filter_scaled.text = 'Scaled';
        }
      }

      if(data.ranking_categories && data.ranking_categories.length > 0) {
        var that = this;
        that.selectFilters.id_wod_ranking_category = [];
        data.ranking_categories.forEach(function (obj, index) {
          if(obj.selected == 1) {
            that.selectFilters.id_wod_ranking_category.push({
              value: obj.id_wod_ranking_category,
              text: obj.name,
            });

          }
        });
      }

      this.filters = data;
    },

    _initRanking(data) {
      if(data.status == 'OK') {
        //set filters
        this.sortFilters(data);

        //set ranking
        if(data.ranking) this.ranking = data.ranking;

        //get wod data
        this.wod = data.wod;

        //set wod id
        this.IDWhiteboard = data.wod.id_wod_whiteboard;

        //emit ID wod for changing tabs
        this.$emit('saveIDWod', this.IDWhiteboard);

        //emit name_url for disabled_sharing
        this.$emit('nameUrl', this.wod.name_url);

        //set pagination
        this.pagination = data.pagination;

        this.visible = true;
      }
    },

    toWod(val) {
		  $AjaxHelper.doRequest('WOD', 'whiteboard', {idc: this.idc, id_appuser: $UserHelper.getID(), version_wod: 1.0, page: 'ranking', id_wod_whiteboard: val}, {callback: this._initRanking});
    },

    //with filters
    getRanking(data) {
  		$AjaxHelper.doRequest('WOD', 'whiteboard', Object.assign({idc: this.idc, version_wod: 1.0, page: 'ranking', filterForm: 1, id_wod_whiteboard: this.IDWhiteboard}, data), {callback: this._initRanking});
  	},
  },

  created() {
    this.toWod(this.idWod);
	},
}
</script>

<style scoped>
.paddingCell {
  padding-top: 15px;
  padding-bottom: 10px;
}

.themeNavbar {
  background-color: var(--navbar-bg-color);
  color: var(--navbar-color);
}

.theme {
  color: var(--theme-color);
}

.image img, .image .icon {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  margin-top:4px;
  margin-bottom: 4px;
  border: 1px solid #e7e7e7;
  vertical-align: middle;
}

.icon.ionPerson {
  text-align: center;
  font-size: 30px;
  color: #d7d7d7;
}

.icon.ionPerson:after {
  vertical-align: sub;
}

.row:nth-child(even) {
  background-color: #f7f7f7;
}

.block:first-child {
  margin-top: 20px;
  margin-bottom: 0px;
}

.wrapperPeriod {
  margin: auto;
}
</style>
